import React, { useState, useEffect, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { URL_Start } from '../../../constant';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import { Spinner } from 'react-bootstrap';
import LoadingButton from '../commonComponent/LoadingButton';
import { v4 as uuid } from 'uuid';
import { useHistory } from "react-router-dom";
import DynamicDataTables from '../commonComponent/DataTable/DynamicDataTable';
import paginationFactory from 'react-bootstrap-table2-paginator';

const DoctorBusinessMapping = () => {
    const history = useHistory();
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const aceptableFileName = ["csv", "XLS", "XLSX"];
    const [modal, setModal] = useState();
    const toggleModal = () => {
        setModal(!modal)
    }
    const [ExcelFID, SetExcelFID] = useState("");
    const [FileuploadStatus, SetFileuploadStatus] = useState(true);
    const [CorrectRecord, SetCorrectRecord] = useState(null);
    const [ErrorRecord, SetErrorRecord] = useState(null);
    const [FileNameDone, SetFileNameDone] = useState("");
    const fileUploadRef = useRef();
    const [exlFileName, setExlFileName] = useState({ fileName: '' });
    const [exlHeader, setExlHeader] = useState([]);
    const [exlSheetData, setExlSheetData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [IsProcessLoad, SetIsProcessLoad] = useState(false);
    const [BtnspinnerLoadingFinal, SetBtnspinnerLoadingFinal] = useState(false);
    const localStorageDetails = JSON.parse(localStorage.getItem("UserDetails"));
    const [ProcessStatus, SetProcessStatus] = useState(false);
    const [ProcessStatusCancel, SetProcessStatusCancel] = useState(false);
    const [ExcelID, SetExcelID] = useState(null);
    const [DrValidBusinessMaplistCSV, setValidDrBusinessMaplistCSV] = useState([]);
    const [FileType, SetFileType] = useState("1");
    const [downloadvisible, setDownloadVisible] = useState(false);

    const [PageHelper, SetPageHelper] = useState({
        dsDrBusinessMaplists: []
    });

    const paginationOptions = {

        totalSize: PageHelper.TotalRecord,
        sizePerPage: PageHelper.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',

        onSizePerPageChange: (sizePerPage, page, event) => {
            console.log('Size per page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            BindDrBusinessMapList(page, sizePerPage);
            document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
        },
        onPageChange: (page, sizePerPage) => {
            console.log('Page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            BindDrBusinessMapList(page, sizePerPage);
        }
    };

    const columns = [{
        dataField: '#',
        text: '#',
        formatter: (cell, row, rowIndex) => {
            let rowNumber = (PageHelper.PageNo - 1) * PageHelper.PageSize + (rowIndex + 1);
            return <span>{rowNumber}</span>;
        },
        headerStyle: (colum, colIndex) => {
            return { width: '5%' };
        }

    },
    {
        dataField: 'DoctorUniqueId',
        text: 'DrUniqueId',
        editable: false

    },
    {
        dataField: 'FirstName',
        text: 'First Name',
        editable: false
    },
    {
        dataField: 'Mobile',
        text: 'Mobile',
        editable: false
    },
    {
        dataField: 'ErrorDesc',
        text: 'Error Desc',
        editable: false
    }
    ];

    const BindDrBusinessMapList = async (PageNo, PageSize) => {
        setIsLoading(true);
        try {

            let url = URL_Start + 'DoctorMaster/getDrBusinessMapDetails';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { PageNo: `${PageNo}`, PageSize: `${PageSize}`, ExcelID: `${localStorage.ImpExcelId == undefined ? "" : localStorage.ImpExcelId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;

                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        SetCorrectRecord(ds.CorrectRecord);
                        SetErrorRecord(ds.ErrorRecord);
                        SetFileNameDone(ds.FileName);
                        await SetPageHelper({
                            ...PageHelper,
                            PageNo: Number(PageNo),
                            PageSize: Number(PageSize),
                            TotalRecord: Number(ds.TotalRecord),
                            dsDrBusinessMaplists: ds.ObjImportVaildDrBusinessMapList == null ? [] : ds.ObjImportVaildDrBusinessMapList
                        });

                        setIsLoading(false);
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');
                    setIsLoading(false);
                }

            }
            else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
            // return data;
        } catch (error) {
            if (error.response) {
                ErrorFunction(error.response.status);
                setIsLoading(false);
            }

        }
    }

    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }



    const handleUpload = async (e) => {
        const myFile = e.target.files[0];

        if (!myFile) return;
        if (!checkFileName(myFile.name)) {
            handleUploadRemoveFile();
            SweetAlert.fire({ title: "File upload", text: "Valid file required", icon: "info" })
            return;
        }

        const data = await myFile.arrayBuffer();
        const workbook = XLSX.read(data, { type: 'binary', cellDates: true, dateNF: 'mm/dd/yyyy;@' });
        const workheader = XLSX.read(data, { sheetRows: 0 });
        const workSheet = workbook.Sheets[workbook.SheetNames[0]];
        let jsonData = JSON.stringify(XLSX.utils.sheet_to_json(workSheet, { raw: false }));
        console.log(jsonData);

        const wsHeader = workheader.Sheets[workbook.SheetNames[0]];
        const jsonHeaderData = XLSX.utils.sheet_to_json(wsHeader, {
            header: 1,
            defval: "1",
        });
        var JH = jsonHeaderData[0];
        //if check header size and header name  validation write JH 
        if (JH.length !== 31) {
            handleUploadRemoveFile();
            SweetAlert.fire({ title: "File handle", text: "31 Required header not available.", icon: "error" });
            return;
        }
        else if (JH[0] !== 'DoctorUniqueId' || JH[1] !== 'DrTitle' || JH[2] !== 'FirstName' || JH[3] !== 'MiddleName' || JH[4] !== 'LastName' || JH[5] !== 'PinCode' || JH[6] !== 'State' || JH[7] !== 'Region' || JH[8] !== 'District' || JH[9] !== 'ClinicAddress' || JH[10] !== 'ResidenceAddress' || JH[11] !== 'Landmark' || JH[12] !== 'Landline' || JH[13] !== 'Mobile' || JH[14] !== 'Category' || JH[15] !== 'Specialization' || JH[16] !== 'DrLocationState' || JH[17] !== 'DrLocationRegion' || JH[18] !== 'DrLocationDistrict' || JH[19] !== 'Modality1' || JH[20] !== 'Modality1SalesPersonEmpCode' || JH[21] !== 'Modality1SalesManagerEmpCode' || JH[22] !== 'Modality1SalesHeadEmpCode' || JH[23] !== 'Modality2' || JH[24] !== 'Modality2SalesPersonEmpCode' || JH[25] !== 'Modality2SalesManagerEmpCode' || JH[26] !== 'Modality2SalesHeadEmpCode' || JH[27] !== 'Modality3' || JH[28] !== 'Modality3SalesPersonEmpCode' || JH[29] !== 'Modality3SalesManagerEmpCode' || JH[30] !== 'Modality3SalesHeadEmpCode') {
            handleUploadRemoveFile();
            SweetAlert.fire({ title: "File handle", text: "Invalid header : Header name not matched", icon: "error" });
            return;
        }

        setExlSheetData(jsonData);
        setExlFileName({ fileName: myFile.name });
        setExlHeader(JH.map((d, index) => (<option key={index} value={d}>{d}</option>)));
    }
    const handleUploadRemoveFile = () => {
        setExlFileName({ ...exlFileName, "fileName": "" });
        setExlHeader(null);
        fileUploadRef.current.value = "";
        //formikexl.resetForm();
    }

    const checkFileName = (name) => {
        return aceptableFileName.includes(name.split(".").pop().toLowerCase());
    }

    const handleSubmit = (files, allFiles) => {
        console.log(files.map(f => f.meta));
        console.log(files);
        allFiles.forEach(f => f.remove())

    }

    const uploadfile = () => {
        SubmitExcelUploadDetails();
    }

    const SubmitExcelUploadDetails = async () => {
        let errorMessage = "";
        const ValidOnlyMiddleCharName = /^[A-Za-z_ ]+$/i;
        const ValidOnlyCharName = /^[A-Za-z]+$/i;
        const ValidOnlyNumric = /^[0-9\b]+$/i;
        const validOnlyalphabets = /^[a-zA-Z& ]*$/i;
        const validOnlyCharNum = /^[a-zA-Z0-9]*$/i;
        const validOnlyCharalphabets = /^[A-Za-z.]*$/i;
        const File = fileUploadRef.current.value;
        if (File === "") {
            SweetAlert.fire({ title: "Warning", text: "File is required!", icon: "info" });
            return;
        }
        if (errorMessage !== "") {
            SweetAlert.fire({ title: "File handle", text: errorMessage, icon: "error" });
            return;
        }
        var ColumnSame = 0;
        if (ColumnSame === 1) {
            SweetAlert.fire({ title: "Map from uplodaded CSV", text: 'Duplicate value not allowed', icon: "error" });
            return;
        }
        setIsLoading(true);
        var Data = exlSheetData;
        const arr = JSON.parse(Data);

        if (arr.length > 0) {
            arr.forEach(function (e) {
                if (typeof e === "object") {
                    e["ErrorDesc"] = ""
                }
            });
            arr.forEach(function (e) {
                if (typeof e === "object") {
                    e["ErrorFlag"] = "0"
                }
            });

            for (let i = 0; i < arr.length; i++) {
                if (arr[i].DoctorUniqueId != "" && arr[i].DoctorUniqueId != null) {
                    arr[i].DoctorUniqueId = arr[i].DoctorUniqueId.replace(/ /g, '').replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('[U+200E]', '').replace(/[^\x00-\x7F]/g, "").trim();
                }
                if (arr[i].DrTitle != "" && arr[i].DrTitle != null) {
                    arr[i].DrTitle = arr[i].DrTitle.replace(/ /g, '').replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('[U+200E]', '').replace(/[^\x00-\x7F]/g, "").trim();
                }
                // if (arr[i].FirstName != "" && arr[i].FirstName != null) {
                //     arr[i].FirstName = arr[i].FirstName.replace(/ /g, '').replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('[U+200E]', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].MiddleName != "" && arr[i].MiddleName != null) {
                //     arr[i].MiddleName = arr[i].MiddleName.replace(/ /g, '').replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('[U+200E]', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].LastName != "" && arr[i].LastName != null) {
                //     arr[i].LastName = arr[i].LastName.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('[U+200E]', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                if (arr[i].PinCode != "" && arr[i].PinCode != null) {
                    arr[i].PinCode = arr[i].PinCode.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('[U+200E]', '').replace(/[^\x00-\x7F]/g, "").trim();
                }
                if (arr[i].Mobile === undefined || arr[i].Mobile == "") {
                    arr[i].Mobile ="";
                }
                // if (arr[i].State != "" && arr[i].State != null) {
                //     arr[i].State = arr[i].State.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('[U+200E]', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].Region != "" && arr[i].Region != null) {
                //     arr[i].Region = arr[i].Region.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('U+200E', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].District != "" && arr[i].District != null) {
                //     arr[i].District = arr[i].District.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('U+200E', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].ClinicAddress != "" && arr[i].ClinicAddress != null) {
                //     arr[i].ClinicAddress = arr[i].ClinicAddress.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('U+200E', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].ResidenceAddress != "" && arr[i].ResidenceAddress != null) {
                //     arr[i].ResidenceAddress = arr[i].ResidenceAddress.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('U+200E', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].Landmark != "" && arr[i].Landmark != null) {
                //     arr[i].Landmark = arr[i].Landmark.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('U+200E', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].Landline != "" && arr[i].Landline != null) {
                //     arr[i].Landline = arr[i].Landline.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('U+200E', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].Mobile != "" && arr[i].Mobile != null) {
                //     arr[i].Mobile = arr[i].Mobile.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('U+200E', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].Category != "" && arr[i].Category != null) {
                //     arr[i].Category = arr[i].Category.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('U+200E', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].Specialization != "" && arr[i].Specialization != null) {
                //     arr[i].Specialization = arr[i].Specialization.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('U+200E', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].DrLocationState != "" && arr[i].DrLocationState != null) {
                //     arr[i].DrLocationState = arr[i].DrLocationState.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('U+200E', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].DrLocationRegion != "" && arr[i].DrLocationRegion != null) {
                //     arr[i].DrLocationRegion = arr[i].DrLocationRegion.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('U+200E', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].DrLocationDistrict != "" && arr[i].DrLocationDistrict != null) {
                //     arr[i].DrLocationDistrict = arr[i].DrLocationDistrict.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('U+200E', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].Modality1 != "" && arr[i].Modality1 != null) {
                //     arr[i].Modality1 = arr[i].Modality1.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('U+200E', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].Modality1SalesPersonEmpCode != "" && arr[i].Modality1SalesPersonEmpCode != null) {
                //     arr[i].Modality1SalesPersonEmpCode = arr[i].Modality1SalesPersonEmpCode.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('U+200E', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].Modality1SalesManagerEmpCode != "" && arr[i].Modality1SalesManagerEmpCode != null) {
                //     arr[i].Modality1SalesManagerEmpCode = arr[i].Modality1SalesManagerEmpCode.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('U+200E', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].Modality1SalesHeadEmpCode != "" && arr[i].Modality1SalesHeadEmpCode != null) {
                //     arr[i].Modality1SalesHeadEmpCode = arr[i].Modality1SalesHeadEmpCode.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('U+200E', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].Modality2 != "" && arr[i].Modality2 != null) {
                //     arr[i].Modality2 = arr[i].Modality2.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('U+200E', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].Modality2SalesPersonEmpCode != "" && arr[i].Modality2SalesPersonEmpCode != null) {
                //     arr[i].Modality2SalesPersonEmpCode = arr[i].Modality2SalesPersonEmpCode.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('U+200E', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].Modality2SalesManagerEmpCode != "" && arr[i].Modality2SalesManagerEmpCode != null) {
                //     arr[i].Modality2SalesManagerEmpCode = arr[i].Modality2SalesManagerEmpCode.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('U+200E', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].Modality2SalesHeadEmpCode != "" && arr[i].Modality2SalesHeadEmpCode != null) {
                //     arr[i].Modality2SalesHeadEmpCode = arr[i].Modality2SalesHeadEmpCode.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('U+200E', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].Modality3 != "" && arr[i].Modality3 != null) {
                //     arr[i].Modality3 = arr[i].Modality3.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('U+200E', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].Modality3SalesPersonEmpCode != "" && arr[i].Modality3SalesPersonEmpCode != null) {
                //     arr[i].Modality3SalesPersonEmpCode = arr[i].Modality3SalesPersonEmpCode.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('U+200E', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].Modality3SalesManagerEmpCode != "" && arr[i].Modality3SalesManagerEmpCode != null) {
                //     arr[i].Modality3SalesManagerEmpCode = arr[i].Modality3SalesManagerEmpCode.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('U+200E', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
                // if (arr[i].Modality3SalesHeadEmpCode != "" && arr[i].Modality3SalesHeadEmpCode != null) {
                //     arr[i].Modality3SalesHeadEmpCode = arr[i].Modality3SalesHeadEmpCode.replace(/(^[\s\u200b]*|[\s\u200b]*|[\s\u200e]*$)/g, '').replace(/[^\x20-\x7E]/g, '').replace(/\u200e/g, '').replace('‎', '').replace('&#x200e;', '').replace('(U+200E)', '').replace('U+200E', '').replace(/[^\x00-\x7F]/g, "").trim();
                // }
            }
        }
        if (arr.length > 0) {

            for (let i = 0; i < arr.length; i++) {
                let ErrorMsgTxt = "";
                if (arr[i].DrTitle !== undefined && !validOnlyCharalphabets.test(arr[i].DrTitle)) {
                    ErrorMsgTxt += "Invalid Dr Title" + " , ";
                    arr[i].ErrorFlag = "1";
                }
                if (arr[i].DoctorUniqueId === undefined || arr[i].DoctorUniqueId == "") {
                    ErrorMsgTxt += "Doctor UniqueId Required" + " , ";
                    arr[i].ErrorFlag = "1";
                }
                if (arr[i].DoctorUniqueId !== undefined && !ValidOnlyNumric.test(arr[i].DoctorUniqueId)) {
                    ErrorMsgTxt += "Invalid Doctor UniqueId" + " , ";
                    arr[i].ErrorFlag = "1";
                }
                if (arr[i].FirstName == undefined || arr[i].FirstName == "") {
                    ErrorMsgTxt += "First Name Required" + " , ";
                    arr[i].ErrorFlag = "1";
                }
                if (arr[i].FirstName !== undefined && !ValidOnlyCharName.test(arr[i].FirstName)) {
                    ErrorMsgTxt += "Invalid First Name" + " , ";
                    arr[i].ErrorFlag = "1";
                }
                if (arr[i].MiddleName !== undefined && !ValidOnlyMiddleCharName.test(arr[i].MiddleName)) {
                    ErrorMsgTxt += "Invalid Middle Name" + " , ";
                    arr[i].ErrorFlag = "1";
                }
                if (arr[i].LastName !== undefined && !ValidOnlyCharName.test(arr[i].LastName)) {
                    ErrorMsgTxt += "Invalid Last Name" + " , ";
                    arr[i].ErrorFlag = "1";
                }
                if (arr[i].PinCode !== undefined && !ValidOnlyNumric.test(arr[i].PinCode)) {
                    ErrorMsgTxt += "Invalid PinCode" + " , ";
                    arr[i].ErrorFlag = "1";
                }
                if ((arr[i].State == undefined || arr[i].State == "")) {
                    ErrorMsgTxt += "State Required" + " , ";
                    arr[i].ErrorFlag = "1";
                }
                if ((arr[i].State !== undefined && !validOnlyalphabets.test(arr[i].State))) {
                    ErrorMsgTxt += "Invalid State" + " , ";
                    arr[i].ErrorFlag = "1";
                }
                if ((arr[i].Region == undefined || arr[i].Region == "")) {
                    ErrorMsgTxt += "Region Required" + " , ";
                    arr[i].ErrorFlag = "1";
                }
                if ((arr[i].Region !== undefined && !validOnlyalphabets.test(arr[i].Region))) {
                    ErrorMsgTxt += "Invalid Region" + " , ";
                    arr[i].ErrorFlag = "1";
                }
                if ((arr[i].District == undefined || arr[i].District == "")) {
                    ErrorMsgTxt += "District Required" + " , ";
                    arr[i].ErrorFlag = "1";
                }
                if ((arr[i].District == undefined || arr[i].District == "") || (arr[i].District !== undefined && !validOnlyalphabets.test(arr[i].District))) {
                    ErrorMsgTxt += "Invalid District" + " , ";
                    arr[i].ErrorFlag = "1";
                }
                if ((arr[i].Specialization == undefined || arr[i].Specialization == "")) {
                    ErrorMsgTxt += "Specialization Required" + " , ";
                    arr[i].ErrorFlag = "1";
                }
                // if ((arr[i].Specialization !== undefined && !validOnlyalphabets.test(arr[i].Specialization))) {
                //     ErrorMsgTxt += "Invalid Specialization" + " , ";
                //     arr[i].ErrorFlag = "1";
                // }

                if ((arr[i].DrLocationState == undefined || arr[i].DrLocationState == "")) {
                    ErrorMsgTxt += "Invalid Doctor Location (State)" + " , ";
                    arr[i].ErrorFlag = "1";
                }

                if ((arr[i].DrLocationRegion == undefined || arr[i].DrLocationRegion == "")) {
                    ErrorMsgTxt += "Invalid Doctor Location (Region)" + " , ";
                    arr[i].ErrorFlag = "1";
                }
                if ((arr[i].DrLocationDistrict == undefined || arr[i].DrLocationDistrict == "")) {
                    ErrorMsgTxt += "Invalid Doctor Location (District)" + " , ";
                    arr[i].ErrorFlag = "1";
                }
                if ((arr[i].Modality1 !== undefined && !ValidOnlyNumric.test(arr[i].Modality1)) || (arr[i].Modality1 !== undefined  && arr[i].Modality1!="1") ) {
                    ErrorMsgTxt += "Invalid Modality1 UnquieId (Pathology)" + " , ";
                    arr[i].ErrorFlag = "1";
                }
                if ((arr[i].Modality2 !== undefined && !ValidOnlyNumric.test(arr[i].Modality2))|| (arr[i].Modality2 !== undefined && arr[i].Modality2!="2")) {
                    ErrorMsgTxt += "Invalid Modality2 UnquieId (Radiology)" + " , ";
                    arr[i].ErrorFlag = "1";
                }
                
                if ((arr[i].Modality3 !== undefined && !ValidOnlyNumric.test(arr[i].Modality3))||(arr[i].Modality3 !== undefined && arr[i].Modality3!="8")) {
                    ErrorMsgTxt += "Invalid Modality3 UnquieId (Nuclear Medicine)" + " , ";
                    arr[i].ErrorFlag = "1";
                }
                // if ((arr[i].Modality1 == undefined || arr[i].Modality1 == "") || (arr[i].Modality1 !== undefined && !ValidOnlyNumric.test(arr[i].Modality1))) {
                //     ErrorMsgTxt += "allow only vaild Modality1" + " , ";
                //     arr[i].ErrorFlag = "1";
                // }
                // if ((arr[i].Modality1SalesPersonEmpCode == undefined || arr[i].Modality1SalesPersonEmpCode == "") || (arr[i].Modality1SalesPersonEmpCode !== undefined && !validOnlyCharNum.test(arr[i].Modality1SalesPersonEmpCode))) {
                //     ErrorMsgTxt += "allow only vaild Modality1 SalesPersonEmpCode" + " , ";
                //     arr[i].ErrorFlag = "1";
                // }
                // if ((arr[i].Modality1SalesManagerEmpCode == undefined || arr[i].Modality1SalesManagerEmpCode == "") || (arr[i].Modality1SalesManagerEmpCode !== undefined && !validOnlyCharNum.test(arr[i].Modality1SalesManagerEmpCode))) {
                //     ErrorMsgTxt += "allow only vaild Modality1 SalesManagerEmpCode" + " , ";
                //     arr[i].ErrorFlag = "1";
                // }

                // if ((arr[i].Modality1SalesHeadEmpCode == undefined || arr[i].Modality1SalesHeadEmpCode == "") || (arr[i].Modality1SalesHeadEmpCode !== undefined && !validOnlyCharNum.test(arr[i].Modality1SalesHeadEmpCode))) {
                //     ErrorMsgTxt += "allow only vaild Modality1 SalesHeadEmpCode" + " , ";
                //     arr[i].ErrorFlag = "1";
                // }

                // if ((arr[i].Modality2== undefined || arr[i].Modality2=="")||(arr[i].Modality2!== undefined && !ValidOnlyNumric.test(arr[i].Modality2))) {
                //     ErrorMsgTxt += "allow only vaild Modality2" + " , ";
                //     arr[i].ErrorFlag = "1";
                // }
                // if ((arr[i].Modality2SalesPersonEmpCode== undefined || arr[i].Modality2SalesPersonEmpCode=="")||(arr[i].Modality2SalesPersonEmpCode!== undefined && !validOnlyCharNum.test(arr[i].Modality2SalesPersonEmpCode))) {
                //     ErrorMsgTxt += "allow only vaild Modality2 SalesPersonEmpCode" + " , ";
                //     arr[i].ErrorFlag = "1";
                // }
                // if ((arr[i].Modality2SalesManagerEmpCode== undefined || arr[i].Modality2SalesManagerEmpCode=="")||(arr[i].Modality2SalesManagerEmpCode!== undefined && !validOnlyCharNum.test(arr[i].Modality2SalesManagerEmpCode))) {
                //     ErrorMsgTxt += "allow only vaild Modality2 SalesManagerEmpCode" + " , ";
                //     arr[i].ErrorFlag = "1";
                // }

                // if ((arr[i].Modality2SalesHeadEmpCode== undefined || arr[i].Modality2SalesHeadEmpCode=="")||(arr[i].Modality2SalesHeadEmpCode!== undefined && !validOnlyCharNum.test(arr[i].Modality2SalesHeadEmpCode))) {
                //     ErrorMsgTxt += "allow only vaild Modality2 SalesHeadEmpCode" + " , ";
                //     arr[i].ErrorFlag = "1";
                // }

                // if ((arr[i].Modality3== undefined || arr[i].Modality3=="")||(arr[i].Modality3!== undefined && !ValidOnlyNumric.test(arr[i].Modality3))) {
                //     ErrorMsgTxt += "allow only vaild Modality3" + " , ";
                //     arr[i].ErrorFlag = "1";
                // }
                // if ((arr[i].Modality3SalesPersonEmpCode== undefined || arr[i].Modality3SalesPersonEmpCode=="")||(arr[i].Modality3SalesPersonEmpCode!== undefined && !validOnlyCharNum.test(arr[i].Modality3SalesPersonEmpCode))) {
                //     ErrorMsgTxt += "allow only vaild Modality3 SalesPersonEmpCode" + " , ";
                //     arr[i].ErrorFlag = "1";
                // }
                // if ((arr[i].Modality3SalesManagerEmpCode== undefined || arr[i].Modality3SalesManagerEmpCode=="")||(arr[i].Modality3SalesManagerEmpCode!== undefined && !validOnlyCharNum.test(arr[i].Modality3SalesManagerEmpCode))) {
                //     ErrorMsgTxt += "allow only vaild Modality3 SalesManagerEmpCode" + " , ";
                //     arr[i].ErrorFlag = "1";
                // }

                // if ((arr[i].Modality3SalesHeadEmpCode== undefined || arr[i].Modality3SalesHeadEmpCode=="")||(arr[i].Modality3SalesHeadEmpCode!== undefined && !validOnlyCharNum.test(arr[i].Modality3SalesHeadEmpCode))) {
                //     ErrorMsgTxt += "allow only vaild Modality3 SalesHeadEmpCode" + " , ";
                //     arr[i].ErrorFlag = "1";
                // }


                arr[i].ErrorDesc = ErrorMsgTxt.replace(/,\s*$/, "");
            }

            let duplicates = [];
            arr.forEach((el, i) => {
                arr.forEach((element, index) => {
                    if (i === index) return null;
                    if (element.DoctorUniqueId === el.DoctorUniqueId) {
                        if (!duplicates.includes(el.DoctorUniqueId)) {
                            duplicates.push(el.DoctorUniqueId);
                            if (el.ErrorDesc != "") {
                                el.ErrorDesc += "Duplicate Doctor UniqueId" + " , ";
                            }
                            else {
                                el.ErrorDesc = "Duplicate DoctorUniqueId" + " , ";
                            }
                            el.ErrorDesc = el.ErrorDesc;
                            //ErrorMsgTxt+="\n Invalid Contact Number2";
                            el.ErrorFlag = "1";
                            //DuplicateEmails +=el.Email+",";
                        }
                    }
                });
            });
            let duplicatesMobile = [];
            arr.forEach((el, i) => {
                arr.forEach((element, index) => {
                    if (i === index) return null;
                    if (element.Mobile === el.Mobile && element.Mobile !== null && element.Mobile != "") {
                        if (!duplicatesMobile.includes(el.Mobile)) {
                            duplicatesMobile.push(el.Mobile);
                            if (el.ErrorDesc != "") {
                                el.ErrorDesc += "Duplicate Doctor Mobile" + " , ";
                            }
                            else {
                                el.ErrorDesc = "Duplicate Doctor Mobile" + " , ";
                            }
                            el.ErrorDesc = el.ErrorDesc;
                            //ErrorMsgTxt+="\n Invalid Contact Number2";
                            el.ErrorFlag = "1";
                            //DuplicateEmails +=el.Email+",";
                        }
                    }
                });
            });
        }
        if (arr.length > 0) {

            for (let i = 0; i < arr.length; i++) {
                arr[i].ErrorDesc = arr[i].ErrorDesc.replace(/,\s*$/, "");
            }
        }
        const unique_id = uuid();
        const small_id = unique_id.slice(0, 8)
        let url = URL_Start + 'DoctorMaster/UploadDrBusinessMapData';
        const filearr = exlFileName.fileName.split('.');
        const NewFilename = filearr[0] + unique_id + '.' + filearr[1];

        const requestOptions = {
            "FileName": exlFileName.fileName,
            "CreatedBy": localStorage.LoggedInUser,
            "XLDynamicFileNAme": NewFilename,
            "DrBusinessCSVDataList": arr,
            "FileType":FileType
        };
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success && data.Data > 0) {
                SweetAlert.fire({ title: "Success", text: "File Upload successfully", icon: "success" });
                SetFileuploadStatus(false);
                localStorage.removeItem('ImpExcelId');
                localStorage.setItem('ImpExcelId',data.Data);
                SetExcelFID(data.Data);
                BindDrBusinessMapList(1, 10);
                
                //BindDrBusinessMapList(1, 2, data.Data);
            
                SetExcelID(data.Data);
                setIsLoading(false);
                handleUploadRemoveFile();
                //navigate("/Doctor_master/ValidateDrBusinessMapping", { state: { id: data.Data, importType: "1" } }); 
            }

            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { setIsLoading(false); });

    }



    const handleExport = () => {
        ValidateDownloadDrBusinessMapList(1, 10);
    }

    const ValidateDownloadDrBusinessMapList = async (PageNo, PageSize) => {
        try {
            let url = URL_Start + 'DoctorMaster/getDrBusinessMapDetails';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { PageNo: `${PageNo}`, PageSize: `${PageSize}`, ExcelID: `${localStorage.ImpExcelId == undefined ? "" : localStorage.ImpExcelId}`, DrExcelStatus: '1' }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success) {
                    if (data.Data != null && data.Data.ObjImportVaildDrBusinessMapList.length>0) { setDownloadVisible(true); setValidDrBusinessMaplistCSV(data.Data.ObjImportVaildDrBusinessMapList); document.getElementById("csvdownload").click(); } else { APIError(data.Data); }
                }
                else {
                    setDownloadVisible(false);
                }
            }
            else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); }
        } catch (error) {
            if (error.response) {
                ErrorFunction(error.response.status);
            }

        }
    }

    const ProcessedHideClick = async () => {
        SetProcessStatus(false);
        SetBtnspinnerLoadingFinal(false);
    }
    const ProcessedStartClick = async () => {
        //SetProcessStatus(true);
        toggleModal();
        SetBtnspinnerLoadingFinal(false);
    }
    const CancelHandlerClick = async () => {
        SetFileuploadStatus(true);
        await SetPageHelper({
            ...PageHelper,
            PageNo: Number(1),
            PageSize: Number(1),
            TotalRecord: 0,
            dsDrBusinessMaplists: []
        });

        // localStorage.removeItem('state');
        // localStorage.removeItem('ImportType');
        // SetProcessStatus(false);
        // SetBtnspinnerLoadingFinal(false);
        // try {
        //     let url=START_URL+ 'ViewLead/SetCancelImportCSVFinalStep';

        //     let options = {
        //         method: 'POST',
        //         url: url,
        //         headers: {
        //             'Accept': 'application/json',
        //             'Content-Type': 'application/json;charset=UTF-8'
        //         },
        //         data: { LID: `${param.LID}` }
        //     };

        //     let response = await axios(options);
        //         let responseOK = response && response.status == 200;
        //         if (responseOK) {
        //             let data = response.data;
        //             if(data.Success==true)
        //             {
        //                 navigate("/Leads/AddLeads"); 
        //             }
        //             else{
        //             }
        //         }
        //         else{
        //             console.log('no record found');

        //         }
        //      // return data;
        //     } catch (error) {
        //         console.log(error.message);
        //     }
    }
    const ProcessedFinalStep = async () => {

        SetIsProcessLoad(true);
        if (ExcelFID > 0) {
            try {
                let url = URL_Start + 'DoctorMaster/SetImportCSVFinalStep';

                let options = {
                    method: 'POST',
                    url: url,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                    data: { ExcelID: `${ExcelFID}`, AddedBy: `${LoginId}` }
                };

                let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                    let data = response.data;
                    if (data.Success == true) {
                        if (data.Data > 0) {
                            SetProcessStatus(false);
                            localStorage.removeItem('ExcelId');
                            localStorage.setItem('ExcelId', data.Data);
                            history.push({ pathname: `${process.env.PUBLIC_URL}/doctor-master/ValidateDrBusinessMapping`, state: { ExcelId: data.Data } });

                            return;
                        }
                        SetIsProcessLoad(false);
                    }
                    else {
                        SetIsProcessLoad(false);
                        setModal(false);
                        //  if(data.ErrorList!=null && data.ErrorList.length>0)
                        //  {

                        //     //setModal(false);
                        //     //SweetAlert.fire(''+data.ErrorList[0].errorMsg+'');

                        //  }
                        //SetspinnerLoading(false);
                    }
                }
                else {
                    SetIsProcessLoad(false);
                    setModal(false);
                    console.log('no record found');

                }
                // return data;
            } catch (error) {
                console.log(error.message);
                SetIsProcessLoad(false);
                setModal(false);

            }

        }

    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Data unavailable", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-2") { SweetAlert.fire({ title: "API Server Error", text: ErrorMsg, icon: "error" }); }
        else if (ErrorCode === "-3") { SweetAlert.fire({ title: "Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        else if (ErrorCode === "-4") { SweetAlert.fire({ title: "Wrong Credentials", text: "Please enter correct credentials", icon: "error" }) }
        else if (ErrorCode === "-5") { SweetAlert.fire({ title: "Oops", text: "Data could not be saved, Please try again later", icon: "error" }) }
        else if (ErrorCode === "-6") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
        else if (ErrorCode === "-7") { SweetAlert.fire({ title: "Duplicate", text: "Template already exists", icon: "error" }) }
        else if (ErrorCode === "-8") { SweetAlert.fire({ title: "Data unavailable", text: "No record found to import", icon: "error" }) }
        else if (ErrorCode === "-9") { SweetAlert.fire({ title: "LDAP authentication failed", text: ErrorMsg, icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    const handleRadioChange = async (e) => {
        let val = e.target.value;
        SetFileType(e.target.value);
         //alert(val);
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Doctor Business Mapping" parent="Master" />
            <Container fluid>
                {FileuploadStatus == true ? <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row className='justify-content-center'>
                                    <Col lg={8} md={8} className="col-8">
                                        <Form.Label>File Upload Type</Form.Label>
                                        <Form.Group className='animate-chk m-checkbox-inline mt-15'>
                                            <Form.Label for="centreType1">
                                                <input class="radio_animated" type="radio" id='centreType1' name="rdo-ani" defaultChecked onChange={handleRadioChange} value='1' />Create Doctor Business Mapping
                                            </Form.Label>
                                            <Form.Label for="centreType2">
                                                <input class="radio_animated" type="radio" id='centreType2' name="rdo-ani" onChange={handleRadioChange} value='2' />Update Doctor Business Mapping
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row className='justify-content-center'>
                                    <Col lg={6} md={6} className="col-7">
                                        <Form.Group>

                                            <InputGroup className="mb-3">
                                                <Form.Label>Upload file (.XLS or .XLSX or .CSV) <span className='text-red'>*</span></Form.Label>
                                                {/* <input type='file' accept=".xls,.xlsx,.csv"  onChange={(e) => handleNewFileUpload(e, "1")} /> */}
                                                <input type="file" accept=".xls,.xlsx,.csv" name="file" multiple={false} ref={fileUploadRef} onChange={(e) => handleUpload(e)} />
                                                <InputGroup.Append>
                                                    <Button variant='primary' type='submit' className="mr-2" onClick={uploadfile} onSubmit={handleSubmit} disabled={isLoading}>{isLoading ? <><LoadingButton /></> : null}Upload Now</Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={12} className='text-center'>
                                        {/* <Button variant='secondary' className="mr-2" onClick={handleSearch} >Search</Button> */}

                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row> : null}
                {FileuploadStatus == false ? <><Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Doctor Business Mapping List</Card.Title>
                                <div className='text-success text-center mt-2'>{FileNameDone} done. </div>
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading &&
                                    <div className='text-center'> <Spinner animation="border" size='sm' /></div>
                                }

                                <DynamicDataTables keyField="DoctorId" tableData={PageHelper.dsDrBusinessMaplists} columns={columns} pagination={paginationOptions ? paginationFactory(paginationOptions) : null} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

                    <Row >
                        <Col md={12}>
                            <Card> <Card.Body>

                                <div className='text-success text-center mt-2'> <span><i className="fa fa-check-circle" aria-hidden="true"></i></span> {CorrectRecord} Correct records and {ErrorRecord} Error records. </div>
                                <div className='text-center mt-4'>

                                    <button type="button" className="btn btn-primary" onClick={ProcessedStartClick} disabled={CorrectRecord == "0" ? true : false}> Proceed </button>
                                    &nbsp;&nbsp;<button type="button" className="btn btn-secondary" onClick={CancelHandlerClick}> Cancel </button>
                                    &nbsp;&nbsp;<button type="button" className="btn btn-secondary" onClick={handleExport} filename={"DoctorvalidateBusinessMappingList.csv"} target="_blank"> <i class="fa fa-file-excel-o" aria-hidden="true"></i> Download Csv </button>
                                </div>
                            </Card.Body></Card>
                        </Col>
                    </Row>
                    {
                        downloadvisible &&
                        <CSVLink data={DrValidBusinessMaplistCSV} filename="DoctorvalidateBusinessMappingList.csv" target="_blank" id="csvdownload"></CSVLink>
                    }
                </>

                    : null}

            </Container>
            {modal &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true} >
                    <ModalHeader toggle={toggleModal}>Are you sure ? </ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <Form.Row className="justify-content-center">
                                            <Col md={12}>
                                                <Form.Group>
                                                    <div className='text-success text-center'>
                                                        <span>Proceed with {CorrectRecord} records.</span></div>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>

                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Row className='text-center mt-2'>
                            <Col lg={12}>
                                <Button variant='primary' className="mr-2" onClick={ProcessedFinalStep} disabled={IsProcessLoad}>{IsProcessLoad ? <><LoadingButton /></> : null}  Proceed</Button>
                                {/* <Button variant="primary" onClick={deletedBtnHandler} >Processed</Button> */}
                            </Col>
                        </Row>
                        {/* <Button variant="primary" onClick={updateHandler}>Update</Button>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button> */}
                    </ModalFooter>
                </Modal>
            }
        </React.Fragment>
    )
}

export default DoctorBusinessMapping;
