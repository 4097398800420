
import React, { useState, useEffect, useRef,Fragment } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import EditToolTip from '../UI/ToolTips/EditToolTip';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip';
import ActiveToolTip from '../UI/ToolTips/ActiveToolTip';
import InactiveToolTip from '../UI/ToolTips/InactiveToolTip';
import { URL_Start } from '../../../constant';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';


import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import { Spinner } from 'react-bootstrap';


const HrmsSyncSystem = () => {
    const [isButton, setIsButton] = useState(false);
    const [CallTypeData, setCallTypeData] = useState(0);
    const[IsLoader,setIsLoader]=useState(false);
    const[ErrorPopUp, setErrorPopUp]=useState(0);
    const[PageHelper,SetPageHelper]=useState({
   PageNo:'1',
   PageSize:'10',
   TotalRecord:0,
    dsPurposeList:[]

    });
     const[PurposeDs, SetPurposeDs]=useState({
        editpurpose:"",
        editcallType:"",
        purposeId:""
     });
    
    const paginationOptions = {
        
        totalSize:PageHelper.TotalRecord,
        sizePerPage:PageHelper.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',

         onSizePerPageChange: (sizePerPage, page,event) => {
            console.log('Size per page change!!!');
             console.log('Newest size per page:' + sizePerPage);
             console.log('Newest page:' + page);
            // asyncFunBindPurposeDetails(page,sizePerPage);
             document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
         },
        onPageChange: (page, sizePerPage) => {
            console.log('Page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
           
            //asyncFunBindPurposeDetails(page,sizePerPage);
        }
    };
    
   
    useEffect(() => {
        //asyncFunCallTypeDDL();
    }, []);
      
   
  
  
  
      /********** Modals  ***********/
      const [modal, setModal] = useState();
      const toggleModal = () => {
          setModal(!modal)
      }
  
      const [errorModal, setErrorModal] = useState();
      const hrmsAPISyncSystem = async() => {
        setIsButton(true);
        try {
           
            let url=URL_Start+ 'hrms/gethrmspullrecords';
           
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
              data:{FromDate:'',ToDate:''}
            };
           
            let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                    let data = response.data;
                    // let data = await response.data;
                    if(data.Success==true)
                    { 
                        SweetAlert.fire({ title: "Success!", text: "hrms sync data has been successfully!", icon: "success" });
                        //asyncFunBindPurposeDetails(1,10);
                        setErrorModal(false);
                        setIsButton(false);
                    }
                    else{
                         if(data.ErrorList!=null && data.ErrorList.length>0)
                         {
                            let Errtext=<p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                            SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.');
                           
                         }
                         setIsButton(false);
                        
                    }
                   
                  
                }
                else{
                    console.log('no record found');
                    setIsButton(false);
                    
                }
             // return data;
            } catch (error) {
                console.log(error.message);
                if (error.response) { ErrorFunction(error.response.status);
                }
                setIsButton(false);
             }
      
            }
        
            const ErrorFunction = async (ErrorCode) => {
                if (ErrorCode == "400")
                    SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
                else if (ErrorCode == "401")
                    SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
                else if (ErrorCode == "403")
                    SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
                else if (ErrorCode == "404")
                    SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
                else if (ErrorCode == "500")
                    SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
                else if (ErrorCode == "503")
                    SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
            }    
return (
        <Fragment>
            <Breadcrumb title="HRMS Sync System" parent="Master" />
            <Container fluid>

                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                            <Col md={12} className='text-center'>

                            <Button variant='primary' type='submit'   onClick={hrmsAPISyncSystem} >{isButton?<Spinner animation="border" size='sm' />:null} Sync Data </Button>
                                        </Col>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>


             
            </Container>





          
        </Fragment>
    )
}

export default HrmsSyncSystem;