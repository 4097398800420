import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import SweetAlert from 'sweetalert2';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import LoadingButton from '../commonComponent/LoadingButton';
import DataTables from '../commonComponent/DataTable/DataTables';
import paginationFactory from 'react-bootstrap-table2-paginator';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import ToolTip from '../commonComponent/ToolTip';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const POSMachines = () => {
    const [posdetails, setPOSDetails] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [posmid, setPOSMID] = useState("0");
    useEffect(() => {
        GetPOSMachineDetails();
    }, [])

    const formik = useFormik({
        initialValues: {
            posmachinename: '', posimei: '', posmachinecode: '', posmachinestoreposcode: ''
        },
        validationSchema: Yup.object({
            posmachinename: Yup.string().required('POS machine name is required'),
            posimei: Yup.string().required('POS imei is required'),
            posmachinestoreposcode: Yup.string().required('POS machine store pos code is required')
        }),
        onSubmit: values => {
            SavePOSMachineDetails(values);
        },
    });
    const paginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <span>{crntPageNO + rowIndex + 1}</span>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <ToolTip title="Edit" placement="top" onClick={() => EditPOSMachineDetails(rowData)} linkId="Edit" faName="fa fa-pencil mr-2"></ToolTip>
                <ToolTip title="Delete" placement="top" onClick={() => DeletePOSMachine(rowData.POSMachineId)} linkId="Delete" faName="fa fa-trash mr-2"></ToolTip>
            </div>
        </React.Fragment>
    }
    const columns =
        [
            { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
            { dataField: 'POSMachineName', text: 'POS Machine Name', editable: false },
            { dataField: 'POSMachineCode', text: 'POS Machine Code', editable: false },
            { dataField: 'IMEI', text: 'IMEI', editable: false },
            { dataField: 'MerchantStorePosCode', text: 'Merchant Store POS Code', editable: false },
            { dataField: 'actions', isDummyField: true, text: 'Actions', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
        ];
    const GetPOSMachineDetails = async () => {
        let url = URL_Start + 'master/getposmachinedetails/'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data != null) {
                    setPOSDetails(data.Data);
                }
                else {
                    setPOSDetails([]);
                }
            }
            else {
                setPOSDetails([]);
                // SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); 
            }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const SavePOSMachineDetails = async (values) => {
        console.log(values);
        setIsSaving(true);
        const requestOptions = {
            POSMachineId: posmid,
            POSMachineName: values.posmachinename,
            POSMachineCode: values.posmachinecode,
            POSMachineIMEI: values.posimei,
            POSMachineMSPC: values.posmachinestoreposcode,
            AddedBy: localStorage.LoggedInUser
        };
        let url = URL_Start + 'master/saveposmachinedetails'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                setIsSaving(false);
                if(posmid !== "0"){SweetAlert.fire("POS machine updated successfully");}
                else{SweetAlert.fire("POS machine saved successfully");}
                GetPOSMachineDetails();
                cancelHandler();
            }
            else {
                if (data.Data == "-1998") { setIsSaving(false); SweetAlert.fire("POS machine already exists"); }
                else { setIsSaving(false); SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); }
            }
        }).catch(function (error) { setIsSaving(false); });
    }
    const EditPOSMachineDetails = async (rowData) => {
        formik.values.posmachinename = rowData.POSMachineName;
        formik.values.posmachinecode = rowData.POSMachineCode;
        formik.values.posimei = rowData.IMEI;
        formik.values.posmachinestoreposcode = rowData.MerchantStorePosCode;
        setPOSMID(rowData.POSMachineId);
    }
    const DeletePOSMachine = async (POSMachineId) => {
        SweetAlert.fire({
            title: 'Warning',
            text: "You are going to delete an item. Are you sure?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            cancelButtonColor: "#000",
            reverseButtons: true
        })
            .then((result) => {
                if (result.value) { ConfirmedDeletePOSMachine(POSMachineId); }
                else { SweetAlert.fire('Your data is safe!') }
            })
    }
    const ConfirmedDeletePOSMachine = async (POSMachineId) => {
        //setIsSaving(true);
        const requestOptions = {
            POSMachineId: POSMachineId,
            AddedBy: localStorage.LoggedInUser
        };
        let url = URL_Start + 'master/deleteposmachine'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                SweetAlert.fire({
                    text: "Deleted",
                    confirmButtonText: 'Ok',
                    reverseButtons: true
                })
                    .then((result) => {
                        if (result.value) { GetPOSMachineDetails(); cancelHandler(); }
                        else { GetPOSMachineDetails(); cancelHandler(); }
                    })
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsSaving(false); }
        }).catch(function (error) { setIsSaving(false); });
    }
    const cancelHandler = async () => {
        formik.values.posmachinename = "";
        formik.values.posmachinecode = "";
        formik.values.posimei = "";
        formik.values.posmachinestoreposcode = "";
        GetPOSMachineDetails();
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    return (
        <>
            <Breadcrumb title="POS Machine" parent="Master" />
            <Container fluid>
                <Form>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Header className='pb-0'>
                                    <Card.Title>POS Machine Details</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form.Row>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>POS Machine Name<span className='text-required'>*</span></Form.Label>
                                                <Form.Control type="text" name="posmachinename" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.posmachinename} />
                                                {formik.touched.posmachinename && formik.errors.posmachinename ? (<div className='text-required'>{formik.errors.posmachinename}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>POS Machine Code</Form.Label>
                                                <Form.Control type="text" name="posmachinecode" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.posmachinecode} />
                                                {formik.touched.posmachinecode && formik.errors.posmachinecode ? (<div className='text-required'>{formik.errors.posmachinecode}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>IMEI<span className='text-required'>*</span></Form.Label>
                                                <Form.Control type="text" name="posimei" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.posimei} />
                                                {formik.touched.posimei && formik.errors.posimei ? (<div className='text-required'>{formik.errors.posimei}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Merchant Store POS Code<span className='text-required'>*</span></Form.Label>
                                                <Form.Control type="text" name="posmachinestoreposcode" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.posmachinestoreposcode} />
                                                {formik.touched.posmachinestoreposcode && formik.errors.posmachinestoreposcode ? (<div className='text-required'>{formik.errors.posmachinestoreposcode}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                    <Col md={12} className='text-center'>
                                        <Form.Group>
                                            <Button variant='secondary' className="mr-2" onClick={cancelHandler}>Cancel</Button>
                                            {isSaving ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' type='submit' onClick={formik.handleSubmit}>Save</Button>}
                                        </Form.Group>
                                    </Col>
                                    <Form.Row>
                                        <Col md={12}>
                                            <DataTables keyField='POSMachineId' tableData={posdetails} columns={columns} pagination={paginationFactory(paginationOptions)} noDataIndication={NoRecordFounds} />
                                        </Col>
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </>
    )
}

export default POSMachines