import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import Breadcrumb from '../../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';

var glbRecords = [];
const RequiredFields = () => {
    const [tableData, setTableData] = useState([]);
    let textSearch = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [isSyncing, setIsSyncing] = useState(false);
    useEffect(() => { BindGrid(); }, []);
    const handleSearch = async () => {
        setTableData(glbRecords.filter((item) => item.FieldName.toLowerCase().indexOf(textSearch.current.value.toLowerCase()) !== -1));
    }
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; }  },
        { dataField: 'FieldName', text: 'Field Name', editable: false },
        { dataField: 'InputType', text: 'Input Type', editable: false },
        { dataField: 'IsUnit', text: 'Is Unit', editable: false  },
        { dataField: 'Unit', text: 'Unit', editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; }  },
        { dataField: 'DropDownOption', text: 'DropDown Option', editable: false }
    ];
    const BindGrid = async () => {
        setIsLoading(true);
        let url = URL_Start + 'master/getrequiredfieldsmasterlist'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                glbRecords = data.Data;
                setTableData(glbRecords);
                setIsLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const SyncRequiredFields = async () => {
        setIsSyncing(true);
        let url = URL_Start + 'LIS/getlisrequiredfieldsmaster'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                SweetAlert.fire({ title: "Success!", text: "Data synced successfully!", icon: "success" });
                setIsSyncing(false);
                BindGrid();
            }
            else { setIsSyncing(false); SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsSyncing(false); });
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Required Fields" parent="LIS Master" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row className="justify-content-center">
                                    <Col lg={4} md={6} className="col-8">
                                        <Form.Group>
                                            <InputGroup className="mb-3">
                                                <Form.Control onChange={handleSearch} placeholder="Search by Name..." ref={textSearch} />
                                                <InputGroup.Append>
                                                    <Button variant="secondary" onClick={handleSearch}><i className='fa fa-search'></i></Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2} md={6} className="col-4">
                                        <Form.Group>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Append>
                                                <Button variant='primary' type='submit' onClick={SyncRequiredFields} >{isSyncing ? <Spinner animation="border" size='sm' /> : null} Sync Data </Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Required Fields List</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <DataTables keyField='id' tableData={tableData} columns={columns} />
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default RequiredFields;