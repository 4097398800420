import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import Breadcrumb from '../../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import EditToolTip from '../UI/ToolTips/EditToolTip';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip';
import ViewToolTip from '../UI/ToolTips/ViewToolTip';
import { useHistory } from "react-router-dom";
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import paginationFactory from 'react-bootstrap-table2-paginator';

var glblData = [];
const CheckerCentre = () => {
    let regionId = useRef();
    let centrType = useRef();
    let centrName = useRef();
    let IsActive = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [tableData, setTableData] = useState([]);
    const [sapStatesList, setSAPStatesList] = useState([]);
    const [centreTypeList, setCentreTypeList] = useState(0);
    const [centreStatus, setCentreStatus] = useState("-1");
    const [centretypeStatus, setCentreTypeStatus] = useState("1");
    const [CentrelistCSV, setCentrelistCSV] = useState([]);
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [crntPageNO, setCrntPageNO] = useState(0);

    const paginationOptions = { sizePerPage: 50, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last', onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); } };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <ViewToolTip title="View" placement="top" onClick={() => editHandler(rowData.CentreId)} />
            </div>
        </React.Fragment>
    }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'StateName', text: 'State', editable: false },
        { dataField: 'TypeName', text: 'Partner Type', editable: false },
        { dataField: 'CentreName', text: 'Name', editable: false },
        { dataField: 'SAPCode', text: 'SAP Code', editable: false },
        { dataField: 'Address', text: 'Address', editable: false },
        { dataField: 'ContactPerson', text: 'Contact Person', editable: false },
        { dataField: 'CentreStatus', text: 'Is Active', editable: false },
        { dataField: 'CreateType', text: 'Create Type', editable: false },
        { dataField: '', text: 'Action', formatter: actionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    //GetCentreDetailsList("0", "0", "", "-1", "1");
    useEffect(() => { BindGrid("0", "0", "", "-1", "1"); GetSAPStates("1"); GetPartnerTypes("1"); }, []);
    const handleRadioChange = async (e) => { setCentreStatus(e.target.value); }
    const handleSearch = async () => {
        BindGrid(regionId.current.value, centrType.current.value, centrName.current.value.toLowerCase(), centreStatus, centretypeStatus)
    }
    const handleSearchList = async () => {
        GetCentreDetailList(regionId.current.value, centrType.current.value, centrName.current.value.toLowerCase(), centreStatus, centretypeStatus);
    }
    const GetSAPStates = async (id) => {
        try {
            let url = URL_Start + 'master/getstateslist/' + id
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setSAPStatesList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const BindGrid = async (rId, tId, cName, isActve, ctId) => {
        setIsLoading(true);
        const requestOptions = { StateId: rId.toString(), TypeId: tId.toString(), CentreName: cName, IsActive: isActve.toString(), CenterTypeId: ctId.toString() };
        let url = URL_Start + 'master/getcentremasterlist_checker'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data !== null) { setTableData(data.Data); glblData = data.Data } else { setTableData([]); }
                setIsLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const editHandler = async (CentreId) => {
        localStorage.setItem('cId', CentreId);
        history.push({ pathname: `${process.env.PUBLIC_URL}/master/centre-info-checker`, search: `` });
    }
    const newHandler = async () => {
        history.push({ pathname: `${process.env.PUBLIC_URL}/master/centre-info-checker`, search: `` });
        localStorage.removeItem('cId');
    }
    const Displayalert = (CentreId) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        })
            .then((result) => { if (result.value) { UpdateStatus(CentreId); } else { SweetAlert.fire('Your data is safe!'); } })
    }
    const UpdateStatus = async (id) => {
        setIsLoading(true);
        const requestOptions = { Id: id, ModifiedBy: localStorage.LoggedInUser, };
        let url = URL_Start + 'master/updatecentrestatus'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) { BindGrid("0", "0", "", "-1"); SweetAlert.fire('Data has been deleted!'); }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); }
            setIsLoading(false);
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const handleCenterTypeRadioChange = async (e) => {
        setSAPStatesList([]);
        GetSAPStates("1");
        GetPartnerTypes(e.target.value);
        BindGrid("0", "0", "", "-1", e.target.value);
        setCentreTypeStatus(e.target.value);
        centrName.current.value = "";
    }
    const GetPartnerTypes = async (ctId) => {
        try {
            let url = URL_Start + 'master/getpartnertypes/' + ctId;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setCentreTypeList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetCentreDetailList = async (rId, tId, cName, isActve, ctId) => {
        setIsLoading(true);
        try {
            let url = URL_Start + 'master/getCentreDetailList/' + rId + '/' + tId + '/' + (cName === "" ? "0" : cName) + '/' + isActve + '/' + ctId
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsLoading(false);
                    if (data.Data != null) { setDownloadVisible(true); setCentrelistCSV(data.Data); document.getElementById("csvdownload").click(); } else { APIError(data.Data); }
                }
                else { setDownloadVisible(false); setIsLoading(false); 
                    //APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); 
                }
            }).catch(function (error) { setIsLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
        } catch (error) { setIsLoading(false); APIError("-3"); }
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Data unavailable", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-2") { SweetAlert.fire({ title: "API Server Error", text: ErrorMsg, icon: "error" }); }
        else if (ErrorCode === "-3") { SweetAlert.fire({ title: "Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        else if (ErrorCode === "-4") { SweetAlert.fire({ title: "Wrong Credentials", text: "Please enter correct credentials", icon: "error" }) }
        else if (ErrorCode === "-5") { SweetAlert.fire({ title: "Oops", text: "Data could not be saved, Please try again later", icon: "error" }) }
        else if (ErrorCode === "-6") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
        else if (ErrorCode === "-7") { SweetAlert.fire({ title: "Duplicate", text: "Template already exists", icon: "error" }) }
        else if (ErrorCode === "-8") { SweetAlert.fire({ title: "Data unavailable", text: "No record found to import", icon: "error" }) }
        else if (ErrorCode === "-9") { SweetAlert.fire({ title: "LDAP authentication failed", text: ErrorMsg, icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Centre Checker" parent="Master" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row>
                                    <Col md={12}>
                                        <Form.Label className='mb-4'><b>Centre Type</b></Form.Label>
                                        <Form.Group className='animate-chk m-checkbox-inline center__type mt-15'>
                                            <Form.Label for="centreType1">
                                                <input class="radio_animated" type="radio" id='centreType1' name="rdoo-ani" defaultChecked onChange={handleCenterTypeRadioChange} value='1' />Own Centres
                                            </Form.Label>
                                            <Form.Label for="centreType2">
                                                <input class="radio_animated" type="radio" id='centreType2' name="rdoo-ani" onChange={handleCenterTypeRadioChange} value='2' />Third Party Centres
                                            </Form.Label>
                                            <Form.Label for="centreType5">
                                                <input class="radio_animated" type="radio" id='centreType5' name="rdoo-ani" onChange={handleCenterTypeRadioChange} value='3' />Panel
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>State</Form.Label>
                                            <Form.Control as="select" name="regionId" ref={regionId}>
                                                <option value="0">---Select---</option>
                                                {sapStatesList}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>Partner Type</Form.Label>
                                            <Form.Control as="select" name="centreType" ref={centrType}>
                                                <option value="0">---Select---</option>
                                                {centreTypeList}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>Centre Name</Form.Label>
                                            <Form.Control name="centreName" ref={centrName} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12}>
                                        <Form.Group className='animate-chk m-checkbox-inline mt-2'>
                                            <Form.Label for="rd1">
                                                <input class="radio_animated" type="radio" id='rd1' defaultChecked name="rdo-ani" onChange={handleRadioChange} value='-1' />All
                                            </Form.Label>
                                            <Form.Label for="rd2">
                                                <input class="radio_animated" type="radio" id='rd2' name="rdo-ani" onChange={handleRadioChange} value='1' />Active
                                            </Form.Label>
                                            <Form.Label for="rd3">
                                                <input class="radio_animated" type="radio" id='rd3' name="rdo-ani" onChange={handleRadioChange} value='0' />In-Active
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} className='text-center __center__buttonsec'>
                                        <Button variant='secondary' className="mr-2" onClick={handleSearch} >Search</Button>
                                        {/* <Button variant='primary' className="mr-2" type='submit' onClick={newHandler}>Add Centre</Button> */}
                                        <Button variant='primary' onClick={handleSearchList} filename={"CentreDetailList.csv"} target="_blank" >Download CSV</Button>
                                    </Col>
                                </Form.Row>
                                <Form.Row>

                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Centre List</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <DataTables
                                                keyField='CentreId'
                                                tableData={tableData}
                                                columns={columns}
                                                noDataIndication={NoRecordFounds}
                                                pagination={paginationFactory(paginationOptions)}
                                            />
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    downloadvisible &&
                    <CSVLink data={CentrelistCSV} filename="CentreDetailList.csv" target="_blank" id="csvdownload"></CSVLink>
                }
            </Container>
        </React.Fragment>
    )
}
export default CheckerCentre;