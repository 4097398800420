import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Dropdown } from 'react-bootstrap';
import { BackgroundColor, FrontDesk_URL, URL_Start, Right } from '../../../constant';
import Breadcrumb from '../../../components/common/breadcrumb';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import LoadingButton from '../commonComponent/LoadingButton';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Spinner } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';

var glblArr = [];
const GeneratePartB = () => {

    const [tableitems, setTableItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [updatereason, setUpdateReason] = useState([]);
    let reasoncode = useRef();
    let remark = useRef();
    let vehicleno = useRef();
    useEffect(() => {
        GetUpdateReason();
        GetAllActiveIRNDetails();
    }, [])

    const GetUpdateReason = async () => {
        setIsLoading(true);
        try {
            let url = FrontDesk_URL + 'master/getewaybillupdatereasoncode/'
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsLoading(false);
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) { setUpdateReason(ds.map((d) => (<option key={d.Id} value={d.Id}>{d.UpdateReason}</option>))); }
                    else { setUpdateReason([]); }
                }
                else { setUpdateReason([]); SweetAlert.fire("Some error occured. Check logs!"); return false; }
            }).catch(function (error) { setIsLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { setIsLoading(false); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }

    const GetAllActiveIRNDetails = async () => {
        setIsLoading(true);
        try {
            let url = FrontDesk_URL + 'master/getallactiveirndetails/'
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsLoading(false);
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) { setTableItems(ds); }
                    else { setTableItems([]); }
                }
                else { setTableItems([]); SweetAlert.fire("Some error occured. Check logs!"); return false; }
            }).catch(function (error) { setIsLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { setIsLoading(false); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }

    const paginationOptions = {
        sizePerPage: 50,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <input className="checkbox_animated" type="checkbox" onClick={(e) => { handleEditCheckbox(e, rowData); }} />
            </div>
        </React.Fragment>
    }
    const actionheaderFormatter = (column, colIndex) => {
        return <React.Fragment>
            <div>
                <input className="checkbox_animated" type="checkbox" onClick={(e) => { handleAllEditCheckbox(e, column); }} />
            </div>
        </React.Fragment>
    }
    const columns =
        [
            { dataField: '#', text: '#', editable: false, formatter: idFormatter },
            { dataField: '', text: 'Action', editable: false, formatter: actionFormatter },//, headerFormatter: actionheaderFormatter
            { dataField: 'IRNNo', text: 'IRN No', editable: false },
            { dataField: 'InvoiceNo', text: 'Invoice No', editable: false },
            { dataField: 'InvoiceDate', text: 'Invoice Date', editable: false },
            { dataField: 'PartyName', text: 'Party Name', editable: false },
            { dataField: 'PartyLocation', text: 'Location', editable: false },
            { dataField: 'StateCodeAlpha', text: 'Supplier State Code', editable: false }
        ];
    const handleEditCheckbox = (e, rawData) => {
        const checked = e.target.checked;
        const uniqueid = Math.random();
        if (checked) {
            glblArr.push({
                Id: rawData.Id,
                IRNNo: rawData.IRNNo,
                InvoiceNo: rawData.InvoiceNo,
                InvoiceDate: rawData.InvoiceDate,
                PartyName: rawData.PartyName,
                PartyLocation: rawData.PartyLocation,
                EwbNo: rawData.EwbNo,
                SupplierStateCodeAlpha: rawData.StateCodeAlpha,
                SupplierStateCode: rawData.StateCode,
                CompanyLocation: rawData.CompanyLocation,
                CompanyGSTIN: rawData.CompanyGSTIN,
                TransDocDate: rawData.TransDocDate.split("-")[2] + "/" + rawData.TransDocDate.split("-")[1] + "/" + rawData.TransDocDate.split("-")[0],
                UniqueId: uniqueid
            })
        }
        else {
            glblArr = glblArr.filter((d) => d.Id != rawData.Id)
        }
    };
    const handleAllEditCheckbox = async () => { }
    const GeneratePartBFunc = async () => {
        if (glblArr.length == 0) {
            SweetAlert.fire("Please select atleast one order to generate part b");
            return false;
        }
        if (vehicleno.current.value == "" || vehicleno.current.value == null || vehicleno.current.value == undefined || vehicleno.current.value == "0") {
            SweetAlert.fire("Vehicle no is mandatory");
            return false;
        }
        if (reasoncode.current.value == "" || reasoncode.current.value == null || reasoncode.current.value == undefined || reasoncode.current.value == "0") {
            SweetAlert.fire("Reason code is mandatory");
            return false;
        }
        const first2 = vehicleno.current.value.slice(0, 2);
        if (first2 != glblArr[0].SupplierStateCodeAlpha) {
            SweetAlert.fire("State code is invalid");
            return false;
        }
        setIsButtonLoading(true);
        const requestOptions = {
            VehicleNo: vehicleno.current.value,
            ReasonCode: reasoncode.current.value,
            ReasonRem: remark.current.value,
            TableData: JSON.stringify(glblArr)
        };
        let url = FrontDesk_URL + 'master/GeneratePartB'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsButtonLoading(false);
            if (data.Success) { SweetAlert.fire("Part b generated successfully"); }
            else { SweetAlert.fire({ title: "API Error!", text: "Some error occured! Please try after sometime.", icon: "error" }); }
        }).catch(function (error) { setIsButtonLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    return (
        <>
            <Breadcrumb title="Generate Part B" parent="Bulk Invoice" />
            <Form>
                <Container fluid>
                    {
                        isLoading == true ? <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                            <>
                                <div className="mt15">
                                    <BootstrapTable
                                        keyField='Id'
                                        data={tableitems}
                                        columns={columns}
                                        bootstrap4
                                        headerWrapperClasses="thead-light"
                                        bordered={false}
                                        noDataIndication={<NoRecordFounds />}
                                        pagination={paginationFactory(paginationOptions)}
                                    >
                                    </BootstrapTable>
                                </div>
                                {
                                    tableitems == [] || tableitems == null || tableitems.length == 0 ? null :
                                        <>
                                            <Form.Row>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Vehicle No <span className='text-red'>* (Max length 15 characters allowed)</span></Form.Label>
                                                        <Form.Control type="text" ref={vehicleno} maxLength={15} id="vehicleno" name="vehicleno" />
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Reason Code <span className='text-red'>*</span></Form.Label>
                                                        <Form.Control as="select" name="reasoncode" ref={reasoncode}>
                                                            <option key="0" value="0">---Select---</option>
                                                            {updatereason}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Reason Remark <span className='text-red'>(Max length 50 characters allowed)</span></Form.Label>
                                                        <Form.Control type="text" ref={remark} maxLength={50} id="remark" name="remark" />
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Form.Row>
                                                <Col md={12} className="mt-4 text-center">
                                                    {isButtonLoading ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' className="mr-2" onClick={GeneratePartBFunc}>Generate Part B</Button>}
                                                    <Button variant='secondary' className="mr-2" onClick={(e) => { e.preventDefault(); window.location.reload(false) }} >Refresh</Button>
                                                </Col>
                                            </Form.Row>
                                        </>
                                }
                            </>
                    }
                </Container>
            </Form>
        </>
    )
}

export default GeneratePartB