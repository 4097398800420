import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Row, Col, Card } from 'react-bootstrap';
import NewToolTip from '../UI/ToolTips/NewToolTip';


const BankPOSFlow = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [table, setTable] = useState([]);
    useEffect(() => {
        GetBankPOSFlowDetails();
    }, [])
    const PaginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.ReconcileStatus === "0" || rowData.ReconcileStatus === "False" ?
                        <>
                            <NewToolTip title="Reconcile" placement="top" id={`Reconcile${rowData.POSFlowId}`} icon="fa fa-check-square-o" onClick={(e) => ReconcileCheck(rowData.POSFlowId, "1")} />
                            &nbsp; <NewToolTip title="Reject" placement="top" id={`Reject${rowData.POSFlowId}`} icon="fa fa-close" onClick={(e) => ReconcileCheck(rowData.POSFlowId, "2")} />
                        </> :
                        rowData.ReconcileStatus === "2" ?
                            <div>Rejected</div>
                            :
                            <div>Reconciled</div>
                }
            </div>
        </React.Fragment>
    }
    const actionImageFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <NewToolTip title="Download Uploaded Documents" id={`Documents${rowData.POSFlowId}`} placement="top" icon="fa fa-download" onClick={() => ShowAllDocuments(rowData.ImageName)} />
                {/* <img src={rowData.ImageName} width="80"></img> */}
            </div>
        </React.Fragment>
    }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'TransferToBank', text: 'Transfer By', editable: false },
        { dataField: 'Bank', text: 'Transfer To', editable: false },
        { dataField: 'Amount', text: 'Amount', editable: false },
        { dataField: 'ImageName', text: 'Bank Receipt', editable: false, formatter: actionImageFormatter },
        { dataField: 'ReferenceNo', text: 'Reference No', editable: false },
        { dataField: 'ReconsileBy', text: 'Reconcile By', editable: false },
        { dataField: 'ReconsileOn', text: 'Reconcile On', editable: false },
        { dataField: 'EntryDateTime', text: 'Entry Date Time', editable: false },
        { dataField: 'actions', isDummyField: true, text: 'Actions', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } }
    ];
    const GetBankPOSFlowDetails = async () => {
        setIsLoading(true);
        try {
            let url = URL_Start + 'master/GetBankPOSFlowDetails/'
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsLoading(false);
                if (data.Success) {
                    if (data.Data === null) { setTable([]); }
                    else { setTable(data.Data); }
                }
                else {
                    setTable([]);
                }
            }).catch(function (error) { setIsLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { setIsLoading(false); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const ReconcileCheck = async (POSFlowId, Flag) => {
        if (Flag === "1") {
            SweetAlert.fire({
                title: 'You are going to reconcile a record. Are you sure?',
                text: "Once reconciled, you will not be able to change!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => { if (result.value) { ReconsileBankAmount(POSFlowId, Flag); } else { SweetAlert.fire('Not reconciled') } })
        }
        else {
            SweetAlert.fire({
                title: 'You are going to reject a record. Are you sure?',
                text: "Once rejected, you will not be able to change!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => { if (result.value) { ReconsileBankAmount(POSFlowId, Flag); } else { SweetAlert.fire('Not rejected') } })
        }
    }
    const ReconsileBankAmount = async (POSFlowId, Flag) => {
        const requestOptions = {
            POSFlowId: POSFlowId,
            LoginId: localStorage.getItem('LoggedInUser'),
            StatusFlag: Flag
        };
        let url = URL_Start + 'master/ReconsilePOSBankAmount'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (Flag === "1") { SweetAlert.fire({ text: "Reconciled", icon: "success" }); }
                else { SweetAlert.fire({ text: "Rejected", icon: "error" }); }
                GetBankPOSFlowDetails();
            }
            else {
                if (data.ErrorList[0].errorCode == -2000 || data.Data == -2000) { SweetAlert.fire({ title: "API Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
                else if (data.ErrorList[0].errorCode == -1 || data.Data == -1) {
                    SweetAlert.fire({
                        title: 'Already reconciled',
                        text: "Already reconciled",
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    }).then((result) => { if (result.value) { window.location.reload(false); } else { window.location.reload(false); } })
                }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
            }
        }).catch(function (error) {
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const ShowAllDocuments = async (imagename) => {
        if (imagename === "") { SweetAlert.fire("No documents available"); }
        else {
            var preurl = imagename;
            var prename = "Bank Slips";
            downloadFile(preurl, prename);
        }
    }
    function downloadFile(url, fileName) {
        const aElement = document.createElement('a');
        aElement.setAttribute('download', fileName);
        aElement.href = url;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(url);
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    return (
        <>
            <Breadcrumb title="Bank POS Flow" parent="Accounts" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Bank POS Flow Details</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <div className="table-responsive mt15">
                                            <DataTables
                                                keyField='POSFlowId'
                                                tableData={table}
                                                columns={columns}
                                                noDataIndication={NoRecordFounds}
                                                pagination={paginationFactory(PaginationOptions)}
                                            />
                                        </div>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default BankPOSFlow;