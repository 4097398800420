
import React, { useState, useEffect, useRef,Fragment } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { URL_Start,GlobalBusiness } from '../../../constant';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import InputGroup from 'react-bootstrap/InputGroup';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Spinner } from 'react-bootstrap';
import DynamicDataTables from '../commonComponent/DataTable/DynamicDataTable';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolTip from '../UI/ToolTips/ToolTip';
const CACHE = {};

const AddDoctorMergingDetails = () => {
    let textSearch = useRef();
    let typeaheadAsycDoctorMerge = useRef(null);  
    let typeaheadAsycDoctor = useRef(null);  
    const history = useHistory();
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const[ErrorPopUp, setErrorPopUp]=useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingMerge, setIsLoadingMerge] = useState(false);
    const[EmployeeDLists,SetEmployeeDLists]=useState(null);
    const[EmployeeMergeDLists,SetEmployeeMergeDLists]=useState(null);
const[DoctorFList,SetDoctorFList]=useState([]);
const[DoctorFListMerge,SetDoctorFListMerge]=useState([]);
const[IsShowEmp,SetIsShowEmp]=useState(false);
const[IsMergeShowEmp,SetIsMergeShowEmp]=useState(false);
const[IsShowMrgDr,SetIsShowMrgDr]=useState(false);
const[FirstDoctorSelection,SetFirstDoctorSelection]=useState("");
const[OrgDoctorSelection,SetOrgDoctorSelection]=useState("");

const [PageHelper, SetPageHelper] = useState({
    PageNo: '1',
    PageSize: '50',
    TotalRecord: 0,
    dsDoctorDlists: []

});

const [PageHelperMerge, SetPageHelperMerge] = useState({
    PageNo: '1',
    PageSize: '50',
    TotalRecord: 0,
    dsDoctorDlists: []

});
   
   /********** Modals  ***********/
   const [modal, setModal] = useState();
   const toggleModal = () => {
       setModal(!modal)
   }

   const [errorModal, setErrorModal] = useState();
   const errorHandler = () => {
        setModal(true); setErrorModal(true);
        formik.handleSubmit();
   
   }


   


   /********** Validations  ***********/
   const formik = useFormik({
    initialValues: {
      Employee:'0',
      LDoctor:'0',
      LDoctorMerge:'0'
      
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
             LDoctor:Yup.string().transform(v => v=== '0' ? '' : v)
             .required('Merge Doctor is Required'),
             LDoctorMerge:Yup.string().transform(v => v=== '0' ? '' : v)
             .required('Merge with Doctor is Required'),  
    }),
       isSubmitting:true,
       onSubmit:  async (values,actions) => {
        setModal(false);
        setErrorModal(false);
        setErrorPopUp("");
        try {
           
          let url=URL_Start+ 'doctormaster/SETInsertUpdateDoctorMergeDetails';
         
          let options = {
              method: 'POST',
              url: url,
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json;charset=UTF-8'
              },
            data:{OriginalDrId:`${OrgDoctorSelection}`,MergeDrId:`${FirstDoctorSelection}`, AddedBy:`${LoginId}`}
          };
         
        
         let response = await axios(options);
             let responseOK = response && response.status == 200;
             if (responseOK) {
                 let data = response.data;
                 // let data = await response.data;
                 if(data.Success==true && data.Data=="2000")
                 {    formik.resetForm();
                    SetEmployeeDLists(null);
                    SetEmployeeMergeDLists(null);
                    SetDoctorFList([]);
                    SetDoctorFListMerge([]);
                    SetIsShowMrgDr(false);
                    SetIsMergeShowEmp(false);
                    SetEmployeeMergeDLists(null);
                    document.getElementById("MergeChk1").checked = false;
                     SetIsShowEmp(false);
                   SetFirstDoctorSelection("");
                   SetOrgDoctorSelection("");
                history.push({ pathname: `${process.env.PUBLIC_URL}/doctor-master/doctorMergingDetails`, search: `` }); 
                     SweetAlert.fire({ title: "Success!", text: "Doctor has been Merged", icon: "success" });
                     setErrorModal(false);
                    

                 }
               
                 else{
                      if(data.ErrorList!=null && data.ErrorList.length>0)
                      {
                         let Errtext=<p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                         SweetAlert.fire({ title: "Error", text:data.ErrorList[0].errorMsg, icon: "error" });
                      }
                     
                 }
                
               
             }
             else{
                 console.log('no record found');
                 
             }
         } catch (error) {
             console.log(error.message);
             let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
             SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
          }
          setTimeout(() => {
           
             actions.setSubmitting(false);
             }, 1000);
            
       },
   });
   
       
   function onKeyDown(keyEvent) {
       if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
         keyEvent.preventDefault();
       }
     }



const PrevBckHandler= async()=>{
   formik.resetForm();
 SetEmployeeDLists(null);
 SetEmployeeMergeDLists(null);
 SetDoctorFList([]);
 SetDoctorFListMerge([]);
 SetIsShowMrgDr(false);
 SetIsMergeShowEmp(false);
 SetEmployeeMergeDLists(null);
 document.getElementById("MergeChk1").checked = false;
  SetIsShowEmp(false);
SetFirstDoctorSelection("");
SetOrgDoctorSelection("");
 typeaheadAsycDoctor.current._instance.clear();
 typeaheadAsycDoctorMerge.current._instance.clear();
 history.push({ pathname: `${process.env.PUBLIC_URL}/doctor-master/doctorMergingDetails`, search: `` }); 
}


const asyncFundoctorFilterDetails =async(query)=> {
 BindGrid(1,50,query);
}

const BindGrid = async(PageNo, PageSize,query)=>
{
 try {
   setIsLoading(true);
     let url=URL_Start+ 'DoctorMaster/getdoctorfiltermappeddetails';
     let options = {
         method: 'POST',
         url: url,
         headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json;charset=UTF-8'
         },
         data: { PageNo: `${PageNo}`, PageSize: `${PageSize}`,SearchTxt:query}
     };
     let response = await axios(options);
         let responseOK = response && response.status == 200;
         if (responseOK) {
             let data = response.data;
             // let data = await response.data;
             if(data.Success==true)
             { let ds=data.Data;
                 setIsLoading(false);
                 if(ds!=null && ds.ObjBindDoctorRecordList!=null && ds.ObjBindDoctorRecordList.length>0)
                 {
                     const TypeOptionDs=[];
                   
                 ds.ObjBindDoctorRecordList.map((option) =>{
                     const TypeOptions={
                         'id':option.DoctorId,
                         'name':option.CustDoctorName
                     }
                  return TypeOptionDs.push(TypeOptions);
                     });
                     CACHE[query]={...DoctorFList,TypeOptionDs, total_count:Number(ds.TotalRecord),page:Number(PageNo)};
                   
                     SetDoctorFList(TypeOptionDs);
                     //const options = cachedQuery.options.concat(resp.options);
                     await SetPageHelper({
                         ...PageHelper,
                          PageNo:Number(PageNo),
                         PageSize: Number(PageSize),
                         TotalRecord: Number(ds.TotalRecord),
                         dsDoctorDlists: ds.ObjBindDoctorRecordList==null ? [] : ds.ObjBindDoctorRecordList
                     }); 
                   
                                      
                 }
             }
             else{
                 setIsLoading(false);
                 let Errtext=<p><div className='text-required'> no record found </div></p>;
                 SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                 console.log('no record found');
                
             }
            
           
         }
         else{
             setIsLoading(false);
             let Errtext=<p><div className='text-required'> no record found </div></p>;
             SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
         }
      // return data;
     } catch (error) {
         setIsLoading(false);
         console.log(error.message);
         let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
         SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
      }

}
const [query, setQuery] = useState('');

const handleInputChange = (q) => {
 setQuery(q);
};

const handlePagination = (e, shownResults) => {
const cachedQuery = CACHE[query];

 if (
   cachedQuery.TypeOptionDs.length > shownResults ||
   cachedQuery.TypeOptionDs.length === cachedQuery.total_count
 ) {
   return;
 }
 const page = cachedQuery.page + 1;
 const pagesize=PageHelper.PageSize;
 BindGridAFTER(page,pagesize,query,cachedQuery);
};

const BindGridAFTER = async(PageNo, PageSize,query,cachedQuery)=>
{
 try {
     setIsLoading(true);
     let url=URL_Start+ 'DoctorMaster/getdoctorfiltermappeddetails';
     let options = {
         method: 'POST',
         url: url,
         headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json;charset=UTF-8'
         },
         data: { PageNo: `${PageNo}`, PageSize: `${PageSize}`,SearchTxt:query}
     };
     let response = await axios(options);
         let responseOK = response && response.status == 200;
         if (responseOK) {
             let data = response.data;
             // let data = await response.data;
             if(data.Success==true)
             { let ds=data.Data;
                 setIsLoading(false);
                 if(ds!=null && ds.ObjBindDoctorRecordList!=null &&  ds.ObjBindDoctorRecordList.length>0)
                 {
                     let TypeOptionDs=[];
                    
                 ds.ObjBindDoctorRecordList.map((option) =>{
                     const TypeOptions={
                         'id':option.DoctorId,
                         'name':option.CustDoctorName
                     }
                  return TypeOptionDs.push(TypeOptions);
                     });
                     //CACHE[query]={...DoctorFList,TypeOptionDs, total_count:Number(ds.TotalRecord),page:Number(PageNo)};
                     
                      //TypeOptionDs=[].cachedQuery.TypeOptionDs.concat(TypeOptionDs);
                      TypeOptionDs=[].concat(cachedQuery.TypeOptionDs,TypeOptionDs);
                     const page=PageNo;
                     CACHE[query] = { ...cachedQuery, TypeOptionDs, page };
               

                     SetDoctorFList(TypeOptionDs);
                     //const options = cachedQuery.options.concat(resp.options);
                     await SetPageHelper({
                         ...PageHelper,
                          PageNo:Number(PageNo),
                         PageSize: Number(PageSize),
                         TotalRecord: Number(ds.TotalRecord),
                         dsDoctorDlists: ds.ObjBindDoctorRecordList==null ? [] : ds.ObjBindDoctorRecordList
                     }); 
                   
                                      
                 }
             }
             else{
                 setIsLoading(false);
                 let Errtext=<p><div className='text-required'> no record found </div></p>;
                 SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                 console.log('no record found');
             }
            
           
         }
         else{
             setIsLoading(false);
             let Errtext=<p><div className='text-required'> no record found </div></p>;
             SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
         }
      // return data;
     } catch (error) {
         setIsLoading(false);
         console.log(error.message);
         let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
         SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
      }

}
const ErrorFunction = async (ErrorCode) => {
    if (ErrorCode == "400")
        SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
    else if (ErrorCode == "401")
        SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
    else if (ErrorCode == "403")
        SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
    else if (ErrorCode == "404")
        SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
    else if (ErrorCode == "500")
        SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
    else if (ErrorCode == "503")
        SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
   }
   const handleChange = (option) => {
    
    
    const value = (option.length > 0) ?option[0].name : '';
    const idval = (option.length > 0) ?option[0].id : '';
     if(idval!='')
     {
        
      SetFirstDoctorSelection(idval);
      SetIsShowEmp(true);
      asyncFunEmpDetails(idval);
     }
     else{
        SetIsShowEmp(false);
        SetFirstDoctorSelection("");
     }
    formik.setFieldValue('LDoctor', idval);
            
   }
   const asyncFunEmpDetails =async(DoctorId)=> {
    try {
    let url=URL_Start+ 'master/getBindEmployeeDetailbyDrId';
    let options = {
        method: 'POST',
        url: url,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {DoctorId:`${DoctorId}`
    }
    };
    let response = await axios(options);
        let responseOK = response && response.status == 200;
        if (responseOK) {
            let data = response.data;
            // let data = await response.data;
            if(data.Success==true)
            { let ds=data.Data;
                if(ds!=null)
                {       
             SetEmployeeDLists(ds);                 
                }
                 else{
                    SetEmployeeDLists(null);
                 }
            }
            else{
                SetEmployeeDLists(null);
                let Errtext=<p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                console.log('no record found');
            }
        }
        else{

            let Errtext=<p><div className='text-required'> no record found </div></p>;
            SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
        }
     // return data;
    } catch (error) {
        console.log(error.message);
        let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
        SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
     }
  }
  
  const CheckHandler = (e) => {
    const value = e.target.value;
     const isChecked =e.target.checked;

         
          if(isChecked==true)
          {
            document.getElementById("MergeChk1").checked = true
            SetIsShowMrgDr(true);
          }
           else
           {
            SetIsShowMrgDr(false);
            SetIsMergeShowEmp(false);
            SetEmployeeMergeDLists(null);
            document.getElementById("MergeChk1").checked = false;
            
           }
 
 
     };

    
const asyncFundoctorFilterDetailsMerge =async(query1)=> {
    BindGridMergeDr(1,50,query1);
   }
const BindGridMergeDr= async(PageNo, PageSize,query1)=>
{
 try {
    setIsLoadingMerge(true);
     let url=URL_Start+ 'DoctorMaster/getdoctorfilterMergeDetailDr';
     let options = {
         method: 'POST',
         url: url,
         headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json;charset=UTF-8'
         },
         data: { PageNo: `${PageNo}`, PageSize: `${PageSize}`,SearchTxt:query1,DoctorId:`${FirstDoctorSelection}`}
     };
     let response = await axios(options);
         let responseOK = response && response.status == 200;
         if (responseOK) {
             let data = response.data;
             // let data = await response.data;
             if(data.Success==true)
             { let ds=data.Data;
                setIsLoadingMerge(false);
                 if(ds!=null && ds.ObjBindDoctorRecordList!=null && ds.ObjBindDoctorRecordList.length>0)
                 {
                     const TypeOptionDs=[];
                   
                 ds.ObjBindDoctorRecordList.map((option) =>{
                     const TypeOptions={
                         'id':option.DoctorId,
                         'name':option.CustDoctorName
                     }
                  return TypeOptionDs.push(TypeOptions);
                     });
                     CACHE[query1]={...DoctorFListMerge,TypeOptionDs, total_count:Number(ds.TotalRecord),page:Number(PageNo)};
                   
                     SetDoctorFListMerge(TypeOptionDs);
                     //const options = cachedQuery.options.concat(resp.options);
                     await SetPageHelperMerge({
                         ...PageHelperMerge,
                          PageNo:Number(PageNo),
                         PageSize: Number(PageSize),
                         TotalRecord: Number(ds.TotalRecord),
                         dsDoctorDlists: ds.ObjBindDoctorRecordList==null ? [] : ds.ObjBindDoctorRecordList
                     }); 
                   
                                      
                 }
             }
             else{
                setIsLoadingMerge(false);
                 let Errtext=<p><div className='text-required'> no record found </div></p>;
                 SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                 console.log('no record found');
                
             }
            
           
         }
         else{
            setIsLoadingMerge(false);
             let Errtext=<p><div className='text-required'> no record found </div></p>;
             SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
         }
      // return data;
     } catch (error) {
        setIsLoadingMerge(false);
         console.log(error.message);
         let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
         SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
      }

}
const [query1, setQuery1] = useState('');

const handleInputChange1 = (q) => {
 setQuery1(q);
};

const handlePagination1 = (e, shownResults) => {
const cachedQuery1 = CACHE[query1];

 // Don't make another request if:
 // - the cached results exceed the shown results
 // - we've already fetched all possible results
 if (
   cachedQuery1.TypeOptionDs.length > shownResults ||
   cachedQuery1.TypeOptionDs.length === cachedQuery1.total_count
 ) {
   return;
 }

 const page = cachedQuery1.page + 1;
 const pagesize=PageHelperMerge.PageSize;
 BindGridAFTERMergeDr(page,pagesize,query1,cachedQuery1);
};

const BindGridAFTERMergeDr = async(PageNo, PageSize,query1,cachedQuery1)=>
{
 try {
    setIsLoadingMerge(true);
     let url=URL_Start+ 'DoctorMaster/getdoctorfilterMergeDetailDr';
     let options = {
         method: 'POST',
         url: url,
         headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json;charset=UTF-8'
         },
         data: { PageNo: `${PageNo}`, PageSize: `${PageSize}`,SearchTxt:query1,DoctorId:`${FirstDoctorSelection}`}
     };
     let response = await axios(options);
         let responseOK = response && response.status == 200;
         if (responseOK) {
             let data = response.data;
             // let data = await response.data;
             if(data.Success==true)
             { let ds=data.Data;
                setIsLoadingMerge(false);
                 if(ds!=null && ds.ObjBindDoctorRecordList!=null &&  ds.ObjBindDoctorRecordList.length>0)
                 {
                     let TypeOptionDs=[];
                    
                 ds.ObjBindDoctorRecordList.map((option) =>{
                     const TypeOptions={
                         'id':option.DoctorId,
                         'name':option.CustDoctorName
                     }
                  return TypeOptionDs.push(TypeOptions);
                     });
                     TypeOptionDs=[].concat(cachedQuery1.TypeOptionDs,TypeOptionDs);
                     const page=PageNo;
                     CACHE[query] = { ...cachedQuery1, TypeOptionDs, page };
               

                     SetDoctorFListMerge(TypeOptionDs);
                     //const options = cachedQuery.options.concat(resp.options);
                     await SetPageHelperMerge({
                         ...PageHelperMerge,
                          PageNo:Number(PageNo),
                         PageSize: Number(PageSize),
                         TotalRecord: Number(ds.TotalRecord),
                         dsDoctorDlists: ds.ObjBindDoctorRecordList==null ? [] : ds.ObjBindDoctorRecordList
                     }); 
                   
                                      
                 }
             }
             else{
                setIsLoadingMerge(false);
                 let Errtext=<p><div className='text-required'> no record found </div></p>;
                 SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                 console.log('no record found');
             }
            
           
         }
         else{
            setIsLoadingMerge(false);
             let Errtext=<p><div className='text-required'> no record found </div></p>;
             SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
         }
      // return data;
     } catch (error) {
        setIsLoadingMerge(false);
         console.log(error.message);
         let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
         SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
      }

}
const asyncFunEmpDetailsMerge =async(DoctorId)=> {
    try {
    let url=URL_Start+ 'master/getBindEmployeeDetailbyDrId';
    let options = {
        method: 'POST',
        url: url,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {DoctorId:`${DoctorId}`
    }
    };
    let response = await axios(options);
        let responseOK = response && response.status == 200;
        if (responseOK) {
            let data = response.data;
            // let data = await response.data;
            if(data.Success==true)
            { let ds=data.Data;
                if(ds!=null)
                {       
                    SetEmployeeMergeDLists(ds);                 
                }
                 else{
                    SetEmployeeMergeDLists(null);
                 }
            }
            else{
                SetEmployeeMergeDLists(null);
                let Errtext=<p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                console.log('no record found');
            }
        }
        else{

            let Errtext=<p><div className='text-required'> no record found </div></p>;
            SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
        }
     // return data;
    } catch (error) {
        console.log(error.message);
        let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
        SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
     }
  }
const handleChangeMerge = (option) => {
    
    
    const value = (option.length > 0) ?option[0].name : '';
    const idval = (option.length > 0) ?option[0].id : '';
     if(idval!='')
     {
        SetOrgDoctorSelection(idval);
      SetIsMergeShowEmp(true);
      asyncFunEmpDetailsMerge(idval);
     }
     else{
        SetIsMergeShowEmp(false);
        SetOrgDoctorSelection("");
     }
    formik.setFieldValue('LDoctorMerge',idval);
   }
   
 return (
     <Fragment>
 <Breadcrumb title="Doctor Merge Details" parent="Master" />
            <Container fluid>


             <Row>
                 <Col md={12}>
                     <Card>
                     <div class="pb-0 card-header"><div class="card-title h5">Add Doctor  Merge Detail</div></div>
                         <Card.Body>
                    
                         <Form.Row className="justify-content-center">
                                       
                                       <Col md={6} lg={6}>
                                               <Form.Group>
                                                               <Form.Label>Merge this Doctor<span className='text-required'>*</span></Form.Label>
                                                               {isLoading?<Spinner animation="border" size='sm' />:null} 
                                                               <AsyncTypeahead
                                                    id="LDoctor" name="LDoctor"
                                                    //clearButton
                                                    labelKey="name"
                                                   
                                                    
                                                    minLength={3}
                                                    maxResults={Number(PageHelper.PageSize)-1}
                                                    paginate
                                                    ref={typeaheadAsycDoctor}
                                                    onInputChange={handleInputChange}
                                                    onPaginate={handlePagination}
                                                    onSearch={asyncFundoctorFilterDetails}
                                                    options={DoctorFList}
                                                    value={formik.values.LDoctor}
                                                     // onChange is on valid option selection (a menu click)
               onChange= {handleChange}
                        onBlur={(e) => {
                            if(formik.values.LDoctor=='' || formik.values.LDoctor==null)
                        formik.setFieldTouched('LDoctor',true);
                            else
                            formik.errors.LDoctor="";

                        }}
                                                    placeholder="Location OR Doctor Name OR Doctor Mobile OR Specialization ....."
                                                />
                                                               {formik.touched.LDoctor && formik.errors.LDoctor ? (<div className='text-required'>{formik.errors.LDoctor}</div>) : null}
                                                           </Form.Group>
                                                           
                                          </Col>
                                        
                                       </Form.Row>
                                       {IsShowEmp &&<>{EmployeeDLists!=null?<>
                                        <Form.Row className="justify-content-center">
                                      
                                      <Col md={6} lg={6}>
                                          
                                      <Form.Group>
                                     <Form.Label>Salesman</Form.Label>
                                         <span className="form-control-plaintext" >{EmployeeDLists.CustUserName==''?'N/A':EmployeeDLists.CustUserName}</span>
                                   </Form.Group>
                                            
                                         </Col>
                                       
                                      </Form.Row>
                                       </>:<Form.Row className="justify-content-center">
                                      
                                      <Col md={6} lg={6}>
                                          
                                      <Form.Group>
                                     <Form.Label>Salesman</Form.Label>
                                         <span className="form-control-plaintext" >{'N/A'}</span>
                                   </Form.Group>
                                            
                                         </Col>
                                       
                                      </Form.Row>}<Form.Row className='justify-content-center'>
                                   
                                   <Col  md={6} lg={6}>

                                       <Form.Group>
                                           <Form.Label>

                                               <input name={"MergeChk"} className="checkbox_animated" type="checkbox"
                                                   value={0} id="MergeChk1" onChange={CheckHandler} />
                                               {'Merging with Doctor'}
                                           </Form.Label>

                                       </Form.Group>
                                   </Col>
                               </Form.Row></>}
                                      
                                       
                
                         </Card.Body>
                     </Card>
                 </Col>
             </Row> 

            {IsShowMrgDr &&<>
             <Row>
                 <Col md={12}>
                     <Card>
                         <Card.Body>
                    
                         <Form.Row className="justify-content-center">
                                       
                                       <Col md={6} lg={6}>
                                               <Form.Group>
                                                               <Form.Label>Merging with this Doctor<span className='text-required'>*</span></Form.Label>
                                                               {isLoadingMerge?<Spinner animation="border" size='sm' />:null} 
                                                               <AsyncTypeahead
                                                    id="LDoctorMerge" name="LDoctorMerge"
                                                    //clearButton
                                                    labelKey="name"
                                                   
                                                    
                                                    minLength={3}
                                                    maxResults={Number(PageHelperMerge.PageSize)-1}
                                                    paginate
                                                    ref={typeaheadAsycDoctorMerge}
                                                    onInputChange={handleInputChange1}
                                                    onPaginate={handlePagination1}
                                                    onSearch={asyncFundoctorFilterDetailsMerge}
                                                    options={DoctorFListMerge}
                                                    value={formik.values.LDoctorMerge}
                                                     // onChange is on valid option selection (a menu click)
               onChange= {handleChangeMerge}
                        onBlur={(e) => {
                            if(formik.values.LDoctorMerge=='' || formik.values.LDoctorMerge==null)
                        formik.setFieldTouched('LDoctorMerge',true);
                            else
                            formik.errors.LDoctorMerge="";

                        }}
                                                    placeholder="Location OR Doctor Name OR Doctor Mobile OR Specialization ....."
                                                />
                                                               {formik.touched.LDoctorMerge && formik.errors.LDoctorMerge ? (<div className='text-required'>{formik.errors.LDoctorMerge}</div>) : null}
                                                           </Form.Group>
                                                           
                                          </Col>
                                        
                                       </Form.Row>
                                       {IsMergeShowEmp &&<>{EmployeeMergeDLists!=null?<>
                                        <Form.Row className="justify-content-center">
                                      
                                      <Col md={6} lg={6}>
                                          
                                      <Form.Group>
                                     <Form.Label>Salesman</Form.Label>
                                         <span className="form-control-plaintext" >{EmployeeMergeDLists.CustUserName==''?'N/A':EmployeeMergeDLists.CustUserName}</span>
                                   </Form.Group>
                                            
                                         </Col>
                                       
                                      </Form.Row>
                                       </>:<Form.Row className="justify-content-center">
                                      
                                      <Col md={6} lg={6}>
                                          
                                      <Form.Group>
                                     <Form.Label>Salesman</Form.Label>
                                         <span className="form-control-plaintext" >{'N/A'}</span>
                                   </Form.Group>
                                            
                                         </Col>
                                       
                                      </Form.Row>}</>}
                         </Card.Body>
                     </Card>
                 </Col>
             </Row>         
             <Row className='mt15'>
                                 <Col md={12} className='text-center'>
                                     <Button variant='secondary' className="mr-2"  onClick={PrevBckHandler} >Cancel</Button>
                                     {<Button variant='primary' type='submit' onClick={errorHandler}>Save</Button>}
                                 </Col>
                             </Row></>} 
      
                             </Container>
       

         {errorModal &&
             <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                 <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                 <ModalBody>
                
                     <p>
                     {formik.touched.LDoctor && formik.errors.LDoctor ? (<div className='text-required'>{formik.errors.LDoctor}</div>) : null}
                     </p>
                     <p>
                     {formik.touched.LDoctorMerge && formik.errors.LDoctorMerge ? (<div className='text-required'>{formik.errors.LDoctorMerge}</div>) : null}
                     </p>
                 </ModalBody>
                 <ModalFooter>
                     <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                     <Button variant="primary" onClick={toggleModal}>Ok</Button>
                 </ModalFooter>
                 
             </Modal>
         }
     </Fragment>
 )
}

export default AddDoctorMergingDetails;