import React from "react";
import ReactToPrint from "react-to-print";
import CustomerCollectionReport from "../pdfPrints/CustomerCollectionReport";
import PrintDayEndReport from "../pdfPrints/PrintDayEndReport";

class PrintPDFReports extends React.Component {
    render() {
        const pdfProps = this.props;
        const apiData = pdfProps.APIData;
        const reportName = pdfProps.ReportName;
        return (
            <div>
                {reportName === "PrintDayEndReport" ? <PrintDayEndReport APIData={apiData} ref={(response) => (this.componentRef = response)} /> :
                    reportName === "CustomerCollection" ? <CustomerCollectionReport APIData={apiData} ref={(response) => (this.componentRef = response)} /> : null}
                <ReactToPrint content={() => this.componentRef} trigger={() => <button type="button" className="btn btn-primary">Print</button>} />
            </div>
        );
    }
}

export default React.memo(PrintPDFReports);