import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import SweetAlert from 'sweetalert2';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import LoadingButton from '../commonComponent/LoadingButton';

const ManualUsers = () => {

    const [modal, setModal] = useState();
    const toggleModal = () => { setModal(!modal) }
    const [errorModal, setErrorModal] = useState();
    const errorHandler = () => { setModal(true); setErrorModal(true); }
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);


    const formik = useFormik({
        initialValues: {
            name: '', email: '', mobileNo: '', branchcode: ''
        },
        validationSchema: Yup.object({
            name: Yup.string().max(250, 'User name must be 250 characters or less').required('User name is required'),
            email: Yup.string().email('Invalid email address').required('Email is required'),
            mobileNo: Yup.string().max(10, 'Mobile no must be  in 10 digits').min(10, 'Mobile No must be  in 10 digits').required('Mobile No is required'),
            branchcode: Yup.string().required('Branch code is required'),
        }),
        onSubmit: values => {
            setModal(false);
            setErrorModal(false);
            CheckManualUser(values);
        },
    });
    const cancelHandler = async () => {
        formik.resetForm();
    }
    const CheckManualUser = async (values) => {
        try {
            let url = URL_Start + 'master/checkmanualusers/' + values.name + '/' + values.mobileNo + '/' + values.email + '/' + values.branchcode
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data == null) { SweetAlert.fire("No record found or connectivity issue.Please check your network"); }
                    else {
                        if (data.Data.IsManualUserExist == "1") {
                            SweetAlert.fire({
                                title: 'Already Exist',
                                text: "This user is already exist.Do you want to make it available for mapping?",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonText: 'Yes',
                                reverseButtons: true
                            })
                                .then((result) => {
                                    if (result.value) { AddManualUser(values, data.Data.IsManualUserExist); }
                                    else { window.location.reload(false); }
                                })
                        }
                        else {
                            SweetAlert.fire({
                                title: 'Not Exist',
                                text: "This user does not exist.Do you want to create this user?",
                                icon: 'warning',
                                showCancelButton: true,
                                confirmButtonText: 'Yes',
                                reverseButtons: true
                            })
                                .then((result) => {
                                    if (result.value) { AddManualUser(values, data.Data.IsManualUserExist); }
                                    else { window.location.reload(false); }
                                })
                        }
                    }
                }
                else {
                    if (data.Data == null) { SweetAlert.fire("Some error occured. Check logs!") }
                    else { SweetAlert.fire("Exception is: " + data.Data); }
                }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) {
            SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const AddManualUser = async (values, flag) => {
        const ObjCollection = {
            UserId: localStorage.getItem('LoggedInUser'),
            Name: values.name,
            MobileNo: values.mobileNo,
            Email: values.email,
            BranchCode: values.branchcode,
            Flag: flag
        };
        try {
            let url = URL_Start + 'master/savemanualuser/'
            await axios.post(url, ObjCollection).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        SweetAlert.fire({
                            title: 'Success',
                            text: "Manual user added successfully",
                            icon: 'success',
                            showCancelButton: false,
                            confirmButtonText: 'Yes',
                            reverseButtons: true
                        })
                            .then((result) => {
                                if (result.value) { window.location.reload(false); }
                                else { window.location.reload(false); }
                            })
                    }
                    else { SweetAlert.fire("Some db error occured.Please report to support team."); }
                }
                else {
                    if (data.Data == null) { SweetAlert.fire("Some db error occured.Please report to support team."); }
                    else {
                        if (data.Data == "-1") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
                        else if (data.Data == "-2") { SweetAlert.fire({ title: "API Server Error", text: "Check Logs", icon: "error" }); }
                        else if (data.Data == "-3") { SweetAlert.fire({ title: "Duplicate", text: "User already exist", icon: "error" }); }
                    }
                }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Manual Users" parent="User Master" />
            <Container fluid>
                {
                    isLoading ?
                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                        <>
                            <Form onSubmit={formik.handleSubmit}>
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <Card.Header className='pb-0'>
                                                <Card.Title>Add Manual User</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form.Row>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Name<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="text" name="name" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.name} />
                                                            {formik.touched.name && formik.errors.name ? (<div className='text-required'>{formik.errors.name}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Mobile<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="text" name="mobileNo" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.mobileNo} />
                                                            {formik.touched.mobileNo && formik.errors.mobileNo ? (<div className='text-required'>{formik.errors.mobileNo}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Email<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="email" name="email" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} />
                                                            {formik.touched.email && formik.errors.email ? (<div className='text-required'>{formik.errors.email}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Branch Code<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="text" name="branchcode" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.branchcode} />
                                                            {formik.touched.branchcode && formik.errors.branchcode ? (<div className='text-required'>{formik.errors.branchcode}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className='mt15'>
                                    <Col md={12} className='text-center'>
                                        <Button variant='secondary' className="mr-2" onClick={cancelHandler}>Cancel</Button>
                                        {isSaving ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' type='submit' onClick={errorHandler}>Add User</Button>}
                                    </Col>
                                </Row>
                            </Form>
                        </>
                }
                {/******** Modal Popups ******* */}
                {errorModal &&
                    <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                        <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                        <ModalBody>
                            <p>{formik.touched.name && formik.errors.name ? (<div className='text-required'>{formik.errors.name}</div>) : null}</p>
                            <p>{formik.touched.email && formik.errors.email ? (<div className='text-required'>{formik.errors.email}</div>) : null}</p>
                            <p>{formik.touched.mobileNo && formik.errors.mobileNo ? (<div className='text-required'>{formik.errors.mobileNo}</div>) : null}</p>
                            <p>{formik.touched.branchcode && formik.errors.branchcode ? (<div className='text-required'>{formik.errors.branchcode}</div>) : null}</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                            <Button variant="primary" onClick={toggleModal}>Ok</Button>
                        </ModalFooter>
                    </Modal>
                }
            </Container>
        </React.Fragment >
    )
}

export default ManualUsers