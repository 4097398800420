import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import SweetAlert from 'sweetalert2';
import { Image_Path, URL_Start } from '../../../constant';
import axios from 'axios';
import LoadingButton from '../commonComponent/LoadingButton';
import DataTables from '../commonComponent/DataTable/DataTables';
import Select from 'react-select';
import paginationFactory from 'react-bootstrap-table2-paginator';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import ToolTip from '../commonComponent/ToolTip';


const Banners = () => {
    const [bannerPath, setBannerPath] = useState('');
    const [virtualcentre, setVirtualCentre] = useState([]);
    const [bannerdetails, setBannerDetails] = useState([]);
    const [stateSelected, setStateSelected] = useState([]);
    const [regionSelected, setRegionSelected] = useState([]);
    const [districtSelected, setDistrictSelected] = useState([]);
    const [centreSelected, setCentreSelected] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [editicon, setEditIcon] = useState(false);
    const [statename, setStateName] = useState('');
    const [regionname, setRegionName] = useState('');
    const [districtname, setDistrictName] = useState('');
    const [centrename, setCentreName] = useState('');
    const [locationstatelist, SetLocationStateList] = useState([]);
    const [locationregionlist, SetLocationRegionList] = useState([]);
    const [locationdistrictlist, SetLocationDistrictList] = useState([]);
    const [alltest, SetAllTest] = useState([]);
    const [allpackage, SetAllPackage] = useState([]);
    const [packageSelected, setPackageSelected] = useState([]);
    const [typeSelected, setTypeSelected] = useState([]);
    const [itemSelected, setItemSelected] = useState([]);
    const [statebannerref, setStateBannerRef] = useState("");
    const [urlbannerref, setUrlBannerRef] = useState("");
    let bannerref = useRef();
    let urlbanneref = useRef();
    useEffect(() => {
        GetAllStates();
        GetBannerDetails();
        GetAllPackageAndTestList();
    }, [])
    useEffect(() => {
        if (stateSelected.length === 0) {
            SetLocationRegionList([]);
            SetLocationDistrictList([]);
            setVirtualCentre([]);
            setRegionSelected([]);
            setDistrictSelected([]);
            setCentreSelected([]);
        }
        else { GetAllRegions(); }
    }, [stateSelected])
    useEffect(() => {
        if (regionSelected.length === 0) {
            SetLocationDistrictList([]);
            setDistrictSelected([]);
            setVirtualCentre([]);
            setCentreSelected([]);
        }
        else { GetAllDistricts(); }
    }, [regionSelected])
    useEffect(() => {
        if (districtSelected.length === 0) {
            setVirtualCentre([]);
            setCentreSelected([]);
        }
        else { GetAllCentreList(); }
    }, [districtSelected])
    useEffect(() => {
        if (statebannerref !== "") {
            bannerref.current.value = statebannerref;
            setStateBannerRef("");
        }
    }, [statebannerref])
    useEffect(() => {
        if (urlbannerref !== "") {
            urlbanneref.current.value = urlbannerref;
            setUrlBannerRef("");
        }
    }, [urlbannerref])

    useEffect(() => {
        if (bannerPath !== '') {
            var element = document.querySelector("input[type='file']")
            element.classList.add("removeValue");
        }
    }, [bannerPath])
    const paginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <span>{crntPageNO + rowIndex + 1}</span>
        </React.Fragment>
    }
    const actionBannerFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <img src={Image_Path + 'WebsiteBanners/' + rowData.Banner} width="80"></img>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <ToolTip title="Edit" placement="top" onClick={() => EditBannerDetails(rowData)} linkId="Edit" faName="fa fa-pencil mr-2"></ToolTip>
                <ToolTip title="Delete" placement="top" onClick={() => DeleteBanner(rowData.CentreBannersId)} linkId="Delete" faName="fa fa-trash mr-2"></ToolTip>
            </div>
        </React.Fragment>
    }
    const columns =
        [
            { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
            { dataField: 'DistrictName', text: 'Location', editable: false },
            { dataField: 'CentreName', text: 'Centre Name', editable: false },
            { dataField: 'BannerType', text: 'Banner Type', editable: false },
            { dataField: 'BannerName', text: 'Banner Name', editable: false },
            { dataField: 'Banner', text: 'Banner', editable: false, formatter: actionBannerFormatter },
            { dataField: 'actions', isDummyField: true, text: 'Actions', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
        ];
    const UploadImages = async (e, folderName) => {
        const FileURL = e.target.files[0].name;
        const BreakURL = FileURL.lastIndexOf('.');
        const extension = FileURL.substring(BreakURL + 1);
        //const extension = e.target.files[0].name.split(".");
        var base64 = await toBase64(e.target.files[0]);
        var base64img = base64.split("base64,");
        const formData = new FormData();
        formData.append('Image', base64img[1]);
        formData.append('FolderName', folderName);
        const randomImageId = Math.random();
        var imgName = randomImageId + folderName + "." + extension; //extension[extension.length - 1];
        formData.append('ImageName', imgName);
        let url = URL_Start + 'master/UploadImage/'
        await axios.post(url, formData, {
            headers: { "Content-type": "multipart/form-data" },
        }).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (folderName.toLowerCase() === "websitebanners") { setBannerPath(Image_Path + "WebsiteBanners/" + imgName); }
            } else { SweetAlert.fire({ title: "Upload failed", text: "Unable to upload image due to " + data.Data + ".Please retry again", icon: "error" }) }
        }).catch(function (error) { if (error.response) { } });
    }
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const StateChangeHandler = (options) => {
        var isBreak = "0";
        for (var key in options) {
            if (options[key].value === "0" && isBreak === "0") {
                isBreak = "1";
            }
        }
        if (isBreak === "1") {
            setStateSelected(locationstatelist);
        } else {
            setStateSelected(options);
        }
    }
    const RegionChangeHandler = (options) => {
        var isBreak = "0";
        for (var key in options) {
            if (options[key].value === "0" && isBreak === "0") {
                isBreak = "1";
            }
        }
        if (isBreak === "1") {
            setRegionSelected(locationregionlist);
        } else {
            setRegionSelected(options);
        }
    }
    const DistrictChangeHandler = (options) => {
        var isBreak = "0";
        for (var key in options) {
            if (options[key].value === "0" && isBreak === "0") {
                isBreak = "1";
            }
        }
        if (isBreak === "1") {
            setDistrictSelected(locationdistrictlist);
        } else {
            setDistrictSelected(options);
        }
    }
    const CentreChangeHandler = (options) => {
        var isBreak = "0";
        for (var key in options) {
            if (options[key].value === "0" && isBreak === "0") {
                isBreak = "1";
            }
        }
        if (isBreak === "1") {
            setCentreSelected(virtualcentre);
        } else {
            setCentreSelected(options);
        }
    }
    const PackageChangeHandler = (options) => {
        var packarr = [];
        packarr.push({
            value: options.value,
            label: options.label
        })
        setPackageSelected(packarr);
    }
    const TypeChangeHandler = (options) => {
        var typearr = [];
        typearr.push({
            value: options.value,
            label: options.label
        })
        setTypeSelected(typearr);
    }
    const ItemChangeHandler = (options) => {
        var itemarr = [];
        itemarr.push({
            value: options.value,
            label: options.label
        })
        setItemSelected(itemarr);
    }
    const GetAllStates = async () => {
        var glblArr = [];
        let url = URL_Start + 'master/getstateslist/1'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data != null) {
                    var itmData = data.Data;
                    itmData.map((option) => { return glblArr.push({ 'value': option.Id, 'label': option.Value, 'disabled': false }); });
                    SetLocationStateList(glblArr);
                }
                else {
                    SetLocationStateList([]);
                }
            }
            else { SweetAlert.fire({ title: "Error", text: "No record found", icon: "error" }); setIsSaving(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const GetAllRegions = async () => {
        var glblArr = [];
        let arryRegions = [];
        for (var i in stateSelected) { arryRegions.push({ 'StateId': stateSelected[i].value }); }
        const requestOptions = {
            objTblLocationTypeList: arryRegions
        };
        let url = URL_Start + 'master/getBindLocationRegionDetails'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data != null) {
                    var itmData = data.Data;
                    itmData.map((option) => { return glblArr.push({ 'value': option.RegionId, 'label': option.RegionName, 'disabled': false }); });
                    SetLocationRegionList(glblArr);
                }
                else {
                    SetLocationRegionList([]);
                }
            }
            else { SweetAlert.fire({ title: "Error", text: "No record found", icon: "error" }); setIsSaving(false); }
        }).catch(function (error) { setIsSaving(false); });
    }
    const GetAllDistricts = async () => {
        var glblArr = [];
        let arryDistrict = [];
        for (var i in regionSelected) { arryDistrict.push({ 'RegionId': regionSelected[i].value }); }
        const requestOptions = {
            objTblLocationTypeList: arryDistrict
        };
        let url = URL_Start + 'master/getBindLocationDistrictDetails'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data != null) {
                    var itmData = data.Data;
                    itmData.map((option) => { return glblArr.push({ 'value': option.DistrictId, 'label': option.DistrictName, 'disabled': false }); });
                    SetLocationDistrictList(glblArr);
                }
                else {
                    SetLocationDistrictList([]);
                }
            }
            else { SweetAlert.fire({ title: "Error", text: "No record found", icon: "error" }); setIsSaving(false); }
        }).catch(function (error) { setIsSaving(false); });
    }
    const GetAllCentreList = async () => {
        var glblArr = [];
        var arrcentre = [];
        let url = URL_Start + 'master/getvirtualcentrelist/'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data != null) {
                    for (var i in districtSelected) {
                        for (var key in data.Data) {
                            if (districtSelected[i].value === data.Data[key].SAPDistrictId) {
                                arrcentre.push({ 'Id': data.Data[key].Id, 'Value': data.Data[key].Value });
                            }
                        }
                    }
                    var itmData = arrcentre;
                    itmData.map((option) => { return glblArr.push({ 'value': option.Id, 'label': option.Value, 'disabled': false }); });
                    setVirtualCentre(glblArr);
                }
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const GetAllPackageAndTestList = async () => {
        var glblitemArr = [];
        var glblpackageArr = [];
        let url = URL_Start + 'master/getallpackageandtestlist/'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data != null) {
                    var itmData = data.Data.filter((d) => (d.IsPackage === "0" || d.IsPackage === "False"));
                    itmData.map((option) => { return glblitemArr.push({ 'value': option.ItemMasterId, 'label': option.ItemName, 'IsPackage': option.IsPackage, 'disabled': false }); });
                    var pkgData = data.Data.filter((d) => (d.IsPackage === "1" || d.IsPackage === "True"));
                    pkgData.map((option) => { return glblpackageArr.push({ 'value': option.ItemMasterId, 'label': option.ItemName, 'IsPackage': option.IsPackage, 'disabled': false }); });
                    SetAllTest(glblitemArr);
                    SetAllPackage(glblpackageArr);
                }
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const GetBannerDetails = async () => {
        let url = URL_Start + 'master/getbannerdetails/'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data != null) {
                    setBannerDetails(data.Data);
                }
                else {
                    setBannerDetails([]);
                }
            }
            else {
                setBannerDetails([]);
                // SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); 
            }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const SaveBannerDetails = async (e) => {
        var BannerType = "";
        var BannerId = "";
        var BannerName = "";
        var Banner = "";
        if (editicon === false) {
            if (stateSelected.length === 0) { SweetAlert.fire("State is required"); return false; }
            if (regionSelected.length === 0) { SweetAlert.fire("Region is required"); return false; }
            if (districtSelected.length === 0) { SweetAlert.fire("District is required"); return false; }
            if (centreSelected.length === 0) { SweetAlert.fire("Centre is required"); return false; }
        }
        if (typeSelected[0].value === "0") {
            if (packageSelected.length === 0) { SweetAlert.fire("Package banner name is required"); return false; }
            if (bannerPath === '') { SweetAlert.fire("Package banner is required"); return false; }
            BannerType = "0";
            BannerId = packageSelected[0].value;
            BannerName = packageSelected[0].label;
            Banner = bannerPath.substring(bannerPath.lastIndexOf("/") + 1, bannerPath.length);
        }
        else if (typeSelected[0].value === "1") {
            if (itemSelected.length === 0) { SweetAlert.fire("Item banner name is required"); return false; }
            if (bannerPath === '') { SweetAlert.fire("Item banner is required"); return false; }
            BannerType = "1";
            BannerId = itemSelected[0].value;
            BannerName = itemSelected[0].label;
            Banner = bannerPath.substring(bannerPath.lastIndexOf("/") + 1, bannerPath.length);
        }
        else if (typeSelected[0].value === "2") {
            if (bannerref.current.value === '') { SweetAlert.fire("Banner name is required"); return false; }
            if (bannerPath === '') { SweetAlert.fire("Banner is required"); return false; }
            BannerType = "2";
            BannerId = "0";
            BannerName = bannerref.current.value;
            Banner = bannerPath.substring(bannerPath.lastIndexOf("/") + 1, bannerPath.length);
        }
        else {
            if (urlbanneref.current.value === '') { SweetAlert.fire("Url is required"); return false; }
            if (bannerPath === '') { SweetAlert.fire("Url banner is required"); return false; }
            BannerType = "3";
            BannerId = "-1";
            BannerName = urlbanneref.current.value;
            Banner = bannerPath.substring(bannerPath.lastIndexOf("/") + 1, bannerPath.length);
        }
        setIsSaving(true);
        let arryCentres = [];
        for (var i in centreSelected) { arryCentres.push({ 'CentreId': centreSelected[i].value, 'CentreName': centreSelected[i].label }); }
        const requestOptions = {
            BannerType: BannerType,
            BannerId: BannerId,
            BannerName: BannerName,
            Banner: Banner,
            Centres: arryCentres,
            Flag: editicon === true ? "1" : "0",
            AddedBy: localStorage.LoggedInUser
        };
        let url = URL_Start + 'master/savebannerdetails'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                setIsSaving(false);
                editicon === true ? SweetAlert.fire("Banner details updated successfully") : SweetAlert.fire("Banner details saved successfully")
                setStateSelected([]);
                setRegionSelected([]);
                setDistrictSelected([]);
                setCentreSelected([]);
                SetLocationRegionList([]);
                SetLocationDistrictList([]);
                setVirtualCentre([]);
                setBannerPath('');
                var element = document.querySelector("input[type='file']")
                element.classList.remove("removeValue");
                if (typeSelected[0].value === "0") { document.getElementById("packagebanner").value = ""; setPackageSelected([]); }
                else if (typeSelected[0].value === "1") { document.getElementById("itembanner").value = ""; setItemSelected([]); }
                else if (typeSelected[0].value === "2") { document.getElementById("banner").value = ""; bannerref.current.value = ""; }
                else { document.getElementById("urlbanner").value = ""; urlbanneref.current.value = ""; }
                setTypeSelected([]);
                setEditIcon(false);
                GetBannerDetails();
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsSaving(false); }
        }).catch(function (error) { setIsSaving(false); });
    }
    const EditBannerDetails = async (rowData) => {
        setEditIcon(true);
        var typearr = [];
        typearr.push({
            value: rowData.BannerType.toLowerCase() === "package banner" ? "0" : rowData.BannerType.toLowerCase() === "test banner" ? "1" : rowData.BannerType.toLowerCase() === "banner" ? "2" : "3",
            label: rowData.BannerType
        })
        setTypeSelected(typearr);
        setStateName(rowData.StateName);
        setRegionName(rowData.RegionName);
        setDistrictName(rowData.DistrictName);
        setCentreName(rowData.CentreName);
        var arr = [];
        arr.push({
            value: rowData.CentreBannersId,
            label: rowData.CentreName
        })
        setBannerPath(Image_Path + "WebsiteBanners/" + rowData.Banner);
        setCentreSelected(arr);
        if (rowData.BannerType.toLowerCase() === "package banner") {
            var packarr = [];
            packarr.push({
                value: rowData.BannerId,
                label: rowData.BannerName
            })
            setPackageSelected(packarr);
        }
        else if (rowData.BannerType.toLowerCase() === "test banner") {
            var itemarr = [];
            itemarr.push({
                value: rowData.BannerId,
                label: rowData.BannerName
            })
            setItemSelected(itemarr);
        }
        else if (rowData.BannerType.toLowerCase() === "banner") {
            setStateBannerRef(rowData.BannerName);
        }
        else {
            setUrlBannerRef(rowData.BannerName);
        }
    }
    const DeleteBanner = async (CentreBannersId) => {
        SweetAlert.fire({
            title: 'Warning',
            text: "You are going to delete an item. Are you sure?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            cancelButtonColor: "#000",
            reverseButtons: true
        })
            .then((result) => {
                if (result.value) { ConfirmedDeleteBanner(CentreBannersId); }
                else { SweetAlert.fire('Your data is safe!') }
            })
    }
    const ConfirmedDeleteBanner = async (CentreBannersId) => {
        //setIsSaving(true);
        const requestOptions = {
            CentreBannerId: CentreBannersId,
            AddedBy: localStorage.LoggedInUser
        };
        let url = URL_Start + 'master/deletebanner'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                SweetAlert.fire({
                    text: "Deleted",
                    confirmButtonText: 'Ok',
                    reverseButtons: true
                })
                    .then((result) => {
                        if (result.value) { GetBannerDetails();clearHandler(); }
                        else { GetBannerDetails();clearHandler(); }
                    })
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsSaving(false); }
        }).catch(function (error) { setIsSaving(false); });
    }
    const clearHandler = async () => {
        setEditIcon(false);
        setStateSelected([]);
        setRegionSelected([]);
        setDistrictSelected([]);
        setCentreSelected([]);
        SetLocationRegionList([]);
        SetLocationDistrictList([]);
        setVirtualCentre([]);
        setTypeSelected([]);
        setPackageSelected([]);
        setItemSelected([]);
        setBannerPath('');
        bannerref.current.value = "";
        urlbanneref.current.value = "";
        GetBannerDetails();
    }
    const cancelHandler = async () => {
        setEditIcon(false);
        setStateSelected([]);
        setRegionSelected([]);
        setDistrictSelected([]);
        setCentreSelected([]);
        SetLocationRegionList([]);
        SetLocationDistrictList([]);
        setVirtualCentre([]);
        var element = document.querySelector("input[type='file']")
        element.classList.remove("removeValue");
        if (typeSelected[0].value === "0") {
            document.getElementById("packagebanner").value = "";
        }
        else if (typeSelected[0].value === "1") {
            document.getElementById("itembanner").value = "";
        }
        else if (typeSelected[0].value === "2") {
            document.getElementById("banner").value = "";
        }
        else {
            document.getElementById("urlbanner").value = "";
        }
        setTypeSelected([]);
        setPackageSelected([]);
        setItemSelected([]);
        setBannerPath('');
        bannerref.current.value = "";
        urlbanneref.current.value = "";
        GetBannerDetails();
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    return (
        <>
            <Breadcrumb title="Banner Master" parent="Master" />
            <Container fluid>
                <Form>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Header className='pb-0'>
                                    <Card.Title>Banner Details</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form.Row>
                                        {
                                            editicon === true ?
                                                <>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>State<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="text" value={statename} disabled />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Region<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="text" value={regionname} disabled />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>District<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="text" value={districtname} disabled />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Centres<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="text" value={centrename} disabled />
                                                        </Form.Group>
                                                    </Col>
                                                </> :
                                                <>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>State<span className='text-required'>*</span></Form.Label>
                                                            <Select options={[{ label: "All", value: "0" }, ...locationstatelist]} isMulti={true} onChange={StateChangeHandler}
                                                                value={stateSelected} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Region<span className='text-required'>*</span></Form.Label>
                                                            <Select options={[{ label: "All", value: "0" }, ...locationregionlist]} isMulti={true} onChange={RegionChangeHandler}
                                                                value={regionSelected} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>District<span className='text-required'>*</span></Form.Label>
                                                            <Select options={[{ label: "All", value: "0" }, ...locationdistrictlist]} isMulti={true} onChange={DistrictChangeHandler}
                                                                value={districtSelected} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Centre<span className='text-required'>*</span></Form.Label>
                                                            <Select options={[{ label: "All", value: "0" }, ...virtualcentre]} isMulti={true} onChange={CentreChangeHandler}
                                                                value={centreSelected} />
                                                        </Form.Group>
                                                    </Col>
                                                </>
                                        }
                                    </Form.Row>
                                    <Form.Row>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Banner Type<span className='text-required'>*</span></Form.Label>
                                                <Select onChange={TypeChangeHandler}
                                                    options={[{ label: "Package Banner", value: "0" }, { label: "Test Banner", value: "1" }, { label: "Banner", value: "2" }, { label: "Url Banner", value: "3" }]}
                                                    value={typeSelected} />
                                            </Form.Group>
                                        </Col>
                                        {
                                            typeSelected.length > 0 ?
                                                <>
                                                    {
                                                        typeSelected[0].value === "0" ?
                                                            <>
                                                                <Col md={3}>
                                                                    <Form.Group>
                                                                        <Form.Label>Package Banner Name<span className='text-required'>*</span></Form.Label>
                                                                        <Select onChange={PackageChangeHandler} options={allpackage}
                                                                            value={packageSelected} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col md={3}>
                                                                    <Form.Group>
                                                                        <Form.Label>Package Banner<span className='text-required'>*</span></Form.Label>
                                                                        <div><input type="file" id="packagebanner" onChange={(e) => UploadImages(e, "WebsiteBanners")} accept="image/*" />
                                                                            <img src={bannerPath} width="80"></img>
                                                                        </div>
                                                                    </Form.Group>
                                                                </Col>
                                                            </>
                                                            :
                                                            typeSelected[0].value === "1" ?
                                                                <>
                                                                    <Col md={3}>
                                                                        <Form.Group>
                                                                            <Form.Label>Test Banner Name<span className='text-required'>*</span></Form.Label>
                                                                            <Select onChange={ItemChangeHandler} options={alltest}
                                                                                value={itemSelected} />
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col md={3}>
                                                                        <Form.Group>
                                                                            <Form.Label>Test Banner<span className='text-required'>*</span></Form.Label>
                                                                            <div><input type="file" id="itembanner" onChange={(e) => UploadImages(e, "WebsiteBanners")} accept="image/*" />
                                                                                <img src={bannerPath} width="80"></img></div>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </>
                                                                :
                                                                typeSelected[0].value === "2" ?
                                                                    <>
                                                                        <Col md={3}>
                                                                            <Form.Group>
                                                                                <Form.Label>Banner Name<span className='text-required'>*</span></Form.Label>
                                                                                <Form.Control type="text" ref={bannerref} />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col md={3}>
                                                                            <Form.Group>
                                                                                <Form.Label>Banner<span className='text-required'>*</span></Form.Label>
                                                                                <div><input type="file" id="banner" onChange={(e) => UploadImages(e, "WebsiteBanners")} accept="image/*" />
                                                                                    <img src={bannerPath} width="80"></img></div>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Col md={3}>
                                                                            <Form.Group>
                                                                                <Form.Label>Url<span className='text-required'>*</span></Form.Label>
                                                                                <Form.Control type="text" ref={urlbanneref} />
                                                                            </Form.Group>
                                                                        </Col>
                                                                        <Col md={3}>
                                                                            <Form.Group>
                                                                                <Form.Label>Url Banner<span className='text-required'>*</span></Form.Label>
                                                                                <div><input type="file" id="urlbanner" onChange={(e) => UploadImages(e, "WebsiteBanners")} accept="image/*" />
                                                                                    <img src={bannerPath} width="80"></img></div>
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </>
                                                    }
                                                </>
                                                :
                                                null
                                        }
                                        {/* <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Package Banner Name<span className='text-required'>*</span></Form.Label>
                                                <Select onChange={PackageChangeHandler} options={allpackage}
                                                    value={packageSelected} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Package Banner<span className='text-required'>*</span></Form.Label>
                                                <div><input type="file" id="packagebanner" onChange={(e) => UploadImages(e, "GlobalBannerPackage")} />
                                                    <img src={bannerpackagePath} width="80"></img>
                                                </div>
                                            </Form.Group>
                                        </Col> */}
                                    </Form.Row>
                                    {/* <Form.Row>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Item Banner Name<span className='text-required'>*</span></Form.Label>
                                                <Select onChange={ItemChangeHandler} options={alltest}
                                                    value={itemSelected} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Item Banner<span className='text-required'>*</span></Form.Label>
                                                <div><input type="file" id="itembanner" onChange={(e) => UploadImages(e, "GlobalBannerItem")} />
                                                    <img src={banneritemPath} width="80"></img></div>
                                            </Form.Group>
                                        </Col>
                                    </Form.Row> */}
                                    {/* <Form.Row>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Banner Name<span className='text-required'>*</span></Form.Label>
                                                <Form.Control type="text" ref={bannerref} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Banner<span className='text-required'>*</span></Form.Label>
                                                <div><input type="file" id="banner" onChange={(e) => UploadImages(e, "GlobalBanner")} />
                                                    <img src={bannerPath} width="80"></img></div>
                                            </Form.Group>
                                        </Col>
                                    </Form.Row> */}
                                    <Col md={12} className='text-center'>
                                        <Form.Group>
                                            <Button variant='secondary' className="mr-2" onClick={cancelHandler}>Cancel</Button>
                                            {isSaving ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' onClick={(e) => SaveBannerDetails(e)}>Save</Button>}
                                        </Form.Group>
                                    </Col>
                                    <Form.Row>
                                        <Col md={12}>
                                            <DataTables keyField='CentreBannersId' tableData={bannerdetails} columns={columns} pagination={paginationFactory(paginationOptions)} noDataIndication={NoRecordFounds} />
                                        </Col>
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </>
    )
}

export default Banners