import React, { useState, useEffect } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import SweetAlert from 'sweetalert2';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip';
import EditToolTip from '../UI/ToolTips/EditToolTip';
import { useHistory } from 'react-router';
import Select from 'react-select';

const UserCentreTemplatesMapping = () => {
    const history = useHistory();
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [tabledata, setTableData] = useState([]);
    const [users, setUsers] = useState([]);
    const [typeSelected, setTypeSelected] = useState([]);
    const paginationOptions = { sizePerPage: 25, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last', onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); } };

    useEffect(() => {
        localStorage.removeItem("EditUserCentreTemplateData");
        GetAllUsers();
        GetUserCentreRateTemplates();
    }, [])
    const GetAllUsers = async () => {
        var glblArr = [];
        try {
            let url = URL_Start + 'master/getallusers'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        const usersdata = data.Data;
                        usersdata.map((option) => { return glblArr.push({ 'value': option.UserId, 'label': option.UserName }); });
                        setUsers(glblArr);
                    }
                    else { setUsers([]); }
                }
                else { setUsers([]); }
            }).catch(function (error) { if (error.response) { setUsers([]); AxiosError(error.response.status); } });
        }
        catch (error) { setUsers([]); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetUserCentreRateTemplates = async () => {
        var UserId = "0";
        if (typeSelected.length > 0) {
            UserId = typeSelected[0].value
        }
        try {
            let url = URL_Start + 'master/getusercentreratetemplates/' + UserId
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) { setTableData(data.Data); }
                    else { setTableData([]); }
                }
                else { setTableData([]); }
            }).catch(function (error) { if (error.response) { setTableData([]); AxiosError(error.response.status); } });
        }
        catch (error) { setTableData([]); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <EditToolTip title="Edit" placement="top" id={`EditId${rowData.UserCentreTemplateId}`} onClick={() => editHandler(rowData)} />
                <DeleteToolTip title="Delete" placement="top" id={`DeleteId${rowData.UserCentreTemplateId}`} onClick={() => Displayalert(rowData.UserCentreTemplateId)} />
            </div>
        </React.Fragment>
    }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'UserName', text: 'User Name', editable: false },
        { dataField: 'CentreName', text: 'Centre Name', editable: false },
        { dataField: 'TemplateName', text: 'Rate Template Name', editable: false },
        { dataField: '', text: 'Action', formatter: actionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    const Displayalert = (UserCentreTemplateId) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "User will be mapped with all rate templates at this centre",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        })
            .then((result) => { if (result.value) { DeleteUserCentreTemplate(UserCentreTemplateId); } else { SweetAlert.fire('Your data is safe!'); } })
    }
    const editHandler = async (rowData) => {
        localStorage.setItem("EditUserCentreTemplateData", JSON.stringify(rowData));
        history.push({ pathname: `${process.env.PUBLIC_URL}/master/addeditusercentretemplatesmapping`, search: `` });
    }
    const DeleteUserCentreTemplate = async (UserCentreTemplateId) => {
        const requestOptions = {
            UserCentreTemplateId: UserCentreTemplateId,
            AddedBy: localStorage.LoggedInUser
        };
        let url = URL_Start + 'master/deleteusercentremapping'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                SweetAlert.fire({
                    title: 'Deleted',
                    text: "Mapping removed succesfully",
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    reverseButtons: true
                })
                    .then((result) => {
                        if (result.value) { setTypeSelected([]); GetUserCentreRateTemplates(); }
                        else { setTypeSelected([]); GetUserCentreRateTemplates(); }
                    })
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please ask support to check server logs", icon: "error" }); }
        }).catch(function (error) { if (error.response) { setTableData([]); AxiosError(error.response.status); } });
    }
    const newHandler = async () => {
        history.push({ pathname: `${process.env.PUBLIC_URL}/master/addeditusercentretemplatesmapping`, search: `` });
    }
    const TypeChangeHandler = (options) => {
        var typearr = [];
        typearr.push({
            value: options.value,
            label: options.label
        });
        setTypeSelected(typearr);
    }
    const AxiosError = async (ErrorCode) => {
        if (ErrorCode === 400) { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode === 401) { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode === 403) { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode === 404) { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode === 500) { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode === 503) { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="User Centre Templates Mapping" parent="Master" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row className='align-items-center'>
                                    <Col md={6} lg={6}>
                                        <Form.Group>
                                            <Form.Label>Users</Form.Label>
                                            <Select onChange={TypeChangeHandler}
                                                options={users}
                                                value={typeSelected} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} lg={6}>
                                        <Form.Group className='mb-0'>
                                            <Button variant='secondary' className="mr-2" onClick={GetUserCentreRateTemplates} >Search</Button>
                                            <Button variant='primary' type='submit' onClick={newHandler}>Add User Centre Templates Mapping</Button>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className='mt25'>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Mapping Lists</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form.Row>
                                    <Col lg={12} md={12}>
                                        <DataTables
                                            keyField='UserCentreTemplateId'
                                            tableData={tabledata}
                                            columns={columns}
                                            noDataIndication={NoRecordFounds}
                                            pagination={paginationFactory(paginationOptions)}
                                        />
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default UserCentreTemplatesMapping