import React, { useState, useEffect, useRef, Fragment } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { URL_Start } from '../../../constant';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { MultiSelect } from 'react-multi-select-component';
import '../../../pages/admin/react-multiselect.css';
let globalModuleId = "0";
let globalPageSelection = false;
let globaltblAllGroups = [];
let globaltblExistGroups = [];
let globaltblExGroupLocation = [];
let globallocationStateList = [];
let LGlbLocationRegionList = [];
let LGLocationDistrictList = [];
let GGLocationRegionList = [];
let GGLocationDistrictList = [];
let GloBLocationStates = [];
let GloBLocationRegions = [];
let StateOptionSelFlag = false;
let stringStates = "";
let stringStateIds = "";
let stringRegions = "";
let stringRegionIds = "";
let stringDistricts = "";
let stringDistrictIds = "";
let stringCities = "";
let stringCityIds = "";
let SelectedAllStateFlag = false;
let SelectedAllregionFlag = false;
let SelectedAlldistricFlag = false;
let glblCentreIds = [];
let glblCentreNames = [];
let glbSelectedCentreIds = [];
const EditAccessGroupLocation = () => {
    let typeahead = useRef(null);
    let textSearch = useRef();
    let isFirstRender = useRef(false);
    let isLastRender = useRef(false);
    const history = useHistory();
    const [GroupUId, SetGroupUId] = useState(localStorage.GroupUId == undefined ? "" : localStorage.GroupUId);
    const [GroupModuleId, SetGroupModuleId] = useState(localStorage.GroupModuleId == undefined ? "" : localStorage.GroupModuleId);
    const [GroupPageAccessGroupId, SetGroupPageAccessGroupId] = useState(localStorage.GroupPageAccessGroupId == undefined ? "" : localStorage.GroupPageAccessGroupId);
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const [GPageACCName, SetGPageACCName] = useState(null);
    const [ErrorPopUp, setErrorPopUp] = useState(0);
    const [tblRefDoctor, SettblRefDoctor] = useState([]);
    const [ModuleDetail, SetModuleDetail] = useState(0);
    const [IsUserTypeMoudle, SetIsUserTypeMoudle] = useState(false);
    const [tbAccessPageRMaster, SettbAccessPageRMaster] = useState([]);
    const [DepartmentDetail, SetDepartmentDetail] = useState(0);
    const [DesignationDetail, SetDesignationDetail] = useState(0);
    const [UserPlatformDetail, SetUserPlatformDetail] = useState(0);
    const [PartnerTypeDetail, SetPartnerTypeDetail] = useState(0);
    const [IsPartnerTypeShow, SetIsPartnerTypeShow] = useState(false);
    const [IsDepartmentShow, SetIsDepartmentShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isButton, setIsButton] = useState(false);
    const [IsSearchUsers, SetIsSearchUsers] = useState(false);
    const [SearchBox, SetSearchBox] = useState("");
    const [UsersDLists, SetUsersDLists] = useState([]);
    const [tblUserGroupMapping, SettblUserGroupMapping] = useState(null);
    const [userModuleShow, SetuserModuleShow] = useState(false);
    const [TXtSearch, SetTXtSearch] = useState("");
    const [ViewAccessGroups, SetViewAccessGroups] = useState(null);
    const [IsUserView, SetIsUserView] = useState(false);
    const [LocationZoneId, SetLocationZoneId] = useState(1);
    const [LocationStateList, SetLocationStateList] = useState([]);
    const [LocationRegionList, SetLocationRegionList] = useState([]);
    const [selectedGlocationStateList, SetselectedGlocationStateList] = useState([]);
    const [selectedGlocationRegionList, SetselectedGlocationRegionList] = useState([]);
    const [selectedGlocationDistrictList, SetselectedGlocationDistrictList] = useState([]);
    const [LocationDistrictList, SetLocationDistrictList] = useState([]);
    const [SelectionglobaltblAllGroups, SetSelectionglobaltblAllGroups] = useState([]);
    const [VaildFlag, SetVaildFlag] = useState(false);
    const [FinalStage, SetFinalStage] = useState(false);
    const [LGLocationZoneList, SetLGLocationZoneList] = useState([{ "zoneId": "1" }]);
    const [centresList, setCentresList] = useState([]);
    const [selectedCentres, SetSelectedCentres] = useState([]);
    useEffect(() => { asyncFunLocationBindDetails(); BindAccessGroupUID(); asyncFunUserAccessGroupLocationMapping(); }, []);

    const asyncFunLocationBindDetails = async () => {
        try {
            let url = URL_Start + 'master/getstateslistlocationMapping/' + `${LocationZoneId}`;
            let options = {
                method: 'GET', url: url,
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
                data: {}
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) {
                        const TypeOptionDs = [];
                        GloBLocationStates = [];
                        ds.map((option) => {
                            const TypeOptions = { 'label': option.Value, 'value': option.Id, 'StateId': option.Id, 'disabled': option.HaveRegionStatus == "1" ? false : true }
                            return TypeOptionDs.push(TypeOptions);
                        });
                        SetLocationStateList(TypeOptionDs);
                        ds.map((option) => {
                            const TypeOptions = { 'label': option.Value, 'value': option.Id, 'StateId': option.Id, 'disabled': option.HaveRegionStatus == "1" ? false : true }
                            return GloBLocationStates.push(TypeOptions);
                        });
                    }
                    else { SetLocationStateList([]); }

                }
                else { SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" }); }
            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
            }
        } catch (error) {
            console.log(error.message);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const asyncFunRegionLocationBindDetails = async (isChecked) => {
        try {
            let url = URL_Start + 'master/getBindLocationRegionDetails'
            let options = {
                method: 'POST', url: url,
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
                data: { objTblLocationTypeList: globallocationStateList }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    SetLocationRegionList([]);
                    if (ds != null && ds.length > 0) {
                        GloBLocationRegions = [];
                        ds.map((option) => {
                            const TypeOptions = { 'label': option.RegionName, 'value': option.RegionId, 'StateId': option.StateId, 'RegionId': option.RegionId, 'IsHaveDistrictStatus': option.IsHaveDistrictStatus, 'disabled': false }
                            return GloBLocationRegions.push(TypeOptions);
                        });
                        const TypeOptionDs = [];
                        ds.map((option) => {
                            const TypeOptions = { 'label': option.RegionName, 'value': option.RegionId, 'StateId': option.StateId, 'RegionId': option.RegionId, 'IsHaveDistrictStatus': option.IsHaveDistrictStatus, 'disabled': false }
                            return TypeOptionDs.push(TypeOptions);
                        });
                        SetLocationRegionList(TypeOptionDs);
                    }
                    else { SetLocationRegionList([]); SetselectedGlocationRegionList([]); }

                    if (ds != null && ds.length > 0) {
                        if (SelectedAllStateFlag == true) {
                            const TypeOptionDs = [];
                            ds.map((option) => {
                                const TypeOptions = { 'label': option.RegionName, 'value': option.RegionId, 'StateId': option.StateId, 'RegionId': option.RegionId, 'IsHaveDistrictStatus': option.IsHaveDistrictStatus, 'disabled': false }
                                return TypeOptionDs.push(TypeOptions);
                            });
                            SetselectedGlocationRegionList(selectedGlocationRegionList => [...TypeOptionDs]);
                            LGlbLocationRegionList = [];
                            ds.map((option) => {
                                const item = { "RegionId": option.RegionId };
                                LGlbLocationRegionList.push(item);
                            });
                        }
                        else {
                            SetselectedGlocationRegionList(selectedGlocationRegionList => [...selectedGlocationRegionList.filter(o1 => ds.some(o2 => o1.RegionId == o2.RegionId))]);
                        }
                    }
                    else {
                        SetLocationRegionList([]);
                        SetselectedGlocationRegionList([]);
                        if (globallocationStateList.length > 0) {
                            stringRegions = "Selected All Regions";
                            stringRegionIds = "0";
                            stringDistricts = "Selected All Districts";
                            stringDistrictIds = "0";
                            SetuserModuleShow(true);
                        }
                        else { stringRegions = ""; stringRegionIds = "0"; stringDistricts = ""; stringDistrictIds = "0"; }
                    }
                }
                else {
                    SetselectedGlocationRegionList([]);
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                }
            }
            else {
                SetselectedGlocationRegionList([]);
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
            }
        } catch (error) {
            SetselectedGlocationRegionList([]);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const [modal, setModal] = useState();
    const toggleModal = () => { setModal(!modal) }
    const [errorModal, setErrorModal] = useState();
    const errorHandler = () => { SetIsUserView(false); setModal(true); setErrorModal(true); formik.handleSubmit(); }
    const formik = useFormik({
        initialValues: { module: '', userPlatform: '', PageAccessGroupId: '0', Users: '', partnertype: '' },
        enableReinitialize: true,
        validationSchema: Yup.object({}),
        isSubmitting: true,
        onSubmit: async (values, actions) => {
            if (globalPageSelection == true) { SetIsUserView(false); setModal(true); setErrorModal(true); }
            else if (globallocationStateList.length == 0) { setModal(true); setErrorModal(true); }
            else if (LocationRegionList.length > 0 && LGlbLocationRegionList.length == 0) { setModal(true); setErrorModal(true); }
            else if (GroupModuleId === "2" && glbSelectedCentreIds.length == 0) { setModal(true); setErrorModal(true); }
            else {
                setModal(false);
                setErrorModal(false);
                setErrorPopUp("");
                let UnWantStates = "";
                let HasMatchCheck = false;
                let GlbArrUnMatchStates = selectedGlocationStateList.filter(d1 => !selectedGlocationRegionList.some(d2 => d1.StateId === d2.StateId));
                if (GlbArrUnMatchStates != null && GlbArrUnMatchStates.length > 0) {
                    HasMatchCheck = true;
                    GlbArrUnMatchStates = GlbArrUnMatchStates.sort((a, b) => a.value - b.value);
                    UnWantStates = GlbArrUnMatchStates.map(({ label }) => `${label}`).join(',');
                }
                let UnWantRegions = "";
                let HasMatchRegion = false;
                let GlbArrUnMatchRegion = selectedGlocationRegionList.filter(d1 => !selectedGlocationDistrictList.some(d2 => d1.StateId === d2.StateId && d1.RegionId == d2.RegionId));
                GlbArrUnMatchRegion = GlbArrUnMatchRegion.filter((d) => d.IsHaveDistrictStatus === "1");
                if (GlbArrUnMatchRegion != null && GlbArrUnMatchRegion.length > 0) {
                    HasMatchRegion = true;
                    GlbArrUnMatchRegion = GlbArrUnMatchRegion.sort((a, b) => a.value - b.value);
                    UnWantRegions = GlbArrUnMatchRegion.map(({ label }) => `${label}`).join(',');
                }
                let hasMatchFirst = false;
                let hasMatchSecond = false;
                hasMatchFirst = globaltblExGroupLocation.some(function (d) {
                    return d.ModuleId == GroupModuleId && d.LocationStateIds.split(',').some(host => selectedGlocationStateList.map(val => val["StateId"]).indexOf(host) != -1)
                        && d.LocationRegionIds.split(',').some(host => selectedGlocationRegionList.map(val => val["RegionId"]).indexOf(host) != -1)
                        && d.LocationDistrictIds.split(',').some(host => selectedGlocationDistrictList.map(val => val["RegionId"]).indexOf(host) != -1)
                });

                hasMatchSecond = globaltblExGroupLocation.some(function (d) {
                    return d.ModuleId == GroupModuleId && ((d.LocationStateIds.split(',').some(host => selectedGlocationStateList.map(val => val["StateId"]).indexOf(host) != -1)
                        && ((d.LocationRegionIds.split(',').some(host => host == "0") && d.LocationDistrictIds.split(',').some(host => host == "0"))
                        ))
                    )
                });
                if (!hasMatchSecond) {
                    hasMatchSecond = globaltblExGroupLocation.some(function (d) {
                        return d.ModuleId == GroupModuleId &&
                            d.LocationStateIds.split(',').some(host => selectedGlocationStateList.map(val => val["StateId"]).indexOf(host) != -1)
                            && d.LocationStateIds.split(',').some(host => selectedGlocationRegionList.map(val => val["StateId"]).indexOf(host) != -1)
                            && d.LocationStateIds.split(',').some(host => selectedGlocationDistrictList.map(val => val["StateId"]).indexOf(host) != -1)
                            && d.LocationRegionIds.split(',').some(host => selectedGlocationRegionList.map(val => val["RegionId"]).indexOf(host) != -1)
                            && d.LocationRegionIds.split(',').some(host => selectedGlocationDistrictList.map(val => val["RegionId"]).indexOf(host) != -1)
                            && d.LocationDistrictIds.split(',').some(host => selectedGlocationDistrictList.map(val => val["DistrictId"]).indexOf(host) != -1)
                    });
                }
                if (HasMatchCheck) {
                    SweetAlert.fire({ title: "Warning", text: "You have not selected regions for " + UnWantStates + ". Please either select region or deselect state", icon: "warning" });
                }
                else if (hasMatchSecond) {
                    SweetAlert.fire({ title: "Warning", text: "you have already choose selected region. choose different region", icon: "warning" });
                }
                else if (hasMatchFirst) {
                    SweetAlert.fire({ title: "Warning", text: "You  can added only single group in section. if you want added new group  first remove then added new one.", icon: "warning" });
                }
                else if (hasMatchSecond && hasMatchFirst == false) {
                    SweetAlert.fire({ title: "Warning", text: "You  can not repeat same location. if you want added new group  first remove related location then added new one.", icon: "warning" });
                }
                else {
                    try {
                        let url = URL_Start + 'Users/SETUPDATEUserLocationGroupDetails';
                        let options = {
                            method: 'POST',
                            url: url,
                            headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
                            data: {
                                UserId: GroupUId, AddedBy: `${LoginId}`, objtblUserGroupMappingType: globaltblExistGroups,
                                objTblLocationZoneIds: LGLocationZoneList,
                                objTblLocationStateIds: globallocationStateList, objTblLocationRegionIds: LGlbLocationRegionList, objTblLocationDistrictIds: LGLocationDistrictList
                                , PageAccessGroupId: `${GroupPageAccessGroupId}`, ModuleId: `${GroupModuleId}`, CentreIds: glbSelectedCentreIds
                            }
                        };
                        let response = await axios(options);
                        let responseOK = response && response.status == 200;
                        if (responseOK) {
                            let data = response.data;
                            if (data.Success == true && data.Data == "2000") {
                                formik.resetForm();
                                globaltblExistGroups = [];
                                globaltblAllGroups = [];
                                SetuserModuleShow(false);
                                SetIsPartnerTypeShow(false);
                                SetUsersDLists([]);
                                globalModuleId = "0";
                                globallocationStateList = [];
                                LGLocationDistrictList = [];
                                LGlbLocationRegionList = [];
                                SetLocationRegionList([]);
                                SetLocationDistrictList([]);
                                SetselectedGlocationRegionList([]);
                                SetLocationDistrictList([]);
                                SetselectedGlocationDistrictList([]);
                                SetLocationRegionList([]);
                                SetLocationDistrictList([]);
                                SetselectedGlocationStateList([]);
                                SetIsSearchUsers(false);
                                SweetAlert.fire({ title: "Success!", text: "Access Group User Location Mapping has been Updated!", icon: "success" });
                                setErrorModal(false);
                                history.push({ pathname: `${process.env.PUBLIC_URL}/user-master/addAccessGroupsMapping`, search: `` });
                            }
                            else {
                                if (data.ErrorList != null && data.ErrorList.length > 0) {
                                    let Errtext = <p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                                    SweetAlert.fire({ title: "Error", text: data.ErrorList[0].errorMsg, icon: "error" });
                                }
                            }
                        }
                    } catch (error) {
                        let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                        SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
                    }
                    setTimeout(() => { actions.setSubmitting(false); }, 1000);
                }
            }
        },
    });
    const PrevBckHandler = async () => {
        localStorage.removeItem('GroupPageAccessGroupId');
        localStorage.removeItem('GroupModuleId');
        localStorage.removeItem('GroupUId');
        history.push({ pathname: `${process.env.PUBLIC_URL}/user-master/addAccessGroupsMapping`, search: `` });
    }
    async function BindAccessGroupUID() {
        try {

            let url = URL_Start + 'users/getpageAccessGroupByAcesId';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { PageAccessGroupId: `${GroupPageAccessGroupId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;

                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        await SetViewAccessGroups(ds);
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');

                }

            }
            else {
                setErrorModal(false);
                SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false);
            }
            // return data;
        } catch (error) {
            if (error.response) {
                setErrorModal(false);
                ErrorFunction(error.response.status);

            }

        }
    }
    const asyncFunUserAccessGroupLocationMapping = async () => {
        try {

            let url = URL_Start + 'users/getUserLocationGroupMapping';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { UserId: `${GroupUId}`, ModuleId: `${GroupModuleId}`, PageAccessGroupId: `${GroupPageAccessGroupId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.objBindUserLocationnGroupMapping != null && ds.objBindUserLocationnGroupMapping.length > 0) {
                        SetGPageACCName(ds.objBindUserLocationnGroupMapping[0].AccessGroupName);
                    }

                    if (ds != null && ds.objUserGroupsMappingBind != null && ds.objUserGroupsMappingBind.length > 0) {
                        globaltblExGroupLocation = [];

                        ds.objUserGroupsMappingBind.map((item1) => {
                            if (globaltblExGroupLocation.map(val => val["PageAccessGroupId"]).indexOf(item1["PageAccessGroupId"]) == -1) {
                                const item = {
                                    "PageAccessGroupId": item1.PageAccessGroupId,
                                    "AccessGroupName": item1.AccessGroupName,
                                    "ModuleId": item1.ModuleId,
                                    "LocationStates": item1.LocationStates == "" ? "" : item1.LocationStates,
                                    "LocationRegions": item1.LocationRegions == "" ? "" : item1.LocationRegions,
                                    "LocationDistricts": item1.LocationDistricts == "" ? "" : item1.LocationDistricts,
                                    "LocationCities": item1.LocationCities == "" ? "" : item1.LocationCities,
                                    "LocationStateIds": item1.LocationStateIds,
                                    "LocationRegionIds": item1.LocationRegionIds,
                                    "LocationDistrictIds": item1.LocationDistrictIds,
                                    "LocationCityIds": item1.LocationCityIds,
                                    "IsAlreadyHaveDB": item1.IsAlreadyHaveDB,
                                    "LocZoneId": item1.LocZoneId
                                };
                                return globaltblExGroupLocation.push(item);
                            }
                        });

                    }
                    if (ds != null && ds.objBindUserLocationnGroupMapping != null && ds.objBindUserLocationnGroupMapping.length > 0) {
                        globaltblExistGroups = [];
                        globaltblExistGroups = ds.objBindUserLocationnGroupMapping;
                    }
                    if (ds != null && ds.objBindUserLocationnGroupMapping != null && ds.objBindUserLocationnGroupMapping.length > 0) {
                        const TypeOptionDs = [];

                        ds.objBindUserLocationnGroupMapping.map((option) => {
                            if (TypeOptionDs.indexOf(option.StateId) === -1) {
                                const TypeOptions = {
                                    'label': option.StateName,
                                    'value': option.LocStateId,
                                    'StateId': option.LocStateId,
                                    'disabled': option.HaveRegionStatus == "1" ? false : true
                                }
                                return TypeOptionDs.push(TypeOptions);
                            }

                        });

                        SetselectedGlocationStateList(selectedGlocationStateList => [...TypeOptionDs]);
                    }
                    if (ds != null && ds.objBindUserLocationnGroupMapping != null && ds.objBindUserLocationnGroupMapping.length > 0) {
                        globallocationStateList = [];

                        ds.objBindUserLocationnGroupMapping.map((option) => {

                            if (globallocationStateList.indexOf(option.StateId) === -1) {
                                const TypeOptions = {
                                    'StateId': option.LocStateId,
                                }
                                return globallocationStateList.push(TypeOptions);
                            }
                        });


                        asyncFunRegionLocationBindDetails(false);
                    }

                    if (ds != null && ds.objBindUserLocationnGroupMapping != null && ds.objBindUserLocationnGroupMapping.length > 0) {
                        const TypeOptionDs = [];

                        ds.objBindUserLocationnGroupMapping.map((option) => {

                            if (TypeOptionDs.indexOf(option.RegionId) === -1) {
                                const TypeOptions = {
                                    'label': option.RegionName,
                                    'value': option.LocRegionId,
                                    'StateId': option.LocStateId,
                                    'RegionId': option.LocRegionId,
                                    'IsHaveDistrictStatus': option.IsHaveDistrictStatus,
                                    'disabled': false
                                }
                                return TypeOptionDs.push(TypeOptions);
                            }

                        });
                        SetselectedGlocationRegionList(selectedGlocationRegionList => [...TypeOptionDs]);
                    }
                    if (ds != null && ds.objBindUserLocationnGroupMapping != null && ds.objBindUserLocationnGroupMapping.length > 0) {
                        LGlbLocationRegionList = [];

                        ds.objBindUserLocationnGroupMapping.map((option) => {
                            if (LGlbLocationRegionList.indexOf(option.RegionId) === -1) {
                                if (option.LocStateId != '0') {
                                    const TypeOptions = {
                                        'RegionId': option.LocRegionId,
                                    }
                                    return LGlbLocationRegionList.push(TypeOptions);
                                }
                            }
                        });
                        console.log(ds.CentreIds)

                        const centreIdsOption = [];
                        ds.CentreIds.map((option) => {
                            if (centreIdsOption.indexOf(option.CentreId) === -1) {
                                const CenetreOptions = {
                                    'label': option.CentreName,
                                    'value': option.CentreId,
                                    'disabled': false
                                }
                                return centreIdsOption.push(CenetreOptions);
                            }
                        });
                        SetSelectedCentres(selectedCentres => [...centreIdsOption]);
                        glbSelectedCentreIds = [];
                        ds.CentreIds.map((option) => { const item = { "CentreId": option.CentreId }; glbSelectedCentreIds.push(item); });
                        GetCentresList();
                        console.log(glbSelectedCentreIds);
                    }
                    if (ds != null && ds.objBindUserLocationnGroupMapping != null && ds.objBindUserLocationnGroupMapping.length > 0) {
                        GGLocationRegionList = [];
                        ds.objBindUserLocationnGroupMapping.map((option) => {
                            if (GGLocationRegionList.indexOf(option.RegionId) === -1) {
                                const TypeOptions = {
                                    'label': option.RegionName,
                                    'value': option.LocRegionId,
                                    'StateId': option.LocStateId,
                                    'RegionId': option.LocRegionId,
                                    'IsHaveDistrictStatus': option.IsHaveDistrictStatus,
                                    'disabled': false
                                }
                                return GGLocationRegionList.push(TypeOptions);
                            }

                        });
                    }
                    if (ds != null && ds.objBindUserLocationnGroupMapping != null && ds.objBindUserLocationnGroupMapping.length > 0) {
                        const TypeOptionDs = [];

                        ds.objBindUserLocationnGroupMapping.map((option) => {
                            if (TypeOptionDs.indexOf(option.DistrictId) === -1) {
                                const TypeOptions = {
                                    'label': option.DistrictName,
                                    'value': option.LocDistrictId,
                                    'StateId': option.LocStateId,
                                    'RegionId': option.LocRegionId,
                                    'DistrictId': option.LocDistrictId,
                                    'IsHaveDistrictStatus': option.IsHaveDistrictStatus,
                                }
                                return TypeOptionDs.push(TypeOptions);
                            }

                        });
                        SetselectedGlocationDistrictList(selectedGlocationDistrictList => [...TypeOptionDs]);
                        //SetselectedGlocationDistrictList(TypeOptionDs);
                    }
                    if (ds != null && ds.objBindUserLocationnGroupMapping != null && ds.objBindUserLocationnGroupMapping.length > 0) {
                        GGLocationDistrictList = [];
                        ds.objBindUserLocationnGroupMapping.map((option) => {
                            if (GGLocationDistrictList.indexOf(option.DistrictId) === -1) {
                                const TypeOptions = {
                                    'label': option.DistrictName,
                                    'value': option.LocDistrictId,
                                    'StateId': option.LocStateId,
                                    'RegionId': option.LocRegionId,
                                    'DistrictId': option.LocDistrictId,
                                    'IsHaveDistrictStatus': option.IsHaveDistrictStatus,
                                }
                                return GGLocationDistrictList.push(TypeOptions);
                            }

                        });
                    }

                    if (ds != null && ds.objBindUserLocationnGroupMapping != null && ds.objBindUserLocationnGroupMapping.length > 0) {
                        LGLocationDistrictList = [];
                        ds.objBindUserLocationnGroupMapping.map((option) => {
                            if (LGLocationDistrictList.indexOf(option.DistrictId) === -1) {
                                const TypeOptions = {
                                    'DistrictId': option.LocDistrictId,
                                }
                                return LGLocationDistrictList.push(TypeOptions);
                            }
                        });
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                    console.log('no record found');
                }
            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
            }
        } catch (error) {
            console.log(error.message);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401") SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403") SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404") SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500") SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503") SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    const stateSelectionHandler = (options) => {
        SetselectedGlocationStateList(options);
        if (options != null && options.length > 0) {
            globallocationStateList = [];
            SetLocationDistrictList([]);
            options.map((option) => {
                const TypeOptions = { 'StateId': option.value }
                return globallocationStateList.push(TypeOptions);
            });
            StateOptionSelFlag = false;
            asyncFunRegionLocationBindDetails(true);
        }
        else {
            globallocationStateList = [];
            LGlbLocationRegionList = [];
            SetLocationRegionList([]);
            SetLocationDistrictList([]);
            SetselectedGlocationRegionList([]);
            SetLocationDistrictList([]);
            SetselectedGlocationDistrictList([]);
            asyncFunRegionLocationBindDetails(false);
            stringRegions = "";
            stringRegionIds = "0";
            stringDistricts = "";
            stringDistrictIds = "0";
            StateOptionSelFlag = true;
        }
        console.log(JSON.stringify(globallocationStateList));
    }
    const RegionSelectionHandler = (options) => {
        if (options != null && options.length > 0) {
            let hasMatchSecond = globaltblExGroupLocation.some(function (d) {
                return d.ModuleId == globalModuleId &&
                    d.LocationRegionIds.split(',').some(host => options.map(val => val["IsHaveDistrictStatus"] == "0" && val["RegionId"]).indexOf(host) != -1)
            });
            if (hasMatchSecond) {
                SweetAlert.fire({ title: "Warning", text: "you have already choose selected region or District. choose different region or District", icon: "warning" });
            }
            else {
                SetselectedGlocationRegionList(options);
                LGlbLocationRegionList = [];
                const TypeOptDocL = [];
                options.map((option) => {
                    const item = {
                        "RegionId": option.value
                    };
                    LGlbLocationRegionList.push(item);
                });
            }
        }
        else {
            SetselectedGlocationRegionList(options);
            LGlbLocationRegionList = [];
            SetLocationDistrictList([]);
            SetuserModuleShow(false);
            setCentresList([]); SetSelectedCentres([]);
            glbSelectedCentreIds = [];
            glblCentreIds = [];
        }
        SelectedAllStateFlag = false;
        SelectedAllregionFlag = false;
        isFirstRender.current = true;
        GetCentresList();
    }
    const customStateValRenderer = (selected, _options) => {
        GGLocationRegionList = [];
        GGLocationDistrictList = [];
        if (selected.length > 0 && _options.length > 0 && selected.length < _options.length) {
            SelectedAllStateFlag = false;
            SelectedAllregionFlag = false;
            stringStates = "";
            stringStateIds = "";
            selected = selected.sort((a, b) => a.value - b.value);
            stringStates = selected.map(({ label }) => `${label}`).join(',');
            stringStateIds = selected.map(({ value }) => `${value}`).join(',');

        }
        else if (selected.length > 0 && _options.length > 0 && (selected.length == _options.length)) {
            SelectedAllStateFlag = true;
            SelectedAllregionFlag = true;
            stringStates = "Selected All States";
            stringStateIds = "";
            selected = selected.sort((a, b) => a.value - b.value);
            stringStateIds = selected.map(({ value }) => `${value}`).join(',');


        }
        else {
            SelectedAllStateFlag = false;
            SelectedAllregionFlag = false;
            stringStates = "";
            stringStateIds = "";

        }

    };
    const RegionValueRenderer = (selected, _options) => {

        if (_options.length > 0) {
            GGLocationRegionList = [];
            GGLocationRegionList = _options;
        }
        else {
            GGLocationRegionList = [];
            GGLocationDistrictList = [];
        }

        if (selected.length > 0 && _options.length > 0 && selected.length < _options.length) {
            SelectedAllStateFlag = false;
            SelectedAllregionFlag = false;
            stringRegions = "";
            stringRegionIds = "";
            selected = selected.sort((a, b) => a.value - b.value);
            stringRegions = selected.map(({ label }) => `${label}`).join(',');
            stringRegionIds = selected.map(({ value }) => `${value}`).join(',');
        }
        else if (selected.length > 0 && _options.length > 0 && (selected.length == _options.length)) {
            stringRegions = "Selected All Regions";
            selected = selected.sort((a, b) => a.value - b.value);
            stringRegionIds = selected.map(({ value }) => `${value}`).join(',');
        }
        else {
            stringRegions = "";
            stringRegionIds = "";
            if (globallocationStateList.length > 0 && _options.length == 0) {
                stringRegions = "Selected All Regions";
                stringRegionIds = "0";
                stringDistricts = "Selected All Districts";
                stringDistrictIds = "0";
            }
            else {
                stringRegions = "";
                stringRegionIds = "0";
                stringDistricts = "";
                stringDistrictIds = "0";
            }
        }
    };
    useEffect(() => {
        if (isLastRender.current == true) {
            SetSelectionglobaltblAllGroups(SelectionglobaltblAllGroups => [globaltblAllGroups]);
            isLastRender.current = false;
        }
    }, [selectedGlocationDistrictList, SelectionglobaltblAllGroups, LGlbLocationRegionList, GloBLocationRegions]);
    const GetCentresList = async () => {
        setIsLoading(true);
        try {
            setCentresList([]);
            const requestOptions = { RegionIds: JSON.stringify(LGlbLocationRegionList) };
            let url = URL_Start + 'users/getcentersbyregion'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsLoading(false);
                    console.log(data.Data);
                    if (data.Data != null) {
                        let ds = data.Data;
                        if (ds != null && ds.length > 0) {
                            const TypeOptionDs = [];
                            ds.map((option) => {
                                const TypeOptions = { 'label': option.Value, 'value': option.Id, 'disabled': false }
                                return TypeOptionDs.push(TypeOptions);
                            });
                            setCentresList(TypeOptionDs);
                        }
                        else { setCentresList([]); SetSelectedCentres([]); }
                    }
                    else { APIError(data.Data); }
                }
                else { setIsLoading(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
        } catch (error) { setIsLoading(false); APIError("-3"); }
    }
    const CentreChangeHandler = (options) => {
        SetSelectedCentres(options);
        glblCentreIds = [];
        glblCentreNames = [];
        glblCentreIds = options.map(({ value }) => `${value}`).join(',');
        glblCentreNames = options.map(({ label }) => `${label}`).join(',');
        globaltblAllGroups = globaltblAllGroups.map(el => (
            el.IsAlreadyHaveDB == "False" ? { ...el, CentreNames: glblCentreNames, CentreIds: glblCentreIds } : el
        ));
        glbSelectedCentreIds = [];
        options.map((option) => { const item = { "CentreId": option.value }; glbSelectedCentreIds.push(item); });
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Data unavailable", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-2") { SweetAlert.fire({ title: "API Server Error", text: ErrorMsg, icon: "error" }); }
        else if (ErrorCode === "-3") { SweetAlert.fire({ title: "Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        else if (ErrorCode === "-4") { SweetAlert.fire({ title: "Wrong Credentials", text: "Please enter correct credentials", icon: "error" }) }
        else if (ErrorCode === "-5") { SweetAlert.fire({ title: "Oops", text: "Data could not be saved, Please try again later", icon: "error" }) }
        else if (ErrorCode === "-6") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
        else if (ErrorCode === "-7") { SweetAlert.fire({ title: "Duplicate", text: "Template already exists", icon: "error" }) }
        else if (ErrorCode === "-8") { SweetAlert.fire({ title: "Data unavailable", text: "No record found to import", icon: "error" }) }
        else if (ErrorCode === "-9") { SweetAlert.fire({ title: "LDAP authentication failed", text: ErrorMsg, icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }

    return (
        <Fragment>
            <Breadcrumb title="Edit Access Group Location" parent="Master" />
            <Container fluid>
                {ViewAccessGroups != null ? <Row>
                    <Col md={12}>
                        <Card>
                            <div class="pb-0 card-header"><div class="card-title h5">Access Group  Detail</div></div>
                            <Card.Body>
                                <Form.Row className="justify-content-left">
                                    <Col md={12} lg={12}>
                                        <Form.Group>
                                            <Form.Label>Access Group Name : <strong>  {GPageACCName == '' ? 'N/A' : GPageACCName}</strong></Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="justify-content-left">
                                    {ViewAccessGroups.map((item) => {
                                        return (
                                            <>
                                                <Col lg={6} md={6}>
                                                    <span className="form-control-plaintext" >{item.GroupPageNames == '' ? 'N/A' : item.GroupPageNames}</span>
                                                </Col>
                                            </>
                                        )
                                    })}
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row> : null
                }
                <Row>
                    <Col md={12}>
                        <Card>
                            <div class="pb-0 card-header"><div class="card-title h5">Access Group Location Mapping Detail</div></div>
                            <Card.Body>
                                <Form.Row>
                                    <Col md={3} lg={3}>
                                        <Form.Group>
                                            <Form.Label>State<span className='text-required'>*</span></Form.Label>
                                            <MultiSelect id="stateSelect" name="stateSelect"
                                                options={LocationStateList}
                                                value={selectedGlocationStateList}
                                                onChange={stateSelectionHandler}
                                                valueRenderer={customStateValRenderer}
                                                labelledBy="Select"
                                            />
                                            {globallocationStateList.length == 0 ? (<div className='text-required'>{"State Selection is Required"}</div>) : null}
                                        </Form.Group>
                                    </Col>
                                    {globallocationStateList.length > 0 && LocationRegionList.length > 0 && <>
                                        <Col md={3} lg={3}>
                                            <Form.Group>
                                                <Form.Label>Region<span className='text-required'>*</span></Form.Label>
                                                <MultiSelect id="regionSelect" name="regionSelect"
                                                    options={LocationRegionList}
                                                    value={selectedGlocationRegionList}
                                                    onChange={RegionSelectionHandler}
                                                    valueRenderer={RegionValueRenderer}
                                                    labelledBy="Select"
                                                />

                                                {LocationRegionList.length > 0 && LGlbLocationRegionList.length == 0 ? (<div className='text-required'>{"Region Selection is Required"}</div>) : null}

                                            </Form.Group>
                                        </Col></>}
                                    {GroupModuleId === "2" ?
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Centres<span className='text-required'>*</span></Form.Label>
                                                <MultiSelect id="centreIds" name="centreIds" options={centresList} labelledBy="Select centres" value={selectedCentres} onChange={CentreChangeHandler} />
                                                {(centresList.length == 0) || (glbSelectedCentreIds.length == 0)  ? (<div className='text-required'>{"Centre is Required"}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        : null}
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className='mt15'>
                    <Col md={12} className='text-center'>
                        <Button variant='secondary' className="mr-2" onClick={PrevBckHandler} >Cancel</Button>
                        {<Button variant='primary' type='submit' onClick={errorHandler}>Save</Button>}
                    </Col>
                </Row>
            </Container>
            {IsUserView &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true} size="lg">
                    <ModalHeader toggle={toggleModal}>View Access Group Detail</ModalHeader>
                    <ModalBody>
                        {ViewAccessGroups != null ? <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <Form.Row className="justify-content-left">
                                            {ViewAccessGroups.map((item) => {
                                                return (
                                                    <>
                                                        <Col lg={6} md={6}>
                                                            <span className="form-control-plaintext" >{item.GroupPageNames == '' ? 'N/A' : item.GroupPageNames}</span>
                                                        </Col>
                                                    </>
                                                )

                                            })}
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row> : null
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            }
            {errorModal &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                    <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                    <ModalBody>
                        <p>
                            {formik.touched.userPlatform && formik.errors.userPlatform ? (<div className='text-required'>{formik.errors.userPlatform}</div>) : null}
                        </p>
                        <p>
                            {formik.values.partnertype == '' && IsPartnerTypeShow ? (<div className='text-required'>Partner Type required</div>) : null}
                        </p>
                        <p>
                            {formik.touched.Users && formik.errors.Users && IsSearchUsers ? (<div className='text-required'>{formik.errors.Users}</div>) : null}
                        </p>
                        <p>
                            {globallocationStateList.length == 0 ? (<div className='text-required'>{"State is required"}</div>) : null}
                        </p>
                        <p>
                            {GGLocationRegionList.length > 0 && LGlbLocationRegionList.length == 0 ? (<div className='text-required'>{"Region is required"}</div>) : null}
                        </p>
                        <p>
                            {GroupModuleId === "2" && glbSelectedCentreIds.length == 0 ? (<div className='text-required'>{"Centre is required"}</div>) : null}
                        </p>
                        <p>
                            {formik.touched.module && formik.errors.module && userModuleShow ? (<div className='text-required'>{formik.errors.module}</div>) : null}
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                        <Button variant="primary" onClick={toggleModal}>Ok</Button>
                    </ModalFooter>
                </Modal>
            }
        </Fragment>
    )
}

export default EditAccessGroupLocation;