
import React, { useState, useEffect, useRef,Fragment } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { URL_Start} from '../../../constant';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import DoctorWise from '../commonComponent/DoctorBusinessMapped/DoctorWise';
import DoctorSalesMWise from '../commonComponent/DoctorBusinessMapped/DoctorSalesMWise';
const DoctorToBusinessMapping = () => {
    const GlobalBusinessSelection=window.GlobalBusiness;
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const[DoctorBusinessDetails,SetDoctorBusinessDetails]=useState(0);
// const[GlobalBusinessSelection,SetGlobalBusinessSelection]=useState(0);
const [errorModal, setErrorModal] = useState();
const[BusinessDocMapped,SetBusinessDocMapped]=useState(0);
useEffect(() => {
    //SetGlobalBusinessSelection([...GlobalBusiness]);
    //GlobalBusinessSelection=GlobalBusiness;
     if(GlobalBusinessSelection=="0")
     { SetBusinessDocMapped(0);}
    console.log(GlobalBusinessSelection); 
     
}, [GlobalBusinessSelection]);
    useEffect(() => {
      
           asyncFunDoctorBusinessDDLDetails();
    }, []);

   
   
      const asyncFunDoctorBusinessDDLDetails =async()=> {
        try {
         
        let url=URL_Start+ 'doctormaster/getDoctorToBusinessDDL';
        let options = {
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {}
        };
        let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if(data.Success==true)
                { let ds=data.Data;
                    if(ds!=null && ds.length>0)
                    {
                        SetDoctorBusinessDetails(ds.map((option) =>(<option value={option.DrBusinessId}>{option.DrBusinessName}</option>)));               
                    }
                }
                else{
                    let Errtext=<p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                    console.log('no record found');
                }
               
              
            }
            else{
                let Errtext=<p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
            }
         // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
         }
      }


     
  
      /********** Modals  ***********/
      const [modal, setModal] = useState();
      const toggleModal = () => {
          setModal(!modal)
      }         
      function onKeyDown(keyEvent) {
          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
          }
        }

        const drbusinessChangeHandler= (e) => {
                const s1 = e.target.value;
                window.GlobalBusiness=s1;
                window.Activeflag=false;
                //GlobalBusinessSelection=s1;
             //SetGlobalBusinessSelection(s1);
             SetBusinessDocMapped(s1);
        }
      
  const ErrorFunction = async (ErrorCode) => {
    if (ErrorCode == "400")
        SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
    else if (ErrorCode == "401")
        SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
    else if (ErrorCode == "403")
        SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
    else if (ErrorCode == "404")
        SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
    else if (ErrorCode == "500")
        SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
    else if (ErrorCode == "503")
        SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
}
    return (
        <Fragment>
            <Breadcrumb title="Doctor Business Mapping" parent="Master" />
            <Container fluid>

                <Row>
                    <Col md={12}>
                        <Card>
                        <div class="pb-0 card-header"><div class="card-title h5">Add Doctor Business Mapping</div></div>
                            <Card.Body>
                            <Form.Row className="justify-content-center">
                                       
                                       <Col md={6} lg={6}>
                                               <Form.Group>
                                                               <Form.Label>Mapping By</Form.Label>
                                                               <Form.Control as="select"  name="drbusinessmap"  onChange={drbusinessChangeHandler} value={BusinessDocMapped}>
                                                               <option value='0'>---select---</option>
                                                             {DoctorBusinessDetails}
                                                               </Form.Control>
                                                           </Form.Group>
                                          </Col>
                                        
                                       </Form.Row>
                                       {/* {GlobalBusinessSelection==1&& <>
                                        <Form.Row className="justify-content-center">
                                       
                                       <Col md={6} lg={6}>
                                           
                                       <Form.Group>
                                                               <Form.Label>Department<span className='text-required'>*</span></Form.Label>
                                                               <Form.Control as="select"  name="department"  onChange={departmentChangeHandler} onBlur={formik.handleBlur} value={formik.values.department}>
                                                               <option value='0'>---select---</option>
                                                             {DepartmentDetail}
                                                               </Form.Control>
                                                               {formik.touched.department && formik.errors.department ? (<div className='text-required'>{formik.errors.department}</div>) : null}
                                                           </Form.Group>
                                             
                                          </Col>
                                        
                                       </Form.Row>
                                     
                                  
                                       <Form.Row className="justify-content-center">
                                       
                                       <Col md={6} lg={6}>
                                               <Form.Group>
                                                               <Form.Label>Employee<span className='text-required'>*</span></Form.Label>
                                                               {isButton?<Spinner animation="border" size='sm' />:null} 
                                                               <Typeahead
                                                    id="Employee" name="Employee"
                                                    //clearButton
                                                    defaultSelected={EmployeeDLists.slice(0, 5)}
                                                    labelKey="name"
                                                    ref={typeahead}
                                                    options={EmployeeDLists}
                                                    value={formik.values.Employee}
                                                     // onChange is on valid option selection (a menu click)
  onChange={empGroupChangeHandler}
 
  // onBlur is on leaving the control. Can be used for error checks
  onBlur={empGroupBlurHandler}
                                                    placeholder="---select---"
                                                />
                                                               {formik.touched.Employee && formik.errors.Employee ? (<div className='text-required'>{formik.errors.Employee}</div>) : null}
                                                           </Form.Group>
                                                           
                                          </Col>
                                        
                                       </Form.Row>
                                       <Form.Row className="justify-content-center">
                                       
                                       <Col md={6} lg={6}>
                                               <Form.Group>
                                                               <Form.Label>Doctors<span className='text-required'>*</span></Form.Label>
                                                               {isLoading?<Spinner animation="border" size='sm' />:null} 
                                                               <AsyncTypeahead
                                                    id="LDoctor" name="LDoctor"
                                                    //clearButton
                                                    labelKey="name"
                                                   
                                                    multiple
                                                    minLength={3}
                                                    maxResults={Number(PageHelper.PageSize)-1}
                                                    paginate
                                                    ref={typeaheadAsycDoctor}
                                                    onInputChange={handleInputChange}
                                                    onPaginate={handlePagination}
                                                    onSearch={asyncFundoctorFilterDetails}
                                                    options={DoctorFList}
                                                    value={formik.values.LDoctor}
                                                     // onChange is on valid option selection (a menu click)
  onChange={option => {
      const value = (option.length > 0) ?option[0].name : '';
      formik.setFieldValue('LDoctor', value);
      const TypeOpt=[];
                      
          option.map((item) =>{
                        const TypeOptrows={
                            'CityId':item.id,
                            'DoctorId':'0'
                        }
                     return TypeOpt.push(TypeOptrows);
                        });
                        SetDoctorTyOption(TypeOpt);  
                     }}
                        onBlur={(e) => {
                            if(formik.values.LDoctor=='' || formik.values.LDoctor==null)
                        formik.setFieldTouched('LDoctor',true);
                            else
                            formik.errors.LDoctor="";

                        }}
                                                    placeholder="Location OR Doctor Name OR Doctor Mobile OR Specialization ....."
                                                />
                                                               {formik.touched.LDoctor && formik.errors.LDoctor ? (<div className='text-required'>{formik.errors.LDoctor}</div>) : null}
                                                           </Form.Group>
                                                           
                                          </Col>
                                        
                                       </Form.Row>
                           </>
                           } 

                                         {GlobalBusinessSelection==2&& <>
                                            <Form.Row className="justify-content-center">
                                       
                                       <Col md={6} lg={6}>
                                               <Form.Group>
                                                               <Form.Label>Doctors<span className='text-required'>*</span></Form.Label>
                                                               {isLoading?<Spinner animation="border" size='sm' />:null} 
                                                               <AsyncTypeahead
                                                    id="LDoctor" name="LDoctor"
                                                    //clearButton
                                                    labelKey="name"
                                                   
                                                    
                                                    minLength={3}
                                                    maxResults={Number(PageHelper.PageSize)-1}
                                                    paginate
                                                    ref={typeaheadAsycDoctor}
                                                    onInputChange={handleInputChange}
                                                    onPaginate={handlePagination}
                                                    onSearch={asyncFundoctorFilterDetails}
                                                    options={DoctorFList}
                                                    value={formik.values.LDoctor}
                                                     // onChange is on valid option selection (a menu click)
  onChange={option => {
      const value = (option.length > 0) ?option[0].name : '';
      formik.setFieldValue('LDoctor', value);
      const TypeOpt=[];
                      
          option.map((item) =>{
                        const TypeOptrows={
                            'CityId':item.id,
                            'DoctorId':'0'
                        }
                     return TypeOpt.push(TypeOptrows);
                        });
                        SetDoctorTyOption(TypeOpt);  
                     }}
                        onBlur={(e) => {
                            if(formik.values.LDoctor=='' || formik.values.LDoctor==null)
                        formik.setFieldTouched('LDoctor',true);
                            else
                            formik.errors.LDoctor="";

                        }}
                                                    placeholder="Location OR Doctor Name OR Doctor Mobile OR Specialization ....."
                                                />
                                                               {formik.touched.LDoctor && formik.errors.LDoctor ? (<div className='text-required'>{formik.errors.LDoctor}</div>) : null}
                                                           </Form.Group>
                                                           
                                          </Col>
                                        
                                       </Form.Row>
                                        <Form.Row className="justify-content-center">
                                       
                                       <Col md={6} lg={6}>
                                           
                                       <Form.Group>
                                                               <Form.Label>Department<span className='text-required'>*</span></Form.Label>
                                                               <Form.Control as="select"  name="department"  onChange={departmentChangeHandler} onBlur={formik.handleBlur} value={formik.values.department}>
                                                               <option value='0'>---select---</option>
                                                             {DepartmentDetail}
                                                               </Form.Control>
                                                               {formik.touched.department && formik.errors.department ? (<div className='text-required'>{formik.errors.department}</div>) : null}
                                                           </Form.Group>
                                             
                                          </Col>
                                        
                                       </Form.Row>
                                     
                                  
                                       <Form.Row className="justify-content-center">
                                       
                                       <Col md={6} lg={6}>
                                               <Form.Group>
                                                               <Form.Label>Employee<span className='text-required'>*</span></Form.Label>
                                                               {isButton?<Spinner animation="border" size='sm' />:null} 
                                                               <Typeahead
                                                    id="Employee" name="Employee"
                                                    //clearButton
                                                    defaultSelected={EmployeeDLists.slice(0, 5)}
                                                    labelKey="name"
                                                    ref={typeahead}
                                                    options={EmployeeDLists}
                                                    value={formik.values.Employee}
                                                     // onChange is on valid option selection (a menu click)
  onChange={empGroupChangeHandler}
 
  // onBlur is on leaving the control. Can be used for error checks
  onBlur={empGroupBlurHandler}
                                                    placeholder="---select---"
                                                />
                                                               {formik.touched.Employee && formik.errors.Employee ? (<div className='text-required'>{formik.errors.Employee}</div>) : null}
                                                           </Form.Group>
                                                           
                                          </Col>
                                        
                                       </Form.Row>
                                
                           </>
                           }       */}
                                       
                               
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>   
                {/* {GlobalBusinessSelection!=0&& <>
                    <Row className='mt15'>
                                    <Col md={12} className='text-center'>
                                        <Button variant='secondary' className="mr-2"  onClick={PrevBckHandler} >Cancel</Button>
                                        {<Button variant='primary' type='submit' onClick={errorHandler}>Save</Button>}
                                    </Col>
                                </Row>
                </>}   */}

                 {GlobalBusinessSelection==1&& <>
                    <DoctorSalesMWise/>  
                </>}
                {GlobalBusinessSelection==2&& <>
           <DoctorWise/>
                </>}                      

            </Container>


          

            {errorModal &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                    <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                    <ModalBody>
                       <p>
                        {/* {formik.touched.userPlatform && formik.errors.userPlatform ? (<div className='text-required'>{formik.errors.userPlatform}</div>) : null} */}
                        </p>
                      
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                        <Button variant="primary" onClick={toggleModal}>Ok</Button>
                    </ModalFooter>
                    
                </Modal>
            }
        </Fragment>
    )
}


export default DoctorToBusinessMapping;