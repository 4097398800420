import React, { useEffect, useState, useRef } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Breadcrumb from '../../../components/common/breadcrumb';
import DynamicDataTables from '../commonComponent/DataTable/DynamicDataTable';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ViewToolTip from '../UI/ToolTips/ViewToolTip';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip';
import EditToolTip from '../UI/ToolTips/EditToolTip';
import NewToolTip from '../UI/ToolTips/NewToolTip';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useFormik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { MultiSelect } from 'react-multi-select-component';
import '../../../pages/admin/react-multiselect.css';
import Select from 'react-select';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import DataTables from '../commonComponent/DataTable/DataTables';

const Users = () => {
    let textSearch = useRef();
    const navigate = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isButton, setIsButton] = useState(false);
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const [PerUser, SetPerUser] = useState(0);
    const [isposdetails, setIsPOSDetails] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [posmachinelist, setPOSMachineList] = useState([]);
    const [userid, setUserId] = useState("0");
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [PageHelper, SetPageHelper] = useState({
        PageNo: '1',
        PageSize: '10',
        TotalRecord: 0,
        dsUserDlists: []

    });
    const [IsUserView, SetIsUserView] = useState(false);
    const [IsUserEdit, SetIsUserEdit] = useState(false);
    const [IsUserEditDetails, SetIsUserEditDetails] = useState(false);
    const [modal, setModal] = useState();
    const [posmodal, setPOSModal] = useState();
    const toggleModal = () => {
        setModal(!modal)
    }
    const togglePOSModal = () => {
        setPOSModal(!posmodal)
    }
    const [EditUserDetails, SetEditUserDetails] = useState(null);
    const [EditAllDetails, SetEditAllDetails] = useState(null);
    const [posSelected, setPOSSelected] = useState([]);
    const paginationOptions = {

        totalSize: PageHelper.TotalRecord,
        sizePerPage: PageHelper.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',

        onSizePerPageChange: (sizePerPage, page, event) => {
            console.log('Size per page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            BindGrid(page, sizePerPage, textSearch.current.value.toLowerCase());
            document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
        },
        onPageChange: (page, sizePerPage) => {
            console.log('Page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            BindGrid(page, sizePerPage, textSearch.current.value.toLowerCase());
        }
    };
    const paginationOptions2 = {
        sizePerPage: 50,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const ViewFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div >
                <ViewToolTip title="View" onClick={() => ViewHandler(rowData.UserId)} />
                {/* {
                    rowData.UserPlatformId == "2" ? <EditToolTip title="Update SAP Code" disabled/> :
                    <EditToolTip title="Update SAP Code" onClick={() => editHandler(rowData.UserId)} />
                } */}
                <EditToolTip title="Update SAP Code" onClick={() => editHandler(rowData.UserId)} />
                <NewToolTip title="Map POS Machine" placement="top" id={`POS${rowData.UserId}`} icon="fa fa-calculator" onClick={() => editPOSHandler(rowData.UserId)} />
            </div>
        </React.Fragment>
    }
    const mappingFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{rowData.UserPlatformName.toLowerCase() == "partner" ? "No" : "Yes"}</span>
            </div>
        </React.Fragment>
    }

    const columns = [{
        dataField: '#',
        text: '#',
        formatter: (cell, row, rowIndex) => {
            let rowNumber = (PageHelper.PageNo - 1) * PageHelper.PageSize + (rowIndex + 1);
            return <span>{rowNumber}</span>;
        },
        headerStyle: (colum, colIndex) => {
            return { width: '5%' };
        }

    },
    {
        dataField: 'UserName',
        text: 'UserName',
        editable: false

    },
    {
        dataField: 'Email',
        text: 'LoginName',
        editable: false
    },
    {
        dataField: 'MobileNo',
        text: 'MobileNo',
        editable: false
    },
    // {
    //     dataField: 'Password',
    //     text: 'Password',
    //     editable: false
    // },
    {
        dataField: 'UserPlatformName',
        text: 'UserPlatform',
        editable: false
    }
        ,
    {
        dataField: 'UserPlatformName',
        text: 'Mapping Available',
        editable: false,
        formatter: mappingFormatter
    },
    {
        dataField: 'LastSyncDate',
        text: 'LastSyncDate',
        editable: false
    },
    {
        dataField: 'actions',
        isDummyField: true,
        text: 'Actions',
        formatter: ViewFormatter,
        headerStyle: (colum, colIndex) => {
            return { width: '5%' };
        }

    }
    ];

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
       
        BindGrid(1, 10, '');
    }, []);
    const handleSearch = async () => {
        SetPageHelper({
            PageNo: '1',
            PageSize: '10',
            TotalRecord: 0,
            dsUserDlists: []
        });
        BindGrid(1, 10, textSearch.current.value.toLowerCase());

    }
    const idPOSFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div><span>{crntPageNO + rowIndex + 1}</span></div>
        </React.Fragment>
    }
    const machineviewcolumn = [
        { dataField: '#', text: '#', editable: false, formatter: idPOSFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'label', text: 'POS Machine Name', editable: false, headerStyle: (colum, colIndex) => { return { width: '7%' }; } }
    ];
    const BindGrid = async (PageNo, PageSize, SearchTxtInput) => {
        setIsLoading(true);
      
        try {

            let url = URL_Start + 'users/getusersyncdetails';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { PageNo: `${PageNo}`, PageSize: `${PageSize}`, SearchTxt: `${SearchTxtInput}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;

                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                       
                        await SetPageHelper({
                            ...PageHelper,
                            PageNo: Number(PageNo),
                            PageSize: Number(PageSize),
                            TotalRecord: Number(ds.TotalRecord),
                            dsUserDlists: ds.objBindUsers == null ? [] : ds.objBindUsers
                        });

                        setIsLoading(false);
                    }
                    else {
                        SetPageHelper({
                            PageNo: '1',
                            PageSize: '10',
                            TotalRecord: 0,
                            dsUserDlists: []

                        });
                    }
                }
                else {
                    SetPageHelper({
                        PageNo: '1',
                        PageSize: '10',
                        TotalRecord: 0,
                        dsUserDlists: []
                    });
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');
                    setIsLoading(false);
                }

            }
            else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
            // return data;
        } catch (error) {
            if (error.response) {
                ErrorFunction(error.response.status);
                setIsLoading(false);
            }

        }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }

    const CustomlistHandler = () => {
        asyncFunhrmsSyncUsersList();
    }
    async function asyncFunhrmsSyncUsersList() {
        try {
            setIsButton(true);
            let url = URL_Start + 'users/updateEmployeeToUsersSyncList';

            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { AddedBy: `${LoginId}` }
            };

            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {

                    setTimeout(() => {
                        setIsButton(false);
                        BindGrid(1, 10, textSearch.current.value.toLowerCase());
                        SweetAlert.fire({ title: "Success!", text: "Latest Sync data has been successfully!", icon: "success" });
                    }, 2000);

                }
                else {
                    setIsButton(false);
                    if (data.ErrorList != null && data.ErrorList.length > 0) {

                        SweetAlert.fire({ title: "Error", text: data.ErrorList[0].errorMsg, icon: "error" });
                    }

                }


            }
            else {
                setIsButton(false);
                console.log('no record found');

            }
            // return data;
        } catch (error) {
            setIsButton(false);
            console.log(error.message);
            let Errtext = "";
            Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text: Errtext, icon: "error" });

        }
    }

    const ViewHandler = (UserId) => {
        setModal(true);
        setErrorModal(false);
        SetIsUserView(true);
        SetIsUserEdit(false);
        SetIsUserEditDetails(false);
        BindUserDetailByUID(UserId);
        setPOSSelected([]);
        GetPOSMachines(UserId);
    }
    async function BindUserDetailByUID(UserId) {
        try {

            let url = URL_Start + 'users/getuserdetailsbyUID';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { UserId: `${UserId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;

                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {

                        await SetEditUserDetails(ds);
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');

                }

            }
            else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
            // return data;
        } catch (error) {
            if (error.response) {
                ErrorFunction(error.response.status);

            }

        }
    }


    /********** Delete Confirm Alert  ***********/
    const Displayalert = (UserId) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        })
            .then((result) => {
                if (result.value && result.isConfirmed === true) {

                    UserDeletedDetailByUID(UserId);

                }
                else {
                    SweetAlert.fire(
                        'Your data is safe!'
                    )
                }
            })
    }

    async function UserDeletedDetailByUID(UserId) {
        try {

            let url = URL_Start + 'users/DeleteUsersDetailbyUID';

            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { AddedBy: `${LoginId}`, UserId: `${UserId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (data.Success == true && data.Data == "2000") {
                        SweetAlert.fire({ title: "Success!", text: "User has been deleted!", icon: "success" });
                        BindGrid(1, 10, '');
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                    console.log('no record found');
                }


            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
            }
            // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }

    // const editHandler = (UserId) => {

    //     SetIsUserView(false);
    //     formikEdit.resetForm();
    //     EditBindUserDetailByUID(UserId);


    // }
    /********** Update Success Alert  ***********/
    const updateHandler = () => {
        setModal(false);
        SetIsUserView(false);
        formikEdit.handleSubmit();

        // setModal(!modal);
        // SweetAlert.fire({ title: "Success!", text: "Data has been updated!", icon: "success" });
    }

    async function EditBindUserDetailByUID(UserId) {
        try {

            let url = URL_Start + 'users/getuserdetailsbyUID';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { UserId: `${UserId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;

                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {

                        formikEdit.values.txtmobile = ds.MobileNo;
                        formikEdit.values.txtPassword = ds.Password;
                        formikEdit.values.txtusername = ds.UserName;
                        formikEdit.values.txtemail = ds.Email;
                        formikEdit.values.UserId = UserId;
                    }
                    setModal(true);
                    SetIsUserEditDetails(true);
                    SetIsUserEdit(false);
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');

                }

            }
            else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
            // return data;
        } catch (error) {
            if (error.response) {
                ErrorFunction(error.response.status);

            }

        }
    }
    const [errorModal, setErrorModal] = useState();
    const editHandler = async (UserId) => {
        formikUsers.resetForm();
        formikUsers.values.sapcode = "";
        formikUsers.values.vendorCode = "";
        formikUsers.values.posCode = "";
        formikUsers.values.cashcode = "";
        formikUsers.values.UserId = "";
        formikUsers.values.paytmCode = "";
        formikUsers.values.bharatpeCode = "";
        formikUsers.values.phonepeCode = "";
        formikUsers.values.creditcardCode = "";
        formikUsers.values.debitcardCode = "";
        formikUsers.values.gpayCode = "";
        formikUsers.values.onlineCode = "";
        formikUsers.values.razorpayCode = "";
        setErrorModal(false);
        EditBinduserDetailsAll(UserId);
        SetIsUserView(false);
    }
    const editPOSHandler = async (UserId) => {
        setUserId(UserId);
        setPOSModal(true);
        setIsPOSDetails(true);
        setPOSMachineList([]);
        setPOSSelected([]);
        GetPOSMachines(UserId);
    }
    async function EditBinduserDetailsAll(UserId) {
        try {
            let url = URL_Start + 'users/getuserdetailsbyUID';
            let options = {
                method: 'POST', url: url,
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
                data: { UserId: `${UserId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        formikUsers.values.UserId = UserId;
                        if (ds.SAPCode != null && ds.SAPCode != '') { formikUsers.values.sapcode = ds.SAPCode; }
                        if (ds.CashCode != null && ds.CashCode != '') { formikUsers.values.cashcode = ds.CashCode; }
                        if (ds.VendorCode != null && ds.VendorCode != '') { formikUsers.values.vendorCode = ds.VendorCode; }
                        if (ds.POSCode != null && ds.POSCode != '') { formikUsers.values.posCode = ds.POSCode; }
                        if (ds.PaytmCode != null && ds.PaytmCode != '') { formikUsers.values.paytmCode = ds.PaytmCode; }
                        if (ds.BharatpeCode != null && ds.BharatpeCode != '') { formikUsers.values.bharatpeCode = ds.BharatpeCode; }
                        if (ds.PhonepeCode != null && ds.PhonepeCode != '') { formikUsers.values.phonepeCode = ds.PhonepeCode; }
                        if (ds.CreditcardCode != null && ds.CreditcardCode != '') { formikUsers.values.creditcardCode = ds.CreditcardCode; }
                        if (ds.DebitcardCode != null && ds.DebitcardCode != '') { formikUsers.values.debitcardCode = ds.DebitcardCode; }
                        if (ds.GpayCode != null && ds.GpayCode != '') { formikUsers.values.gpayCode = ds.GpayCode; }
                        if (ds.OnlineCode != null && ds.OnlineCode != '') { formikUsers.values.onlineCode = ds.OnlineCode; }
                        if (ds.RazorPayCode != null && ds.RazorPayCode != '') { formikUsers.values.razorpayCode = ds.RazorPayCode; }
                    }
                    await SetEditAllDetails(ds);
                    setModal(true);
                    SetIsUserEditDetails(true);
                    SetIsUserEdit(false);
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');
                }
            }
            else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
        } catch (error) { if (error.response) { ErrorFunction(error.response.status); } }
    }

    /********** Validations  ***********/
    const formikEdit = useFormik({
        initialValues: {
            txtmobile: '',
            txtPassword: '',
            txtusername: '',
            txtemail: '',
            UserId: '0'
        },
        validationSchema: Yup.object({

            txtPassword: Yup.string().required('Password is Required')
        }),
        isSubmitting: true,
        onSubmit: async (values) => {
            try {
                let url = URL_Start + 'users/UpdatedUsersDetailbyUID';

                let options = {
                    method: 'POST',
                    url: url,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                    data: { UserId: `${values.UserId}`, AddedBy: `${LoginId}`, MobileNo: `${values.txtmobile}`, Password: `${values.txtPassword}` }
                };

                let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                    let data = response.data;
                    // let data = await response.data;
                    if (data.Success == true) {
                        let ds = data.Data;
                        if (data.Success == true && data.Data == "2000") {
                            setModal(false);
                            SetIsUserView(false);
                            SetIsUserEdit(false);
                            SweetAlert.fire({ title: "Success!", text: "User has been updated!", icon: "success" });
                            BindGrid(1, 10, '');
                        }
                    }
                    else {
                        setModal(false);
                        SetIsUserView(false);
                        SetIsUserEdit(false);
                        let Errtext = <p><div className='text-required'> no record found </div></p>;
                        SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                        console.log('no record found');
                    }

                }
                else {
                    console.log('no record found');

                }
                // return data;
            } catch (error) {
                setModal(false);
                SetIsUserView(false);
                SetIsUserEdit(false);
                console.log(error.message);
                SweetAlert.fire({ title: "Error", text: 'There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.', icon: "error" });
            }
        },
    });

    /********** Validations  ***********/
    const formikUsers = useFormik({
        initialValues: { sapcode: '', cashcode: '', vendorCode: '', posCode: '', UserId: '0', paytmCode: '', bharatpeCode: '', phonepeCode: '', creditcardCode: '', debitcardCode: '', gpayCode: '', onlineCode: '',razorpayCode:'' },
        validationSchema: Yup.object({
            // sapcode: Yup.string().required('Customer Code required'),
            // cashcode: Yup.string().required('Cash Code required'),
            // vendorCode: Yup.string().required('Vendor Code required'),
            // posCode: Yup.string().required('POS Code required'),
            // paytmCode: Yup.string().required('Paytm Code required'),
            // bharatpeCode: Yup.string().required('Bharatpe Code required'),
            // phonepeCode: Yup.string().required('Axis Soundbox Code required'),
            // creditcardCode: Yup.string().required('Creditcard Code required'),
            // debitcardCode: Yup.string().required('Debitcard Code required'),
            // gpayCode: Yup.string().required('GPay Code required'),
            // razorpayCode: Yup.string().required('Razor Pay Code required')
        }),
        isSubmitting: true,
        onSubmit: async (values, actions) => {
            try {
                setModal(false);
                setErrorModal(false);
                SetIsUserView(false);
                let url = URL_Start + 'Users/UpdateUSERSAPCodeDetails';
                let options = {
                    method: 'POST',
                    url: url,
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
                    data: {
                        SAPCode: `${values.sapcode}`, CashCode: `${values.cashcode}`, VendorCode: `${values.vendorCode}`, POSCode: `${values.posCode}`, AddedBy: `${LoginId}`, UserId: `${values.UserId}`
                        , PaytmCode: `${values.paytmCode}`, BharatpeCode: `${values.bharatpeCode}`, PhonepeCode: `${values.phonepeCode}`, CreditcardCode: `${values.creditcardCode}`, DebitcardCode: `${values.debitcardCode}`,
                        GpayCode: `${values.gpayCode}`, OnlineCode: `${values.onlineCode}`,RazorPayCode: `${values.razorpayCode}`
                    }
                };
                let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                    let data = response.data;
                    if (data.Success == true && data.Data == "2000") {
                        formikUsers.resetForm();
                        SweetAlert.fire({ title: "Success!", text: "Codes has been Updated!", icon: "success" });
                        setErrorModal(false);
                    }
                    else {
                        if (data.ErrorList != null && data.ErrorList.length > 0) {
                            let Errtext = <p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                            SweetAlert.fire({ title: "Error", text: data.ErrorList[0].errorMsg, icon: "error" });
                        }
                    }
                }
                else { console.log('no record found'); }
            } catch (error) {
                console.log(error.message);
                let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
            }
            setTimeout(() => { actions.setSubmitting(false); }, 1000);
        },
    });
    const updateUserHandler = async () => {
        setModal(true);
        setErrorModal(true);
        SetIsUserEdit(false);
        formikUsers.handleSubmit();
    }
    const GetPOSMachines = async (UserId) => {
        setIsSaving(true);
        var glblArr = [];
        var glblSelectedArr = [];
        let url = URL_Start + 'master/getposmachinelist/' + UserId
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsSaving(false);
            if (data.Success) {
                if (data != null) {
                    var itmData = data.Data.commonselect;
                    var selectedData = data.Data.posinfolist;
                    if (itmData !== null) {
                        itmData.map((option) => { return glblArr.push({ 'value': option.Id, 'label': option.Value, 'disabled': false }); });
                        if (selectedData !== null) {
                            glblArr = glblArr.filter(el => {
                                return !selectedData.find(element => {
                                    return element.POSMachineId === el.value;
                                });
                            });
                        }
                        setPOSMachineList(glblArr);
                    }
                    if (selectedData !== null) {
                        selectedData.map((option) => { return glblSelectedArr.push({ 'value': option.POSMachineId, 'label': option.POSMachineName, 'disabled': false }); });
                        setPOSSelected(glblSelectedArr);
                    }
                }
                else {
                    setPOSMachineList([]);
                    setPOSSelected([]);
                }
            }
            else { SweetAlert.fire({ title: "Error", text: "No record found", icon: "error" }); setIsSaving(false); }
        }).catch(function (error) { setIsSaving(false); if (error.response) { ErrorFunction(error.response.status); } });
    }

    const POSChangeHandler = (options) => {
        // var isBreak = "0";
        // for (var key in options) {
        //     if (options[key].value === "0" && isBreak === "0") {
        //         isBreak = "1";
        //     }
        // }
        // if (isBreak === "1") {
        //     setPOSSelected(posmachinelist);
        // } else {
        //     setPOSSelected(options);
        // }
        setPOSSelected(options);
    }
    const SavePOSMappingDetails = async () => {
        if (posSelected.length === 0) { SweetAlert.fire("POS machine is required"); return false; }
        if (userid === "0") { SweetAlert.fire("UserId not found, Please refresh and try again"); return false; }
        setIsSaving(true);
        let arryPOS = [];
        for (var i in posSelected) { arryPOS.push({ 'POSMachineNameId': posSelected[i].value, 'POSMachineName': posSelected[i].label }); }
        const requestOptions = {
            POS: arryPOS,
            UserId: userid,
            AddedBy: localStorage.LoggedInUser
        };
        let url = URL_Start + 'master/saveposmappingdetails'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                setIsSaving(false);
                setPOSSelected([]);
                setPOSModal(false);
                SweetAlert.fire("Mapping saved succesfully");
            }
            else {
                if (data.Data == "-1998") { SweetAlert.fire("POS code not found. Please update pos code first."); }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsSaving(false); }
            }
        }).catch(function (error) { setIsSaving(false); });
    }

    return (
        <React.Fragment>
            <Breadcrumb title="Users" parent="Master" />
            <Container fluid>

                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row className="justify-content-center">

                                    <Col lg={4} md={6} className="col-8">
                                        <Form.Group>

                                            <InputGroup className="mb-3">
                                                <Form.Control placeholder="User Name..." ref={textSearch} />
                                                <InputGroup.Append>
                                                    <Button variant="secondary" onClick={handleSearch}><i className='fa fa-search'></i></Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2} md={6} className="col-4">
                                        <Form.Group>
                                            <InputGroup className="mb-3">

                                                <InputGroup.Append>
                                                    <Button variant='primary' type='submit' onClick={CustomlistHandler} >{isButton ? <Spinner animation="border" size='sm' /> : null} Sync Data </Button>

                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>User List</Card.Title>
                            </Card.Header>
                            <Card.Body>

                                {
                                    isLoading &&
                                    <div className='text-center'> <Spinner animation="border" size='sm' /></div>
                                }
                                <DynamicDataTables keyField="UserId" tableData={PageHelper.dsUserDlists} columns={columns} pagination={paginationOptions ? paginationFactory(paginationOptions) : null} />

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {IsUserView &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true} size="lg">
                    <ModalHeader toggle={toggleModal}>View User Detail</ModalHeader>
                    <ModalBody>
                        {EditUserDetails != null ? <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <Form.Row>
                                            <Col md={3}>
                                                <Form.Group>

                                                    <label className="col-form-label"><b>User Name</b></label>
                                                    <span className="form-control-plaintext" >{EditUserDetails.UserName == '' ? 'N/A' : EditUserDetails.UserName}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>Email</b></label>
                                                    <span className="form-control-plaintext" >{EditUserDetails.Email == '' ? 'N/A' : EditUserDetails.Email}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>MobileNo</b></label>
                                                    <span className="form-control-plaintext" >{EditUserDetails.MobileNo == '' ? 'N/A' : EditUserDetails.MobileNo}</span>
                                                </Form.Group>
                                            </Col>
                                            {/* <Col md={3}>
                                                <Form.Group>

                                                    <label className="col-form-label"><b>Password</b></label>
                                                    <span className="form-control-plaintext" >{EditUserDetails.Password == '' ? 'N/A' : EditUserDetails.Password}</span>
                                                </Form.Group>
                                            </Col> */}
                                            {EditUserDetails.UserPlatformId == "1" && <>
                                                <Col md={3}>
                                                    <Form.Group>
                                                        <label className="col-form-label"><b>EmployeeCode</b></label>
                                                        <span className="form-control-plaintext" >{EditUserDetails.EmployeeCode == '' ? 'N/A' : EditUserDetails.EmployeeCode}</span>
                                                    </Form.Group>
                                                </Col></>
                                            }
                                            <Col md={3}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>UserPlatform</b></label>
                                                    <span className="form-control-plaintext" >{EditUserDetails.UserPlatformName == '' ? 'N/A' : EditUserDetails.UserPlatformName}</span>
                                                </Form.Group>
                                            </Col>
                                            {EditUserDetails.UserPlatformId == "1" && <>
                                                <Col md={3}>
                                                    <Form.Group>

                                                        <label className="col-form-label"><b>DepartmentCode</b></label>
                                                        <span className="form-control-plaintext" >{EditUserDetails.DepartmentCode == '' ? 'N/A' : EditUserDetails.DepartmentCode}</span>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group>
                                                        <label className="col-form-label"><b>DepartmentName</b></label>
                                                        <span className="form-control-plaintext" >{EditUserDetails.DepartmentName == '' ? 'N/A' : EditUserDetails.DepartmentName}</span>
                                                    </Form.Group>
                                                </Col>

                                                <Col md={3}>
                                                    <Form.Group>
                                                        <label className="col-form-label"><b>Designation</b></label>
                                                        <span className="form-control-plaintext" >{EditUserDetails.Designation == '' ? 'N/A' : EditUserDetails.Designation}</span>
                                                    </Form.Group>
                                                </Col>
                                            </>}
                                            <Col md={3}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>PBX Status</b></label>
                                                    <span className="form-control-plaintext" >{EditUserDetails.IsPBXMapped == '' ? 'N/A' : EditUserDetails.IsPBXMapped}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>LDAP Status</b></label>
                                                    <span className="form-control-plaintext" >{EditUserDetails.IsLDAPMapped == '' ? 'N/A' : EditUserDetails.IsLDAPMapped}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>Branch Code</b></label>
                                                    <span className="form-control-plaintext" >{EditUserDetails.BranchCode == '' ? 'N/A' : EditUserDetails.BranchCode}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>Emp Group Code</b></label>
                                                    <span className="form-control-plaintext" >{EditUserDetails.EmpGroupCode == '' ? 'N/A' : EditUserDetails.EmpGroupCode}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>Location</b></label>
                                                    <span className="form-control-plaintext" >{EditUserDetails.FMLocation == '' ? 'N/A' : EditUserDetails.FMLocation}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>SAP Code</b></label>
                                                    <span className="form-control-plaintext" >{EditUserDetails.SAPCode == '' ? 'N/A' : EditUserDetails.SAPCode}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>Cash Code</b></label>
                                                    <span className="form-control-plaintext" >{EditUserDetails.CashCode == '' ? 'N/A' : EditUserDetails.CashCode}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>Vendor Code</b></label>
                                                    <span className="form-control-plaintext" >{EditUserDetails.VendorCode == '' ? 'N/A' : EditUserDetails.VendorCode}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>POS Code</b></label>
                                                    <span className="form-control-plaintext" >{EditUserDetails.POSCode == '' ? 'N/A' : EditUserDetails.POSCode}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>Paytm Code</b></label>
                                                    <span className="form-control-plaintext" >{EditUserDetails.PaytmCode == '' ? 'N/A' : EditUserDetails.PaytmCode}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>Bharatpe Code</b></label>
                                                    <span className="form-control-plaintext" >{EditUserDetails.BharatpeCode == '' ? 'N/A' : EditUserDetails.BharatpeCode}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>Axis Soundbox Code</b></label>
                                                    <span className="form-control-plaintext" >{EditUserDetails.PhonepeCode == '' ? 'N/A' : EditUserDetails.PhonepeCode}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>Creditcard Code</b></label>
                                                    <span className="form-control-plaintext" >{EditUserDetails.CreditcardCode == '' ? 'N/A' : EditUserDetails.CreditcardCode}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>Debitcard Code</b></label>
                                                    <span className="form-control-plaintext" >{EditUserDetails.DebitcardCode == '' ? 'N/A' : EditUserDetails.DebitcardCode}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>Gpay Code</b></label>
                                                    <span className="form-control-plaintext" >{EditUserDetails.GpayCode == '' ? 'N/A' : EditUserDetails.GpayCode}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>Online Code</b></label>
                                                    <span className="form-control-plaintext" >{EditUserDetails.OnlineCode == '' ? 'N/A' : EditUserDetails.OnlineCode}</span>
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>Razor Pay Code</b></label>
                                                    <span className="form-control-plaintext" >{EditUserDetails.RazorPayCode == '' ? 'N/A' : EditUserDetails.RazorPayCode}</span>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        {
                                            (posSelected == [] || posSelected.length == 0) ?
                                                null
                                                :
                                                <DataTables
                                                    keyField='value'
                                                    tableData={posSelected}
                                                    columns={machineviewcolumn}
                                                    noDataIndication={NoRecordFounds}
                                                    pagination={paginationFactory(paginationOptions2)}
                                                />
                                        }

                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row> : null
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            }

            {IsUserEdit &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true} >
                    <ModalHeader toggle={toggleModal}>Edit User Detail</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <Form.Row className="justify-content-center">

                                            <Col md={12}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>User Name</b></label>
                                                    <span className="form-control-plaintext" >{formikEdit.values.txtusername == '' ? 'N/A' : formikEdit.values.txtusername}</span>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row className="justify-content-center">

                                            <Col md={12}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>Email</b></label>
                                                    <span className="form-control-plaintext" >{formikEdit.values.txtemail == '' ? 'N/A' : formikEdit.values.txtemail}</span>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row className="justify-content-center">

                                            <Col md={12}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>MobileNo</b></label>
                                                    <Form.Control type='text' id="txtmobile" name="txtmobile" onChange={formikEdit.handleChange}
                                                        onBlur={formikEdit.handleBlur}
                                                        value={formikEdit.values.txtmobile} />
                                                    {formikEdit.touched.txtmobile && formikEdit.errors.txtmobile ? (
                                                        <div className='text-required'>{formikEdit.errors.txtmobile}</div>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row className="justify-content-center">

                                            <Col md={12}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>Password</b></label>
                                                    <Form.Control type='text' id="txtPassword" name="txtPassword" onChange={formikEdit.handleChange}
                                                        onBlur={formikEdit.handleBlur}
                                                        value={formikEdit.values.txtPassword} />
                                                    {formikEdit.touched.txtPassword && formikEdit.errors.txtPassword ? (
                                                        <div className='text-required'>{formikEdit.errors.txtPassword}</div>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>

                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="primary" onClick={updateHandler}  >Update</Button>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            }

            {IsUserEditDetails &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true} >
                    <ModalHeader toggle={toggleModal}>Update SAP Code and Cash Code Details</ModalHeader>
                    <ModalBody>
                        {EditAllDetails != null ?
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Body>
                                            <Form.Row className="justify-content-center">
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <label className="col-form-label"><b>User Name</b></label>
                                                        <span className="form-control-plaintext" >{EditAllDetails.UserName == null ? 'N/A' : EditAllDetails.UserName}</span>
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Form.Row className="justify-content-center">
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <label className="col-form-label"><b>Customer Code</b></label>
                                                        <Form.Control type='text' id="sapcode" name="sapcode" onChange={formikUsers.handleChange} onBlur={formikUsers.handleBlur}
                                                            value={formikUsers.values.sapcode} />
                                                        {formikUsers.touched.sapcode && formikUsers.errors.sapcode ? (<div className='text-required'>{formikUsers.errors.sapcode}</div>) : null}
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Form.Row className="justify-content-center">
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <label className="col-form-label"><b>Cash Code</b></label>
                                                        <Form.Control type='text' id="cashcode" name="cashcode" onChange={formikUsers.handleChange} onBlur={formikUsers.handleBlur}
                                                            value={formikUsers.values.cashcode} />
                                                        {formikUsers.touched.cashcode && formikUsers.errors.cashcode ? (<div className='text-required'>{formikUsers.errors.cashcode}</div>) : null}
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Form.Row className="justify-content-center">
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <label className="col-form-label"><b>Vendor Code</b></label>
                                                        <Form.Control type='text' id="vendorCode" name="vendorCode" onChange={formikUsers.handleChange} onBlur={formikUsers.handleBlur}
                                                            value={formikUsers.values.vendorCode} />
                                                        {formikUsers.touched.vendorCode && formikUsers.errors.vendorCode ? (<div className='text-required'>{formikUsers.errors.vendorCode}</div>) : null}
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Form.Row className="justify-content-center">
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <label className="col-form-label"><b>POS Code</b></label>
                                                        <Form.Control type='text' id="posCode" name="posCode" onChange={formikUsers.handleChange} onBlur={formikUsers.handleBlur}
                                                            value={formikUsers.values.posCode} />
                                                        {formikUsers.touched.posCode && formikUsers.errors.posCode ? (<div className='text-required'>{formikUsers.errors.posCode}</div>) : null}
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Form.Row className="justify-content-center">
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <label className="col-form-label"><b>Paytm Code</b></label>
                                                        <Form.Control type='text' id="paytmCode" name="paytmCode" onChange={formikUsers.handleChange} onBlur={formikUsers.handleBlur}
                                                            value={formikUsers.values.paytmCode} />
                                                        {formikUsers.touched.paytmCode && formikUsers.errors.paytmCode ? (<div className='text-required'>{formikUsers.errors.paytmCode}</div>) : null}
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Form.Row className="justify-content-center">
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <label className="col-form-label"><b>Bharat Pe Code</b></label>
                                                        <Form.Control type='text' id="bharatpeCode" name="bharatpeCode" onChange={formikUsers.handleChange} onBlur={formikUsers.handleBlur}
                                                            value={formikUsers.values.bharatpeCode} />
                                                        {formikUsers.touched.bharatpeCode && formikUsers.errors.bharatpeCode ? (<div className='text-required'>{formikUsers.errors.bharatpeCode}</div>) : null}
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Form.Row className="justify-content-center">
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <label className="col-form-label"><b>Axis Soundbox Code</b></label>
                                                        <Form.Control type='text' id="phonepeCode" name="phonepeCode" onChange={formikUsers.handleChange} onBlur={formikUsers.handleBlur}
                                                            value={formikUsers.values.phonepeCode} />
                                                        {formikUsers.touched.phonepeCode && formikUsers.errors.phonepeCode ? (<div className='text-required'>{formikUsers.errors.phonepeCode}</div>) : null}
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Form.Row className="justify-content-center">
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <label className="col-form-label"><b>Credit Card Code</b></label>
                                                        <Form.Control type='text' id="creditcardCode" name="creditcardCode" onChange={formikUsers.handleChange} onBlur={formikUsers.handleBlur}
                                                            value={formikUsers.values.creditcardCode} />
                                                        {formikUsers.touched.creditcardCode && formikUsers.errors.creditcardCode ? (<div className='text-required'>{formikUsers.errors.creditcardCode}</div>) : null}
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Form.Row className="justify-content-center">
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <label className="col-form-label"><b>Debit Card Code</b></label>
                                                        <Form.Control type='text' id="debitcardCode" name="debitcardCode" onChange={formikUsers.handleChange} onBlur={formikUsers.handleBlur}
                                                            value={formikUsers.values.debitcardCode} />
                                                        {formikUsers.touched.debitcardCode && formikUsers.errors.debitcardCode ? (<div className='text-required'>{formikUsers.errors.debitcardCode}</div>) : null}
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Form.Row className="justify-content-center">
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <label className="col-form-label"><b>GPay Code</b></label>
                                                        <Form.Control type='text' id="gpayCode" name="gpayCode" onChange={formikUsers.handleChange} onBlur={formikUsers.handleBlur}
                                                            value={formikUsers.values.gpayCode} />
                                                        {formikUsers.touched.gpayCode && formikUsers.errors.gpayCode ? (<div className='text-required'>{formikUsers.errors.gpayCode}</div>) : null}
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Form.Row className="justify-content-center">
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <label className="col-form-label"><b>Online Code</b></label>
                                                        <Form.Control type='text' id="onlineCode" name="onlineCode" onChange={formikUsers.handleChange} onBlur={formikUsers.handleBlur}
                                                            value={formikUsers.values.onlineCode} />
                                                        {formikUsers.touched.onlineCode && formikUsers.errors.onlineCode ? (<div className='text-required'>{formikUsers.errors.onlineCode}</div>) : null}
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Form.Row className="justify-content-center">
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <label className="col-form-label"><b>Razor Pay Code</b></label>
                                                        <Form.Control type='text' id="razorpayCode" name="razorpayCode" onChange={formikUsers.handleChange} onBlur={formikUsers.handleBlur}
                                                            value={formikUsers.values.razorpayCode} />
                                                        {formikUsers.touched.razorpayCode && formikUsers.errors.razorpayCode ? (<div className='text-required'>{formikUsers.errors.razorpayCode}</div>) : null}
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row> : null}
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="primary" onClick={updateUserHandler}  >Update</Button>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            }
            {isposdetails &&
                <Modal isOpen={posmodal} toggle={togglePOSModal} centered={true} >
                    <ModalHeader toggle={togglePOSModal}>Map POS Machine</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <Form.Row className="justify-content-center">
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label>POS Machine<span className='text-required'>*</span></Form.Label>
                                                    <Select options={[...posmachinelist]} isMulti={true}
                                                        onChange={POSChangeHandler}
                                                        value={posSelected}
                                                    />
                                                    {/* <MultiSelect id="posmachine"
                                                        name="posmachine"
                                                        // options={LocationStateList}
                                                        // value={selectedGlocationStateList}
                                                        // onChange={stateSelectionHandler}
                                                        // valueRenderer={customStateValRenderer}
                                                        labelledBy="Select" /> */}
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="primary" onClick={SavePOSMappingDetails}>Save</Button>
                        <Button variant="secondary" onClick={togglePOSModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            }
            {errorModal &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                    <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                    <ModalBody>
                        <p>{formikUsers.touched.sapcode && formikUsers.errors.sapcode ? (<div className='text-required'>{formikUsers.errors.sapcode}</div>) : null}</p>
                        <p>{formikUsers.touched.cashcode && formikUsers.errors.cashcode ? (<div className='text-required'>{formikUsers.errors.cashcode}</div>) : null}</p>
                        <p>{formikUsers.touched.vendorCode && formikUsers.errors.vendorCode ? (<div className='text-required'>{formikUsers.errors.vendorCode}</div>) : null}</p>
                        <p>{formikUsers.touched.posCode && formikUsers.errors.posCode ? (<div className='text-required'>{formikUsers.errors.posCode}</div>) : null}</p>
                        <p>{formikUsers.touched.paytmCode && formikUsers.errors.paytmCode ? (<div className='text-required'>{formikUsers.errors.paytmCode}</div>) : null}</p>
                        <p>{formikUsers.touched.bharatpeCode && formikUsers.errors.bharatpeCode ? (<div className='text-required'>{formikUsers.errors.bharatpeCode}</div>) : null}</p>
                        <p>{formikUsers.touched.phonepeCode && formikUsers.errors.phonepeCode ? (<div className='text-required'>{formikUsers.errors.phonepeCode}</div>) : null}</p>
                        <p>{formikUsers.touched.creditcardCode && formikUsers.errors.creditcardCode ? (<div className='text-required'>{formikUsers.errors.creditcardCode}</div>) : null}</p>
                        <p>{formikUsers.touched.debitcardCode && formikUsers.errors.debitcardCode ? (<div className='text-required'>{formikUsers.errors.debitcardCode}</div>) : null}</p>
                        <p>{formikUsers.touched.gpayCode && formikUsers.errors.gpayCode ? (<div className='text-required'>{formikUsers.errors.gpayCode}</div>) : null}</p>
                        <p>{formikUsers.touched.onlineCode && formikUsers.errors.onlineCode ? (<div className='text-required'>{formikUsers.errors.onlineCode}</div>) : null}</p>
                        <p>{formikUsers.touched.razorpayCode && formikUsers.errors.razorpayCode ? (<div className='text-required'>{formikUsers.errors.razorpayCode}</div>) : null}</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                        <Button variant="primary" onClick={toggleModal}>Ok</Button>
                    </ModalFooter>
                </Modal>
            }
        </React.Fragment>

    )
}

export default Users;