const config = {
    defaults: {
        namespace: ''
    },
    URL_Start:process.env.REACT_APP_URL_Start,
    Image_URL_Start:process.env.REACT_APP_Image_URL_Start,
    APP_StartTKey:process.env.REACT_APP_TokenStart,
    FrontDesk_URL:process.env.REACT_APP_FrontDesk_URL_Start,
    FrontDesk_Cyclotron_URL:process.env.REACT_APP_FrontDesk_Cyclotron_Start,
}

export default config;