import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { URL_Start } from '../../../constant';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import InputGroup from 'react-bootstrap/InputGroup';
import { MultiSelect } from 'react-multi-select-component';
import '../../../pages/admin/react-multiselect.css';
const ModewiseCollections = () => {
    let fromDate = useRef();
    let toDate = useRef();
    const [table, setTable] = useState([]);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [CSVData, setCSVData] = useState([]);
    const [defaultDate, setDefaultDate] = useState("");
    const [SelectCentreDDL, SetSelectCentreDDL] = useState([]);
    const [SelectCentres, SetSelectCentres] = useState([]);
    const [LGlCentreTypeoption, SetLGlCentreTypeoption] = useState([]);
    const [filtertype, setFilterType] = useState("1");
    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        GetBindCenterAllLists("2");
        BindGrid(defaultValue, defaultValue,"1");
    }, []);

    const paginationOptions = {
        sizePerPage: 100,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const columns = [{ dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    { dataField: 'VisitNo', text: 'VisitNo', editable: false },
    { dataField: 'OrderNo', text: 'Order No', editable: false },
    { dataField: 'BillNo', text: 'Bill No', editable: false },
    { dataField: 'CustomerName', text: 'Name', editable: false },
    { dataField: 'ClientName', text: 'Client Name', editable: false },
    { dataField: 'PartnerType', text: 'Partner Type', editable: false },
    { dataField: 'PaymentMode', text: 'Payment Mode', editable: false },
    { dataField: 'PaidAmount', text: 'Paid Amount', editable: false },
    { dataField: 'PaymentDate', text: 'Payment Date', editable: false },
    { dataField: 'CollectedBy', text: 'Collected By', editable: false },
    { dataField: 'EmployeeCode', text: 'Employee Code', editable: false },
    { dataField: 'BookingUserName', text: 'Booking Username', editable: false },
    { dataField: 'Panel', text: 'Panel', editable: false },
    { dataField: 'RatePlan', text: 'RatePlan', editable: false }
    ];
    const BindGrid = async (fromDate, toDate,filtertype) => {
        const  ObjServiceCollection= {
            UserId:localStorage.getItem('LoggedInUser'),
            FromDate:fromDate,
            ToDate:toDate,
            objtblCentreIds:LGlCentreTypeoption,
            FilterType: filtertype
        };        
        try {
            let url = URL_Start + 'reports/getmodewisecollectionreport'
            await axios.post(url,ObjServiceCollection).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) { setTable(data.Data); }
                    else { setTable([]); }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetReportData = async (fromDate, toDate,filtertype) => {
        const  ObjServiceCollection= {
            UserId:localStorage.getItem('LoggedInUser'),
            FromDate:fromDate,
            ToDate:toDate,
            objtblCentreIds:LGlCentreTypeoption,
            FilterType: filtertype
        };      
        setDownloadVisible(false);
        try {
            let url = URL_Start + 'reports/getmodewisecollectionreport'
            await axios.post(url,ObjServiceCollection).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null && data.Data.length > 0) {
                        setDownloadVisible(true);
                        setCSVData(data.Data);
                        document.getElementById("csvdownload").click();
                    }
                    else { setCSVData([]); }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const handleSearch = async () => {
        setCrntPageNO(0);
        BindGrid(fromDate.current.value, toDate.current.value,filtertype);
    }
    const handleDownload = async () => {
        setCrntPageNO(0);
        GetReportData(fromDate.current.value, toDate.current.value,filtertype);
    }
    const GetBindCenterAllLists = async (ftypevalue) => {
        const ObjFilterCentye = [];
        const requestOptions = {
            CentreFilterType: ftypevalue//filtertype
        };
        try {
            let url = URL_Start + 'master/getBindAllCentreListsNew'
            await axios.post(url, requestOptions).then((response) => { //ObjFilterCentye
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        let ds = data.Data;
                        const TypeOptionDs1 = [];
                        ds.map((option) => {
                            const TypeOptions1 = {
                                'CentreId': option.CentreId,
                                'label': option.CentreName,
                                'value': option.CentreId,
                                'CenterTypeId': option.CenterTypeId,
                                'disabled': false
                            }
                            return TypeOptionDs1.push(TypeOptions1);
                        });
                        SetSelectCentreDDL(TypeOptionDs1);

                    }
                    else { SetSelectCentreDDL([]); }
                }
            }).catch(function (error) {
                //ErrorFunction(error.response.status);
                SetSelectCentreDDL([]);
            });
        }
        catch (error) {
            SetSelectCentreDDL([]);
            SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const CentreHandler = (options) => {
        SetSelectCentres(options);
        const TypeOptL = [];
        if (options != null && options.length > 0) {
            options.map((item) => {
                const TypeOptrows = {
                    'CentreId': item.CentreId
                }
                return TypeOptL.push(TypeOptrows);
            });
            SetLGlCentreTypeoption(TypeOptL);
        }
        else {
            SetLGlCentreTypeoption([]);
        }
    }
    const AdvanceChkHandler = (e) => {
        SetSelectCentreDDL([]);
        setFilterType(e.target.value);
        GetBindCenterAllLists(e.target.value);
    }
    
    return (
        <React.Fragment>
            <Breadcrumb title="Modewise Collection" parent="Reports" />
            <Container fluid>
                <Row className='mt25'>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row className='justify-content-center mt-4'>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>From Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="fromDate" name="fromDate" ref={fromDate} defaultValue={defaultDate} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>To Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="toDate" name="toDate" ref={toDate} defaultValue={defaultDate} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}></Col>
                                </Form.Row>
                                {/* <Form.Row className='justify-content-center'>
                                    <Col md={9}>
                                        <div>
                                            <Form.Group className='advancepaymentradio'>
                                                <Form.Label><strong>Filter Data:</strong></Form.Label>
                                                <Form.Label>
                                                    <input name="chkAdvance" defaultChecked={true} type="radio" id="chkAdvance" onChange={AdvanceChkHandler} value={1} />All
                                                </Form.Label> &nbsp;  &nbsp;
                                                <Form.Label>
                                                    <input name="chkAdvance" type="radio" id="chkAdvance" onChange={AdvanceChkHandler} value={2} />Own
                                                </Form.Label> &nbsp;   &nbsp;
                                                <Form.Label>
                                                    <input name="chkAdvance" type="radio" id="chkAdvance" onChange={AdvanceChkHandler} value={3} />Third Party
                                                </Form.Label> &nbsp;   &nbsp;
                                                <Form.Label>
                                                    <input name="chkAdvance" type="radio" id="chkAdvance" onChange={AdvanceChkHandler} value={4} />Panel
                                                </Form.Label>
                                            </Form.Group>
                                        </div>
                                    </Col>
                                </Form.Row> */}
                                <Form.Row className='justify-content-center'>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Centre</Form.Label>
                                            <div className='txtMultiSelect1'>
                                                <MultiSelect id="CentreSelect" name="CentreSelect"
                                                    options={SelectCentreDDL}
                                                    value={SelectCentres}
                                                    onChange={CentreHandler}
                                                    labelledBy="Select"
                                                /></div>
                                        </Form.Group>
                                    </Col>

                                    <Col md={3} className='topmargin30'>
                                        <Button variant='secondary' onClick={handleSearch}>Search</Button>
                                        <Button className='ml-2' variant='primary' onClick={handleDownload}>Download CSV</Button>
                                    </Col>

                                </Form.Row>
                                <Form.Row className='justify-content-center mt-4'>  <Col lg={12} md={12}>
                                        {
                                            <DataTables
                                                keyField='Id'
                                                tableData={table}
                                                columns={columns}
                                                noDataIndication={NoRecordFounds}
                                                pagination={paginationFactory(paginationOptions)}
                                            />
                                        }
                                    </Col></Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    downloadvisible &&
                    <CSVLink data={CSVData} filename="ModewiseReport.csv" target="_blank" id="csvdownload"></CSVLink>
                }
            </Container>
        </React.Fragment>
    )
}

export default ModewiseCollections