import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import Breadcrumb from '../../components/common/breadcrumb';
import SweetAlert from 'sweetalert2';
import { URL_Start } from '../../constant';
import axios from 'axios';
import InputGroup from 'react-bootstrap/InputGroup';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Spinner } from 'react-bootstrap';
const Dashboard = () => {
    const [userName, setuserName] = useState(localStorage.UserName == undefined ? "" : localStorage.UserName);
    useEffect(() => { 



     }, []);
 
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
   
    return (
        <React.Fragment>
            <Breadcrumb title="Dashboard"   parent="Admin" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row className="justify-content-center">
                                    <Col lg={12} md={6}>
                                    <div class="text-center mt175" style={{height : "70vh"}}> <h1> Welcome {userName}</h1></div>
                                    </Col>
                                    
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
              
            </Container>
        </React.Fragment>
    )
}


export default Dashboard;