import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import SweetAlert from 'sweetalert2';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import LoadingButton from '../commonComponent/LoadingButton';
import DataTables from '../commonComponent/DataTable/DataTables';
import paginationFactory from 'react-bootstrap-table2-paginator';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import ToolTip from '../commonComponent/ToolTip';
import Select from 'react-select';

let routePhleboList = [];
const RoutePhleboMapping = () => {
    const [RouteMasterList, setRouteMasterList] = useState([]);
    const [PhleboList, setPhleboList] = useState([]);
    const [SelectedPhleboList, setSelectedPhleboList] = useState([]);
    const [RoutePhleboMapping, setRoutePhleboMapping] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [RouteID, setRouteID] = useState("0");
    const [RouteDisabled, setRouteDisabled] = useState(0);
    let refRouteID = useRef();
    useEffect(() => {
        GetRouteList();
        GetPhleboList();
        GetRoutePhleboMappingList();
    }, []);
    const RouteChangeHandler = (e) => {
        const val = e.target.value;
        setRouteID(e.target.value);
    }
    const GetRouteList = async (id) => {
        try {
            let url = URL_Start + 'master/getroutemasterlist/'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setRouteMasterList(data.Data.map((d) => (<option key={d.RouteID} value={d.RouteID}>{d.RouteName}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetPhleboList = async (id) => {
        try {
            let url = URL_Start + 'master/getphlebolist/';
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) {
                        const TypeOptionDs = [];
                        ds.map((option) => {
                            const TypeOptions = { 'label': option.EmployeeName, 'value': option.EmployeeID }
                            return TypeOptionDs.push(TypeOptions);
                        });
                        setPhleboList(TypeOptionDs);
                    }
                    else { setPhleboList([]); }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetRoutePhleboMappingList = async () => {
        let url = URL_Start + 'master/getroutephlebomappinglist/'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data != null) {
                    setRoutePhleboMapping(data.Data);
                }
                else {
                    setRoutePhleboMapping([]);
                }
            }
            else {
                setRoutePhleboMapping([]);
            }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const paginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <span>{crntPageNO + rowIndex + 1}</span>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <ToolTip title="Edit" placement="top" onClick={() => EditRoutePhleboMappingDetails(rowData)} linkId="Edit" faName="fa fa-pencil mr-2"></ToolTip>
                <ToolTip title="Delete" placement="top" onClick={() => DeleteRoutePhleboMapping(rowData.RouteID)} linkId="Delete" faName="fa fa-trash mr-2"></ToolTip>
            </div>
        </React.Fragment>
    }
    const columns =
        [
            { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
            { dataField: 'RouteName', text: 'Route Name', editable: false },
            { dataField: 'PhleboName', text: 'Phlebo Name', editable: false },
            { dataField: 'actions', isDummyField: true, text: 'Actions', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
        ];
    const SaveRoutePhleboMapping = async (RouteID, PhleboIds) => {
        setIsSaving(true);
        let arryPhelbos = [];
        for (var i in PhleboIds) { arryPhelbos.push({ 'PhleboID': PhleboIds[i].value }); }

        const requestOptions = {
            RouteID: RouteID,
            phlebos: arryPhelbos,
            AddedBy: localStorage.LoggedInUser
        };
        let url = URL_Start + 'master/saveroutephlebomapping'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                setIsSaving(false);
                if (RouteID != "0") { SweetAlert.fire("Route Phlebo Mapping updated successfully"); }
                else { SweetAlert.fire("Route Phlebo Mapping saved successfully"); }
                setRouteDisabled(0);
                setRouteID("0");
                routePhleboList = [];
                setSelectedPhleboList([]);
                GetRoutePhleboMappingList();
            }
            else {
                if (data.Data == "-1998") { setIsSaving(false); SweetAlert.fire("Route Phlebo Mapping already exists"); }
                else { setIsSaving(false); SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); }
            }
        }).catch(function (error) { setIsSaving(false); });
    }
    const EditRoutePhleboMappingDetails = async (rowData) => {
        setRouteDisabled(1);
        const requestOptions = {
            RouteID: rowData.RouteID
        };
        let url = URL_Start + 'master/getroutephlebomappingdetailsbyid'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                let ds = data.Data;
                routePhleboList = [];
                if (ds != null && ds.length > 0) {
                    ds.map((option) => {
                        const TypeOptions = { 'label': option.PhleboName, 'value': option.PhleboID }
                        return routePhleboList.push(TypeOptions);
                    });
                    setSelectedPhleboList(routePhleboList);
                }
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); }

        }).catch(function (error) { });
        setRouteID(rowData.RouteID);
    }
    const DeleteRoutePhleboMapping = async (RouteID) => {
        SweetAlert.fire({
            title: 'Warning',
            text: "You are going to delete an item. Are you sure?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            cancelButtonColor: "#000",
            reverseButtons: true
        })
            .then((result) => {
                if (result.value) { ConfirmedDeleteRoutePhleboMapping(RouteID); }
                else { SweetAlert.fire('Your data is safe!') }
            })
    }
    const ConfirmedDeleteRoutePhleboMapping = async (RouteID) => {
        const requestOptions = {
            RouteID: RouteID,
            AddedBy: localStorage.LoggedInUser
        };
        let url = URL_Start + 'master/deleteroutephlebomapping'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                SweetAlert.fire({
                    text: "Deleted",
                    confirmButtonText: 'Ok',
                    reverseButtons: true
                })
                    .then((result) => {
                        if (result.value) { GetRoutePhleboMappingList(); cancelHandler(); }
                        else { GetRoutePhleboMappingList(); cancelHandler(); }
                    })
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsSaving(false); }
        }).catch(function (error) { setIsSaving(false); });
    }
    const cancelHandler = async () => {
        setRouteDisabled(0);
        setRouteID("0");
        routePhleboList = [];
        setSelectedPhleboList([]);
        GetRoutePhleboMappingList();
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    const PhleboSelectionHandler = (options) => {
        setSelectedPhleboList(options);
    }
    const RoutePhleboMappingHandler = async () => {
        if (refRouteID.current.value == '' || refRouteID.current.value == '0') {
            SweetAlert.fire({ title: "Error", text: "Please select route name", icon: "error" });
        }

        if (SelectedPhleboList.length == 0) {
            SweetAlert.fire({ title: "Error", text: "Please select phlebo", icon: "error" });
        }
        else {
            SaveRoutePhleboMapping(refRouteID.current.value, SelectedPhleboList);
        }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Route Phlebo Mapping" parent="Master" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Form.Row className='justify-content-center'>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Route <span className='text-required'>*</span></Form.Label>
                                    <Form.Control as="select" name="RouteID" ref={refRouteID} value={RouteID} onChange={(e) => RouteChangeHandler(e)}
                                        disabled={RouteDisabled} >
                                        <option key="0" value="0">---Select---</option>
                                        {RouteMasterList}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Phlebo <span className='text-required'>*</span></Form.Label>
                                    <Select options={[...PhleboList]} isMulti={true}
                                        onChange={PhleboSelectionHandler}
                                        value={SelectedPhleboList}
                                    />
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    </Col>
                </Row>
                <Col md={12} className='text-center'>
                    <Form.Group>
                        <Button variant='secondary' className="mr-2" onClick={cancelHandler}>Cancel</Button>
                        {isSaving ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' onClick={RoutePhleboMappingHandler} >{
                            RouteID == 0 ? 'Save' : 'Update'
                        }</Button>}
                    </Form.Group>
                </Col>
                <Form.Row>
                    <Col md={12}>
                        <DataTables keyField='RoutePhleboMappingId' tableData={RoutePhleboMapping} columns={columns} pagination={paginationFactory(paginationOptions)} noDataIndication={NoRecordFounds} />
                    </Col>
                </Form.Row>
            </Container>
        </React.Fragment>
    )
}

export default RoutePhleboMapping