import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import SweetAlert from 'sweetalert2';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import LoadingButton from '../commonComponent/LoadingButton';

var glblArr = [];
const PackageMarkersDetails = (id) => {
    const history = useHistory();
    const [modal, setModal] = useState();
    const toggleModal = () => { setModal(!modal) }
    const [errorModal, setErrorModal] = useState();
    const errorHandler = () => { setModal(true); setErrorModal(true); }
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    useEffect(() => { GetDetails();}, []);
    const GetDetails = async () => {
        try {
            if (localStorage.getItem('pmId') !== null) {
                setIsLoading(true);
                formik.values.id = localStorage.getItem('pmId');
                let url = URL_Start + 'master/getpackagemarkerinfo/' + formik.values.id;
                await axios.get(url).then((response) => {
                    const data = response.data;
                    if (data.Success) {
                        const dbInfo = data.Data;
                        formik.values.markerTitle = dbInfo.MarkerTitle;
                        formik.values.markerDescription = dbInfo.MarkerDescription;
                        setIsLoading(false);
                    }
                }).catch(function (error) { setIsLoading(false); });
            }
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); setIsLoading(false); }
    }
    const formik = useFormik({
        initialValues: { id: '0', markerTitle: '', markerDescription: ''},
        validationSchema: Yup.object({
            markerTitle: Yup.string().required('Marker title is required'),
            markerDescription: Yup.string().required('Marker description is required'),
        }),
        onSubmit: values => { setModal(false); setErrorModal(false); AddInfo(values); }
    });
    const AddInfo = async (values) => {
        setIsSaving(true);
        const requestOptions = {
            Id: values.id,
            MarkerTitle: values.markerTitle,
            MarkerDescription: values.markerDescription,
            AddedBy: localStorage.LoggedInUser
        };
        let url = URL_Start + 'master/setpackagemarker'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                SweetAlert.fire({ title: "Success", text: "Data saved successfully", icon: "success" });
                formik.resetForm();
                localStorage.removeItem('pmId');
                history.push({ pathname: `${process.env.PUBLIC_URL}/master/packagemarkers`, search: `` });
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsSaving(false); }
        }).catch(function (error) { setIsSaving(false); });
    }
    const cancelHandler = async (CentreId) => {
        localStorage.removeItem('pmId');
        history.push({ pathname: `${process.env.PUBLIC_URL}/master/packagemarkers`, search: `` });
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Package Marker Details" parent="Master" />
            <Container fluid>
                {
                    isLoading ?
                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                        <>
                            <Form onSubmit={formik.handleSubmit}>
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <Card.Header className='pb-0'>
                                                <Card.Title>Details</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form.Row>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Title<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type='text' name="markerTitle" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.markerTitle} />
                                                            {formik.touched.markerTitle && formik.errors.markerTitle ? (<div className='text-required'>{formik.errors.markerTitle}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={9}>
                                                        <Form.Group>
                                                            <Form.Label>Description<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="text" name="markerDescription" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.markerDescription} />
                                                            {formik.touched.markerDescription && formik.errors.markerDescription ? (<div className='text-required'>{formik.errors.markerDescription}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className='mt15'>
                                    <Col md={12} className='text-center'>
                                        <Button variant='secondary' className="mr-2" onClick={cancelHandler}>Cancel</Button>
                                        {isSaving ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' type='submit' onClick={errorHandler}>Save</Button>}
                                    </Col>
                                </Row>
                            </Form>
                        </>
                }
                {errorModal &&
                    <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                        <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                        <ModalBody>
                            <p>{formik.touched.markerTitle && formik.errors.markerTitle ? (<div className='text-required'>{formik.errors.markerTitle}</div>) : null}</p>
                            <p>{formik.touched.markerDescription && formik.errors.markerDescription ? (<div className='text-required'>{formik.errors.markerDescription}</div>) : null}</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                            <Button variant="primary" onClick={toggleModal}>Ok</Button>
                        </ModalFooter>
                    </Modal>
                }
            </Container>
        </React.Fragment >
    )
}

export default PackageMarkersDetails;