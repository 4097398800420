import React from "react";
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import Alert from "react-bootstrap/Alert";
import DataTables from '../commonComponent/DataTable/DataTables';

class CustomerCollectionReport extends React.Component {

    render() {
        const columns = [
            // { dataField: 'ReceiptNo', text: '#', editable: false },
            // { dataField: 'ClientName', text: 'Client Name', editable: false },
            // { dataField: 'UserName', text: 'User Name', editable: false },
            // { dataField: 'ReceiptDate', text: 'Receipt Date', editable: false },
            // { dataField: 'BillNo', text: 'Bill No', editable: false },
            // { dataField: 'BillDate', text: 'Bill Date', editable: false },
            // // { dataField: 'ClientCode', text: 'Client Code', editable: false },
            // // { dataField: 'ClientName', text: 'Client Name', editable: false },
            // // { dataField: 'VisitingCode', text: 'Visiting Id', editable: false },
            // { dataField: 'PatientName', text: 'Patient Name', editable: false },
            // { dataField: 'PaidAmount', text: 'Paid Amount', editable: false },
            // { dataField: 'ISRefund', text: 'IS Refund', editable: false }
        { dataField: 'ReceiptNo', text: '#', editable: false ,  headerStyle: { width: '8%', fontSize: 7 }, style:{width: '8%', fontSize: 10 } },
        { dataField: 'ClientName', text: 'Client Name', editable: false ,  headerStyle: { width: '16%', fontSize: 7 }, style:{width: '16%', fontSize: 10 } }, 
        { dataField: 'UserName', text: 'User Name', editable: false ,  headerStyle: { width: '16%', fontSize: 7 }, style:{width: '16%', fontSize: 10 } }, 
        { dataField: 'ReceiptDate', text: 'Receipt Date', editable: false ,  headerStyle: { width: '8%', fontSize: 7 }, style:{width: '8%', fontSize: 10 } }, 
        { dataField: 'BillNo', text: 'Bill No', editable: false ,  headerStyle: { width: '8%', fontSize: 7 }, style:{width: '8%', fontSize: 10 } }, 
        { dataField: 'BillDate', text: 'Bill Date', editable: false ,  headerStyle: { width: '8%', fontSize: 7 }, style:{width: '8%', fontSize: 10 } }, 
        // { dataField: 'ClientCode', text: 'Client Code', editable: false },
        { dataField: 'VisitingCode', text: 'Visiting Id', editable: false ,  headerStyle: { width: '12%', fontSize: 7 }, style:{width: '12%', fontSize: 10 } }, 
        { dataField: 'PatientName', text: 'Patient Name', editable: false,  headerStyle: { width: '8%', fontSize: 7 }, style:{width: '8%', fontSize: 10 } }, 
        { dataField: 'PaidAmount', text: 'Paid Amount', editable: false,  headerStyle: { width: '8%', fontSize: 7 }, style:{width: '8%', fontSize: 10 } }, 
        { dataField: 'ISRefund', text: 'IS Refund', editable: false ,  headerStyle: { width: '8%', fontSize: 7 }, style:{width: '8%', fontSize: 10 } }
        ];
        const Usercolumns = [
            { dataField: 'UserName', text: 'User Name', editable: false  ,  headerStyle: { width: '16%', fontSize: 7 }, style:{width: '16%', fontSize: 10 } },
            { dataField: 'CentreName', text: 'Centre Name', editable: false,  headerStyle: { width: '20%', fontSize: 7 }, style:{width: '20%', fontSize: 10 }  },
            { dataField: 'Cash', text: 'Cash', editable: false  ,  headerStyle: { width: '8%', fontSize: 7 }, style:{width: '8%', fontSize: 10 } },
            { dataField: 'Paytm', text: 'Paytm', editable: false  ,  headerStyle: { width: '8%', fontSize: 7 }, style:{width: '8%', fontSize: 10 } },
            { dataField: 'BharatPay', text: 'BharatPay', editable: false  ,  headerStyle: { width: '8%', fontSize: 7 }, style:{width: '8%', fontSize: 10 } },
            { dataField: 'PhonePe', text: 'Axis Soundbox', editable: false  ,  headerStyle: { width: '8%', fontSize: 7 }, style:{width: '8%', fontSize: 10 } },
            { dataField: 'CreditCard', text: 'Credit Card', editable: false  ,  headerStyle: { width: '8%', fontSize: 7 }, style:{width: '8%', fontSize: 10 } },
            { dataField: 'DebitCard', text: 'Debit Card', editable: false  ,  headerStyle: { width: '8%', fontSize: 7 }, style:{width: '8%', fontSize: 10 } },
            { dataField: 'GPay', text: 'GPay', editable: false  ,  headerStyle: { width: '8%', fontSize: 7 }, style:{width: '8%', fontSize: 10 } },
            { dataField: 'Online', text: 'Online', editable: false  ,  headerStyle: { width: '8%', fontSize: 7 }, style:{width: '8%', fontSize: 10 } },
            { dataField: 'RPay', text: 'RPay', editable: false, headerStyle: { width: '8%', fontSize: 7 }, style: { width: '8%', fontSize: 10 } },
            { dataField: 'POS', text: 'POS', editable: false, headerStyle: { width: '8%', fontSize: 7 }, style: { width: '8%', fontSize: 10 } }
        ];
        const pdfProps = this.props;
        const apiData = pdfProps.APIData;
        return (
            <>
                <Container fluid>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Body>
                                    <Form>
                                        {/* <Form.Row>
                                            <Col md={12}>
                                                <h6 className='text-red'>Summary</h6>
                                                <Alert variant="dark" className="text-center">
                                                    <ul className="list-inline pointsInline">
                                                        <li className="list-inline-item">Name: <strong>{apiData.UserName}</strong></li>
                                                        <li className="list-inline-item">Total Sale: <strong>{apiData.Total}</strong></li>
                                                        <li className="list-inline-item">Cash: <strong>{apiData.Cash}</strong></li>
                                                        <li className="list-inline-item">Paytm: <strong>{apiData.Paytm}</strong></li>
                                                        <li className="list-inline-item">Bharat Pay: <strong>{apiData.BharatPay}</strong></li>
                                                        <li className="list-inline-item">Axis Soundbox: <strong>{apiData.PhonePe}</strong></li>
                                                        <li className="list-inline-item">Credit Card: <strong>{apiData.CreditCard}</strong></li>
                                                        <li className="list-inline-item">Debit Card: <strong>{apiData.DebitCard}</strong></li>
                                                        <li className="list-inline-item">GPay: <strong>{apiData.GPay}</strong></li>
                                                        <li className="list-inline-item">Online: <strong>{apiData.Online}</strong></li>
                                                    </ul>
                                                </Alert>
                                            </Col>
                                        </Form.Row> */}
                                        {apiData.Uinfo.length > 0 ?
                                            <Form.Row >
                                                <Col md={12} >
                                                <h6 className='text-red'>Summary</h6>
                                                <div class="alert alert-info p-4" id="UTable">
                                                    {<DataTables keyField='id' tableData={apiData.Uinfo} columns={Usercolumns} />}
                                                    </div>
                                                </Col>
                                            </Form.Row>
                                            : null} 
                                        {apiData.CashCollectionList.length > 0 ?
                                            <Form.Row className='mt15'>
                                                <Col md={12}>
                                                    <h6 className='text-red'>Cash Details</h6>
                                                    <div id="cashTable">
                                                    {<DataTables keyField='id' tableData={apiData.CashCollectionList} columns={columns} />}
                                                    </div>
                                                </Col>
                                            </Form.Row>
                                            : null}
                                        {apiData.PaytmCollectionList.length > 0 ?
                                            <Form.Row className='mt15'>
                                                <Col md={12}>
                                                    <h6 className='text-red'>Paytm Details</h6>
                                                    <div id="paytmTable">
                                                    {<DataTables keyField='id' tableData={apiData.PaytmCollectionList} columns={columns} />}
                                                    </div>
                                                </Col>
                                            </Form.Row>
                                            : null}
                                        {apiData.BharatPayCollectionList.length > 0 ?
                                            <Form.Row className='mt15'>
                                                <Col md={12}>
                                                    <h6 className='text-red'>Bharat Pay Details</h6>
                                                    <div id="bharatPayTable">
                                                    {<DataTables keyField='id' tableData={apiData.BharatPayCollectionList} columns={columns} />}
                                                    </div>
                                                </Col>
                                            </Form.Row>
                                            : null}
                                        {apiData.PhonePeCollectionList.length > 0 ?
                                            <Form.Row className='mt15'>
                                                <Col md={12}>
                                                    <h6 className='text-red'>Axis Soundbox Details</h6>
                                                    <div id="phonePeTable">
                                                    {<DataTables keyField='id' tableData={apiData.PhonePeCollectionList} columns={columns} />}
                                                    </div>
                                                </Col>
                                            </Form.Row>
                                            : null}
                                        {apiData.CreditCardCollectionList.length > 0 ?
                                            <Form.Row className='mt15'>
                                                <Col md={12}>
                                                    <h6 className='text-red'>Credit Card Details</h6>
                                                    <div id="creditCardTable">
                                                    {<DataTables keyField='id' tableData={apiData.CreditCardCollectionList} columns={columns} />}
                                                    </div>
                                                </Col>
                                            </Form.Row>
                                            : null}
                                        {apiData.DebitCardCollectionList.length > 0 ?
                                            <Form.Row className='mt15'>
                                                <Col md={12}>
                                                    <h6 className='text-red'>Debit Card Details</h6>
                                                    <div id="debitCardTable">
                                                    {<DataTables keyField='id' tableData={apiData.DebitCardCollectionList} columns={columns} />}
                                                    </div>
                                                </Col>
                                            </Form.Row>
                                            : null}
                                        {apiData.GPayCollectionList.length > 0 ?
                                            <Form.Row className='mt15'>
                                                <Col md={12}>
                                                    <h6 className='text-red'>GPay Details</h6>
                                                    <div id="gPayTable">
                                                    {<DataTables keyField='id' tableData={apiData.GPayCollectionList} columns={columns} />}
                                                    </div>
                                                </Col>
                                            </Form.Row>
                                            : null}
                                        {apiData.OnlineCollectionList.length > 0 ?
                                            <Form.Row className='mt15'>
                                                <Col md={12}>
                                                    <h6 className='text-red'>Online Details</h6>
                                                    <div id="onlineTable">
                                                    {<DataTables keyField='id' tableData={apiData.OnlineCollectionList} columns={columns} />}
                                                    </div>
                                                </Col>
                                            </Form.Row>
                                            : null}
                                        {apiData.RPayCollectionList.length > 0 ?
                                            <Form.Row className='mt15'>
                                                <Col md={12}>
                                                    <h6 className='text-red'>Razor Pay Details</h6>
                                                    <div id="onlineTable">
                                                    {<DataTables keyField='id' tableData={apiData.RPayCollectionList} columns={columns} />}
                                                    </div>
                                                </Col>
                                            </Form.Row>
                                            : null}
                                            {apiData.POSCollectionList.length > 0 ?
                                            <Form.Row className='mt15'>
                                                <Col md={12}>
                                                    <h6 className='text-red'>POS Details</h6>
                                                    <div id="onlineTable">
                                                    {<DataTables keyField='id' tableData={apiData.POSCollectionList} columns={columns} />}
                                                    </div>
                                                </Col>
                                            </Form.Row>
                                            : null}
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }
}

export default CustomerCollectionReport;