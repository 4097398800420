
import React, { useState, useEffect, useRef,Fragment } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import EditToolTip from '../UI/ToolTips/EditToolTip';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip';
import ActiveToolTip from '../UI/ToolTips/ActiveToolTip';
import InactiveToolTip from '../UI/ToolTips/InactiveToolTip';
import ViewToolTip  from '../UI/ToolTips/ViewToolTip';
import { URL_Start } from '../../../constant';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import InputGroup from 'react-bootstrap/InputGroup';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Spinner } from 'react-bootstrap';
import AddToolTip from '../UI/ToolTips/AddToolTip';
import SubtractToolTip from '../UI/ToolTips/SubtractToolTip';
import { Typeahead } from 'react-bootstrap-typeahead';
import {MultiSelect} from 'react-multi-select-component';
import '../../../pages/admin/react-multiselect.css';
import Select from 'react-select';
import { AlertTriangle } from 'react-feather';
 let globalModuleId="0";
 let globalPageSelection=false;
let globalUserId="0";
let globaltblAllGroups=[];
let globaltblExistGroups=[];
let globallocationStateList=[];
let LGlbLocationRegionList=[];
let LGLocationDistrictList=[];
 let GGLocationRegionList=[];
 let GGLocationDistrictList=[];
let stringStates="";
let stringStateIds="";
let stringRegions="";
let stringRegionIds="";
let stringDistricts="";
let stringDistrictIds="";
let SelectedAllStateFlag=false;
let SelectedAllregionFlag=false;
let GlobalDesignationMId="";
let GlobalCentreIds="";
let GlobalEmployeeIds="";
const EditCredit = () => {
    const[MaxDiscountField,SetMaxDiscountField]=useState("");
   
    const[SelectDiscountShareDDL, SetSelectDiscountShareDDL]=useState([]);
    const[SelectDiscountShare, SetSelectDiscountShare]=useState(null);
    const[SelectCentreDDL, SetSelectCentreDDL]=useState([]);
    const[SelectCentres, SetSelectCentres]=useState(null);
    const[LGlCentreTypeoption,SetLGlCentreTypeoption]=useState([]);
    const[MDesignationCreditId,SetMDesignationCreditId] = useState(localStorage.DesignationCreditId == undefined ? "" : localStorage.DesignationCreditId);
    const[DiscountDetailFDs,SetDiscountDetailFDs]=useState(null);
    const[DiscountCentreDs,SetDiscountCentreDs]=useState(null);
    const[IsButtonSubmit, SetIsButtonSubmit]=useState(false);
    
    
        let typeahead = useRef(null);  
        let textSearch = useRef();
        let isFirstRender= useRef(false);
        let isLastRender= useRef(false);
        let IsDefaultLocation=useRef(false);
        const history = useHistory();
        const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
        const[ErrorPopUp, setErrorPopUp]=useState(0);
 
        const [isLoading, setIsLoading] = useState(false);
        const [isButton, setIsButton] = useState(false);
    const [userModuleShow, SetuserModuleShow] = useState(false);
    const[TXtSearch,SetTXtSearch]=useState("");
    const[ViewAccessGroups,SetViewAccessGroups]=useState(null);
    const[IsUserView,SetIsUserView]=useState(false);
    const[LocationZoneId,SetLocationZoneId]=useState(1);
    const[LocationStateList,SetLocationStateList]=useState([]);
    const[LocationRegionList,SetLocationRegionList]=useState([]);
    const[selectedGlocationStateList,SetselectedGlocationStateList]=useState([]);
    const[selectedGlocationRegionList,SetselectedGlocationRegionList]=useState([]);
    const[selectedGlocationDistrictList,SetselectedGlocationDistrictList]=useState([]);
     const[LocationDistrictList,SetLocationDistrictList]=useState([]);
    const[SelectionglobaltblAllGroups,SetSelectionglobaltblAllGroups]=useState([]);
    const[VaildFlag,SetVaildFlag]=useState(false);
    const[FinalStage,SetFinalStage]=useState(false);
    const[LGLocationZoneList,SetLGLocationZoneList]=useState([{
        "zoneId":"1"
     }]);
        useEffect(() => {    
           
               asyncFunLocationBindDetails();
               asyncBindFunDiscountShareDDL();
               asyncEditBindFunDiscountEmployee();
        }, []);
       
        const  asyncEditBindFunDiscountEmployee=async()=> 
        {
           try {
            
               let url=URL_Start + 'master/getEditCreditDesignationEmployeedetails';
                   let options = {
                       method: 'POST',
                       url: url,
                       headers: {
                           'Accept': 'application/json',
                           'Content-Type': 'application/json;charset=UTF-8'
                       },
                       data: { DesignationCreditId:`${MDesignationCreditId}`}
                   };
                   let response = await axios(options);
                   let responseOK = response && response.status == 200;
                   if (responseOK) {
                       let data = response.data;
                       let GEmpId="";
                       // let data = await response.data;
                       if (data.Success == true) {
                           let ds = data.Data;
                           if (ds != null) {
                             if(ds.objBindCreditDetailFirst!=null)
                             {
                                SetDiscountDetailFDs(ds.objBindCreditDetailFirst);
                                formik.values.maxdiscount=ds.objBindCreditDetailFirst.MaxCreditPerMnthRs;
                                SetMaxDiscountField(ds.objBindCreditDetailFirst.MaxCreditPerMnthPercent);
                                const TypeOptions1={
                                    'DisShareId':ds.objBindCreditDetailFirst.DisShareId,
                                    'label':ds.objBindCreditDetailFirst.ShareType,
                                    'value':ds.objBindCreditDetailFirst.DisShareId,
                                     'disabled':false
                                }
                             SetSelectDiscountShare(TypeOptions1);
                             GEmpId=ds.objBindCreditDetailFirst.EmployeeId;
                             GlobalDesignationMId=ds.objBindCreditDetailFirst.DesignationId;
                            
                             }
                              else{
                                SetDiscountDetailFDs(null);
                              }

                              if(ds.ObjBindCreditCentreListByEmployee!=null)
                              {
                                GlobalCentreIds=ds.ObjBindCreditCentreListByEmployee.CentreId;
                                GlobalEmployeeIds=ds.ObjBindCreditCentreListByEmployee.EmployeeId;
                                
                                SetDiscountCentreDs(ds.ObjBindCreditCentreListByEmployee);
                                         const TypeOptionDs=[];
                                        const TypeOptions={
                                            'label':ds.ObjBindCreditCentreListByEmployee.StateName,
                                            'value':ds.ObjBindCreditCentreListByEmployee.SAPStateId,
                                            'StateId':ds.ObjBindCreditCentreListByEmployee.SAPStateId,
                                            'disabled':false
                                        }
                                         TypeOptionDs.push(TypeOptions);
                                SetselectedGlocationStateList(TypeOptionDs);  
                                globallocationStateList=[];
                                  SetLocationDistrictList([]);
                                  
                                        const TypeOptionsP1={
                                            'StateId':ds.ObjBindCreditCentreListByEmployee.SAPStateId
                                        }
                                       globallocationStateList.push(TypeOptionsP1);
                                    asyncFunRegionLocationBindDetails(true);

                                    const TypeOptionDs1=[];
                          
                                   
                                        const TypeOptions1={
                                            'label':ds.ObjBindCreditCentreListByEmployee.RegionName,
                                            'value':ds.ObjBindCreditCentreListByEmployee.SAPRegionId,
                                            'StateId':ds.ObjBindCreditCentreListByEmployee.SAPStateId,
                                            'RegionId':ds.ObjBindCreditCentreListByEmployee.SAPRegionId,
                                            'IsHaveDistrictStatus':true,
                                            'disabled':false
                                        }
                                     TypeOptionDs1.push(TypeOptions1);
                                    SetselectedGlocationRegionList(TypeOptionDs1);  
                                    LGlbLocationRegionList=[];
                                   
                                        const item = {
                                            "RegionId":ds.ObjBindCreditCentreListByEmployee.SAPRegionId
                                          };
                                          LGlbLocationRegionList.push(item);
                                    asyncFunDistinctLocationBindDetails(true);
                                    const TypeOptionDs2=[];
                                      const TypeOptions2={
                                            'label':ds.ObjBindCreditCentreListByEmployee.DistrictName,
                                            'value':ds.ObjBindCreditCentreListByEmployee.SAPDistrictId,
                                            'StateId':ds.ObjBindCreditCentreListByEmployee.SAPStateId,
                                            'RegionId':ds.ObjBindCreditCentreListByEmployee.SAPRegionId,
                                             'DistrictId':ds.ObjBindCreditCentreListByEmployee.SAPDistrictId,
                                            'disabled':false
                                        }
                                TypeOptionDs2.push(TypeOptions2);
                              SetselectedGlocationDistrictList(TypeOptionDs2);  

                              LGLocationDistrictList=[];
                              const TypeOptDocL=[];
                              
                                  const itemP1 = {
                                      "DistrictId":ds.ObjBindCreditCentreListByEmployee.SAPDistrictId
                                    };
                               LGLocationDistrictList.push(itemP1);
                                  asyncFunCentresbylocations(true);
                                  const TypeOptionsP2={
                                    'CentreId':ds.ObjBindCreditCentreListByEmployee.CentreId,
                                    'label':ds.ObjBindCreditCentreListByEmployee.CentreName,
                                    'value':ds.ObjBindCreditCentreListByEmployee.CentreId,
                                    'CenterTypeId':ds.ObjBindCreditCentreListByEmployee.CenterTypeId,
                                     'disabled':false
                                }
                                SetSelectCentres(TypeOptionsP2);
                                        const TypeOptrowsP1={
                                        'CentreId':  ds.ObjBindCreditCentreListByEmployee.CentreId
                                         }
                                SetLGlCentreTypeoption(TypeOptrowsP1);
                                
                                 
                                 
                              }
                              else{
                                SetDiscountCentreDs(null);
                              }
                       }
                       else {
                           let Errtext = <p><div className='text-required'> no record found </div></p>;
                       console.log('no record found');
                       
                       }
        
                   }
                   else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                console.log('no record found');
                
                    }  
                    }
                   else {
                    setErrorModal(false);  
                    SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
                   // return data;
               } catch (error) {
                   if (error.response) {
                    setErrorModal(false);  
                    ErrorFunction(error.response.status);
                      
               }
        
           }
           }  
    
      
    
      
    
        const  asyncBindFunDiscountShareDDL=async()=> 
              {
                 try {
                  
                     let url=URL_Start + 'master/getdiscountsharedetails';
                         let options = {
                             method: 'GET',
                             url: url,
                             headers: {
                                 'Accept': 'application/json',
                                 'Content-Type': 'application/json;charset=UTF-8'
                             },
                             data: {}
                         };
                         let response = await axios(options);
                         let responseOK = response && response.status == 200;
                         if (responseOK) {
                             let data = response.data;
              
                             // let data = await response.data;
                             if (data.Success == true) {
                                 let ds = data.Data;
                                 if (ds != null) {
                                  setErrorModal(false);
                                  const TypeOptionDs1=[];
                                
                                  ds.map((option) =>{
                                      const TypeOptions1={
                                          'DisShareId':option.DisShareId,
                                          'label':option.ShareType,
                                          'value':option.DisShareId,
                                           'disabled':false
                                      }
                                   return TypeOptionDs1.push(TypeOptions1);
                                      });
                                   SetSelectDiscountShareDDL(TypeOptionDs1);
                                 }
                             }
                             else {
                                 let Errtext = <p><div className='text-required'> no record found </div></p>;
                             console.log('no record found');
                             
                             }
              
                         }
                         else {
                          setErrorModal(false);  
                          SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
                         // return data;
                     } catch (error) {
                         if (error.response) {
                          setErrorModal(false);  
                          ErrorFunction(error.response.status);
                            
                     }
              
                 }
                 }  
    const asyncFunLocationBindDetails =async()=> {
        try {
         
        let url=URL_Start+ 'master/getstateslistlocationMapping/'+`${LocationZoneId}`;
        let options = {
            method: 'GET',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {}
        };
        let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if(data.Success==true)
                { let ds=data.Data;
                    if(ds!=null && ds.length>0)
                    {  const TypeOptionDs=[];
                          
                        ds.map((option) =>{
                            const TypeOptions={
                                'label':option.Value,
                                'value':option.Id,
                                'StateId':option.Id,
                                'disabled':option.HaveRegionStatus=="1"?false:true
                            }
                         return TypeOptionDs.push(TypeOptions);
                            });
                        SetLocationStateList(TypeOptionDs);                 
                    }
                     else{
                        SetLocationStateList([]);
                     }
    
                }
                else{
                    let Errtext=<p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                    console.log('no record found');
                }
            }
            else{
                let Errtext=<p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
            }
         // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
         }
      }
      
      const asyncFunRegionLocationBindDetails=async(isChecked)=> {
        try {
         
        let url=URL_Start+ 'master/getBindLocationRegionDetails'
        let options = {
            method: 'POST',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {objTblLocationTypeList:globallocationStateList}
        };
        let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if(data.Success==true)
                { let ds=data.Data;
                    SetLocationRegionList([]);
                    if(ds!=null && ds.length>0)
                    { 
                        const TypeOptionDs=[];
                          
                        ds.map((option) =>{
                            const TypeOptions={
                                'label':option.RegionName,
                                'value':option.RegionId,
                                'StateId':option.StateId,
                                'RegionId':option.RegionId,
                                'IsHaveDistrictStatus':option.IsHaveDistrictStatus,
                                'disabled':false
                            }
                         return TypeOptionDs.push(TypeOptions);
                            });
                            SetLocationRegionList(TypeOptionDs);
                            SetuserModuleShow(false);  
                            
                    }
                     else{
                        SetLocationRegionList([]);
                        SetselectedGlocationRegionList([]);
                        if(globallocationStateList.length==0)
                        {
                           SetuserModuleShow(false);      
                        }
                        
                     }
    
                     if(ds!=null && ds.length>0)
                     {
                      if(SelectedAllStateFlag==true)
                        {
                            const TypeOptionDs=[];
                          
                            ds.map((option) =>{
                                const TypeOptions={
                                    'label':option.RegionName,
                                    'value':option.RegionId,
                                    'StateId':option.StateId,
                                    'RegionId':option.RegionId,
                                    'IsHaveDistrictStatus':option.IsHaveDistrictStatus,
                                    'disabled':false
                                }
                             return TypeOptionDs.push(TypeOptions);
                                });
    
                            SetselectedGlocationRegionList(selectedGlocationRegionList=>[...TypeOptionDs]);
                            LGlbLocationRegionList=[];
                            ds.map((option) =>{
                                const item = {
                                    "RegionId":option.RegionId
                                  };
                                  LGlbLocationRegionList.push(item);
                                });
                            asyncFunDistinctLocationBindDetails(true);
                            SetuserModuleShow(true);
                        }
                        else{
                            
                        SetselectedGlocationRegionList(selectedGlocationRegionList=>[...selectedGlocationRegionList.filter(o1 => ds.some(o2 => o1.RegionId== o2.RegionId))]);
                       
                        if(selectedGlocationRegionList.length==0)
                        {
                           SetuserModuleShow(false);      
                        }
                         else{
                            SetuserModuleShow(true);
                         }
                        }
                        //LGlbLocationRegionList=LGlbLocationRegionList.filter(o1 => ds.some(o2 => o1.RegionId== o2.RegionId));
                     }
                     else{
                        SetLocationRegionList([]);
                        SetselectedGlocationRegionList([]);
                        if(globallocationStateList.length>0)
                        {
                           stringRegions="Selected All Regions";
                            stringRegionIds="0";
                            stringDistricts="Selected All Districts";
                            stringDistrictIds="0";
                            SetuserModuleShow(true); 
                       }
                       else{
                           stringRegions="";
                         stringRegionIds="0";
                         stringDistricts="";
                         stringDistrictIds="0";
                        
                       }
                        
                           globaltblAllGroups=globaltblAllGroups.map(el => (
                               el.IsAlreadyHaveDB=="False"?{...el, LocationRegions:stringRegions,LocationRegionIds:stringRegionIds,LocationDistricts:stringDistricts, LocationDistrictIds:stringDistrictIds}:el   
                      ));
                    
                     }
                     console.log(JSON.stringify(LGlbLocationRegionList));
                }
                else{
                    SetselectedGlocationRegionList([]);
                    let Errtext=<p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                    console.log('no record found');
                }
            }
            else{
                SetselectedGlocationRegionList([]);
                let Errtext=<p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
            }
        } catch (error) {
            SetselectedGlocationRegionList([]);
            console.log(error.message);
            let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
         }
      }
    
      const asyncFunDistinctLocationBindDetails=async(isChecked)=> {
        try {
         
        let url=URL_Start+ 'master/getBindLocationDistrictDetails'
        let options = {
            method: 'POST',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {objTblLocationTypeList:LGlbLocationRegionList}
        };
        let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if(data.Success==true)
                { let ds=data.Data;
                    SetLocationDistrictList([]);
                    if(ds!=null && ds.length>0)
                    {    const TypeOptionDs=[];
                          
                        ds.map((option) =>{
                            const TypeOptions={
                                'label':option.DistrictName,
                                'value':option.DistrictId,
                                'StateId':option.StateId,
                                'RegionId':option.RegionId,
                                 'DistrictId':option.DistrictId,
                                'disabled':false
                            }
                         return TypeOptionDs.push(TypeOptions);
                            });
                        
                        SetLocationDistrictList(TypeOptionDs); 
                          
                    }
                     else{
                        SetLocationDistrictList([]);
                     }
    
                     if(ds!=null && ds.length>0)
                     {
                        if(SelectedAllregionFlag==true)
                        {
                            const TypeOptionDs=[];
                          
                            ds.map((option) =>{
                                const TypeOptions={
                                    'label':option.DistrictName,
                                    'value':option.DistrictId,
                                    'StateId':option.StateId,
                                    'RegionId':option.RegionId,
                                    'DistrictId':option.DistrictId,
                                    'disabled':false
                                }
                             return TypeOptionDs.push(TypeOptions);
                                });
    
                        SetselectedGlocationDistrictList(selectedGlocationDistrictList=>[...TypeOptionDs]);
                        LGLocationDistrictList=[];
                            ds.map((option) =>{
                                const item = {
                                    "DistrictId":option.DistrictId
                                  };
                                  LGLocationDistrictList.push(item);
                                });
                                SetuserModuleShow(true);  
                        }
                        else{
                            SetselectedGlocationDistrictList(selectedGlocationDistrictList=>[...selectedGlocationDistrictList.filter(o1 => ds.some(o2 => o1.DistrictId== o2.DistrictId))]);
                   
                    }
                       
    
                        //SetselectedGlocationDistrictList(selectedGlocationDistrictList=>[...selectedGlocationDistrictList.filter(o1 => ds.some(o2 => o1.value== o2.DistrictId))]);
                        //LGLocationDistrictList=selectedGlocationDistrictList=>[...selectedGlocationDistrictList.filter(o1 => ds.some(o2 => o1.value== o2.DistrictId))];
                       
                       
                        //LGLocationDistrictList=LGLocationDistrictList.filter(o1 => ds.some(o2 => o1.DistrictId== o2.DistrictId));
                     
                        //   if(isChecked==false)
                        //   {
                        //     SetLGLocationDistrictList(LGLocationDistrictList=>[...LGLocationDistrictList.filter(o1 => ds.some(o2 => o1.DistrictId== o2.DistrictId))]);
            
                        //   }
                     }
                     else{
                          SetselectedGlocationDistrictList([]);
                           if(LGlbLocationRegionList.length>0)
                           {
                            SetuserModuleShow(true);
                           }
                        
                         
                          //LGLocationDistrictList=[];
                          //LGLocationDistrictList=[];
                     }
                     //isFirstRender.current=false;
                }
                else{
                    SetselectedGlocationDistrictList([]);
                    let Errtext=<p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                    console.log('no record found');
                }
            }
            else{
                SetselectedGlocationDistrictList([]);
                let Errtext=<p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
            }
        } catch (error) {
            SetselectedGlocationDistrictList([]);
            console.log(error.message);
            let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
         }
      }
      const asyncFunCentresbylocations=async()=> {
        try {
         
        let url=URL_Start+ 'master/getEditBindCentrebyCreditlocID'
        let options = {
            method: 'POST',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {objTblLocationTypeList:LGLocationDistrictList, CentreId:`${GlobalCentreIds}`, EmployeeId:`${GlobalEmployeeIds}` }
        };
        let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                     setErrorModal(false);
                     const TypeOptionDs1=[];
                   
                     ds.map((option) =>{
                         const TypeOptions1={
                             'CentreId':option.CentreId,
                             'label':option.CentreName,
                             'value':option.CentreId,
                             'CenterTypeId':option.CenterTypeId,
                              'disabled':false
                         }
                      return TypeOptionDs1.push(TypeOptions1);
                         });
                         SetSelectCentreDDL(TypeOptionDs1);
                    }
                    else{
                      SetSelectCentreDDL([]);
                    }
                }
                else {
                  SetSelectCentreDDL([]);
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                console.log('no record found');
                
                }
    
            }
            else {
              SetSelectCentreDDL([]);
             setErrorModal(false);  
             SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
            // return data;
        } catch (error) {
          SetSelectCentreDDL([]);
            if (error.response) {
             setErrorModal(false);  
             ErrorFunction(error.response.status);
               
        }
    
    }
      }
    
          /********** Modals  ***********/
          const [modal, setModal] = useState();
          const toggleModal = () => {
              setModal(!modal)
          }
      
          const [errorModal, setErrorModal] = useState();
          const errorHandler = () => {
            SetIsButtonSubmit(true);
             SetIsUserView(false);
               setModal(true); setErrorModal(true);
               formik.handleSubmit();
          
          }
      
      
          
      
      
          /********** Validations  ***********/
          const formik = useFormik({
              initialValues: {
                maxdiscount:'',
                maxdiscountprecent:''
               
              },
             // enableReinitialize: true,
              validationSchema: Yup.object({
                  // email: Yup.string().email('Invalid email address').required('Required'),
                  // callType: Yup.string().transform(v => v=== '0' ? '' : v)
                  // .required('Call Type is Required'),
                  maxdiscount: Yup.number().required('Max Credit Per Month (in Rs.) is Required')
                    
                    
              }),
              isSubmitting:true,
              onSubmit:  async (values,actions) => {
              
                 if(globallocationStateList.length==0)
                {
                 setModal(true); setErrorModal(true);
                }
                else if(LocationRegionList.length>0 &&LGlbLocationRegionList.length==0)
                 {
                     setModal(true); setErrorModal(true);
                 }
                  else if(LocationDistrictList.length>0 &&LGLocationDistrictList.length==0)
                  {
                     setModal(true); setErrorModal(true);
                  }
                
                else
                {
                 setModal(false);
                 setErrorModal(false);
                 setErrorPopUp("");
                 try {
                     let  DisShareMId="0";
                  let url=URL_Start+ 'master/SETUpdateCreditEmpDesIDDetail';
                 
                  let options = {
                      method: 'POST',
                      url: url,
                      headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json;charset=UTF-8'
                      },
                    data:{DesignationCreditId:`${MDesignationCreditId}`,DesignationId:`${GlobalDesignationMId}`,MaxCreditPerMnthRs:`${values.maxdiscount}`,MaxCreditPerMnthPercent:`${MaxDiscountField}`,DisShareId:`${DisShareMId}`,  AddedBy:`${LoginId}`,CentreId:LGlCentreTypeoption.CentreId}
                  };
                 
                  let response = await axios(options);
                      let responseOK = response && response.status == 200;
                      if (responseOK) {
                          let data = response.data;
                          // let data = await response.data;
                          if(data.Success==true && data.Data=="2000")
                          {  
                              formik.resetForm();
                              globallocationStateList=[];                    
                            LGLocationDistrictList=[];
                            LGlbLocationRegionList=[];
                            SetLocationRegionList([]);
                            SetLocationDistrictList([]);
                            SetselectedGlocationRegionList([]);
                            SetLocationDistrictList([]);
                            SetselectedGlocationDistrictList([]);
                             SetLocationRegionList([]);
                             SetLocationDistrictList([]);
                             SetselectedGlocationStateList([]);
                           SetSelectDiscountShare(null);
                             SetSelectCentreDDL([]);
                             SetSelectCentres([]);
                             SetLGlCentreTypeoption([]);
                              SweetAlert.fire({ title: "Success!", text: "Credit has been  updated!", icon: "success" });
                              setErrorModal(false);
                              history.push({ pathname: `${process.env.PUBLIC_URL}/master/credits`, search: `` });
                             
    
                          }
                        
                          else{
                               if(data.ErrorList!=null && data.ErrorList.length>0)
                               {
                                  let Errtext=<p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                                  SweetAlert.fire({ title: "Error", text:data.ErrorList[0].errorMsg, icon: "error" });
                               }
                              
                          }
                         
                        
                      }
                      else{
                          console.log('no record found');
                          
                      }
                   // return data;
                  } catch (error) {
                      console.log(error.message);
                      let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                      SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
                   }
                   setTimeout(() => {
                    
                      actions.setSubmitting(false);
                      }, 1000);
                }
          
                   
              },
          });
          
              
          function onKeyDown(keyEvent) {
              if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                keyEvent.preventDefault();
              }
            }
    
    
          
           
      const PrevBckHandler= async()=>{
          formik.resetForm();
      
         globallocationStateList=[];                    
        LGLocationDistrictList=[];
        LGlbLocationRegionList=[];
        SetselectedGlocationRegionList([]);
        SetLocationDistrictList([]);
        SetselectedGlocationDistrictList([]);
         SetLocationRegionList([]);
         SetLocationDistrictList([]);
         SetselectedGlocationStateList([]);
         history.push({ pathname: `${process.env.PUBLIC_URL}/master/credits`, search: `` });
      }
       const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    
     const stateSelectionHandler=(options)=>{
        SetselectedGlocationStateList(options);
        if(options!=null && options.length>0)
        {   globallocationStateList=[];
            SetLocationDistrictList([]);
            //SetselectedGlocationDistrictList([]);
            options.map((option) =>{
                const TypeOptions={
                    'StateId':option.value
                }
             return globallocationStateList.push(TypeOptions);
                });
                
                asyncFunRegionLocationBindDetails(true);
                asyncFunDistinctLocationBindDetails(true);
                asyncFunCentresbylocations(true);
            }
             else{
                globallocationStateList=[];
                LGlbLocationRegionList=[];
                LGLocationDistrictList=[];
                SetLocationRegionList([]);
                SetLocationDistrictList([]);
                SetselectedGlocationRegionList([]);
                SetLocationDistrictList([]);
                SetselectedGlocationDistrictList([]);
                asyncFunRegionLocationBindDetails(false);
                asyncFunCentresbylocations(true);
                stringRegions="";
                stringRegionIds="0";
                stringDistricts="";
                stringDistrictIds="0";
              
               globaltblAllGroups=globaltblAllGroups.map(el => (
                      el.IsAlreadyHaveDB=="False"?{...el, LocationRegions:stringRegions,LocationRegionIds:stringRegionIds,LocationDistricts:stringDistricts, LocationDistrictIds:stringDistrictIds}:el   
                       )); 
            }
        
          
        console.log(JSON.stringify(globallocationStateList));
        //SetgloballocationStateList
    
     }
     const RegionSelectionHandler=(options)=>{
       
        
        if(options!=null && options.length>0)
        {   let hasMatchSecond=globaltblExistGroups.some(function (d) {
            return d.ModuleId==globalModuleId &&
            d.LocationRegionIds.split(',').some(host=>options.map(val =>val["IsHaveDistrictStatus"]=="0" && val["RegionId"]).indexOf(host) !=-1 )
               });
                if(hasMatchSecond)
                {
                    SweetAlert.fire({ title: "Warning", text:"you have already choose selected region or District. choose different region or District", icon: "warning" });
                }
                 else
                 {
                    SetselectedGlocationRegionList(options);
                    LGlbLocationRegionList=[];
                    const TypeOptDocL=[];
                    options.map((option) =>{
                        const item = {
                            "RegionId":option.value
                          };
                          LGlbLocationRegionList.push(item);
                        });
                        asyncFunDistinctLocationBindDetails(true);
                        asyncFunCentresbylocations(true);
                 }
          
            }
             else{
                SetselectedGlocationRegionList(options);
                LGlbLocationRegionList=[];
                SetLocationDistrictList([]);
                LGLocationDistrictList=[];
                asyncFunDistinctLocationBindDetails(true);
                asyncFunCentresbylocations(true);
                SetuserModuleShow(false);
             }
             SelectedAllStateFlag=false;
            SelectedAllregionFlag=false;
             isFirstRender.current=true;
     }
     const DistrictSelectionHandler=(options)=>{
       
       
        if(options!=null && options.length>0)
        {   
            let hasMatchSecond=globaltblExistGroups.some(function (d) {
                return d.ModuleId==globalModuleId &&
                ( (d.LocationRegionIds.split(',').some(host=>selectedGlocationRegionList.map(val =>val["IsHaveDistrictStatus"]=="0" && val["RegionId"]).indexOf(host) !=-1 ))
                || (d.LocationDistrictIds.split(',').some(host=>options.map(val =>val["DistrictId"]).indexOf(host) !=-1 ))
                )
                     });
                    if(hasMatchSecond)
                    {
                        SweetAlert.fire({ title: "Warning", text:"you have already choose selected region or District. choose different region or District", icon: "warning" });
                    }
                     else
                     {
                        SetselectedGlocationDistrictList(options);
                        LGLocationDistrictList=[];
                        const TypeOptDocL=[];
                        options.map((option) =>{
                            const item = {
                                "DistrictId":option.value
                              };
                         LGLocationDistrictList.push(item);
                            });
                            asyncFunCentresbylocations(true);
                            
                     }
           
            }
             else{
                SetselectedGlocationDistrictList(options);
                LGLocationDistrictList=[];
                asyncFunCentresbylocations(true);
             }
             SelectedAllStateFlag=false;
             SelectedAllregionFlag=false;
             isLastRender.current=true; 
     }
    
     const DiscountShareHandler=(options)=>{
       
     SetSelectDiscountShare(options);
         }  
const CentreHandler=(options)=>{
       
            SetSelectCentres(options);
            const TypeOptL=[];                  
         if(options!=null)
         {
           
                const TypeOptrows={
                    'CentreId':options.CentreId
                     }
            SetLGlCentreTypeoption(TypeOptrows);
    
         }
             }
             const MaxhandleChange = event => {
     if(event.target.value!='' && event.target.value!=null)
     {
                 if( Number(event.target.value)>0 && Number(event.target.value)<=100)
                 {
                    SetMaxDiscountField(event.target.value);
                 }  else{
                    SweetAlert.fire('Disc. % Allow only 1 to 100.'); 
                 }
                }
                else{
                    SetMaxDiscountField(event.target.value);
                }
              };
        return (
            <Fragment>
                <Breadcrumb title="Add Discount" parent="Master" />
                <Container fluid>
    
                  {DiscountDetailFDs!=null ? <Row>
                        <Col md={12}>
                            <Card>
                            <div class="pb-0 card-header"><div class="card-title h5">Add Discount</div></div>
                                <Card.Body>
                                <Form.Row className="justify-content-center">
                                           
                                           <Col md={6} lg={6}>
                                                   <Form.Group>
                                            <label className="col-form-label"><b>Department</b></label>
                                   <span className="form-control-plaintext" >{DiscountDetailFDs.DepartmentName==''?'N/A':DiscountDetailFDs.DepartmentName}</span>
                                                               </Form.Group>
                                              </Col>
                                            
                                           </Form.Row>
                                           <Form.Row className="justify-content-center">
                                           
                                           <Col md={6} lg={6}>
                                                   <Form.Group>
                                                   <label className="col-form-label"><b>Designation</b></label>
                                   <span className="form-control-plaintext" >{DiscountDetailFDs.Designation==''?'N/A':DiscountDetailFDs.Designation}</span>
                                                               </Form.Group>
                                              </Col>
                                            
                                           </Form.Row>
                                         <Form.Row className="justify-content-center">
                                           
                                           <Col md={6} lg={6}>
                                                   <Form.Group>
                                                   <label className="col-form-label"><b>Employee</b></label>
                                   <span className="form-control-plaintext" >{DiscountDetailFDs.EmployeeName==''?'N/A':DiscountDetailFDs.EmployeeName}</span>
                                            
                                                               </Form.Group>
                                              </Col>
                                            
                                           </Form.Row>
                                           <Form.Row className="justify-content-center">
                                           
                                           <Col md={6} lg={6}>
                                                   <Form.Group>
                                                                   <Form.Label>Max Discount Per Month(in Rs)<span className='text-required'>* </span></Form.Label>
                                                                   <Form.Control type='number' id="maxdiscount" name="maxdiscount" 
                                                      onChange={formik.handleChange}
                                                       onBlur={formik.handleBlur}
                                                      value={formik.values.maxdiscount} 
                                                   
                                                   />
                                                        {formik.touched.maxdiscount && formik.errors.maxdiscount ? (<div className='text-required'>{formik.errors.maxdiscount}</div>) : null}
                                                               </Form.Group>
                                              </Col>
                                            
                                           </Form.Row>
                                           <Form.Row className="justify-content-center">
                                           
                                           <Col md={6} lg={6}>
                                                   <Form.Group>
                                                                   <Form.Label>Max Disc.(%)</Form.Label>
                                                                   <Form.Control  type='number' id="maxdiscountprecent" name="maxdiscountprecent" 
                                                      onChange={MaxhandleChange}
                                                    //  onBlur={formik.handleBlur}
                                                      value={MaxDiscountField} 
                                                   
                                                   />
                                                               </Form.Group>
                                              </Col>
                                            
                                           </Form.Row>
                                          
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>:null} 
                    <Row>
                        <Col md={12}>
                            <Card>
                            <div class="pb-0 card-header"><div class="card-title h5">Centre Search</div></div>
                                <Card.Body>
                               
                               <Form.Row className="justify-content-center">
                                           
                               <Col md={4} lg={4}>
                                       <Form.Group>
                                                       <Form.Label>State<span className='text-required'>*</span></Form.Label>
                                                                <MultiSelect    id="stateSelect" name="stateSelect"
                                                        options={LocationStateList}
                                                        value={selectedGlocationStateList}
                                                        onChange={stateSelectionHandler}
                                                        labelledBy="Select"
                                                           />
                                    {(globallocationStateList.length==0)&& IsButtonSubmit==true ? (<div className='text-required'>{"State Selection is Required"}</div>) : null}
                                                   </Form.Group>
                                  </Col>
                                  <Col md={4} lg={4}>
                                                   <Form.Group>
                                                                   <Form.Label>Region<span className='text-required'>*</span></Form.Label>
                                                                            <MultiSelect id="regionSelect" name="regionSelect"
                                                                    options={LocationRegionList}
                                                                 disabled={SelectedAllStateFlag}
                                                                    value={selectedGlocationRegionList}
                                                                    onChange={RegionSelectionHandler}
                                                                  labelledBy="Select"
                                                                       />
                                                          
                                                          {(LocationRegionList.length>0 &&selectedGlocationRegionList.length==0) && IsButtonSubmit==true ? (<div className='text-required'>{"Region Selection is Required"}</div>) : null}
                         
                                                               </Form.Group>
                                              </Col>
                                              <Col md={4} lg={4}>
                                                   <Form.Group>
                                                                   <Form.Label>District<span className='text-required'>*</span></Form.Label>
                                                                            <MultiSelect id="DistrictSelect" name="DistrictSelect"
                                                                             disabled={SelectedAllregionFlag}  
                                                                    options={LocationDistrictList}
                                                                     value={selectedGlocationDistrictList}
                                                                    onChange={DistrictSelectionHandler}                                                                                                                       
                                                                    labelledBy="Select"
                                                                       />
                             {(LocationDistrictList.length>0 &&LGLocationDistrictList.length==0) && IsButtonSubmit==true ? (<div className='text-required'>{"District Selection is Required"}</div>) : null}
                                                               </Form.Group>
                                              </Col>
                               </Form.Row>
    
                            <Form.Row className="justify-content-left">
                                           
                            <Col md={4} lg={4}>
                                                   <Form.Group>
                                                                   <Form.Label>Centre<span className='text-required'>*</span></Form.Label>
                                                                           
                                                                   <Select  id="CentreSelect" name="CentreSelect"
                                                                   onChange={CentreHandler}
                                                                    options={SelectCentreDDL}
                                                                     value={SelectCentres}
                                                                    />    
                             {(SelectCentres==null ||SelectCentres.length==0) && IsButtonSubmit==true  ? (<div className='text-required'>{"Centre Selection is Required"}</div>) : null}
                                                               </Form.Group>
                                              </Col>
                                
                               </Form.Row>
                               
                              
                                       
                                           
                                           
                                   
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>                 
                    <Row className='mt15'>
                                        <Col md={12} className='text-center'>
                                            <Button variant='secondary' className="mr-2"  onClick={PrevBckHandler} >Cancel</Button>
                                            {<Button variant='primary' type='submit' onClick={errorHandler}>Save</Button>}
                                        </Col>
                                    </Row>
                </Container>
    
                {errorModal &&
                    <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                        <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                        <ModalBody>
                      
                       
                       <p> {formik.touched.maxdiscount && formik.errors.maxdiscount ? (<div className='text-required'>{formik.errors.maxdiscount}</div>) : null}</p>
                        <p>
                        { (globallocationStateList.length==0)&& IsButtonSubmit==true ? (<div className='text-required'>{"State Selection is Required"}</div>) : null}
                        </p>
                        <p>
                        {(GGLocationRegionList.length>0 &&LGlbLocationRegionList.length==0)&& IsButtonSubmit==true ? (<div className='text-required'>{"Region Selection is Required"}</div>) : null}
                        </p>
                        <p>
                        {(GGLocationDistrictList.length>0 &&selectedGlocationDistrictList.length==0)&& IsButtonSubmit==true? (<div className='text-required'>{"District Selection is Required"}</div>) : null}
                        </p>
                        <p>{(SelectCentres==null ||SelectCentres.length==0) && IsButtonSubmit==true  ? (<div className='text-required'>{"Centre Selection is Required"}</div>) : null}</p>
                       
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                            <Button variant="primary" onClick={toggleModal}>Ok</Button>
                        </ModalFooter>
                        
                    </Modal>
                }
            </Fragment>
        )
    }

export default EditCredit;