import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import SweetAlert from 'sweetalert2';
import { Image_Path, URL_Start } from '../../../constant';
import axios from 'axios';
import LoadingButton from '../commonComponent/LoadingButton';
import DataTables from '../commonComponent/DataTable/DataTables';
import paginationFactory from 'react-bootstrap-table2-paginator';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import ToolTip from '../commonComponent/ToolTip';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const SchemeofAmalgamation = () => {
    const [isSaving, setIsSaving] = useState(false);
    const [tabledata, setTableData] = useState([]);
    const [bwcpath, setBWCPath] = useState('');
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [investorid, setInvestorId] = useState("0");
    const [invalidfiletype, setInvalidFileType] = useState(false);
    useEffect(() => {
        GetInvestorDetails();
    }, [])

    const formik = useFormik({
        initialValues: {
            centreId: '0', biotitle: '', bioheader: '', biodescription: '', bwcpath: ''
        },
        validationSchema: Yup.object({
            // biotitle: Yup.string().required('Title is required'),
            // bioheader: Yup.string().required('Header is required'),
            // biodescription: Yup.string().required('Description is required')
        }),
        onSubmit: values => {
            SaveSchemeOfAmalgamation(values);
        },
    });
    const UploadImages = async (e, folderName) => {
        const FileURL = e.target.files[0].name;
        const BreakURL = FileURL.lastIndexOf('.');
        const extension = FileURL.substring(BreakURL + 1);
        //const extension = e.target.files[0].name.split(".");
        if (extension.toLowerCase() === "pdf") {
            setInvalidFileType(false);
            var element = document.querySelector("input[type='file']")
            element.classList.remove("removeValue");
            document.getElementById("showpdficon").style.display = "none";
        }
        else {
            SweetAlert.fire("Invalid file type! Please select only pdf type."); setInvalidFileType(true);
            var element = document.querySelector("input[type='file']")
            element.classList.add("removeValue");
            document.getElementById("showpdficon").style.display = "none";
            return false;
        }
        var base64 = await toBase64(e.target.files[0]);
        var base64img = base64.split("base64,");
        const formData = new FormData();
        formData.append('Image', base64img[1]);
        formData.append('FolderName', folderName);
        const randomImageId = Math.random();
        var imgName = randomImageId + folderName + "." + extension; //extension[extension.length - 1];
        formData.append('ImageName', imgName);
        let url = URL_Start + 'master/UploadImage/'
        await axios.post(url, formData, {
            headers: { "Content-type": "multipart/form-data" },
        }).then((response) => {
            const data = response.data;
            if (data.Success) {
                formik.values.bwcpath = imgName; setBWCPath(Image_Path + folderName + "/" + imgName);
            } else { SweetAlert.fire({ title: "Upload failed", text: "Unable to upload document due to " + data.Data + ".Please retry again", icon: "error" }) }
        }).catch(function (error) { if (error.response) { } });
    }
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const SaveSchemeOfAmalgamation = async (values) => {
        if (values.bioheader === "" && values.biodescription === "" && bwcpath === "" && values.biotitle === "") { SweetAlert.fire({ title: "Validation Failed!", text: "Please enter details.", icon: "error" }); return false; }
        if ((values.bioheader !== "" || values.biodescription !== "" || bwcpath !== "") && values.biotitle === "") { SweetAlert.fire({ title: "Validation Failed!", text: "Title is mandatory.", icon: "error" }); return false; }
        if (bwcpath !== "") {
            if (invalidfiletype === true) { SweetAlert.fire({ title: "Validation Failed!", text: "Please upload valid file type.", icon: "error" }); return false; }
        }
        if(values.bwcpath == "" || values.bwcpath == null || values.bwcpath == undefined){
            SweetAlert.fire({ title: "Validation Failed!", text: "Please upload document upto 5 mb.", icon: "error" });
            return false;
        }
        setIsSaving(true);
        const requestOptions = {
            CentreId: values.centreId,
            BioWasteTitle: values.biotitle,
            BioWasteHeader: values.bioheader,
            BioWasteDescription: values.biodescription,
            BioWasteDoc: values.bwcpath,
            InvestorTypeId: '4',
            InvestorTypeName: 'Scheme of Amalgamation',
            AddedBy: localStorage.LoggedInUser,
            InvestorId: investorid
        };
        let url = URL_Start + 'master/saveinvestordetails'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsSaving(false);
            if (data.Success) {
                if (investorid === "0") { SweetAlert.fire({ title: "Success", text: "Data saved successfully", icon: "success" }); }
                else { SweetAlert.fire({ title: "Success", text: "Data updated successfully", icon: "success" }); }
                setBWCPath('');
                formik.resetForm();
                document.getElementById("cancelbutton").click();
                var element = document.querySelector("input[type='file']")
                element.classList.remove("removeValue");
                document.getElementById("showpdficon").style.display = "none";
                setInvestorId("0");
                GetInvestorDetails();
            }
            else {
                SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" });
                setIsSaving(false);
            }
        }).catch(function (error) { setIsSaving(false); if (error.response) { ErrorFunction(error.response.status); } });
    }
    const GetInvestorDetails = async () => {
        try {
            let url = URL_Start + 'master/GetInvestorDetails/4'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setTableData(data.Data);
                }
                else {
                    setTableData([]);
                }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const cancelHandler = async () => {
        formik.resetForm();
        formik.values.biotitle = "";
        formik.values.bioheader = "";
        formik.values.biodescription = "";
        setBWCPath('');
        formik.values.bwcpath = "";
        setInvestorId("0");
        var element = document.querySelector("input[type='file']")
        element.classList.remove("removeValue");
        document.getElementById("showpdficon").style.display = "none";
    }
    const paginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>                <span>{crntPageNO + rowIndex + 1}</span>            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <ToolTip title="Edit" placement="top" onClick={() => SelectOption(rowData, "1")} linkId="Edit" faName="fa fa-pencil mr-2"></ToolTip>
                <ToolTip title="Delete" placement="top" onClick={() => SelectOption(rowData, "2")} linkId="Delete" faName="fa fa-trash mr-2"></ToolTip>
            </div>
        </React.Fragment>
    }
    const downloadFormatter = (cellData, rowData, rowIndex, extraData) => {
        const url = Image_Path + "SchemeofAmalgamation/" + rowData.FileName;
        return <React.Fragment>
            <div>
                <i className='fa fa-download' onClick={() => downloadFile(url, rowData.FileName)}></i>
            </div>
        </React.Fragment>
    }
    function downloadFile(url, fileName) {
        const aElement = document.createElement('a');
        aElement.setAttribute('download', fileName);
        aElement.href = url;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(url);
    }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'Title', text: 'Title', editable: false },
        { dataField: 'Header', text: 'Header', editable: false },
        { dataField: 'Description', text: 'Description', editable: false },
        { dataField: 'Document', text: 'Document', formatter: downloadFormatter, editable: false },
        { dataField: 'actions', text: 'Actions', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    const SelectOption = async (rowData, Flag) => {
        if (Flag === "2") {
            cancelHandler();
            SweetAlert.fire({
                title: 'You are going to delete an item. Are you sure?',
                text: "Once deleted, you will not be able to recover!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => { if (result.value) { DeleteInvestor(rowData.InvestorsDetailsId); } else { SweetAlert.fire('Your data is safe!') } })
        }
        else {
            formik.values.biotitle = rowData.Title;
            formik.values.bioheader = rowData.Header;
            formik.values.biodescription = rowData.Description;
            formik.values.bwcpath = rowData.FileName;
            setBWCPath(Image_Path + "SchemeofAmalgamation/" + rowData.FileName);
            setInvestorId(rowData.InvestorsDetailsId);
            var element = document.querySelector("input[type='file']")
            element.classList.add("removeValue");
            document.getElementById("showpdficon").style.display = "block";
        }
    }
    const DeleteInvestor = async (InvestorsDetailsId) => {
        setIsSaving(true);
        const requestOptions = {
            InvestorsDetailsId: InvestorsDetailsId,
            AddedBy: localStorage.LoggedInUser
        };
        let url = URL_Start + 'master/deleteinvestor'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsSaving(false);
            if (data.Success) {
                SweetAlert.fire({ title: "Success", text: "Data deleted successfully", icon: "success" });
                GetInvestorDetails();
            }
            else {
                SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" });
                setIsSaving(false);
            }
        }).catch(function (error) { setIsSaving(false); if (error.response) { ErrorFunction(error.response.status); } });
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    return (
        <>
            <Breadcrumb title="Scheme of Amalgamation" parent="Master" />
            <Container fluid>
                <Form>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Header className='pb-0'>
                                    <Card.Title>Add Scheme of Amalgamation</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form.Row className="billingandfinance">
                                        <Col lg={3} md={4}>
                                            <Form.Group>
                                                <Form.Label>Title</Form.Label>
                                                <Form.Control type="text" name="biotitle" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.biotitle} />
                                                {formik.touched.biotitle && formik.errors.biotitle ? (<div className='text-required'>{formik.errors.biotitle}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3} md={4}>
                                            <Form.Group>
                                                <Form.Label>Header</Form.Label>
                                                <Form.Control type="text" name="bioheader" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.bioheader} />
                                                {formik.touched.bioheader && formik.errors.bioheader ? (<div className='text-required'>{formik.errors.bioheader}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3} md={4}>
                                            <Form.Group>
                                                <Form.Label>Description</Form.Label>
                                                <Form.Control type="text" name="biodescription" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.biodescription} />
                                                {formik.touched.biodescription && formik.errors.biodescription ? (<div className='text-required'>{formik.errors.biodescription}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col lg={3} md={4}>
                                            <Form.Group>
                                                <Form.Label>Upload Document <span className='text-red'>* (Max 5 mb accepted)</span></Form.Label>
                                                <div className='d-inline-flex'>
                                                    <input type="file" onChange={(e) => UploadImages(e, "SchemeofAmalgamation")} accept="application/pdf" />
                                                    <i class="fa fa-file-pdf-o" id="showpdficon" style={{ fontSize: '30px', color: 'red', display: 'none', position: 'absolute', right: '50%' }} aria-hidden="true"></i>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col lg={12} className='text-center'>
                                            <Button variant='secondary' className="mr-2" id="cancelbutton" onClick={cancelHandler}>Cancel</Button>
                                            {isSaving ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' type='submit' onClick={formik.handleSubmit}>Save</Button>
                                            }
                                        </Col>
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Header className='pb-0'>
                                    <Card.Title>Scheme of Amalgamation Detail</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form.Row className="billingandfinance">
                                        <Col lg={12}>
                                            <DataTables keyField='InvestorsDetailsId' tableData={tabledata} columns={columns} noDataIndication={NoRecordFounds}
                                                pagination={paginationFactory(paginationOptions)} />
                                        </Col>
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </Container>
        </>
    )
}

export default SchemeofAmalgamation