import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ViewToolTip  from '../UI/ToolTips/ViewToolTip';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { useHistory } from 'react-router';

const ViewDoctorDetail = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isButton, setIsButton] = useState(false);
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const[DoctorId, setDoctorId] = useState(localStorage.DoctorId == undefined ? "" : localStorage.DoctorId);
    const[EditDoctorDetails,SetEditDoctorDetails]=useState(null);
    const navigate = useHistory();
    const [modal, setModal] = useState();
    const toggleModal = () => {
        setModal(!modal)
        
    }
    useEffect(() => {
        BindDoctorDetailByDoctorId();
       }, []);

    const[IsDoctorView, SetIsDoctorView] =useState(false);
    
   
 
    async function  BindDoctorDetailByDoctorId() 
    {
       try {
        
           let url=URL_Start + 'DoctorMaster/getdoctordetailbyEditDocId';
               let options = {
                   method: 'POST',
                   url: url,
                   headers: {
                       'Accept': 'application/json',
                       'Content-Type': 'application/json;charset=UTF-8'
                   },
                   data: {DoctorId: `${DoctorId}`}
               };
               let response = await axios(options);
               let responseOK = response && response.status == 200;
               if (responseOK) {
                   let data = response.data;
   
                   // let data = await response.data;
                   if (data.Success == true) {
                       let ds = data.Data;
                       if (ds != null) {
   
                           await SetEditDoctorDetails(ds);
                           setModal(!modal);
                       SetIsDoctorView(true); 
                       }
                   }
                   else {
                       let Errtext = <p><div className='text-required'> no record found </div></p>;
                   console.log('no record found');
                   setIsLoading(false);
                   }
   
               }
               else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
               // return data;
           } catch (error) {
               if (error.response) { ErrorFunction(error.response.status);
                   setIsLoading(false);
           }
   
       }
       }
    
  
   const ErrorFunction = async (ErrorCode) => {
       if (ErrorCode == "400")
           SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
       else if (ErrorCode == "401")
           SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
       else if (ErrorCode == "403")
           SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
       else if (ErrorCode == "404")
           SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
       else if (ErrorCode == "500")
           SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
       else if (ErrorCode == "503")
           SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
   }

   
   const backHandler = () => {
       navigate.push(`/doctor-master/doctors`);
    }

 
 return (
     <><Breadcrumb title="View Doctor Detail" parent="Master" />
     <Container fluid>
          {EditDoctorDetails!=null? <Row>
             <Col md={12}>
                 <Card>
                     <Card.Body>
                     <Form.Row className="justify-content-center">
                     <Col lg={3} md={2}>
                            <Form.Group>
                           
                            <label className="col-form-label"><b>Title</b></label>
                                <span className="form-control-plaintext" >{EditDoctorDetails.objBindDoc.DrTitle==''?'':EditDoctorDetails.objBindDoc.DrTitle}</span>
                            </Form.Group>
                        </Col>
                        <Col lg={3} md={3}>
                            <Form.Group>
                           
                            <label className="col-form-label"><b>First Name</b></label>
                                <span className="form-control-plaintext" >{EditDoctorDetails.objBindDoc.DoctorName==''?'N/A':EditDoctorDetails.objBindDoc.DoctorName}</span>
                            </Form.Group>
                        </Col>
                        <Col lg={3} md={3}>
                            <Form.Group>
                           
                            <label className="col-form-label"><b>Middle Name</b></label>
                                <span className="form-control-plaintext" >{EditDoctorDetails.objBindDoc.MiddleName==''?'':EditDoctorDetails.objBindDoc.MiddleName}</span>
                            </Form.Group>
                        </Col>
                        <Col lg={3} md={3}>
                            <Form.Group>                         
                            <label className="col-form-label"><b>Last Name</b></label>
                                <span className="form-control-plaintext" >{EditDoctorDetails.objBindDoc.LastName==''?'':EditDoctorDetails.objBindDoc.LastName}</span>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row className="justify-content-center">
                        
                    <Col lg={3} md={3}>
                            <Form.Group>                         
                            <label className="col-form-label"><b>Pin Code</b></label>
                                <span className="form-control-plaintext" >{EditDoctorDetails.objBindDoc.PinCode==''?'N/A':EditDoctorDetails.objBindDoc.PinCode}</span>
                            </Form.Group>
                        </Col>
                        <Col lg={3} md={3}>
                            <Form.Group>                         
                            <label className="col-form-label"><b>SAP State</b></label>
                                <span className="form-control-plaintext" >{EditDoctorDetails.objBindDoc.StateName==''?'N/A':EditDoctorDetails.objBindDoc.StateName}</span>
                            </Form.Group>
                        </Col>
                        <Col lg={3} md={3}>
                            <Form.Group>                         
                            <label className="col-form-label"><b>SAP Region</b></label>
                                <span className="form-control-plaintext" >{EditDoctorDetails.objBindDoc.RegionName==''?'N/A':EditDoctorDetails.objBindDoc.RegionName}</span>
                            </Form.Group>
                        </Col>
                        <Col lg={3} md={3}>
                            <Form.Group>                         
                            <label className="col-form-label"><b>SAP District</b></label>
                                <span className="form-control-plaintext" >{EditDoctorDetails.objBindDoc.DistrictName==''?'N/A':EditDoctorDetails.objBindDoc.DistrictName}</span>
                            </Form.Group>
                        </Col>
                    </Form.Row>
                    
                    <Form.Row className="justify-content-center">
                                  
                                  <Col md={3} lg={3}>
                                  <Form.Group>
                            <label className="col-form-label"><b>Landline</b></label>
                            <span className="form-control-plaintext" >{EditDoctorDetails.objBindDoc.Landline==''?'N/A':EditDoctorDetails.objBindDoc.Landline}</span>
                            </Form.Group>
                                  </Col>
                                   <Col md={3} lg={3}>
                                   <Form.Group>
                            <label className="col-form-label"><b>Mobile</b></label>
                            <span className="form-control-plaintext" >{EditDoctorDetails.objBindDoc.Mobile==''?'N/A':EditDoctorDetails.objBindDoc.Mobile}</span>
                            </Form.Group>
                                   </Col>
                                   <Col md={3} lg={3}>
                                   <Form.Group>
                           <label className="col-form-label"><b>DOB</b></label>
                               <span className="form-control-plaintext" >{EditDoctorDetails.objBindDoc.DOB==''?'N/A':EditDoctorDetails.objBindDoc.DOB}</span>
                           </Form.Group>
                                  </Col>
                                  
                                  <Col md={3} lg={3}>
                                  <Form.Group>
                           
                            <label className="col-form-label"><b>DateofAnniversary</b></label>
                                <span className="form-control-plaintext" >{EditDoctorDetails.objBindDoc.DateofAnniversary==''?'N/A':EditDoctorDetails.objBindDoc.DateofAnniversary}</span>
                            </Form.Group>
                                  </Col>
                              </Form.Row>
                              <Form.Row className="justify-content-center">
                                       
                                       <Col md={4} lg={4}>
                                       <Form.Group>
                           
                           <label className="col-form-label"><b>Landmark</b></label>
                               <span className="form-control-plaintext" >{EditDoctorDetails.objBindDoc.Landmark==''?'N/A':EditDoctorDetails.objBindDoc.Landmark}</span>
                                 </Form.Group>
                                       </Col>
                                       <Col md={4} lg={4}>
                                       <Form.Group>
                           
                           <label className="col-form-label"><b>ClinicAddress</b></label>
                               <span className="form-control-plaintext" >{EditDoctorDetails.objBindDoc.ClinicAddress==''?'N/A':EditDoctorDetails.objBindDoc.ClinicAddress}</span>
                           </Form.Group>
                                        </Col>
                                       <Col md={4} lg={4}>
                                       <Form.Group>
                           
                           <label className="col-form-label"><b>ResidenceAddress</b></label>
                               <span className="form-control-plaintext" >{EditDoctorDetails.objBindDoc.ResidenceAddress==''?'N/A':EditDoctorDetails.objBindDoc.ResidenceAddress}</span>
                           </Form.Group>
                                       </Col>
                                   </Form.Row>
                   
                    <Form.Row className="justify-content-center">
                        
                       <Col md={4} lg={4}>
                       <Form.Group>
                           
                           <label className="col-form-label"><b>Specialization</b></label>
                               <span className="form-control-plaintext" >{EditDoctorDetails.objBindDoc.DocSpecialization==''?'N/A':EditDoctorDetails.objBindDoc.DocSpecialization}</span>
                           </Form.Group>
                        </Col>
                        <Col md={4} lg={4}>
                        <Form.Group>
                           
                           <label className="col-form-label"><b>Category</b></label>
                               <span className="form-control-plaintext" >{EditDoctorDetails.objBindDoc.CategoryName==''?'N/A':EditDoctorDetails.objBindDoc.CategoryName}</span>
                           </Form.Group>
                        </Col>
                    </Form.Row>
                    <Form.Row className="justify-content-center mt15">
                        
                        <Col lg={12} md={6}>
                            <Form.Group>
                           
                            <label className="col-form-label"><b>Location</b></label>
                          {EditDoctorDetails.objDoctorLocationMappingList!=null && EditDoctorDetails.objDoctorLocationMappingList.length>0?
                           EditDoctorDetails.objDoctorLocationMappingList.map((item) => {
                             return(
                                <span className="form-control-plaintext" >{item.FMLocation}</span> 
                                );  
                        }) : <span className="form-control-plaintext" >N/A</span> 
                    }     
                         

                                {/* <span className="form-control-plaintext" >{EditDoctorDetails.objBindDoc.FMLocation==''?'N/A':EditDoctorDetails.objBindDoc.FMLocation}</span> */}
                            </Form.Group>
                        </Col>
                        
                    </Form.Row>
                     </Card.Body>
                     <div className="card-footer text-center">
                               <button className="btn btn-primary mr-1"onClick={backHandler} >Previous</button>
                               {/* <button className="btn btn-secondary" onClick={modifyHandler}>Modify Personal Detail</button> */}
                           </div>
                 </Card>
             </Col>
             
         </Row>:null}
        
     </Container></>
 )
}


export default ViewDoctorDetail;