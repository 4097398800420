import React from 'react'
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from 'reactstrap';

const SubtractToolTip = (props) => {
    return (
        <>
            <Link to="#" onClick={props.onClick} className="text-dark"><i class="fa fa-minus mr-2" id={`Remove${props.id}`} aria-hidden="true"></i></Link>
            <UncontrolledTooltip placement={props.placement} target={`Remove${props.id}`}>
                {props.title}
            </UncontrolledTooltip>
        </>
    )
}

export default SubtractToolTip