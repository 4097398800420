import React, { useState, useEffect, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import { useLocation, useHistory } from "react-router-dom";
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Moment from 'moment';
import { Spinner } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import LoadingButton from '../commonComponent/LoadingButton';
import InputGroup from 'react-bootstrap/InputGroup';

var glbRecords = [];
var glbNewRecords = [];
const DisplayRatePlan = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [table, setTable] = useState([]);
    const [disabled, setDisabled] = useState();
    const [isactive, setIsActive] = useState();
    const [isnoactive, setIsNoActive] = useState();
    const [editisactive, setEditIsActive] = useState("1");
    const [updaterowid, setUpdateRowId] = useState();
    const [glblpartnertypeid, setGlblPartnerTypeId] = useState();
    const [glblregionid, setGlblRegionId] = useState();
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [newcrntPageNO, setNewCrntPageNO] = useState(0);
    const location = useLocation();
    const [isSaving, setIsSaving] = useState(false);
    let refedittemplate = useRef();
    let refupdate = useRef();
    let textCategory = useRef();
    let textTestName = useRef();
    let ddlDiscountCategory = useRef();
    let textSearch = useRef();
    let textPackageSearch = useRef();
    let textNewItemSearch = useRef();
    const [newItemsData, setNewItemsData] = useState([]);
    const [checked, setChecked] = useState(["All", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]);
    const [allCheck, changeAllCheck] = useState(false);
    const [monCheck, changeMonCheck] = useState(false);
    const [tueCheck, changeTueCheck] = useState(false);
    const [wedCheck, changeWedCheck] = useState(false);
    const [thursdyCheck, changeThursdyCheck] = useState(false);
    const [friCheck, changeFriCheck] = useState(false);
    const [satCheck, changeSatCheck] = useState(false);
    const [sunCheck, changeSunCheck] = useState(false);
    const [displayNewItem, setDisplayNewItem] = useState(false);
    const [discountCategoriesList, setDiscountCategoriesList] = useState(0);

    const paginationOptions = {
        sizePerPage: 500,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const newItemPaginations = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setNewCrntPageNO(((page - 1) * sizePerPage)); }
    };
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        //history.push({ pathname: `${process.env.PUBLIC_URL}/master/displayrateplan`, search: `` });
        GetExistingTemplatesDetails(params.get('id'));
        DiscountCategories();
    }, [])
    const formik = useFormik({
        initialValues: {
            editregion: '',
            editpartnertype: '',
            edittemplatename: '',
            editdisplayname: '',
            editfromdate: '',
            edittodate: '',
            selectedDays: ''
        },
        validationSchema: Yup.object({
            editregion: Yup.string()
                .required('Region is required'),
            editpartnertype: Yup.string()
                .required('Centre Type is required'),
            edittemplatename: Yup.string()
                .required('Template Name is required'),
            editdisplayname: Yup.string()
                .required('Display Name is required'),
            editfromdate: Yup.string()
                .required('From Date is required'),
            edittodate: Yup.string()
                .required('To Date is required'),
        }),
        onSubmit: values => {
            UpdateTemplateDetails(values);
        }
    });
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const idNewFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{newcrntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const columnsMRP = [{
        dataField: '#',
        text: '#',
        editable: false,
        formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    },
    {
        dataField: 'CategoryName',
        text: 'Category Name',
        editable: false
    },
    {
        dataField: 'TestName',
        text: 'Test Name',
        editable: false
    },
    {
        dataField: 'TestCode',
        text: 'Test Code',
        editable: false
    },
    {
        dataField: 'DiscountCategory',
        text: 'Discount Category',
        editable: false
    },
    {
        dataField: 'ItemMrp',
        text: 'MRP',
        editable: true
    },
    {
        dataField: 'ClientRate',
        text: 'Patient Rate',
        editable: true,
        headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    }
    ];
    const columnsB2B = [{
        dataField: '#',
        text: '#',
        editable: false,
        formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    },
    {
        dataField: 'CategoryName',
        text: 'Category Name',
        editable: false
    },
    {
        dataField: 'TestName',
        text: 'Test Name',
        editable: false
    },
    {
        dataField: 'TestCode',
        text: 'Test Code',
        editable: false
    },
    {
        dataField: 'DiscountCategory',
        text: 'Discount Category',
        editable: false
    },
    {
        dataField: 'ItemMrp',
        text: 'MRP',
        editable: true
    },
    {
        dataField: 'ItemRate',
        text: 'Partner Rate',
        editable: true,
        headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    }
    ];
    const columnsOther = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'CategoryName', text: 'Category Name', editable: false },
        { dataField: 'TestName', text: 'Test Name', editable: false },
        { dataField: 'TestCode', text: 'Test Code', editable: false },
        { dataField: 'DiscountCategory', text: 'Discount Category', editable: false },
        { dataField: 'ItemMrp', text: 'MRP', editable: true },
        { dataField: 'ItemRate', text: 'Partner Rate', editable: true },
        { dataField: 'ClientRate', text: 'Patient Rate', editable: true, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    const columnsPackage = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'CategoryName', text: 'Category Name', editable: false },
        { dataField: 'TestName', text: 'Test Name', editable: false },
        { dataField: 'TestCode', text: 'Test Code', editable: false },
        { dataField: 'MRP', text: 'MRP', editable: true },
        { dataField: 'PartnerRate', text: 'Partner Rate', editable: true },
        { dataField: 'PatientRate', text: 'Patient Rate', editable: true, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    const columnsNewMRP = [{
        dataField: '#',
        text: '#',
        editable: false,
        formatter: idNewFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    },
    {
        dataField: 'CategoryName',
        text: 'Category Name',
        editable: false
    },
    {
        dataField: 'TestName',
        text: 'Test Name',
        editable: false
    },
    {
        dataField: 'TestCode',
        text: 'Test Code',
        editable: false
    },
    {
        dataField: 'DiscountCategory',
        text: 'Discount Category',
        editable: false
    },
    {
        dataField: 'ItemMrp',
        text: 'MRP',
        editable: true
    },
    {
        dataField: 'ClientRate',
        text: 'Patient Rate',
        editable: true,
        headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    }
    ];
    const columnsNewB2B = [{
        dataField: '#',
        text: '#',
        editable: false,
        formatter: idNewFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    },
    {
        dataField: 'CategoryName',
        text: 'Category Name',
        editable: false
    },
    {
        dataField: 'TestName',
        text: 'Test Name',
        editable: false
    },
    {
        dataField: 'TestCode',
        text: 'Test Code',
        editable: false
    },
    {
        dataField: 'DiscountCategory',
        text: 'Discount Category',
        editable: false
    },
    {
        dataField: 'ItemMrp',
        text: 'MRP',
        editable: true
    },
    {
        dataField: 'ItemRate',
        text: 'Partner Rate',
        editable: true,
        headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    }
    ];
    const columnsNewOther = [{
        dataField: '#',
        text: '#',
        editable: false,
        formatter: idNewFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    },
    {
        dataField: 'CategoryName',
        text: 'Category Name',
        editable: false
    },
    {
        dataField: 'TestName',
        text: 'Test Name',
        editable: false
    },
    {
        dataField: 'TestCode',
        text: 'Test Code',
        editable: false
    },
    {
        dataField: 'DiscountCategory',
        text: 'Discount Category',
        editable: false
    },
    {
        dataField: 'ItemMrp',
        text: 'MRP',
        editable: true
    },
    {
        dataField: 'ItemRate',
        text: 'Partner Rate',
        editable: true
    },
    {
        dataField: 'ClientRate',
        text: 'Patient Rate',
        editable: true,
        headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    }
    ];
    const columnsNewPackage = [
        { dataField: '#', text: '#', editable: false, formatter: idNewFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'CategoryName', text: 'Category Name', editable: false },
        { dataField: 'TestName', text: 'Test Name', editable: false },
        { dataField: 'TestCode', text: 'Test Code', editable: false },
        { dataField: 'MRP', text: 'MRP', editable: true },
        { dataField: 'PartnerRate', text: 'Partner Rate', editable: true },
        { dataField: 'PatientRate', text: 'Patient Rate', editable: true, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    const handleKeypress = (e) => {
        e.preventDefault();
        return false;
    }
    const GetExistingTemplatesDetails = async (id) => {
        setIsLoading(true);
        try {
            let url = URL_Start + 'master/getexistingtemplatesdetails/' + id
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsLoading(false);
                    if (data.Data != null) {
                        setGlblRegionId(data.Data.RegionId);
                        setGlblPartnerTypeId(data.Data.PartnerTypeId);
                        const finalfromdate = Moment(data.Data.FromDate).format('YYYY-MM-DD');
                        const finaltodate = Moment(data.Data.ToDate).format('YYYY-MM-DD');
                        formik.values.editregion = data.Data.Region;
                        formik.values.editpartnertype = data.Data.PartnerType;
                        formik.values.edittemplatename = data.Data.TemplateName;
                        formik.values.editdisplayname = data.Data.DisplayName;
                        formik.values.editfromdate = finalfromdate;
                        formik.values.edittodate = finaltodate;
                        formik.values.selectedDays = data.Data.SelectedDays;
                        GetNewItemToTemplate(id);
                        refedittemplate.current.focus();
                        refupdate.current.focus();
                        setDisabled(formik.dirty == true ? formik.dirty : !formik.dirty);
                        if (data.Data.PartnerTypeId === "7") {
                            glbRecords = data.Data.existingtemplatepackages;
                            setTable(data.Data.existingtemplatepackages);
                            console.log(glbRecords);
                        }
                        else {
                            glbRecords = data.Data.existingtemplateitems;
                            setTable(data.Data.existingtemplateitems);
                        }
                        setUpdateRowId(data.Data.Id);
                        if (data.Data.IsActive === "Yes") { setIsActive(true); setIsNoActive(false); }
                        else { setIsNoActive(true); setIsActive(false); }
                    }
                    else {
                        formik.values.editregion = "";
                        formik.values.editpartnertype = "";
                        formik.values.edittemplatename = "";
                        formik.values.editdisplayname = "";
                        formik.values.editfromdate = "";
                        formik.values.edittodate = "";
                        setIsActive(true);
                        setIsNoActive(false);
                        setTable([]);
                        setUpdateRowId();
                    }
                }
                else { setIsLoading(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
            var i;

            setChecked(formik.values.selectedDays.split(','));
            var selectedDays = formik.values.selectedDays.split(',');
            for (i = 0; i < selectedDays.length; i++) {
                switch (selectedDays[i]) {
                    case 'All': changeAllCheck(true); break;
                    case 'Monday': changeMonCheck(true); break;
                    case 'Tuesday': changeTueCheck(true); break;
                    case 'Wednesday': changeWedCheck(true); break;
                    case 'Thursday': changeThursdyCheck(true); break;
                    case 'Friday': changeFriCheck(true); break;
                    case 'Saturday': changeSatCheck(true); break;
                    case 'Sunday': changeSunCheck(true); break;
                }
            }
        } catch (error) { setIsLoading(false); APIError("-3"); }
    }
    const GetNewItemToTemplate = async (id) => {
        try {
            var newItemCount = 0;
            let url = URL_Start + 'master/getnewitemstoexistingtemplates/' + id
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        glbNewRecords = data.Data;
                        setNewItemsData(glbNewRecords); newItemCount = data.Data.length;
                    }
                    else { setNewItemsData([]); }
                }
                else { APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
            if (newItemCount > 0) { setDisplayNewItem(true); }
        } catch (error) { APIError("-3"); }
    }
    const UpdateTemplateDetails = async (values) => {
        for (var key in table) {
            if (glblpartnertypeid === "7") {
                if (parseFloat(table[key].PatientRate) > 0 && parseFloat(table[key].MRP) > 0 && parseFloat(table[key].PartnerRate) > 0) {
                    if (parseFloat(table[key].PatientRate) > parseFloat(table[key].MRP)) {
                        SweetAlert.fire("MRP must be greater than or equal to patient rate"); return false;
                    }
                    if (parseFloat(table[key].PartnerRate) > parseFloat(table[key].PatientRate)) {
                        SweetAlert.fire("Partner rate must be less than or equal to patient rate"); return false;
                    }
                }
                else { SweetAlert.fire("Amount cannot be zero"); return false; }
            }
            else if (glblpartnertypeid === "5") {
                if (parseFloat(table[key].ClientRate) > 0 && parseFloat(table[key].ItemMrp) > 0 && parseFloat(table[key].ItemRate) > 0) {

                    // if (parseFloat(verifyrate[key].ClientRate) > parseFloat(verifyrate[key].ItemMrp)) {
                    //     glblitemstore = []; SweetAlert.fire("Patient rate must be less than or equal to ItemMrp"); return false;
                    // }
                    // if (parseFloat(verifyrate[key].ItemRate) > parseFloat(verifyrate[key].ItemMrp)) {
                    //     glblitemstore = []; SweetAlert.fire("Partner rate must be less than or equal to ItemMrp"); return false;
                    // }
                    // if (parseFloat(verifyrate[key].ItemRate) >= parseFloat(verifyrate[key].ClientRate)) {
                    //     glblitemstore = []; SweetAlert.fire("Patient rate must be greater than partner rate"); return false;
                    // }


                    if (parseFloat(table[key].ClientRate) > parseFloat(table[key].ItemMrp)) {
                        SweetAlert.fire("MRP must be greater than or equal to patient rate"); return false;
                    }
                    if (parseFloat(table[key].ItemRate) > parseFloat(table[key].ItemMrp)) {
                        SweetAlert.fire("MRP must be greater than or equal to partner rate"); return false;
                    }
                    if (parseFloat(table[key].ItemRate) > parseFloat(table[key].ClientRate)) {
                        SweetAlert.fire("Partner rate must be less than or equal to patient rate"); return false;
                    }
                }
                else { SweetAlert.fire("Amount cannot be zero"); return false; }
            }
            else if (glblpartnertypeid === "3" || glblpartnertypeid === "4" || glblpartnertypeid === "6") {
                if (parseFloat(table[key].ItemRate) > 0 && parseFloat(table[key].ItemMrp) > 0) {
                    if (parseFloat(table[key].ItemRate) >= parseFloat(table[key].ItemMrp)) {
                        SweetAlert.fire("MRP must be greater than partner rate"); return false;
                    }
                }
                else { SweetAlert.fire("Amount cannot be zero"); return false; }
            }
            else {
                if (parseFloat(table[key].ClientRate) > 0 && parseFloat(table[key].ItemMrp) > 0) {
                    if (parseFloat(table[key].ClientRate) > parseFloat(table[key].ItemMrp)) {
                        SweetAlert.fire("MRP must be greater than or equal to patient rate"); return false;
                    }
                }
                else { SweetAlert.fire("Amount cannot be zero"); return false; }
            }
        }
        setIsSaving(true);
        const requestOptions = {
            Id: updaterowid,
            Region: glblregionid,
            PartnerType: glblpartnertypeid,
            TemplateName: values.edittemplatename,
            DisplayName: values.editdisplayname,
            FromDate: values.editfromdate,
            ToDate: values.edittodate,
            IsActive: editisactive,
            TemplateItemsList: JSON.stringify(table),
            CreatedBy: localStorage.LoggedInUser,
            SelectedDays: JSON.stringify(checked)
        };
        try {
            let url
            if (glblpartnertypeid === "7") {
                url = URL_Start + 'master/updatepackageratetemplate'
            }
            else {
                url = URL_Start + 'master/updateratetemplate'
            }
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsSaving(false);
                    SweetAlert.fire({
                        title: 'Updated',
                        text: "Records updated successfully",
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    })
                        .then((result) => {
                            if (result.value) { window.close(); }
                        })
                }
                else { setIsSaving(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsSaving(false); if (error.response) { AxiosError(error.response.status); } });
        } catch (error) { setIsSaving(false); APIError("-3"); }
    }
    const CheckIsActive = (e) => { setEditIsActive(e.target.value); }
    const DiscountCategories = async () => {
        try {
            let url = URL_Start + 'master/getdiscountcategories'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setDiscountCategoriesList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const handleSearch = async () => {
        setTable(glbRecords.filter((item) =>
            item.CategoryName.toLowerCase().indexOf(textSearch.current.value.toLowerCase()) !== -1
            || item.TestName.toLowerCase().indexOf(textSearch.current.value.toLowerCase()) !== -1
        ));
    }
    const handleCategorySearch = async (e) => {
        if (e.target.value === "-1") { setTable(glbRecords); }
        else { setTable(glbRecords.filter((item) => item.DiscountCategoryId.indexOf(e.target.value) !== -1)); }
    }
    const handlePackageSearch = async () => {
        setTable(glbRecords.filter((item) =>
            item.CategoryName.toLowerCase().indexOf(textPackageSearch.current.value.toLowerCase()) !== -1
            || item.TestName.toLowerCase().indexOf(textPackageSearch.current.value.toLowerCase()) !== -1
        ));
    }
    const handleNewItemSearch = async () => {
        setNewItemsData(glbNewRecords.filter((item) => item.TestName.toLowerCase().indexOf(textNewItemSearch.current.value.toLowerCase()) !== -1));
    }
    const handleNewItemSubmit = async () => {
        for (var key in newItemsData) {
            if (glblpartnertypeid === "7") {
                if (parseFloat(newItemsData[key].PatientRate) > 0) {
                    if (parseFloat(newItemsData[key].PatientRate) > parseFloat(newItemsData[key].MRP)) {
                        SweetAlert.fire("MRP must be greater than or equal to patient rate"); return false;
                    }
                }
                else {
                    if (parseFloat(newItemsData[key].MRP) > 0 && parseFloat(newItemsData[key].PatientRate) < 1) {
                        SweetAlert.fire("Patient rate cannot be zero"); return false;
                    }
                }
                if (parseFloat(newItemsData[key].PartnerRate) > 0) {
                    if (parseFloat(newItemsData[key].PartnerRate) > parseFloat(newItemsData[key].PatientRate)) {
                        SweetAlert.fire("Partner rate must be less than or equal to patient rate"); return false;
                    }
                }
                else {
                    if (parseFloat(newItemsData[key].PatientRate) > 0 && parseFloat(newItemsData[key].PartnerRate) < 1) {
                        SweetAlert.fire("PartnerRate rate cannot be zero"); return false;
                    }
                }
            }
            else if (glblpartnertypeid === "5") {
                if (parseFloat(newItemsData[key].ClientRate) > 0) {
                    if (parseFloat(newItemsData[key].ClientRate) > parseFloat(newItemsData[key].ItemMrp)) {
                        SweetAlert.fire("MRP must be greater than or equal to patient rate"); return false;
                    }
                }
                else {
                    if (parseFloat(newItemsData[key].ItemMrp) > 0 && parseFloat(newItemsData[key].ClientRate) < 1) {
                        SweetAlert.fire("Patient rate cannot be zero"); return false;
                    }
                }
                if (parseFloat(newItemsData[key].ItemRate) > 0) {
                    if (parseFloat(newItemsData[key].ItemRate) > parseFloat(newItemsData[key].ClientRate)) {
                        SweetAlert.fire("Partner rate must be less than or equal to patient rate"); return false;
                    }
                }
                else {
                    if (parseFloat(newItemsData[key].ClientRate) > 0 && parseFloat(newItemsData[key].ItemRate) < 1) {
                        SweetAlert.fire("PartnerRate rate cannot be zero"); return false;
                    }
                }
            }
            else {
                if (glblpartnertypeid === "3" || glblpartnertypeid === "4" || glblpartnertypeid === "6") {
                    if (parseFloat(newItemsData[key].ItemRate) > 0) {
                        if (parseFloat(newItemsData[key].ItemRate) > parseFloat(newItemsData[key].ItemMrp)) {
                            SweetAlert.fire("MRP must be greater than or equal to partner rate"); return false;
                        }
                    }
                    else {
                        if (parseFloat(newItemsData[key].ItemMrp) > 0 && parseFloat(newItemsData[key].ItemRate) < 1) {
                            SweetAlert.fire("Partner rate cannot be zero"); return false;
                        }
                    }
                }
                else {
                    if (parseFloat(newItemsData[key].ClientRate) > 0) {
                        if (parseFloat(newItemsData[key].ClientRate) > parseFloat(newItemsData[key].ItemMrp)) {
                            SweetAlert.fire("MRP must be greater than or equal to patient rate");
                            return false;
                        }
                        // if (glblpartnertypeid !== "1") {
                        //     if (parseFloat(newItemsData[key].ItemRate) > 0) {
                        //         if (parseFloat(newItemsData[key].ItemRate) >= parseFloat(newItemsData[key].ClientRate)) {
                        //             SweetAlert.fire("Patient rate must be greater than partner rate");
                        //             return false;
                        //         }
                        //     }
                        //     else {
                        //         if (parseFloat(newItemsData[key].ClientRate) > 0 && parseFloat(newItemsData[key].ItemRate) < 1) {
                        //             SweetAlert.fire("Partner rate cannot be zero");
                        //             return false;
                        //         }
                        //     }
                        // }
                    }
                    else {
                        if (parseFloat(newItemsData[key].ItemMrp) > 0 && parseFloat(newItemsData[key].ClientRate) < 1) {
                            SweetAlert.fire("Patient rate cannot be zero"); return false;
                        }
                    }
                }
            }
        }
        setIsSaving(true);
        let _Type = "ItemRate";
        if (glblpartnertypeid === "7") { _Type = "PackageRate"; }
        const params = new URLSearchParams(location.search);
        const requestOptions = {
            Id: params.get('id'),
            Type: _Type,
            CreatedBy: localStorage.LoggedInUser,
            NewItemRates: JSON.stringify(newItemsData)
        };
        try {
            let url = URL_Start + 'master/addnewitemstoexistingtemplates';
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                if (data.Success) {
                    SweetAlert.fire({
                        title: 'Updated',
                        text: "Item added successfully",
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    })
                        .then((result) => {
                            if (result.value) { setIsSaving(false); GetNewItemToTemplate(params.get('id')); GetExistingTemplatesDetails(params.get('id')); }
                        })
                }
                else { setIsSaving(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsSaving(false); if (error.response) { AxiosError(error.response.status); } });
        } catch (error) { setIsSaving(false); APIError("-3"); }

    }
    const handleCheckChange = (event) => {

        var updatedList = [...checked];
        if (event.target.checked) {
            if (event.target.value === "All") {
                changeAllCheck(true); changeMonCheck(true); changeTueCheck(true); changeWedCheck(true); changeThursdyCheck(true); changeFriCheck(true); changeSatCheck(true); changeSunCheck(true);
                setChecked(["All", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]);
                updatedList = ["All", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
            }
            else {
                updatedList = [...checked, event.target.value];
                switch (event.target.value) {
                    case 'Monday': changeMonCheck(true); break; case 'Tuesday': changeTueCheck(true); break; case 'Wednesday': changeWedCheck(true); break;
                    case 'Thursday': changeThursdyCheck(true); break; case 'Friday': changeFriCheck(true); break; case 'Saturday': changeSatCheck(true); break; case 'Sunday': changeSunCheck(true); break;
                }
            }
        } else {
            if (updatedList.length == 1) { SweetAlert.fire("Either All Days or One day must be selected."); return; }

            if (event.target.value !== "All") { updatedList.splice(checked.indexOf(event.target.value), 1); }
            switch (event.target.value) {
                case 'Monday': changeMonCheck(false); break; case 'Tuesday': changeTueCheck(false); break; case 'Wednesday': changeWedCheck(false); break;
                case 'Thursday': changeThursdyCheck(false); break; case 'Friday': changeFriCheck(false); break; case 'Saturday': changeSatCheck(false); break; case 'Sunday': changeSunCheck(false); break;
            }
            changeAllCheck(false);
            if (allCheck) { updatedList.splice(checked.indexOf("All"), 1); }
        }

        if (updatedList.length === 7 && event.target.value !== "All") {
            const isInArray = updatedList.includes("All");
            if (isInArray === false) {
                updatedList = ["All", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
                changeAllCheck(true); changeMonCheck(true); changeTueCheck(true); changeWedCheck(true); changeThursdyCheck(true); changeFriCheck(true); changeSatCheck(true); changeSunCheck(true);
            }
        }
        setChecked(updatedList);
    };
    const handleFromDateChange = (e, type) => {
        const date = new Date(e.target.value);
        date.setFullYear(date.getFullYear() + 5);
        var day = date.getDate();
        if (day.toString().length < 2) { day = "0" + day; }
        var month = date.getMonth() + 1;
        if (month.toString().length < 2) { month = "0" + month; }
        const year = date.getFullYear();
        if (type === "1") { formik.values.edittodate = year + "-" + month + "-" + day; }
    }
    const AxiosError = async (ErrorCode) => {
        if (ErrorCode === 400) { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode === 401) { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode === 403) { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode === 404) { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode === 500) { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode === 503) { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Data unavailable", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-2") { SweetAlert.fire({ title: "API Server Error", text: ErrorMsg, icon: "error" }); }
        else if (ErrorCode === "-3") { SweetAlert.fire({ title: "Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        else if (ErrorCode === "-4") { SweetAlert.fire({ title: "Wrong Credentials", text: "Please enter correct credentials", icon: "error" }) }
        else if (ErrorCode === "-5") { SweetAlert.fire({ title: "Oops", text: "Data could not be saved, Please try again later", icon: "error" }) }
        else if (ErrorCode === "-6") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
        else if (ErrorCode === "-7") { SweetAlert.fire({ title: "Duplicate", text: "Template already exists", icon: "error" }) }
        else if (ErrorCode === "-8") { SweetAlert.fire({ title: "Data unavailable", text: "No record found to import", icon: "error" }) }
        else if (ErrorCode === "-9") { SweetAlert.fire({ title: "LDAP authentication failed", text: ErrorMsg, icon: "error" }) }
        else if (ErrorCode === "-10") { SweetAlert.fire({ title: "Validations", text: "Please insert atleast one valid rate", icon: "error" }) }
        else if (ErrorCode === "-25") { SweetAlert.fire({ title: "Duplicate", text: "Item already added to this template.Please refresh", icon: "error" })} 
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Rate Template Display" parent="Master / Rate Template" />
            <Container fluid>
                <Row className='mt25'>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row className="justify-content-center">
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>State <span className='text-red'>*</span></Form.Label>
                                            <Form.Control type="text" disabled={disabled} defaultValue={formik.values.editregion} name="editregion" value={formik.values.editregion} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                            {formik.touched.editregion && formik.errors.editregion ? (<div className='text-required'>{formik.errors.editregion}</div>) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Rate Type <span className='text-red'>*</span></Form.Label>
                                            <Form.Control type="text" disabled={disabled} defaultValue={formik.values.editpartnertype} name="editpartnertype" value={formik.values.editpartnertype} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                            {formik.touched.editpartnertype && formik.errors.editpartnertype ? (<div className='text-required'>{formik.errors.editpartnertype}</div>) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Template Name <span className='text-red'>*</span></Form.Label>
                                            <Form.Control type="text" defaultValue={formik.values.edittemplatename} name="edittemplatename" ref={refedittemplate} value={formik.values.edittemplatename} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                            {formik.touched.edittemplatename && formik.errors.edittemplatename ? (<div className='text-required'>{formik.errors.edittemplatename}</div>) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Display Name <span className='text-red'>*</span></Form.Label>
                                            <Form.Control type="text" defaultValue={formik.values.editdisplayname} name="editdisplayname" value={formik.values.editdisplayname} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                            {formik.touched.editdisplayname && formik.errors.editdisplayname ? (<div className='text-required'>{formik.errors.editdisplayname}</div>) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3 align-items-center">
                                            <Form.Label>From Date <span className='text-red'>*</span></Form.Label>
                                            <Form.Control type="date" name="editfromdate" defaultValue={formik.values.editfromdate} value={formik.values.editfromdate} onKeyPress={(e) => { handleKeypress(e) }}
                                                onChange={formik.handleChange} onBlur={formik.handleBlur} onChangeCapture={(e) => { handleFromDateChange(e, "1") }} />
                                            {formik.touched.editfromdate && formik.errors.editfromdate ? (<div className='text-required'>{formik.errors.editfromdate}</div>) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group className="mb-3 align-items-center">
                                            <Form.Label>To Date <span className='text-red'>*</span></Form.Label>
                                            <Form.Control type="date" name="edittodate" defaultValue={formik.values.edittodate} value={formik.values.edittodate} onKeyPress={(e) => { handleKeypress(e) }} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                            {formik.touched.edittodate && formik.errors.edittodate ? (<div className='text-required'>{formik.errors.edittodate}</div>) : null}
                                        </Form.Group>
                                    </Col>

                                    <Col md={3}>
                                        <Form.Group className="mb-3 align-items-center animate-chk m-checkbox-inline">
                                            <Form.Label>Is Active <span className='text-red'>*</span></Form.Label>
                                            <div className='mt-2'>
                                                <Form.Label for="rd1">
                                                    <input class="radio_animated" type="radio" id='rd1' name="isActive" value="1" onChange={CheckIsActive} defaultChecked={isactive} />Yes
                                                </Form.Label>
                                                <Form.Label for="rd2">
                                                    <input class="radio_animated" type="radio" id='rd2' name="isActive" value="0" onChange={CheckIsActive} defaultChecked={isnoactive} />No
                                                </Form.Label>
                                            </div>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Label>Active Days: <span className='text-red'>*</span></Form.Label>
                                        <Form.Group className='animate-chk m-checkbox-inline'>
                                            <Form.Label for="chkBx0">
                                                <input className="checkbox_animated" type="checkbox" id='chkBx0' checked={allCheck} name="isActive" value="All" onChange={handleCheckChange} />All Days
                                            </Form.Label>
                                            <Form.Label for="chkBx1">
                                                <input className="checkbox_animated" type="checkbox" id='chkBx1' checked={monCheck} name="isActive" value="Monday" onChange={handleCheckChange} />Monday
                                            </Form.Label>
                                            <Form.Label for="chkBx2">
                                                <input className="checkbox_animated" type="checkbox" id='chkBx2' checked={tueCheck} name="isActive" value="Tuesday" onChange={handleCheckChange} />Tuesday
                                            </Form.Label>
                                            <Form.Label for="chkBx3">
                                                <input className="checkbox_animated" type="checkbox" id='chkBx3' checked={wedCheck} name="isActive" value="Wednesday" onChange={handleCheckChange} />Wednesday
                                            </Form.Label>
                                            <Form.Label for="chkBx4">
                                                <input className="checkbox_animated" type="checkbox" id='chkBx4' checked={thursdyCheck} name="isActive" value="Thursday" onChange={handleCheckChange} />Thursday
                                            </Form.Label>
                                            <Form.Label for="chkBx5">
                                                <input className="checkbox_animated" type="checkbox" id='chkBx5' checked={friCheck} name="isActive" value="Friday" onChange={handleCheckChange} />Friday
                                            </Form.Label>
                                            <Form.Label for="chkBx6">
                                                <input className="checkbox_animated" type="checkbox" id='chkBx6' checked={satCheck} name="isActive" value="Saturday" onChange={handleCheckChange} />Saturday
                                            </Form.Label>
                                            <Form.Label for="chkBx7">
                                                <input className="checkbox_animated" type="checkbox" id='chkBx7' checked={sunCheck} name="isActive" value="Sunday" onChange={handleCheckChange} />Sunday
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {displayNewItem ? <Row>
                    <Col>
                        <Card>
                            <Card.Header className='pb-0'>
                                {glblpartnertypeid === "7" ? <Card.Title>New Schemes List</Card.Title> : <Card.Title>New Investigation List</Card.Title>}
                            </Card.Header>
                            <Card.Body>
                                {
                                    glblpartnertypeid === "7" ?
                                        <>
                                            <Form.Row><div className='text-red'>Click on rates to enter rate</div></Form.Row>
                                        </> :
                                        <>

                                            <Form.Row><div className='text-red'>Click on mrp or rates to edit</div></Form.Row>
                                        </>
                                }
                                <Form.Row className="justify-content-center">
                                    <Col lg={4} md={6}>
                                        <Form.Group>
                                            <InputGroup className="mb-3">
                                                <Form.Control onChange={handleNewItemSearch} placeholder="Filter by investigation name..." ref={textNewItemSearch} />
                                                <InputGroup.Append>
                                                    <Button variant="secondary" onClick={handleNewItemSearch}><i className='fa fa-search'></i></Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                {
                                    glblpartnertypeid === "1" || glblpartnertypeid === "2" ?
                                        <DataTables
                                            keyField='TestCode'
                                            tableData={newItemsData}
                                            columns={columnsNewMRP}
                                            noDataIndication={NoRecordFounds}
                                            pagination={paginationFactory(newItemPaginations)}
                                        /> :
                                        glblpartnertypeid === "3" || glblpartnertypeid === "4" || glblpartnertypeid === "6" ?
                                            <DataTables
                                                keyField='TestCode'
                                                tableData={newItemsData}
                                                columns={columnsNewB2B}
                                                noDataIndication={NoRecordFounds}
                                                pagination={paginationFactory(newItemPaginations)}
                                            /> :
                                            glblpartnertypeid === "7" ?
                                                <DataTables
                                                    keyField='TestCode'
                                                    tableData={newItemsData}
                                                    columns={columnsNewPackage}
                                                    noDataIndication={NoRecordFounds}
                                                    pagination={paginationFactory(newItemPaginations)}
                                                /> :
                                                <DataTables
                                                    keyField='TestCode'
                                                    tableData={newItemsData}
                                                    columns={columnsNewOther}
                                                    noDataIndication={NoRecordFounds}
                                                    pagination={paginationFactory(newItemPaginations)}
                                                />
                                }
                                {newItemsData.length > 0 ? <Row>
                                    <Col md={12} className='text-center'>
                                        {isSaving ? <LoadingButton variantName='primary' cssName="mr-2" /> : <Button variant='primary' type='submit' className="mr-2" ref={refupdate} onClick={handleNewItemSubmit}>Add Items to Template</Button>}
                                        {glblpartnertypeid === "7" ? <p>Schemes which has valid rate will be saved!</p> : <p>Item's which has valid rate will be saved!</p>}
                                    </Col>
                                </Row>
                                    : <Row></Row>
                                }
                            </Card.Body>
                        </Card>
                    </Col>

                </Row>
                    : <Row></Row>}
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                {glblpartnertypeid === "7" ? <Card.Title>Existing Scheme Rates</Card.Title> : <Card.Title>Existing Investigation Rates</Card.Title>}
                            </Card.Header>
                            <Card.Body>
                                {
                                    glblpartnertypeid === "7" ?
                                        <>
                                            <Form.Row className="justify-content-center">
                                                <Col lg={4} md={6}>
                                                    <Form.Group>
                                                        <InputGroup className="mb-3">
                                                            <Form.Control onChange={handlePackageSearch} placeholder="Filter by Category name/Test name..." ref={textPackageSearch} />
                                                            <InputGroup.Append>
                                                                <Button variant="secondary" onClick={handlePackageSearch}><i className='fa fa-search'></i></Button>
                                                            </InputGroup.Append>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Form.Row>
                                                <div className='text-red'>Click on rates to edit</div>
                                            </Form.Row>
                                        </> :
                                        <>
                                            <Form.Row className="justify-content-center">
                                                <Col md={3}>
                                                    <Form.Group>
                                                        <InputGroup className="mb-3">
                                                            <Form.Label>Filter by discount category&nbsp;</Form.Label>
                                                            <Form.Control as="select" name="categoryId" onChange={handleCategorySearch}>
                                                                <option value="-1">---Select---</option>
                                                                {discountCategoriesList}
                                                            </Form.Control>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                                <Col md={3}>
                                                    <Form.Group>
                                                        <InputGroup className="mb-3">
                                                            <Form.Label>&nbsp;&nbsp;OR&nbsp;&nbsp;&nbsp;</Form.Label>
                                                            <Form.Control onChange={handleSearch} placeholder="Filter by Category name/Test name..." ref={textSearch} />
                                                            <InputGroup.Append>
                                                                <Button variant="secondary" onClick={handleSearch}><i className='fa fa-search'></i></Button>
                                                            </InputGroup.Append>
                                                        </InputGroup>
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Form.Row>
                                                <div className='text-red'>Click on mrp or rates to edit</div>
                                            </Form.Row>
                                        </>
                                }
                                {
                                    glblpartnertypeid === "1" || glblpartnertypeid === "2" ?
                                        <DataTables
                                            keyField='Id'
                                            tableData={table}
                                            columns={columnsMRP}
                                            noDataIndication={NoRecordFounds}
                                            pagination={paginationFactory(paginationOptions)}
                                        /> :
                                        glblpartnertypeid === "3" || glblpartnertypeid === "4" || glblpartnertypeid === "6" ?
                                            <DataTables
                                                keyField='Id'
                                                tableData={table}
                                                columns={columnsB2B}
                                                noDataIndication={NoRecordFounds}
                                                pagination={paginationFactory(paginationOptions)}
                                            /> :
                                            glblpartnertypeid === "7" ?
                                                <DataTables
                                                    keyField='Id'
                                                    tableData={table}
                                                    columns={columnsPackage}
                                                    noDataIndication={NoRecordFounds}
                                                    pagination={paginationFactory(paginationOptions)}
                                                /> :
                                                <DataTables
                                                    keyField='Id'
                                                    tableData={table}
                                                    columns={columnsOther}
                                                    noDataIndication={NoRecordFounds}
                                                    pagination={paginationFactory(paginationOptions)}
                                                />
                                }

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className='text-center'>
                        {isSaving ? <LoadingButton variantName='primary' cssName="mr-2" /> : <Button variant='primary' type='submit' className="mr-2" ref={refupdate} onClick={formik.handleSubmit}>Update</Button>}
                    </Col>
                </Row>
                {
                    isLoading ?
                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                        null
                }
            </Container>
        </React.Fragment>
    )
}

export default DisplayRatePlan