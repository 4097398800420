import React from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from 'reactstrap';

const NewToolTip = (props) => {
    return (
        <>
            <Link to="#" onClick={props.onClick} className="text-dark"><i class={props.icon} id={props.id} aria-hidden="true" icon={props.icon}></i></Link>
            <UncontrolledTooltip placement={props.placement} target={props.id}>
                {props.title}
            </UncontrolledTooltip>
        </>
    )
}

export default NewToolTip;