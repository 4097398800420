import React, { useState, Fragment, useEffect } from 'react';
import logo from '../../../assets/images/logo-atulaya.png';
import UserMenu from './userMenu';
import axios from 'axios';
//import SearchHeader from './searchHeader';
import { Link } from 'react-router-dom';
import { AlignLeft, MoreHorizontal, Settings } from 'react-feather';
import { URL_Start } from '../../../constant';
import SweetAlert from 'sweetalert2';
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import Select from 'react-select';
let GlobalModuleId = "0";
let GlobalGroupLocationDDL = [];
const Header = () => {
  const [SelectLocationUser, SetSelectLocationUser] = useState(localStorage.SelectionOptonLoc == undefined ? "" : JSON.parse(localStorage.getItem('SelectionOptonLoc')));
  const [UserGroupLocationDDL, SetUserGroupLocationDDL] = useState(null);
  const [sidebar, setSidebar] = useState(false);
  const [rightSidebar, setRightSidebar] = useState(true);
  const [headerbar, setHeaderbar] = useState(true);
  const [UserType, setUserType] = useState(localStorage.UserType == undefined ? "" : localStorage.UserType);
  const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
  const [UserAllowModule, SetUserAllowModule] = useState(localStorage.UserAllowModule == undefined ? "" : JSON.parse(localStorage.getItem('UserAllowModule')));
  const openCloseSidebar = () => {
    var element = document.querySelector(".page-main-header");
    var classexist = element.classList.contains('open');
    if (classexist === true) {
      document.querySelector(".page-main-header").classList.remove('open');
      document.querySelector(".page-sidebar").classList.remove('open');
    }
    else {
      document.querySelector(".page-main-header").classList.add('open');
      document.querySelector(".page-sidebar").classList.add('open');
    }
  }
  useEffect(() => {
    const mediaQuery = window.matchMedia('(max-width: 1024px)')
    if (mediaQuery.matches) {
      document.querySelector(".page-main-header").classList.add('open');
      document.querySelector(".page-sidebar").classList.add('open');
    }
  }, [])
  useEffect(() => { FuncModuleList(); FuncUserLocationMappingList(); }, []);
  const [FModuleURL, SetFModuleURL] = useState(null);

  function showRightSidebar() {
    if (rightSidebar) {
      setRightSidebar(!rightSidebar)
      document.querySelector(".right-sidebar").classList.add('show');
    } else {
      setRightSidebar(!rightSidebar)
      document.querySelector(".right-sidebar").classList.remove('show');
    }
  }

  const ChangeHandler = (e) => {
    GlobalModuleId = e.target.value; FuncModuleChangeFirst();
  }
  const FuncModuleChangeFirst = async () => {
    try {
      let url = URL_Start + 'Users/SETUpdateUserModuleChangeAllowStatus';
      let options = {
        method: 'POST', url: url,
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
        data: { UserId: `${LoginId}`, ModuleId: `${GlobalModuleId}`, AddedBy: `${LoginId}`, ModLogId: "0" }
      };

      let response = await axios(options);
      let responseOK = response && response.status == 200;
      if (responseOK) {
        let data = response.data;
        if (data.Success == true) {

          const FModuleURLFetch = FModuleURL.filter((item) => {
            return item.Mid == GlobalModuleId
          });
          if (FModuleURLFetch != null && FModuleURLFetch.length > 0) {
            const ReqURL = FModuleURLFetch[0].ModuleURL;
            window.location.assign(`${ReqURL}?Loginkey=` + data.Data);
          }

        }
        else {
          if (data.ErrorList != null && data.ErrorList.length > 0) {
            let Errtext = <p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
            SweetAlert.fire({ title: "Error", text: data.ErrorList[0].errorMsg, icon: "error" });
          }
        }
      }
      else { console.log('no record found'); }
    } catch (error) {
      console.log(error.message);
      SweetAlert.fire({ title: "Error", text: 'You may not be connected to a network or Unable to connect to a server', icon: "error" });
    }
  }

  const FuncModuleList = async () => {
    try {
      let url = URL_Start + 'master/getBindModuledetails';
      let options = {
        method: 'POST', url: url,
        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
        data: {}
      };

      let response = await axios(options);
      let responseOK = response && response.status == 200;
      if (responseOK) {
        let data = response.data;
        if (data.Success == true) {
          if (data.Data != null && data.Data.length > 0) { SetFModuleURL(data.Data); }
        }
        else {
          if (data.ErrorList != null && data.ErrorList.length > 0) {
            let Errtext = <p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
            SweetAlert.fire({ title: "Error", text: data.ErrorList[0].errorMsg, icon: "error" });
          }
        }
      }
      else {
        console.log('no record found');
      }
    } catch (error) {
      console.log(error.message);
      SweetAlert.fire({ title: "Error", text: 'You may not be connected to a network or Unable to connect to a server', icon: "error" });
    }
  }
  const FuncUserLocationMappingList = async () => {
    try {
      let url = URL_Start + 'Users/GetUserAccessGroupLocationDDL';

      let options = {
        method: 'POST',
        url: url,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: { UserId: `${LoginId}`, ModuleId: `${"3"}` }
      };
      let response = await axios(options);
      let responseOK = response && response.status == 200;
      if (responseOK) {
        let ds = response.data;

        // let data = await response.data;
        if (ds.Success == true) {
          if (ds.Data != null && ds.Data.length > 0) {
            const TypeOptionDs = [];

            ds.Data.map((option) => {
              const TypeOptions = {
                'UserLocld': option.UserLocld,
                'label': option.CustUserLocation,
                'value': option.UserLocld,
                'ModuleId': option.ModuleId,
                'AccessGroupName': option.AccessGroupName,
                'IsDefaultGroup': option.IsDefaultGroup,
                'PageAccessGroupId': option.PageAccessGroupId,
                'disabled': false
              }
              return TypeOptionDs.push(TypeOptions);
            });

            SetUserGroupLocationDDL(TypeOptionDs);
          }
        }
        else {
          if (ds.Data.ErrorList != null && ds.Data.ErrorList.length > 0) {
            let Errtext = <p><div className='text-required'>{ds.Data.ErrorList[0].errorMsg} </div></p>;
            SweetAlert.fire({ title: "Error", text: ds.Data.ErrorList[0].errorMsg, icon: "error" });
          }
        }
      }
      else {
        console.log('no record found');

      }
    } catch (error) {
      console.log(error.message);
      SweetAlert.fire({ title: "Error", text: 'You may not be connected to a network or Unable to connect to a server', icon: "error" });
    }
  }

  const UserAccessSelectionHandler = (options) => {

    SetSelectLocationUser(options);
    localStorage.removeItem("SelectionOptonLoc");
    localStorage.setItem("SelectionOptonLoc", JSON.stringify(options));
    if (options != null) {
      let ModuleId = options.ModuleId;
      let UserId = LoginId;
      let UserLocld = options.UserLocld;
      let PageAccessGroupId = options.PageAccessGroupId;

      BinduserAccessGroupLocations(ModuleId, UserId, UserLocld, PageAccessGroupId);

    }
  }
  async function BinduserAccessGroupLocations(ModuleId, UserId, UserLocld, PageAccessGroupId) {

    try {
      if (PageAccessGroupId) {

        let url = URL_Start + 'Users/GetMoveLocationAccessGroupDetail';
        let options = {
          method: 'POST',
          url: url,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
          },
          data: { ModuleId: `${ModuleId}`, UserId: `${UserId}`, UserLocld: `${UserLocld}`, PageAccessGroupId: `${PageAccessGroupId}` }
        };
        let response = await axios(options);
        let responseOK = response && response.status == 200;
        if (responseOK) {
          let data = response.data;
          if (data.Success == true) {
            let ds = data.Data;
            if (ds != null) {
              localStorage.removeItem("UserGroups");
              localStorage.removeItem("UserSubGroups");
              localStorage.setItem("UserGroups", JSON.stringify(ds.objUserAllowGroupsDetailsList));
              localStorage.setItem("UserSubGroups", JSON.stringify(ds.objUserSubAllowGroupsList));

              window.location.assign(`${process.env.PUBLIC_URL}/dashboard`);
            }
            else {
              window.location.assign(`${process.env.PUBLIC_URL}/dashboard`);
            }
          }
          else {
            window.location.assign(`${process.env.PUBLIC_URL}/dashboard`);

          }

        }
        else {
          window.location.assign(`${process.env.PUBLIC_URL}/dashboard`);
        }
      }

    } catch (error) {
      window.location.assign(`${process.env.PUBLIC_URL}/dashboard`);
      console.log(error.message);
    }
  }

  return (
    <Fragment>

      <div className="page-main-header">
        <div className="main-header-right row">
          <div className="main-header-left d-lg-none">
            <div className="logo-wrapper">
              <Link to={`${process.env.PUBLIC_URL}/pages/Dashboard`}>
                <img className="img-fluid" src={logo} alt="" />
                <div className='logoTagLine'>Imaging <span>&amp;</span> Laboratories</div>
              </Link>
            </div>
          </div>
          <div className="mobile-sidebar d-block">
            <div className="media-body text-right switch-sm">
              <label className="switch">
                <a href="javascript:void(0)" onClick={() => openCloseSidebar()}>
                  <AlignLeft />
                </a>
              </label>
            </div>
          </div>

          <div className="nav-right col p-0">
            <ul className={`nav-menus ${headerbar ? '' : 'open'}`}>
              <li className='text-left' style={{ width: 500 }}  >

                {UserGroupLocationDDL != null && UserGroupLocationDDL.length > 0 ? <Select

                  //  defaultValue={UserGroupLocationDDL[1]}
                  onChange={UserAccessSelectionHandler}
                  options={UserGroupLocationDDL}
                  value={SelectLocationUser}
                /> : null}


                {/* <SearchHeader /> */}
              </li>
              {UserAllowModule != null && UserAllowModule != '' && UserAllowModule.length > 0 ?
                <li className='text-center'> <Form.Control as="select" id="userModule" name="userModule"
                  onChange={ChangeHandler} >
                  {UserAllowModule.map((option) => (

                    <option value={option.ModuleId} selected={option.ModuleId == "3" ? true : false} >{option.ModuleName}</option>))}
                </Form.Control></li> : null
              }
              <li>
                <a href="javascript:void(0)" onClick={showRightSidebar}>
                  {/* <Settings /> */}
                  {/* <span className="dot"></span> */}
                </a>
              </li>
              <UserMenu />
            </ul>
            <div className="d-lg-none mobile-toggle pull-right" onClick={() => setHeaderbar(!headerbar)}><MoreHorizontal /></div>
          </div>
          <script id="result-template" type="text/x-handlebars-template">
            <div className="ProfileCard u-cf">
              <div className="ProfileCard-avatar">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-airplay m-0"><path d="M5 17H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-1">
                </path>
                  <polygon points="12 15 17 21 7 21 12 15"></polygon>
                </svg>
              </div>
              <div className="ProfileCard-details">
                <div className="ProfileCard-realName"></div>
              </div>
            </div>
          </script>
          <script id="empty-template" type="text/x-handlebars-template">
            <div className="EmptyMessage">{"Your search turned up 0 results. This most likely means the backend is down, yikes!"}</div>
          </script>
        </div>
      </div>
    </Fragment>
  )
};
export default Header;