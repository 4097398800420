import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import InputGroup from 'react-bootstrap/InputGroup';
import Breadcrumb from '../../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Spinner } from 'react-bootstrap';
import ToolTip from '../commonComponent/ToolTip';
import LoadingButton from '../commonComponent/LoadingButton';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';

var glbRecords = [];
const Investigations = () => {
    const [tableData, setTableData] = useState([]);
    const [tableDatasample, setTableDataSample] = useState([]);
    const [tableDatainvest, setTableDataInvest] = useState([]);
    const [sampleModal, setSampleModal] = useState();
    const [investModal, setInvestModal] = useState();
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isSyncing, setIsSyncing] = useState(false);
    let textSearch = useRef();
    const [concernFormModal, setConcernFormModal] = useState();
    const [discountCategoriesList, setDiscountCategoriesList] = useState(0);
    const [isSaving, setIsSaving] = useState(false);
    const [csvData, setCSVData] = useState([]);
    const [csvUploadData, setCsvUploadData] = useState([]);

    useEffect(() => { DiscountCategories(); BindGrid(); }, []);
    const DiscountCategories = async () => {
        try {
            let url = URL_Start + 'master/getdiscountcategories'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setDiscountCategoriesList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const handleCategorySearch = async (e) => {
        setTableData(glbRecords.filter((item) => item.DiscountCategoryId.indexOf(e.target.value) !== -1));
    }
    const handleSearch = async () => {
        setCrntPageNO(0);
        // setTableData(glbRecords.filter((item) => item.Name.toLowerCase().indexOf(textSearch.current.value.toLowerCase()) !== -1));
        setTableData(glbRecords.filter((item) => item.Name.toLowerCase().indexOf(textSearch.current.value.toLowerCase()) !== -1 || item.TestCode.toLowerCase().indexOf(textSearch.current.value.toLowerCase()) !== -1));
    }
    const openSampleModal = () => { setSampleModal(!sampleModal); };
    const openInvestModal = () => { setInvestModal(!investModal); };
    const openConcernFormModal = () => { setConcernFormModal(!concernFormModal); };

    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.DisplaySampleTypes === "1" ?
                        <ToolTip title="Sample Types" placement="top" onClick={() => DisplaySampleTypeName(rowData.InvestigationId, "1")} linkId="SampleTypes" faName="fa fa-file-text-o mr-2"></ToolTip>
                        : null
                }
                {
                    rowData.DisplayRequireFields === "1" ?
                        <ToolTip title="Require Fields" placement="top" onClick={() => DisplaySampleTypeName(rowData.InvestigationId, "2")} linkId="RequireFields" faName="fa fa-asterisk mr-2"></ToolTip>
                        : null
                }
                {
                    rowData.DisplayConcernForm === "1" ?
                        <ToolTip title="Concern Forms" placement="top" onClick={() => DisplaySampleTypeName(rowData.InvestigationId, "3")} linkId="ConcernForms" faName="fa fa-wpforms mr-2"></ToolTip>
                        : null
                }
            </div>
        </React.Fragment>
    }

    const columnssample = [
        { dataField: 'SampleName', text: 'Sample Name', editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'DisplayName', text: 'Display Name', editable: false },
        { dataField: 'Container', text: 'Container', editable: false },
        { dataField: 'Color', text: 'Color', editable: false },
        { dataField: 'IsActive', text: 'Is Active', editable: false }
    ];
    const columnsinvest = [
        { dataField: 'FieldName', text: 'FieldName', editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'ShowOnBooking', text: 'Show On Booking', editable: false },
        { dataField: 'ShowOnSampleCollection', text: 'Show On Sample Collection', editable: false },
        { dataField: 'Gender', text: 'Gender', editable: false }
    ];
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'Name', text: 'Name', editable: false, headerStyle: (colum, colIndex) => { return { width: '40%' }; } },
        { dataField: 'TestCode', text: 'Test Code', editable: false, headerStyle: (colum, colIndex) => { return { width: '10%' }; } },
        { dataField: 'SAPCode', text: 'SAP Code', editable: false, headerStyle: (colum, colIndex) => { return { width: '10%' }; } },
        { dataField: 'DiscountCategory', text: 'Discount Category', editable: false, headerStyle: (colum, colIndex) => { return { width: '10%' }; } },
        { dataField: 'HSNCode', text: 'HSN Code', editable: true, headerStyle: (colum, colIndex) => { return { width: '10%' }; } },
        { dataField: 'TaxRate', text: 'Tax Rate', editable: true, headerStyle: (colum, colIndex) => { return { width: '10%' }; } },
        { dataField: 'IsActive', text: 'Is Active', editable: false, headerStyle: (colum, colIndex) => { return { width: '10%' }; } },
        { dataField: 'actions', text: 'Actions', formatter: actionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    ];
    const concernFormActionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <ToolTip title="View Form" placement="top" onClick={() => OpenForm(rowData.FormUrl)} linkId="Download" faName="fa fa-eye mr-2"></ToolTip> Download
            </div>
        </React.Fragment>
    }
    const concernFormColumns = [
        { dataField: 'FormName', text: 'FormName', editable: false },
        { dataField: 'FormUrl', text: 'Download Form', formatter: concernFormActionFormatter, editable: false }
    ];
    const OpenForm = (formUrl) => { window.open(formUrl, "_blank"); }
    const BindGrid = async () => {
        setIsLoading(true);
        let url = URL_Start + 'master/getinvestigationsmasterlist'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                glbRecords = data.Data;
                setTableData(glbRecords);
                var arrayCSV = glbRecords;
                console.log(glbRecords);
                arrayCSV = arrayCSV.map(({ InvestigationId, Name, TestCode, HSNCode, TaxRate }) => ({ InvestigationId, Name, TestCode, HSNCode, TaxRate }));
                setCSVData(arrayCSV);
                setIsLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime.", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const DisplaySampleTypeName = async (InvestigationId, Flag) => {
        let url = URL_Start + 'master/getinvestigationsamplefieldname/' + InvestigationId + "/" + Flag
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data != null) {
                    if (Flag === "1") { openSampleModal(); setTableDataSample(data.Data); }
                    else if (Flag === "2") { openInvestModal(); setTableDataInvest(data.Data); }
                    else if (Flag === "3") { openConcernFormModal(); setTableDataInvest(data.Data); }
                }
                else { SweetAlert.fire('No data available.') }
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const paginationOptions = {
        sizePerPage: 50,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    const SyncInvestigations = async () => {
        setIsSyncing(true);
        let url = URL_Start + 'LIS/getlisinvestigationrequiredfield'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                SyncInvestigationsMaster();
            }
            else { setIsSyncing(false); SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsSyncing(false); });
    }
    const SyncInvestigationsMaster = async () => {
        setIsSyncing(true);
        let url = URL_Start + 'LIS/getlisinvestigationmaster'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                SyncInvestigationsSampleType();
            }
            else { setIsSyncing(false); SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsSyncing(false); });
    }
    const SyncInvestigationsSampleType = async () => {
        setIsSyncing(true);
        let url = URL_Start + 'LIS/getlisinvestigationssampletype'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                SyncSampleType();
            }
            else { setIsSyncing(false); SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsSyncing(false); });
    }
    const SyncSampleType = async () => {
        setIsSyncing(true);
        let url = URL_Start + 'LIS/getlissampletypemaster'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                SyncInvestigationsLabObservations();
            }
            else { setIsSyncing(false); SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsSyncing(false); });
    }
    const SyncInvestigationsLabObservations = async () => {
        setIsSyncing(true);
        let url = URL_Start + 'LIS/getlislabobservationinvestigation'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                SweetAlert.fire({ title: "Success!", text: "Data synced successfully!", icon: "success" });
                setIsSyncing(false);
                BindGrid();
            }
            else { setIsSyncing(false); SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsSyncing(false); });
    }
    const UpdateHSNCode = async () => {
        setIsSaving(true);
        var arrayHSNCode = [];
        for (var key in tableData) {
            if (tableData[key].HSNCode !== "" || tableData[key].TaxRate !== "") {
                arrayHSNCode.push({ InvestigationId: tableData[key].InvestigationId, HSNCode: tableData[key].HSNCode, TaxRate: tableData[key].TaxRate });
            }
        }
        const requestOptions = {
            IsPackage: "0",
            ModifiedBy: localStorage.LoggedInUser,
            HSNCodes: arrayHSNCode,
        };
        let url = URL_Start + 'master/updatehsncode'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                SweetAlert.fire({ title: "Success", text: "HSN Code updated successfully", icon: "success" });
                BindGrid();
                setIsSaving(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsSaving(false); }
        }).catch(function (error) { setIsSaving(false); });
    }
    const processData = (dataString, flag) => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j].replace(/ /g, "")] = d;
                    }
                }
                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }
        console.log(list);
        setCsvUploadData(list);
    }
    const handleNewFileUpload = (e, flag) => {
        setCsvUploadData([]);
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data, flag);
        };
        reader.readAsBinaryString(file);
    }
    const UploadHSNCode = async () => {
        setIsSaving(true);
        var arrayHSNCode = [];
        var isDataValid = true;
        var errorMsg = true;
        for (var key in csvUploadData) {
            if (csvUploadData[key].HSNCode !== "" || csvUploadData[key].TaxRate !== "") {
                if (isNaN(csvUploadData[key].TaxRate) === false) {
                    arrayHSNCode.push({ InvestigationId: csvUploadData[key].InvestigationId, HSNCode: csvUploadData[key].HSNCode, TaxRate: csvUploadData[key].TaxRate });
                }
                else { isDataValid = false; errorMsg = "Please enter valid data for " + csvUploadData[key].PackageName + ", you have entered " + csvUploadData[key].TaxRate + " in tax rate column"; break; }
            }
        }
        if (isDataValid) {
            const requestOptions = {
                IsPackage: "0",
                ModifiedBy: localStorage.LoggedInUser,
                HSNCodes: arrayHSNCode,
            };
            let url = URL_Start + 'master/updatehsncode'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                if (data.Success) {
                    SweetAlert.fire({ title: "Success", text: "HSN Code/Tax Rate uploaded successfully", icon: "success" });
                    BindGrid();
                    setIsSaving(false);
                }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsSaving(false); }
            }).catch(function (error) { setIsSaving(false); });
        } else { SweetAlert.fire({ title: "Data Validation Failed", text: errorMsg, icon: "error" }); setIsSaving(false); }
    }

    return (
        <React.Fragment>
            <Breadcrumb title="Investigations" parent="LIS Master" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row className="justify-content-center">

                                    <Col lg={5} md={5} xs={12}>
                                        <Form.Group>
                                            <InputGroup className="mb-0">
                                                <Col lg={6} md={6} xs={12} className="p-0 m-0 text-sm-center text-md-center text-xs-center">
                                                    <Form.Label>Filter by discount category &nbsp;</Form.Label>
                                                </Col>
                                                <Col lg={6} md={6} xs={12} className="p-0 m-0">
                                                    <Form.Control as="select" name="categoryId" onChange={handleCategorySearch}>
                                                        <option value="0">---Select---</option>
                                                        {discountCategoriesList}
                                                    </Form.Control>
                                                </Col>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={5} md={5} xs={12}>
                                        <Form.Group>
                                            <InputGroup className="mb-0">
                                                <Col lg={2} md={2} xs={12} className="p-0 m-0 pt-2 text-sm-center text-md-center text-xs-center">
                                                    <Form.Label>&nbsp;&nbsp; OR &nbsp;&nbsp;&nbsp;</Form.Label>
                                                </Col>
                                                <Col lg={10} md={10} xs={12} className="p-0 m-0">

                                                    <InputGroup.Append>
                                                        <Form.Control onChange={handleSearch} placeholder="Search by Name or test code..." ref={textSearch} />
                                                        <Button variant="secondary" onClick={handleSearch}><i className='fa fa-search'></i></Button>
                                                    </InputGroup.Append>
                                                </Col>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2} md={2} xs={12}>
                                        <Form.Group>
                                            <InputGroup className="mb-0">
                                                <Col className="p-0 m-0 mx-auto">
                                                    <InputGroup.Append className='justify-content-center'>
                                                        <Button variant='primary' type='submit' onClick={SyncInvestigations} >{isSyncing ? <Spinner animation="border" size='sm' /> : null} Sync Data </Button>
                                                    </InputGroup.Append>
                                                </Col>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={6} xs={12}>
                                        <Form.Group>
                                            <Form.Label>Upload file (.XLS or .XLSX or .CSV) <span className='text-red'>*</span></Form.Label>
                                            <input type='file' accept=".xls,.xlsx,.csv" onChange={(e) => handleNewFileUpload(e, "1")} />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={6} xs={12}>
                                    {isSaving ? <LoadingButton variantName='primary' cssName="mr-2" /> : <Button variant='primary' type='submit' className="mr-2" onClick={UploadHSNCode}>Upload</Button>}
                                    <CSVLink className='textUnderline' data={csvData} filename={"Sample.csv"} target="_blank">Download Sample CSV</CSVLink>

                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Investigations List</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <DataTables keyField='InvestigationId' tableData={tableData} columns={columns} pagination={paginationFactory(paginationOptions)} />
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className='text-center'>
                        {isSaving ? <LoadingButton variantName='primary' cssName="mr-2" /> : <Button variant='primary' type='submit' className="mr-2" onClick={UpdateHSNCode}>Update</Button>}
                    </Col>
                </Row>
                {sampleModal &&
                    <Modal isOpen={sampleModal} toggle={openSampleModal} centered={true} size="lg">
                        <ModalHeader toggle={openSampleModal}>Sample Types</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Body>
                                            <DataTables keyField='id' tableData={tableDatasample} columns={columnssample} />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                }
                {investModal &&
                    <Modal isOpen={investModal} toggle={openInvestModal} centered={true} size="lg">
                        <ModalHeader toggle={openInvestModal}>Required Fields</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Body>
                                            <DataTables keyField='id' tableData={tableDatainvest} columns={columnsinvest} />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                }
                {concernFormModal &&
                    <Modal isOpen={concernFormModal} toggle={openConcernFormModal} centered={true} size="lg">
                        <ModalHeader toggle={openConcernFormModal}>Concern Forms</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Body>
                                            <DataTables keyField='id' tableData={tableDatainvest} columns={concernFormColumns} />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                }
            </Container>
        </React.Fragment>
    )
}

export default Investigations;