import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import SweetAlert from 'sweetalert2';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import LoadingButton from '../commonComponent/LoadingButton';

var glblArr = [];
const PackageCategoryDetails = (id) => {
    const history = useHistory();
    const [modal, setModal] = useState();
    const toggleModal = () => { setModal(!modal) }
    const [errorModal, setErrorModal] = useState();
    const errorHandler = () => { setModal(true); setErrorModal(true); }
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    useEffect(() => { GetDetails(); }, []);
    const GetDetails = async () => {
        try {
            if (localStorage.getItem('pcId') !== null) {
                setIsLoading(true);
                formik.values.id = localStorage.getItem('pcId');
                let url = URL_Start + 'master/getpackagecategoryinfo/' + formik.values.id;
                await axios.get(url).then((response) => {
                    const data = response.data;
                    if (data.Success) {
                        const dbInfo = data.Data;
                        formik.values.categoryName = dbInfo.CategoryName;
                        setIsLoading(false);
                    }
                }).catch(function (error) { setIsLoading(false); });
            }
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); setIsLoading(false); }
    }
    const formik = useFormik({
        initialValues: { id: '0', categoryName: '' },
        validationSchema: Yup.object({
            categoryName: Yup.string().required('Category name is required')
        }),
        onSubmit: values => { setModal(false); setErrorModal(false); AddInfo(values); }
    });
    const AddInfo = async (values) => {
        setIsSaving(true);
        const requestOptions = {
            Id: values.id,
            CategoryName: values.categoryName,
            AddedBy: localStorage.LoggedInUser
        };
        let url = URL_Start + 'master/setpackagecategory'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            console.log(data);
            if (data.Success) {
                SweetAlert.fire({ title: "Success", text: "Data saved successfully", icon: "success" });
                formik.resetForm();
                localStorage.removeItem('pcId');
                history.push({ pathname: `${process.env.PUBLIC_URL}/master/packagecategories`, search: `` });
            }
            else {
                if (data.ErrorList[0].errorCode === "-2") { SweetAlert.fire({ title: "Database validations", text: "Category name already exist.", icon: "error" }); }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); }
                setIsSaving(false);
            }
        }).catch(function (error) { setIsSaving(false); });
    }
    const cancelHandler = async (CentreId) => {
        localStorage.removeItem('pcId');
        history.push({ pathname: `${process.env.PUBLIC_URL}/master/packagecategories`, search: `` });
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Package Category Detail" parent="Master" />
            <Container fluid>
                {
                    isLoading ?
                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                        <>
                            <Form onSubmit={formik.handleSubmit}>
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <Card.Header className='pb-0'>
                                                <Card.Title>Details</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form.Row>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Title<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type='text' name="categoryName" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.categoryName} />
                                                            {formik.touched.categoryName && formik.errors.categoryName ? (<div className='text-required'>{formik.errors.categoryName}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className='mt15'>
                                    <Col md={12} className='text-center'>
                                        <Button variant='secondary' className="mr-2" onClick={cancelHandler}>Cancel</Button>
                                        {isSaving ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' type='submit' onClick={errorHandler}>Save</Button>}
                                    </Col>
                                </Row>
                            </Form>
                        </>
                }
                {errorModal &&
                    <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                        <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                        <ModalBody>
                            <p>{formik.touched.categoryName && formik.errors.categoryName ? (<div className='text-required'>{formik.errors.categoryName}</div>) : null}</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                            <Button variant="primary" onClick={toggleModal}>Ok</Button>
                        </ModalFooter>
                    </Modal>
                }
            </Container>
        </React.Fragment >
    )
}

export default PackageCategoryDetails;