import React, { useState, useEffect, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import { Spinner } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import LoadingButton from '../commonComponent/LoadingButton';
import InputGroup from 'react-bootstrap/InputGroup';
import ToolTip from '../commonComponent/ToolTip';

var glbRecords = [];
var glblImportRecords = [];
var glblImportDays = [];
const RatePlan = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [table, setTable] = useState([]);
    const [addimportvisible, setAddImportVisible] = useState(false);
    const [addratesvisible, setAddRatesVisible] = useState(false);
    const [importratesvisible, setImportRatesVisible] = useState(false);
    const [region, setRegion] = useState("0");
    const [partnertype, setPartnerType] = useState("0");
    const [csvdata, setCsvData] = useState([]);
    const [rowcsvdata, setRowCsvData] = useState([]);
    const [rowfilename, setRowFileName] = useState();
    const [storetemplateitem, setStoreTemplateItem] = useState([]);
    const [isactive, setIsActive] = useState("1");
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [regiontemplate, setRegionTemplate] = useState("0");
    const [existingtemplatesdata, setExistingTemplatesData] = useState([]);
    const [existingtemplatesdatadays, setExistingTemplatesDataDays] = useState([]);
    const [regioncsvname, setRegionCSVName] = useState();
    const [partnertypecsvname, setPartnerTypeCSVName] = useState();
    const [importpartnertype, setImportPartnerType] = useState("0");
    const [showexistingcsv, setShowExistingCsv] = useState(false);
    const [verifyrate, setVerifyRate] = useState([]);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [isSaving, setIsSaving] = useState(false);
    const [hideAddImportButtons, setHideAddImportButtons] = useState(false);
    const [templateId, setTemplateId] = useState(0);
    const [templateName, setTemplateName] = useState(0);
    const [templateDate, setTemplateDate] = useState(0);
    const [checked, setChecked] = useState(["All", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]);
    const [allCheck, changeAllCheck] = useState(true);
    const [monCheck, changeMonCheck] = useState(true);
    const [tueCheck, changeTueCheck] = useState(true);
    const [wedCheck, changeWedCheck] = useState(true);
    const [thursdyCheck, changeThursdyCheck] = useState(true);
    const [friCheck, changeFriCheck] = useState(true);
    const [satCheck, changeSatCheck] = useState(true);
    const [sunCheck, changeSunCheck] = useState(true);
    let refregion = useRef();
    let refpartnertype = useRef();
    let refregionimport = useRef();
    let reftemplateimport = useRef();
    let refpartnertypeimport = useRef();
    let textSearch = useRef();
    let textPackageSearch = useRef();
    var glblitemstore = [];
    var glblimportitemstore = [];

    useEffect(() => { GetStatesList("1"); GetPartnerType(); }, [])

    const paginationOptions = {
        sizePerPage: 500,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const NewTab = async (Id) => {
        setAddImportVisible(false);
        setAddRatesVisible(false);
        setImportRatesVisible(false);
        setHideAddImportButtons(false);
        setPartnerType("0");
        setRegion("0");
        GetPartnerType();
        GetStatesList("1");
        setVerifyRate([]);
        window.open(`${process.env.PUBLIC_URL}/master/displayrateplan?id=${Id}`, "_blank")
    }
    const DownloadTemplateItems = async (Id, displayName) => {
        setIsLoading(true);
        try {
            let url = URL_Start + 'master/getexistingrowtemplateitems/' + Id + '/' + refpartnertype.current.value
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsLoading(false);
                    if (data.Data != null) {
                        setRowFileName(regioncsvname + "_" + partnertypecsvname + "_" + displayName+'.csv');
                        setRowCsvData(data.Data);
                        setDownloadVisible(true);
                        document.getElementById("csvdownload").click();
                    }
                    else {
                        setRowCsvData([]);
                        setDownloadVisible(false);
                        APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg);
                    }
                }
                else { setIsLoading(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
        } catch (error) { setIsLoading(false); APIError("-3"); }
    }
    const formik = useFormik({
        initialValues: { id: '0', templatename: '', displayname: '', fromdate: '', todate: '' },
        validationSchema: Yup.object({
            templatename: Yup.string().required('Template Name is required'),
            displayname: Yup.string().required('Display Name is required'),
            fromdate: Yup.string().required('From Date is required'),
            todate: Yup.string().required('To Date is required'),
        }),
        onSubmit: values => { SaveRate(values); }
    });
    const editformik = useFormik({
        initialValues: { id: '0', regionimport: '', centretypeimport: '', templateimport: '', templatenameimport: '', displaynameimport: '', fromdateimport: '', todateimport: '' },
        validationSchema: Yup.object({
            regionimport: Yup.string().required('Region is required'),
            centretypeimport: Yup.string().required('Rate Type is required'),
            templateimport: Yup.string().required('Template is required'),
            templatenameimport: Yup.string().required('Template Name is required'),
            displaynameimport: Yup.string().required('Display Name is required'),
            fromdateimport: Yup.string().required('From Date is required'),
            todateimport: Yup.string().required('To Date is required'),
        }),
        onSubmit: values => { SaveImportRate(values); }
    });
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>                <span>{crntPageNO + rowIndex + 1}</span>            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <ToolTip title="Edit" placement="top" onClick={() => NewTab(rowData.Id)} linkId="Edit" faName="fa fa-pencil mr-2"></ToolTip>
                <ToolTip title="Download" placement="top" onClick={() => DownloadTemplateItems(rowData.Id, rowData.DisplayName)} linkId="Download" faName="fa fa-download mr-2"></ToolTip>
                <ToolTip title="Refresh" placement="top" onClick={() => handleReupload(rowData)} linkId="Refresh" faName="fa fa-repeat mr-2"></ToolTip>
            </div>
        </React.Fragment>
    }
    const columns = [{ dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    { dataField: 'TemplateName', text: 'Template Name', editable: false },
    { dataField: 'DisplayName', text: 'Display Name', editable: false },
    { dataField: 'FromDate', text: 'From Date', editable: false },
    { dataField: 'ToDate', text: 'To Date', editable: false },
    { dataField: 'IsActive', text: 'Is Active', editable: false},
    { dataField: 'actions', isDummyField: true, text: 'Actions', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    const rowStyle2 = (row, rowIndex) => {
        const style = {};
        if (row.Status === "-2000") { style.backgroundColor = "#ff5c62"; }
        else if (row.Status === "1" || row.Status === "2") { style.backgroundColor = "#ffc107"; }
        else if (row.Status === "3" || row.Status === "4" || row.Status === "5" || row.Status === "6") { style.backgroundColor = "#add8e6"; }
        return style;
    };
    const actionvalidationformatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.Status === "1" ? "Data should not be character"
                        : rowData.Status === "2" ? "Amount should not be zero"
                            : rowData.Status === "3" ? "MRP must be greater than or equal to patient rate"
                                : rowData.Status === "4" ? "Partner rate must be less than or equal to patient rate"
                                    : rowData.Status === "5" ? "MRP must be greater than or equal to patient rate"
                                        : rowData.Status === "6" ? "MRP must be greater than partner rate"
                                            : rowData.Status === "-2000" ? "Invalid"
                                                : "Valid"
                }
            </div>
        </React.Fragment>
    }
    const verifycolumnsMRP = [{ dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    { dataField: 'CategoryName', text: 'Category Name', editable: false },
    { dataField: 'TestName', text: 'Test Name', editable: false },
    { dataField: 'TestCode', text: 'Test Code', editable: false },
    { dataField: 'ItemMrp', text: 'MRP', editable: true },
    { dataField: 'ClientRate', text: 'Patient Rate', editable: true },
    { formatter: actionvalidationformatter, text: 'Status', editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; }  }
    ];
    const verifycolumnsB2B = [{ dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    { dataField: 'CategoryName', text: 'Category Name', editable: false },
    { dataField: 'TestName', text: 'Test Name', editable: false },
    { dataField: 'TestCode', text: 'Test Code', editable: false },
    { dataField: 'ItemMrp', text: 'MRP', editable: true },
    { dataField: 'ItemRate', text: 'Partner Rate', editable: true },
    { formatter: actionvalidationformatter, text: 'Status', editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; }  }
    ];
    const verifypackagecolumnsrate = [{ dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    { dataField: 'CategoryName', text: 'Category Name', editable: false },
    { dataField: 'TestName', text: 'Test Name', editable: false },
    { dataField: 'TestCode', text: 'Test Code', editable: false },
    { dataField: 'MRP', text: 'MRP', editable: true },
    { dataField: 'PartnerRate', text: 'Partner Rate', editable: true },
    { dataField: 'PatientRate', text: 'Patient Rate', editable: true },
    { formatter: actionvalidationformatter, text: 'Status', editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; }  }
    ];
    const verifycolumnsOther = [{ dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    { dataField: 'CategoryName', text: 'Category Name', editable: false },
    { dataField: 'TestName', text: 'Test Name', editable: false },
    { dataField: 'TestCode', text: 'Test Code', editable: false },
    { dataField: 'ItemMrp', text: 'MRP', editable: true },
    { dataField: 'ItemRate', text: 'Partner Rate', editable: true },
    { dataField: 'ClientRate', text: 'Patient Rate', editable: true },
    { formatter: actionvalidationformatter, text: 'Status', editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; }  }
    ];
    const handleKeypress = (e) => { e.preventDefault(); return false; }
    const handleReupload = async (templateInfo) => {
        setHideAddImportButtons(true);
        setAddRatesVisible(false);
        setImportRatesVisible(false);
        formik.values.id = templateInfo.Id.toString();
        formik.values.templatename = templateInfo.TemplateName;
        formik.values.displayname = templateInfo.DisplayName;
        formik.values.fromdate = templateInfo.FromDate;
        formik.values.todate = templateInfo.ToDate;
        setTemplateId(templateInfo.Id);
        setTemplateName(templateInfo.DisplayName);
        setTemplateDate(templateInfo.FromDate + " TO " + templateInfo.ToDate);
    }
    const CallAddImport = (e) => {
        setVerifyRate([]);
        setHideAddImportButtons(false);
        if (e.target.value === "0") {
            setAddImportVisible(false);
            setAddRatesVisible(false);
            setImportRatesVisible(false);
            setCsvData([]);
        }
        else {
            if (refregion.current.value === "0") {
                SweetAlert.fire('Region is required.');
                setPartnerType("0");
                GetPartnerType();
                return false;
            }
            else {
                setAddImportVisible(true);
                setAddRatesVisible(false);
                setImportRatesVisible(false);
                setPartnerTypeCSVName(e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text);
                GetExistingTemplates(refregion.current.value, e.target.value);
                GetAllLISInvestigationItems();
            }
        }
    }
    const GetAllLISInvestigationItems = async () => {
        setIsLoading(true);
        try {
            let url = URL_Start + 'master/getallLISinvestigationitemslist/' + refpartnertype.current.value
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsLoading(false);
                    if (data.Data != null) { setCsvData(data.Data); } else { APIError(data.Data); }
                }
                else { setIsLoading(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
        } catch (error) { setIsLoading(false); APIError("-3"); }
    }
    const CallRegionAddImport = (e) => {
        setAddImportVisible(false);
        setAddRatesVisible(false);
        setImportRatesVisible(false);
        setPartnerType("0");
        GetPartnerType();
        setRegionCSVName(e.nativeEvent.target[e.nativeEvent.target.selectedIndex].text);
        setVerifyRate([]);
        setHideAddImportButtons(false);
    }
    const CallRegionPartner = (e) => {
        setImportPartnerType("0");
        setRegionTemplate("0");
        setShowExistingCsv(false);
        editformik.values.centretypeimport = "0";
        if (e.target.value !== "0") { GetImportPartnerType(); }
    }
    const addsavingfields = () => {
        formik.values.templatename = "";
        formik.values.displayname = "";
        formik.values.fromdate = "";
        formik.values.todate = "";
        setAddRatesVisible(true);
        setImportRatesVisible(false);
        setHideAddImportButtons(false);
        setStoreTemplateItem([]);
        setVerifyRate([]);
        formik.resetForm();
    }
    const importsavingfields = () => {
        editformik.values.templatenameimport = "";
        editformik.values.displaynameimport = "";
        setImportRatesVisible(true);
        setAddRatesVisible(false);
        setShowExistingCsv(false);
        setHideAddImportButtons(false);
        setVerifyRate([]);
        editformik.resetForm();
    }
    const CheckIsActive = (e) => { setIsActive(e.target.value); }
    // process CSV data
    const processData = (dataString, flag) => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j].replace(/ /g, "")] = d;
                    }
                }
                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }
        if (flag === "1") { setStoreTemplateItem(list); } else { setExistingTemplatesData(list); }
    }
    const handleNewFileUpload = (e, flag) => {
        setVerifyRate([]);
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data, flag);
        };
        reader.readAsBinaryString(file);
    }
    const VerifyRate = async () => {
        if (storetemplateitem.length === 0) { SweetAlert.fire("Choose a file before verify"); return false; }
        const requestOptions = { PartnerType: refpartnertype.current.value, TemplateItemsList: JSON.stringify(storetemplateitem), CreatedBy: localStorage.LoggedInUser, };
        setIsSaving(true);
        try {
            let url;
            refpartnertype.current.value === "7" ? url = URL_Start + 'master/verifypackageratetemplate' : url = URL_Start + 'master/verifyratetemplate'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsLoading(false);
                    if (data.Data !== null) {
                        if (data.Data === "-1") { setVerifyRate([]); } else { glbRecords = data.Data; setVerifyRate(data.Data); }
                    }
                    setIsSaving(false);
                    document.getElementById("verify").style.display = "none";
                }
                else { setIsSaving(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsSaving(false); if (error.response) { AxiosError(error.response.status); } });
        } catch (error) { setIsSaving(false); APIError("-3"); }
    }
    const SaveRate = async (values) => {
        setHideAddImportButtons(false);
        glblitemstore = [];
        if (refpartnertype.current.value === "7") {
            for (var key in verifyrate) {
                if (parseFloat(verifyrate[key].PatientRate) > 0 && parseFloat(verifyrate[key].MRP) > 0 && parseFloat(verifyrate[key].PartnerRate) > 0) {
                    if (parseFloat(verifyrate[key].PatientRate) > parseFloat(verifyrate[key].MRP)) {
                        glblitemstore = []; SweetAlert.fire("Patient rate must be less than or equal to MRP"); return false;
                    }
                    if (parseFloat(verifyrate[key].PartnerRate) > parseFloat(verifyrate[key].MRP)) {
                        glblitemstore = []; SweetAlert.fire("Partner rate must be less than or equal to MRP"); return false;
                    }
                    if (parseFloat(verifyrate[key].PartnerRate) > parseFloat(verifyrate[key].PatientRate)) {
                        glblitemstore = []; SweetAlert.fire("Partner rate must be less than or equal to patient rate"); return false;
                    }
                }
                else { SweetAlert.fire("Amount cannot be zero"); return false; }
                glblitemstore.push(
                    {
                        UniqueId: verifyrate[key].Id, Id: verifyrate[key].ItemId, CategoryName: verifyrate[key].CategoryName,
                        TestName: verifyrate[key].TestName, TestCode: verifyrate[key].TestCode, MRP: verifyrate[key].MRP,
                        PartnerRate: verifyrate[key].PartnerRate, PatientRate: verifyrate[key].PatientRate
                    }
                );
            }
        }
        else if (refpartnertype.current.value === "5") {
            for (var key in verifyrate) {
                if (parseFloat(verifyrate[key].ClientRate) > 0 && parseFloat(verifyrate[key].ItemMrp) > 0 && parseFloat(verifyrate[key].ItemRate) > 0) {
                    if (parseFloat(verifyrate[key].ClientRate) > parseFloat(verifyrate[key].ItemMrp)) {
                        glblitemstore = []; SweetAlert.fire("Patient rate must be less than or equal to ItemMrp"); return false;
                    }
                    if (parseFloat(verifyrate[key].ItemRate) > parseFloat(verifyrate[key].ItemMrp)) {
                        glblitemstore = []; SweetAlert.fire("Partner rate must be less than or equal to ItemMrp"); return false;
                    }
                    if (parseFloat(verifyrate[key].ItemRate) > parseFloat(verifyrate[key].ClientRate)) {
                        glblitemstore = []; SweetAlert.fire("Partner rate must be less than or equal to patient rate"); return false;
                    }
                }
                else { SweetAlert.fire("Amount cannot be zero"); return false; }
                glblitemstore.push(
                    {
                        UniqueId: verifyrate[key].Id, Id: verifyrate[key].InvestigationId, CategoryName: verifyrate[key].CategoryName,
                        TestName: verifyrate[key].TestName, TestCode: verifyrate[key].TestCode, MRP: verifyrate[key].ItemMrp,
                        PartnerRate: verifyrate[key].ItemRate, PatientRate: verifyrate[key].ClientRate
                    }
                );
            }
        }
        else {
            for (var key in verifyrate) {
                if (refpartnertype.current.value === "3" || refpartnertype.current.value === "4" || refpartnertype.current.value === "6") {
                    if (parseFloat(verifyrate[key].ItemRate) > 0 && parseFloat(verifyrate[key].ItemMrp) > 0) {
                        if (parseFloat(verifyrate[key].ItemRate) > parseFloat(verifyrate[key].ItemMrp)) {
                            glblitemstore = []; SweetAlert.fire("Partner rate must be less than or equal to MRP"); return false;
                        }
                    } else { SweetAlert.fire("Amount cannot be zero"); return false; }
                }
                else {
                    if (parseFloat(verifyrate[key].ClientRate) > 0 && parseFloat(verifyrate[key].ItemMrp) > 0) {
                        if (parseFloat(verifyrate[key].ClientRate) > parseFloat(verifyrate[key].ItemMrp)) {
                            glblitemstore = []; SweetAlert.fire("patient rate must be less than or equal to MRP"); return false;
                        }
                    }
                    else { SweetAlert.fire("Amount cannot be zero"); return false; }
                }
                glblitemstore.push(
                    {
                        UniqueId: verifyrate[key].Id, Id: verifyrate[key].InvestigationId, CategoryName: verifyrate[key].CategoryName,
                        TestName: verifyrate[key].TestName, TestCode: verifyrate[key].TestCode, MRP: verifyrate[key].ItemMrp,
                        PartnerRate: verifyrate[key].ItemRate === null ? 0 : verifyrate[key].ItemRate,
                        PatientRate: verifyrate[key].ClientRate === null ? 0 : verifyrate[key].ClientRate
                    }
                );
            }
        }
        const requestOptions = {
            Id: values.id,
            Region: refregion.current.value,
            PartnerType: refpartnertype.current.value,
            TemplateName: values.templatename,
            DisplayName: values.displayname,
            FromDate: values.fromdate,
            ToDate: values.todate,
            IsActive: isactive,
            TemplateItemsList: JSON.stringify(glblitemstore),
            CreatedBy: localStorage.LoggedInUser,
            SelectedDays: JSON.stringify(checked)
        };
        setIsSaving(true);
        try {
            let url
            if (refpartnertype.current.value === "7") { url = URL_Start + 'master/savepackageratetemplate' }
            else { url = URL_Start + 'master/saveratetemplate' }
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsSaving(false);
                    SweetAlert.fire({ title: 'Saved', text: "Records saved successfully", icon: 'success', confirmButtonText: 'Ok', reverseButtons: true })
                        .then((result) => {
                            if (result.value) {
                                setAddImportVisible(false);
                                setAddRatesVisible(false);
                                setImportRatesVisible(false);
                                setPartnerType("0");
                                setRegion("0");
                                GetPartnerType();
                                GetStatesList("1");
                                setVerifyRate([]);
                                const tempid = parseInt(data.Data);
                                window.open(`${process.env.PUBLIC_URL}/master/displayrateplan?id=${tempid}`, "_blank");
                            }
                        })
                }
                else { setIsSaving(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsSaving(false); if (error.response) { AxiosError(error.response.status); } });
        } catch (error) { setIsSaving(false); APIError("-3"); }
    }
    const SaveImportRate = async (values) => {
        glblimportitemstore = [];
        if (refpartnertype.current.value === "7" || refpartnertype.current.value === "5") {
            for (var key in existingtemplatesdata) {
                if (parseFloat(existingtemplatesdata[key].PatientRate) > 0 && parseFloat(existingtemplatesdata[key].MRP) > 0 && parseFloat(existingtemplatesdata[key].PartnerRate) > 0) {
                    if (parseFloat(existingtemplatesdata[key].PatientRate) > parseFloat(existingtemplatesdata[key].MRP)) {
                        glblimportitemstore = []; SweetAlert.fire("Patient rate must be less than or equal to MRP"); return false;
                    }
                    if (parseFloat(existingtemplatesdata[key].PartnerRate) > parseFloat(existingtemplatesdata[key].PatientRate)) {
                        glblimportitemstore = []; SweetAlert.fire("Partner rate must be less than or equal to patient rate"); return false;
                    }
                }
                else { SweetAlert.fire("Amount cannot be zero"); return false; }
                glblimportitemstore.push(
                    {
                        UniqueId: "0",
                        Id: existingtemplatesdata[key].Id,
                        CategoryName: existingtemplatesdata[key].CategoryName,
                        TestName: existingtemplatesdata[key].TestName,
                        TestCode: existingtemplatesdata[key].TestCode,
                        MRP: existingtemplatesdata[key].MRP,
                        PartnerRate: existingtemplatesdata[key].PartnerRate,
                        PatientRate: existingtemplatesdata[key].PatientRate
                    }
                );
            }
        }
        else {
            for (var key in existingtemplatesdata) {
                if (refpartnertype.current.value === "3" || refpartnertype.current.value === "4" || refpartnertype.current.value === "6") {
                    if (parseFloat(existingtemplatesdata[key].PartnerRate) > 0 && parseFloat(existingtemplatesdata[key].MRP) > 0) {
                        if (parseFloat(existingtemplatesdata[key].PartnerRate) > parseFloat(existingtemplatesdata[key].MRP)) {
                            glblimportitemstore = []; SweetAlert.fire("partner rate must be greater than or equal to MRP"); return false;
                        }
                    }
                    else { SweetAlert.fire("Amount cannot be zero"); return false; }
                }
                else {
                    if (parseFloat(existingtemplatesdata[key].PatientRate) > 0 && parseFloat(existingtemplatesdata[key].MRP) > 0) {
                        if (parseFloat(existingtemplatesdata[key].PatientRate) > parseFloat(existingtemplatesdata[key].MRP)) {
                            glblimportitemstore = []; SweetAlert.fire("patient rate must be less than or equal to MRP"); return false;
                        }
                    }
                    else { SweetAlert.fire("Amount cannot be zero"); return false; }
                }
                glblimportitemstore.push(
                    {
                        UniqueId: "0",
                        Id: existingtemplatesdata[key].Id,
                        CategoryName: existingtemplatesdata[key].CategoryName,
                        TestName: existingtemplatesdata[key].TestName,
                        TestCode: existingtemplatesdata[key].TestCode,
                        MRP: existingtemplatesdata[key].MRP,
                        PartnerRate: existingtemplatesdata[key].PartnerRate === null ? 0 : existingtemplatesdata[key].PartnerRate,
                        PatientRate: existingtemplatesdata[key].PatientRate === null ? 0 : existingtemplatesdata[key].PatientRate
                    }
                );
            }
        }
        const str = existingtemplatesdatadays[0].Days;
        const arr = str.split(',');
        const requestOptions = {
            Id: values.id,
            Region: refregion.current.value,
            PartnerType: refpartnertypeimport.current.value,
            TemplateName: values.templatenameimport,
            DisplayName: values.displaynameimport,
            FromDate: values.fromdateimport,
            ToDate: values.todateimport,
            IsActive: isactive,
            TemplateItemsList: JSON.stringify(glblimportitemstore),
            CreatedBy: localStorage.LoggedInUser,
            SelectedDays: JSON.stringify(checked)
        };
        setIsSaving(true);
        try {
            let url
            if (refpartnertype.current.value === "7") { url = URL_Start + 'master/savepackageratetemplate' }
            else { url = URL_Start + 'master/saveratetemplate' }
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsSaving(false);
                    SweetAlert.fire({ title: 'Saved', text: "Records saved successfully", icon: 'success', confirmButtonText: 'Ok', reverseButtons: true })
                        .then((result) => {
                            if (result.value) {
                                setAddImportVisible(false);
                                setAddRatesVisible(false);
                                setImportRatesVisible(false);
                                setPartnerType("0");
                                setRegion("0");
                                GetPartnerType();
                                GetStatesList("1");
                                const tempid = parseInt(data.Data);
                                window.open(`${process.env.PUBLIC_URL}/master/displayrateplan?id=${tempid}`, "_blank");
                            }
                        })
                }
                else { setIsSaving(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsSaving(false); if (error.response) { AxiosError(error.response.status); } });
        } catch (error) { setIsSaving(false); APIError("-3"); }
    }
    const GetStatesList = async (Id) => {
        setIsLoading(true);
        try {
            let url = URL_Start + 'master/getstateslist/' + Id
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsLoading(false);
                    if (data.Data != null) { setRegion(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
                    else { APIError(data.Data); }
                }
                else { setIsLoading(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
        } catch (error) { setIsLoading(false); APIError("-3"); }
    }
    const GetPartnerType = async () => {
        setIsLoading(true);
        try {
            let url = URL_Start + 'master/getpartnertypelist/2/1'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsLoading(false);
                    if (data.Data != null) { setPartnerType(data.Data.map((d) => <option key={d.Id} value={d.Id}>{d.Name}</option>)) }
                    else { APIError(data.Data); }
                }
                else { setIsLoading(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
        } catch (error) { setIsLoading(false); APIError("-3"); }
    }
    const GetImportPartnerType = async () => {
        setIsLoading(true);
        try {
            let url = URL_Start + 'master/getpartnertypelist/2/1'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsLoading(false);
                    if (data.Data != null) {
                        setImportPartnerType(data.Data.filter((d) => d.Id == refpartnertype.current.value).map((d) => <option key={d.Id} value={d.Id}>{d.Name}</option>))
                    }
                    else { APIError(data.Data); }
                }
                else { setIsLoading(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
        } catch (error) { setIsLoading(false); APIError("-3"); }
    }
    const GetExistingTemplates = async (region, partnertype) => {
        setIsLoading(true);
        try {
            let url = URL_Start + 'master/getexistingtemplates/' + region + '/' + partnertype
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsLoading(false);
                    if (data.Data != null) { setTable(data.Data); }
                    else { setTable([]); }
                }
                else { setIsLoading(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
        } catch (error) { setIsLoading(false); APIError("-3"); }
    }
    const CallRegionTemplates = async (e) => {
        setRegionTemplate("0");
        setShowExistingCsv(false);
        editformik.values.templateimport = "0";
        if (e.target.value !== "0") { GetImportPartnerType(); } else { return false; }
        setIsLoading(true);
        try {
            let url = URL_Start + 'master/getregiontemplates/' + refregionimport.current.value + '/' + e.target.value
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsLoading(false);
                    if (data.Data != null) { setRegionTemplate(data.Data.map((d) => <option key={d.Id} value={d.Id}>{d.TemplateName}</option>)); }
                    else { setRegionTemplate("0"); }
                }
                else { setIsLoading(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
        } catch (error) { setIsLoading(false); APIError("-3"); }
    }
    const CallExistingTemplatesData = async (e) => {
        if (e.target.value === "0") { setShowExistingCsv(false); return false; } else { setShowExistingCsv(true); }
        setIsLoading(true);
        try {
            let url = URL_Start + 'master/getexistingtemplatesdata/' + e.target.value + '/' + refpartnertype.current.value
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsLoading(false);
                    if (data.Data != null) {
                        glblImportRecords = [];
                        glblImportDays = [];
                        if (refpartnertype.current.value === "7") {
                            glblImportDays.push(data.Data);
                            for (var key in data.Data) {
                                glblImportRecords.push({
                                    Id: data.Data[key].Id,
                                    CategoryName: data.Data[key].CategoryName,
                                    TestName: data.Data[key].TestName,
                                    TestCode: data.Data[key].TestCode,
                                    MRP: data.Data[key].MRP,
                                    PartnerRate: data.Data[key].PartnerRate,
                                    PatientRate: data.Data[key].PatientRate
                                })
                            }
                        }
                        else if (refpartnertype.current.value === "1" || refpartnertype.current.value === "2") {
                            glblImportDays.push(data.Data);
                            for (var key in data.Data) {
                                glblImportRecords.push({
                                    Id: data.Data[key].Id,
                                    CategoryName: data.Data[key].CategoryName,
                                    TestCode: data.Data[key].TestCode,
                                    TestName: data.Data[key].TestName,
                                    MRP: data.Data[key].ItemMrp,
                                    PatientRate: data.Data[key].ClientRate
                                })
                            }
                        }
                        else if (refpartnertype.current.value === "3" || refpartnertype.current.value === "4" || refpartnertype.current.value === "6") {
                            glblImportDays.push(data.Data);
                            for (var key in data.Data) {
                                glblImportRecords.push({
                                    Id: data.Data[key].Id,
                                    CategoryName: data.Data[key].CategoryName,
                                    TestCode: data.Data[key].TestCode,
                                    TestName: data.Data[key].TestName,
                                    MRP: data.Data[key].ItemMrp,
                                    PartnerRate: data.Data[key].ItemRate
                                })
                            }
                        }
                        else {
                            glblImportDays.push(data.Data);
                            for (var key in data.Data) {
                                glblImportRecords.push({
                                    Id: data.Data[key].Id,
                                    CategoryName: data.Data[key].CategoryName,
                                    TestCode: data.Data[key].TestCode,
                                    TestName: data.Data[key].TestName,
                                    MRP: data.Data[key].ItemMrp,
                                    PartnerRate: data.Data[key].ItemRate,
                                    PatientRate: data.Data[key].ClientRate
                                })
                            }
                        }
                        setExistingTemplatesData(glblImportRecords);
                        setExistingTemplatesDataDays(data.Data);
                    }
                    else { setExistingTemplatesData([]); setExistingTemplatesDataDays([]); APIError(data.Data); }
                }
                else { setIsLoading(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
            changeAllCheck(false); changeMonCheck(false); changeTueCheck(false); changeWedCheck(false); changeThursdyCheck(false); changeFriCheck(false); changeSatCheck(false); changeSunCheck(false);
            if (glblImportDays.length > 0) {
                var tmpDays = glblImportDays[0];
                for (var i in tmpDays) {
                    if (i > 0) { break; }
                    setChecked(tmpDays[i].Days.split(','));
                    var selectedDays = tmpDays[i].Days.split(',');
                    for (i = 0; i < selectedDays.length; i++) {
                        switch (selectedDays[i]) {
                            case 'All': changeAllCheck(true); console.log(selectedDays[i]); break;
                            case 'Monday': changeMonCheck(true); console.log(selectedDays[i]); break;
                            case 'Tuesday': changeTueCheck(true); console.log(selectedDays[i]); break;
                            case 'Wednesday': changeWedCheck(true); console.log(selectedDays[i]); break;
                            case 'Thursday': changeThursdyCheck(true); console.log(selectedDays[i]); break;
                            case 'Friday': changeFriCheck(true); console.log(selectedDays[i]); break;
                            case 'Saturday': changeSatCheck(true); console.log(selectedDays[i]); break;
                            case 'Sunday': changeSunCheck(true); console.log(selectedDays[i]); break;
                        }
                    }
                }
            }

        } catch (error) { setIsLoading(false); APIError("-3"); }
    }
    const handleSearch = async () => {
        if (glbRecords.filter((item) =>
            item.CategoryName.toLowerCase().indexOf(textSearch.current.value.toLowerCase()) !== -1 || item.TestName.toLowerCase().indexOf(textSearch.current.value.toLowerCase()) !== -1).length === 0) {
            SweetAlert.fire("No Record Found");
        }
        else {
            setVerifyRate(glbRecords.filter((item) => item.CategoryName.toLowerCase().indexOf(textSearch.current.value.toLowerCase()) !== -1 || item.TestName.toLowerCase().indexOf(textSearch.current.value.toLowerCase()) !== -1));
        }
    }
    const handlePackageSearch = async () => {
        if (glbRecords.filter((item) => item.CategoryName.toLowerCase().indexOf(textPackageSearch.current.value.toLowerCase()) !== -1 || item.TestName.toLowerCase().indexOf(textPackageSearch.current.value.toLowerCase()) !== -1).length === 0) {
            SweetAlert.fire("No Record Found");
        }
        else {
            setVerifyRate(glbRecords.filter((item) => item.CategoryName.toLowerCase().indexOf(textPackageSearch.current.value.toLowerCase()) !== -1 || item.TestName.toLowerCase().indexOf(textPackageSearch.current.value.toLowerCase()) !== -1));
        }
    }
    const handleCheckChange = (event) => {
        var updatedList = [...checked];
        if (event.target.checked) {
            if (event.target.value === "All") {
                changeAllCheck(true); changeMonCheck(true); changeTueCheck(true); changeWedCheck(true); changeThursdyCheck(true); changeFriCheck(true); changeSatCheck(true); changeSunCheck(true);
                setChecked(["All", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]);
                updatedList = ["All", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
            }
            else {
                updatedList = [...checked, event.target.value];
                switch (event.target.value) {
                    case 'Monday': changeMonCheck(true); break; case 'Tuesday': changeTueCheck(true); break; case 'Wednesday': changeWedCheck(true); break;
                    case 'Thursday': changeThursdyCheck(true); break; case 'Friday': changeFriCheck(true); break; case 'Saturday': changeSatCheck(true); break; case 'Sunday': changeSunCheck(true); break;
                }
            }
        } else {
            if (updatedList.length == 1) { SweetAlert.fire("Either All Days or One day must be selected."); return; }

            if (event.target.value !== "All") { updatedList.splice(checked.indexOf(event.target.value), 1); }
            switch (event.target.value) {
                case 'Monday': changeMonCheck(false); break; case 'Tuesday': changeTueCheck(false); break; case 'Wednesday': changeWedCheck(false); break;
                case 'Thursday': changeThursdyCheck(false); break; case 'Friday': changeFriCheck(false); break; case 'Saturday': changeSatCheck(false); break; case 'Sunday': changeSunCheck(false); break;
            }
            changeAllCheck(false);
            if (allCheck) { updatedList.splice(checked.indexOf("All"), 1); }
        }

        if (updatedList.length === 7 && event.target.value !== "All") {
            const isInArray = updatedList.includes("All");
            if (isInArray === false) {
                updatedList = ["All", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
                changeAllCheck(true); changeMonCheck(true); changeTueCheck(true); changeWedCheck(true); changeThursdyCheck(true); changeFriCheck(true); changeSatCheck(true); changeSunCheck(true);
            }
        }
        setChecked(updatedList);
    };
    const handleFromDateChange = (e, type) => {
        const date = new Date(e.target.value);
        date.setFullYear(date.getFullYear() + 5);
        var day = date.getDate();
        if (day.toString().length < 2) { day = "0" + day; }
        var month = date.getMonth() + 1;
        if (month.toString().length < 2) { month = "0" + month; }
        const year = date.getFullYear();
        console.log(year + "-" + month + "-" + day + ":" + type);
        if (type === "1") { formik.values.todate = year + "-" + month + "-" + day; }
        if (type === "2") { editformik.values.todateimport = year + "-" + month + "-" + day; }
    }

    const AxiosError = async (ErrorCode) => {
        if (ErrorCode === 400) { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode === 401) { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode === 403) { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode === 404) { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode === 500) { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode === 503) { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Data unavailable", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-2") { SweetAlert.fire({ title: "API Server Error", text: ErrorMsg, icon: "error" }); }
        else if (ErrorCode === "-3") { SweetAlert.fire({ title: "Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        else if (ErrorCode === "-4") { SweetAlert.fire({ title: "Wrong Credentials", text: "Please enter correct credentials", icon: "error" }) }
        else if (ErrorCode === "-5") { SweetAlert.fire({ title: "Oops", text: "Data could not be saved, Please try again later", icon: "error" }) }
        else if (ErrorCode === "-6") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
        else if (ErrorCode === "-7") { SweetAlert.fire({ title: "Duplicate", text: "Template already exists", icon: "error" }) }
        else if (ErrorCode === "-8") { SweetAlert.fire({ title: "Data unavailable", text: "No record found to import", icon: "error" }) }
        else if (ErrorCode === "-9") { SweetAlert.fire({ title: "LDAP authentication failed", text: ErrorMsg, icon: "error" }) }
        else if (ErrorCode === "-25") { SweetAlert.fire({ title: "Duplicate", text: "Two items with same test code is added.Please remove before proceed", icon: "error" })} 
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Rate Templates" parent="Master" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row className="justify-content-center">
                                    <Col lg={4} md={6}>
                                        <Form.Group>
                                            <Form.Label>State <span className='text-red'>*</span></Form.Label>
                                            <Form.Control as="select" ref={refregion} name="region" onChange={(e) => CallRegionAddImport(e)}>
                                                <option key="0" value="0">---Select---</option>
                                                {region}
                                            </Form.Control>
                                            {formik.touched.region && formik.errors.region ? (<div className='text-required'>{formik.errors.region}</div>) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} md={6}>
                                        <Form.Group>
                                            <Form.Label>Rate Type <span className='text-red'>*</span></Form.Label>
                                            <Form.Control as="select" ref={refpartnertype} name="partnertype" onChange={(e) => CallAddImport(e)}>
                                                <option key="0" value="0">---Select---</option>
                                                {partnertype}
                                            </Form.Control>
                                            {formik.touched.partnertype && formik.errors.partnertype ? (<div className='text-required'>{formik.errors.partnertype}</div>) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col lg={12} md={12}>
                                    {
                                        addimportvisible &&
                                        <DataTables
                                            keyField='Id'
                                            tableData={table}
                                            columns={columns}
                                            noDataIndication={NoRecordFounds}
                                        />
                                    }
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    addimportvisible &&
                    <Row>
                        <Col md={12} className='text-center'>
                            <Button variant='secondary' className="mr-2" onClick={addsavingfields}>Add New Rate Template</Button>
                            <Button variant='primary' className="mr-2" onClick={importsavingfields}>Import Existing Rate Template</Button>
                        </Col>
                    </Row>
                }
                {
                    addratesvisible && addimportvisible &&
                    <Row className='mt25'>
                        <Col md={12}>
                            <Card>
                                <Card.Body>
                                    <Form.Row className="justify-content-center">
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Template Name <span className='text-red'>*</span></Form.Label>
                                                <Form.Control type="text" name="templatename"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.templatename}
                                                />
                                                {formik.touched.templatename && formik.errors.templatename ? (<div className='text-required'>{formik.errors.templatename}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Display Name <span className='text-red'>*</span></Form.Label>
                                                <Form.Control type="text" name="displayname"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.displayname}
                                                />
                                                {formik.touched.displayname && formik.errors.displayname ? (<div className='text-required'>{formik.errors.displayname}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className="mb-3 align-items-center">
                                                <Form.Label>From Date <span className='text-red'>*</span></Form.Label>
                                                <Form.Control type="date" name="fromdate" onKeyPress={(e) => { handleKeypress(e) }}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.fromdate}
                                                    onChangeCapture={(e) => { handleFromDateChange(e, "1") }}
                                                />
                                                {formik.touched.fromdate && formik.errors.fromdate ? (<div className='text-required'>{formik.errors.fromdate}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className="mb-3 align-items-center">
                                                <Form.Label>To Date <span className='text-red'>*</span></Form.Label>
                                                <Form.Control type="date" name="todate" onKeyPress={(e) => { handleKeypress(e) }}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.todate}
                                                />
                                                {formik.touched.todate && formik.errors.todate ? (<div className='text-required'>{formik.errors.todate}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className="mb-3 align-items-center animate-chk m-checkbox-inline">
                                                <Form.Label>Is Active: <span className='text-red'>*</span></Form.Label>
                                                <div className='mt-2'>
                                                    <Form.Label for="rd1">
                                                        <input class="radio_animated" type="radio" id='rd1' name="isActive" value="1" onChange={CheckIsActive} defaultChecked />Yes
                                                    </Form.Label>
                                                    <Form.Label for="rd2">
                                                        <input class="radio_animated" type="radio" id='rd2' name="isActive" value="0" onChange={CheckIsActive} />No
                                                    </Form.Label>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Label>Active Days: <span className='text-red'>*</span></Form.Label>
                                            <Form.Group className='animate-chk m-checkbox-inline'>
                                                <Form.Label for="chkBx0">
                                                    <input className="checkbox_animated" type="checkbox" id='chkBx0' checked={allCheck} name="isActive" value="All" onChange={handleCheckChange} />All Days
                                                </Form.Label>
                                                <Form.Label for="chkBx1">
                                                    <input className="checkbox_animated" type="checkbox" id='chkBx1' checked={monCheck} name="isActive" value="Monday" onChange={handleCheckChange} />Monday
                                                </Form.Label>
                                                <Form.Label for="chkBx2">
                                                    <input className="checkbox_animated" type="checkbox" id='chkBx2' checked={tueCheck} name="isActive" value="Tuesday" onChange={handleCheckChange} />Tuesday
                                                </Form.Label>
                                                <Form.Label for="chkBx3">
                                                    <input className="checkbox_animated" type="checkbox" id='chkBx3' checked={wedCheck} name="isActive" value="Wednesday" onChange={handleCheckChange} />Wednesday
                                                </Form.Label>
                                                <Form.Label for="chkBx4">
                                                    <input className="checkbox_animated" type="checkbox" id='chkBx4' checked={thursdyCheck} name="isActive" value="Thursday" onChange={handleCheckChange} />Thursday
                                                </Form.Label>
                                                <Form.Label for="chkBx5">
                                                    <input className="checkbox_animated" type="checkbox" id='chkBx5' checked={friCheck} name="isActive" value="Friday" onChange={handleCheckChange} />Friday
                                                </Form.Label>
                                                <Form.Label for="chkBx6">
                                                    <input className="checkbox_animated" type="checkbox" id='chkBx6' checked={satCheck} name="isActive" value="Saturday" onChange={handleCheckChange} />Saturday
                                                </Form.Label>
                                                <Form.Label for="chkBx7">
                                                    <input className="checkbox_animated" type="checkbox" id='chkBx7' checked={sunCheck} name="isActive" value="Sunday" onChange={handleCheckChange} />Sunday
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Upload file (.XLS or .XLSX or .CSV) <span className='text-red'>*</span></Form.Label>
                                                <input type='file' accept=".xls,.xlsx,.csv" onChange={(e) => handleNewFileUpload(e, "1")} />
                                            </Form.Group>
                                            <CSVLink className='textUnderline' data={csvdata} filename={"Sample.csv"} target="_blank">Download Sample CSV</CSVLink>
                                        </Col>
                                        <Row>
                                            <Col md={12} className='text-center'>
                                                {isSaving ? <LoadingButton variantName='primary' cssName="mr-2" /> : <Button variant='primary' type='submit' id="verify" className="mr-2" onClick={VerifyRate}>Verify</Button>}
                                            </Col>
                                        </Row>
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                }
                {
                    hideAddImportButtons &&
                    <Row className='mt25'>
                        <Col md={12}>
                            <Card>
                                <Card.Body>
                                    <Form.Row className="justify-content-center">
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Template Name:<span>&nbsp;</span></Form.Label>
                                                <Form.Label><b>{templateName}</b></Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Date:<span>&nbsp;</span></Form.Label>
                                                <Form.Label><b>{templateDate}</b></Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Upload file (.XLS or .XLSX or .CSV) <span className='text-red'>*</span></Form.Label>
                                                <input type='file' accept=".xls,.xlsx,.csv" onChange={(e) => handleNewFileUpload(e, "1")} />
                                            </Form.Group>
                                            {/* <CSVLink className='textUnderline' data={csvdata} filename={"Sample.csv"} target="_blank">Download Sample CSV</CSVLink> */}
                                        </Col>
                                        <Col md={3}>
                                            <ToolTip title="Download" placement="top" onClick={() => DownloadTemplateItems(templateId, templateName)} linkId="Download" faName="fa fa-download mr-2"></ToolTip>
                                            <span>Download Data</span>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className="justify-content-center">
                                        <Col md={12} className='text-center'>
                                            {isSaving ? <LoadingButton variantName='primary' cssName="mr-2" /> : <Button variant='primary' type='submit' id="verify" className="mr-2" onClick={VerifyRate}>Verify</Button>}
                                        </Col>
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                }
                {
                    verifyrate.length !== 0 ?
                        <Row>
                            <Col>
                                <Card>
                                    <Card.Body>

                                        {
                                            refpartnertype.current.value === "7" ?
                                                <>
                                                    <Form.Row className="justify-content-center">
                                                        <Col lg={4} md={6}>
                                                            <Form.Group>
                                                                <InputGroup className="mb-3">
                                                                    <Form.Control onChange={handlePackageSearch} placeholder="Filter by Category name/Test name..." ref={textPackageSearch} />
                                                                    <InputGroup.Append>
                                                                        <Button variant="secondary" onClick={handlePackageSearch}><i className='fa fa-search'></i></Button>
                                                                    </InputGroup.Append>
                                                                </InputGroup>
                                                            </Form.Group>
                                                        </Col>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <div className='text-red'>Click on rates to edit</div>
                                                    </Form.Row>
                                                </>
                                                :
                                                <>
                                                    <Form.Row className="justify-content-center">
                                                        <Col lg={4} md={6}>
                                                            <Form.Group>
                                                                <InputGroup className="mb-3">
                                                                    <Form.Control onChange={handleSearch} placeholder="Filter by Category name/Test name..." ref={textSearch} />
                                                                    <InputGroup.Append>
                                                                        <Button variant="secondary" onClick={handleSearch}><i className='fa fa-search'></i></Button>
                                                                    </InputGroup.Append>
                                                                </InputGroup>
                                                            </Form.Group>
                                                        </Col>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <div className='text-red'>Click on mrp or rates to edit</div>
                                                    </Form.Row>
                                                </>
                                        }
                                        {
                                            refpartnertype.current.value === "1" || refpartnertype.current.value === "2" ?
                                                <DataTables
                                                    keyField='Id'
                                                    tableData={verifyrate}
                                                    columns={verifycolumnsMRP}
                                                    noDataIndication={NoRecordFounds}
                                                    pagination={paginationFactory(paginationOptions)}
                                                    rowStyle={rowStyle2}
                                                /> :
                                                refpartnertype.current.value === "3" || refpartnertype.current.value === "4" || refpartnertype.current.value === "6" ?
                                                    <DataTables
                                                        keyField='Id'
                                                        tableData={verifyrate}
                                                        columns={verifycolumnsB2B}
                                                        noDataIndication={NoRecordFounds}
                                                        pagination={paginationFactory(paginationOptions)}
                                                        rowStyle={rowStyle2}
                                                    /> :
                                                    refpartnertype.current.value === "7" ?
                                                        <DataTables
                                                            keyField='Id'
                                                            tableData={verifyrate}
                                                            columns={verifypackagecolumnsrate}
                                                            noDataIndication={NoRecordFounds}
                                                            pagination={paginationFactory(paginationOptions)}
                                                            rowStyle={rowStyle2}
                                                        /> :
                                                        <DataTables
                                                            keyField='Id'
                                                            tableData={verifyrate}
                                                            columns={verifycolumnsOther}
                                                            noDataIndication={NoRecordFounds}
                                                            pagination={paginationFactory(paginationOptions)}
                                                            rowStyle={rowStyle2}
                                                        />
                                        }
                                        <Row>
                                            <Col md={12} className='text-center'>
                                                {isSaving ? <LoadingButton variantName='primary' cssName="mr-2" /> : <Button variant='primary' type='submit' className="mr-2" onClick={formik.handleSubmit}>Save</Button>}
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        : null
                }
                {
                    importratesvisible && addimportvisible &&
                    <Row className='mt25'>
                        <Col md={12}>
                            <Card>
                                <Card.Body>
                                    <Form.Row className="justify-content-center">
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Region <span className='text-red'>*</span></Form.Label>
                                                <Form.Control as="select" ref={refregionimport} name="regionimport" onChange={editformik.handleChange}
                                                    onBlur={editformik.handleBlur} value={editformik.values.regionimport} onChangeCapture={(e) => CallRegionPartner(e)}>
                                                    <option value="0">---Select---</option>
                                                    {region}
                                                </Form.Control>
                                                {editformik.touched.regionimport && editformik.errors.regionimport ? (<div className='text-required'>{editformik.errors.regionimport}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Rate Type <span className='text-red'>*</span></Form.Label>
                                                <Form.Control as="select" ref={refpartnertypeimport} name="centretypeimport" onChange={editformik.handleChange}
                                                    onBlur={editformik.handleBlur} value={editformik.values.centretypeimport} onChangeCapture={(e) => CallRegionTemplates(e)}>
                                                    <option value="0">---Select---</option>
                                                    {importpartnertype}
                                                </Form.Control>
                                                {editformik.touched.centretypeimport && editformik.errors.centretypeimport ? (<div className='text-required'>{editformik.errors.centretypeimport}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Templates <span className='text-red'>*</span></Form.Label>
                                                <Form.Control as="select" ref={reftemplateimport} name="templateimport" onChange={editformik.handleChange}
                                                    onBlur={editformik.handleBlur} value={editformik.values.templateimport} onChangeCapture={(e) => CallExistingTemplatesData(e)}>
                                                    <option value="0">---Select---</option>
                                                    {regiontemplate}
                                                </Form.Control>
                                                {editformik.touched.templateimport && editformik.errors.templateimport ? (<div className='text-required'>{editformik.errors.templateimport}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Template Name <span className='text-red'>*</span></Form.Label>
                                                <Form.Control type="text" name="templatenameimport"
                                                    onChange={editformik.handleChange}
                                                    onBlur={editformik.handleBlur}
                                                    value={editformik.values.templatenameimport}
                                                />
                                                {editformik.touched.templatenameimport && editformik.errors.templatenameimport ? (<div className='text-required'>{editformik.errors.templatenameimport}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Display Name <span className='text-red'>*</span></Form.Label>
                                                <Form.Control type="text" name="displaynameimport"
                                                    onChange={editformik.handleChange}
                                                    onBlur={editformik.handleBlur}
                                                    value={editformik.values.displaynameimport}
                                                />
                                                {editformik.touched.displaynameimport && editformik.errors.displaynameimport ? (<div className='text-required'>{editformik.errors.displaynameimport}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className="mb-3 align-items-center">
                                                <Form.Label>From Date <span className='text-red'>*</span></Form.Label>
                                                <Form.Control type="date" name="fromdateimport" onKeyPress={(e) => { handleKeypress(e) }}
                                                    onChange={editformik.handleChange}
                                                    onBlur={editformik.handleBlur}
                                                    value={editformik.values.fromdateimport}
                                                    onChangeCapture={(e) => { handleFromDateChange(e, "2") }}
                                                />
                                                {editformik.touched.fromdateimport && editformik.errors.fromdateimport ? (<div className='text-required'>{editformik.errors.fromdateimport}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className="mb-3 align-items-center">
                                                <Form.Label>To Date <span className='text-red'>*</span></Form.Label>
                                                <Form.Control type="date" name="todateimport" onKeyPress={(e) => { handleKeypress(e) }}
                                                    onChange={editformik.handleChange}
                                                    onBlur={editformik.handleBlur}
                                                    value={editformik.values.todateimport}
                                                />
                                                {editformik.touched.todateimport && editformik.errors.todateimport ? (<div className='text-required'>{editformik.errors.todateimport}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group className="mb-3 align-items-center animate-chk m-checkbox-inline">
                                                <Form.Label>Is Active: <span className='text-red'>*</span></Form.Label>
                                                <div className='mt-2'>
                                                    <Form.Label for="rd3">
                                                        <input class="radio_animated" type="radio" id='rd3' name="isActiveimport" onChange={CheckIsActive} value="1" defaultChecked />Yes
                                                    </Form.Label>
                                                    <Form.Label for="rd4">
                                                        <input class="radio_animated" type="radio" id='rd4' name="isActiveimport" onChange={CheckIsActive} value="0" />No
                                                    </Form.Label>
                                                </div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Label>Active Days: <span className='text-red'>*</span></Form.Label>
                                            <Form.Group className='animate-chk m-checkbox-inline'>
                                                <Form.Label for="chkBx0">
                                                    <input className="checkbox_animated" type="checkbox" id='chkBx0' checked={allCheck} name="isActive" value="All" onChange={handleCheckChange} />All Days
                                                </Form.Label>
                                                <Form.Label for="chkBx1">
                                                    <input className="checkbox_animated" type="checkbox" id='chkBx1' checked={monCheck} name="isActive" value="Monday" onChange={handleCheckChange} />Monday
                                                </Form.Label>
                                                <Form.Label for="chkBx2">
                                                    <input className="checkbox_animated" type="checkbox" id='chkBx2' checked={tueCheck} name="isActive" value="Tuesday" onChange={handleCheckChange} />Tuesday
                                                </Form.Label>
                                                <Form.Label for="chkBx3">
                                                    <input className="checkbox_animated" type="checkbox" id='chkBx3' checked={wedCheck} name="isActive" value="Wednesday" onChange={handleCheckChange} />Wednesday
                                                </Form.Label>
                                                <Form.Label for="chkBx4">
                                                    <input className="checkbox_animated" type="checkbox" id='chkBx4' checked={thursdyCheck} name="isActive" value="Thursday" onChange={handleCheckChange} />Thursday
                                                </Form.Label>
                                                <Form.Label for="chkBx5">
                                                    <input className="checkbox_animated" type="checkbox" id='chkBx5' checked={friCheck} name="isActive" value="Friday" onChange={handleCheckChange} />Friday
                                                </Form.Label>
                                                <Form.Label for="chkBx6">
                                                    <input className="checkbox_animated" type="checkbox" id='chkBx6' checked={satCheck} name="isActive" value="Saturday" onChange={handleCheckChange} />Saturday
                                                </Form.Label>
                                                <Form.Label for="chkBx7">
                                                    <input className="checkbox_animated" type="checkbox" id='chkBx7' checked={sunCheck} name="isActive" value="Sunday" onChange={handleCheckChange} />Sunday
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group>
                                                {/* <Form.Label>Upload file (.XLS or .xlsx) <span className='text-red'>*</span></Form.Label>
                                                <input type='file' accept=".xls,.xlsx,.csv" onChange={(e) => handleNewFileUpload(e, "2")} /> */}
                                                {
                                                    showexistingcsv &&
                                                    <CSVLink className='textUnderline' data={existingtemplatesdata} filename={"Existing.csv"} target="_blank">Download Existing CSV</CSVLink>
                                                }

                                            </Form.Group>
                                        </Col>
                                        <Row>
                                            <Col md={12} className='text-center'>
                                                {isSaving ? <LoadingButton variantName='primary' cssName="mr-2" /> : <Button variant='primary' type='submit' className="mr-2" onClick={editformik.handleSubmit}>Save</Button>}
                                            </Col>
                                        </Row>
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                }

                {
                    downloadvisible &&
                    <CSVLink data={rowcsvdata} filename={rowfilename} target="_blank" id="csvdownload"></CSVLink>
                }
                {
                    isLoading ?
                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                        null
                }
            </Container>
        </React.Fragment >
    )
}

export default RatePlan;