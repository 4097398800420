import React, { useState, useEffect, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import SweetAlert from 'sweetalert2';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import Select from 'react-select';
import { MultiSelect } from 'react-multi-select-component';
import LoadingButton from '../commonComponent/LoadingButton';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip';
import { useHistory } from 'react-router';

var fglblArr = [];
var temparr = [];
var temparr1 = [];
const AddEditCentrePanelTemplateMapping = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [typeSelected, setTypeSelected] = useState([]);
    const [paneltypeSelected, setPanelTypeSelected] = useState([]);
    const [owncentres, setOwnCentres] = useState([]);
    const [panelcentres, setPanelCentres] = useState([]);
    const [paymenttypedata, setPaymentTypeData] = useState([]);
    const [showpanels, setShowPanels] = useState(false);
    const [selectedoptions, setSelectedOptions] = useState([]);
    const [temporarydata, setTemporaryData] = useState([]);
    const [panelratetemplate, setPanelRateTemplate] = useState([]);
    const [ratetemplatetypeSelected, setRateTemplateTypeSelected] = useState([]);
    const [paymenttypeSelected, setPaymentTypeSelected] = useState([]);

    useEffect(() => {
        GetAllOwnPanelCentres();
        return () => {
            localStorage.removeItem("EditTemplateData");
        }
    }, [])
    const GetAllOwnPanelCentres = async () => {
        var glblArr = [];
        var glblArr1 = [];
        var glblArr2 = [];
        try {
            let url = URL_Start + 'master/getAllOwnPanelCentres'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        const owndata = data.Data.filter((f) => f.CentreTypeId == "1")
                        const paneldata = data.Data.filter((f) => f.CentreTypeId == "3")
                        owndata.map((option) => { return glblArr.push({ 'value': option.CentreId, 'label': option.CentreName, 'locationid': option.LocationId, 'districtid': option.DistrictId, 'companyid': option.CompanyId }); });
                        setOwnCentres(glblArr);
                        paneldata.map((option) => { return glblArr1.push({ 'value': option.CentreId, 'label': option.CentreName, 'locationid': option.LocationId, 'districtid': option.DistrictId, 'companyid': option.CompanyId }); });
                        setPanelCentres(glblArr1);
                        fglblArr = glblArr1;
                        var paymenttypedata = [];
                        paymenttypedata.push({
                            Id: "1",
                            Name: "Credit"
                        }, {
                            Id: "2",
                            Name: "Cash"
                        });
                        paymenttypedata.map((option) => { return glblArr2.push({ 'value': option.Id, 'label': option.Name }); });
                        setPaymentTypeData(glblArr2);

                        ////For Edit Purpose Only////
                        if (JSON.parse(localStorage.getItem('EditTemplateData')) == null || JSON.parse(localStorage.getItem('EditTemplateData')) == undefined) { }
                        else {
                            var ExtractArray = JSON.parse(localStorage.getItem('EditTemplateData'));
                            var typearr = [];
                            typearr.push({
                                value: ExtractArray.CentreId,
                                label: ExtractArray.CentreName,
                                locationid: ExtractArray.LocationId
                            });
                            setTypeSelected(typearr);
                            setShowPanels(true);
                            const TypeOptL = [];
                            TypeOptL.push({
                                panelid: ExtractArray.PanelId,
                                panelname: ExtractArray.PanelName,
                                locationid: ExtractArray.LocationId
                            });
                            setSelectedOptions(TypeOptL);
                            const TypeOptLArr = [];
                            TypeOptLArr.push({
                                value: ExtractArray.PanelId,
                                label: ExtractArray.PanelName,
                                locationid: ExtractArray.LocationId
                            });
                            setPanelTypeSelected(TypeOptLArr);
                            var Arr = [];
                            Arr.push({
                                Id: "1",
                                CentreId: ExtractArray.CentreId,
                                CentreName: ExtractArray.CentreName,
                                PanelId: ExtractArray.PanelId,
                                PanelName: ExtractArray.PanelName,
                                LocationId: ExtractArray.LocationId
                            });
                            setTemporaryData(Arr);
                            GetPanelRateTemplates();
                            var tarr = [];
                            tarr.push({
                                value: ExtractArray.TemplateId,
                                label: ExtractArray.TemplateName,
                                locationid: ExtractArray.LocationId,
                                Id: "1"
                            });
                            setRateTemplateTypeSelected(tarr);
                            var tarr1 = [];
                            tarr1.push({
                                value: ExtractArray.PaymentTypeId,
                                label: ExtractArray.PaymentTypeName,
                                locationid: ExtractArray.LocationId,
                                Id: "1"
                            });
                            setPaymentTypeSelected(tarr1);
                        }
                        ////For Edit Purpose Only////
                    }
                    else { fglblArr = []; setOwnCentres([]); setPanelCentres([]); setPaymentTypeData([]); }
                }
                else { fglblArr = []; setOwnCentres([]); setPanelCentres([]); }
            }).catch(function (error) { if (error.response) { fglblArr = []; setOwnCentres([]); setPanelCentres([]); AxiosError(error.response.status); } });
        }
        catch (error) { fglblArr = []; setOwnCentres([]); setPanelCentres([]); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const TypeChangeHandler = (options) => {
        var typearr = [];
        typearr.push({
            value: options.value,
            label: options.label,
            locationid: options.locationid
        });
        setTypeSelected(typearr);
        // setPanelCentres(fglblArr.filter((d) => d.locationid == options.locationid));
        setPanelCentres(fglblArr.filter((d) => d.companyid == options.companyid));
        setShowPanels(true);
    }
    const PanelChangeHandler = (options) => {
        setIsLoading(false);
        setPanelTypeSelected(options);
        const TypeOptL = [];
        if (options != null && options.length > 0) {
            options.map((item) => {
                const TypeOptrows = {
                    panelid: item.value,
                    panelname: item.label,
                    locationid: item.locationid
                }
                return TypeOptL.push(TypeOptrows);
            });
            setSelectedOptions(TypeOptL);
            GetPanelRateTemplates();
        }
        else {
            setSelectedOptions([]);
        }
    }
    const SaveTemporaryData = async () => {
        setIsLoading(true);
        var Arr = [];
        for (var key in selectedoptions) {
            Arr.push({
                Id: parseInt(key + 1),
                CentreId: typeSelected[0].value,
                CentreName: typeSelected[0].label,
                PanelId: selectedoptions[key].panelid,
                PanelName: selectedoptions[key].panelname,
                LocationId: typeSelected[0].locationid
                // LocationId: selectedoptions[key].locationid
            });
        }
        setTemporaryData(Arr);
        setRateTemplateTypeSelected([]);
        temparr = [];
        setPaymentTypeSelected([]);
        temparr1 = [];
        setIsLoading(false);
    }
    const Displayalert = (Id) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        })
            .then((result) => { if (result.value) { UpdateStatus(Id); } else { SweetAlert.fire('Your data is safe!'); } })
    }
    const UpdateStatus = async (Id) => {
        setTemporaryData(temporarydata.filter((d) => d.Id != Id));
        setRateTemplateTypeSelected(ratetemplatetypeSelected.filter((e) => e.Id != Id));
        setPaymentTypeSelected(paymenttypeSelected.filter((f) => f.Id != Id));
    }
    const GetPanelRateTemplates = async () => {
        var glblArr = [];
        try {
            let url = URL_Start + 'master/getpanelratetemplates'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        data.Data.map((option) => { return glblArr.push({ 'value': option.TemplateId, 'label': option.TemplateName, 'locationid': option.LocationId }); });
                        setPanelRateTemplate(glblArr);
                    }
                    else { setPanelRateTemplate([]); }
                }
                else { setPanelRateTemplate([]); }
            }).catch(function (error) { if (error.response) { setPanelRateTemplate([]); AxiosError(error.response.status); } });
        }
        catch (error) { setPanelRateTemplate([]); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const PanelTypeChangeHandler = (e, Id) => {
        if (e == null || e == undefined || e == "") {
            setRateTemplateTypeSelected([]); temparr = [];
        }
        else {
            temparr = temparr.filter((l) => l.Id != Id)
            temparr.push({
                value: e.value,
                label: e.label,
                locationid: e.locationid,
                Id: Id
            });
            setRateTemplateTypeSelected(temparr);
        }
    }
    const CallPaymentType = async (e, Id) => {
        if (e == null || e == undefined || e == "") {
            setPaymentTypeSelected([]); temparr1 = [];
        }
        else {
            temparr1 = temparr1.filter((l) => l.Id != Id)
            temparr1.push({
                value: e.value,
                label: e.label,
                locationid: e.locationid,
                Id: Id
            });
            setPaymentTypeSelected(temparr1);
        }
    }
    const SavePermanentData = async () => {
        if (temporarydata.length != ratetemplatetypeSelected.length) {
            SweetAlert.fire('Rate template is mandatory in all panels');
            return false;
        }
        // if (temporarydata.length != paymenttypeSelected.length) {
        //     SweetAlert.fire('Payment type is mandatory in all panels');
        //     return false;
        // }
        var finalArr = [];
        for (var key in temporarydata) {
            for (var keys in ratetemplatetypeSelected) {
                //for (var keyss in paymenttypeSelected) {
                // if (temporarydata[key].Id == paymenttypeSelected[keyss].Id && temporarydata[key].Id == ratetemplatetypeSelected[keys].Id) {
                if (temporarydata[key].Id == ratetemplatetypeSelected[keys].Id) {
                    finalArr.push({
                        AutoScriptId: temporarydata[key].Id.toString(),
                        CentreId: temporarydata[key].CentreId,
                        PanelId: temporarydata[key].PanelId,
                        LocationId: temporarydata[key].LocationId,
                        TemplateId: ratetemplatetypeSelected[keys].value,
                        TemplateName: ratetemplatetypeSelected[keys].label,
                        // PaymentId: paymenttypeSelected[keyss].value,
                        // PaymentName: paymenttypeSelected[keyss].label
                        PaymentId: "1",
                        PaymentName: "Credit"
                    })
                }
                //}
            }
        }
        var EditId = "0";
        if (JSON.parse(localStorage.getItem('EditTemplateData')) == null || JSON.parse(localStorage.getItem('EditTemplateData')) == undefined) { EditId = "0"; }
        else {
            var ExtractArray = JSON.parse(localStorage.getItem('EditTemplateData'));
            EditId = ExtractArray.CentrePanelTemplateId;
        }
        const requestOptions = {
            LoginId: localStorage.LoggedInUser, CentrePanelData: JSON.stringify(finalArr), EditTemplateData: EditId
        };
        setIsButtonLoading(true);
        let url = URL_Start + 'master/SaveCentrePanelData'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsButtonLoading(false);
            if (data.Success) {
                SweetAlert.fire({
                    title: 'Success!',
                    text: EditId == "0" ? "Data saved successfully!" : "Data updated successfully!",
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    reverseButtons: true
                })
                    .then((result) => {
                        if (result.value) { history.push({ pathname: `${process.env.PUBLIC_URL}/master/centrepaneltemplatemapping`, search: `` }); window.location.reload(false); }
                        else { history.push({ pathname: `${process.env.PUBLIC_URL}/master/centrepaneltemplatemapping`, search: `` }); window.location.reload(false); }
                    })
            }
            else {
                setIsButtonLoading(false);
                if (data.Data == "-1999") { SweetAlert.fire({ title: "Error", text: "Centre panel template already mapped!", icon: "error" }); return false; }
                else if (data.Data == "-2000") { SweetAlert.fire({ title: "Error", text: "Check db logs!", icon: "error" }); return false; }
                else { SweetAlert.fire({ title: "Error", text: data.Data, icon: "error" }); return false; }
            }
        }).catch(function (error) { setIsButtonLoading(false); if (error.response) { AxiosError(error.response.status); } });
    }
    const PrevBckHandler = async () => {
        history.push({ pathname: `${process.env.PUBLIC_URL}/master/centrepaneltemplatemapping`, search: `` });
    }
    const AxiosError = async (ErrorCode) => {
        if (ErrorCode === 400) { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode === 401) { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode === 403) { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode === 404) { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode === 500) { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode === 503) { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Data unavailable", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-2") { SweetAlert.fire({ title: "API Server Error", text: ErrorMsg, icon: "error" }); }
        else if (ErrorCode === "-3") { SweetAlert.fire({ title: "Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        else if (ErrorCode === "-4") { SweetAlert.fire({ title: "Wrong Credentials", text: "Please enter correct credentials", icon: "error" }) }
        else if (ErrorCode === "-5") { SweetAlert.fire({ title: "Oops", text: "Data could not be saved, Please try again later", icon: "error" }) }
        else if (ErrorCode === "-6") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
        else if (ErrorCode === "-7") { SweetAlert.fire({ title: "Duplicate", text: "Template already exists", icon: "error" }) }
        else if (ErrorCode === "-8") { SweetAlert.fire({ title: "Data unavailable", text: "No record found to import", icon: "error" }) }
        else if (ErrorCode === "-9") { SweetAlert.fire({ title: "LDAP authentication failed", text: ErrorMsg, icon: "error" }) }
        else if (ErrorCode === "-10") { SweetAlert.fire({ title: "Validations", text: "Please insert atleast one valid rate", icon: "error" }) }
        else if (ErrorCode === "-25") { SweetAlert.fire({ title: "Duplicate", text: "Item already added to this template.Please refresh", icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Add Edit Centre Panel Templates Mapping" parent="Master" />
            <Container fluid>
                <Row className='mt25'>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row>
                                    <Col lg={6} md={6}>
                                        <Form.Group>
                                            <Form.Label>Own/CC Centres <span className='text-red'>*</span></Form.Label>
                                            <Select onChange={TypeChangeHandler}
                                                options={owncentres}
                                                value={typeSelected} />
                                        </Form.Group>
                                    </Col>
                                    {
                                        showpanels == true ?
                                            <Col lg={6} md={6}>
                                                <Form.Group>
                                                    <Form.Label>Panels <span className='text-red'>*</span></Form.Label>
                                                    <div className='txtMultiSelect1'>
                                                        <MultiSelect id="SalesSelect" name="SalesSelect"
                                                            options={panelcentres}
                                                            value={paneltypeSelected}
                                                            onChange={PanelChangeHandler}
                                                            labelledBy="Select"
                                                        /></div>
                                                </Form.Group>
                                            </Col> : null
                                    }
                                    <Col lg={12} md={12} className='text-center'>
                                        <Button variant='secondary' className="mr-2" onClick={PrevBckHandler} >Cancel</Button>
                                        {
                                            selectedoptions == [] || selectedoptions == null || selectedoptions == undefined || selectedoptions == "" ? null :
                                                <>
                                                    {
                                                        (JSON.parse(localStorage.getItem('EditTemplateData')) == null || JSON.parse(localStorage.getItem('EditTemplateData')) == undefined) ?
                                                            <>
                                                                {isLoading ? <LoadingButton variantName='primary' cssName="mr-2" /> :
                                                                    <Button variant='primary' type='submit' className="mr-2" onClick={(e) => SaveTemporaryData()}>Bind Grid</Button>
                                                                }
                                                            </> :
                                                            <>
                                                                <Button variant='primary' type='submit' className="mr-2" onClick={(e) => SaveTemporaryData()} disabled>Bind Grid</Button>
                                                            </>
                                                    }
                                                    {/* {isLoading ? <LoadingButton variantName='primary' cssName="mr-2" /> :
                                                        <Button variant='primary' type='submit' className="mr-2" onClick={(e) => SaveTemporaryData()}>Bind Grid</Button>
                                                    } */}
                                                </>
                                        }
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    temporarydata == [] || temporarydata == null || temporarydata == undefined || temporarydata == "" ? null :
                        <Row className='mt25'>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <Form.Row>
                                            <Col lg={12} md={12}>
                                                <Table>
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">Centre Name</th>
                                                            <th scope="col">Panel Name</th>
                                                            <th scope="col">Rate Templates</th>
                                                            {/* <th scope="col">Payment Type</th> */}
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            temporarydata.length > 0 ?
                                                                <>
                                                                    {
                                                                        temporarydata.map((d, index) =>
                                                                            <>
                                                                                <tr>
                                                                                    <td>{index + 1}</td>
                                                                                    <td>{d.CentreName}</td>
                                                                                    <td>{d.PanelName}</td>
                                                                                    <td>
                                                                                        <Select
                                                                                            onChange={(e) => PanelTypeChangeHandler(e, d.Id)}
                                                                                            options={panelratetemplate.filter((k) => k.locationid == d.LocationId)}
                                                                                            // options={panelratetemplate}
                                                                                            value={ratetemplatetypeSelected.filter((z) => z.Id == d.Id)}
                                                                                        />
                                                                                    </td>
                                                                                    {/* <td>
                                                                                        <Select
                                                                                            onChange={(e) => CallPaymentType(e, d.Id)}
                                                                                            options={paymenttypedata}
                                                                                            value={paymenttypeSelected.filter((z) => z.Id == d.Id)}
                                                                                        />
                                                                                    </td> */}
                                                                                    <td className="text-center">
                                                                                        <DeleteToolTip
                                                                                            title="Delete"
                                                                                            placement="top"
                                                                                            id={`Delete${d.Id}`}
                                                                                            onClick={() => Displayalert(d.Id)}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    }
                                                                </>
                                                                :
                                                                <tr><td className="text-center" colSpan={6} style={{ color: 'red' }}><b>No record found</b></td></tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                            <Col lg={12} md={12} className='text-center'>
                                                {isButtonLoading ? <LoadingButton variantName='primary' cssName="mr-2" /> : <Button variant='primary' type='submit' className="mr-2" onClick={(e) => SavePermanentData()}>Save</Button>}
                                            </Col>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                }
            </Container>
        </React.Fragment>
    )
}

export default AddEditCentrePanelTemplateMapping