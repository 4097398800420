import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import Breadcrumb from '../../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import SweetAlert from 'sweetalert2';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import Alert from "react-bootstrap/Alert";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PrintPDF from '../commonComponent/PrintPDF';
import EditToolTip from '../UI/ToolTips/EditToolTip';
import { CSVLink } from "react-csv";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { MultiSelect } from 'react-multi-select-component';
import '../../../pages/admin/react-multiselect.css';
import { Check } from 'react-feather';
import { array } from 'yup/lib/locale';
import Select from 'react-select';

const CustomerCollections = () => {
    let fromDate = useRef();
    let toDate = useRef();
    let reportsuser = useRef('1');
    let btnrpttype = useRef();
    let centreval = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [apiData, setAPIData] = useState();
    const [defaultDate, setDefaultDate] = useState("");
    const [userName, setUserName] = useState("");
    const [totalSale, setTotalSale] = useState("0");
    const [cashSale, setCashSale] = useState("0");
    const [paytmSale, setPaytmSale] = useState("0");
    const [bharatPaySale, setBharatPaySale] = useState("0");
    const [phonePeSale, setPhonePeSale] = useState("0");
    const [creditCardSale, setCreditCardSale] = useState("0");
    const [debitCardSale, setDebitCardSale] = useState("0");
    const [gPaySale, setGPaySale] = useState("0");
    const [onlineSale, setOnlineSale] = useState("0");
    const [RPay, setGPay] = useState("0");
    const [userTable, setuserTable] = useState([]);
    const [cashTable, setCashTable] = useState([]);
    const [paytmTable, setPaytmTable] = useState([]);
    const [bharatPayTable, setBharatPayTable] = useState([]);
    const [phonePeTable, setPhonePeTable] = useState([]);
    const [creditCardTable, setCreditCardTable] = useState([]);
    const [debitCardTable, setDebitCardTable] = useState([]);
    const [gPayTable, setGPayTable] = useState([]);
    const [onlineTable, setOnlineTable] = useState([]);
    const [rpayTable, setrpayTable] = useState([]);
    const [posTable, setPOSTable] = useState([]);
    const [isPrintReport, setIsPrintReport] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [visitingid, setVisitingId] = useState("");
    const openReportModal = () => { setIsPrintReport(!isPrintReport); }
    const openEditModal = () => { setIsEdit(!isEdit); }
    const [displayPrintReport, setDisplayPrintReport] = useState(false);
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [edittabledata, setEditTableData] = useState([]);
    const [users, setUsers] = useState([]);
    const [SelectCentreDDL, SetSelectCentreDDL] = useState([]);
    const [SelectCentres, SetSelectCentres] = useState([]);
    const [LGlCentreTypeoption, SetLGlCentreTypeoption] = useState([]);
    const [typeSelected, setTypeSelected] = useState([]);
    const [typeSelected1, setTypeSelected1] = useState([]);

    useEffect(() => {
        btnrpttype.current = '1';
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        GetBindCenterAllLists();
        GetUsersforDayendreports();
    }, [])
    const GetUsersforDayendreports = async () => {
        var glblArr = [];
        try {
            let url = URL_Start + 'reports/GetUsersforDayendreports/'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    // setUsers(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>)))
                    if (data.Data != null) {
                        var itmData = data.Data;
                        itmData.map((option) => { return glblArr.push({ 'value': option.Id, 'label': option.Value, 'disabled': false }); });
                        setUsers(glblArr);
                    }
                    else { setUsers([]); }
                }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const formik = useFormik({
        initialValues: { reportuser: '' },
        // validationSchema: Yup.object({
        //     reportuser: Yup.string().required('user is required'),
        // }),
        onSubmit: values => { BindGrid(fromDate.current.value, fromDate.current.value); }
    });
    const columns = [
        { dataField: 'ReceiptNo', text: '#', editable: false, headerStyle: { width: '8%', fontSize: 7 }, style: { width: '8%', fontSize: 10 } },
        { dataField: 'ClientName', text: 'Client Name', editable: false, headerStyle: { width: '16%', fontSize: 7 }, style: { width: '16%', fontSize: 10 } },
        { dataField: 'UserName', text: 'User Name', editable: false, headerStyle: { width: '16%', fontSize: 7 }, style: { width: '16%', fontSize: 10 } },
        { dataField: 'ReceiptDate', text: 'Receipt Date', editable: false, headerStyle: { width: '8%', fontSize: 7 }, style: { width: '8%', fontSize: 10 } },
        { dataField: 'BillNo', text: 'Bill No', editable: false, headerStyle: { width: '8%', fontSize: 7 }, style: { width: '8%', fontSize: 10 } },
        { dataField: 'BillDate', text: 'Bill Date', editable: false, headerStyle: { width: '8%', fontSize: 7 }, style: { width: '8%', fontSize: 10 } },
        // { dataField: 'ClientCode', text: 'Client Code', editable: false },
        { dataField: 'VisitingCode', text: 'Visiting Id', editable: false, headerStyle: { width: '12%', fontSize: 7 }, style: { width: '12%', fontSize: 10 } },
        { dataField: 'PatientName', text: 'Patient Name', editable: false, headerStyle: { width: '8%', fontSize: 7 }, style: { width: '8%', fontSize: 10 } },
        { dataField: 'PaidAmount', text: 'Paid Amount', editable: false, headerStyle: { width: '8%', fontSize: 7 }, style: { width: '8%', fontSize: 10 } },
        { dataField: 'ISRefund', text: 'IS Refund', editable: false, headerStyle: { width: '8%', fontSize: 7 }, style: { width: '8%', fontSize: 10 } }
    ];
    const Usercolumns = [
        { dataField: 'UserName', text: 'User Name', editable: false, headerStyle: { width: '16%', fontSize: 7 }, style: { width: '16%', fontSize: 10 } },
        { dataField: 'CentreName', text: 'Centre Name', editable: false, headerStyle: { width: '20%', fontSize: 7 }, style: { width: '20%', fontSize: 10 } },
        { dataField: 'Cash', text: 'Cash', editable: false, headerStyle: { width: '8%', fontSize: 7 }, style: { width: '8%', fontSize: 10 } },
        { dataField: 'Paytm', text: 'Paytm', editable: false, headerStyle: { width: '8%', fontSize: 7 }, style: { width: '8%', fontSize: 10 } },
        { dataField: 'BharatPay', text: 'BharatPay', editable: false, headerStyle: { width: '8%', fontSize: 7 }, style: { width: '8%', fontSize: 10 } },
        { dataField: 'PhonePe', text: 'Axis Soundbox', editable: false, headerStyle: { width: '8%', fontSize: 7 }, style: { width: '8%', fontSize: 10 } },
        { dataField: 'CreditCard', text: 'Credit Card', editable: false, headerStyle: { width: '8%', fontSize: 7 }, style: { width: '8%', fontSize: 10 } },
        { dataField: 'DebitCard', text: 'Debit Card', editable: false, headerStyle: { width: '8%', fontSize: 7 }, style: { width: '8%', fontSize: 10 } },
        { dataField: 'GPay', text: 'GPay', editable: false, headerStyle: { width: '8%', fontSize: 7 }, style: { width: '8%', fontSize: 10 } },
        { dataField: 'Online', text: 'Online', editable: false, headerStyle: { width: '8%', fontSize: 7 }, style: { width: '8%', fontSize: 10 } },
        { dataField: 'RPay', text: 'RPay', editable: false, headerStyle: { width: '8%', fontSize: 7 }, style: { width: '8%', fontSize: 10 } },
        { dataField: 'POS', text: 'POS', editable: false, headerStyle: { width: '8%', fontSize: 7 }, style: { width: '8%', fontSize: 10 } }

        // { dataField: 'UserName', text: 'User Name', editable: false  ,  headerStyle: { width: '5%', fontSize: 7 }, style:{width: '5%', fontSize: 10 } },
        // { dataField: 'CentreName', text: 'Centre Name', editable: false,  headerStyle: { width: '5%', fontSize: 7 }, style:{width: '5%', fontSize: 10 }  },
        // { dataField: 'CentreName', text: 'Cash', editable: false  ,  headerStyle: { width: '5%', fontSize: 7 }, style:{width: '5%', fontSize: 10 } },
        // { dataField: 'CentreName', text: 'Paytm', editable: false  ,  headerStyle: { width: '5%', fontSize: 7 }, style:{width: '5%', fontSize: 10 } },
        // { dataField: 'CentreName', text: 'BharatPay', editable: false  ,  headerStyle: { width: '5%', fontSize: 7 }, style:{width: '5%', fontSize: 10 } },
        // { dataField: 'CentreName', text: 'Axis Soundbox', editable: false  ,  headerStyle: { width: '35%', fontSize: 7 }, style:{width: '5%', fontSize: 10 } },
        // { dataField: 'CentreName', text: 'Credit Card', editable: false  ,  headerStyle: { width: '5%', fontSize: 7 }, style:{width: '5%', fontSize: 10 } },
        // { dataField: 'CentreName', text: 'Debit Card', editable: false  ,  headerStyle: { width: '5%', fontSize: 7 }, style:{width: '5%', fontSize: 10 } },
        // { dataField: 'CentreName', text: 'GPay', editable: false  ,  headerStyle: { width: '5%', fontSize: 7 }, style:{width: '5%', fontSize: 10 } },
        // { dataField: 'CentreName', text: 'Online', editable: false  ,  headerStyle: { width: '25%', fontSize: 7 }, style:{width: '55%', fontSize: 10 } }
        //{ dataField: 'UserId', text: '#', editable: false },
        // { dataField: 'UserName', text: 'User Name', editable: false, Style: (colum, colIndex) => { return { width: '8%', fontSize: 7  }; } },
        // { dataField: 'CentreName', text: 'Centre Name', editable: false, Style: (colum, colIndex) => { return { width: '2%', fontSize: 7  }; }},
        // { dataField: 'Cash', text: 'Cash', editable: false, Style: (colum, colIndex) => { return { width: '20%', fontSize: 7  }; } },
        // { dataField: 'Paytm', text: 'Paytm', editable: false, Style: (colum, colIndex) => { return { width: '10%', fontSize: 7  }; } },
        // { dataField: 'BharatPay', text: 'BharatPay', editable: false, Style: (colum, colIndex) => { return { width: '10%', fontSize: 7  }; } },
        // { dataField: 'PhonePe', text: 'Axis Soundbox', editable: false, Style: (colum, colIndex) => { return { width: '10%', fontSize: 7  }; } },
        // { dataField: 'CreditCard', text: 'Credit Card', editable: false, Style: (colum, colIndex) => { return { width: '10%', fontSize: 7  }; } },
        // { dataField: 'DebitCard', text: 'Debit Card', editable: false, Style: (colum, colIndex) => { return { width: '10%', fontSize: 7  }; } },
        // { dataField: 'GPay', text: 'GPay', editable: false, Style: (colum, colIndex) => { return { width: '10%', fontSize: 7  }; }  },
        // { dataField: 'Online', text: 'Online', editable: false, Style: (colum, colIndex) => { return { width: '10%', fontSize: 7  }; } }
    ];
    const handleSearch = async () => {
        if ((typeSelected == [] || typeSelected == null || typeSelected == undefined || typeSelected == "" || typeSelected.length == 0) && btnrpttype.current === '1') {
            SweetAlert.fire('Please select user');
            return false;
        }
        if ((typeSelected1 == [] || typeSelected1 == null || typeSelected1 == undefined || typeSelected1 == "" || typeSelected1.length == 0) && btnrpttype.current === '2') {
            SweetAlert.fire('Please select company');
            return false;
        }
        BindGrid(fromDate.current.value, fromDate.current.value);
        // if (reportsuser.current.value === '' && btnrpttype.current === '1') { SweetAlert.fire('Please select user'); }
        // else if (centreval.length === 0 && btnrpttype.current === '2') { SweetAlert.fire('Please select Company'); }
        // else { BindGrid(fromDate.current.value, fromDate.current.value); }
    }
    const BindGrid = async (fDte, tDte) => {
        const ObjServiceCollection = {
            AccessUserId: localStorage.getItem('LoggedInUser'),
            UserId: typeSelected.length == 0 ? "" : typeSelected,//reportsuser.current.value,
            FromDate: fDte,
            ToDate: tDte,
            //objtblCentreIds: SelectCentres,
            CentreId: typeSelected1.length == 0 ? "" : typeSelected1 //centreval.current.value

        };
        setIsLoading(true);
        setDisplayPrintReport(false);
        let url = URL_Start + 'reports/getcustomercollectionreport'
        await axios.post(url, ObjServiceCollection).then((response) => {
            const data = response.data;
            if (data.Success) {
                var apiData = data.Data;
                if (apiData.length > 0) {
                    var curntUserData = apiData[0];
                    setAPIData(curntUserData);
                    //setUserName(curntUserData.UserName);
                    setuserTable(curntUserData.Uinfo);
                    //setTotalSale(curntUserData.Total);
                    //setCashSale(curntUserData.Cash);
                    //setPaytmSale(curntUserData.Paytm);
                    //setBharatPaySale(curntUserData.BharatPay);
                    //setPhonePeSale(curntUserData.PhonePe);
                    //setCreditCardSale(curntUserData.CreditCard);
                    //setDebitCardSale(curntUserData.DebitCard);
                    //setGPaySale(curntUserData.GPay);
                    //setOnlineSale(curntUserData.Online);
                    setCashTable(curntUserData.CashCollectionList);
                    setPaytmTable(curntUserData.PaytmCollectionList);
                    setBharatPayTable(curntUserData.BharatPayCollectionList);
                    setPhonePeTable(curntUserData.PhonePeCollectionList);
                    setCreditCardTable(curntUserData.CreditCardCollectionList);
                    setDebitCardTable(curntUserData.DebitCardCollectionList);
                    setOnlineTable(curntUserData.OnlineCollectionList);
                    setrpayTable(curntUserData.RPayCollectionList);
                    setPOSTable(curntUserData.POSCollectionList);
                    setDisplayPrintReport(true);
                    // const elementdata = document.getElementById('tbLedger');
                    // if (elementdata) {
                    //     elementdata.classList.remove('table');
                    // }
                } else { setPOSTable([]);setuserTable([]); setCashTable([]); setPaytmTable([]); setBharatPayTable([]); setPhonePeTable([]); setCreditCardTable([]); setDebitCardTable([]); setGPayTable([]); }
                setIsLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const OpenReport = () => {
        setIsPrintReport(true);
    }
    useEffect(() => {

        if (downloadvisible === true) {
            document.getElementById("csvdownload").click();
        }
    }, [downloadvisible])

    const OpenCSV = () => {
        setDownloadVisible(true);
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    const GetBindCenterAllLists = async () => {
        const ObjFilterCentye = [];
        var glblArr1 = [];
        try {
            let url = URL_Start + 'master/getBindAllCentreLists'
            await axios.post(url, ObjFilterCentye).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        let ds = data.Data;
                        // const TypeOptionDs1 = [];
                        // ds.map((option) => {
                        //     const TypeOptions1 = {
                        //         'CentreId': option.CentreId,
                        //         'label': option.CentreName,
                        //         'value': option.CentreId,
                        //         'CenterTypeId': option.CenterTypeId,
                        //         'disabled': false
                        //     }
                        //     return TypeOptionDs1.push(TypeOptions1);
                        // });
                        // SetSelectCentreDDL(TypeOptionDs1);
                        //SetSelectCentreDDL(data.Data.map((d) => (<option key={d.CentreId} value={d.CentreId}>{d.CentreName}</option>)));
                        var itmData = data.Data;
                        itmData.map((option) => { return glblArr1.push({ 'value': option.CentreId, 'label': option.CentreName, 'disabled': false }); });
                        SetSelectCentreDDL(glblArr1);
                    }
                    else { SetSelectCentreDDL([]); }
                }
            }).catch(function (error) {
                //ErrorFunction(error.response.status);
                SetSelectCentreDDL([]);
            });
        }
        catch (error) {
            SetSelectCentreDDL([]);
            SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    // const CentreHandler = (options) => {

    //     SetSelectCentres(options);
    //     const TypeOptL = [];
    //     if (options != null && options.length > 0) {
    //         options.map((item) => {
    //             const TypeOptrows = {
    //                 'CentreId': item.CentreId
    //             }
    //             return TypeOptL.push(TypeOptrows);
    //         });
    //         SetLGlCentreTypeoption(TypeOptL);

    //     }
    //     else {
    //         SetLGlCentreTypeoption([]);
    //     }
    // }
    const handleRadioChange = async (e) => {
        const eleCentre = document.getElementById("SelectCentre");
        const eleuser = document.getElementById("selUser");
        // SetSelectCentres([]);
        setTypeSelected([]);
        setTypeSelected1([]);
        SetLGlCentreTypeoption([]);
        setuserTable([]);
        setCashTable([]);
        setPaytmTable([]);
        setBharatPayTable([]);
        setCreditCardTable([]);
        setDebitCardTable([]);
        setOnlineTable([]);
        setrpayTable([]);
        setPOSTable([]);
        setPhonePeTable([]);
        setGPayTable([]);
        setUserName('');
        setTotalSale('0');
        setCashSale('0');
        setPaytmSale('0');
        setBharatPaySale('0');
        setPhonePeSale('0');
        setCreditCardSale('0');
        setDebitCardSale('0');
        setGPaySale('0');
        setOnlineSale('0');
        setDisplayPrintReport('');
        formik.setFieldValue("reportuser", "0");
        formik.setFieldValue("CentreSelect", "0");
        eleCentre.style.display = e.target.value == "2" ? "block" : "none";
        eleuser.style.display = e.target.value == "1" ? "block" : "none";
        if (e.target.value == "1") {
            btnrpttype.current = "1";
        }
        else {
            btnrpttype.current = "2";
        }
    }
    const TypeChangeHandler = (options) => { formik.values.reportuser = options.value; setTypeSelected(options.value); }
    const TypeChangeHandler1 = (options) => { formik.values.CentreSelect = options.value; setTypeSelected1(options.value); }
    return (
        <>
            <Breadcrumb title="Customer Collections" parent="Reports" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form>
                                    <Form.Row className='justify-content-center'>
                                        <Col md={2}>
                                            {/* <div className="searchButtonSec">
                                                <Button variant='primary' onClick={handleSearch}>Search</Button>
                                            </div> */}
                                            <Form.Label className='mb-4'>Select User/Centre</Form.Label>
                                            <Form.Group className='animate-chk m-checkbox-inline center__type mt-15'>
                                                <Form.Label for="centreType1">
                                                    <input class="radio_animated" type="radio" id='centreType1' name="rdoo-ani" defaultChecked onChange={handleRadioChange} value='1' />Users
                                                </Form.Label>
                                                <Form.Label for="centreType2">
                                                    <input class="radio_animated" type="radio" id='centreType2' name="rdoo-ani" onChange={handleRadioChange} value='2' />Centre
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Select Date</Form.Label>
                                                <Form.Control type="date" id="date1" name="date1" ref={fromDate} defaultValue={defaultDate} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}></Col>

                                    </Form.Row>
                                </Form>
                                <Form.Row className='justify-content-center'>
                                    <Col md={6} >
                                        <Form.Group>
                                            <div className='txtMultiSelect1' id="SelectCentre" style={{ display: 'none' }}>
                                                <Form.Label>Select Centre</Form.Label>

                                                {/* <MultiSelect id="CentreSelect" name="CentreSelect"
                                                    options={SelectCentreDDL}
                                                    value={SelectCentres}
                                                    onChange={CentreHandler}
                                                    labelledBy="Select"
                                                /> */}
                                                {/* <Form.Control as="select" name="CentreSelect" ref={centreval} value={formik.values.CentreSelect} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option key="" value="">---Select---</option>
                                                    {SelectCentreDDL}
                                                </Form.Control> */}

                                                <Select onChange={TypeChangeHandler1}
                                                    options={SelectCentreDDL}
                                                    value={SelectCentreDDL.filter(function (x) { return x.value === typeSelected1; })} />
                                            </div>
                                            <div id='selUser'>
                                                <Form.Label>Select User<span className='text-red'>*</span></Form.Label>
                                                {/* <Form.Control as="select" name="reportuser" ref={reportsuser} value={formik.values.reportuser} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option key="" value="">---Select---</option>
                                                    {users}
                                                </Form.Control>
                                                {formik.touched.reportuser && formik.errors.reportuser ? (<div className='text-required'>{formik.errors.reportuser}</div>) : null} */}
                                                <Select onChange={TypeChangeHandler}
                                                    options={users}
                                                    value={users.filter(function (x) { return x.value === typeSelected; })} />
                                            </div>
                                        </Form.Group>
                                    </Col>

                                    <Col md={2} >
                                        <div className="searchButtonSec">
                                            <Button variant='primary' onClick={handleSearch}>Search</Button>
                                        </div>

                                    </Col>

                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form id='CheckHide'>
                                    {/* <Form.Row>
                                        <Col md={12} className="summaryHeadingAlertSec">
                                            <h6 className='text-red'>Summary</h6>
                                            <Alert variant="success" className="text-center">
                                                <ul className="list-inline pointsInline">
                                                    <li className="list-inline-item">Name: <strong>{userName}</strong></li>
                                                    <li className="list-inline-item">Total Sale: <strong>{totalSale}</strong></li>
                                                    <li className="list-inline-item">Cash: <strong>{cashSale}</strong></li>
                                                    <li className="list-inline-item">Paytm: <strong>{paytmSale}</strong></li>
                                                    <li className="list-inline-item">Bharat Pay: <strong>{bharatPaySale}</strong></li>
                                                    <li className="list-inline-item">Axis Soundbox: <strong>{phonePeSale}</strong></li>
                                                    <li className="list-inline-item">Credit Card: <strong>{creditCardSale}</strong></li>
                                                    <li className="list-inline-item">Debit Card: <strong>{debitCardSale}</strong></li>
                                                    <li className="list-inline-item">GPay: <strong>{gPaySale}</strong></li>
                                                    <li className="list-inline-item">Online: <strong>{onlineSale}</strong></li>
                                                </ul>
                                            </Alert>
                                        </Col>
                                    </Form.Row> */}
                                    {userTable.length > 0 ?
                                        <Form.Row >
                                            <Col md={12} className="summaryHeadingAlertSec">
                                                <h6 className='text-red'>Summary</h6>
                                                {
                                                    isLoading ?
                                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <div class="alert alert-info p-4" id="UTable">
                                                                <DataTables keyField='id' tableData={userTable} columns={Usercolumns} style={{ width: '100%', class: 'pk' }} />
                                                            </div>
                                                        </>
                                                }
                                            </Col>
                                        </Form.Row>
                                        : null}
                                    {cashTable.length > 0 ?
                                        <Form.Row className='mt15 summaryTableClass'>
                                            <Col md={12}>
                                                <h6 className='text-red'>Cash Details</h6>
                                                {
                                                    isLoading ?
                                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <div id="cashTable">
                                                                <DataTables keyField='id' tableData={cashTable} columns={columns} />
                                                            </div>
                                                        </>
                                                }
                                            </Col>
                                        </Form.Row>
                                        : null}
                                    {paytmTable.length > 0 ?
                                        <Form.Row className='mt15 summaryTableClass'>
                                            <Col md={12}>
                                                <h6 className='text-red'>Paytm Details</h6>
                                                {
                                                    isLoading ?
                                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <div id="paytmTable">
                                                                <DataTables keyField='id' tableData={paytmTable} columns={columns} />
                                                            </div>
                                                        </>
                                                }
                                            </Col>
                                        </Form.Row>
                                        : null}
                                    {bharatPayTable.length > 0 ?
                                        <Form.Row className='mt15 summaryTableClass'>
                                            <Col md={12}>
                                                <h6 className='text-red'>Bharat Pay Details</h6>
                                                {
                                                    isLoading ?
                                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <div id="bharatPayTable">
                                                                <DataTables keyField='id' tableData={bharatPayTable} columns={columns} />
                                                            </div>
                                                        </>
                                                }
                                            </Col>
                                        </Form.Row>
                                        : null}
                                    {phonePeTable.length > 0 ?
                                        <Form.Row className='mt15 summaryTableClass'>
                                            <Col md={12}>
                                                <h6 className='text-red'>Axis Soundbox Details</h6>
                                                {
                                                    isLoading ?
                                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <div id="phonePeTable">
                                                                <DataTables keyField='id' tableData={phonePeTable} columns={columns} />
                                                            </div>
                                                        </>
                                                }
                                            </Col>
                                        </Form.Row>
                                        : null}
                                    {creditCardTable.length > 0 ?
                                        <Form.Row className='mt15 summaryTableClass'>
                                            <Col md={12}>
                                                <h6 className='text-red'>Credit Card Details</h6>
                                                {
                                                    isLoading ?
                                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <div id="creditCardTable">
                                                                <DataTables keyField='id' tableData={creditCardTable} columns={columns} />
                                                            </div>
                                                        </>
                                                }
                                            </Col>
                                        </Form.Row>
                                        : null}
                                    {debitCardTable.length > 0 ?
                                        <Form.Row className='mt15 summaryTableClass'>
                                            <Col md={12}>
                                                <h6 className='text-red'>Debit Card Details</h6>
                                                {
                                                    isLoading ?
                                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <div id="debitCardTable">
                                                                <DataTables keyField='id' tableData={debitCardTable} columns={columns} />
                                                            </div>
                                                        </>
                                                }
                                            </Col>
                                        </Form.Row>
                                        : null}
                                    {gPayTable.length > 0 ?
                                        <Form.Row className='mt15 summaryTableClass'>
                                            <Col md={12}>
                                                <h6 className='text-red'>GPay Details</h6>
                                                {
                                                    isLoading ?
                                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <div id="gPayTable">
                                                                <DataTables keyField='id' tableData={gPayTable} columns={columns} />
                                                            </div>
                                                        </>
                                                }
                                            </Col>
                                        </Form.Row>
                                        : null}
                                    {onlineTable.length > 0 ?
                                        <Form.Row className='mt15 summaryTableClass'>
                                            <Col md={12}>
                                                <h6 className='text-red'>Online Details</h6>
                                                {
                                                    isLoading ?
                                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <div id="onlineTable">
                                                                <DataTables keyField='id' tableData={onlineTable} columns={columns} />
                                                            </div>
                                                        </>
                                                }
                                            </Col>
                                        </Form.Row>
                                        : null}
                                    {rpayTable.length > 0 ?
                                        <Form.Row className='mt15 summaryTableClass'>
                                            <Col md={12}>
                                                <h6 className='text-red'>Razor Pay Details</h6>
                                                {
                                                    isLoading ?
                                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <div id="cashTable">
                                                                <DataTables keyField='id' tableData={rpayTable} columns={columns} />
                                                            </div>
                                                        </>
                                                }
                                            </Col>
                                        </Form.Row>
                                        : null}
                                        {posTable.length > 0 ?
                                        <Form.Row className='mt15 summaryTableClass'>
                                            <Col md={12}>
                                                <h6 className='text-red'>POS Details</h6>
                                                {
                                                    isLoading ?
                                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <div id="cashTable">
                                                                <DataTables keyField='id' tableData={posTable} columns={columns} />
                                                            </div>
                                                        </>
                                                }
                                            </Col>
                                        </Form.Row>
                                        : null}
                                    <Form.Row className='mt15'>
                                        <Col md={12} className='text-center'>
                                            {displayPrintReport ?
                                                <>
                                                    <Button variant='primary' className="mr-2" onClick={(e) => OpenReport()}>Print Preview</Button>
                                                </>
                                                : null}
                                        </Col>
                                    </Form.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Modal isOpen={isPrintReport} toggle={openReportModal} centered={true} className="printReportContainerModel">
                <ModalHeader toggle={openReportModal}>Print Report</ModalHeader>
                <ModalBody>
                    <Form.Row>
                        <Col md={12} className="PrintDayEndReport">
                            <PrintPDF ReportName="CustomerCollection" APIData={apiData} />
                        </Col>
                    </Form.Row>
                </ModalBody>
            </Modal>
        </>
    )
}

export default CustomerCollections;