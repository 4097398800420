import React from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from 'reactstrap';


const ToolTip = (props) => {
    return (
        <>
            <Link to="#" onClick={props.onClick} className="text-dark"><i class={props.icon} id='ToolTipId' aria-hidden="true" icon={props.icon}></i></Link>
            <UncontrolledTooltip placement={props.placement} target="ToolTipId">
                {props.title}
            </UncontrolledTooltip>
        </>
    )
}
export default ToolTip;