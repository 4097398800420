import React from 'react'
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from 'reactstrap';

const AddToolTip = (props) => {
  return (
    <>
      <Link to="#" onClick={props.onClick} className="text-dark"><i class="fa fa-plus mr-2" id={`Add${props.id}`} aria-hidden="true"></i></Link>
      <UncontrolledTooltip placement={props.placement} target={`Add${props.id}`}>
        {props.title}
      </UncontrolledTooltip>
    </>
  )
}

export default AddToolTip