import React, { useEffect, useState, useRef } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Breadcrumb from '../../../components/common/breadcrumb';
import DynamicDataTables from '../commonComponent/DataTable/DynamicDataTable';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import Select from 'react-select';
import { Spinner } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { useHistory } from 'react-router';
import EditToolTip from '../UI/ToolTips/EditToolTip';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip';
import SelfActiveToolTip from '../UI/ToolTips/SelfActiveToolTip';
import ToolTip from '../UI/ToolTips/ToolTip';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment';
let globalPaymentModeText = "";
let GlobalOptionAdvc = "0";
let GlobalCentreId = "";
let globalPaymentMode = "0";
let GlbalstartDate = "";
let globalAdvancePaymentId = "";
let EditglobalPaymentMode = "0";
let EditglobalPaymentModeText = "";
let globalPartnerNameId = "0";
const ValidatePayment = () => {
    const today = new Date();
    const date = today.setDate(today.getDate());
    const defaultValue = new Date(date).toISOString().split('T')[0];
    const [modal, setModal] = useState();
    const [errorModal, setErrorModal] = useState();
    const [ErrorModalEdit, SetErrorModalEdit] = useState(false);
    let fromDate = useRef("");
    let TODate = useRef("");
    let EditpaymentDate = useRef("");
    let EditfromDate = useRef("");
    const [CentreListDDL, SetCentreListDDL] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [PaymentModeDLL, SetPaymentModeDLL] = useState([]);
    const [EditPaymentModeDLL, SetEditPaymentModeDLL] = useState([]);
    const [EditPaymentModeVal, SetEditPaymentModeVal] = useState("");
    const [PaymentStatusDLL, SetPaymentStatusDLL] = useState([]);
    const [PaymentModeVal, SetPaymentModeVal] = useState("");
    const [PartnerNameVal, SetPartnerNameVal] = useState("");
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const [IsButtonSubmitNew, SetIsButtonSubmitNew] = useState(false);
    const [IsButtonSubmitEdit, SetIsButtonSubmitEdit] = useState(false);
    const [ValidDateF, SetValidDateF] = useState("");
    const [IsShowDateCand, SetIsShowDateCand] = useState(false);
    const [ShowValidDateF, SetShowValidDateF] = useState("");
    const [SelectedCenter, SetSelectedCenter] = useState("");
    const [RejectReasonDetails, SetRejectReasonDetails] = useState("");
    const [IsRejectReason, SetIsRejectReason] = useState(false);
    const [BankVal, SetBankVal] = useState("");
    const [CustrizeNo, SetCustrizeNo] = useState("");
    const [IsEditDetail, SetIsEditDetail] = useState(false);
    const [BankDLL, SetBankDLL] = useState([]);
    const [EditBindAdvancePayDetails, SetEditBindAdvancePayDetails] = useState(null);
    const [typeSelected, setTypeSelected] = useState([]);

    const history = useHistory();
    const [PageHelper, SetPageHelper] = useState({
        PageNo: '1',
        PageSize: '10',
        TotalRecord: 0,
        TotalApprovedAmt: 0,
        TotalPendingAmt: 0,
        dsAdvancePaymentDlists: []

    });

    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }


    const ClientFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <p><strong>Partner Code:</strong> {rowData.CentreCode}</p>
                <p><strong>Partner Name:</strong> {rowData.CentreName}</p>
            </div>
        </React.Fragment>
    }
    const PaymentFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <p><strong>Payment Mode:</strong> {rowData.Payment}</p>
                <p><strong>Amount:</strong> {rowData.DepositAmount}</p>
                <p><strong>Deposit By:</strong> {rowData.UserName}</p>
                <p><strong>Deposit Date:</strong> {rowData.CustDepositDate}</p>
                <p><strong>Entry Date:</strong> {rowData.CustEntryDate}</p>
                <p><strong>Payment Status:</strong> {rowData.PaymentStatus}</p>
            </div>
        </React.Fragment>
    }
    const AdvPaymentFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <p><strong>Bank Name:</strong> {rowData.Bank == '' ? 'N/A' : rowData.Bank}</p>
                <p><strong>Payment Mode No:</strong> {rowData.PaymentModeNo == '' ? 'N/A' : rowData.PaymentModeNo}</p>
                <p><strong>Payment Mode Date:</strong> {rowData.CustPaymentDate == '' ? 'N/A' : rowData.CustPaymentDate}</p>
            </div>
        </React.Fragment>
    }
    const ViewFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            {rowData.PaymentStatusId == "1" ? <div >
                <EditToolTip title="Edit" onClick={() => editHandler(rowData.AdvancePaymentId)} />
                <SelfActiveToolTip title="Approve" onClick={() => StatusHandler(rowData.AdvancePaymentId)} />
                <ToolTip icon="fa fa-ban" title="Reject" onClick={() => Displayalert(rowData.AdvancePaymentId)} />
            </div> : null}

        </React.Fragment>
    }
    const columns = [
        {
            dataField: '#',
            text: '#',
            formatter: (cell, row, rowIndex) => {
                let rowNumber = (PageHelper.PageNo - 1) * PageHelper.PageSize + (rowIndex + 1);
                return <span>{rowNumber}</span>;
            },
            headerStyle: (colum, colIndex) => {
                return { width: '5%' };
            }

        },
        {
            text: 'Partner Detail', editable: false, formatter: ClientFormatter,

        },
        {
            text: 'Payment Detail', editable: false, formatter: PaymentFormatter

        },
        {
            text: 'Payment Advc. Detail', editable: false, formatter: AdvPaymentFormatter

        },

        { dataField: 'Remarks', text: 'Remarks', editable: false },
        ,

        {
            dataField: 'actions',
            isDummyField: true,
            text: 'Actions',
            formatter: ViewFormatter,
            headerStyle: (colum, colIndex) => {
                return { width: '5%' };
            }

        }

    ];
    const paginationOptions = {

        totalSize: PageHelper.TotalRecord,
        sizePerPage: PageHelper.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',

        onSizePerPageChange: (sizePerPage, page, event) => {
            console.log('Size per page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            BindGrid(1, 10, 1, globalPaymentMode, globalPartnerNameId);//GlobalOptionAdvc
            document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
        },
        onPageChange: (page, sizePerPage) => {
            console.log('Page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            BindGrid(1, 10, 1, globalPaymentMode, globalPartnerNameId);//GlobalOptionAdvc
        }
    };
    useEffect(() => {
        GetPaymentmode();
        GetPaymentStatusLists();
        GetCentresList();
        GetBankLists();
        BindGrid(1, 10, 1, 0, 0)
    }, []);


    const BindGrid = async (PageNo, PageSize, PaymentStatusId, PaymentId, CentreIds) => {
        if (fromDate.current.value !== "" && TODate.current.value === "") { SweetAlert.fire("Please enter to date"); return false; }
        if (TODate.current.value !== "" && fromDate.current.value === "") { SweetAlert.fire("Please enter from date"); return false; }
        setIsLoading(true);
        try {
            let url = URL_Start + 'master/GETAdvancePaymentDetailAdmin'
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {
                    PageNo: `${PageNo}`, PageSize: `${PageSize}`, PaymentStatusId: `${PaymentStatusId}`, CentreId: `${CentreIds}`//`${GlobalCentreId == "" ? "0" : GlobalCentreId}`
                    , PaymentId: `${PaymentId}`, FromDate: `${fromDate.current.value}`, ToDate: `${TODate.current.value}`
                }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;

                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {

                        await SetPageHelper({
                            ...PageHelper,
                            PageNo: Number(PageNo),
                            PageSize: Number(PageSize),
                            TotalRecord: Number(ds.TotalRecord),
                            TotalApprovedAmt: Number(ds.TotalApprovedAmt),
                            TotalPendingAmt: Number(ds.TotalPendingAmt),
                            dsAdvancePaymentDlists: ds.ObjBindAdvancePaymentList == null ? [] : ds.ObjBindAdvancePaymentList
                        });
                        setIsLoading(false);
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');
                    setIsLoading(false);
                }

            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
            // return data;
        } catch (error) {
            if (error.response) {
                ErrorFunction(error.response.status);
                setIsLoading(false);
            }

        }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    const GetCentresList = async () => {
        var glblArr1 = [];
        try {

            let url = URL_Start + 'master/GETBindCentreListDDL'
            let options = {
                method: 'GET',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {}
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        setErrorModal(false);
                        // const TypeOptionDs1 = [];

                        // ds.map((option) => {
                        //     const TypeOptions1 = {
                        //         'CentreId': option.CentreId,
                        //         'label': option.CustCentreName,
                        //         'value': option.CentreId,
                        //         'disabled': false
                        //     }
                        //     return TypeOptionDs1.push(TypeOptions1);
                        // });
                        //SetCentreListDDL(TypeOptionDs1);
                        //SetCentreListDDL(data.Data.map((d) => (<option key={d.CentreId} value={d.CentreId}>{d.CustCentreName}</option>)));
                        var itmData = data.Data;
                        glblArr1.push({ 'value': "0", 'label': "Select All", 'disabled': false });
                        itmData.map((option) => { return glblArr1.push({ 'value': option.CentreId, 'label': option.CustCentreName, 'disabled': false }); });
                        SetCentreListDDL(glblArr1);
                    }
                    else {
                        SetCentreListDDL([]);
                    }
                }
                else {
                    SetCentreListDDL([]);
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');

                }

            }
            else {
                SetCentreListDDL([]);
                setErrorModal(false);
                SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false);
            }
            // return data;
        } catch (error) {
            SetCentreListDDL([]);
            if (error.response) {
                setErrorModal(false);
                ErrorFunction(error.response.status);

            }

        }
    }
    const GetBankLists = async () => {
        try {
            let url = URL_Start + 'master/BankLists'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { SetBankDLL(data.Data.map((d) => (<option key={d.SNo} value={d.SNo}>{d.Bank}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetEditAdvancepaymentDetailsbyId = async (AdvancePayId) => {
        try {

            let url = URL_Start + 'master/GETAdvancePaymentDetailByPayId'
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { AdvancePaymentId: `${AdvancePayId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        if (ds.PaymentId != "") {
                            SetEditPaymentModeVal(ds.PaymentId);
                        }
                        else {
                            SetEditPaymentModeVal(0);
                        }
                        formik.values.advanceamt = ds.DepositAmount;
                        formik.values.txtremarks = ds.Remarks;
                        EditglobalPaymentModeText = ds.Payment;
                        formik.values.advancePayId = AdvancePayId;
                        SetBankVal(ds.BankId);
                        SetCustrizeNo(ds.PaymentModeNo);
                        SetEditBindAdvancePayDetails(ds);

                    }
                    else {
                        SetEditBindAdvancePayDetails(null);
                    }
                }
                else {
                    SetEditBindAdvancePayDetails(null);
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');

                }

            }
            else {
                SetEditBindAdvancePayDetails(null);
                SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false);
            }
            // return data;
        } catch (error) {
            SetEditBindAdvancePayDetails(null);
            if (error.response) {
                ErrorFunction(error.response.status);

            }
        }
    }
    const editHandler = async (AdvancePaymentId) => {
        SetIsEditDetail(true);
        SetErrorModalEdit(false);
        setModal(true);
        SetIsRejectReason(false);
        setErrorModal(false);
        globalAdvancePaymentId = AdvancePaymentId;
        GetEditAdvancepaymentDetailsbyId(AdvancePaymentId);

    }
    const Displayalert = (AdvancePaymentId) => {
        SweetAlert.fire({
            //title: 'You are going to approve.Are you sure?',
            text: "You are going to reject.Are you sure?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                SetIsRejectReason(true);
                setErrorModal(false);
                setModal(true);
                SetIsEditDetail(false);
                SetErrorModalEdit(false);
                globalAdvancePaymentId = AdvancePaymentId;
            } else { SweetAlert.fire('Reject still pending!') }
        })
    }

    const StatusHandler = async (AdvancePaymentId) => {
        SweetAlert.fire({
            //title: 'You are going to approve.Are you sure?',
            text: "You are going to approve.Are you sure?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                let PaymentStatusMId = "2";
                globalAdvancePaymentId = "";
                asynFunAdvancePaymentStatusDetails(AdvancePaymentId, PaymentStatusMId)
            } else { SweetAlert.fire('Approve still pending!') }
        })
    }
    const asynFunAdvancePaymentStatusDetails = async (AdvancePaymentId, PaymentStatusM) => {
        try {

            let url = URL_Start + 'master/SETUPDATEAdvancePaymentstatus';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { PaymentStatusId: `${PaymentStatusM}`, AddedBy: `${LoginId}`, RejectReason: `${RejectReasonDetails}`, AdvancePaymentId: `${AdvancePaymentId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (data.Success == true && data.Data == "2000") {
                        SweetAlert.fire({ title: "Success!", text: "Amount  has been Approved!", icon: "success" });
                        BindGrid(1, 10, 1, globalPaymentMode, globalPartnerNameId);//GlobalOptionAdvc
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                    console.log('no record found');
                }


            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
            }
            // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const GetPaymentStatusLists = async () => {
        try {
            let url = URL_Start + 'master/GetPaymentStatusDLL'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { SetPaymentStatusDLL(data.Data.map((d) => (<option value={d.PaymentStatusId}>{d.PaymentStatus}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetPaymentmode = async () => {
        try {
            let url = URL_Start + 'master/GetPaymentModesAdvance'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    SetPaymentModeDLL(data.Data.map((d) => (<option value={d.SNo}>{d.PaymentMode}</option>)));
                    SetEditPaymentModeDLL(data.Data.map((d) => (<option value={d.SNo}>{d.PaymentMode}</option>)));

                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }


    /********** Modals  ***********/

    const toggleModal = () => {
        setModal(!modal)
    }

    const UpdateAdvancePaymentHandler = async () => {

        SetIsButtonSubmitEdit(true);
        setModal(true);
        if (EditfromDate.current != undefined && EditfromDate.current != "") {
            SetValidDateF(EditfromDate.current.value);
        }
        else {
            SetValidDateF("");
        }
        if (EditpaymentDate.current != undefined && EditpaymentDate.current != "") {
            SetShowValidDateF(EditpaymentDate.current.value);
        }
        else {
            SetShowValidDateF("");
        }
        SetIsButtonSubmitNew(true);
        formik.handleSubmit();
    }
    /********** Validations  ***********/
    const formik = useFormik({
        initialValues: {
            aymentmode: '0',
            advanceamt: '',
            txtremarks: '',
            advancePayId: '0'

            // callType: '',
        },
        // enableReinitialize: true,
        validationSchema: Yup.object({
            // email: Yup.string().email('Invalid email address').required('Required'),
            // Paymentmode: Yup.string().transform(v => v=== '0' ? '' : v)
            // .required('Payment Mode Selection is Required'),
            advanceamt: Yup.string().required('Advance Amount is Required'),
            txtremarks: Yup.string().required("Remarks is Required"),
            // Clientname: Yup.string().transform(v => v=== '0' ? '' : v)
            // .required('Client Name is Required'),

        }),
        isSubmitting: true,
        onSubmit: async (values, actions) => {
            try {

                if (EditPaymentModeVal == "" || EditPaymentModeVal == "0" && IsButtonSubmitEdit == true) {
                    setModal(true); SetErrorModalEdit(true);
                }
                else if (IsShowDateCand == true && BankVal == "" && IsButtonSubmitEdit == true) {
                    setModal(true); SetErrorModalEdit(true);
                }
                else if (IsShowDateCand == true && CustrizeNo == "" && IsButtonSubmitEdit == true) {
                    setModal(true); SetErrorModalEdit(true);
                }
                else if (IsShowDateCand == true && (ShowValidDateF == "" || moment(ShowValidDateF, 'YYYY-MM-DD').isValid() == false)) {
                    setModal(true); SetErrorModalEdit(true);
                }
                else {
                    SetIsButtonSubmitNew(false);
                    SetIsButtonSubmitEdit(false);
                    setModal(false);
                    let url = URL_Start + 'master/SETupdateAdvancePaymentDetails';

                    let options = {
                        method: 'POST',
                        url: url,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json;charset=UTF-8'
                        },
                        data: {
                            AdvancePaymentId: `${values.advancePayId}`, PaymentId: `${EditPaymentModeVal}`, DepositDate: `${ValidDateF}`, DepositAmount: `${values.advanceamt}`, Remarks: `${values.txtremarks}`, BankId: `${BankVal}`, PaymentModeNo: `${CustrizeNo}`, PaymentDate: `${ShowValidDateF}`
                            , AddedBy: `${LoginId}`
                        }
                    };

                    let response = await axios(options);
                    let responseOK = response && response.status == 200;
                    if (responseOK) {
                        let data = response.data;
                        // let data = await response.data;
                        if (data.Success == true && data.Data == "2000") {
                            formik.resetForm();


                            SweetAlert.fire({ title: "Success!", text: "Advance Payment has been Saved!", icon: "success" });
                            setErrorModal(false);
                            setModal(false);
                            SetIsRejectReason(false);
                            SetErrorModalEdit(false);
                            BindGrid(1, 10, 1, globalPaymentMode, globalPartnerNameId);//GlobalOptionAdvc

                        }

                        else {
                            if (data.ErrorList != null && data.ErrorList.length > 0) {
                                let Errtext = <p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                                SweetAlert.fire({ title: "Error", text: data.ErrorList[0].errorMsg, icon: "error" });
                            }
                            SetIsRejectReason(false);
                            SetIsButtonSubmitNew(false);
                            SetIsButtonSubmitEdit(false);
                            SetErrorModalEdit(false);
                        }


                    }
                    else {
                        console.log('no record found');

                    }
                }

            }

            catch (error) {
                console.log(error.message);
                let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
                SetIsRejectReason(false);
                SetIsButtonSubmitNew(false);
                SetIsButtonSubmitEdit(false);
                SetErrorModalEdit(false);
            }
            setTimeout(() => {

                actions.setSubmitting(false);
            }, 1000);
        },
    });

    const SEARCHHandler = async () => {
        if ((new Date(fromDate.current.value) > new Date(TODate.current.value)) || (new Date(TODate.current.value) < new Date(fromDate.current.value))) {
            SweetAlert.fire({ title: "Wrong Dates Selection", text: "From date always less than or equal to todate.", icon: 'warning' });
            BindGrid(1, 10, 1, globalPaymentMode, globalPartnerNameId);//GlobalOptionAdvc
        }
        else {
            BindGrid(1, 10, 1, globalPaymentMode, globalPartnerNameId);//GlobalOptionAdvc
        }
    }

    const AdvanceChkHandler = async (e) => {
        const value = e.target.value;
        GlobalOptionAdvc = value;
    }


    const PaymentModeHandler = async (e) => {
        const value = e.target.value;
        globalPaymentMode = value;
        globalPaymentModeText = e.target.options[e.target.selectedIndex].text;
        SetPaymentModeVal(value);
    }
    const PartnerNameHandler = async (e) => {
        const value = e.target.value;
        globalPartnerNameId = value;
        SetPartnerNameVal(value);    
    }
    const TypeChangeHandler = (options) => { globalPartnerNameId = options.value; setTypeSelected(options.value);SetPartnerNameVal(options.value);  }
    const CentreDDLHandler = (options) => {

        SetSelectedCenter(options);
        GlobalCentreId = options.CentreId;
        if (options != null && options.CentreId != null && options.CentreId != "0") {
            //asyncBindFunDesginationDDL(options.DepartmentId);
        }
    }
    const PaymentStatusHandler = async (e) => {
        const value = e.target.value;
        //SetPaymentStatusVal(value);

    }
    const updateRejectReasonHandler = async () => {
        SetIsButtonSubmitNew(true);
        SetIsButtonSubmitEdit(false);
        let PaymentStatusMId = "3";
        asynFunAdvancePaymentReject(globalAdvancePaymentId, PaymentStatusMId)
    }

    const asynFunAdvancePaymentReject = async (AdvancePaymentId, PaymentStatusM) => {
        try {
            if (RejectReasonDetails == "") {
                setErrorModal(false);
                setModal(false);
                SweetAlert.fire({ title: "Error", text: "Rejected Reason Required.", icon: "error" });

            }
            else {
                setErrorModal(false);
                let url = URL_Start + 'master/SETUPDATEAdvancePaymentstatus';
                let options = {
                    method: 'POST',
                    url: url,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                    data: { PaymentStatusId: `${PaymentStatusM}`, AddedBy: `${LoginId}`, RejectReason: `${RejectReasonDetails}`, AdvancePaymentId: `${AdvancePaymentId}` }
                };
                let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                    let data = response.data;
                    // let data = await response.data;
                    if (data.Success == true) {
                        let ds = data.Data;
                        if (data.Success == true && data.Data == "2000") {
                            SweetAlert.fire({ title: "Success!", text: "Amount  has been Rejected!", icon: "success" });
                            setModal(false);
                            SetIsRejectReason(false);
                            SetIsButtonSubmitNew(false);
                            BindGrid(1, 10, 1, globalPaymentMode, globalPartnerNameId);//GlobalOptionAdvc
                        }
                    }
                    else {
                        let Errtext = <p><div className='text-required'> no record found </div></p>;
                        SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                        console.log('no record found');
                    }


                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                }
            }


            // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const ReajectReasonHandler = async (e) => {
        const Val = e.target.value;
        SetRejectReasonDetails(Val);
    }
    const bankHandler = async () => {

    }
    const CustrizeHandler = async (e) => {
        SetCustrizeNo(e.target.value);
    }
    const EditPaymentModeHandler = async (e) => {
        const value = e.target.value;
        EditglobalPaymentMode = value;
        EditglobalPaymentModeText = e.target.options[e.target.selectedIndex].text;
        SetEditPaymentModeVal(value);
        if (value == "3" || value == "7" || value == "8" || value == "9" || value == "10" || value == "11") {
            SetIsShowDateCand(true);
        }
        else {
            SetIsShowDateCand(false);
        }
    }
    //  const UpdateAdvancePaymentHandler=async()=>{
    //     SetIsButtonSubmitEdit(true);
    //  }
    return (
        <>
            <Breadcrumb title="Validate Payment" parent="Accounts" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form>
                                    <Form.Row className='justify-content-center'>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>From Date</Form.Label>
                                                <Form.Control type="date" id="date1" name="date1" ref={fromDate} />
                                                {(ValidDateF == "" || moment(ValidDateF, 'YYYY-MM-DD').isValid() == false) && IsButtonSubmitNew == true ? <div className='text-required'>Valid from date required</div> : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>To Date</Form.Label>
                                                <Form.Control type="date" id="date2" name="date2" ref={TODate} />
                                                {(ValidDateF == "" || moment(ValidDateF, 'YYYY-MM-DD').isValid() == false) && IsButtonSubmitNew == true ? <div className='text-required'>Valid to date required</div> : null}
                                            </Form.Group>
                                        </Col>
                                        {/* <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Partner Name<span className='text-red'>*</span></Form.Label>
                                                <Select onChange={CentreDDLHandler} options={CentreListDDL} value={SelectedCenter} />
                                            </Form.Group>
                                        </Col> */}
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Partner Name<span className='text-red'>*</span></Form.Label>
                                                {/* <Form.Control as="select" id="Partnername" name="Partnername" value={PartnerNameVal} onChange={PartnerNameHandler}>
                                                    <option key="0" value="0" selected>Select All</option>
                                                    {CentreListDDL}
                                                </Form.Control> */}
                                                <Select onChange={TypeChangeHandler}
                                                    options={CentreListDDL}
                                                    placeholder='Select All'
                                                    value={CentreListDDL.filter(function (x) { return x.value === typeSelected; })} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>Payment Mode<span className='text-red'>*</span></Form.Label>
                                                <Form.Control as="select" id="Paymentmode" name="Paymentmode" value={PaymentModeVal} onChange={PaymentModeHandler}>
                                                    <option value="0" selected>Select All</option>
                                                    {PaymentModeDLL}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        {/* <Col md={6}>
                                            <div className='text-center mt-4'>
                                                <Form.Group className='advancepaymentradio'>
                                                    <Form.Label>
                                                        <input name="chkAdvance" defaultChecked={true} type="radio" id="chkAdvance" onChange={AdvanceChkHandler} value={1} />
                                                        Pending
                                                    </Form.Label>
                                                    &nbsp;  &nbsp;
                                                    <Form.Label>
                                                        <input name="chkAdvance" type="radio" id="chkAdvance" onChange={AdvanceChkHandler} value={2} />
                                                        Approved
                                                    </Form.Label>
                                                    &nbsp;  &nbsp;
                                                    <Form.Label>
                                                        <input name="chkAdvance" type="radio" id="chkAdvance" onChange={AdvanceChkHandler} value={3} />
                                                        Rejected
                                                    </Form.Label>
                                                    &nbsp;  &nbsp;
                                                    <Form.Label>
                                                        <input name="chkAdvance" type="radio" id="chkAdvance" onChange={AdvanceChkHandler} value={4} />
                                                        All
                                                    </Form.Label>
                                                </Form.Group>
                                            </div>
                                        </Col> */}
                                        <Col md={12} className='text-center'>
                                            <Button variant='primary' onClick={SEARCHHandler} >Search</Button>
                                        </Col>
                                    </Form.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Validate Payment Detail</Card.Title>
                            </Card.Header>
                            <Card.Body>

                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <DynamicDataTables keyField="AdvancePaymentId" tableData={PageHelper.dsAdvancePaymentDlists} columns={columns} pagination={paginationOptions ? paginationFactory(paginationOptions) : null} />
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>

            </Container>
            {IsEditDetail &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true} >
                    <ModalHeader toggle={toggleModal}>Edit Details</ModalHeader>
                    <ModalBody>
                        {EditBindAdvancePayDetails != null ? <><Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <Form.Row className="justify-content-center">
                                            <Col md={12}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>Partner Name</b></label>
                                                    <span className="form-control-plaintext" >{EditBindAdvancePayDetails.CentreName == '' ? 'N/A' : EditBindAdvancePayDetails.CentreName + ' ' + '(' + EditBindAdvancePayDetails.CentreCode + ')'}</span>
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row className="justify-content-center">
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label>Payment Mode<span className='text-red'>*</span></Form.Label>
                                                    <Form.Control as="select" id="editPaymentmode" name="editPaymentmode" value={EditPaymentModeVal} onChange={EditPaymentModeHandler}>
                                                        <option value="0" selected>Select</option>
                                                        {EditPaymentModeDLL}
                                                    </Form.Control>
                                                    {(EditPaymentModeVal == "" || EditPaymentModeVal == "0") && IsButtonSubmitEdit == true ? (<div className='text-required'>{"Payment Mode is Required"}</div>) : null}
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row className="justify-content-center">
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label>Deposit Date</Form.Label>
                                                    <Form.Control type="date" id="editdate1" name="editdate1" defaultValue={EditBindAdvancePayDetails.DepositDate == "" ? defaultValue : EditBindAdvancePayDetails.DepositDate} ref={EditfromDate} />
                                                    {(ValidDateF == "" || moment(ValidDateF, 'YYYY-MM-DD').isValid() == false) && IsButtonSubmitEdit == true ? <div className='text-required'>Valid Deposit Date Required</div> : null}
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row className="justify-content-center">
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label>Advance Amount<span className='text-red'>*</span></Form.Label>
                                                    <Form.Control type="number" id="advanceamt" name="advanceamt" onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.advanceamt} />
                                                    {formik.touched.advanceamt && formik.errors.advanceamt ? (
                                                        <div className='text-required'>{formik.errors.advanceamt}</div>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        {EditPaymentModeVal != "" && (EditPaymentModeVal == "3" || EditPaymentModeVal == "7"

                                            || EditPaymentModeVal == "8" || EditPaymentModeVal == "9" || EditPaymentModeVal == "10" || EditPaymentModeVal == "11") ? <>
                                            <Form.Row className="justify-content-center">
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <Form.Label>Bank Name</Form.Label>
                                                        <Form.Control as="select" id="bankname" name="bankname" onChange={bankHandler} value={BankVal} >
                                                            <option value="0" selected>Select</option>
                                                            {BankDLL}
                                                        </Form.Control>
                                                        {IsShowDateCand == true && BankVal == "" && IsButtonSubmitEdit == true ? <div className='text-required'>Bank Name is Required</div> : null}
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Form.Row className="justify-content-center">
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <Form.Label>{EditglobalPaymentModeText} No.</Form.Label>
                                                        <Form.Control type="text" id="txtcustrizeNo" name="txtcustrizeNo" onChange={CustrizeHandler}

                                                            defaultValue={CustrizeNo} />
                                                        {IsShowDateCand == true && CustrizeNo == "" && IsButtonSubmitEdit == true ? <div className='text-required'>{EditglobalPaymentModeText} No. is Required</div> : null}
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Form.Row className="justify-content-center">
                                                <Col md={12}>
                                                    <Form.Group>
                                                        <Form.Label>{EditglobalPaymentModeText} Date</Form.Label>
                                                        <Form.Control type="date" id="datePayment" name="datepayment" ref={EditpaymentDate} defaultValue={EditBindAdvancePayDetails.PaymentDate == "" ? defaultValue : EditBindAdvancePayDetails.PaymentDate} />
                                                        {IsShowDateCand == true && (ShowValidDateF == "" || moment(ShowValidDateF, 'YYYY-MM-DD').isValid() == false) && IsButtonSubmitEdit == true ? <div className='text-required'>Valid {EditglobalPaymentModeText} Date is Required</div> : null}
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                        </> : null}

                                        <Form.Row className="justify-content-center">
                                            <Col md={12}>
                                                <Form.Group>
                                                    <Form.Label>Remarks<span className='text-red'>*</span></Form.Label>
                                                    <Form.Control type="text" id="txtremarks" name="txtremarks" onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.txtremarks} />
                                                    {formik.touched.txtremarks && formik.errors.txtremarks ? (
                                                        <div className='text-required'>{formik.errors.txtremarks}</div>
                                                    ) : null}
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row></> : null}

                    </ModalBody>
                    <ModalFooter>
                        <Button variant="primary" onClick={UpdateAdvancePaymentHandler}  >Update</Button>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            }              {IsRejectReason &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true} >
                    <ModalHeader toggle={toggleModal}>Do you want to Cancel this amount ?</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>


                                        <Form.Row className="justify-content-center">
                                            <Col md={12}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>Reason <span className='text-red'>*</span></b></label>
                                                    <textarea className="form-control" id="txtreason" name="txtreason" rows="3" onChange={ReajectReasonHandler}
                                                        value={RejectReasonDetails}
                                                    ></textarea>
                                                    {/* {RejectReasonDetails=="" && IsButtonSubmitNew==true? (
                                                    <div className='text-required'>Reject Reason Required</div>
                                                ) : null}  */}
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="primary" onClick={updateRejectReasonHandler}  >Update</Button>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            }
            {errorModal &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                    <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                    <ModalBody>

                        <p>   {RejectReasonDetails == "" && IsButtonSubmitNew == true ? (
                            <div className='text-required'>Reject Reason Required</div>
                        ) : null} </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                        <Button variant="primary" onClick={toggleModal}>Ok</Button>
                    </ModalFooter>

                </Modal>
            }
            {ErrorModalEdit &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                    <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                    <ModalBody>

                        <p>{(EditPaymentModeVal == "" || EditPaymentModeVal == "0") && IsButtonSubmitEdit == true ? (<div className='text-required'>{"Payment Mode is Required"}</div>) : null}</p>
                        <p> {formik.touched.advanceamt && formik.errors.advanceamt ? (
                            <div className='text-required'>{formik.errors.advanceamt}</div>
                        ) : null} </p>

                        <p> {formik.touched.txtremarks && formik.errors.txtremarks ? (
                            <div className='text-required'>{formik.errors.txtremarks}</div>
                        ) : null} </p>
                        <p> {(ValidDateF == "" || moment(ValidDateF, 'YYYY-MM-DD').isValid() == false) && IsButtonSubmitEdit == true ? <div className='text-required'>Valid Deposit Date Required</div> : null} </p>
                        <p>{IsShowDateCand == true && BankVal == "" && IsButtonSubmitEdit == true ? <div className='text-required'>Bank Name is Required</div> : null}</p>
                        <p> {IsShowDateCand == true && CustrizeNo == "" && IsButtonSubmitEdit == true ? <div className='text-required'>{EditglobalPaymentModeText} No. is Required</div> : null}</p>
                        <p> {IsShowDateCand == true && (ShowValidDateF == "" || moment(ShowValidDateF, 'YYYY-MM-DD').isValid() == false) && IsButtonSubmitEdit == true ? <div className='text-required'>Valid {EditglobalPaymentModeText} Date Required</div> : null}</p>

                    </ModalBody>
                    <ModalFooter>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                        <Button variant="primary" onClick={toggleModal}>Ok</Button>
                    </ModalFooter>

                </Modal>
            }
        </>
    )
}
export default ValidatePayment;