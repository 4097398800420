import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import SweetAlert from 'sweetalert2';
import axios from 'axios';
import { FrontDesk_URL } from '../../../constant';
import Breadcrumb from '../../../components/common/breadcrumb';
import LoadingButton from '../commonComponent/LoadingButton';
import { useHistory } from "react-router-dom";
const ViewInvoice = (props) => {
    const lsFilterDetails = JSON.parse(localStorage.getItem("CyclotronData"));
    const param = { VisitingId: lsFilterDetails.VisitingId, BuyerCentreId: lsFilterDetails.BuyerCentreId, CreatedBy: lsFilterDetails.CreatedBy };
    const history = useHistory();
    const [SellerName, setSellerName] = useState("");
    const [SellerAddress, setSellerAddress] = useState("");
    const [SellerGSTIN, setSellerGSTIN] = useState("");
    const [SellerLocation, setSellerLocation] = useState("");
    const [SellerStateName, setSellerStateName] = useState("");
    const [SellerPincode, setSellerPincode] = useState("");
    const [SellerEmail, setSellerEmail] = useState("");
    const [PANNo, setPANNo] = useState("");
    const [isButtonLoading, setIsButtonLoading] = useState(false);


    const [BuyerName, setBuyerName] = useState("");
    const [BuyerShippingName, setBuyerShippingName] = useState("");
    const [BuyerAddress, setBuyerAddress] = useState("");
    const [BuyerGSTIN, setBuyerGSTIN] = useState("");
    const [BuyerLocation, setBuyerLocation] = useState("");
    const [BuyerPincode, setBuyerPincode] = useState("");
    const [BuyerPhone, setBuyerPhone] = useState("");
    const [BuyerEmail, setBuyerEmail] = useState("");
    const [BuyerState, setBuyerState] = useState("");

    const [InvoiceNo, setInvoiceNo] = useState("");
    const [VisitID, setVisitID] = useState("");
    const [IRNNo, setIRNNo] = useState("");
    const [InvoiceDate, setInvoiceDate] = useState("");
    const [DueDate, setDueDate] = useState("");
    const [QRCodeImg, setQRCodeImg] = useState("");

    const [InvoiceItems, setInvoiceItems] = useState([]);

    const [ValueBeforeDiscount, setValueBeforeDiscount] = useState("");
    const [TotalDiscount, setTotalDiscount] = useState("");
    const [TotalTaxableValue, setTotalTaxableValue] = useState("");
    const [TotalTaxAmount, setTotalTaxAmount] = useState("");
    const [TotalAfterTax, setTotalAfterTax] = useState("");
    const [Frieght, setFrieght] = useState("");
    const [GrandTotal, setGrandTotal] = useState("");
    const [TotalIGST, setTotalIGST] = useState("");
    const [TotalCGST, setTotalCGST] = useState("");
    const [TotalSGST, setTotalSGST] = useState("");
    const [AmountInWords, setAmountInWords] = useState("");

    const [InvoiceType, setInvoiceType] = useState("");
    const [Remarks, setRemarks] = useState("");
    const [BuyerShippingState, setBuyerShippingState] = useState("");
    const [BuyerShippingRegion, setBuyerShippingRegion] = useState("");
    const [BuyerShippingLocation, setBuyerShippingLocation] = useState("");
    const [BuyerShippingAddress, setBuyerShippingAddress] = useState("");
    const [BuyerShippingGSTIN, setBuyerShippingGSTIN] = useState("");
    useEffect(() => {
        PrintEInvoice();
    }, [])

    const PrintEInvoice = async () => {
        const ObjServiceCollection = {
            LoggedInUser: param.CreatedBy,
            BuyerCentreId: param.BuyerCentreId,
            VisitingID: param.VisitingId,
        };
        try {
            let url = FrontDesk_URL + 'master/GetCyclotronInvoice'
            await axios.post(url, ObjServiceCollection).then((response) => {
                const data = response.data;
                if (data.Success) {
                    let ds = data.Data;
                    if (ds != null) {
                        if (ds != null && ds.cyclotronInvoiceSeller != null) {
                            setSellerName(ds.cyclotronInvoiceSeller.SellerLegalName);
                            setSellerAddress(ds.cyclotronInvoiceSeller.SellerAddress);
                            setSellerGSTIN(ds.cyclotronInvoiceSeller.SellerGSTIN);
                            setSellerLocation(ds.cyclotronInvoiceSeller.SellerLocation);
                            setSellerStateName(ds.cyclotronInvoiceSeller.SellerState);
                            setSellerPincode(ds.cyclotronInvoiceSeller.SellerPincode);
                            setSellerEmail(ds.cyclotronInvoiceSeller.SellerEmail);
                            setPANNo(ds.cyclotronInvoiceSeller.PANNo);
                        }
                        else {
                            setSellerName("");
                            setSellerAddress("");
                            setSellerGSTIN("");
                            setSellerLocation("");
                            setSellerStateName("");
                            setSellerPincode("");
                            setSellerEmail("");
                            setPANNo("");
                        }
                        if (ds != null && ds.cyclotronInvoiceBuyer != null) {
                            setBuyerName(ds.cyclotronInvoiceBuyer.BuyerLegalName);
                            setBuyerShippingName(ds.cyclotronInvoiceBuyer.BuyerShippingLegalName);
                            setBuyerState(ds.cyclotronInvoiceBuyer.BuyerState);
                            setBuyerAddress(ds.cyclotronInvoiceBuyer.BuyerAddress);
                            setBuyerGSTIN(ds.cyclotronInvoiceBuyer.BuyerGSTIN);
                            setBuyerLocation(ds.cyclotronInvoiceBuyer.BuyerLocation);
                            setBuyerPincode(ds.cyclotronInvoiceBuyer.BuyerPicode);
                            setBuyerPhone(ds.cyclotronInvoiceBuyer.BuyerPhone);
                            setBuyerEmail(ds.cyclotronInvoiceBuyer.BuyerEmail);
                        }
                        else {
                            setBuyerName("");
                            setBuyerShippingName("");
                            setBuyerState("");
                            setBuyerAddress("");
                            setBuyerGSTIN("");
                            setBuyerLocation("");
                            setBuyerPincode("");
                            setBuyerPhone("");
                            setBuyerEmail("");
                        }
                        if (ds != null && ds.cyclotronInvoiceDetails != null) {
                            setInvoiceNo(ds.cyclotronInvoiceDetails.InvoiceNo);
                            setVisitID(ds.cyclotronInvoiceDetails.VisitingID);
                            setIRNNo(ds.cyclotronInvoiceDetails.IRNNo);
                            setInvoiceDate(ds.cyclotronInvoiceDetails.InvoiceDate);
                            setDueDate(ds.cyclotronInvoiceDetails.DueDate);
                            setQRCodeImg(ds.cyclotronInvoiceDetails.QRCodeImg);
                        }
                        else {
                            setInvoiceNo("");
                            setVisitID("");
                            setIRNNo("");
                            setInvoiceDate("");
                            setDueDate("");
                            setQRCodeImg("");
                        }
                        if (ds != null && ds.cyclotronInvoiceOrderFields != null && ds.cyclotronInvoiceOrderFields.length > 0) {
                            setInvoiceItems(ds.cyclotronInvoiceOrderFields);
                        }
                        else {
                            setInvoiceItems([]);
                        }
                        if (ds != null && ds.cyclotronInvoiceTotalTestAmt != null) {
                            setValueBeforeDiscount(ds.cyclotronInvoiceTotalTestAmt.ValueBeforeDiscount);
                            setTotalDiscount(ds.cyclotronInvoiceTotalTestAmt.TotalDiscount);
                            setTotalTaxableValue(ds.cyclotronInvoiceTotalTestAmt.TotalTaxableValue);
                            setTotalTaxAmount(ds.cyclotronInvoiceTotalTestAmt.TotalTaxAmount);
                            setTotalAfterTax(ds.cyclotronInvoiceTotalTestAmt.TotalAfterTax);
                            setFrieght(ds.cyclotronInvoiceTotalTestAmt.Frieght);
                            setGrandTotal(ds.cyclotronInvoiceTotalTestAmt.GrandTotal);
                            setTotalIGST(ds.cyclotronInvoiceTotalTestAmt.TotalIGST);
                            setTotalCGST(ds.cyclotronInvoiceTotalTestAmt.TotalCGST);
                            setTotalSGST(ds.cyclotronInvoiceTotalTestAmt.TotalSGST);
                            const Amtinwords = ds.cyclotronInvoiceTotalTestAmt.AmountInWords;
                            setAmountInWords(Amtinwords);
                        }
                        else {
                            setValueBeforeDiscount(0.00);
                            setTotalDiscount(0.00);
                            setTotalTaxableValue(0.00);
                            setTotalTaxAmount(0.00);
                            setTotalAfterTax(0.00);
                            setFrieght(0.00);
                            setGrandTotal(0.00);
                            setTotalIGST(0.00);
                            setTotalCGST(0.00);
                            setTotalSGST(0.00);
                            setAmountInWords("");
                        }
                        if (ds != null && ds.cyclotronInvoiceBuyerShipping != null) {
                            setInvoiceType(ds.cyclotronInvoiceBuyerShipping.InvoiceType);
                            setRemarks(ds.cyclotronInvoiceBuyerShipping.Remarks);
                            setBuyerShippingState(ds.cyclotronInvoiceBuyerShipping.BuyerShippingState);
                            setBuyerShippingRegion(ds.cyclotronInvoiceBuyerShipping.BuyerShippingRegion);
                            setBuyerShippingLocation(ds.cyclotronInvoiceBuyerShipping.BuyerShippingLocation);
                            setBuyerShippingAddress(ds.cyclotronInvoiceBuyerShipping.BuyerShippingAddress);
                            setBuyerShippingGSTIN(ds.cyclotronInvoiceBuyerShipping.BuyerShippingGSTIN);
                        }
                        else {
                            setInvoiceType("");
                            setRemarks("");
                            setBuyerShippingState("");
                            setBuyerShippingRegion("");
                            setBuyerShippingLocation("");
                            setBuyerShippingAddress("");
                            setBuyerShippingGSTIN("");
                        }
                    }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GenerateIRNNo = async () => {
        if (BuyerGSTIN == null || BuyerGSTIN == "" || BuyerGSTIN == undefined) {
            SweetAlert.fire({
                title: 'Are you sure?',
                text: "This party does not have a GST number. Invoice will be generated without IRN. Do you want to proceed ?",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                reverseButtons: true,
                cancelButtonText: 'Cancel',
                cancelButtonColor: "#000",
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        SaveWithoutEInvoice();
                    }
                })
        }
        else {
            setIsButtonLoading(true);
            const objGenrrateIRNFields = {
                LoggedInUser: localStorage.LoggedInUser,
                BuyerCentreId: param.BuyerCentreId,
                VisitingID: param.VisitingId
            };
            let url = FrontDesk_URL + 'master/GetEInvoiceIRNNo'
            await axios.post(url, objGenrrateIRNFields).then((response) => {
                setIsButtonLoading(false);
                const data = response.data;
                if (data.Success) {
                    if (data.Data == "2000") {
                        SweetAlert.fire({
                            title: 'Thank You!', text: "Invoice generated successfully.", icon: 'success', showCancelButton: false, confirmButtonText: 'Ok', reverseButtons: true
                        }).then((result) => {
                            const stateparams = { VisitingId: param.VisitingId, BuyerCentreId: param.BuyerCentreId, CreatedBy: localStorage.LoggedInUser };
                            localStorage.removeItem('CyclotronData');
                            localStorage.setItem('CyclotronData', JSON.stringify(stateparams));
                            window.open(`${process.env.PUBLIC_URL}/pdfPrints/PrintCyclotronInvoice`, '_blank');
                            localStorage.setItem('rvpage', '1');
                            { props.invoicepage(false) }
                        })
                    }
                    else if (data.Data == "2004") {
                        SweetAlert.fire({
                            title: 'Thank You!', text: "Invoice generated successfully but unable to save response in our system.", icon: 'success', showCancelButton: false, confirmButtonText: 'Ok', reverseButtons: true
                        }).then((result) => {
                            const stateparams = { VisitingId: param.VisitingId, BuyerCentreId: param.BuyerCentreId, CreatedBy: localStorage.LoggedInUser };
                            localStorage.removeItem('CyclotronData');
                            localStorage.setItem('CyclotronData', JSON.stringify(stateparams));
                            window.open(`${process.env.PUBLIC_URL}/pdfPrints/PrintCyclotronInvoice`, '_blank');
                            localStorage.setItem('rvpage', '1');
                            { props.invoicepage(false) }
                        })
                    }
                    else if (data.Data == "2005") {
                        SweetAlert.fire({
                            title: 'Thank You!', text: "Invoice generated successfully but unable to save QR Code in our system.", icon: 'success', showCancelButton: false, confirmButtonText: 'Ok', reverseButtons: true
                        }).then((result) => {
                            const stateparams = { VisitingId: param.VisitingId, BuyerCentreId: param.BuyerCentreId, CreatedBy: localStorage.LoggedInUser };
                            localStorage.removeItem('CyclotronData');
                            localStorage.setItem('CyclotronData', JSON.stringify(stateparams));
                            window.open(`${process.env.PUBLIC_URL}/pdfPrints/PrintCyclotronInvoice`, '_blank');
                            localStorage.setItem('rvpage', '1');
                            { props.invoicepage(false) }
                        })
                    }
                }
                else {
                    if (data.Data == "-2001" || data.Data == "-2002") {
                        SweetAlert.fire({ title: "Data Missing", text: "Invoice related all information  ot available.! Please try after sometime.", icon: "error" }).then((result) => {
                            setTimeout(function () {window.location.reload();}, 1000);    
                        })                                          
                    }
                    else if (data.Data == "-2003") {
                        SweetAlert.fire({ title: "Invoice Number Not Generated", text: "E-Invoice API given error.", icon: "error" }).then((result) => {
                            setTimeout(function () {window.location.reload();}, 1000);    
                        })                                          
                    }            
                    else if (data.Data == "-2004") {
                        SweetAlert.fire({ title: "Invoice Number Not Generated", text: "Error in generating invoice.! Please try after sometime.", icon: "error" }).then((result) => {
                            setTimeout(function () {window.location.reload();}, 1000);    
                        })                                          
                    }                  
                    else if (data.Data == "-2005") {
                        SweetAlert.fire({ title: "GSP Adaequare Error", text: "Token can't be generating.! Please try after sometime.", icon: "error" }).then((result) => {
                            setTimeout(function () {window.location.reload();}, 1000);    
                        })                                          
                    }
                    else if (data.Data == "-2006") {
                        SweetAlert.fire({ title: "GSTIn details not matched", text: "GSTIN master details are not matched.", icon: "error" }).then((result) => {
                            setTimeout(function () {window.location.reload();}, 1000);    
                        })                                          
                    }
                    else if (data.Data == "-2000") {
                        SweetAlert.fire({ title: "API Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }).then((result) => {
                            setTimeout(function () {window.location.reload();}, 1000);    
                        })                                          
                    }
                    else {
                        SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime.", icon: "error" }).then((result) => {
                            setTimeout(function () {window.location.reload();}, 1000);    
                        })
                    }

                }
            }).catch(function (error) {
                setIsButtonLoading(false);
                if (error.response) { ErrorFunction(error.response.status); }
            });
        }


    }
    const SaveWithoutEInvoice = async () => {
        setIsButtonLoading(true);
        const objInvWithoutEInvoiceFields = {
            VisitingID: param.VisitingId,
            BuyerCentreId: param.BuyerCentreId,
            CreatedBy: localStorage.LoggedInUser,
        };
        let url = FrontDesk_URL + 'master/SetWithoutEInvoiceResponse'
        await axios.post(url, objInvWithoutEInvoiceFields).then((response) => {
            setIsButtonLoading(false);
            const data = response.data;
            if (data.Success) {
                if (data.Data == "2000") {
                    SweetAlert.fire({
                        title: 'Thank You!', text: "Invoice generated successfully.", icon: 'success', showCancelButton: false, confirmButtonText: 'Ok', reverseButtons: true
                    }).then((result) => {
                        const stateparams = { VisitingId: param.VisitingId, BuyerCentreId: param.BuyerCentreId, CreatedBy: localStorage.LoggedInUser };
                        localStorage.removeItem('CyclotronData');
                        localStorage.setItem('CyclotronData', JSON.stringify(stateparams));
                        window.open(`${process.env.PUBLIC_URL}/pdfPrints/PrintCyclotronInvoice`, '_blank');
                        localStorage.setItem('rvpage', '1');
                        { props.invoicepage(false) }
                    })
                }
               else if (data.Data == "-2001" || data.Data == "-2002") {
                    SweetAlert.fire({ title: "Data Missing", text: "Invoice generated but E-way bill not generated due to some missing fields.", icon: "error" })
                }
                else if (data.Data == "-2004") {
                    SweetAlert.fire({ title: "E-Way Bill Error", text: "Invoice generated but E-Way Bill response not saved in system.", icon: "error" })
                }
                else if (data.Data == "-2005") {
                    SweetAlert.fire({ title: "E-Way Bill Error", text: "Invoice generated but E-Way Bill API token generating error.", icon: "error" })
                }
                else if (data.Data == "-2006") {
                    SweetAlert.fire({ title: "E-Way Bill Error", text: "Invoice generated but E-Way Bill API given error.", icon: "error" })
                }
            }
            else {                
              if (data.ErrorList[0].errorCode == -2000 || data.Data == -2000) { 
                SweetAlert.fire({ title: "API Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
            }
        }).catch(function (error) {
            setIsButtonLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }

    const PrevBckHandler = async () => {
        window.location.href = `${process.env.PUBLIC_URL}/BulkInvoice/Create-Invoice`;
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    return (
        <>
            <Breadcrumb title="Preview Invoice" parent="Bulk Invoice" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <div>
                            <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="tbInvoice">
                                <tbody>
                                    <tr>
                                        <td className="tdHeaderInvoice">
                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                <tbody>
                                                    <tr>
                                                        <td width="100%" align="left" valign="top" scope="col">
                                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td align="center"><h1>{SellerName}</h1></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="center">{SellerAddress},{SellerLocation},{SellerStateName},{SellerPincode},IN GSTNO. {SellerGSTIN} </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="center">E-Mail : {SellerEmail}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td height="10" align="left"></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="left"></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td height="10" align="left"></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>

                                                    </tr>
                                                </tbody>
                                            </table></td>
                                    </tr>
                                    <tr>
                                        <td height="40" align="center" className="brdrTop brdrBottom"><h2 className="taxInvoice">{InvoiceType == "1" ? "Tax Invoice" : "Credit Note"}</h2></td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                <tbody>
                                                    <tr>
                                                        <td className="brdrRight brdrBottom p-10">
                                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><strong>Supplier :</strong></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><strong>{SellerName} </strong></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{SellerAddress},{SellerLocation},{SellerStateName},{SellerPincode} ,IN</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><strong>GSTNO.:</strong> {SellerGSTIN} </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><strong>E-Mail : {SellerEmail}</strong></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><strong>PAN No : </strong>{PANNo}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><strong>Remarks : {Remarks} </strong></td>
                                                                    </tr>

                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td valign="top" className="brdrBottom p-10">
                                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0" style={{ fontWeight: 'bold' }}>
                                                                {
                                                                    InvoiceType == "1" ?
                                                                        <tbody>
                                                                            <tr>
                                                                                <td width="31%">Invoice No. </td>
                                                                                <td width="7%">:</td>
                                                                                <td width="62%">{InvoiceNo}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Invoice Date </td>
                                                                                <td>:</td>
                                                                                <td>{InvoiceDate} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Kind Attention </td>
                                                                                <td>:</td>
                                                                                <td>&nbsp;</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Shipping Type </td>
                                                                                <td>:</td>
                                                                                <td>&nbsp;</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Due Date</td>
                                                                                <td>:</td>
                                                                                <td>{DueDate} </td>
                                                                            </tr>
                                                                        </tbody>
                                                                        :
                                                                        <tbody>
                                                                            <tr>
                                                                                <td width="31%">Credit Note No. </td>
                                                                                <td width="7%">:</td>
                                                                                <td width="62%">{InvoiceNo}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Credit Note Date</td>
                                                                                <td>:</td>
                                                                                <td>{InvoiceDate} </td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Kind Attention </td>
                                                                                <td>:</td>
                                                                                <td>&nbsp;</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Shipping Type </td>
                                                                                <td>:</td>
                                                                                <td>&nbsp;</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>Mode/Terms Of Payment</td>
                                                                                <td>:</td>
                                                                                <td>Net 30 Days <br />(Posting Date) </td>
                                                                            </tr>
                                                                        </tbody>
                                                                }
                                                            </table>

                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td width="50%" valign="top" className="brdrRight brdrBottom p-10">
                                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><strong>Customer Address :</strong></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><strong>{BuyerName} </strong></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{BuyerAddress} {BuyerLocation} {BuyerState} {BuyerPincode} India </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td><strong>GSTIN No .</strong>{BuyerGSTIN == "" ? "URP (Unregistered Party)" : BuyerGSTIN}</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                        <td width="50%" valign="top" className="brdrBottom p-10">
                                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td><strong>Ship To Customer Address :</strong></td>
                                                                    </tr>
                                                                    <tr>
                                                                        {/* <td><strong>{BuyerName}</strong></td> */}
                                                                        <td><strong>{BuyerShippingName}</strong></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>{BuyerShippingAddress} {BuyerShippingLocation} {BuyerShippingState}  {BuyerPincode} India </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>&nbsp;</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>
                                                                            {/* <strong>GSTIN No .</strong>{BuyerShippingGSTIN} */}
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td colSpan="2" valign="top">
                                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="tbItem">
                                                                <tbody>
                                                                    <tr>
                                                                        <td width="2%" className="brdrBottom"><strong>Sr No</strong></td>
                                                                        <td width="23%" className="brdrBottom brdrLeft"><strong>Item Description</strong></td>
                                                                        <td width="8%" className="brdrBottom brdrLeft"><strong>Catalog No</strong></td>
                                                                        <td width="8%" className="brdrBottom brdrLeft"><strong>HSN No</strong></td>
                                                                        <td width="7%" className="brdrBottom brdrLeft"><strong>Quantity</strong></td>
                                                                        <td width="7%" className="brdrBottom brdrLeft"><strong>Unit</strong></td>
                                                                        <td width="7%" className="brdrBottom brdrLeft"><strong>Unit Price</strong></td>
                                                                        <td width="7%" className="brdrBottom brdrLeft"><strong>Unit Discount</strong></td>
                                                                        <td width="9%" className="brdrBottom brdrLeft"><strong>Taxable Value</strong></td>
                                                                        <td width="7%" className="brdrBottom brdrLeft"><strong>Tax Rate (%)</strong></td>
                                                                        <td width="7%" className="brdrBottom brdrLeft"><strong>Tax Value </strong></td>
                                                                        <td width="8%" className="brdrBottom brdrLeft"><strong>Amount</strong></td>
                                                                    </tr>
                                                                    {
                                                                        InvoiceItems != [] ?
                                                                            <>
                                                                                {
                                                                                    InvoiceItems.map((d, index) =>
                                                                                        <tr>
                                                                                            <td valign="top" className="brdrBottom">{parseInt(index + 1)}</td>
                                                                                            <td valign="top" className="brdrBottom brdrLeft">{d.ItemDescription}</td>
                                                                                            <td align="left" valign="top" className="brdrBottom brdrLeft"> {d.CatalogNo}</td>
                                                                                            <td align="left" valign="top" className="brdrBottom brdrLeft"> {d.HSNCode}</td>
                                                                                            <td align="left" valign="top" className="brdrBottom brdrLeft"> {d.Quantity}</td>
                                                                                            <td align="left" valign="top" className="brdrBottom brdrLeft"> {d.Unit}</td>
                                                                                            <td align="left" valign="top" className="brdrBottom brdrLeft"> {d.UnitPrice}</td>
                                                                                            <td align="left" valign="top" className="brdrBottom brdrLeft"> {d.Discount}</td>
                                                                                            <td align="left" valign="top" className="brdrBottom brdrLeft"> {d.TaxableValue}</td>
                                                                                            <td align="left" valign="top" className="brdrBottom brdrLeft"> {d.TaxSlab}</td>
                                                                                            <td align="left" valign="top" className="brdrBottom brdrLeft"> {d.TaxValue}</td>
                                                                                            <td align="left" valign="top" className="brdrBottom brdrLeft"> {d.TotalAmount}</td>
                                                                                        </tr>)
                                                                                }
                                                                            </>
                                                                            : null
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td valign="top" className="brdrRight brdrBottom">
                                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td>
                                                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="tbValueDiscount">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <td width="56%" align="center" className="brdrBottom brdrRight">Total IGST</td>
                                                                                        <td width="44%" align="right" className="brdrBottom">{TotalIGST}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td align="center" className="brdrBottom brdrRight">Total SGST / UTGST</td>
                                                                                        <td align="right" className="brdrBottom">{TotalSGST}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td align="center" className="brdrBottom brdrRight">Total CGST</td>
                                                                                        <td align="right" className="brdrBottom">{TotalCGST}</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td align="center" className="brdrBottom brdrRight">Total Tax</td>
                                                                                        <td align="right" className="brdrBottom">{TotalTaxAmount}</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </tbody>
                                                                <tr>
                                                                    <td>
                                                                        <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="tbValueDiscount">
                                                                            <tbody>
                                                                                <tr>
                                                                                    <td className="txtUnderline">Our Bank Details : </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Name :- Atulaya Healthcare Private Limited </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Bank :- AXIS Bank Ltd </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Account No :- 921020047800018 </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>IFSC :- UTIB0004866 </td>
                                                                                </tr>
                                                                                <tr>
                                                                                    <td>Branch :- Sector - 47, Chandigarh </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </table></td>
                                                                </tr>
                                                                <tr>
                                                                    <td>&nbsp;</td>
                                                                </tr>
                                                            </table></td>
                                                        <td valign="top" className="brdrBottom">
                                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0" className="tbValueDiscount">
                                                                <tbody>
                                                                    <tr>
                                                                        <td width="72%" align="center" className="brdrBottom">Value Before Discount </td>
                                                                        <td width="28%" align="right" className="brdrBottom"> {ValueBeforeDiscount} </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="center" className="brdrBottom">Total Discount </td>
                                                                        <td align="right" className="brdrBottom"> {TotalDiscount} </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="center" className="brdrBottom">Total Taxable Value </td>
                                                                        <td align="right" className="brdrBottom"> {TotalTaxableValue} </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="center" className="brdrBottom">Total Tax Amount </td>
                                                                        <td align="right" className="brdrBottom"> {TotalTaxAmount} </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="center" className="brdrBottom">Total After Tax </td>
                                                                        <td align="right" className="brdrBottom"> {TotalAfterTax} </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="center" className="brdrBottom">Grand Total </td>
                                                                        <td align="right" className="brdrBottom"> {GrandTotal} </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td colSpan={2} align="center" className="brdrBottom">Amount in words : {AmountInWords} </td>
                                                                    </tr>
                                                                </tbody>
                                                            </table></td>
                                                    </tr>
                                                    <tr>
                                                        <td valign="top" className="brdrRight p-10"><strong>Terms &amp; Conditions : </strong>
                                                            <div className="text-md-left">
                                                                <ul>
                                                                    <li className="d-inline-block"> 1. Any complaint about this Invoice must be lodged within 7 days from its receipt, otherwise it will be considered as accepted. Invoice related queries please write to us at ar@atulaya.com  Please send Payment Advice at ar@atulaya.com  </li>
                                                                    <li className="d-inline-block"> 2. Interest @ 18% will be charged on the Invoice Value if it is not paid in stipulated time. </li>
                                                                    <li className="d-inline-block"> 3. All disputes subject to Chandigarh Jurisdiction only.  </li>
                                                                    <li className="d-inline-block"> 4. The Company is registered under the MSMED Act, 2006 with effect from 18th July,2020 bearing Udyam registration number UDYAM-CH-01-0000264</li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                        <td valign="top" className="p-10">
                                                            <table width="100%" border="0" cellSpacing="0" cellPadding="0">
                                                                <tbody>
                                                                    <tr>
                                                                        <td height="10"></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="right"><strong>{SellerName} </strong></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td height="100"></td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td align="right"><strong>Authorized Signatory</strong></td>
                                                                    </tr>
                                                                </tbody>
                                                            </table></td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </Col>
                </Row>
                <Row>
                    <Col md={12} className="mt-4 text-center">
                        {isButtonLoading ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' className="mr-2" type='Button' onClick={(e) => GenerateIRNNo()}>Generate  e-Invoice</Button>}
                        <Button variant='secondary' className="mr-2" onClick={PrevBckHandler} >Back</Button>
                    </Col>
                </Row>
            </Container >
        </>
    )
}

export default ViewInvoice