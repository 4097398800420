import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import InputGroup from 'react-bootstrap/InputGroup';
import Breadcrumb from '../../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Spinner } from 'react-bootstrap';
import SampleToolTip from '../UI/ToolTips/SampleToolTip';
import LoadingButton from '../commonComponent/LoadingButton';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';

var glbRecords = [];
const LISPackages = () => {
    let textSearch = useRef();
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [testsModal, setTestsModal] = useState();
    const [popupTableData, setPopupTableData] = useState([]);
    const [isSyncing, setIsSyncing] = useState(false);
    const openModal = () => { setTestsModal(!testsModal); };
    const [isSaving, setIsSaving] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [csvData, setCSVData] = useState([]);
    const [csvUploadData, setCsvUploadData] = useState([]);

    const paginationOptions = { sizePerPage: 50, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last', onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); } };
    const popupPaginationOptions = { sizePerPage: 10, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last', onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); } };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <SampleToolTip title="Test List" placement="top" id={rowData.PlabId} onClick={() => GetTestsList(rowData)} />
            </div>
        </React.Fragment>
    }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        // { dataField: 'PlabId', text: 'Id' },
        { dataField: 'PackageName', text: 'Package Name', editable: false },
        { dataField: 'PackageCode', text: 'Package Code', editable: false, headerStyle: (colum, colIndex) => { return { width: '10%' }; } },
        { dataField: 'FromAge', text: 'From Age', editable: false, headerStyle: (colum, colIndex) => { return { width: '12%' }; } },
        { dataField: 'ToAge', text: 'To Age', editable: false, headerStyle: (colum, colIndex) => { return { width: '12%' }; } },
        { dataField: 'Gender', text: 'Gender', editable: false, headerStyle: (colum, colIndex) => { return { width: '8%' }; } },
        { dataField: 'SAPCode', text: 'SAP Code', editable: false, headerStyle: (colum, colIndex) => { return { width: '8%' }; } },
        // { dataField: 'ShowInReport', text: 'Show In Report', editable: false, headerStyle: (colum, colIndex) => { return { width: '10%' }; } },
        { dataField: 'HSNCode', text: 'HSN Code', editable: true, headerStyle: (colum, colIndex) => { return { width: '10%' }; } },
        { dataField: 'TaxRate', text: 'Tax Rate', editable: true, headerStyle: (colum, colIndex) => { return { width: '10%' }; } },
        { dataField: 'IsActive', text: 'Is Active', editable: false, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
        { dataField: 'actions', text: 'Actions', formatter: actionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    useEffect(() => { BindGrid(); }, []);
    const handleSearch = async () => {
        setCrntPageNO(0);
        setTableData(glbRecords.filter((item) => item.PackageName.toLowerCase().indexOf(textSearch.current.value.toLowerCase()) !== -1));
    }
    const BindGrid = async () => {
        setIsLoading(true);
        let url = URL_Start + 'master/getpackagemasterlist'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data != null) {
                    glbRecords = data.Data;
                    setTableData(glbRecords);
                    var arrayCSV = glbRecords;
                    arrayCSV = arrayCSV.map(({ PlabId, PackageName, PackageCode, HSNCode, TaxRate }) => ({ PlabId, PackageName, PackageCode, HSNCode, TaxRate }));
                    setCSVData(arrayCSV);
                }
                setIsLoading(false);
            }
            else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const popupColumns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'TestName', text: 'Name', editable: false },
        { dataField: 'TestCode', text: 'Test Code', editable: false },
        { dataField: 'SampleQty', text: 'Sample Qty', editable: false },
        { dataField: 'SampleRemarks', text: 'Remarks', editable: false },
        { dataField: 'IsActive', text: 'IsActive', editable: false }
    ];
    const GetTestsList = async (rwData) => {
        let url = URL_Start + 'master/getpackageitems/' + rwData.PlabId
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data != null) { setPopupTableData(data.Data); openModal(); }
                else { SweetAlert.fire('No data available.') }
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    const SyncPackages = async () => {
        setIsSyncing(true);
        let url = URL_Start + 'LIS/getlispackagedetails'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                SyncPackagesMaster();
            }
            else { setIsSyncing(false); SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsSyncing(false); });
    }
    const SyncPackagesMaster = async () => {
        setIsSyncing(true);
        let url = URL_Start + 'LIS/getlispackagemaster'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                SweetAlert.fire({ title: "Success!", text: "Data synced successfully!", icon: "success" });
                setIsSyncing(false);
                BindGrid();
            }
            else { setIsSyncing(false); SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsSyncing(false); });
    }
    const UpdateHSNCode = async () => {
        setIsSaving(true);
        var arrayHSNCode = [];
        for (var key in tableData) {
            if (tableData[key].HSNCode !== "" || tableData[key].TaxRate !== "") {
                arrayHSNCode.push({ InvestigationId: tableData[key].PlabId, HSNCode: tableData[key].HSNCode, TaxRate: tableData[key].TaxRate });
            }
        }
        const requestOptions = {
            IsPackage: "1",
            ModifiedBy: localStorage.LoggedInUser,
            HSNCodes: arrayHSNCode,
        };
        let url = URL_Start + 'master/updatehsncode'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                SweetAlert.fire({ title: "Success", text: "HSN Code/Tax Rate updated successfully", icon: "success" });
                BindGrid();
                setIsSaving(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsSaving(false); }
        }).catch(function (error) { setIsSaving(false); });
    }
    const processData = (dataString, flag) => {
        const dataStringLines = dataString.split(/\r\n|\n/);
        const headers = dataStringLines[0].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
        const list = [];
        for (let i = 1; i < dataStringLines.length; i++) {
            const row = dataStringLines[i].split(/,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/);
            if (headers && row.length == headers.length) {
                const obj = {};
                for (let j = 0; j < headers.length; j++) {
                    let d = row[j];
                    if (d.length > 0) {
                        if (d[0] == '"')
                            d = d.substring(1, d.length - 1);
                        if (d[d.length - 1] == '"')
                            d = d.substring(d.length - 2, 1);
                    }
                    if (headers[j]) {
                        obj[headers[j].replace(/ /g, "")] = d;
                    }
                }
                // remove the blank rows
                if (Object.values(obj).filter(x => x).length > 0) {
                    list.push(obj);
                }
            }
        }
        console.log(list);
        setCsvUploadData(list);
    }
    const handleNewFileUpload = (e, flag) => {
        setCsvUploadData([]);
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.onload = (evt) => {
            /* Parse data */
            const bstr = evt.target.result;
            const wb = XLSX.read(bstr, { type: 'binary' });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            processData(data, flag);
        };
        reader.readAsBinaryString(file);
    }
    const UploadHSNCode = async () => {
        setIsSaving(true);
        var arrayHSNCode = [];
        var isDataValid = true;
        var errorMsg = true;
        for (var key in csvUploadData) {
            if (csvUploadData[key].HSNCode !== "" || csvUploadData[key].TaxRate !== "") {
                if (isNaN(csvUploadData[key].TaxRate) === false) {
                    arrayHSNCode.push({ InvestigationId: csvUploadData[key].PlabId, HSNCode: csvUploadData[key].HSNCode, TaxRate: csvUploadData[key].TaxRate });
                }
                else { isDataValid = false; errorMsg = "Please enter valid data for " + csvUploadData[key].PackageName + ", you have entered " + csvUploadData[key].TaxRate + " in tax rate column"; break; }
            }
        }
        if (isDataValid) {
            const requestOptions = {
                IsPackage: "1",
                ModifiedBy: localStorage.LoggedInUser,
                HSNCodes: arrayHSNCode,
            };
            let url = URL_Start + 'master/updatehsncode'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                if (data.Success) {
                    SweetAlert.fire({ title: "Success", text: "HSN Code/Tax Rate uploaded successfully", icon: "success" });
                    BindGrid();
                    setIsSaving(false);
                }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsSaving(false); }
            }).catch(function (error) { setIsSaving(false); });
        } else { SweetAlert.fire({ title: "Data Validation Failed", text: errorMsg, icon: "error" }); setIsSaving(false); }
    }
    
    return (
        <React.Fragment>
            <Breadcrumb title="Packages" parent="LIS Master" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row className="justify-content-center">
                                    <Col lg={4} md={6} className="col-8">
                                        <Form.Group>
                                            <InputGroup className="mb-3">
                                                <Form.Control onChange={handleSearch} placeholder="Search by Name..." ref={textSearch} />
                                                <InputGroup.Append>
                                                    <Button variant="secondary" onClick={handleSearch}><i className='fa fa-search'></i></Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2} md={6} className="col-4">
                                        <Form.Group>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Append>
                                                    <Button variant='primary' type='submit' onClick={SyncPackages}>{isSyncing ? <Spinner animation="border" size='sm' /> : null} Sync Data </Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6}>
                                        <Form.Group>
                                            <Form.Label>Upload file (.XLS or .XLSX or .CSV) <span className='text-red'>*</span></Form.Label>
                                            <input type='file' accept=".xls,.xlsx,.csv" onChange={(e) => handleNewFileUpload(e, "1")} />
                                        </Form.Group>
                                        <CSVLink className='textUnderline' data={csvData} filename={"Sample.csv"} target="_blank">Download Sample CSV</CSVLink>
                                        {isSaving ? <LoadingButton variantName='primary' cssName="mr-2" /> : <Button variant='primary' type='submit' className="mr-2" onClick={UploadHSNCode}>Upload</Button>}
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Packages List</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <DataTables keyField='PlabId' tableData={tableData} columns={columns} pagination={paginationFactory(paginationOptions)} />
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className='text-center'>
                        {isSaving ? <LoadingButton variantName='primary' cssName="mr-2" /> : <Button variant='primary' type='submit' className="mr-2" onClick={UpdateHSNCode}>Update</Button>}
                    </Col>
                </Row>
                {testsModal &&
                    <Modal isOpen={testsModal} toggle={openModal} centered={true} size="lg">
                        <ModalHeader toggle={openModal}>Tests List</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Body>
                                            <DataTables keyField='PlabId' tableData={popupTableData} columns={popupColumns} pagination={paginationFactory(popupPaginationOptions)} />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                }
            </Container>
        </React.Fragment>
    )
}

export default LISPackages;