import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { URL_Start } from '../../../constant';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import InputGroup from 'react-bootstrap/InputGroup';
import { MultiSelect } from 'react-multi-select-component';
import '../../../pages/admin/react-multiselect.css';
import Select from 'react-select';
import { useFormik } from 'formik';
const UserReconcilation = () => {
    let fromDate = useRef();
    let toDate = useRef();
    const [table, setTable] = useState([]);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [CSVData, setCSVData] = useState([]);
    const [defaultDate, setDefaultDate] = useState("");
    const[SelectedStatus,SetSelectedStatus]=useState("");
    const [SelectCentreDDL, SetSelectCentreDDL] = useState([]);
    const [typeSelected1, setTypeSelected1] = useState([]);
    useEffect(() => {
        GetBindCenterAllLists();
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
    }, []);
    const formik = useFormik({
        initialValues: { reportuser: '' },
        // validationSchema: Yup.object({
        //     reportuser: Yup.string().required('user is required'),
        // }),
        onSubmit: values => { BindGrid(fromDate.current.value, fromDate.current.value); }
    });
    const paginationOptions = {
        sizePerPage: 100,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const columns = [{ dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    { dataField: 'Code', text: 'Code', editable: false },
    { dataField: 'ClientName', text: 'Client Name', editable: false },
    { dataField: 'ClientType', text: 'Client Type', editable: false },
    { dataField: 'OpeningBalance', text: 'Opening Amt', editable: false },
    { dataField: 'ReceivedAmount', text: 'Received Amt', editable: false },
    { dataField: 'DepositAmount', text: 'Deposit Amt', editable: false },
    { dataField: 'BalanceAmount', text: 'Balance Amt', editable: false },
    ];
    const BindGrid = async (fromDate, toDate) => {
        const  ObjServiceCollection= {
            UserId:localStorage.getItem('LoggedInUser'),
            FromDate:fromDate,
            ToDate:toDate,
            CentreId:formik.values.CentreSelect           
        };        
        try {
            let url = URL_Start + 'reports/getuserreconcilationreport'
            await axios.post(url,ObjServiceCollection).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) { setTable(data.Data); }
                    else { setTable([]); }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetReportData = async (fromDate, toDate) => {
        const  ObjServiceCollection= {
            UserId:localStorage.getItem('LoggedInUser'),
            FromDate:fromDate,
            ToDate:toDate,
            CentreId:formik.values.CentreSelect 
            
        };      
        setDownloadVisible(false);
        try {
            let url = URL_Start + 'reports/getuserreconcilationreport'
            await axios.post(url,ObjServiceCollection).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        setDownloadVisible(true);
                        setCSVData(data.Data);
                        document.getElementById("csvdownload").click();
                    }
                    else { setCSVData([]); }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const handleSearch = async () => {
            setCrntPageNO(0);
            BindGrid(fromDate.current.value, toDate.current.value);
    }
    const handleDownload = async () => {
        setCrntPageNO(0);
        GetReportData(fromDate.current.value, toDate.current.value);
    }
    const GetBindCenterAllLists = async () => {
        const ObjFilterCentye = [];
        const requestOptions = {
            CentreFilterType: "2"//filtertype
        };
        var glblArr1 = [];
        glblArr1.push({ 'value': '0', 'label': 'ALL CENTRE', 'disabled': false });
        try {
            // let url = URL_Start + 'master/getBindAllCentreLists'
            let url = URL_Start + 'master/getBindAllCentreListsNew'
            // await axios.post(url, ObjFilterCentye).then((response) => {
            await axios.post(url,requestOptions).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        let ds = data.Data;
                        var itmData = data.Data;
                        itmData.map((option) => { return glblArr1.push({ 'value': option.CentreId, 'label': option.CentreName, 'disabled': false }); });
                        SetSelectCentreDDL(glblArr1);
                    }
                    else { SetSelectCentreDDL([]); }
                }
            }).catch(function (error) {
                SetSelectCentreDDL([]);
            });
        }
        catch (error) {
            SetSelectCentreDDL([]);
            SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const TypeChangeHandler1 = (options) => { formik.values.CentreSelect = options.value; setTypeSelected1(options.value); }
    return (
        <React.Fragment>
            <Breadcrumb title="User Recon" parent="Reports" />
            <Container fluid>
                <Row className='mt25'>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row className='justify-content-center mt-4'>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>From Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="fromDate" name="fromDate" ref={fromDate} defaultValue={defaultDate} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>To Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="toDate" name="toDate" ref={toDate} defaultValue={defaultDate} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>  
                                        <Form.Group>
                                            <div className='txtMultiSelect1' id="SelectCentre">
                                                <Form.Label>Select Centre</Form.Label>

                                                <Select onChange={TypeChangeHandler1}
                                                    options={SelectCentreDDL}
                                                    value={SelectCentreDDL.filter(function (x) { return x.value === typeSelected1; })} />
                                            </div>
                                        </Form.Group>     
                                    </Col>
                                </Form.Row>
                                <Form.Row className='justify-content-center'>
                                    <Col md={5} className='topmargin30'>
                                    <Button variant='secondary' onClick={handleSearch}>Search</Button>
                                        <Button className='ml-2' variant='primary' onClick={handleDownload}>Download CSV</Button>
                                    </Col>
                                 
                                </Form.Row>
                            <Form.Row className='justify-content-center mt-4'>
                            <Col lg={12} md={12}>
                                        {
                                            <DataTables
                                                keyField='SNo'
                                                tableData={table}
                                                columns={columns}
                                                noDataIndication={NoRecordFounds}
                                                pagination={paginationFactory(paginationOptions)}
                                            />
                                        }
                                    </Col>
                            </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    downloadvisible &&
                    <CSVLink data={CSVData} filename="UserReconcilation.csv" target="_blank" id="csvdownload"></CSVLink>
                }
            </Container>
        </React.Fragment>
    )
}

export default UserReconcilation