import React, { useEffect, useState, useRef } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Breadcrumb from '../../../components/common/breadcrumb';
import DynamicDataTables from '../commonComponent/DataTable/DynamicDataTable';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ViewToolTip from '../UI/ToolTips/ViewToolTip';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip';
import EditToolTip from '../UI/ToolTips/EditToolTip';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { Typeahead } from 'react-bootstrap-typeahead';
import { MultiSelect } from 'react-multi-select-component';
import '../../../pages/admin/react-multiselect.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import SubtractToolTip from '../UI/ToolTips/SubtractToolTip';

let FlagUserCustFilterOnly = "0";

const UserAccessGroups = () => {
    const history = useHistory();
    let typeahead = useRef(null);
    let globalUserId = "0";
    const [UsersDLists, SetUsersDLists] = useState([]);
    const [SelectedAccessUser, SetSelectedAccessUser] = useState("0");
    const [SelectUser, SetSelectUser] = useState("0");
    let textSearch = useRef();
    const navigate = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isButton, setIsButton] = useState(false);
    const [isButtonSearch, setIsButtonSearch] = useState(false);
    const [IsButtonClickAddGroup, SetIsButtonClickAddGroup] = useState(false);
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const [PerUser, SetPerUser] = useState(0);
    const [ViewAccessGroups, SetViewAccessGroups] = useState(null);

    const [PageHelper, SetPageHelper] = useState({
        PageNo: '1',
        PageSize: '10',
        TotalRecord: 0,
        dsUserDlists: []

    });
    const [IsUserView, SetIsUserView] = useState(false);
    const [IsUserEdit, SetIsUserEdit] = useState(false);
    const [IsUserEditDetails, SetIsUserEditDetails] = useState(false);
    const [modal, setModal] = useState();
    const toggleModal = () => {
        setModal(!modal)

    }
    const [EditUserDetails, SetEditUserDetails] = useState(null);
    const [EditAllDetails, SetEditAllDetails] = useState(null);

    const paginationOptions = {

        totalSize: PageHelper.TotalRecord,
        sizePerPage: PageHelper.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',

        onSizePerPageChange: (sizePerPage, page, event) => {
            console.log('Size per page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            BindGrid(page, sizePerPage,textSearch.current.value.toLowerCase());
            document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
        },
        onPageChange: (page, sizePerPage) => {
            console.log('Page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            BindGrid(page, sizePerPage,textSearch.current.value.toLowerCase());
        }
    };
    const actionExistingFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <ViewToolTip title="View" onClick={() => ViewHandler(rowData.PageAccessGroupId)} />
                {/* {rowData.IsAlreadyHaveDB == "True" ? <EditToolTip title="Edit" onClick={() => editHandler(rowData.PageAccessGroupId, rowData.ModuleId)} /> : null} */}
                <SubtractToolTip title="Remove" placement="top" id={rowData.Id} onClick={(e) => UserAccessGroupDelete(rowData.PageAccessGroupId, rowData.AccessGroupName, rowData.ModuleId,
                    rowData.IsAlreadyHaveDB, rowData.LocationCities, rowData.LocationCityIds, rowData.LocationDistrictIds, rowData.LocationDistricts,
                    rowData.LocationRegionIds, rowData.LocationRegions, rowData.LocationStateIds, rowData.LocationStates, rowData.LocZoneId, rowData.CentreIds, rowData.UserId
                )} />
            </div>
        </React.Fragment>
    }
    const LocationExistsViewFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <p><strong>Location States:</strong> {rowData.LocationStates}</p>
                <p><strong>Location Regions:</strong> {rowData.LocationRegions}</p>
            </div>
        </React.Fragment>
    }
    // const ViewFormatter = (cellData, rowData, rowIndex, extraData) => {
    //     return <React.Fragment>
    //         <div >
    //             <ViewToolTip title="View" onClick={() => ViewHandler(rowData.UserId)} />
    //             <EditToolTip title="Update SAP Code" onClick={() => editHandler(rowData.UserId)} />
    //         </div>
    //     </React.Fragment>
    // }

    const columns = [{
        dataField: '#',
        text: '#',
        formatter: (cell, row, rowIndex) => {
            let rowNumber = (PageHelper.PageNo - 1) * PageHelper.PageSize + (rowIndex + 1);
            return <span>{rowNumber}</span>;
        },
        headerStyle: (colum, colIndex) => {
            return { width: '5%' };
        }

    },
    {
        dataField: 'UserName',
        text: 'UserName',
        editable: false

    },
    {
        dataField: 'Email',
        text: 'Email',
        editable: false
    },
    // {
    //     dataField: 'ModuleName',
    //     text: 'Module Name',
    //     editable: false
    // },
    {
        dataField: 'AccessGroupName',
        text: 'Access Group',
        editable: false
    },
    {
        dataField: 'CentreNames',
        text: 'Centre Names',
        editable: false
    },
    {

        text: 'Location',
        editable: false,
        formatter: LocationExistsViewFormatter
    }
        ,
    {
        dataField: 'LocationRegions',
        text: 'Location Regions',
        editable: false
    },
    { dataField: 'actions', isDummyField: true, text: 'Actions', editable: false, formatter: actionExistingFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];

    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        asyncFunUsersDetails("0");
        BindGrid(1, 10);
    }, []);

    const BindGrid = async (PageNo, PageSize) => {
        setIsLoading(true);
        try {

            let url = URL_Start + 'users/GetBindUserAccessGroupMappingList';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { PageNo: `${PageNo}`, PageSize: `${PageSize}`, UserId: `${SelectedAccessUser}`,SearchTxt:textSearch.current.value.toLowerCase()}
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;

                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {

                        await SetPageHelper({
                            ...PageHelper,
                            PageNo: Number(PageNo),
                            PageSize: Number(PageSize),
                            TotalRecord: Number(ds.TotalRecord),
                            dsUserDlists: ds.UserGroupsMappingList == null ? [] : ds.UserGroupsMappingList
                        });

                        setIsLoading(false);
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');
                    setIsLoading(false);
                }

            }
            else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
            // return data;
        } catch (error) {
            if (error.response) {
                ErrorFunction(error.response.status);
                setIsLoading(false);
            }

        }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    const RemoveGroup = async (PageAccessGroupId, AccessGroupName, ModuleId, IsAlreadyHaveDB, LocationCities, LocationCityIds, LocationDistrictIds, LocationDistricts,
        LocationRegionIds, LocationRegions, LocationStateIds, LocationStates, LocZoneId, OrgCentreIds, UserId) => {        
        try {
            OrgCentreIds = Array.from(new Set(OrgCentreIds.split(','))).toString();
            LocationRegionIds = Array.from(new Set(LocationRegionIds.split(','))).toString();
            LocationStateIds = Array.from(new Set(LocationStateIds.split(','))).toString();
            LocationCityIds = Array.from(new Set(LocationCityIds.split(','))).toString();
            LocationDistrictIds = Array.from(new Set(LocationDistrictIds.split(','))).toString();
            console.log(OrgCentreIds);
            let url = URL_Start + 'users/DeleteUserAccessGroup';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { PageAccessGroupId: `${PageAccessGroupId}`, ModuleId: `${ModuleId}`, UserId: `${UserId}`, CentreIds: `${OrgCentreIds}`, LocationRegionIds: `${LocationRegionIds}`, LocationStateIds: `${LocationStateIds}`, LocationCityIds: `${LocationCityIds}`,LocationDistrictIds:`${LocationDistrictIds}`,AddedBy:`${LoginId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;

                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds =="1") {

                     SweetAlert.fire({ title: "Success!", text: "User Access Group Deleted successfully!", icon: "success" });
                     BindGrid(1, 10);

                    }
                    else{ SweetAlert.fire('User Access Group Deleted failed due to some issue please try later');}
                }
                else {
                    let Errtext = <p><div className='text-required'> not remove User Access Group </div></p>;
                }

            }
            else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
            // return data;
        } catch (error) {
            if (error.response) {
                ErrorFunction(error.response.status);
            }

        }

    }

    const UserAccessGroupDelete = (PageAccessGroupId, AccessGroupName, ModuleId, IsAlreadyHaveDB, LocationCities, LocationCityIds, LocationDistrictIds, LocationDistricts,
        LocationRegionIds, LocationRegions, LocationStateIds, LocationStates, LocZoneId, OrgCentreIds, UserId) => {
        SweetAlert.fire({
            title: 'Are you sure?',
           // text: "Status will be updated",
           text: "Record will be Deleted",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        })
            .then((result) => {
                if (result.value && result.isConfirmed === true) {

                    RemoveGroup(PageAccessGroupId, AccessGroupName, ModuleId, IsAlreadyHaveDB, LocationCities, LocationCityIds, LocationDistrictIds, LocationDistricts,
                        LocationRegionIds, LocationRegions, LocationStateIds, LocationStates, LocZoneId, OrgCentreIds, UserId);
                }
                else {
                    SweetAlert.fire(
                        'Your data is safe!'
                    )
                }
            });
    }

    const CustomlistHandler = () => {
        //asyncFunhrmsSyncUsersList();
    }

    const asyncFunUsersDetails = async (GlbUserPlatform) => {
        try {
            setIsButton(true);
            let url = URL_Start + 'master/getUsersfilterdetails';
            let options = {
                method: 'POST', url: url,
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
                data: {
                    UserPlatformId: `${GlbUserPlatform}`, SearchTxt: `${""}`, FlagUserCustFilterOnly: `${FlagUserCustFilterOnly}`,
                    PartnerTypeId: `${0}`
                }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) {
                        setIsButton(false);
                        const TypeOptionDs = [];
                        ds.map((option) => {
                            const TypeOptions = { 'id': option.UserId, 'name': option.CustUserName }
                            return TypeOptionDs.push(TypeOptions);
                        });
                        SetUsersDLists(TypeOptionDs);
                    }
                    else { SetUsersDLists([]); setIsButton(false); }
                }
                else {
                    SetUsersDLists([]);
                    setIsButton(false);
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                }
            }
            else {
                setIsButton(false);
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
            }
        } catch (error) {
            setIsButton(false);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }


    const ViewHandler = (PageAccessGroupId) => {

        setModal(true);
        setErrorModal(false);
        SetIsUserView(true);
        BindAccessGroupUID(PageAccessGroupId)
    }

    async function BindAccessGroupUID(PageAccessGroupId) {
        try {

            let url = URL_Start + 'users/getpageAccessGroupByAcesId';
            let options = {
                method: 'POST', url: url, headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
                data: { PageAccessGroupId: `${PageAccessGroupId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        setErrorModal(false);
                        await SetViewAccessGroups(ds);
                    }
                }
                else { let Errtext = <p><div className='text-required'> no record found </div></p>; }
            }
            else {
                setErrorModal(false);
                SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false);
            }
        } catch (error) {
            if (error.response) { setErrorModal(false); ErrorFunction(error.response.status); }
        }
    }

    /********** Delete Confirm Alert  ***********/
    const Displayalert = (UserId) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        })
            .then((result) => {
                if (result.value && result.isConfirmed === true) {

                    UserDeletedDetailByUID(UserId);

                }
                else {
                    SweetAlert.fire(
                        'Your data is safe!'
                    )
                }
            })
    }

    async function UserDeletedDetailByUID(UserId) {
        try {

            let url = URL_Start + 'users/DeleteUsersDetailbyUID';

            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { AddedBy: `${LoginId}`, UserId: `${UserId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (data.Success == true && data.Data == "2000") {
                        SweetAlert.fire({ title: "Success!", text: "User has been deleted!", icon: "success" });
                        BindGrid(1, 10);
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                    console.log('no record found');
                }


            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
            }
            // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }


    const [errorModal, setErrorModal] = useState();
    const editHandler = async (UserId) => {
        formikUsers.resetForm();
        formikUsers.values.sapcode = "";
        formikUsers.values.vendorCode = "";
        formikUsers.values.cashcode = "";
        formikUsers.values.UserId = "";
        setErrorModal(false);
        EditBinduserDetailsAll(UserId);
        SetIsUserView(false);
    }
    async function EditBinduserDetailsAll(UserId) {
        try {
            let url = URL_Start + 'users/getuserdetailsbyUID';
            let options = {
                method: 'POST', url: url,
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
                data: { UserId: `${UserId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        formikUsers.values.UserId = UserId;
                        if (ds.SAPCode != null && ds.SAPCode != '') { formikUsers.values.sapcode = ds.SAPCode; }
                        if (ds.CashCode != null && ds.CashCode != '') { formikUsers.values.cashcode = ds.CashCode; }
                        if (ds.VendorCode != null && ds.VendorCode != '') { formikUsers.values.vendorCode = ds.VendorCode; }
                    }
                    await SetEditAllDetails(ds);
                    setModal(true);
                    SetIsUserEditDetails(true);
                    SetIsUserEdit(false);
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');
                }
            }
            else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
        } catch (error) { if (error.response) { ErrorFunction(error.response.status); } }
    }

    /********** Validations  ***********/
    const formikEdit = useFormik({
        initialValues: {
            txtmobile: '',
            txtPassword: '',
            txtusername: '',
            txtemail: '',
            UserId: '0'
        },
        validationSchema: Yup.object({

            txtPassword: Yup.string().required('Password is Required')
        }),
        isSubmitting: true,
        onSubmit: async (values) => {
            try {
                let url = URL_Start + 'users/UpdatedUsersDetailbyUID';

                let options = {
                    method: 'POST',
                    url: url,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                    data: { UserId: `${values.UserId}`, AddedBy: `${LoginId}`, MobileNo: `${values.txtmobile}`, Password: `${values.txtPassword}` }
                };

                let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                    let data = response.data;
                    // let data = await response.data;
                    if (data.Success == true) {
                        let ds = data.Data;
                        if (data.Success == true && data.Data == "2000") {
                            setModal(false);
                            SetIsUserView(false);
                            SetIsUserEdit(false);
                            SweetAlert.fire({ title: "Success!", text: "User has been updated!", icon: "success" });
                            BindGrid(1, 10);
                        }
                    }
                    else {
                        setModal(false);
                        SetIsUserView(false);
                        SetIsUserEdit(false);
                        let Errtext = <p><div className='text-required'> no record found </div></p>;
                        SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                        console.log('no record found');
                    }

                }
                else {
                    console.log('no record found');

                }
                // return data;
            } catch (error) {
                setModal(false);
                SetIsUserView(false);
                SetIsUserEdit(false);
                console.log(error.message);
                SweetAlert.fire({ title: "Error", text: 'There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.', icon: "error" });
            }
        },
    });

    /********** Validations  ***********/
    const formikUsers = useFormik({
        initialValues: { sapcode: '', cashcode: '', vendorCode: '', UserId: '0' },
        validationSchema: Yup.object({
            sapcode: Yup.string().required('Customer Code Required'),
            cashcode: Yup.string().required('Cash Code Required'),
            vendorCode: Yup.string().required('Vendor Code Required'),
        }),
        isSubmitting: true,
        onSubmit: async (values, actions) => {
            try {
                setModal(false);
                setErrorModal(false);
                SetIsUserView(false);
                let url = URL_Start + 'Users/UpdateUSERSAPCodeDetails';
                let options = {
                    method: 'POST',
                    url: url,
                    headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
                    data: { SAPCode: `${values.sapcode}`, CashCode: `${values.cashcode}`, VendorCode: `${values.vendorCode}`, AddedBy: `${LoginId}`, UserId: `${values.UserId}` }
                };
                let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                    let data = response.data;
                    if (data.Success == true && data.Data == "2000") {
                        formikUsers.resetForm();
                        SweetAlert.fire({ title: "Success!", text: "SAP Code and Cash Code been Updated!", icon: "success" });
                        setErrorModal(false);
                    }
                    else {
                        if (data.ErrorList != null && data.ErrorList.length > 0) {
                            let Errtext = <p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                            SweetAlert.fire({ title: "Error", text: data.ErrorList[0].errorMsg, icon: "error" });
                        }
                    }
                }
                else { console.log('no record found'); }
            } catch (error) {
                console.log(error.message);
                let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
            }
            setTimeout(() => { actions.setSubmitting(false); }, 1000);
        },
    });
    const updateUserHandler = async () => {
        setModal(true);
        setErrorModal(true);
        SetIsUserEdit(false);
        formikUsers.handleSubmit();
    }
    const userGroupChangeHandler = (option) => {
        // globaltblExistGroups = [];
        // globaltblAllGroups = [];
        const value = (option.length > 0) ? option[0].name : '';
        const idval = (option.length > 0) ? option[0].id : '';
        //formik.setFieldValue('Users', idval);
        const TypeOpt = [];
        option.map((item) => {
            const TypeOptrows = { 'UserId': item.id }
            return TypeOpt.push(TypeOptrows);
        });
        const s1 = idval;
        SetSelectedAccessUser(idval);
    }
    const OnSearchHandler = async () => {
        BindGrid(1, 10,textSearch.current.value.toLowerCase());
    }
    const OnAddAccessGroupHandler = async () => {
        history.push({ pathname: `${process.env.PUBLIC_URL}/user-master/addAccessGroupsMapping`, search: `` });

    }
    return (
        <React.Fragment>
            <Breadcrumb title="User Access Groups" parent="User Master" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>

                                <Form.Row className='justify-content-center form-row align-items-center'>
                                    <Col lg={6} md={6} className="col-12">
                                        <Form.Group>

                                            <Form.Label>HRMS Users<span className='text-required'>*</span></Form.Label>
                                            {isButton ? <Spinner animation="border" size='sm' /> : null}
                                            <Typeahead
                                                id="Users" name="Users" defaultSelected={UsersDLists.slice(0, 5)} labelKey="name" ref={typeahead} options={UsersDLists}
                                                value={SelectUser}
                                                onChange={userGroupChangeHandler}
                                                placeholder="---Select User---" />
                                        </Form.Group>
                                    </Col>
                                    <Col lg={6} md={6} className="col-12">
                                        <Form.Group className="form-group mb-3">
                                        <Form.Label></Form.Label>
                                        <InputGroup>
                                            <Form.Control onChange={OnSearchHandler} placeholder="Search by Module Name Or Access Group ..." ref={textSearch} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>

                                </Form.Row>

                                <Row className='justify-content-center mt15'>
                                    <Col md={12} className='text-center'>
                                        <Button variant='primary' type='submit' onClick={OnSearchHandler} ><i className='fa fa-search'></i>{isButtonSearch ? <Spinner animation="border" size='sm' /> : null} Search </Button>
                                        &nbsp; {<Button variant='primary' type='submit' onClick={OnAddAccessGroupHandler} >{IsButtonClickAddGroup ? <Spinner animation="border" size='sm' /> : null} Add Access Group Mapping </Button>}
                                    </Col>
                                </Row>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>User Access Groups List</Card.Title>
                            </Card.Header>
                            <Card.Body>

                                {
                                    isLoading &&
                                    <div className='text-center'> <Spinner animation="border" size='sm' /></div>
                                }
                                <DynamicDataTables keyField="UserIdUserGroupRelId" tableData={PageHelper.dsUserDlists} columns={columns} pagination={paginationOptions ? paginationFactory(paginationOptions) : null} />

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {
                IsUserView &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true} size="lg">
                    <ModalHeader toggle={toggleModal}>View Access Group Detail</ModalHeader>
                    <ModalBody>
                        {ViewAccessGroups != null ? <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <Form.Row className="justify-content-left">
                                            {ViewAccessGroups.map((item) => {
                                                return (
                                                    <>
                                                        <Col lg={6} md={6}>
                                                            <span className="form-control-plaintext" >{item.GroupPageNames == '' ? 'N/A' : item.GroupPageNames}</span>
                                                        </Col>
                                                    </>
                                                )

                                            })}
                                        </Form.Row>

                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row> : null

                        }

                    </ModalBody>
                    <ModalFooter>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            }

            {errorModal &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                    <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                    <ModalBody>
                        <p>{formikUsers.touched.sapcode && formikUsers.errors.sapcode ? (<div className='text-required'>{formikUsers.errors.sapcode}</div>) : null}</p>
                        <p>{formikUsers.touched.cashcode && formikUsers.errors.cashcode ? (<div className='text-required'>{formikUsers.errors.cashcode}</div>) : null}</p>
                        <p>{formikUsers.touched.vendorCode && formikUsers.errors.vendorCode ? (<div className='text-required'>{formikUsers.errors.vendorCode}</div>) : null}</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                        <Button variant="primary" onClick={toggleModal}>Ok</Button>
                    </ModalFooter>
                </Modal>
            }
        </React.Fragment>

    )
}

export default UserAccessGroups;