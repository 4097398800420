import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { URL_Start } from '../../../constant';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import DataTable from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CSVLink } from "react-csv";
import InputGroup from 'react-bootstrap/InputGroup';
import { MultiSelect } from 'react-multi-select-component';
import '../../../pages/admin/react-multiselect.css';

const UnpaidBills = () => {
    const [tableData, setTableData] = useState();
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [CSVData, setCSVData] = useState([]);
    const [defaultDate, setDefaultDate] = useState("");
    const [SelectCentreDDL, SetSelectCentreDDL] = useState([]);
    const [SelectCentres, SetSelectCentres] = useState([]);
    const [LGlCentreTypeoption, SetLGlCentreTypeoption] = useState([]);

    let fromDate = useRef();
    let toDate = useRef();

    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        GetBindCenterAllLists();
    }, []);
    const paginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const columns = [{ dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    { dataField: 'OrderNo', text: 'Order No', editable: false },
    { dataField: 'VisitNo', text: 'Visit No', editable: false },
    { dataField: 'CentreName', text: 'Centre Name', editable: false },
    { dataField: 'OrderAddedBy', text: 'Order Added By', editable: false },
    { dataField: 'OrderDate', text: 'Order Date', editable: false },
    { dataField: 'TotalOrderAmount', text: 'Order Amount', editable: false },
    { dataField: 'CreditApprovedBy', text: 'Approved By', editable: false },
    { dataField: 'CreditApprovedAmount', text: 'Approved Amount', editable: false },
    { dataField: 'PendingSince', text: 'Pending Since', editable: false }
    ];
    const BindGrid = async (fromDateSelected, toDateSelected) => {
        if (fromDateSelected == undefined || fromDateSelected == null || fromDateSelected == "") { fromDateSelected = "0" }
        if (toDateSelected == undefined || toDateSelected == null || toDateSelected == "") { toDateSelected = "0" }
        if (fromDateSelected == "0" && toDateSelected != "0") { SweetAlert.fire("Please enter from order date"); return false; }
        if (fromDateSelected != "0" && toDateSelected == "0") { SweetAlert.fire("Please enter to order date"); return false; }
        if (LGlCentreTypeoption.length < 1) { SweetAlert.fire("Please select centre"); return false; }
        const ObjCollection = {
            UserId: localStorage.getItem('LoggedInUser'),
            FromDate: fromDateSelected,
            ToDate: toDateSelected,
            objtblCentreIds: LGlCentreTypeoption
        };
        try {
            let url = URL_Start + 'reports/creditapprovedunpaidbills/'
            await axios.post(url, ObjCollection).then((response) => {
                const data = response.data;
                if (data.Success) { if (data.Data != null) { setTableData(data.Data); } else { setTableData([]); } }
                else { setTableData([]); SweetAlert.fire("Some db error occured.Please report to support team."); }
            }).catch(function (error) { if (error.response) { setTableData([]); ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetReportData = async (fromDateSelected, toDateSelected, loginid) => {
        if (fromDateSelected == undefined || fromDateSelected == null || fromDateSelected == "") { fromDateSelected = "0" }
        if (toDateSelected == undefined || toDateSelected == null || toDateSelected == "") { toDateSelected = "0" }
        if (fromDateSelected == "0" && toDateSelected != "0") { SweetAlert.fire("Please enter from order date"); return false; }
        if (fromDateSelected != "0" && toDateSelected == "0") { SweetAlert.fire("Please enter to order date"); return false; }
        if (LGlCentreTypeoption.length < 1) { SweetAlert.fire("Please select centre"); return false; }
        setDownloadVisible(false);
        const ObjCollection = {
            UserId: localStorage.getItem('LoggedInUser'),
            FromDate: fromDateSelected,
            ToDate: toDateSelected,
            objtblCentreIds: LGlCentreTypeoption
        };
        try {
            let url = URL_Start + 'reports/creditapprovedunpaidbills/'
            await axios.post(url, ObjCollection).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) { setDownloadVisible(true); setCSVData(data.Data); document.getElementById("csvdownload").click(); }
                    else { setCSVData([]); }
                }
                else { setCSVData([]); }
            }).catch(function (error) { if (error.response) { setCSVData([]); setTableData([]); ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const handleSearch = async () => {
        BindGrid(fromDate.current.value, toDate.current.value);
    }
    const handleDownload = async () => {
        GetReportData(fromDate.current.value, toDate.current.value);
    }
    const GetBindCenterAllLists = async () => {
        try {
            let url = URL_Start + 'master/getBindAllCentreListsAdmin/1'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        let ds = data.Data;
                        const TypeOptionDs1 = [];
                        ds.map((option) => {
                            const TypeOptions1 = {
                                'CentreId': option.CentreId,
                                'label': option.CentreName,
                                'value': option.CentreId,
                                'CenterTypeId': option.CenterTypeId,
                                'disabled': false
                            }
                            return TypeOptionDs1.push(TypeOptions1);
                        });
                        SetSelectCentreDDL(TypeOptionDs1);
                    }
                    else { SetSelectCentreDDL([]); }
                }
            }).catch(function (error) { if (error.response) { SetSelectCentreDDL([]); ErrorFunction(error.response.status); } });
        }
        catch (error) {
            SetSelectCentreDDL([]);
            SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const CentreHandler = (options) => {
        SetSelectCentres(options);
        const TypeOptL = [];
        if (options != null && options.length > 0) {
            options.map((item) => {
                const TypeOptrows = {
                    'CentreId': item.CentreId
                }
                return TypeOptL.push(TypeOptrows);
            });
            SetLGlCentreTypeoption(TypeOptL);
        }
        else { SetLGlCentreTypeoption([]); }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    return (
        <>
            <React.Fragment>
                <Breadcrumb title="Unpaid Bills" parent="Reports" />
                <Container fluid>
                    <Row className='mt25'>
                        <Col md={12}>
                            <Card>
                                <Card.Body>
                                    <Form.Row className='justify-content-center mt-4'>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>From Order Date</Form.Label>
                                                <InputGroup className="mb-3 d-flex justify-content-start">
                                                    <Form.Control type="date" id="fromDate" name="fromDate" ref={fromDate} defaultValue={defaultDate} max={defaultDate} />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>To Order Date</Form.Label>
                                                <InputGroup className="mb-3 d-flex justify-content-start">
                                                    <Form.Control type="date" id="toDate" name="toDate" ref={toDate} defaultValue={defaultDate} max={defaultDate} />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}></Col>
                                    </Form.Row>
                                    <Form.Row className='justify-content-center'>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>Centre</Form.Label>
                                                <div className='txtMultiSelect1'>
                                                    <MultiSelect id="CentreSelect" name="CentreSelect"
                                                        options={SelectCentreDDL}
                                                        value={SelectCentres}
                                                        onChange={CentreHandler}
                                                        labelledBy="Select"
                                                    /></div>
                                            </Form.Group>
                                        </Col>
                                        <Col md={3} className='topmargin30'>
                                            <Button variant='secondary' onClick={handleSearch}>Search</Button>
                                            <Button className='ml-2' variant='primary' onClick={handleDownload}>Download CSV</Button>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className='justify-content-center mt-4'>
                                        <Col lg={12} md={12}>
                                            {
                                                <DataTable
                                                    keyField='OrderId'
                                                    tableData={tableData}
                                                    columns={columns}
                                                    noDataIndication={NoRecordFounds}
                                                    pagination={paginationOptions ? paginationFactory(paginationOptions) : null}
                                                />
                                            }
                                        </Col>
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    {
                        downloadvisible &&
                        <CSVLink data={CSVData} filename="Unpaid-Bills.csv" target="_blank" id="csvdownload"></CSVLink>
                    }
                </Container>
            </React.Fragment>
        </>
    )
}

export default UnpaidBills