import React, { Fragment, useState, useEffect } from 'react';
import man from '../../../assets/images/dashboard/NoImage.jpg';
import { User, Mail, Lock, Settings, LogOut } from 'react-feather';
import { firebase_app } from "../../../data/config";
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { useAuth0 } from '@auth0/auth0-react'
import { EditProfile, Inbox, LockScreen } from '../../../constant'

const UserMenu = ({ history }) => {

    const [profile, setProfile] = useState('');
    const authenticated = JSON.parse(localStorage.getItem("authenticated"))
    const auth0_profile = JSON.parse(localStorage.getItem("auth0_profile"))

    useEffect(() => {
        setProfile(localStorage.getItem('profileURL') || man);
    }, []);

    const Logout = () => {
        localStorage.removeItem("auth0_profile")
        localStorage.setItem("authenticated", false)
        localStorage.setItem('Session', "");
        history.push(`${process.env.PUBLIC_URL}/login`)
    }
    return (
        <Fragment>
            {/* <li className="onhover-dropdown">
                <div className="media align-items-center">
                    <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={authenticated ? auth0_profile.picture : profile} alt="header-user" />
                </div>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                    <li><a onClick={Logout} href="javascript:void(0)" ><LogOut /> {"Log out"}</a></li>
                </ul>
            </li> */}

            <li className="onhover-dropdown">
                <div className="media align-items-center">
                    <img className="align-self-center pull-right img-50 rounded-circle blur-up lazyloaded" src={profile} alt="header-user" />
                </div>
                <ul className="profile-dropdown onhover-show-div p-20 profile-dropdown-hover">
                    <li className='text-center'>
                        <h6 className="mt-3 f-14">{localStorage.UserName}</h6>
                        {/* <p>{UserTypeName}</p> */}
                    </li>
                    <li><a onClick={Logout} href="javascript:void(0)"  ><LogOut /> {"Log out"}</a></li>
                </ul>
            </li>
        </Fragment>
    );
};


export default withRouter(UserMenu);