import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import SweetAlert from 'sweetalert2';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import LoadingButton from '../commonComponent/LoadingButton';
import DataTables from '../commonComponent/DataTable/DataTables';
import paginationFactory from 'react-bootstrap-table2-paginator';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import ToolTip from '../commonComponent/ToolTip';
import Select from 'react-select';
let routeLocationList = [];
const RouteMaster = () => {
    const [statesList, setStatesList] = useState([]);
    const [DistrictList, setDistrictList] = useState([]);
    const [SelectedLocationList, setSelectedLocationList] = useState([]);
    const [routedetails, setRoutedetails] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [RouteID, setRouteID] = useState("0");
    const [RouteName, setRouteName] = useState('');
    const [RouteDesc, setRouteDesc] = useState('');
    const [StateID, setStateID] = useState(0);
    let refStateID = useRef();
    let refRouteName = useRef();
    let refRouteDesc = useRef();
    useEffect(() => {
        GetStates(1);
        GetRouteList();
    }, []);
    const StateChangeHandler = (e) => {
        const val = e.target.value;
        setStateID(e.target.value);
        if (e.target.value !== "0") { GetDistricts(e.target.value); } else { setDistrictList([]); }
    }
    const GetStates = async (id) => {
        try {
            let url = URL_Start + 'master/getstateslist/' + id
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setStatesList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetDistricts = async (id) => {
        try {
            let url = URL_Start + 'master/getlocationlist/' + id;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) {
                        const TypeOptionDs = [];
                        ds.map((option) => {
                            const TypeOptions = { 'label': option.Value, 'value': option.Id, 'DistrictID': option.Id }
                            return TypeOptionDs.push(TypeOptions);
                        });
                        setDistrictList(TypeOptionDs);
                    }
                    else { setDistrictList([]); }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const SaveRouteHandler = async () => {
        if (refRouteName.current.value == '' || refRouteName.current.value == '0') {
            SweetAlert.fire({ title: "Error", text: "Please enter route name", icon: "error" });
        }
        else if (SelectedLocationList.length == 0) {
            SweetAlert.fire({ title: "Error", text: "Please select at least one location", icon: "error" });
        }
        else {
            SaveRouteDetails(refRouteName.current.value, refRouteDesc.current.value, SelectedLocationList);
        }
    }
    const SaveRouteDetails = async (RouteName, RouteDesc, LocationSelected) => {
        let arryLocations = [];
        for (var i in LocationSelected) { arryLocations.push({ 'DistrictID': LocationSelected[i].value, 'DistrictName': LocationSelected[i].label }); }
        setIsSaving(true);
        const requestOptions = {
            RouteID: RouteID,
            RouteName: RouteName,
            RouteDesc: RouteDesc,
            locations: arryLocations,
            AddedBy: localStorage.LoggedInUser
        };
        let url = URL_Start + 'master/saveroutemasterdetails'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                setIsSaving(false);
                if (RouteID !== "0") { SweetAlert.fire("Route updated successfully"); }
                else { SweetAlert.fire("Route saved successfully"); }
                setRouteID("0");
                setRouteName('');
                setRouteDesc('');
                setStateID(0);
                routeLocationList = [];
                setSelectedLocationList([]);
                GetStates(1);
                GetRouteList();
            }
            else {
                if (data.Data == "-1998") { setIsSaving(false); SweetAlert.fire("Route already exists"); }
                else { setIsSaving(false); SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); }
            }
        }).catch(function (error) { setIsSaving(false); });
    }
    const GetRouteList = async () => {
        let url = URL_Start + 'master/getroutemasterlist/'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data != null) {
                    setRoutedetails(data.Data);
                }
                else {
                    setRoutedetails([]);
                }
            }
            else {
                setRoutedetails([]);
            }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const paginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <span>{crntPageNO + rowIndex + 1}</span>
        </React.Fragment>
    }
    const DescFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div style={{ wordWrap: 'break-word', textWrap:'wrap' }}>
                {
                    rowData.RouteDesc
                }
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <ToolTip title="Edit" placement="top" onClick={() => EditRouteDetails(rowData)} linkId="Edit" faName="fa fa-pencil mr-2"></ToolTip>
                <ToolTip title="Delete" placement="top" onClick={() => DeleteRoute(rowData.RouteID)} linkId="Delete" faName="fa fa-trash mr-2"></ToolTip>
            </div>
        </React.Fragment>
    }
    const columns =
        [
            { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '3%' }; } },
            { dataField: 'RouteName', text: 'Route Name', editable: false },
            { dataField: 'RouteDesc', text: 'Route Description', formatter: DescFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '50%', textWrap:'wrap'}; } },
            { dataField: 'StateName', text: 'State Name', editable: false },
            { dataField: 'Locations', text: 'Location', editable: false },
            { dataField: 'actions', isDummyField: true, text: 'Actions', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
        ];
    const EditRouteDetails = async (rowData) => {
        const requestOptions = {
            RouteID: rowData.RouteID
        };
        let url = URL_Start + 'master/getroutemasterdetailsbyid'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                let ds = data.Data;
                routeLocationList = [];
                if (ds != null && ds.length > 0) {
                    setRouteName(rowData.RouteName);
                    setRouteDesc(rowData.RouteDesc);
                    setStateID(rowData.StateID);
                    GetDistricts(rowData.StateID);
                    ds.map((option) => {
                        const TypeOptions = { 'label': option.Location, 'value': option.LocationID, 'DistrictID': option.LocationID }
                        return routeLocationList.push(TypeOptions);
                    });
                    setSelectedLocationList(routeLocationList);
                }
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); }

        }).catch(function (error) { });
        setRouteID(rowData.RouteID);
    }

    const DeleteRoute = async (RouteID) => {
        setRouteID("0");
        setRouteName('');
        setRouteDesc('');
        setStateID(0);
        GetStates(1);
        setSelectedLocationList([]);
        SweetAlert.fire({
            title: 'Warning',
            text: "You are going to delete an item. Are you sure?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            cancelButtonColor: "#000",
            reverseButtons: true
        })
            .then((result) => {
                if (result.value) { ConfirmedDeleteRoute(RouteID); }
                else { SweetAlert.fire('Your data is safe!') }
            })
    }
    const ConfirmedDeleteRoute = async (RouteID) => {
        const requestOptions = {
            RouteID: RouteID,
            AddedBy: localStorage.LoggedInUser
        };
        let url = URL_Start + 'master/deleteroutemaster'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                SweetAlert.fire({
                    text: "Deleted",
                    confirmButtonText: 'Ok',
                    reverseButtons: true
                })
                    .then((result) => {
                        if (result.value) { GetRouteList(); cancelHandler(); }
                        else { GetRouteList(); cancelHandler(); }
                    })
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsSaving(false); }
        }).catch(function (error) { setIsSaving(false); });
    }
    const cancelHandler = async () => {
        setRouteID("0");
        setRouteName('');
        setRouteDesc('');
        setStateID(0);
        routeLocationList = [];
        setSelectedLocationList([]);
        GetStates(1);
        GetRouteList();
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    const LocationSelectionHandler = (options) => {
        setSelectedLocationList(options);
    }

    return (
        <React.Fragment>
            <Breadcrumb title="Route Master" parent="Master" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Form.Row className='justify-content-center'>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Route Name <span className='text-required'>*</span></Form.Label>
                                    <Form.Control type="text" name="RouteName" onChange={(e) => setRouteName(e.target.value)} value={RouteName} ref={refRouteName} />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Route Description <span className='text-required'>*</span></Form.Label>
                                    <textarea className="form-control" id="RouteDesc" name="RouteDesc" rows="3" onChange={(e) => setRouteDesc(e.target.value)} value={RouteDesc} ref={refRouteDesc} ></textarea>
                                    {/* <Form.Control type="textarea" name="RouteDesc" onChange={(e) => setRouteDesc(e.target.value)} value={RouteDesc} ref={refRouteDesc} /> */}
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>State <span className='text-required'>*</span></Form.Label>
                                    <Form.Control as="select" name="sapStateId" ref={refStateID} value={StateID} onChange={(e) => StateChangeHandler(e)} >
                                        <option value={0} selected>Select</option>
                                        {statesList}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label>Location <span className='text-required'>*</span></Form.Label>
                                    <Select options={[...DistrictList]} isMulti={true}
                                        onChange={LocationSelectionHandler} value={SelectedLocationList}
                                    />
                                </Form.Group>
                            </Col>

                        </Form.Row>
                    </Col>
                </Row>
                <Col md={12} className='text-center'>
                    <Form.Group>
                        <Button variant='secondary' className="mr-2" onClick={cancelHandler}>Cancel</Button>
                        {isSaving ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' onClick={SaveRouteHandler} >{
                            RouteID == 0 ? 'Save' : 'Update'
                        }</Button>}
                    </Form.Group>
                </Col>
                <Form.Row>
                    <Col md={12}>
                        <DataTables keyField='RouteID' tableData={routedetails} columns={columns} pagination={paginationFactory(paginationOptions)} noDataIndication={NoRecordFounds} />
                    </Col>
                </Form.Row>
            </Container>
        </React.Fragment>
    )
}

export default RouteMaster