import React, { useState, useEffect, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import { Spinner } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import LoadingButton from '../commonComponent/LoadingButton';
import InputGroup from 'react-bootstrap/InputGroup';
import ToolTip from '../commonComponent/ToolTip';
import Select from 'react-select';
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';

const ItemWisePanelRateList = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isbuttonLoading, setIsButtonLoading] = useState(false);
    const [itemname, setItemName] = useState([]);
    const [typeSelected, setTypeSelected] = useState([]);
    const [partnertype, setPartnerType] = useState("0");
    const [templatedata, setTemplateData] = useState([]);
    const [crntPageNO, setCrntPageNO] = useState(0);

    let refpartnertype = useRef();
    let mrp = useRef();
    let partnerrate = useRef();
    let patientrate = useRef();
    useEffect(() => {
        GetAllItemsPanelList();
        GetPartnerType();
    }, [])
    const paginationOptions = {
        sizePerPage: 50,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div><span>{crntPageNO + rowIndex + 1}</span></div>
        </React.Fragment>
    }
    const actionFormatter = (column, colIndex) => {
        return <React.Fragment>
            <div>
                <span>{column.text}</span>
                &nbsp;
                <span><input type='text' ref={mrp} tabIndex="1" /></span>
                &nbsp;
                <span><i className='fa fa-refresh' onClick={() => RefreshRates(mrp.current.value, "1")}></i></span>
            </div>
        </React.Fragment>
    }
    const actionFormatter1 = (column, colIndex) => {
        return <React.Fragment>
            <div>
                <span>{column.text}</span>
                &nbsp;
                <span><input type='text' ref={partnerrate} tabIndex="1" /></span>
                &nbsp;
                <span><i className='fa fa-refresh' onClick={() => RefreshRates(partnerrate.current.value, "2")}></i></span>
            </div>
        </React.Fragment>
    }
    const actionFormatter2 = (column, colIndex) => {
        return <React.Fragment>
            <div>
                <span>{column.text}</span>
                &nbsp;
                <span><input type='text' ref={patientrate} tabIndex="1" /></span>
                &nbsp;
                <span><i className='fa fa-refresh' onClick={() => RefreshRates(patientrate.current.value, "3")}></i></span>
            </div>
        </React.Fragment>
    }
    const columns = [{ dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    { dataField: 'TemplateName', text: 'Template Name', editable: false },
    { dataField: 'RateType', text: 'Rate Type', editable: false },
    { dataField: 'MRP', text: 'MRP', editable: true, headerFormatter: actionFormatter },
    { dataField: 'PartnerRate', text: 'Partner Rate', editable: true, headerFormatter: actionFormatter1 },
    { dataField: 'PatientRate', text: 'Patient Rate', editable: true, headerFormatter: actionFormatter2 }
    ];
    const GetAllItemsPanelList = async () => {
        var glblArr = [];
        var arritems = [];
        setIsLoading(true);
        try {
            var newItemCount = 0;
            let url = URL_Start + 'master/getallitemspanellist'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsLoading(false);
                    if (data.Data != null) {
                        for (var key in data.Data) {
                            arritems.push({ 'RowUniqueId': data.Data[key].RowUniqueId, 'ItemId': data.Data[key].ItemId, 'TestName': data.Data[key].TestName, 'TestCode': data.Data[key].TestCode, 'IsPackage': data.Data[key].IsPackage });
                        }
                        var itmData = arritems;
                        itmData.map((option) => { return glblArr.push({ 'value': option.ItemId, 'label': option.TestName, 'rowuniqueid': option.RowUniqueId, 'testcode': option.TestCode, 'ispackage': option.IsPackage, 'disabled': false }); });
                        setItemName(glblArr);
                    }
                    else { setItemName([]); }
                }
                else { setIsLoading(false); setItemName([]); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsLoading(false); setItemName([]); if (error.response) { AxiosError(error.response.status); } });
        } catch (error) { setIsLoading(false); setItemName([]); APIError("-3"); }
    }
    const TypeChangeHandler = (options) => {
        var typearr = [];
        typearr.push({
            value: options.value,
            label: options.label,
            rowuniqueid: options.rowuniqueid,
            testcode: options.testcode,
            ispackage: options.ispackage,
        })
        setTypeSelected(typearr);
    }
    const GetPartnerType = async () => {
        setIsLoading(true);
        try {
            let url = URL_Start + 'master/getpartnertypelist/2/1'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsLoading(false);
                    if (data.Data != null) { setPartnerType(data.Data.map((d) => <option key={d.Id} value={d.Id}>{d.Name}</option>)) }
                    else { APIError(data.Data); }
                }
                else { setIsLoading(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
        } catch (error) { setIsLoading(false); APIError("-3"); }
    }
    const CallAlltemplates = async () => {
        if (typeSelected == [] || typeSelected == null || typeSelected.length == 0) {
            SweetAlert.fire("Please select item");
            setTemplateData([]);
            return false;
        }
        if (refpartnertype.current.value == "-1") {
            SweetAlert.fire("Please select rate type");
            setTemplateData([]);
            return false;
        }
        else {
            GetAllTemplates();
        }
    }
    const GetAllTemplates = async () => {
        setTemplateData([]);
        setIsButtonLoading(true);
        var params = typeSelected[0].value + '/' + typeSelected[0].ispackage + '/' + typeSelected[0].testcode + '/' + refpartnertype.current.value
        try {
            let url = URL_Start + 'master/getalltemplates/' + params
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsButtonLoading(false);
                    if (data.Data != null) { setTemplateData(data.Data) }
                    else { setTemplateData([]); SweetAlert.fire("No record found"); }
                }
                else { setIsButtonLoading(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsButtonLoading(false); if (error.response) { AxiosError(error.response.status); } });
        } catch (error) { setIsButtonLoading(false); APIError("-3"); }
    }
    const CallReload = async (e) => {
        e.preventDefault();
        window.location.reload(false);
    }
    const RefreshRates = async (amount, flag) => {
        const charac = /[^0-9 .']+/g
        if (amount == null || amount == undefined || amount == "") { SweetAlert.fire("Please enter amount"); return false; }
        if (charac.test(amount) == true) { SweetAlert.fire("Only numeric values allowed"); return false; }
        if (amount == ".") { SweetAlert.fire("You need to add numbers before ."); return false; }
        if (parseInt(amount.length) == parseInt(amount.indexOf('.') + 1)) { SweetAlert.fire("You need to add numbers after ."); return false; }
        var glbltemparr = [];
        var isBreak = 0;
        if (flag == "1") {
            for (var key in templatedata) {
                if (parseFloat(templatedata[key].PatientRate) > parseFloat(amount)) { isBreak = 1; }
                else if (parseFloat(templatedata[key].PartnerRate) > parseFloat(amount)) { isBreak = 2; }
                else {
                    if (isBreak == 0) {
                        glbltemparr.push({
                            Id: templatedata[key].Id,
                            MRP: amount,
                            PartnerRate: templatedata[key].PartnerRate,
                            PatientRate: templatedata[key].PatientRate,
                            RateType: templatedata[key].RateType,
                            RateTypeId: templatedata[key].RateTypeId,
                            TemplateId: templatedata[key].TemplateId,
                            TemplateName: templatedata[key].TemplateName
                        });
                    }
                }
            }
            if (isBreak == 1) { SweetAlert.fire("Patient rate must be less than or equal to MRP"); glbltemparr = []; isBreak = 0; return false; }
            else if (isBreak == 2) { SweetAlert.fire("Partner rate must be less than or equal to MRP"); glbltemparr = []; isBreak = 0; return false; }
            else { mrp.current.value = ""; isBreak = 0; }
        }
        if (flag == "2") {
            for (var key in templatedata) {
                if (parseFloat(amount) > parseFloat(templatedata[key].MRP)) { isBreak = 1; }
                else if (parseFloat(amount) > parseFloat(templatedata[key].PatientRate)) { isBreak = 2; }
                else {
                    if (isBreak == 0) {
                        glbltemparr.push({
                            Id: templatedata[key].Id,
                            MRP: templatedata[key].MRP,
                            PartnerRate: amount,
                            PatientRate: templatedata[key].PatientRate,
                            RateType: templatedata[key].RateType,
                            RateTypeId: templatedata[key].RateTypeId,
                            TemplateId: templatedata[key].TemplateId,
                            TemplateName: templatedata[key].TemplateName
                        });
                    }
                }
            }
            if (isBreak == 1) { SweetAlert.fire("Partner rate must be less than or equal to MRP"); glbltemparr = []; isBreak = 0; return false; }
            else if (isBreak == 2) { SweetAlert.fire("Partner rate must be less than or equal to Patient rate"); glbltemparr = []; isBreak = 0; return false; }
            else { partnerrate.current.value = ""; isBreak = 0; }
        }
        if (flag == "3") {
            for (var key in templatedata) {
                if (parseFloat(amount) > parseFloat(templatedata[key].MRP)) { isBreak = 1; }
                else if (parseFloat(templatedata[key].PartnerRate) > parseFloat(amount)) { isBreak = 2; }
                else {
                    if (isBreak == 0) {
                        glbltemparr.push({
                            Id: templatedata[key].Id,
                            MRP: templatedata[key].MRP,
                            PartnerRate: templatedata[key].PartnerRate,
                            PatientRate: amount,
                            RateType: templatedata[key].RateType,
                            RateTypeId: templatedata[key].RateTypeId,
                            TemplateId: templatedata[key].TemplateId,
                            TemplateName: templatedata[key].TemplateName
                        });
                    }
                }
            }
            if (isBreak == 1) { SweetAlert.fire("Patient rate must be less than or equal to MRP"); glbltemparr = []; isBreak = 0; return false; }
            else if (isBreak == 2) { SweetAlert.fire("Partner rate must be less than or equal to Patient rate"); glbltemparr = []; isBreak = 0; return false; }
            else { patientrate.current.value = ""; isBreak = 0; }
        }
        setTemplateData(glbltemparr);
        glbltemparr = [];
    }
    const CheckUpdateValue = async (oldValue, newValue, rowData, column) => {
        if (rowData.MRP == null || rowData.MRP == undefined || rowData.MRP == "") { rowData.MRP = 0; newValue = 0; }
        if (rowData.PartnerRate == null || rowData.PartnerRate == undefined || rowData.PartnerRate == "") { rowData.PartnerRate = 0; newValue = 0; }
        if (rowData.PatientRate == null || rowData.PatientRate == undefined || rowData.PatientRate == "") { rowData.PatientRate = 0; newValue = 0; }
        const charac = /[^0-9 .']+/g
        if (column.text.toLowerCase() == "mrp") {
            if (charac.test(newValue) == true) { SweetAlert.fire("Only numeric values allowed"); rowData.MRP = parseFloat(oldValue); return false; }
            if (newValue == ".") { SweetAlert.fire("You need to add numbers before ."); rowData.MRP = parseFloat(oldValue); return false; }
            if (parseInt(newValue.length) == parseInt(newValue.indexOf('.') + 1)) { SweetAlert.fire("You need to add numbers after ."); rowData.MRP = parseFloat(oldValue); return false; }
            if (parseFloat(rowData.PatientRate) > parseFloat(newValue)) { SweetAlert.fire("Patient rate must be less than or equal to MRP"); rowData.MRP = parseFloat(oldValue); return false; }
            else if (parseFloat(rowData.PartnerRate) > parseFloat(newValue)) { SweetAlert.fire("Partner rate must be less than or equal to MRP"); rowData.MRP = parseFloat(oldValue); return false; }
        }
        if (column.text.toLowerCase() == "partner rate") {
            if (charac.test(newValue) == true) { SweetAlert.fire("Only numeric values allowed"); rowData.PartnerRate = parseFloat(oldValue); return false; }
            if (newValue == ".") { SweetAlert.fire("You need to add numbers before ."); rowData.PartnerRate = parseFloat(oldValue); return false; }
            if (parseInt(newValue.length) == parseInt(newValue.indexOf('.') + 1)) { SweetAlert.fire("You need to add numbers after ."); rowData.PartnerRate = parseFloat(oldValue); return false; }
            if (parseFloat(newValue) > parseFloat(rowData.MRP)) { SweetAlert.fire("Partner rate must be less than or equal to MRP"); rowData.PartnerRate = parseFloat(oldValue); return false; }
            else if (parseFloat(newValue) > parseFloat(rowData.PatientRate)) { SweetAlert.fire("Partner rate must be less than or equal to Patient rate"); rowData.PartnerRate = parseFloat(oldValue); return false; }
        }
        if (column.text.toLowerCase() == "patient rate") {
            if (charac.test(newValue) == true) { SweetAlert.fire("Only numeric values allowed"); rowData.PatientRate = parseFloat(oldValue); return false; }
            if (newValue == ".") { SweetAlert.fire("You need to add numbers before ."); rowData.PatientRate = parseFloat(oldValue); return false; }
            if (parseInt(newValue.length) == parseInt(newValue.indexOf('.') + 1)) { SweetAlert.fire("You need to add numbers after ."); rowData.PatientRate = parseFloat(oldValue); return false; }
            if (parseFloat(newValue) > parseFloat(rowData.MRP)) { SweetAlert.fire("Patient rate must be less than or equal to MRP"); rowData.PatientRate = parseFloat(oldValue); return false; }
            else if (parseFloat(rowData.PartnerRate) > parseFloat(newValue)) { SweetAlert.fire("Partner rate must be less than or equal to Patient rate"); rowData.PatientRate = parseFloat(oldValue); return false; }
        }
    }
    const SaveChangeTemplateData = async () => {
        SweetAlert.fire({
            title: "Are you sure?",
            text: "You are going to change the rate",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'No',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                SaveChangeTemplateDataToDB();
            } else { SweetAlert.fire('Aborted! Nothing changed'); }
        })
    }
    const SaveChangeTemplateDataToDB = async () => {
        if (templatedata.length == 0 || templatedata == undefined || templatedata == null) { SweetAlert.fire("No data to update."); return false; }
        var isBreak = 0;
        for (var key in templatedata) {
            if (parseFloat(templatedata[key].MRP) != 0 && parseFloat(templatedata[key].PartnerRate) == 0 && parseFloat(templatedata[key].PatientRate) == 0 && isBreak == 0) {
                isBreak = 1;
            }
        }
        if (isBreak == 1) { SweetAlert.fire("You had entered only MRP. You need to enter partner/patient rate"); return false; }
        const requestOptions = {
            ItemId: typeSelected[0].value,
            IsPackage: typeSelected[0].ispackage,
            TestCode: typeSelected[0].testcode,
            TemplateDataList: JSON.stringify(templatedata),
            LoginId: localStorage.LoggedInUser
        };
        setIsButtonLoading(true);
        try {
            let url = URL_Start + 'master/savechangetemplatedata'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsButtonLoading(false);
                    SweetAlert.fire({ title: 'Saved', text: "Records saved successfully", icon: 'success', confirmButtonText: 'Ok', reverseButtons: true })
                        .then((result) => {
                            if (result.value) { window.location.reload(false); }
                            else { window.location.reload(false); }
                        })
                }
                else { setIsButtonLoading(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsButtonLoading(false); if (error.response) { AxiosError(error.response.status); } });
        } catch (error) { setIsButtonLoading(false); APIError("-3"); }
    }
    const AxiosError = async (ErrorCode) => {
        if (ErrorCode === 400) { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode === 401) { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode === 403) { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode === 404) { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode === 500) { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode === 503) { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Data unavailable", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-2") { SweetAlert.fire({ title: "API Server Error", text: ErrorMsg, icon: "error" }); }
        else if (ErrorCode === "-3") { SweetAlert.fire({ title: "Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        else if (ErrorCode === "-4") { SweetAlert.fire({ title: "Wrong Credentials", text: "Please enter correct credentials", icon: "error" }) }
        else if (ErrorCode === "-5") { SweetAlert.fire({ title: "Oops", text: "Data could not be saved, Please try again later", icon: "error" }) }
        else if (ErrorCode === "-6") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
        else if (ErrorCode === "-7") { SweetAlert.fire({ title: "Duplicate", text: "Template already exists", icon: "error" }) }
        else if (ErrorCode === "-8") { SweetAlert.fire({ title: "Data unavailable", text: "No record found to import", icon: "error" }) }
        else if (ErrorCode === "-9") { SweetAlert.fire({ title: "LDAP authentication failed", text: ErrorMsg, icon: "error" }) }
        else if (ErrorCode === "-10") { SweetAlert.fire({ title: "Validations", text: "Please insert atleast one valid rate", icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Item Wise Panel Rate List" parent="Master" />
            <Container fluid>
                <Form>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Header className='pb-0'>
                                    <Card.Title>Item Wise Panel Rate List Details</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form.Row className='align-items-center'>
                                        <Col lg={6} md={6}>
                                            <Form.Group>
                                                <Form.Label>Item Name<span className='text-required'>*</span></Form.Label>
                                                <Select onChange={TypeChangeHandler}
                                                    options={itemname}
                                                    value={typeSelected} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4} md={6}>
                                            <Form.Group>
                                                <Form.Label>Rate Type <span className='text-red'>*</span></Form.Label>
                                                <Form.Control as="select" ref={refpartnertype} name="partnertype">
                                                    <option key="-1" value="-1">Select...</option>
                                                    <option key="0" value="0">All</option>
                                                    {partnertype}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col lg={2} md={6} className="text-center">
                                            {isbuttonLoading ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' className="mr-2" onClick={CallAlltemplates}>Search</Button>}
                                            <Button variant='secondary' onClick={(e) => CallReload(e)}>Refresh</Button>
                                        </Col>
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Form>
                {
                    templatedata == null || templatedata == [] || templatedata.length == 0 ? null :
                        <Form>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Header className='pb-0'>
                                            <Card.Title>Template Rate List</Card.Title>
                                        </Card.Header>
                                        <Card.Body>
                                            {/* <DataTables
                                                keyField='Id'
                                                tableData={templatedata}
                                                columns={columns}
                                                noDataIndication={NoRecordFounds}
                                                pagination={paginationFactory(paginationOptions)}
                                                cellEdit={cellEditFactory({
                                                    mode: 'click',
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, rowData, column) => { CheckUpdateValue(newValue, rowData) },
                                                })}
                                            /> */}
                                            <BootstrapTable
                                                keyField='Id'
                                                data={templatedata}
                                                columns={columns}
                                                bootstrap4
                                                headerWrapperClasses="thead-light"
                                                bordered={false}
                                                noDataIndication={<NoRecordFounds />}
                                                pagination={paginationFactory(paginationOptions)}
                                                cellEdit={cellEditFactory({
                                                    mode: 'click',
                                                    blurToSave: true,
                                                    afterSaveCell: (oldValue, newValue, rowData, column) => { CheckUpdateValue(oldValue, newValue, rowData, column) },
                                                })
                                                }></BootstrapTable>
                                            <Col lg={12} md={12} className="text-center">
                                                <Form.Label></Form.Label>
                                                <Form.Group>
                                                    {isbuttonLoading ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' className="mt-2" onClick={SaveChangeTemplateData}>Save</Button>}
                                                </Form.Group>
                                            </Col>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Form>
                }
            </Container>
            {
                isLoading ?
                    <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                    null
            }
        </React.Fragment>
    )
}

export default ItemWisePanelRateList