import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import Breadcrumb from '../../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Spinner } from 'react-bootstrap';
import ToolTip from '../commonComponent/ToolTip';
import { useHistory } from "react-router-dom";

var glbRecords = [];
const ShortageLimits = () => {
    let textSearch = useRef();
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <ToolTip title="Edit" placement="top" onClick={() => HandleDetails(rowData.Id)} linkId="Edit" faName="fa fa-pencil mr-2"></ToolTip>
            </div>
        </React.Fragment>
    }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'UserName', text: 'Name', editable: false },
        { dataField: 'DailyMaxLimit', text: 'Daily Limit', editable: false },
        { dataField: 'DailyAvailableLimit', text: 'Daily Available Limit', editable: false },
        { dataField: 'MonthlyMaxLimit', text: 'Monthly Limit', editable: false },
        { dataField: 'MonthlyAvailableLimit', text: 'Monthly Available Limit', editable: false },
        { dataField: '', text: 'Action', formatter: actionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    const [tableData, setTableData] = useState([]);

    useEffect(() => { BindGrid(); }, []);
    const handleSearch = async () => {
        setCrntPageNO(0);
        setTableData(glbRecords.filter((item) => item.UserName.toLowerCase().indexOf(textSearch.current.value.toLowerCase()) !== -1));
    }
    const BindGrid = async () => {
        setIsLoading(true);
        let url = URL_Start + 'master/getallshortagelimits'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                glbRecords = data.Data;
                setTableData(glbRecords);
                setIsLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const paginationOptions = { sizePerPage: 50, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last', onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); } };
    const HandleDetails = async (id) => {
        localStorage.setItem('sId', id);
        history.push({ pathname: `${process.env.PUBLIC_URL}/master/shortagelimit-info`, search: `` });
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Shortage Limit Authorizers" parent="Masters" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row className="justify-content-center">
                                    <Col lg={4} md={6} className="col-6">
                                        <Form.Group>
                                            <InputGroup className="mb-3">
                                                <Form.Control onChange={handleSearch} placeholder="Search by Name..." ref={textSearch} />
                                                <InputGroup.Append>
                                                    <Button variant="secondary" onClick={handleSearch}><i className='fa fa-search'></i></Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2} md={6} className="col-6">
                                        <Form.Group>
                                            <InputGroup className="mb-3">
                                                <InputGroup.Append>
                                                    <Button variant='primary' type='submit' onClick={() => HandleDetails('0')}>Add New Authorizer </Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Shortage Limits</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <DataTables keyField='id' tableData={tableData} columns={columns} pagination={paginationFactory(paginationOptions)} />
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default ShortageLimits;