import React, { useEffect, useState, useRef } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Breadcrumb from '../../../components/common/breadcrumb';
import DynamicDataTables from '../commonComponent/DataTable/DynamicDataTable';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ViewToolTip  from '../UI/ToolTips/ViewToolTip';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { useHistory } from 'react-router';

import EditToolTip from '../UI/ToolTips/EditToolTip';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip';
import SelfActiveToolTip from '../UI/ToolTips/SelfActiveToolTip';
import SelfInactiveToolTip from '../UI/ToolTips/SelfInactiveToolTip';


const Doctors = () => {
    let textSearch = useRef();
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [tableData, setTableData] = useState([]);
    const [PageHelper, SetPageHelper] = useState({
        PageNo: '1',
        PageSize: '10',
        TotalRecord: 0,
        dsDoctorDlists: []

    });
    const paginationOptions = {

        totalSize: PageHelper.TotalRecord,
        sizePerPage: PageHelper.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',

        onSizePerPageChange: (sizePerPage, page, event) => {
            console.log('Size per page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
           BindGrid(page, sizePerPage,textSearch.current.value.toLowerCase());
            document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
        },
        onPageChange: (page, sizePerPage) => {
            console.log('Page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
        BindGrid(page, sizePerPage,textSearch.current.value.toLowerCase());
        }
    };
    const ViewFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
        <div >
            <ViewToolTip title="View" onClick={()=>ViewDoctorHandler(rowData.DoctorId)} />
            <EditToolTip title="Edit" onClick={()=>editHandler(rowData.DoctorId)} />
                <DeleteToolTip title="Delete" onClick={()=>Displayalert(rowData.DoctorId)} />
                {
                   rowData.IsActive=="False"?<SelfInactiveToolTip title="Inactive" onClick={()=>StatusHandler(rowData.DoctorId)} />: <SelfActiveToolTip title="Active" onClick={()=>StatusHandler(rowData.DoctorId)}/>
                }
        </div>
    </React.Fragment>
    }

    const columns = [{
        dataField: '#',
        text: '#',
        formatter: (cell, row, rowIndex) => {
          let rowNumber = (PageHelper.PageNo - 1) * PageHelper.PageSize + (rowIndex + 1);
          return <span>{rowNumber}</span>;
        },
        headerStyle: (colum, colIndex) => {
            return { width: '5%'};
            }
  
    },
    {
        dataField: 'DoctorName',
        text: 'DoctorName',
        editable: false 

    },
    {
        dataField: 'Mobile',
        text: 'Mobile',
        editable: false 
    },
    {
        dataField: 'Landmark',
        text: 'Landmark',
        editable: false 
    },
    {
        dataField: 'FMLocation',
        text: 'Location',
        editable: false 
    },
   {
        dataField: 'DocSpecialization',
        text: 'Specialization',
        editable: false 
    },
   
    {
        dataField: 'actions',
        isDummyField: true,
        text: 'Actions',
        formatter: ViewFormatter
          

    }
    ];
   

    useEffect(() => { BindGrid(1,10,''); 
}, []);
   
    const handleSearch = async () => {

        BindGrid(1,10,textSearch.current.value.toLowerCase());
    
    }
    const BindGrid = async (PageNo, PageSize,SearchTxtInput) => {
        setIsLoading(true);
        try {
         
            let url=URL_Start + 'DoctorMaster/getdoctordetails';
                let options = {
                    method: 'POST',
                    url: url,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                    data: {  PageNo: `${PageNo}`, PageSize: `${PageSize}` ,SearchTxt:`${SearchTxtInput}` }
                };
                let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                    let data = response.data;
    
                    // let data = await response.data;
                    if (data.Success == true) {
                        let ds = data.Data;
                        if (ds != null) {
    
                            await SetPageHelper({
                                ...PageHelper,
                                 PageNo:Number(PageNo),
                                PageSize: Number(PageSize),
                                TotalRecord: Number(ds.TotalRecord),
                                dsDoctorDlists: ds.ObjBindDoctorRecordList == null ? [] : ds.ObjBindDoctorRecordList
                            });
    
                            setIsLoading(false);
                        }
                    }
                    else {
                        let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');
                    setIsLoading(false);
                    }
    
                }
                else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
                // return data;
            } catch (error) {
                if (error.response) { ErrorFunction(error.response.status);
                    setIsLoading(false);
            }

        }
        }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }

    const editHandler = async (DoctorId) => {
        localStorage.removeItem('DoctorId');
        localStorage.setItem('DoctorId',DoctorId);
        history.push({ pathname: `${process.env.PUBLIC_URL}/doctor-master/editdoctorDetail`, search: `` });
    }
    const newHandler = async () => {
        history.push({ pathname: `${process.env.PUBLIC_URL}/doctor-master/adddoctordetail`, search: `` });
        localStorage.removeItem('cId');
    }
    const Displayalert = (DoctorId) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        })
            .then((result) => { if (result.value) { asyncFunDeletedDoctorDetails(DoctorId); } else { SweetAlert.fire('Your data is safe!'); } })
    }
    const asyncFunDeletedDoctorDetails =async(DoctorId)=> {
        try {
         
        let url=URL_Start+ 'DoctorMaster/updateDoctorDeleted';
        let options = {
            method: 'POST',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {AddedBy:`${LoginId}`, DoctorId:`${DoctorId}`}
        };
        let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if(data.Success==true)
                { let ds=data.Data;
                if(data.Success==true && data.Data=="2000")
                { 
                    SweetAlert.fire({ title: "Success!", text: "Doctor   has been deleted!", icon: "success" });
                    BindGrid(1,10,textSearch.current.value.toLowerCase());
                }
                }
                else{
                    let Errtext=<p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                    console.log('no record found');
                }
               
              
            }
            else{
                let Errtext=<p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
            }
         // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
         }
      }

    const ViewDoctorHandler = (DoctorId) => {
        localStorage.removeItem('DoctorId');
            localStorage.setItem('DoctorId',DoctorId);
            history.push(`/doctor-master/viewdoctorDetail`);   
      }
      const StatusHandler=(DoctorId)=>{
    asyncFunStatusDoctorDetails(DoctorId);
     }
     const asyncFunStatusDoctorDetails =async(DoctorId)=> {
        try {
         
        let url=URL_Start+ 'DoctorMaster/updateDoctorStatus';
        let options = {
            method: 'POST',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {AddedBy:`${LoginId}`, DoctorId:`${DoctorId}`}
        };
        let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if(data.Success==true)
                { let ds=data.Data;
                if(data.Success==true && data.Data=="2000")
                { 
                    SweetAlert.fire({ title: "Success!", text: "Doctor Status  has been Updated!", icon: "success" });
                    BindGrid(1,10,'');
                }
                }
                else{
                    let Errtext=<p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                    console.log('no record found');
                }
               
              
            }
            else{
                let Errtext=<p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
            }
         // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
         }
      }

    return (
        <React.Fragment>
            <Breadcrumb title="Doctors" parent="Master" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row className='justify-content-center'>
                                <Col lg={6} md={6} className="col-7">
                                        <Form.Group>
                                       
                                            <InputGroup className="mb-3">
                                            <Form.Control  placeholder="Location OR Doctor Name OR Doctor Mobile OR Specialization OR Landmark.." ref={textSearch} />
                                                <InputGroup.Append>
                                                    <Button variant="secondary" onClick={handleSearch}><i className='fa fa-search'></i></Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2} md={6} className="col-5">
                                        <Form.Group>
                                        <InputGroup className="mb-3">
                                               
                                               <InputGroup.Append>
                                               <Button variant='primary' type='submit' onClick={newHandler}>Add Doctor</Button>
                                               </InputGroup.Append>
                                           </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={12} className='text-center'>
                                        {/* <Button variant='secondary' className="mr-2" onClick={handleSearch} >Search</Button> */}
                                        
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Doctor List</Card.Title>
                            </Card.Header>
                            <Card.Body>
                            {
                                    isLoading &&
                                    <div className='text-center'> <Spinner animation="border" size='sm' /></div>
                                }
                                     
                                             <DynamicDataTables keyField="DoctorId" tableData={PageHelper.dsDoctorDlists} columns={columns} pagination={paginationOptions ? paginationFactory(paginationOptions) : null} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}
export default Doctors;