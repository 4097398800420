import React, { useState, useEffect } from "react";
import Breadcrumb from '../../../components/common/breadcrumb';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { URL_Start } from '../../../constant';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { Spinner } from 'react-bootstrap';
import LoadingButton from '../commonComponent/LoadingButton';
import InputGroup from 'react-bootstrap/InputGroup';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import DynamicDataTables from '../commonComponent/DataTable/DynamicDataTable';
import paginationFactory from 'react-bootstrap-table2-paginator';

const ValidateDrBusinessMapping = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [ExcelID, setExcelID] = useState(localStorage.ExcelId == undefined ? "" : localStorage.ExcelId);
  const [NewRecord, SetNewRecord] = useState(null);
  const [ExistsRecord, SetExistsRecord] = useState(null);
  const [DrBusinessMaplistCSV, setDrBusinessMaplistCSV] = useState([]);
  const [downloadvisible, setDownloadVisible] = useState(false);
  const [FileType, SetFileType] = useState("");

  const [PageHelper, SetPageHelper] = useState({
    PageNo: '1',
    PageSize: '10',
    TotalRecord: 0,
    dsvalidateDrBusinessMaplists: []
  });

  useEffect(() => {
    BindValidateDrBusinessMapList(1,10);
  }, []);

  const paginationOptions = {
    totalSize: PageHelper.TotalRecord,
    sizePerPage: PageHelper.PageSize,
    showTotal: true,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',

    onSizePerPageChange: (sizePerPage, page, event) => {
      console.log('Size per page change!!!');
      console.log('Newest size per page:' + sizePerPage);
      console.log('Newest page:' + page);
      BindValidateDrBusinessMapList(page, sizePerPage);
      document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
    },
    onPageChange: (page, sizePerPage) => {
      console.log('Page change!!!');
      console.log('Newest size per page:' + sizePerPage);
      console.log('Newest page:' + page);
      BindValidateDrBusinessMapList(page, sizePerPage);
    }
  };
  const columns = [{
    dataField: '#',
    text: '#',
    formatter: (cell, row, rowIndex) => {
      let rowNumber = (PageHelper.PageNo - 1) * PageHelper.PageSize + (rowIndex + 1);
      return <span>{rowNumber}</span>;
    },
    headerStyle: (colum, colIndex) => {
      return { width: '5%' };
    }
  },
  {
    dataField: 'DoctorUniqueId',
    text: 'DrUniqueId',
    editable: false

  },
  {
    dataField: 'FirstName',
    text: 'First Name',
    editable: false
  },
  {
    dataField: 'Mobile',
    text: 'Mobile',
    editable: false
  },
  {
    dataField: 'ErrorDesc',
    text: 'Status',
    editable: false
  }
  ];

  const BindValidateDrBusinessMapList = async (PageNo, PageSize) => {
    try {
      let url = URL_Start + 'DoctorMaster/getDrBusinessMapStatusDownload';
      let options = {
        method: 'POST',
        url: url,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: { PageNo: `${PageNo}`, PageSize: `${PageSize}`, ExcelID: `${ExcelID}` }
      };
      let response = await axios(options);
      let responseOK = response && response.status == 200;
      if (responseOK) {
        let data = response.data;
        if (data.Success == true) {
          let ds = data.Data;
          if (ds != null) {
            SetNewRecord(ds.NewRecord);
            SetExistsRecord(ds.ExistsRecord);
            SetFileType(ds.FileType);
            await SetPageHelper({
              ...PageHelper,
              PageNo: Number(PageNo),
              PageSize: Number(PageSize),
              TotalRecord: Number(ds.TotalRecord),
              dsvalidateDrBusinessMaplists: ds.ObjDrBusinessMapStatusList == null ? [] : ds.ObjDrBusinessMapStatusList
            });
          }
        }
        else {
          let Errtext = <p><div className='text-required'> no record found </div></p>;
          console.log('no record found');
        }

      }
      else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); }
      // return data;
    } catch (error) {
      if (error.response) {
        ErrorFunction(error.response.status);
      }
    }
  }
  const handleExport = async () => {
    DownloadDrBusinessMapStatusList(1, 10);
  }
  const DownloadDrBusinessMapStatusList = async (PageNo, PageSize) => {
    try {
      let url = URL_Start + 'DoctorMaster/getDrBusinessMapStatusDownload';
      let options = {
        method: 'POST',
        url: url,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: { PageNo: `${PageNo}`, PageSize: `${PageSize}`, ExcelID: `${ExcelID}`, DrExcelStatus: '1' }
      };
      let response = await axios(options);
      let responseOK = response && response.status == 200;
      if (responseOK) {
        let data = response.data;
        if (data.Success) {
          if (data.Data != null && data.Data.ObjDrBusinessMapStatusList.length>0) { setDownloadVisible(true); setDrBusinessMaplistCSV(data.Data.ObjDrBusinessMapStatusList); document.getElementById("csvdownload").click(); }
          else { ErrorFunction(data.Data); }
        }
        else {
          setDownloadVisible(false);
        }
      }
      else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); }
    } catch (error) {
      if (error.response) {
        ErrorFunction(error.response.status);
      }

    }
  }

  const ErrorFunction = async (ErrorCode) => {
    if (ErrorCode == "400")
      SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
    else if (ErrorCode == "401")
      SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
    else if (ErrorCode == "403")
      SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
    else if (ErrorCode == "404")
      SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
    else if (ErrorCode == "500")
      SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
    else if (ErrorCode == "503")
      SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
  }

  return (
    <React.Fragment>
        <Breadcrumb title="Validate Doctor Business Mapping" parent="Doctor Master" />
      <Container fluid>
        <Row></Row>
        <Row>
          <Col md={12}>
            <Card>
              <Card.Header className='pb-0'>
                {/* <Card.Title>Validate Doctor Business Mapping</Card.Title> */}
              </Card.Header>
              <Card.Body>
                {
                  isLoading &&
                  <div className='text-center'> <Spinner animation="border" size='sm' /></div>
                }
                <DynamicDataTables keyField="DoctorId" tableData={PageHelper.dsvalidateDrBusinessMaplists} columns={columns} pagination={paginationOptions ? paginationFactory(paginationOptions) : null} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
          {FileType=="2"?<div className='text-success text-center'> <span><i className="fa fa-check-circle" aria-hidden="true"></i></span> {NewRecord} records updated. </div>:<div className='text-success text-center'> <span><i className="fa fa-check-circle" aria-hidden="true"></i></span> {NewRecord} New records and {ExistsRecord} existing records. </div>}        
          <div className='text-center mt-4'>
              <Button variant='primary' type='submit' onClick={handleExport} filename={"DoctorBusinessMappingStatus.csv"} target="_blank"><i class="fa fa-file-excel-o" aria-hidden="true"></i>Download CSV</Button>
            </div>
          </Col>
        </Row>
        {
          downloadvisible &&
          <CSVLink data={DrBusinessMaplistCSV} filename="DoctorBusinessMappingStatus.csv" target="_blank" id="csvdownload"></CSVLink>
        }
      </Container>
    </React.Fragment>
  )
}

export default ValidateDrBusinessMapping;