import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import InputGroup from 'react-bootstrap/InputGroup';
import Breadcrumb from '../../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Spinner } from 'react-bootstrap';
import SampleToolTip from '../UI/ToolTips/SampleToolTip';

var glbRecords = [];
const TestCategories = () => {
    let textSearch = useRef();
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [testsModal, setTestsModal] = useState();
    const [popupTableData, setPopupTableData] = useState([]);
    const [isSyncing, setIsSyncing] = useState(false);
    const openModal = () => { setTestsModal(!testsModal); };
    const paginationOptions = {
        sizePerPage: 10, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <SampleToolTip title="Test List" placement="top" id={rowData.PlabId} onClick={() => GetSubCategories(rowData)} />
            </div>
        </React.Fragment>
    }

    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'CategoryName', text: 'Category Name', editable: false },
        { dataField: 'CategoryStatus', text: 'Is Active', editable: false },
        { dataField: 'actions', text: 'Actions', editable: false,formatter:actionFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    const [tableData, setTableData] = useState([]);

    useEffect(() => { BindGrid(); }, []);
    const handleSearch = async () => {
        setCrntPageNO(0);
        setTableData(glbRecords.filter((item) => item.CategoryName.toLowerCase().indexOf(textSearch.current.value.toLowerCase()) !== -1));
    }
    const BindGrid = async () => {
        setIsLoading(true);
        let url = URL_Start + 'master/getcategories'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data != null) {
                    glbRecords = data.Data;
                    setTableData(glbRecords);
                }
                setIsLoading(false);
            }
            else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const popupColumns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'SubCategoryName', text: 'Sub Category Name', editable: false },
        { dataField: 'ModalityCode', text: 'Modality Code', editable: false },
        // { dataField: 'Abbreviation', text: 'Abbreviation', editable: false },
        { dataField: 'SubCategoryStatus', text: 'Is Active', editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    const GetSubCategories = async (rwData) => { setPopupTableData(rwData.SubCategories); openModal(); }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Test Categories" parent="Masters" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row className="justify-content-center">
                                    <Col lg={4} md={6}>
                                        <Form.Group>
                                            <InputGroup className="mb-3">
                                                <Form.Control onChange={handleSearch} placeholder="Search by category name..." ref={textSearch} />
                                                <InputGroup.Append>
                                                    <Button variant="secondary" onClick={handleSearch}><i className='fa fa-search'></i></Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Test Categories List</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <DataTables keyField='id' tableData={tableData} columns={columns} />
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {testsModal &&
                    <Modal isOpen={testsModal} toggle={openModal} centered={true} size="lg">
                        <ModalHeader toggle={openModal}>Tests List</ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Body>
                                            <DataTables keyField='PlabId' tableData={popupTableData} columns={popupColumns} pagination={paginationFactory(paginationOptions)} />
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </ModalBody>
                    </Modal>
                }
            </Container>
        </React.Fragment>
    )
}

export default TestCategories;