import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import Breadcrumb from '../../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
var glbRecords = [];

const Companymaster = () => {
    let textSearch = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [isButton, setIsButton] = useState(false);
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        // { dataField: 'Id', text: 'Id' },
        { 
            
            dataField: 'CompanyCode', text: 'CompanyCode', editable: false 
        },
        { 
            
            dataField: 'CompanyName', text: 'CompanyName', editable: false 
        },
        { 
            
            dataField: 'LastSyncDate', text: 'LastSyncDate', editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } 
        }
    ];
    const [tableData, setTableData] = useState([]);

    useEffect(() => { BindGrid(); }, []);
    const handleSearch = async () => {
        setTableData(glbRecords.filter((item) => item.CompanyName.toLowerCase().indexOf(textSearch.current.value.toLowerCase()) !== -1));
    }
    const BindGrid = async () => {
        setIsLoading(true);
        let url = URL_Start + 'hrms/getcompanylist'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                glbRecords = data.Data;
                setTableData(glbRecords);
                setIsLoading(false);
            }
            else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }

    const CustomlistHandler=()=>{
        asyncFunhrmsSyncCompanyList();
     }
    async function  asyncFunhrmsSyncCompanyList() {
        try {
            setIsButton(true);
            let url=URL_Start +'hrms/updateCompaynmasterList';
    
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
              data:{AddedBy:`${LoginId}` }
            };
    
            let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                    let data = response.data;
                    // let data = await response.data;
                    if(data.Success==true)
                    {
                       
                        setTimeout(() => {
                            setIsButton(false);
                            BindGrid();
                        SweetAlert.fire({ title: "Success!", text: "Latest Sync data has been successfully!", icon: "success" });
                      }, 2000);

                    }
                    else{
                        setIsButton(false);
                         if(data.ErrorList!=null && data.ErrorList.length>0)
                         {
                          
                            SweetAlert.fire({ title: "Error", text:data.ErrorList[0].errorMsg, icon: "error" });
                         }
    
                    }
    
    
                }
                else{
                    setIsButton(false);
                    console.log('no record found');
    
                }
             // return data;
            } catch (error) {
                setIsButton(false);
                console.log(error.message);
                let Errtext="";
                    Errtext =<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                    SweetAlert.fire({ title: "Error", text:  Errtext, icon: "error" });
                  
             }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Company" parent="Master" />
            <Container fluid>
                
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row className="justify-content-center">
                               
                                    <Col lg={4} md={6} className="col-8">
                                        <Form.Group>
                                       
                                            <InputGroup className="mb-3">
                                                <Form.Control onChange={handleSearch} placeholder="Search by Name..." ref={textSearch} />
                                                <InputGroup.Append>
                                                    <Button variant="secondary" onClick={handleSearch}><i className='fa fa-search'></i></Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2} md={6} className="col-4">
                                        <Form.Group>
                                        <InputGroup className="mb-3">
                                               
                                               <InputGroup.Append>
                                               <Button variant='primary' type='submit'  onClick={CustomlistHandler} >{isButton?<Spinner animation="border" size='sm' />:null} Sync Data </Button>
                                                  
                                               </InputGroup.Append>
                                           </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Company List</Card.Title>
                            </Card.Header>
                            <Card.Body>
                            {
                                    isLoading &&
                                    <div className='text-center'> <Spinner animation="border" size='sm' /></div>
                                }
                              <DataTables keyField='CompanyId' tableData={tableData} columns={columns} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}
export default Companymaster;