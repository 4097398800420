import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import Breadcrumb from '../../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import SweetAlert from 'sweetalert2';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import Alert from "react-bootstrap/Alert";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PrintPDF from '../commonComponent/PrintPDF';
import EditToolTip from '../UI/ToolTips/EditToolTip';
import { CSVLink } from "react-csv";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';

const DayEndReport = () => {
    let fromDate = useRef();
    let toDate = useRef();
    let reportsuser = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [apiData, setAPIData] = useState();
    const [cashReceiptsTable, setCashReceiptsTable] = useState([]);
    const [onlineReceiptsTable, setOnlineReceiptsTable] = useState([]);
    const [pendingReceiptsTable, setPendingReceiptsTable] = useState([]);
    const [defaultDate, setDefaultDate] = useState("");
    const [loginname, setLoginname] = useState("");
    const [cashReceived, setCashReceived] = useState("");
    const [onlineReceived, setOnlineReceived] = useState("");
    const [totalBookings, setTotalBookings] = useState("");
    const [printDate, setPrintDate] = useState("");
    const [creditPending, setCreditPending] = useState("");
    const [discountPending, setDiscountPending] = useState("");
    const [creditGiven, setCreditGiven] = useState("");
    const [discountGiven, setDiscountGiven] = useState("");
    const [totalSale, setTotalSale] = useState("");
    const [isPrintReport, setIsPrintReport] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [visitingid, setVisitingId] = useState("");
    const openReportModal = () => { setIsPrintReport(!isPrintReport); }
    const openEditModal = () => { setIsEdit(!isEdit); }
    const [displayPrintReport, setDisplayPrintReport] = useState(false);
    const [table, setTable] = useState([]);
    const [csvtable, setCSVTable] = useState([]);
    const [editcashReceived, setEditCashReceived] = useState("");
    const [editonlineReceived, setEditOnlineReceived] = useState("");
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [edittabledata, setEditTableData] = useState([]);
    const [users, setUsers] = useState([]);
    const [isButton, setIsButton] = useState(false);
    const [lid, setLID] = useState("0");

    useEffect(() => {
        GetUsersforDayendreports();
    }, [])
    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        // BindGrid(defaultValue, defaultValue);
    }, []);
    useEffect(() => {
        if (downloadvisible === true) {
            document.getElementById("csvdownload").click();
        }
    }, [downloadvisible])
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const editidFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <EditToolTip title="Edit" placement="top" onClick={() => editHandler(rowData.VisitingId)} />
            </div>
        </React.Fragment>
    }
    const editactionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.PaymentModeId === "1" ? <Button variant='primary' onClick={(e) => UpdatePaymentMode("1", rowData.OrderDetailId, localStorage.getItem('LoggedInUser'))} >Update to online received</Button> :
                        <Button variant='primary' onClick={(e) => UpdatePaymentMode("13", rowData.OrderDetailId, localStorage.getItem('LoggedInUser'))} >Update to cash received</Button>
                }
            </div>
        </React.Fragment>
    }
    const GetUsersforDayendreports = async () => {
        setIsButton(true);
        try {
            let url = URL_Start + 'reports/GetUsersforDayendreports/'
            await axios.get(url).then((response) => {
                setIsButton(false);
                const data = response.data;
                if (data.Success) {
                    //setUsers(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>)))
                    setUsers(data.Data);
                }
            }).catch(function (error) { setIsButton(false); if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { setIsButton(false); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }

    const formik = useFormik({
        initialValues: { reportuser: '' },
        validationSchema: Yup.object({
            reportuser: Yup.string().required('user is required'),
        }),
        onSubmit: values => { BindGrid(fromDate.current.value, fromDate.current.value); }
    });
    const discountFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {rowData.DiscountAmount !== "" ? <p>{parseFloat(rowData.DiscountAmount).toFixed(2)} ({rowData.DiscountStatus})</p> : null}
            </div>
        </React.Fragment>
    }
    const creditFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {rowData.CreditAmount !== "" ? <p>{parseFloat(rowData.CreditAmount).toFixed(2)} ({rowData.CreditStatus})</p> : null}
            </div>
        </React.Fragment>
    }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'VisitingNo', text: 'Visiting Id', editable: false },
        { dataField: 'PatientName', text: 'Patient', editable: false },
        { dataField: 'OrderAmount', text: 'Total Amount', editable: false },
        { dataField: 'CashReceived', text: 'Cash', editable: false },
        { dataField: 'OnlineReceived', text: 'Online', editable: false },
        { formatter: discountFormatter, text: 'Credit', editable: false },
        // { dataField: 'CreditAmount', text: 'CreditAmount', editable: false },
        // { dataField: 'CreditStatus', text: 'CreditStatus', editable: false },
        { formatter: creditFormatter, text: 'Discount', editable: false },
        // { dataField: 'DiscountAmount', text: 'DiscountAmount', editable: false },
        // { dataField: 'DiscountStatus', text: 'DiscountStatus', editable: false },
        // { dataField: '', text: 'Action', formatter: actionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }

    ];
    const editcolumns = [
        { dataField: '#', text: '#', editable: false, formatter: editidFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'PaymentMode', text: 'Payment Mode', editable: false },
        { dataField: 'PaidAmount', text: 'Amount', editable: false },
        { dataField: 'OrderDate', text: 'Order Date', editable: false },
        { dataField: '', text: 'Action', formatter: editactionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    const editHandler = async (VisitingId) => {
        setVisitingId(VisitingId);
        // let cashrec = "";
        // let onlinerec = "";
        // for (var key in table) {
        //     if (table[key].VisitingId === VisitingId) {
        //         cashrec = table[key].CashReceived
        //         onlinerec = table[key].OnlineReceived
        //     }
        // }
        // setEditCashReceived(cashrec);
        geteditPaymentMode(VisitingId);
    } 
    const handleSearch = async () => {
        if (lid == "0") {
            SweetAlert.fire('Please select user');
            setTable([]);
        }
        else if (fromDate.current.value == "") {
            SweetAlert.fire('Please select date');
            setTable([]);
        }
        else {
            BindGrid(fromDate.current.value, fromDate.current.value);
        }
    }
    const BindGrid = async (fDte, tDte) => {
        setIsLoading(true);
        setDisplayPrintReport(false);
        // let url = URL_Start + 'reports/getdayendreport/' + localStorage.getItem('LoggedInUser') + "/" + localStorage.getItem('CentreSNo') + "/" + fDte + "/" + tDte
        let url = URL_Start + 'reports/getdayendreportdetails/' + lid + "/" + 0 + "/" + fDte
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                var apiData = data.Data;
                if (apiData !== null) {
                    setAPIData(apiData);
                    setLoginname(apiData.DayEndDetails[0].LoginName);
                    setCashReceived(apiData.DayEndDetails[0].CashReceived);
                    setOnlineReceived(apiData.DayEndDetails[0].OnlineReceived);
                    setCreditPending(apiData.DayEndDetails[0].CreditPending);
                    setDiscountPending(apiData.DayEndDetails[0].DiscountPending);
                    setCreditGiven(apiData.DayEndDetails[0].CreditGiven);
                    setDiscountGiven(apiData.DayEndDetails[0].DiscountGiven);
                    setTotalBookings(apiData.DayEndDetails[0].NoOfBookings);
                    setTotalSale(apiData.DayEndDetails[0].TotalSale);
                    setPrintDate(apiData.DayEndDetails[0].PrintOn);
                    if (apiData.reportdetails !== null) { setTable(apiData.reportdetails.filter((d) => d.VisitingId !== '')); setCSVTable(apiData.reportdetails); setDisplayPrintReport(true); } else { setTable([]); setCSVTable([]); }
                    // if (apiData.CashReceipts.length > 0) { setCashReceiptsTable(apiData.CashReceipts); setDisplayPrintReport(true); } else { setCashReceiptsTable([]); }
                    // if (apiData.OnlineReceipts.length > 0) { setOnlineReceiptsTable(apiData.OnlineReceipts); setDisplayPrintReport(true); } else { setOnlineReceiptsTable([]); }
                    // if (apiData.PendingReceipts.length > 0) { setPendingReceiptsTable(apiData.PendingReceipts); setDisplayPrintReport(true); } else { setPendingReceiptsTable([]); }

                } else { setCashReceiptsTable([]); setOnlineReceiptsTable([]); setPendingReceiptsTable([]); }
                setIsLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const geteditPaymentMode = async (VisitingId) => {
        setIsLoading(true);
        let url = URL_Start + 'reports/geteditPaymentmode/' + localStorage.getItem('LoggedInUser') + "/" + 0 + "/" + VisitingId
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                var apiData = data.Data;
                if (apiData !== null) {
                    setEditTableData(data.Data);
                    openEditModal();
                }
                else {
                    setEditTableData([]);
                }
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const OpenReport = () => {
        setIsPrintReport(true);
    }

    const OpenCSV = () => {
        setDownloadVisible(true);
    }
    const UpdatePaymentMode = async (PaymentModeId, OrderDetailId) => {
        var requestOptions = { PaymentModeId: PaymentModeId, OrderDetailId: OrderDetailId, UpdatedBy: localStorage.LoggedInUser }
        let url = URL_Start + 'reports/updatepaymentmode'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success === true) {
                SweetAlert.fire("Payment Mode updated successfully");
                openEditModal();
                const today = new Date();
                const date = today.setDate(today.getDate());
                const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
                setDefaultDate(defaultValue);
                BindGrid(fromDate.current.value, fromDate.current.value);
            }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    return (
        <>
            <Breadcrumb title="Day End Report" parent="Accounts" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form>
                                    <Form.Row>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Label>Select User<span className='text-red'>*</span></Form.Label>
                                                {isButton ? <Spinner animation="border" size='sm' /> : null}
                                                <Typeahead
                                                    id="dayend-names"
                                                    labelKey="Value"
                                                    options={users}
                                                    placeholder="---Select---"
                                                    ref={reportsuser}
                                                    //onChange={userGroupChangeHandler}
                                                    // onInputChange={(text, e) => { getitemlist(e, text); }}
                                                    onChange={(text) => {
                                                        JSON.stringify(text) == "[]" ? setLID("0") : setLID(text[0].Id)
                                                    }}
                                                />
                                                {/* <Form.Control as="select" name="reportuser" ref={reportsuser} value={formik.values.reportuser} onChange={formik.handleChange} onBlur={formik.handleBlur}>
                                                    <option key="" value="">---Select---</option>
                                                    {users}
                                                </Form.Control>
                                                {formik.touched.reportuser && formik.errors.reportuser ? (<div className='text-required'>{formik.errors.reportuser}</div>) : null} */}
                                            </Form.Group>
                                        </Col>
                                        <Col md={2}>
                                            <Form.Group>
                                                <Form.Label>Select Date</Form.Label>
                                                <Form.Control type="date" id="date1" name="date1" ref={fromDate} defaultValue={defaultDate} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={1}>
                                            <div className="searchButtonSec">
                                                <Button variant='primary' onClick={handleSearch}>Search</Button>
                                            </div>
                                        </Col>
                                    </Form.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form>
                                    <Form.Row>
                                        <Col md={12} className="summaryHeadingAlertSec">
                                            <h6 className='text-red'>Summary</h6>
                                            <Alert variant="success" className="text-center">
                                                <ul className="list-inline pointsInline">
                                                    <li className="list-inline-item">Name: <strong>{loginname}</strong></li>
                                                    <li className="list-inline-item">No. Of Bookings: <strong>{totalBookings}</strong></li>
                                                    <li className="list-inline-item">Total Sale: <strong>{totalSale}</strong></li>
                                                    <li className="list-inline-item">Cash Received: <strong>{cashReceived}</strong></li>
                                                    <li className="list-inline-item">Online Received: <strong>{onlineReceived}</strong></li>
                                                    <li className="list-inline-item">Discount Given: <strong>{discountGiven}</strong></li>
                                                    <li className="list-inline-item">Credit Given: <strong>{creditGiven}</strong></li>
                                                    <li className="list-inline-item">Credit Pending: <strong>{creditPending}</strong></li>
                                                    <li className="list-inline-item">Discount Pending: <strong>{discountPending}</strong></li>
                                                    <li className="list-inline-item">Print On: <strong>{printDate}</strong></li>
                                                </ul>
                                            </Alert>
                                        </Col>
                                    </Form.Row>
                                    {/* <Form.Row className='mt15'>
                                        <Col md={12}>
                                            <h6 className='text-red'>Cash Received</h6>
                                            {
                                                isLoading ?
                                                    <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                    <>
                                                        <DataTables keyField='id' tableData={cashReceiptsTable} columns={columns} />
                                                    </>
                                            }
                                        </Col>
                                        <Col md={12} className='text-right'>Total: <strong>{cashReceived}</strong></Col>
                                    </Form.Row>
                                    <Form.Row className='mt15'>
                                        <Col md={12}>
                                            <h6 className='text-red'>Online Received</h6>
                                            {
                                                isLoading ?
                                                    <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                    <>
                                                        <DataTables keyField='id' tableData={onlineReceiptsTable} columns={columns} />
                                                    </>
                                            }
                                        </Col>
                                        <Col md={12} className='text-right'>Total: <strong>{onlineReceived}</strong></Col>
                                    </Form.Row>
                                    <Form.Row className='mt15'>
                                        <Col md={12}>
                                            <h6 className='text-red'>Pending</h6>
                                            {
                                                isLoading ?
                                                    <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                    <>
                                                        <DataTables keyField='id' tableData={pendingReceiptsTable} columns={columns} />
                                                    </>
                                            }
                                        </Col>
                                        <Col md={12} className='text-right'>Total: <strong>{totalPending}</strong></Col>
                                    </Form.Row> */}
                                    <Form.Row className='mt15 summaryTableClass'>
                                        <Col md={12}>
                                            {
                                                isLoading ?
                                                    <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                    <>
                                                        <DataTables keyField='id' tableData={table} columns={columns} />
                                                    </>
                                            }
                                        </Col>
                                        {/* <Col md={12} className='text-right'>Total: <strong>{cashReceived}</strong></Col> */}
                                    </Form.Row>
                                    <Form.Row className='mt15'>
                                        <Col md={12} className='text-center'>
                                            {displayPrintReport ?
                                                <>
                                                    <Button variant='primary' className="mr-2" onClick={(e) => OpenReport()}>Print Preview</Button>
                                                    <Button variant='secondary' className="mr-2" filename={"DayEndReport.csv"} target="_blank" onClick={(e) => OpenCSV()}>Download CSV</Button>
                                                </>
                                                : null}
                                        </Col>
                                    </Form.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    downloadvisible &&
                    <CSVLink data={csvtable} filename="DayEndReport.csv" target="_blank" id="csvdownload"></CSVLink>
                }
            </Container>
            <Modal isOpen={isPrintReport} toggle={openReportModal} centered={true} className="printReportContainerModel">
                <ModalHeader toggle={openReportModal}>Print Report</ModalHeader>
                <ModalBody>
                    <Form.Row>
                        <Col md={12} className="PrintDayEndReport">
                            <PrintPDF ReportName="PrintDayEndReport" APIData={apiData} />
                        </Col>
                    </Form.Row>
                </ModalBody>
            </Modal>
            <Modal isOpen={isEdit} toggle={openEditModal} centered={true} size="lg">
                <ModalHeader toggle={openEditModal}>Edit Payment Mode</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col md={12} className="OrderDetailClass">
                            <DataTables keyField='OrderDetailId' tableData={edittabledata} columns={editcolumns} />
                            {/* {
                                editcashReceived !== "" ?
                                    <Form.Row className="mb-2">
                                        <Col md={6}>
                                            <Form.Label>Cash Received : <b>{editcashReceived}</b> </Form.Label>
                                        </Col>
                                        <Col md={6}>
                                            <Button variant='primary' onClick={(e) => UpdatePaymentMode("1")} >Update to online received</Button>
                                        </Col>
                                    </Form.Row> : null
                            }
                            {
                                editonlineReceived !== "" ?
                                    <Form.Row>
                                        <Col md={6}>
                                            <Form.Label>Online Received : <b>{editonlineReceived}</b> </Form.Label>
                                        </Col>
                                        <Col md={6}>
                                            <Button variant='primary' onClick={(e) => UpdatePaymentMode("13")} >Update to cash received</Button>
                                        </Col>
                                    </Form.Row> : null
                            } */}
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </>
    )
}

export default DayEndReport;