
import React, { useState, useEffect, useRef, Fragment } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import EditToolTip from '../UI/ToolTips/EditToolTip';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import ViewToolTip from '../UI/ToolTips/ViewToolTip';
import { URL_Start } from '../../../constant';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import InputGroup from 'react-bootstrap/InputGroup';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Spinner } from 'react-bootstrap';
import AddToolTip from '../UI/ToolTips/AddToolTip';
import SubtractToolTip from '../UI/ToolTips/SubtractToolTip';
import { Typeahead } from 'react-bootstrap-typeahead';
import { MultiSelect } from 'react-multi-select-component';
import '../../../pages/admin/react-multiselect.css';

let globalModuleId = "0";
let globalPageSelection = false;
let globaluserPlatform = "";
let globalUserId = "0";
let globaltblAllGroups = [];
let globaltblExistGroups = [];
let FlagUserCustFilterOnly = "0";
let GlobalPartnerType = "0";
let globallocationStateList = [];
let LGlbLocationRegionList = [];
let LGLocationDistrictList = [];
let GGLocationRegionList = [];
let GGLocationDistrictList = [];
let stringStates = "";
let stringStateIds = "";
let stringRegions = "";
let stringRegionIds = "";
let stringDistricts = "";
let stringDistrictIds = "";
let SelectedAllStateFlag = false;
let glblCentreIds = [];
let glblCentreNames = [];
let glbSelectedCentreIds = [];

const AddAccessGroupsMapping = () => {
    let typeahead = useRef(null);
    let textSearch = useRef();
    let isFirstRender = useRef(false);
    let isLastRender = useRef(false);
    let IsDefaultLocation = useRef(false);
    const history = useHistory();
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const [ErrorPopUp, setErrorPopUp] = useState(0);
    const [ModuleDetail, SetModuleDetail] = useState(0);
    const [IsUserTypeMoudle, SetIsUserTypeMoudle] = useState(false);
    const [tbAccessPageRMaster, SettbAccessPageRMaster] = useState([]);
    const [IsPartnerTypeShow, SetIsPartnerTypeShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isButton, setIsButton] = useState(false);
    const [IsSearchUsers, SetIsSearchUsers] = useState(false);
    const [SearchBox, SetSearchBox] = useState("");
    const [UsersDLists, SetUsersDLists] = useState([]);
    const [tblUserGroupMapping, SettblUserGroupMapping] = useState(null);
    const [userModuleShow, SetuserModuleShow] = useState(false);
    const [TXtSearch, SetTXtSearch] = useState("");
    const [ViewAccessGroups, SetViewAccessGroups] = useState(null);
    const [IsUserView, SetIsUserView] = useState(false);
    const [LocationZoneId, SetLocationZoneId] = useState(1);
    const [LocationStateList, SetLocationStateList] = useState([]);
    const [LocationRegionList, SetLocationRegionList] = useState([]);
    const [selectedGlocationStateList, SetselectedGlocationStateList] = useState([]);
    const [selectedGlocationRegionList, SetselectedGlocationRegionList] = useState([]);
    const [selectedGlocationDistrictList, SetselectedGlocationDistrictList] = useState([]);
    const [LocationDistrictList, SetLocationDistrictList] = useState([]);
    const [SelectionglobaltblAllGroups, SetSelectionglobaltblAllGroups] = useState([]);
    const [LGLocationZoneList, SetLGLocationZoneList] = useState([{ "zoneId": "1" }]);
    const [centresList, setCentresList] = useState([]);
    const [selectedCentres, SetSelectedCentres] = useState([]);

    useEffect(() => { userPlatformChangeHandler("1"); }, []);
    useEffect(() => {
        asyncFunModuleDDLDetails();
        asyncFunLocationBindDetails();
    }, [globallocationStateList]);
    useEffect(() => {
        if (isLastRender.current == true) {
            SetSelectionglobaltblAllGroups(SelectionglobaltblAllGroups => [globaltblAllGroups]);
            isLastRender.current = false;
        }
        if (isFirstRender.current = true) { isFirstRender.current = false; }
    }, [selectedGlocationDistrictList, globaltblAllGroups, SelectionglobaltblAllGroups, LGlbLocationRegionList]);

    const handleSearch = async (e) => {
        typeahead.current.clear();
        globalModuleId = "0";
        SetuserModuleShow(false);
        SetUsersDLists([]);
        globaltblExistGroups = [];
        globaltblAllGroups = [];
        formik.values.Users = "0";
        SetTXtSearch(e.target.value);
        if (textSearch.current != null && textSearch.current.value != null && textSearch.current.value.length > 1) {
            SetUsersDLists([]);
            asyncFunUsersDetails(globaluserPlatform, textSearch.current.value.toLowerCase());
        }
        else { asyncFunUsersDetails(globaluserPlatform, ""); }
    }
    const asyncFunLocationBindDetails = async () => {
        try {
            let url = URL_Start + 'master/getstateslistlocationMapping/' + `${LocationZoneId}`;
            let options = {
                method: 'GET', url: url,
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
                data: {}
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) {
                        const TypeOptionDs = [];
                        ds.map((option) => {
                            const TypeOptions = { 'label': option.Value, 'value': option.Id, 'StateId': option.Id, 'disabled': option.HaveRegionStatus == "1" ? false : true }
                            return TypeOptionDs.push(TypeOptions);
                        });
                        SetLocationStateList(TypeOptionDs);
                    }
                    else { SetLocationStateList([]); }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                }
            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
            }
        } catch (error) {
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const asyncFunRegionLocationBindDetails = async (isChecked) => {
        try {
            let url = URL_Start + 'master/getBindLocationRegionDetails'
            let options = {
                method: 'POST', url: url, headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
                data: { objTblLocationTypeList: globallocationStateList }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    SetLocationRegionList([]);
                    if (ds != null && ds.length > 0) {
                        const TypeOptionDs = [];
                        ds.map((option) => {
                            const TypeOptions = { 'label': option.RegionName, 'value': option.RegionId, 'StateId': option.StateId, 'RegionId': option.RegionId, 'IsHaveDistrictStatus': option.IsHaveDistrictStatus, 'disabled': false }
                            return TypeOptionDs.push(TypeOptions);
                        });
                        SetLocationRegionList(TypeOptionDs);
                        SetuserModuleShow(false);
                    }
                    else {
                        SetLocationRegionList([]);
                        SetselectedGlocationRegionList([]);
                        if (globallocationStateList.length == 0) { SetuserModuleShow(false); }
                    }

                    if (ds != null && ds.length > 0) {
                        if (SelectedAllStateFlag == true) {
                            const TypeOptionDs = [];
                            ds.map((option) => {
                                const TypeOptions = { 'label': option.RegionName, 'value': option.RegionId, 'StateId': option.StateId, 'RegionId': option.RegionId, 'IsHaveDistrictStatus': option.IsHaveDistrictStatus, 'disabled': false }
                                return TypeOptionDs.push(TypeOptions);
                            });

                            SetselectedGlocationRegionList(selectedGlocationRegionList => [...TypeOptionDs]);
                            LGlbLocationRegionList = [];
                            ds.map((option) => {
                                const item = { "RegionId": option.RegionId };
                                LGlbLocationRegionList.push(item);
                            });
                            SetuserModuleShow(true);
                        }
                        else {
                            SetselectedGlocationRegionList(selectedGlocationRegionList => [...selectedGlocationRegionList.filter(o1 => ds.some(o2 => o1.RegionId == o2.RegionId))]);
                            if (selectedGlocationRegionList.length == 0) { SetuserModuleShow(false); } else { SetuserModuleShow(true); }
                        }
                    }
                    else {
                        SetLocationRegionList([]);
                        SetselectedGlocationRegionList([]);
                        if (globallocationStateList.length > 0) {
                            stringRegions = "Selected All Regions";
                            stringRegionIds = "0";
                            stringDistricts = "Selected All Districts";
                            stringDistrictIds = "0";
                            SetuserModuleShow(true);
                        }
                        else {
                            stringRegions = "";
                            stringRegionIds = "0";
                            stringDistricts = "";
                            stringDistrictIds = "0";
                        }

                        globaltblAllGroups = globaltblAllGroups.map(el => (
                            el.IsAlreadyHaveDB == "False" ? { ...el, LocationRegions: stringRegions, LocationRegionIds: stringRegionIds, LocationDistricts: stringDistricts, LocationDistrictIds: stringDistrictIds } : el
                        ));
                    }
                }
                else {
                    SetselectedGlocationRegionList([]);
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                }
            }
            else {
                SetselectedGlocationRegionList([]);
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
            }
        } catch (error) {
            SetselectedGlocationRegionList([]);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const asyncFunUsersDetails = async (GlbUserPlatform, txtsearch) => {
        try {
            setIsButton(true);
            let url = URL_Start + 'master/getUsersfilterdetails';
            let options = {
                method: 'POST', url: url,
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
                data: {
                    UserPlatformId: `${GlbUserPlatform}`, SearchTxt: `${txtsearch}`, FlagUserCustFilterOnly: `${FlagUserCustFilterOnly}`,
                    PartnerTypeId: `${GlobalPartnerType}`
                }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) {
                        setIsButton(false);
                        const TypeOptionDs = [];
                        ds.map((option) => {
                            const TypeOptions = { 'id': option.UserId, 'name': option.CustUserName }
                            return TypeOptionDs.push(TypeOptions);
                        });
                        SetUsersDLists(TypeOptionDs);
                    }
                    else { SetUsersDLists([]); setIsButton(false); }
                }
                else {
                    SetUsersDLists([]);
                    setIsButton(false);
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                }
            }
            else {
                setIsButton(false);
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
            }
        } catch (error) {
            setIsButton(false);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const asyncFunAccessPageRelationDetails = async (moduleId, usertypeid) => {
        try {

            let url = URL_Start + 'users/getuserPageRelationmasterdetails';
            let options = {
                method: 'POST', url: url,
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
                data: { ModuleId: `${moduleId}`, USERTYPEID: `${usertypeid}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) { SettbAccessPageRMaster(ds); }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                }
            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
            }
        } catch (error) {
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const asyncFunModuleDDLDetails = async () => {
        try {

            let url = URL_Start + 'master/getBindModuledetails';
            let options = {
                method: 'POST', url: url,
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
                data: {}
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) {
                        SetModuleDetail(ds.map((option) => (<option value={option.Mid}>{option.ModuleName}</option>)));
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                }
            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
            }
        } catch (error) {
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const asyncFunUserGroupMappingDetails = async (moduleId, UserId) => {
        try {

            let url = URL_Start + 'users/getuserGroupMapping';
            let options = {
                method: 'POST', url: url, headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
                data: { ModuleId: `${moduleId}`, UserId: `${UserId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        if (ds.PageAccessGroupMasterList != null && ds.PageAccessGroupMasterList.length > 0) {
                            const TypeOptGroup = [];
                            globaltblAllGroups = [];
                            ds.PageAccessGroupMasterList.map((item1) => {
                                if (globaltblExistGroups.map(val => val["PageAccessGroupId"]).indexOf(item1.PageAccessGroupId) == -1) {
                                    const item = {
                                        "PageAccessGroupId": item1.PageAccessGroupId,
                                        "AccessGroupName": item1.AccessGroupName,
                                        "ModuleId": item1.ModuleId,
                                        "LocationStates": globallocationStateList.length == 0 ? "" : stringStates,
                                        "LocationRegions": globallocationStateList.length > 0 && LGlbLocationRegionList.length == 0 ? stringRegions : stringRegions,
                                        "LocationDistricts": LGLocationDistrictList.length == 0 ? stringDistricts : stringDistricts,
                                        "LocationCities": "",
                                        "LocationStateIds": globallocationStateList.length == 0 ? "0" : stringStateIds,
                                        "LocationRegionIds": globallocationStateList.length > 0 && LGlbLocationRegionList.length == 0 ? "0" : stringRegionIds,
                                        "LocationDistrictIds": LGLocationDistrictList.length == 0 ? "0" : stringDistricts,
                                        "LocationCityIds": "0",
                                        "IsAlreadyHaveDB": item1.IsAlreadyHaveDB,
                                        "LocZoneId": "1"
                                    };
                                    return globaltblAllGroups.push(item);
                                }
                            });
                        }
                        else { globaltblAllGroups = []; }
                        if (ds.UserGroupsMappingList != null && ds.UserGroupsMappingList.length > 0) {
                            const ExistTypeOptGroup = [];
                            ds.UserGroupsMappingList.map((item1) => {
                                if (globaltblExistGroups.map(val => val["PageAccessGroupId"]).indexOf(item1["PageAccessGroupId"]) == -1) {
                                    const item = {
                                        "PageAccessGroupId": item1.PageAccessGroupId,
                                        "AccessGroupName": item1.AccessGroupName,
                                        "ModuleId": item1.ModuleId,
                                        "LocationStates": item1.LocationStates == "" ? "" : item1.LocationStates,
                                        "LocationRegions": item1.LocationRegions == "" ? "" : item1.LocationRegions,
                                        "LocationDistricts": item1.LocationDistricts == "" ? "" : item1.LocationDistricts,
                                        "LocationCities": item1.LocationCities == "" ? "" : item1.LocationCities,
                                        "LocationStateIds": item1.LocationStateIds,
                                        "LocationRegionIds": item1.LocationRegionIds,
                                        "LocationDistrictIds": item1.LocationDistrictIds,
                                        "LocationCityIds": item1.LocationCityIds,
                                        "IsAlreadyHaveDB": item1.IsAlreadyHaveDB,
                                        "LocZoneId": item1.LocZoneId,
                                        "CentreIds": item1.CentreIds,
                                        "CentreNames": item1.CentreNames
                                    };
                                    return globaltblExistGroups.push(item);
                                }
                            });
                        }
                        SettblUserGroupMapping(ds);
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                }
            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
            }
        } catch (error) {
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const [modal, setModal] = useState();
    const toggleModal = () => { setModal(!modal) }
    const [errorModal, setErrorModal] = useState();
    const errorHandler = () => {
        if (globaltblExistGroups.length == 0) { globalPageSelection = true; } else { globalPageSelection = false; }
        SetIsUserView(false);
        setModal(true); setErrorModal(true);
        formik.handleSubmit();
    }
    const formik = useFormik({
        initialValues: { module: '', userPlatform: '1', PageAccessGroupId: '0', Users: '', partnertype: '' },
        enableReinitialize: true,
        validationSchema: Yup.object({
            module: Yup.string().transform(v => v === '0' ? '' : v).required('Module is Required'),
            Users: Yup.string().transform(v => v === '0' ? '' : v).required('User is Required')
        }),
        isSubmitting: true,
        onSubmit: async (values, actions) => {
            if (globalPageSelection == true) { SetIsUserView(false); setModal(true); setErrorModal(true); }
            else if (globallocationStateList.length == 0) { setModal(true); setErrorModal(true); }
            else if (LocationRegionList.length > 0 && LGlbLocationRegionList.length == 0) { setModal(true); setErrorModal(true); }
            else if (formik.values.module === "2" && glbSelectedCentreIds.length == 0) { setModal(true); setErrorModal(true); }
            else {
                setModal(false);
                setErrorModal(false);
                setErrorPopUp("");
                try {
                    let url = URL_Start + 'Users/SETUPDATEUserGroupMapping';
                    let options = {
                        method: 'POST', url: url,
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
                        data: {
                            UserId: globalUserId, AddedBy: `${LoginId}`, objtblUserGroupMappingType: globaltblExistGroups, objTblLocationZoneIds: LGLocationZoneList,
                            objTblLocationStateIds: globallocationStateList, objTblLocationRegionIds: LGlbLocationRegionList, objTblLocationDistrictIds: LGLocationDistrictList, CentreIds: glbSelectedCentreIds
                        }
                    };

                    let response = await axios(options);
                    let responseOK = response && response.status == 200;
                    if (responseOK) {
                        let data = response.data;
                        if (data.Success == true && data.Data == "2000") {
                            textSearch.current.value = "";
                            SetTXtSearch("");
                            formik.resetForm();
                            globaltblExistGroups = [];
                            globaltblAllGroups = [];
                            SetuserModuleShow(false);
                            SetUsersDLists([]);
                            globalModuleId = "0";
                            globallocationStateList = [];
                            LGLocationDistrictList = [];
                            LGlbLocationRegionList = [];
                            SetselectedGlocationRegionList([]);
                            SetLocationRegionList([]);
                            SetselectedGlocationStateList([]);
                            SetIsSearchUsers(false);
                            setErrorModal(false);
                            globalPageSelection = false;
                            userPlatformChangeHandler("1");
                            SweetAlert.fire({ title: "Success!", text: "Access Group has been Mapped!", icon: "success" });
                        }
                        else {
                            if (data.ErrorList != null && data.ErrorList.length > 0) {
                                let Errtext = <p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                                SweetAlert.fire({ title: "Error", text: data.ErrorList[0].errorMsg, icon: "error" });
                            }
                        }
                    }
                } catch (error) {
                    let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                    SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
                }
                setTimeout(() => { actions.setSubmitting(false); }, 1000);
            }
        },
    });
    const userPlatformChangeHandler = (platFormId) => {
        const s1 = platFormId;
        formik.setFieldValue('userPlatform', platFormId);
        formik.setFieldValue('module', 0);
        SetUsersDLists([]);
        SetselectedGlocationStateList([]);
        globallocationStateList = [];
        LGlbLocationRegionList = [];
        SetLocationRegionList([]);
        SetLocationDistrictList([]);
        SetselectedGlocationRegionList([]);
        SetLocationDistrictList([]);
        SetselectedGlocationDistrictList([]);
        globalModuleId = "0";
        GlobalPartnerType = "0";
        SetuserModuleShow(false);
        globaltblExistGroups = [];
        globaltblAllGroups = [];
        SetIsSearchUsers(true); globaluserPlatform = s1;
        if (s1 == "2") { SetIsPartnerTypeShow(true); } else { SetIsPartnerTypeShow(false); }
        if (s1 == "1") {
            FlagUserCustFilterOnly = "0";
            SetSearchBox("Filter: Department OR Designation .....");
            SetIsSearchUsers(true);
            SetUsersDLists([]);
            asyncFunUsersDetails(globaluserPlatform, "");
        }
        else { SetIsSearchUsers(false); }
    }
    const userGroupChangeHandler = (option) => {
        globaltblExistGroups = [];
        globaltblAllGroups = [];
        const value = (option.length > 0) ? option[0].name : '';
        const idval = (option.length > 0) ? option[0].id : '';
        formik.setFieldValue('Users', idval);
        const TypeOpt = [];
        option.map((item) => {
            const TypeOptrows = { 'UserId': item.id }
            return TypeOpt.push(TypeOptrows);
        });
        const s1 = idval;
        globalUserId = s1;
        handleModuleChange("0");
    }
    const handleModuleChange = (mId) => {
        globalModuleId = mId;
        if (mId == '0') {
            globaltblAllGroups = [];
            SetIsUserTypeMoudle(false);
            formik.setFieldValue('module', '');
        }
        else {
            if (globalModuleId != "0" && globalUserId != "0") {
                asyncFunUserGroupMappingDetails(globalModuleId, globalUserId);
            }
            formik.setFieldValue('module', mId);
        }
        if (mId == "1") { formik.setFieldValue('moduletype', "0"); SetIsUserTypeMoudle(true); }
        else {
            if (mId == "0") { formik.values.module = "0"; }
            else {
                asyncFunAccessPageRelationDetails(globalModuleId, 0);
                SetIsUserTypeMoudle(false);
            }
        }
        if (globallocationStateList.length > 0 && LGlbLocationRegionList.length == 0) {
            stringRegions = "Selected All Regions";
            stringRegionIds = "0";
            stringDistricts = "Selected All Districts";
            stringDistrictIds = "0";
        }
        else {
            stringRegions = "";
            stringRegionIds = "0";
            stringDistricts = "";
            stringDistrictIds = "0";
        }
        globaltblAllGroups = globaltblAllGroups.map(el => (
            el.IsAlreadyHaveDB == "False" ? { ...el, LocationRegions: stringRegions, LocationRegionIds: stringRegionIds, LocationDistricts: stringDistricts, LocationDistrictIds: stringDistrictIds } : el
        ));
        setCentresList([]);
        SetSelectedCentres([]);
    }
    const ModuleChangeHandler = (e) => {
        handleModuleChange(e.target.value);
        if (e.target.value === "2" && LGlbLocationRegionList.length > 0) { GetCentresList(); } else { setCentresList([]); SetSelectedCentres([]); }
    }

    const PrevBckHandler = async () => {
        SetTXtSearch("");
        formik.resetForm();
        globaltblExistGroups = [];
        globaltblAllGroups = [];
        SetuserModuleShow(false);
        SetIsPartnerTypeShow(false);
        SetUsersDLists([]);
        globalModuleId = "0";
        SetIsSearchUsers(false);
        globaltblExistGroups = [];
        globaltblAllGroups = [];
        SetuserModuleShow(false);
        SetIsPartnerTypeShow(false);
        SetUsersDLists([]);
        globallocationStateList = [];
        LGLocationDistrictList = [];
        LGlbLocationRegionList = [];
        SetselectedGlocationRegionList([]);
        SetLocationDistrictList([]);
        SetselectedGlocationDistrictList([]);
        SetLocationRegionList([]);
        SetLocationDistrictList([]);
        SetselectedGlocationStateList([]);
        userPlatformChangeHandler("1");
        SetUsersDLists([]);
       setIsButton(false);
       typeahead.current.clear();
       
    }
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionnewFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <ViewToolTip title="View" onClick={() => ViewHandler(rowData.PageAccessGroupId)} />
                <AddToolTip title="Add" placement="top" id={rowData.Id} onClick={(e) => AddGroup(rowData.PageAccessGroupId, rowData.AccessGroupName, rowData.ModuleId,
                    rowData.IsAlreadyHaveDB, rowData.LocationCities, rowData.LocationCityIds, rowData.LocationDistrictIds, rowData.LocationDistricts,
                    rowData.LocationRegionIds, rowData.LocationRegions, rowData.LocationStateIds, rowData.LocationStates, rowData.LocZoneId)} />
            </div>
        </React.Fragment>
    }
    const actionExistingFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <ViewToolTip title="View" onClick={() => ViewHandler(rowData.PageAccessGroupId)} />
                {rowData.IsAlreadyHaveDB == "True" ? <EditToolTip title="Edit" onClick={() => editHandler(rowData.PageAccessGroupId, rowData.ModuleId, globalUserId)} /> : null}
                <SubtractToolTip title="Remove" placement="top" id={rowData.Id} onClick={(e) => RemoveGroup(rowData.PageAccessGroupId, rowData.AccessGroupName, rowData.ModuleId,
                    rowData.IsAlreadyHaveDB, rowData.LocationCities, rowData.LocationCityIds, rowData.LocationDistrictIds, rowData.LocationDistricts,
                    rowData.LocationRegionIds, rowData.LocationRegions, rowData.LocationStateIds, rowData.LocationStates, rowData.LocZoneId, rowData.CentreIds
                )} />
            </div>
        </React.Fragment>
    }
    const LocationNewViewFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <p><strong>Location States:</strong> {rowData.LocationStates}</p>
                <p><strong>Location Regions:</strong> {rowData.LocationRegions}</p>
            </div>
        </React.Fragment>
    }
    const LocationExistsViewFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <p><strong>Location States:</strong> {rowData.LocationStates}</p>
                <p><strong>Location Regions:</strong> {rowData.LocationRegions}</p>
            </div>
        </React.Fragment>
    }
    const Newcolumns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'AccessGroupName', text: 'Group', editable: false },
        { dataField: 'CentreNames', text: 'Centres', editable: false },
        { text: 'Location', editable: false, formatter: LocationNewViewFormatter },
        { dataField: 'actions', isDummyField: true, text: 'Actions', editable: false, formatter: actionnewFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    const Existcolumns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'AccessGroupName', text: 'Added Group', editable: false },
        { dataField: 'CentreNames', text: 'Centres', editable: false },
        { text: 'Location', editable: false, formatter: LocationExistsViewFormatter },
        { dataField: 'actions', isDummyField: true, text: 'Actions', editable: false, formatter: actionExistingFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    const AddGroup = async (PageAccessGroupId, AccessGroupName, ModuleId, IsAlreadyHaveDB, LocationCities, LocationCityIds, LocationDistrictIds, LocationDistricts,
        LocationRegionIds, LocationRegions, LocationStateIds, LocationStates, LocZoneId) => {
        let UnWantStates = "";
        let HasMatchCheck = false;
        let GlbArrUnMatchStates = selectedGlocationStateList.filter(d1 => !selectedGlocationRegionList.some(d2 => d1.StateId === d2.StateId));
        if (GlbArrUnMatchStates != null && GlbArrUnMatchStates.length > 0) {
            HasMatchCheck = true;
            GlbArrUnMatchStates = GlbArrUnMatchStates.sort((a, b) => a.value - b.value);
            UnWantStates = GlbArrUnMatchStates.map(({ label }) => `${label}`).join(',');
        }
        let hasMatchFirst = false;
        let hasMatchSecond = false;
        SetIsUserView(false);
        globalPageSelection = false;
        // var dd = selectedGlocationRegionList;
        // var dd1 = selectedGlocationDistrictList;
        if (globallocationStateList.length == 0) { setModal(true); setErrorModal(true); }
        else if (GGLocationRegionList.length > 0 && LGlbLocationRegionList.length == 0) { setModal(true); setErrorModal(true); }
        else {
            setModal(false);
            setErrorModal(false);
            setErrorPopUp("");
            hasMatchFirst = globaltblExistGroups.some(function (d) {
                return d.ModuleId == ModuleId && d.LocationStateIds == LocationStateIds && d.LocationRegionIds == LocationRegionIds && d.LocationDistrictIds == LocationDistrictIds
            });
            hasMatchFirst = globaltblExistGroups.some(function (d) {
                return d.ModuleId == ModuleId && d.LocationStateIds.split(',').some(host => LocationStateIds.split(',').indexOf(host) != -1)
                    && d.LocationRegionIds.split(',').some(host => LocationRegionIds.split(',').indexOf(host) != -1)
                    && d.LocationDistrictIds.split(',').some(host => LocationDistrictIds.split(',').indexOf(host) != -1)
            });
            hasMatchSecond = globaltblExistGroups.some(function (d) {
                return d.ModuleId == ModuleId && ((d.LocationStateIds.split(',').some(host => LocationStateIds.split(',').indexOf(host) != -1)
                    && ((d.LocationRegionIds.split(',').some(host => host == "0") && d.LocationDistrictIds.split(',').some(host => host == "0"))
                    ))
                )
            });
            if (!hasMatchSecond) {
                hasMatchSecond = globaltblExistGroups.some(function (d) {
                    return d.ModuleId == ModuleId &&
                        d.LocationStateIds.split(',').some(host => LocationStateIds.split(',').indexOf(host) != -1)
                        && d.LocationStateIds.split(',').some(host => selectedGlocationRegionList.map(val => val["StateId"]).indexOf(host) != -1)
                        && d.LocationStateIds.split(',').some(host => selectedGlocationDistrictList.map(val => val["StateId"]).indexOf(host) != -1)
                        && d.LocationRegionIds.split(',').some(host => selectedGlocationRegionList.map(val => val["RegionId"]).indexOf(host) != -1)
                        && d.LocationRegionIds.split(',').some(host => selectedGlocationDistrictList.map(val => val["RegionId"]).indexOf(host) != -1)
                        && d.LocationDistrictIds.split(',').some(host => selectedGlocationDistrictList.map(val => val["DistrictId"]).indexOf(host) != -1)
                });
            }
            if (HasMatchCheck) {
                SweetAlert.fire({ title: "Warning", text: "You d'nt have regions choose any  these " + UnWantStates + " . Please choose Properly other wise Remove  these states", icon: "warning" });
            }
            else if (hasMatchFirst) {
                SweetAlert.fire({ title: "Warning", text: "You  can added only single group in section. if you want added new group  first remove then added new one.", icon: "warning" });
            }
            else if (hasMatchSecond && hasMatchFirst == false) {
                SweetAlert.fire({ title: "Warning", text: "You  can not repeat same location. if you want added new group  first remove related location then added new one.", icon: "warning" });
            }
            else {
                globaltblAllGroups = globaltblAllGroups.filter((d) => d.PageAccessGroupId !== PageAccessGroupId);
                let jsonRow = {
                    "PageAccessGroupId": PageAccessGroupId, "AccessGroupName": AccessGroupName, "ModuleId": ModuleId,
                    "LocationStates": LocationStates,
                    "LocationRegions": LocationRegions,
                    "LocationDistricts": LocationDistricts,
                    "LocationCities": LocationCities,
                    "LocationStateIds": LocationStateIds,
                    "LocationRegionIds": LocationRegionIds,
                    "LocationDistrictIds": LocationDistrictIds,
                    "LocationCityIds": LocationCityIds,
                    "IsAlreadyHaveDB": IsAlreadyHaveDB,
                    "LocZoneId": LocZoneId,
                    "CentreNames": glblCentreNames,
                    "CentreIds": glblCentreIds
                }
                globaltblExistGroups.push(jsonRow);
            }
        }
    }
    const RemoveGroup = async (PageAccessGroupId, AccessGroupName, ModuleId, IsAlreadyHaveDB, LocationCities, LocationCityIds, LocationDistrictIds, LocationDistricts,
        LocationRegionIds, LocationRegions, LocationStateIds, LocationStates, LocZoneId,OrgCentreIds) => {
        let jsonRow = {
            "PageAccessGroupId": PageAccessGroupId, "AccessGroupName": AccessGroupName, "ModuleId": ModuleId,
            "LocationStates": LocationStates == "" ? "" : LocationStates,
            "LocationRegions": LocationRegions == "" ? "" : LocationRegions,
            "LocationDistricts": LocationDistricts == "" ? "" : LocationDistricts,
            "LocationCities": LocationCities,
            "LocationStateIds": LocationStateIds,
            "LocationRegionIds": LocationRegionIds,
            "LocationDistrictIds": LocationDistrictIds,
            "LocationCityIds": LocationCityIds,
            "IsAlreadyHaveDB": IsAlreadyHaveDB,
            "LocZoneId": LocZoneId,
            "CentreNames": glblCentreNames,
            "CentreIds": glblCentreIds
        }
        globaltblExistGroups = globaltblExistGroups.filter((d) => d.PageAccessGroupId !== PageAccessGroupId);
        globaltblAllGroups.push(jsonRow);      
    }
    const ViewHandler = (PageAccessGroupId) => {
        setModal(true);
        SetIsUserView(true);
        BindAccessGroupUID(PageAccessGroupId)
    }
    const editHandler = async (PageAccessGroupId, ModuleId, globalUserId) => {
        localStorage.removeItem('GroupPageAccessGroupId');
        localStorage.setItem('GroupPageAccessGroupId', PageAccessGroupId);
        localStorage.removeItem('GroupModuleId');
        localStorage.setItem('GroupModuleId', ModuleId);
        localStorage.removeItem('GroupUId');
        localStorage.setItem('GroupUId', globalUserId);
        history.push({ pathname: `${process.env.PUBLIC_URL}/user-master/editAccessGroupLocation`, search: `` });
    }
    async function BindAccessGroupUID(PageAccessGroupId) {
        try {

            let url = URL_Start + 'users/getpageAccessGroupByAcesId';
            let options = {
                method: 'POST', url: url, headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
                data: { PageAccessGroupId: `${PageAccessGroupId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        setErrorModal(false);
                        await SetViewAccessGroups(ds);
                    }
                }
                else { let Errtext = <p><div className='text-required'> no record found </div></p>; }
            }
            else {
                setErrorModal(false);
                SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false);
            }
        } catch (error) {
            if (error.response) { setErrorModal(false); ErrorFunction(error.response.status); }
        }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401") SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403") SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404") SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500") SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503") SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    const stateSelectionHandler = (options) => {
        SetselectedGlocationStateList(options);
        if (options != null && options.length > 0) {
            globallocationStateList = [];
            options.map((option) => {
                const TypeOptions = { 'StateId': option.value }
                return globallocationStateList.push(TypeOptions);
            });
            asyncFunRegionLocationBindDetails(true);
        }
        else {
            globallocationStateList = [];
            LGlbLocationRegionList = [];
            SetLocationRegionList([]);
            SetLocationDistrictList([]);
            SetselectedGlocationRegionList([]);
            SetLocationDistrictList([]);
            SetselectedGlocationDistrictList([]);
            asyncFunRegionLocationBindDetails(false);
            stringRegions = "";
            stringRegionIds = "0";
            stringDistricts = "";
            stringDistrictIds = "0";
            globaltblAllGroups = globaltblAllGroups.map(el => (
                el.IsAlreadyHaveDB == "False" ? { ...el, LocationRegions: stringRegions, LocationRegionIds: stringRegionIds, LocationDistricts: stringDistricts, LocationDistrictIds: stringDistrictIds } : el
            ));
        }
        handleModuleChange("0");
    }
    const RegionSelectionHandler = (options) => {
        if (options != null && options.length > 0) {
            let hasMatchSecond = globaltblExistGroups.some(function (d) {
                return d.ModuleId == globalModuleId && d.LocationRegionIds.split(',').some(host => options.map(val => val["IsHaveDistrictStatus"] == "0" && val["RegionId"]).indexOf(host) != -1)
            });
            if (hasMatchSecond) {
                SweetAlert.fire({ title: "Warning", text: "you have already choose selected region. choose different region", icon: "warning" });
            }
            else {
                SetselectedGlocationRegionList(options);
                LGlbLocationRegionList = [];
                const TypeOptDocL = [];
                options.map((option) => {
                    const item = { "RegionId": option.value };
                    LGlbLocationRegionList.push(item);
                });
            }
        }
        else {
            SetselectedGlocationRegionList(options);
            LGlbLocationRegionList = [];
            SetLocationDistrictList([]);
            SetuserModuleShow(false);
        }
        SelectedAllStateFlag = false;
        isFirstRender.current = true;
        handleModuleChange("0");
    }
    const customStateValRenderer = (selected, _options) => {
        GGLocationRegionList = [];
        GGLocationDistrictList = [];
        if (selected.length > 0 && _options.length > 0 && selected.length < _options.length) {
            SelectedAllStateFlag = false;
            stringStates = "";
            stringStateIds = "";
            selected = selected.sort((a, b) => a.value - b.value);
            stringStates = selected.map(({ label }) => `${label}`).join(',');
            stringStateIds = selected.map(({ value }) => `${value}`).join(',');
            globaltblAllGroups = globaltblAllGroups.map(el => (
                el.IsAlreadyHaveDB == "False" ? { ...el, LocationStates: stringStates, LocationStateIds: stringStateIds } : el
            ));
        }
        else if (selected.length > 0 && _options.length > 0 && (selected.length == _options.length)) {
            SelectedAllStateFlag = true;
            stringStates = "Selected All States";
            stringStateIds = "";
            selected = selected.sort((a, b) => a.value - b.value);
            stringStateIds = selected.map(({ value }) => `${value}`).join(',');
            globaltblAllGroups = globaltblAllGroups.map(el => (
                el.IsAlreadyHaveDB == "False" ? { ...el, LocationStates: stringStates, LocationStateIds: stringStateIds } : el
            ));
        }
        else {
            SelectedAllStateFlag = false;
            stringStates = "";
            stringStateIds = "";
            globaltblAllGroups = globaltblAllGroups.map(el => (
                el.IsAlreadyHaveDB == "False" ? { ...el, LocationStates: stringStates, LocationStateIds: stringStateIds } : el
            ));
        }
    };
    const RegionValueRenderer = (selected, _options) => {
        if (_options.length > 0) {
            GGLocationRegionList = [];
            GGLocationRegionList = _options;
        }
        else {
            GGLocationRegionList = [];
            GGLocationDistrictList = [];
        }
        if (selected.length > 0 && _options.length > 0 && selected.length < _options.length) {
            SelectedAllStateFlag = false;
            stringRegions = "";
            stringRegionIds = "";
            selected = selected.sort((a, b) => a.value - b.value);
            stringRegions = selected.map(({ label }) => `${label}`).join(',');
            stringRegionIds = selected.map(({ value }) => `${value}`).join(',');
            globaltblAllGroups = globaltblAllGroups.map(el => (
                el.IsAlreadyHaveDB == "False" ? { ...el, LocationRegions: stringRegions, LocationRegionIds: stringRegionIds } : el
            ));
        }
        else if (selected.length > 0 && _options.length > 0 && (selected.length == _options.length)) {

            stringRegions = "Selected All Regions";
            selected = selected.sort((a, b) => a.value - b.value);
            stringRegionIds = selected.map(({ value }) => `${value}`).join(',');
            globaltblAllGroups = globaltblAllGroups.map(el => (
                el.IsAlreadyHaveDB == "False" ? { ...el, LocationRegions: stringRegions, LocationRegionIds: stringRegionIds } : el
            ));
        }
        else {
            stringRegions = "";
            stringRegionIds = "";
            if (globallocationStateList.length > 0 && _options.length == 0) {
                stringRegions = "Selected All Regions";
                stringRegionIds = "0";
                stringDistricts = "Selected All Districts";
                stringDistrictIds = "0";
            }
            else {
                stringRegions = "";
                stringRegionIds = "0";
                stringDistricts = "";
                stringDistrictIds = "0";
            }
            globaltblAllGroups = globaltblAllGroups.map(el => (
                el.IsAlreadyHaveDB == "False" ? { ...el, LocationRegions: stringRegions, LocationRegionIds: stringRegionIds, LocationDistricts: stringDistricts, LocationDistrictIds: stringDistrictIds } : el
            ));
        }
    };
    const GetCentresList = async () => {
        setIsLoading(true);
        try {
            setCentresList([]);
            const requestOptions = { RegionIds: JSON.stringify(LGlbLocationRegionList) };
            let url = URL_Start + 'users/getcentersbyregion'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsLoading(false);
                    console.log(data.Data);
                    if (data.Data != null) {
                        let ds = data.Data;
                        if (ds != null && ds.length > 0) {
                            const TypeOptionDs = [];
                            ds.map((option) => {
                                const TypeOptions = { 'label': option.Value, 'value': option.Id, 'disabled': false }
                                return TypeOptionDs.push(TypeOptions);
                            });
                            setCentresList(TypeOptionDs);
                        }
                        else { setCentresList([]); SetSelectedCentres([]); }
                    }
                    else { APIError(data.Data); }
                }
                else { setIsLoading(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
        } catch (error) { setIsLoading(false); APIError("-3"); }
    }
    const CentreChangeHandler = (options) => {
        SetSelectedCentres(options);
        glblCentreIds = [];
        glblCentreNames = [];
        glblCentreIds = options.map(({ value }) => `${value}`).join(',');
        glblCentreNames = options.map(({ label }) => `${label}`).join(',');
        globaltblAllGroups = globaltblAllGroups.map(el => (
            el.IsAlreadyHaveDB == "False" ? { ...el, CentreNames: glblCentreNames, CentreIds: glblCentreIds } : el
        ));
        glbSelectedCentreIds = [];
        options.map((option) => { const item = { "CentreId": option.value }; glbSelectedCentreIds.push(item); });
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Data unavailable", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-2") { SweetAlert.fire({ title: "API Server Error", text: ErrorMsg, icon: "error" }); }
        else if (ErrorCode === "-3") { SweetAlert.fire({ title: "Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        else if (ErrorCode === "-4") { SweetAlert.fire({ title: "Wrong Credentials", text: "Please enter correct credentials", icon: "error" }) }
        else if (ErrorCode === "-5") { SweetAlert.fire({ title: "Oops", text: "Data could not be saved, Please try again later", icon: "error" }) }
        else if (ErrorCode === "-6") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
        else if (ErrorCode === "-7") { SweetAlert.fire({ title: "Duplicate", text: "Template already exists", icon: "error" }) }
        else if (ErrorCode === "-8") { SweetAlert.fire({ title: "Data unavailable", text: "No record found to import", icon: "error" }) }
        else if (ErrorCode === "-9") { SweetAlert.fire({ title: "LDAP authentication failed", text: ErrorMsg, icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }

    return (
        <Fragment>
            <Breadcrumb title="Access Group Mapping" parent="Master" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <div class="pb-0 card-header"><div class="card-title h5">Add Access Group Mapping</div></div>
                            <Card.Body>
                                <Form.Row>
                                    {IsSearchUsers && <>
                                        <Col lg={3}>
                                            <Form.Group>
                                                <Form.Label>Filter Users List<span className='text-required'>*</span></Form.Label>
                                                <InputGroup className="mb-3">
                                                    <Form.Control onChange={handleSearch} placeholder={SearchBox} ref={textSearch} value={TXtSearch} />
                                                    <InputGroup.Append>
                                                        <Button variant="secondary" onClick={handleSearch}><i className='fa fa-search'></i></Button>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>HRMS Users<span className='text-required'>*</span></Form.Label>
                                                {isButton ? <Spinner animation="border" size='sm' /> : null}
                                                <Typeahead
                                                    id="Users" name="Users" defaultSelected={UsersDLists.slice(0, 5)} labelKey="name" ref={typeahead} options={UsersDLists} value={formik.values.Users}
                                                    onChange={userGroupChangeHandler}
                                                    placeholder="---Select User---" />
                                                {formik.touched.Users && formik.errors.Users ? (<div className='text-required'>{formik.errors.Users}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                    </>
                                    }
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>State<span className='text-required'>*</span></Form.Label>
                                            <MultiSelect id="stateSelect" name="stateSelect" options={LocationStateList} value={selectedGlocationStateList}
                                                onChange={stateSelectionHandler} valueRenderer={customStateValRenderer} labelledBy="Select" />
                                            {globallocationStateList.length == 0 ? (<div className='text-required'>{"State is Required"}</div>) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Group>
                                            <Form.Label>Region<span className='text-required'>*</span></Form.Label>
                                            <MultiSelect id="regionSelect" name="regionSelect"
                                                options={LocationRegionList}
                                                disabled={SelectedAllStateFlag}
                                                value={selectedGlocationRegionList}
                                                onChange={RegionSelectionHandler}
                                                valueRenderer={RegionValueRenderer}
                                                labelledBy="Select"
                                            />
                                            {LocationRegionList.length > 0 && selectedGlocationRegionList.length == 0 ? (<div className='text-required'>{"Region is required"}</div>) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Module<span className='text-required'>*</span></Form.Label>
                                            <Form.Control as="select" name="module" onChange={ModuleChangeHandler} onBlur={formik.handleBlur} value={formik.values.module}>
                                                <option value='0'>---select---</option>
                                                {ModuleDetail}
                                            </Form.Control>
                                            {formik.touched.module && formik.errors.module ? (<div className='text-required'>{formik.errors.module}</div>) : null}
                                        </Form.Group>
                                    </Col>
                                    {
                                        formik.values.module === "2" ?
                                            <Col md={3}>
                                                <Form.Group>
                                                    <Form.Label>Centres<span className='text-required'>*</span></Form.Label>
                                                    <MultiSelect id="centreIds" name="centreIds" options={centresList} labelledBy="Select centres" value={selectedCentres} onChange={CentreChangeHandler} />
                                                    {centresList.length == 0 ? (<div className='text-required'>{"Centre is Required"}</div>) : null}
                                                </Form.Group>
                                            </Col>
                                            : null
                                    }
                                </Form.Row>
                                {globalModuleId != "0" && globalUserId != "0" ? <>
                                    <Form.Row className="justify-content-left">
                                        <div class="pb-0 card-header"><div class="card-title h5">New groups</div></div>
                                    </Form.Row>
                                    <Form.Row className="justify-content-center">
                                        <Col md={12} lg={12}>
                                            <DataTables keyField='PageAccessGroupId' tableData={globaltblAllGroups} columns={Newcolumns} noDataIndication={NoRecordFounds} />
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className="justify-content-left mt25">
                                        <Col md={6} lg={6}>
                                            <p><div class="card-title h5">Added groups</div></p>
                                            <p><div className='text-required'>*At least one Added  group</div></p></Col><Col md={6} lg={6}></Col>
                                    </Form.Row>
                                    <Form.Row className="justify-content-center">
                                        <Col md={12} lg={12}>
                                            <DataTables keyField='PageAccessGroupId' tableData={globaltblExistGroups} columns={Existcolumns} noDataIndication={NoRecordFounds} />
                                        </Col>
                                    </Form.Row>
                                </> : null
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className='mt15'>
                    <Col md={12} className='text-center'>
                        <Button variant='secondary' className="mr-2" onClick={PrevBckHandler} >Cancel</Button>
                        {<Button variant='primary' type='submit' onClick={errorHandler}>Save</Button>}
                    </Col>
                </Row>
            </Container>
            {
                IsUserView &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true} size="lg">
                    <ModalHeader toggle={toggleModal}>View Access Group Detail</ModalHeader>
                    <ModalBody>
                        {ViewAccessGroups != null ? <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <Form.Row className="justify-content-left">
                                            {ViewAccessGroups.map((item) => {
                                                return (
                                                    <>
                                                        <Col lg={6} md={6}>
                                                            <span className="form-control-plaintext" >{item.GroupPageNames == '' ? 'N/A' : item.GroupPageNames}</span>
                                                        </Col>
                                                    </>
                                                )

                                            })}
                                        </Form.Row>

                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row> : null

                        }

                    </ModalBody>
                    <ModalFooter>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            }



            {
                errorModal &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                    <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                    <ModalBody>
                        <p>{formik.touched.userPlatform && formik.errors.userPlatform ? (<div className='text-required'>{formik.errors.userPlatform}</div>) : null}</p>
                        <p>{formik.values.partnertype == '' && IsPartnerTypeShow ? (<div className='text-required'>Partner Type required</div>) : null}</p>
                        <p>{formik.touched.Users && formik.errors.Users && IsSearchUsers ? (<div className='text-required'>{formik.errors.Users}</div>) : null}</p>
                        <p>{globallocationStateList.length == 0 ? (<div className='text-required'>{"State is Required"}</div>) : null}</p>
                        <p>{LGlbLocationRegionList.length == 0 ? (<div className='text-required'>{"Region is Required"}</div>) : null}</p>
                        <p>{formik.touched.module && formik.errors.module ? (<div className='text-required'>{formik.errors.module}</div>) : null}</p>
                        <p>{globalPageSelection && globalModuleId != "0" && globalUserId != "0" ? <div className='text-required'>At least one Added  group</div> : null} </p>
                        <p>{formik.touched.module === "2" && LGlbLocationRegionList.length == 0 ? (<div className='text-required'>Region is required</div>) : null}</p>
                        <p>{ formik.values.module === "2" && glbSelectedCentreIds.length == 0 ? (<div className='text-required'>Centre is required</div>) : null}</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                        <Button variant="primary" onClick={toggleModal}>Ok</Button>
                    </ModalFooter>
                </Modal>
            }
        </Fragment >
    )
}

export default AddAccessGroupsMapping;