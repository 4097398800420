import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import SweetAlert from 'sweetalert2';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import LoadingButton from '../commonComponent/LoadingButton';
import Select from 'react-select';

let glbAuthorizersList = [];
const ShortageLimitInfo = (id) => {
    const history = useHistory();
    const [modal, setModal] = useState();
    const toggleModal = () => { setModal(!modal) }
    const [errorModal, setErrorModal] = useState();
    const errorHandler = () => { setModal(true); setErrorModal(true); }
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [authorizersList, setAuthorizersList] = useState([]);
    const [selectedAuthorizer, SetSelectedAuthorizer] = useState("0");
    const [userName, SetUserName] = useState("0");
    const [dailyAvailableLimit, SetDailyAvailableLimit] = useState("0");
    const [monthlyAvailableLimit, SetMonthlyAvailableLimit] = useState("0");
    const [isEdit, SetIsEdit] = useState(false);

    useEffect(() => {glbAuthorizersList = []; GetAuthorizers(); GetDetails(); }, []);

    const GetAuthorizers = async () => {
        try {
            let url = URL_Start + 'master/getshortageauthorizers'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    var itmData = data.Data;
                    itmData.map((option) => { return glbAuthorizersList.push({ 'value': option.Id, 'label': option.Value, 'disabled': false }); });
                }
            }).catch(function (error) { });
            setAuthorizersList(glbAuthorizersList);
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
        console.log(authorizersList);
    }
    const GetDetails = async () => {
        try {
            if (localStorage.getItem('sId') !== null && localStorage.getItem('sId') !== "0") {
                SetIsEdit(true);
                setIsLoading(true);
                formik.values.id = localStorage.getItem('sId');
                let url = URL_Start + 'master/geteditshortagelimitdetails/' + formik.values.id;
                await axios.get(url).then((response) => {
                    const data = response.data;
                    if (data.Success) {
                        const dbInfo = data.Data;
                        formik.values.dailyLimit = dbInfo.DailyMaxLimit;
                        formik.values.monthlyLimit = dbInfo.MonthlyMaxLimit;
                        SetUserName(dbInfo.UserName);
                        SetDailyAvailableLimit(dbInfo.DailyAvailableLimit);
                        SetMonthlyAvailableLimit(dbInfo.MonthlyAvailableLimit);
                        SetSelectedAuthorizer({ 'value': dbInfo.UserId, 'label': dbInfo.UserName, 'disabled': false });
                        setIsLoading(false);
                    } else { setIsLoading(false); }
                }).catch(function (error) { setIsLoading(false); });
            }
            else { SetIsEdit(false); }
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); setIsLoading(false); }
    }
    const AuthorizerSelectHandler = (options) => { 
        SetSelectedAuthorizer(options); 
        // console.log(selectedAuthorizer); 
    }
    const formik = useFormik({
        initialValues: { id: '0', dailyLimit: '', monthlyLimit: '' },
        validationSchema: Yup.object({
            dailyLimit: Yup.number().required('Daily limit is required').typeError('Daily limit must be a number').min(0, 'Min daily limit must be 0.'),
            monthlyLimit: Yup.number().required('Monthly limit is required').typeError('Monthly limit must be a number').min(0, 'Min monthly limit must be 0.'),
        }),
        onSubmit: values => {
            let selectValue = selectedAuthorizer.value;
            if (selectValue == undefined || selectValue == "0" || selectValue == "") { setModal(true); setErrorModal(true); }
            else { setModal(false); setErrorModal(false); SetShortageLimit(values); }
        }
    });
    const SetShortageLimit = async (values) => {
        setIsSaving(true);
        const requestOptions = {
            Id: values.id, UserId: selectedAuthorizer.value, DailyMaxLimit: values.dailyLimit, MonthlyMaxLimit: values.monthlyLimit, AddedBy: localStorage.LoggedInUser
        };
        let url = URL_Start + 'master/setshortagelimit'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                SweetAlert.fire({ title: "Success", text: "Data saved successfully", icon: "success" });
                formik.resetForm();
                localStorage.removeItem('sId');
                history.push({ pathname: `${process.env.PUBLIC_URL}/master/shortagelimits`, search: `` });
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsSaving(false); }
        }).catch(function (error) { setIsSaving(false); });
    }
    const cancelHandler = async (CentreId) => {
        localStorage.removeItem('fbId');
        history.push({ pathname: `${process.env.PUBLIC_URL}/master/shortagelimits`, search: `` });
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Shortage Authorizer Details" parent="Master" />
            <Container fluid>
                {
                    isLoading ?
                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                        <>
                            <Form onSubmit={formik.handleSubmit}>
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <Card.Header className='pb-0'>
                                                <Card.Title>Details</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                {isEdit ?
                                                    <>
                                                        <Form.Row className='text-right justify-content-end'>
                                                            <Col md={3}>
                                                                <Form.Label>Name: <span className='text-red'>{userName}</span></Form.Label>
                                                                <Form.Label>Daily Available Limit: <span className='text-red'>{dailyAvailableLimit}</span></Form.Label>
                                                                <Form.Label>Monthly Available Limit: <span className='text-red'>{monthlyAvailableLimit}</span></Form.Label>
                                                            </Col>
                                                        </Form.Row>
                                                    </> : null
                                                }
                                                <Form.Row>
                                                    <Col md={4}>
                                                        <Form.Group>
                                                            <Form.Label>Select Authorizer<span className='text-required'>*</span></Form.Label>
                                                            <Select onChange={AuthorizerSelectHandler} options={authorizersList} value={selectedAuthorizer} isDisabled={isEdit} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Daily Shortage Limit<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="text" name="dailyLimit" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.dailyLimit} />
                                                            {formik.touched.dailyLimit && formik.errors.dailyLimit ? (<div className='text-required'>{formik.errors.dailyLimit}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Monthly Shortage Limit<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="text" name="monthlyLimit" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.monthlyLimit} />
                                                            {formik.touched.monthlyLimit && formik.errors.monthlyLimit ? (<div className='text-required'>{formik.errors.monthlyLimit}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className='mt15'>
                                    <Col md={12} className='text-center'>
                                        <Button variant='secondary' className="mr-2" onClick={cancelHandler}>Cancel</Button>
                                        {isSaving ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' type='submit' onClick={errorHandler}>Save</Button>}
                                    </Col>
                                </Row>
                            </Form>
                        </>
                }
                {errorModal &&
                    <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                        <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                        <ModalBody>
                            <p>{(selectedAuthorizer.value == undefined || selectedAuthorizer.value == "0") ? (<div className='text-required'>{"Authorizer is Required"}</div>) : null}</p>
                            <p>{formik.touched.dailyLimit && formik.errors.dailyLimit ? (<div className='text-required'>{formik.errors.dailyLimit}</div>) : null}</p>
                            <p>{formik.touched.monthlyLimit && formik.errors.monthlyLimit ? (<div className='text-required'>{formik.errors.monthlyLimit}</div>) : null}</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                            <Button variant="primary" onClick={toggleModal}>Ok</Button>
                        </ModalFooter>
                    </Modal>
                }
            </Container>
        </React.Fragment >
    )
}

export default ShortageLimitInfo;