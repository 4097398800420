import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import SweetAlert from 'sweetalert2';
import { URL_Start, Image_Path } from '../../../constant';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import LoadingButton from '../commonComponent/LoadingButton';
import Select from 'react-select';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import ToolTip from '../commonComponent/ToolTip';

let glbMarkers = [];
let glbMarkerDisplay = [];
let glbMarkerList = [];
let glbTestsList = [];
const PackageDetails = (id) => {
    const history = useHistory();
    const [modal, setModal] = useState();
    const toggleModal = () => { setModal(!modal) }
    const [errorModal, setErrorModal] = useState();
    const errorHandler = () => { setModal(true); setErrorModal(true); }
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [categoriesList, setCategoriesList] = useState();
    const [iconPath, setIconPath] = useState('');
    const [bannerPath, setBannerPath] = useState('');
    const [markersList, setMarkersList] = useState();
    const [testsList, setTestsList] = useState();
    const [selectedTests, SetSelectedTests] = useState("0");
    const [selectedMarker, SetSelectedMarker] = useState("0");
    const [tableData, setTableData] = useState([]);
    const [markerdata, setMarkerData] = useState(false);
    const [totalTests, setTotalTests] = useState(0);
    useEffect(() => {
        glbMarkers = []; glbMarkerDisplay = []; glbMarkerList = []; glbTestsList = [];
        GetDetails(); GetCategories(); GetMarkers();
    }, []);
    useEffect(() => { setTableData(glbMarkerDisplay); setMarkerData(false); }, [markerdata]);
    const GetDetails = async () => {
        try {
            if (localStorage.getItem('pkgId') !== null) {
                setIsLoading(true);
                formik.values.id = localStorage.getItem('pkgId');
                let url = URL_Start + 'master/getpackageinfo/' + formik.values.id;
                await axios.get(url).then((response) => {
                    const data = response.data;
                    if (data.Success) {
                        const dbInfo = data.Data;
                        var pckgCategory = "";
                        if (dbInfo.CategoryId !== "0") { pckgCategory = dbInfo.CategoryId; }
                        formik.values.packageName = dbInfo.PackageName;
                        formik.values.packageDetailsId = dbInfo.PackageDetailsId;
                        formik.values.packageCategory = pckgCategory;
                        formik.values.packageIconName = dbInfo.PackageIconName;
                        if (dbInfo.PackageIconName !== "") { setIconPath(Image_Path + "PackageIcon/" + formik.values.packageIconName); }
                        else { setIconPath(Image_Path + "PackageIcon/NoImage.png"); }
                        formik.values.packageBannerName = dbInfo.PackageBannerName;
                        if (dbInfo.PackageBannerName !== "") { setBannerPath(Image_Path + "PackageBanner/" + formik.values.packageBannerName); }
                        else { setBannerPath(Image_Path + "PackageBanner/NoImage.png"); }
                        formik.values.shortHeading = dbInfo.ShortHeading;
                        formik.values.shortDescription = dbInfo.ShortDescription;
                        formik.values.longHeading = dbInfo.LongHeading;
                        formik.values.longDescription = dbInfo.LongDescription;
                        formik.values.packageDescription = dbInfo.PackageDescription;
                        formik.values.summary = dbInfo.Summary;
                        if (dbInfo.MarkerTestMappings.length > 0) {
                            // dbInfo.MarkerTestMappings.map((x) => { return glbMarkers.push({ 'MrkrId': x.MarkerId, 'Marker': x.MarkerName, 'TestId': x.TestId, 'Test': x.TestName }); });
                            for (var i in dbInfo.MarkerTestMappings) {
                                glbMarkers.push({ 'MrkrId': dbInfo.MarkerTestMappings[i].MarkerId, 'Marker': dbInfo.MarkerTestMappings[i].MarkerName, 'TestId': dbInfo.MarkerTestMappings[i].TestId, 'Test': dbInfo.MarkerTestMappings[i].TestName });
                                for (var x in glbMarkerList) {
                                    if (dbInfo.MarkerTestMappings[i].MarkerId === glbMarkerList[x].value) {
                                        glbMarkerList.splice(x, 1); break;
                                    }
                                }
                            }
                        }
                        if (dbInfo.MarkerTests.length > 0) {
                            dbInfo.MarkerTests.map((x) => { return glbMarkerDisplay.push({ 'MrkrId': x.MarkerId, 'Marker': x.MarkerName, 'Test': x.TestName }); });
                        }
                        setMarkerData(true);
                        if (dbInfo.MarkerTestMappings.length === 0) { GetTestsList(dbInfo.PlabId, true); } else { GetTestsList(dbInfo.PlabId, false); }
                        setIsLoading(false);
                    }
                }).catch(function (error) { setIsLoading(false); });
                setMarkersList(glbMarkerList);
            }
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); setIsLoading(false); }
    }
    const formik = useFormik({
        initialValues: {
            id: '0', packageName: '', packageDetailsId: '', packageCategory: '', packageIconName: '', packageBannerName: '', shortHeading: '', shortDescription: '',
            longHeading: '', longDescription: '', packageDescription: ''
        },
        validationSchema: Yup.object({
            packageCategory: Yup.string().required('Package category is required'),
            shortHeading: Yup.string().required('Short heading is required'),
            shortDescription: Yup.string().required('Short description is required'),
            longHeading: Yup.string().required('Long heading is required'),
            longDescription: Yup.string().required('Long description is required'),
            longDescription: Yup.string().required('long description is required'),
            packageDescription: Yup.string().required('Package description is required'),
        }),
        onSubmit: values => { setModal(false); setErrorModal(false); AddInfo(values); }
    });
    const AddInfo = async (values) => {
        if (totalTests === glbMarkers.length) {
            setIsSaving(true);
            let arryMarkers = [];
            for (var i in glbMarkers) { arryMarkers.push({ 'MrkrId': glbMarkers[i].MrkrId, 'TestId': glbMarkers[i].TestId }); }
            const requestOptions = {
                Id: values.id,
                PackageDetailsId: values.packageDetailsId,
                CategoryId: values.packageCategory,
                PackageIconName: values.packageIconName,
                PackageBannerName: values.packageBannerName,
                ShortHeading: values.shortHeading,
                ShortDescription: values.shortDescription,
                LongHeading: values.longHeading,
                LongDescription: values.longDescription,
                PackageDescription: values.packageDescription,
                AddedBy: localStorage.LoggedInUser,
                MarkerMappings: arryMarkers,
                Summary: values.summary,
            };
            let url = URL_Start + 'master/setpackage'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                if (data.Success) {
                    SweetAlert.fire({ title: "Success", text: "Data saved successfully", icon: "success" });
                    formik.resetForm();
                    localStorage.removeItem('pkgId');
                    history.push({ pathname: `${process.env.PUBLIC_URL}/master/packages`, search: `` });
                }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsSaving(false); }
            }).catch(function (error) { setIsSaving(false); });
        }
        else { SweetAlert.fire({ title: "Validations", text: "Please select all test before continue.", icon: "error" }); }
    }
    const cancelHandler = async (CentreId) => {
        localStorage.removeItem('pkgId');
        history.push({ pathname: `${process.env.PUBLIC_URL}/master/packages`, search: `` });
    }
    const GetCategories = async () => {
        try {
            let url = URL_Start + 'master/getpackagecategorieslist'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setCategoriesList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.CategoryName}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const UploadImages = async (e, folderName) => {
        const FileURL = e.target.files[0].name;
        const BreakURL = FileURL.lastIndexOf('.');
        const extension = FileURL.substring(BreakURL + 1);
        //const extension = e.target.files[0].name.split(".");
        var base64 = await toBase64(e.target.files[0]);
        var base64img = base64.split("base64,");
        const formData = new FormData();
        formData.append('Image', base64img[1]);
        formData.append('FolderName', folderName);
        const randomImageId = Math.random();
        var imgName = localStorage.getItem('pkgId') + folderName + "." + extension; //extension[extension.length - 1];
        formData.append('ImageName', imgName);
        let url = URL_Start + 'master/UploadImage/'
        await axios.post(url, formData, {
            headers: { "Content-type": "multipart/form-data" },
        }).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (folderName === "PackageIcon") { formik.values.packageIconName = imgName; setIconPath(Image_Path + "PackageIcon/" + imgName); }
                else if (folderName === "PackageBanner") { formik.values.packageBannerName = imgName; setBannerPath(Image_Path + "PackageBanner/" + imgName); }
                SweetAlert.fire('Image Selected');
            } else { SweetAlert.fire({ title: "Upload failed", text: "Unable to upload document due to " + data.Data + ".Please retry again", icon: "error" }) }
        }).catch(function (error) { if (error.response) { } });
    }
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const MarkerSelectHandler = (options) => {
        SetSelectedMarker(options);
    }
    const TestSelectHandler = (options) => {
        SetSelectedTests(options);
    }
    const GetTestsList = async (PckgId, bindData) => {
        let url = URL_Start + 'master/getpackageitems/' + PckgId
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data != null) {
                    var itmData = data.Data;
                    setTotalTests(itmData.length);
                    if (bindData) {
                        itmData.map((option) => { return glbTestsList.push({ 'value': option.InvestigationMasterId, 'label': option.TestName, 'disabled': false }); });
                        setTestsList(glbTestsList);
                    }
                    else { setTestsList([]); }
                }
                else { SweetAlert.fire('No data available.') }
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    const GetMarkers = async () => {
        try {
            let url = URL_Start + 'master/getmarkerslist'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    var itmData = data.Data;
                    itmData.map((option) => { return glbMarkerList.push({ 'value': option.Id, 'label': option.Value, 'disabled': false }); });
                }
            }).catch(function (error) { });
            setMarkersList(glbMarkerList);
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const addHandler = async () => {
        if (selectedTests !== "0") {
            var isMarkerExist = false
            var isTestExist = false
            for (var i in glbMarkers) {
                if (glbMarkers[i].MrkrId === selectedMarker.value) { isMarkerExist = true; break; }
                for (var x in selectedTests) {
                    if (glbMarkers[i].TestId === selectedTests[x].value) { isTestExist = true; break; }
                }
                if (isTestExist === true) { break; }
            }
            if (isMarkerExist === false && isTestExist === false) {
                selectedTests.map((x) => {
                    return glbMarkers.push({ 'MrkrId': selectedMarker.value, 'Marker': selectedMarker.label, 'TestId': x.value, 'Test': x.label });
                });
                var testNames = "";
                for (var i in selectedTests) {
                    if (testNames === "") { testNames = selectedTests[i].label; } else { testNames = testNames + " | " + selectedTests[i].label; }
                    for (var n in glbTestsList) {
                        if (selectedTests[i].value === glbTestsList[n].value) {
                            glbTestsList.splice(n, 1); break;
                        }
                    }
                }
                for (var l in glbMarkerList) {
                    if (selectedMarker.value === glbMarkerList[l].value) {
                        glbMarkerList.splice(l, 1); break;
                    }
                }
                setMarkersList(glbMarkerList);
                setTestsList(glbTestsList);
                glbMarkerDisplay.push({ 'MrkrId': selectedMarker.value, 'Marker': selectedMarker.label, 'Test': testNames });
                setMarkerData(true);
                SetSelectedMarker('');
                SetSelectedTests('');
            }
        }
    }
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <ToolTip title="Delete" placement="top" onClick={() => HandleDelete(rowData)} linkId="Delete" faName="fa fa-trash mr-2"></ToolTip>
            </div>
        </React.Fragment>
    }
    const HandleDelete = async (rowData) => {
        var isMarkerAdded = false;
        for (var i in glbMarkers) {
            if (glbMarkers[i].MrkrId === rowData.MrkrId) {
                if (isMarkerAdded === false) {
                    glbMarkerList.push({ 'value': glbMarkers[i].MrkrId, 'label': glbMarkers[i].Marker, 'disabled': false });
                    for (var x in glbMarkerDisplay) {
                        if (glbMarkers[i].MrkrId === glbMarkerDisplay[x].MrkrId) { glbMarkerDisplay.splice(x, 1); }
                    }
                }
                isMarkerAdded = true;
                glbTestsList.push({ 'value': glbMarkers[i].TestId, 'label': glbMarkers[i].Test, 'disabled': false });
            }
        }
        var tmpMarkers = [];
        for (var i in glbMarkers) {
            if (glbMarkers[i].MrkrId !== rowData.MrkrId) {
                tmpMarkers.push({ 'Marker': glbMarkers[i].Marker, 'MrkrId': glbMarkers[i].MrkrId, 'Test': glbMarkers[i].Test, 'TestId': glbMarkers[i].TestId });
            }
        }
        glbMarkers = tmpMarkers;
        setMarkersList(glbMarkerList);
        setTestsList(glbTestsList);
    }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'Marker', text: 'Marker', editable: false, headerStyle: (colum, colIndex) => { return { width: '30%' }; } },
        { dataField: 'Test', text: 'Tests', editable: false, headerStyle: (colum, colIndex) => { return { width: '55%' }; } },
        { dataField: '', text: 'Action', formatter: actionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    ];
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Package Details" parent="Master" />
            <Container fluid>
                {
                    isLoading ?
                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                        <>
                            <Form onSubmit={formik.handleSubmit}>
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <Card.Header className='pb-0'>
                                                <Card.Title>Details</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form.Row>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Name</Form.Label>
                                                            <Form.Control type='text' name="markerTitle" readOnly={true} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.packageName} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Icon</Form.Label>
                                                            {/* <Form.Control type="file" name="packageIconName" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.packageIconName} /> */}
                                                            <div><input type="file" onChange={(e) => UploadImages(e, "PackageIcon")} /></div>
                                                            <img src={iconPath} width="80"></img>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Banner</Form.Label>
                                                            {/* <Form.Control type="text" name="packageBannerName" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.packageBannerName} /> */}
                                                            <div><input type="file" onChange={(e) => UploadImages(e, "PackageBanner")} /></div>
                                                            <img src={bannerPath} width="80"></img>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Package Category<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control as="select" name="packageCategory" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.packageCategory}>
                                                                <option value="">---Select---</option>
                                                                {categoriesList}
                                                            </Form.Control>
                                                            {formik.touched.packageCategory && formik.errors.packageCategory ? (<div className='text-required'>{formik.errors.packageCategory}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Short Heading<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="text" name="shortHeading" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.shortHeading} />
                                                            {formik.touched.shortHeading && formik.errors.shortHeading ? (<div className='text-required'>{formik.errors.shortHeading}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Short Description <span className='text-required'>* (Separate with ~)</span></Form.Label>
                                                            <Form.Control as="textarea" rows={3} name="shortDescription" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.shortDescription} />
                                                            {formik.touched.shortDescription && formik.errors.shortDescription ? (<div className='text-required'>{formik.errors.shortDescription}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Long Heading<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="text" name="longHeading" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.longHeading} />
                                                            {formik.touched.longHeading && formik.errors.longHeading ? (<div className='text-required'>{formik.errors.longHeading}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Long Description <span className='text-required'>* (Separate with ~)</span></Form.Label>
                                                            <Form.Control as="textarea" rows={3} name="longDescription" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.longDescription} />
                                                            {formik.touched.longDescription && formik.errors.longDescription ? (<div className='text-required'>{formik.errors.longDescription}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Home Page Description<span className='text-required'>* (max-length: 200 characters)</span></Form.Label>
                                                            <Form.Control as="textarea" maxlength="200" rows={3} name="packageDescription" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.packageDescription} />
                                                            {formik.touched.packageDescription && formik.errors.packageDescription ? (<div className='text-required'>{formik.errors.packageDescription}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Summary <span className='text-required'>(max-length: 200 characters)</span></Form.Label>
                                                            <Form.Control as="textarea" maxlength="200" rows={3} name="summary" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.summary} />
                                                            {formik.touched.summary && formik.errors.summary ? (<div className='text-required'>{formik.errors.summary}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <Card.Header className='pb-0'>
                                                <Card.Title>Markers</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form.Row>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Select Marker<span className='text-required'>*</span></Form.Label>
                                                            <Select onChange={MarkerSelectHandler} options={markersList} value={selectedMarker} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={8}>
                                                        <Form.Group>
                                                            <Form.Label>Select Tests<span className='text-required'>*</span></Form.Label>
                                                            <Select isMulti={true} onChange={TestSelectHandler} options={testsList} value={selectedTests} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={1}>
                                                        <Form.Group style={{ marginTop: "31px" }}>
                                                            <Button variant='primary' onClick={addHandler}>Add</Button>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={12}>
                                                        <DataTables keyField='MrkrId' tableData={tableData} columns={columns} noDataIndication={NoRecordFounds} />
                                                    </Col>
                                                </Form.Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className='mt15'>
                                    <Col md={12} className='text-center'>
                                        <Button variant='secondary' className="mr-2" onClick={cancelHandler}>Cancel</Button>
                                        {isSaving ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' type='submit' onClick={errorHandler}>Save</Button>}
                                    </Col>
                                </Row>
                            </Form>
                        </>
                }
                {errorModal &&
                    <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                        <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                        <ModalBody>
                            <p>{formik.touched.packageCategory && formik.errors.packageCategory ? (<div className='text-required'>{formik.errors.packageCategory}</div>) : null}</p>
                            <p>{formik.touched.shortHeading && formik.errors.shortHeading ? (<div className='text-required'>{formik.errors.shortHeading}</div>) : null}</p>
                            <p>{formik.touched.shortDescription && formik.errors.shortDescription ? (<div className='text-required'>{formik.errors.shortDescription}</div>) : null}</p>
                            <p>{formik.touched.longHeading && formik.errors.longHeading ? (<div className='text-required'>{formik.errors.longHeading}</div>) : null}</p>
                            <p>{formik.touched.longDescription && formik.errors.longDescription ? (<div className='text-required'>{formik.errors.longDescription}</div>) : null}</p>
                            <p>{formik.touched.packageDescription && formik.errors.packageDescription ? (<div className='text-required'>{formik.errors.packageDescription}</div>) : null}</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                            <Button variant="primary" onClick={toggleModal}>Ok</Button>
                        </ModalFooter>
                    </Modal>
                }
            </Container>
        </React.Fragment >
    )
}

export default PackageDetails;