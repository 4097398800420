
import React, { useState, useEffect, useRef,Fragment } from 'react';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { URL_Start,GlobalBusiness } from '../../../../constant';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import InputGroup from 'react-bootstrap/InputGroup';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Spinner } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import Select from 'react-select';
 let globalModuleId="0";
 let globalModuleUTypeId="0";
 let globalPageSelection=false;
 let globalmoduletype=false;
let globaluserPlatform="";
let globalUserId="0";
let globaltblAllGroups=[];
let globaltblExistGroups=[];
let FlagUserCustFilterOnly="0";
let GlobalPartnerType="0";
const CACHE = {};
const GlobalcachedQuery='';
let globaldeptid="";
let globaldoctorid="";
let GGSalesEmpBindId="";
let GGHEADEmpBindId="";
let GGsalemanagerBindId="";
const DoctorWise = () => {
    let typeahead = useRef(null);  
    let textSearch = useRef();
    let typeaheaddepartment = useRef(null);  
    let typeaheadAsycDoctor = useRef(null);  
    const history = useHistory();
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const[ErrorPopUp, setErrorPopUp]=useState(0);
    const[tblRefDoctor,SettblRefDoctor]=useState([]);
    const[ModuleDetail,SetModuleDetail]=useState(0);
    const[IsUserTypeMoudle,SetIsUserTypeMoudle]=useState(false);
    const[tbAccessPageRMaster,SettbAccessPageRMaster]=useState([]);
    const[DepartmentDetail,SetDepartmentDetail]=useState([]);
    const[DesignationDetail,SetDesignationDetail]=useState(0);
    const[DoctorBusinessDetails,SetDoctorBusinessDetails]=useState(0);
    const[PartnerTypeDetail,SetPartnerTypeDetail]=useState(0);
    const[IsPartnerTypeShow,SetIsPartnerTypeShow]=useState(false);
    const[IsDepartmentShow,SetIsDepartmentShow]=useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isButton, setIsButton] = useState(false);
    const[IsSearchUsers,SetIsSearchUsers]=useState(false);
    const[SearchBox,SetSearchBox]=useState("");
    const[EmployeeDLists,SetEmployeeDLists]=useState([]);
    const[EditEmployeeDLists,SetEditEmployeeDLists]=useState([]);
    const[EmployeeTyOption,SetEmployeeTyOption]=useState([]);
    const[SalesManagerTyOption,SetSalesManagerTyOption]=useState([]);
    const[SalesHeadTyOption,SetSalesHeadTyOption]=useState([]);
const[tblUserGroupMapping,SettblUserGroupMapping]=useState(null);
const [userModuleShow, SetuserModuleShow] = useState(false);
const[TXtSearch,SetTXtSearch]=useState("");
const[ViewAccessGroups,SetViewAccessGroups]=useState(null);
const[IsUserView,SetIsUserView]=useState(false);
const[SelectedDeptId,SetSelectedDeptId]=useState(0);
const[DoctorFList,SetDoctorFList]=useState([]);
const[DoctorTyOption,SetDoctorTyOption]=useState([]);
const[SelectSalesPerson, SetSelectSalesPerson]=useState(null);
const[SelectSalesManager, SetSelectSalesManager]=useState(null);
const[SelectSalesHead, SetSelectSalesHead]=useState(null);
const[SelectSalesManagerDDL, SetSelectSalesManagerDDL]=useState(null);
const[SelectSalesHeadDDL, SetSelectSalesHeadDDL]=useState(null);
const[IsFirstLoadingStatus,SetIsFirstLoadingStatus]=useState(false);
const [PageHelper, SetPageHelper] = useState({
    PageNo: '1',
    PageSize: '50',
    TotalRecord: 0,
    dsDoctorDlists: []

});

    useEffect(() => {
      
        asyncFunEmpDetails();
 }, []);

 const asyncFunDepartmentDetails =async()=> {
     try {
     let url=URL_Start+ 'master/getBindDepartmentDetailsDDL';
     let options = {
         method: 'GET',
         url: url,
         headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json;charset=UTF-8'
         },
         data: {}
     };
     let response = await axios(options);
         let responseOK = response && response.status == 200;
         if (responseOK) {
             let data = response.data;
             // let data = await response.data;
             if(data.Success==true)
             { let ds=data.Data;
                 if(ds!=null && ds.length>0)
                 {   
                     SetDepartmentDetail(ds.map((option) =>(<option value={option.DepartmentId}>{option.CustDeptName}</option>)));   
                 }
                  else{
                     SetDepartmentDetail([]);
                  }
             }
             else{
                 SetDepartmentDetail([]);
                 let Errtext=<p><div className='text-required'> no record found </div></p>;
                 SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                 console.log('no record found');
             }
            
           
         }
         else{
             let Errtext=<p><div className='text-required'> no record found </div></p>;
             SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
         }
      // return data;
     } catch (error) {
         console.log(error.message);
         let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
         SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
      }
   }
 
   const asyncFunEmployeeDetailsBind =async(deptId)=> {
    try {
    let url=URL_Start+ 'doctormaster/getDoctorBusinessMappingBind';
    let options = {
        method: 'POST',
        url: url,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {DrBusinessId:`${2}`, DepartmentId:`${deptId}`,CommonId:`${globaldoctorid}`
    }
    };
    let response = await axios(options);
        let responseOK = response && response.status == 200;
        if (responseOK) {
            let data = response.data;
            // let data = await response.data;
            if(data.Success==true)
            { let ds=data.Data;
                if(ds!=null && ds.length>0)
                {   const TypeOptionDs=[];
                    ds.map((option) =>{
                        const TypeOptions={
                            'id':option.EmployeeId,
                            'name':option.CustrizeName
                        }
                     return TypeOptionDs.push(TypeOptions);
                        });
                        const TypeOptL=[];                  
                        ds.map((item) =>{
                const TypeOptrows={
                    'EmployeeId':item.EmployeeId
                }
             return TypeOptL.push(TypeOptrows);
                });
                SetEmployeeTyOption(TypeOptL);

                const TypeOptionDs1=[];
                      
                ds.map((option) =>{
                    const TypeOptions1={
                        'EmployeeId':option.SalesManagerId,
                        'label':option.SalesManagerCustName,
                        'value':option.SalesManagerId,
                         'disabled':false
                    }
                 return TypeOptionDs1.push(TypeOptions1);
                    });
                    SetSelectSalesManager(TypeOptionDs1); 
                const TypeOptionDs2=[];
                      
                ds.map((option) =>{
                    const TypeOptions2={
                        'EmployeeId':option.SalesHeadId,
                        'label':option.SalesHeadCustName,
                        'value':option.SalesHeadId,
                         'disabled':false
                    }
                 return TypeOptionDs2.push(TypeOptions2);
                    });
                    SetSelectSalesHead(TypeOptionDs2); 
                       // SetEmployeeDLists(TypeOptionDs);
                         //formik.setFieldValue('Employee',ds[0].EmployeeId);
                        //formik.setFieldTouched('Employee',false);
                       // formik.values.Employee=ds[0].EmployeeId;
                   
                 
                }
                 else{
                    SetEditEmployeeDLists([]);
                  
                 }
            }
            else{
                SetEditEmployeeDLists([]);
                let Errtext=<p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                console.log('no record found');
            }
        }
        else{
            let Errtext=<p><div className='text-required'> no record found </div></p>;
            SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
        }
     // return data;
    } catch (error) {
        console.log(error.message);
        let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
        SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
     }
  }

  const asyncFunEmployeeBindTab =async()=> {
    try {
    let url=URL_Start+ 'doctormaster/getDoctorBusinessMappingBind';
    let options = {
        method: 'POST',
        url: url,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {DrBusinessId:`${2}`, DepartmentId:`${0}`,CommonId:`${globaldoctorid}`
    }
    };
    let response = await axios(options);
        let responseOK = response && response.status == 200;
        if (responseOK) {
            let data = response.data;
            // let data = await response.data;
            if(data.Success==true)
            { let ds=data.Data;
                if(ds!=null && ds.length>0)
                {    const TypeOptionDs=[];
                   
                    ds.map((option) =>{
                        const TypeOptions={
                            'EmployeeId':option.EmployeeId,
                            'label':option.CustrizeName,
                            'value':option.EmployeeId,
                             'disabled':false
                        }
                        GGSalesEmpBindId=option.EmployeeId;
                     return TypeOptionDs.push(TypeOptions);
                        });
                       
                        SetSelectSalesPerson(TypeOptionDs);
                        const TypeOptL=[];                  
                        ds.map((item) =>{
                const TypeOptrows={
                    'EmployeeId':item.EmployeeId
                }
             return TypeOptL.push(TypeOptrows);
                });
                SetEmployeeTyOption(TypeOptL);

                const TypeOptionDs1=[];
                    
                ds.map((option) =>{
                    const TypeOptions1={
                        'EmployeeId':option.SalesManagerId,
                        'label':option.SalesManagerCustName,
                        'value':option.SalesManagerId,
                         'disabled':false
                    }
                    GGsalemanagerBindId=option.SalesManagerId;
                 return TypeOptionDs1.push(TypeOptions1);
                    });
                    SetSelectSalesManager(TypeOptionDs1);
                    
                    const TypeOptL2=[];                  
                    ds.map((item) =>{
            const TypeOptrows2={
                'EmployeeId':item.SalesManagerId
            }
         return TypeOptL2.push(TypeOptrows2);
            });
            SetSalesManagerTyOption(TypeOptL2);

                const TypeOptionDs2=[];
                      
                ds.map((option) =>{
                    const TypeOptions2={
                        'EmployeeId':option.SalesHeadId,
                        'label':option.SalesHeadCustName,
                        'value':option.SalesHeadId,
                         'disabled':false
                    }
                    GGHEADEmpBindId=option.SalesHeadId;
                 return TypeOptionDs2.push(TypeOptions2);
                    });
                    SetSelectSalesHead(TypeOptionDs2); 
                    const TypeOptL3=[];                  
                    ds.map((item) =>{
            const TypeOptrows3={
                'EmployeeId':item.SalesHeadId
            }
         return TypeOptL3.push(TypeOptrows3);
            });
            //SetIsFirstLoadingStatus(true);
            SetSalesHeadTyOption(TypeOptL3);
                       // formik.values.Employee=ds[0].EmployeeId;
                     
                       SetSelectSalesManagerDDL(SelectSalesManagerDDL.filter((d) => d.EmployeeId !==GGSalesEmpBindId && d.EmployeeId !==GGsalemanagerBindId && d.EmployeeId !==GGHEADEmpBindId ) );
                       SetSelectSalesHeadDDL(SelectSalesHeadDDL.filter((d) => d.EmployeeId !==GGSalesEmpBindId && d.EmployeeId !==GGsalemanagerBindId && d.EmployeeId !==GGHEADEmpBindId));
                       SetEmployeeDLists(EmployeeDLists.filter((d) => d.EmployeeId !==GGSalesEmpBindId && d.EmployeeId !==GGsalemanagerBindId && d.EmployeeId !==GGHEADEmpBindId));
                   
                }
                 else{
                    SetEditEmployeeDLists([]);
                  
                 }
            }
            else{
                SetEditEmployeeDLists([]);
                let Errtext=<p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                console.log('no record found');
            }
        }
        else{
            let Errtext=<p><div className='text-required'> no record found </div></p>;
            SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
        }
     // return data;
    } catch (error) {
        console.log(error.message);
        let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
        SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
     }
  }
 
   /********** Modals  ***********/
   const [modal, setModal] = useState();
   const toggleModal = () => {
       setModal(!modal)
   }

   const [errorModal, setErrorModal] = useState();
   const errorHandler = () => {
        setModal(true); setErrorModal(true);
        formik.handleSubmit();
   
   }


   


   /********** Validations  ***********/
   const formik = useFormik({
    initialValues: {
     department:'0',
      Employee:'0',
      LDoctor:'0',
    },
    enableReinitialize: true,
    validationSchema: Yup.object({
             LDoctor:Yup.string().transform(v => v=== '0' ? '' : v)
             .required('Doctor is Required')
            
            
          
    }),
       isSubmitting:true,
       onSubmit:  async (values,actions) => {
        setModal(false);
        setErrorModal(false);
        setErrorPopUp("");
        try {
           
          let url=URL_Start+ 'doctormaster/SETInsertUpdateDoctorBusinessMapping';
         
          let options = {
              method: 'POST',
              url: url,
              headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json;charset=UTF-8'
              },
            data:{DrBusinessId:`${2}`,DepartmentId:`${formik.values.department}`, AddedBy:`${LoginId}`,objDoctorIdsList:DoctorTyOption, objEmployeeIdsList:EmployeeTyOption, objSalesManagerIds:SalesManagerTyOption,objSalesHeadIds:SalesHeadTyOption}
          };
         
        
         let response = await axios(options);
             let responseOK = response && response.status == 200;
             if (responseOK) {
                 let data = response.data;
                 // let data = await response.data;
                 if(data.Success==true && data.Data=="2000")
                 { formik.resetForm();
                    SetuserModuleShow(false);
                    SetEmployeeDLists([]);
                    SetDoctorFList([]);
                    setTimeout(() => typeahead.current.clear(), 100);
                    window.GlobalBusiness="0";
               history.push({ pathname: `${process.env.PUBLIC_URL}/doctor-master/doctortoBusinessMapping`, search: `` }); 
                    //setTimeout(() =>typeaheadAsycDoctor.current='', 100);
                     SweetAlert.fire({ title: "Success!", text: "Doctor  been Mapped to Salesman!", icon: "success" });
                     setErrorModal(false);
                    

                 }
               
                 else{
                      if(data.ErrorList!=null && data.ErrorList.length>0)
                      {
                         let Errtext=<p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                         SweetAlert.fire({ title: "Error", text:data.ErrorList[0].errorMsg, icon: "error" });
                      }
                     
                 }
                
               
             }
             else{
                 console.log('no record found');
                 
             }
          // return data;
         } catch (error) {
             console.log(error.message);
             let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
             SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
          }
          setTimeout(() => {
           
             actions.setSubmitting(false);
             }, 1000);
            
       },
   });
   
       
   function onKeyDown(keyEvent) {
       if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
         keyEvent.preventDefault();
       }
     }


 
  const empGroupBlurHandler= (e) => {

         if(e.target.value!='' && e.target.value!=null)
         formik.setFieldTouched('Employee',true);
          else
          formik.errors.Employee="";
      
      }
     const empGroupChangeHandler= (option) => {
        const TypeOpt1=[];
    if(option.length>0)
    {
        option.map((item) =>{
            const TypeOptrows1={
                'id':item.id,
                'name':item.name
            }
         return TypeOpt1.push(TypeOptrows1);
            });
          
    }
    SetEditEmployeeDLists(TypeOpt1);  
         const value = (option.length > 0) ?option[0].name : '';
         const idval = (option.length > 0) ?option[0].id : '';
         formik.setFieldValue('Employee', idval);
         const TypeOpt=[];
                         
             option.map((item) =>{
                           const TypeOptrows={
                               'EmployeeId':item.id
                           }
                        return TypeOpt.push(TypeOptrows);
                           });
                           SetEmployeeTyOption(TypeOpt);
                           console.log(TypeOpt);
             const s1 =idval;
                 if(idval=="0")
                 {
                     formik.setFieldValue('Employee',0);
                 }
                  else{
                     formik.setFieldValue('Employee',idval);
                  }            
     }
   
 
    
const PrevBckHandler= async()=>{
   formik.resetForm();
 SetEmployeeDLists([]);
 SetDoctorFList([]);
 window.GlobalBusiness="0"; 
 history.push({ pathname: `${process.env.PUBLIC_URL}/doctor-master/doctortoBusinessMapping`, search: `` }); 
}


const departmentChangeHandler= (e) => {
 const s1 = e.target.value;
 asyncFunEmpDetails(s1);

  asyncFunEmployeeDetailsBind(s1);
 setTimeout(() => typeahead.current.clear(), 100);
 globalUserId=s1;
         if(s1=="0")
         {
             formik.setFieldValue('department',0);
         }
          else{
             formik.setFieldValue('department',s1);   
             
          } 
    
}

const asyncFundoctorFilterDetails =async(query)=> {
 BindGrid(1,50,query);
}

const BindGrid = async(PageNo, PageSize,query)=>
{
 try {
   setIsLoading(true);
     let url=URL_Start+ 'DoctorMaster/getdoctorfiltermappeddetails';
     let options = {
         method: 'POST',
         url: url,
         headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json;charset=UTF-8'
         },
         data: { PageNo: `${PageNo}`, PageSize: `${PageSize}`,SearchTxt:query}
     };
     let response = await axios(options);
         let responseOK = response && response.status == 200;
         if (responseOK) {
             let data = response.data;
             // let data = await response.data;
             if(data.Success==true)
             { let ds=data.Data;
                 setIsLoading(false);
                 if(ds!=null && ds.ObjBindDoctorRecordList!=null && ds.ObjBindDoctorRecordList.length>0)
                 {
                     const TypeOptionDs=[];
                   
                 ds.ObjBindDoctorRecordList.map((option) =>{
                     const TypeOptions={
                         'id':option.DoctorId,
                         'name':option.CustDoctorName
                     }
                  return TypeOptionDs.push(TypeOptions);
                     });
                     CACHE[query]={...DoctorFList,TypeOptionDs, total_count:Number(ds.TotalRecord),page:Number(PageNo)};
                   
                     SetDoctorFList(TypeOptionDs);
                     //const options = cachedQuery.options.concat(resp.options);
                     await SetPageHelper({
                         ...PageHelper,
                          PageNo:Number(PageNo),
                         PageSize: Number(PageSize),
                         TotalRecord: Number(ds.TotalRecord),
                         dsDoctorDlists: ds.ObjBindDoctorRecordList==null ? [] : ds.ObjBindDoctorRecordList
                     }); 
                   
                                      
                 }
             }
             else{
                 setIsLoading(false);
                 let Errtext=<p><div className='text-required'> no record found </div></p>;
                 SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                 console.log('no record found');
                
             }
            
           
         }
         else{
             setIsLoading(false);
             let Errtext=<p><div className='text-required'> no record found </div></p>;
             SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
         }
      // return data;
     } catch (error) {
         setIsLoading(false);
         console.log(error.message);
         let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
         SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
      }

}
const [query, setQuery] = useState('');

const handleInputChange = (q) => {
 setQuery(q);
};

const handlePagination = (e, shownResults) => {
const cachedQuery = CACHE[query];

 // Don't make another request if:
 // - the cached results exceed the shown results
 // - we've already fetched all possible results
 if (
   cachedQuery.TypeOptionDs.length > shownResults ||
   cachedQuery.TypeOptionDs.length === cachedQuery.total_count
 ) {
   return;
 }
// const cachedQuery = CACHE[PageHelper];
 // Don't make another request if:
 // - the cached results exceed the shown results
 // - we've already fetched all possible results
 //const cachedQuery =PageHelper.PageNo;
 // if (
 //     DoctorFList.length > shownResults ||
 //     DoctorFList.length === Number(PageHelper.TotalRecord)
 // ) {
 //   return;
 // }
 //BindGrid(page, sizePerPage,textSearch.current.value.toLowerCase());
 const page = cachedQuery.page + 1;
 const pagesize=PageHelper.PageSize;
 BindGridAFTER(page,pagesize,query,cachedQuery);
};

const BindGridAFTER = async(PageNo, PageSize,query,cachedQuery)=>
{
 try {
     setIsLoading(true);
     let url=URL_Start+ 'DoctorMaster/getdoctorfiltermappeddetails';
     let options = {
         method: 'POST',
         url: url,
         headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json;charset=UTF-8'
         },
         data: { PageNo: `${PageNo}`, PageSize: `${PageSize}`,SearchTxt:query}
     };
     let response = await axios(options);
         let responseOK = response && response.status == 200;
         if (responseOK) {
             let data = response.data;
             // let data = await response.data;
             if(data.Success==true)
             { let ds=data.Data;
                 setIsLoading(false);
                 if(ds!=null && ds.ObjBindDoctorRecordList!=null &&  ds.ObjBindDoctorRecordList.length>0)
                 {
                     let TypeOptionDs=[];
                    
                 ds.ObjBindDoctorRecordList.map((option) =>{
                     const TypeOptions={
                         'id':option.DoctorId,
                         'name':option.CustDoctorName
                     }
                  return TypeOptionDs.push(TypeOptions);
                     });
                     //CACHE[query]={...DoctorFList,TypeOptionDs, total_count:Number(ds.TotalRecord),page:Number(PageNo)};
                     
                      //TypeOptionDs=[].cachedQuery.TypeOptionDs.concat(TypeOptionDs);
                      TypeOptionDs=[].concat(cachedQuery.TypeOptionDs,TypeOptionDs);
                     const page=PageNo;
                     CACHE[query] = { ...cachedQuery, TypeOptionDs, page };
               

                     SetDoctorFList(TypeOptionDs);
                     //const options = cachedQuery.options.concat(resp.options);
                     await SetPageHelper({
                         ...PageHelper,
                          PageNo:Number(PageNo),
                         PageSize: Number(PageSize),
                         TotalRecord: Number(ds.TotalRecord),
                         dsDoctorDlists: ds.ObjBindDoctorRecordList==null ? [] : ds.ObjBindDoctorRecordList
                     }); 
                   
                                      
                 }
             }
             else{
                 setIsLoading(false);
                 let Errtext=<p><div className='text-required'> no record found </div></p>;
                 SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                 console.log('no record found');
             }
            
           
         }
         else{
             setIsLoading(false);
             let Errtext=<p><div className='text-required'> no record found </div></p>;
             SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
         }
      // return data;
     } catch (error) {
         setIsLoading(false);
         console.log(error.message);
         let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
         SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
      }

}
const ErrorFunction = async (ErrorCode) => {
    if (ErrorCode == "400")
        SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
    else if (ErrorCode == "401")
        SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
    else if (ErrorCode == "403")
        SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
    else if (ErrorCode == "404")
        SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
    else if (ErrorCode == "500")
        SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
    else if (ErrorCode == "503")
        SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
   }

   const asyncFunEmpDetails =async()=> {
    try {
        setIsButton(true);
    let url=URL_Start+ 'Users/GetSalesManHierarchy';
    let options = {
        method: 'GET',
        url: url,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: { }
    };
    let response = await axios(options);
        let responseOK = response && response.status == 200;
        if (responseOK) {
            let data = response.data;
            // let data = await response.data;
            if(data.Success==true)
            { let ds=data.Data;
                if(ds!=null && ds.length>0)
                {   
                    const TypeOptionDs1=[];
                      
                    ds.map((option) =>{
                        const TypeOptions1={
                            'EmployeeId':option.EmployeeId,
                            'label':option.CustUserName,
                            'value':option.EmployeeId,
                             'disabled':false
                        }
                     return TypeOptionDs1.push(TypeOptions1);
                        });
                    SetSelectSalesManagerDDL(TypeOptionDs1); 
                     const TypeOptionDs2=[];
                      
                     ds.map((option) =>{
                         const TypeOptions2={
                             'EmployeeId':option.EmployeeId,
                             'label':option.CustUserName,
                             'value':option.EmployeeId,
                              'disabled':false
                         }
                      return TypeOptionDs2.push(TypeOptions2);
                         });
                         SetSelectSalesHeadDDL(TypeOptionDs2); 

                    const timer = setTimeout(() => {
                        setIsButton(false);
                        const TypeOptionDs=[];
                  
                          ds.map((option) =>{
                            const TypeOptions={
                                'EmployeeId':option.EmployeeId,
                                'label':option.CustUserName,
                                'value':option.EmployeeId,
                                 'disabled':false
                            }
                         return TypeOptionDs.push(TypeOptions);
                            });
                            SetEmployeeDLists(TypeOptionDs);
                      }, 700);
                      return () => clearTimeout(timer);
                    
                   
                 
                }
                 else{
                    SetEmployeeDLists([]);
                    setIsButton(false);
                 }
            }
            else{
                SetEmployeeDLists([]);
                setIsButton(false);
                let Errtext=<p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                console.log('no record found');
            }
        }
        else{
            setIsButton(false);
            let Errtext=<p><div className='text-required'> no record found </div></p>;
            SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
        }
     // return data;
    } catch (error) {
        setIsButton(false);
        console.log(error.message);
        let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
        SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
     }
  }
   const SalesManagerHandler=(options)=>{
   
    SetSelectSalesManager(options);
    const TypeOptL=[];                  
    
    const TypeOptrows={
    'EmployeeId':options.EmployeeId
    }
    TypeOptL.push(TypeOptrows);
    SetSalesManagerTyOption(TypeOptL);
   if(options!=null) 
   {
    let EmployeeId=options.EmployeeId;
    SetSelectSalesManagerDDL(SelectSalesManagerDDL.filter((d) => d.EmployeeId !==EmployeeId));
    SetSelectSalesHeadDDL(SelectSalesHeadDDL.filter((d) => d.EmployeeId !==EmployeeId));
    SetEmployeeDLists(EmployeeDLists.filter((d) => d.EmployeeId !==EmployeeId));

   }
  } 
  const SelectSalesHeadHandler=(options)=>{
   
    SetSelectSalesHead(options);
    const TypeOptL=[];                  
    
    const TypeOptrows={
    'EmployeeId':options.EmployeeId
    }
    TypeOptL.push(TypeOptrows);
    SetSalesHeadTyOption(TypeOptL);
   if(options!=null)
   {
    let EmployeeId=options.EmployeeId;
    SetSelectSalesManagerDDL(SelectSalesManagerDDL.filter((d) => d.EmployeeId !==EmployeeId));
    SetSelectSalesHeadDDL(SelectSalesHeadDDL.filter((d) => d.EmployeeId !==EmployeeId));
    SetEmployeeDLists(EmployeeDLists.filter((d) => d.EmployeeId !==EmployeeId));

   }
  } 
  const SelectSalesPersonHandler=(options)=>{
    SetSelectSalesPerson(options);
    const TypeOptL=[];                  
    
const TypeOptrows={
'EmployeeId':options.EmployeeId
}
TypeOptL.push(TypeOptrows);
SetEmployeeTyOption(TypeOptL);
   if(options!=null)
   {
    let EmployeeId=options.EmployeeId;
    SetSelectSalesManagerDDL(SelectSalesManagerDDL.filter((d) => d.EmployeeId !==EmployeeId));
    SetSelectSalesHeadDDL(SelectSalesHeadDDL.filter((d) => d.EmployeeId !==EmployeeId));
    SetEmployeeDLists(EmployeeDLists.filter((d) => d.EmployeeId !==EmployeeId));

   }
  } 
 return (
     <Fragment>


             <Row>
                 <Col md={12}>
                     <Card>
                         <Card.Body>
                    
                         <Form.Row className="justify-content-center">
                                       
                                       <Col md={6} lg={6}>
                                               <Form.Group>
                                                               <Form.Label>Doctors<span className='text-required'>*</span></Form.Label>
                                                               {isLoading?<Spinner animation="border" size='sm' />:null} 
                                                               <AsyncTypeahead
                                                    id="LDoctor" name="LDoctor"
                                                    //clearButton
                                                    labelKey="name"
                                                   
                                                    
                                                    minLength={3}
                                                    maxResults={Number(PageHelper.PageSize)-1}
                                                    paginate
                                                    ref={typeaheadAsycDoctor}
                                                    onInputChange={handleInputChange}
                                                    onPaginate={handlePagination}
                                                    onSearch={asyncFundoctorFilterDetails}
                                                    options={DoctorFList}
                                                    value={formik.values.LDoctor}
                                                     // onChange is on valid option selection (a menu click)
  onChange={option => {
    
      const value = (option.length > 0) ?option[0].name : '';
      const idval = (option.length > 0) ?option[0].id : '';
       if(idval!='')
       {
        globaldoctorid=idval;
        asyncFunEmployeeBindTab();
       }
     
       formik.setFieldTouched('LDoctor',true);
      formik.setFieldValue('LDoctor', idval);
      const TypeOpt=[];
                      
          option.map((item) =>{
                        const TypeOptrows={
                            'DoctorId':item.id
                        }
                     return TypeOpt.push(TypeOptrows);
                        });
                        SetDoctorTyOption(TypeOpt);  
                     }}
                        onBlur={(e) => {
                            if(formik.values.LDoctor=='' || formik.values.LDoctor==null)
                        formik.setFieldTouched('LDoctor',true);
                            else
                            formik.errors.LDoctor="";

                        }}
                                                    placeholder="Location OR Doctor Name OR Doctor Mobile OR Specialization ....."
                                                />
                                                               {formik.touched.LDoctor && formik.errors.LDoctor ? (<div className='text-required'>{formik.errors.LDoctor}</div>) : null}
                                                           </Form.Group>
                                                           
                                          </Col>
                                        
                                       </Form.Row>
                                        {/* <Form.Row className="justify-content-center">
                                       
                                       <Col md={6} lg={6}>
                                           
                                       <Form.Group>
                                                               <Form.Label>Department<span className='text-required'>*</span></Form.Label>
                                                               <Form.Control as="select"  name="department"  onChange={departmentChangeHandler} onBlur={formik.handleBlur} value={formik.values.department}>
                                                               <option value='0'>---select---</option>
                                                             {DepartmentDetail}
                                                               </Form.Control>
                                                               {formik.touched.department && formik.errors.department ? (<div className='text-required'>{formik.errors.department}</div>) : null}
                                                           </Form.Group>
                                             
                                          </Col>
                                        
                                       </Form.Row> */}
                                     
                                  
                                       <Form.Row className="justify-content-center">
                                       
                                       <Col md={6} lg={6}>
                                               <Form.Group>
                                                               <Form.Label>Sales Person<span className='text-required'>*</span></Form.Label>
                                                               <Select 
                                                    onChange={SelectSalesPersonHandler}
                                                    options={EmployeeDLists}
                                                value={SelectSalesPerson}
                                                        />      
                                                               {(SelectSalesPerson==null || SelectSalesPerson.length==0 ) && formik.touched.LDoctor? (<div className='text-required'>Employee is Required</div>) : null}
                                                           </Form.Group>
                                                           
                                          </Col>
                                        
                                       </Form.Row>
                                       <Form.Row className="justify-content-center">
                                       
                                       <Col md={6} lg={6}>
                                               <Form.Group>
                                                               <Form.Label>Sales Manager</Form.Label>
                                                               <Select 
                                                    onChange={SalesManagerHandler}
                                                    options={SelectSalesManagerDDL}
                                                value={SelectSalesManager}
                                                        />     
                                                                           </Form.Group>
                                                           
                                          </Col>
                                        
                                       </Form.Row>
                                       <Form.Row className="justify-content-center">
                                       
                                       <Col md={6} lg={6}>
                                               <Form.Group>
                                                               <Form.Label>Sales Head</Form.Label>
                                                               <Select 
                                                    onChange={SelectSalesHeadHandler}
                                                    options={SelectSalesHeadDDL}
                                                value={SelectSalesHead}
                                                        />
                                                                           </Form.Group>
                                                           
                                          </Col>
                                        
                                       </Form.Row>
                        
                
                         </Card.Body>
                     </Card>
                 </Col>
             </Row>          
             <Row className='mt15'>
                                 <Col md={12} className='text-center'>
                                     <Button variant='secondary' className="mr-2"  onClick={PrevBckHandler} >Cancel</Button>
                                     {<Button variant='primary' type='submit' onClick={errorHandler}>Save</Button>}
                                 </Col>
                             </Row>
      

       

         {errorModal &&
             <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                 <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                 <ModalBody>
                 
                     <p>
                     {(SelectSalesPerson==null || SelectSalesPerson.length==0 )  ? (<div className='text-required'>Employee is Required</div>) : null}
                     </p>
                     <p>
                     {formik.touched.LDoctor && formik.errors.LDoctor ? (<div className='text-required'>{formik.errors.LDoctor}</div>) : null}
                     </p>
                 
                 </ModalBody>
                 <ModalFooter>
                     <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                     <Button variant="primary" onClick={toggleModal}>Ok</Button>
                 </ModalFooter>
                 
             </Modal>
         }
     </Fragment>
 )
}

export default DoctorWise;