import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, Button } from 'react-bootstrap';
import Breadcrumb from '../../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import LoadingButton from '../commonComponent/LoadingButton';

var glbRecords = [];
const CostcodeModalityMapping = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [isSaving, setIsSaving] = useState(false);


    const [crntPageNO, setCrntPageNO] = useState(0);
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const columns = [
        { dataField: 'Name', text: 'Name', editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; }  },
        { dataField: 'Costcode1', text: 'Cost Code', editable: true, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        // { dataField: 'Costcode2', text: 'Cost Code2', editable: true },
    ];

    useEffect(() => { BindGrid(); }, []);
    const BindGrid = async () => {
        setIsLoading(true);
        let url = URL_Start + 'master/getmodalities'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data != null) {
                    glbRecords = data.Data;
                    setTableData(glbRecords);
                }
                setIsLoading(false);
            }
            else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const UpdateCostCodes = async () => {
        setIsSaving(true);
        var arrayCostCode = [];
        for (var key in tableData) {
            if (tableData[key].SAPCode !== "") {
                arrayCostCode.push({ CategoryId: tableData[key].Id, CostCode1: tableData[key].Costcode1, CostCode2: tableData[key].Costcode2 });
            }
        }
        const requestOptions = {
            ModifiedBy: localStorage.LoggedInUser,
            CostCodes: arrayCostCode,
        };
        let url = URL_Start + 'master/updatemodalitycostcodes'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                SweetAlert.fire({ title: "Success", text: "Cost Code updated successfully", icon: "success" });
                BindGrid();
                setIsSaving(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsSaving(false); }
        }).catch(function (error) { setIsSaving(false); });
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Cost Code Mapping" parent="SAP" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Costcode Modality Mappings</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <DataTables keyField='Id' tableData={tableData} columns={columns} />
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className='text-center'>
                        {isSaving ? <LoadingButton variantName='primary' cssName="mr-2" /> : <Button variant='primary' type='submit' className="mr-2" onClick={UpdateCostCodes}>Update Cost Codes</Button>}
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default CostcodeModalityMapping;