import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import LoadingButton from '../commonComponent/LoadingButton';
import Select from 'react-select';

var glblArr = [];
const RecoveryFromApprover = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [crntPageNO, setCrntPageNO] = useState(0);
  const [table, setTable] = useState([]);
  const [defaultDate, setDefaultDate] = useState("");
  const [approvers, setApprovers] = useState([]);
  const [typeSelected, setTypeSelected] = useState([]);
  //let fromDate = useRef();
  let toDate = useRef();
  let approverId = useRef();

  useEffect(() => {
    const today = new Date();
    const date = today.setDate(today.getDate());
    const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
    setDefaultDate(defaultValue);
    GetApprovers();
  }, [])

  const PaginationOptions = {
    sizePerPage: 10,
    showTotal: false,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
  };
  const handleEditCheckbox = (e, rawData) => {
    const checked = e.target.checked;
    if (checked) {
      glblArr.push({
        OrderDetailId: rawData.OrderDetailId
      })
    }
    else {
      glblArr = glblArr.filter((d) => d.OrderDetailId != rawData.OrderDetailId)
    }
  };
  const idFormatter = (cellData, rowData, rowIndex, extraData) => {
    return <React.Fragment>
      <div>
        <span>{crntPageNO + rowIndex + 1}</span>
      </div>
    </React.Fragment>
  }
  const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
    return <React.Fragment>
      <div>
        <input className="checkbox_animated" type="checkbox" onClick={(e) => { handleEditCheckbox(e, rowData); }} />
      </div>
    </React.Fragment>
  }
  const columns = [
    { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    { dataField: 'OrderNo', text: 'Order No', editable: false },
    { dataField: 'VisitingCode', text: 'Visiting Code', editable: false },
    { dataField: 'CreaterName', text: 'Creater Name', editable: false },
    { dataField: 'OrderGenerateDate', text: 'Order Generation Date', editable: false },
    { dataField: 'OtstandingApproveDate', text: 'Outstanding Approved Date', editable: false },
    { dataField: 'PaidAmount', text: 'Outstanding Amount', editable: false },
    { dataField: 'actions', isDummyField: true, text: 'Actions', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } }
  ];
  const GetApprovers = async () => {
    var glblArr1 = [];
    try {
      let url = URL_Start + 'master/getallapprovers/'
      await axios.get(url).then((response) => {
        const data = response.data;
        if (data.Success) {
          if (data.Data == null || data.Data == undefined) { setApprovers([]); SweetAlert.fire("No approver found"); }
          else {
            data.Data.map((option) => { return glblArr1.push({ 'value': option.AuthorizerId, 'label': option.AuthorizerName, 'disabled': false }); });
            setApprovers(glblArr1);
            //setApprovers(data.Data.map((d) => (<option key={d.AuthorizerId} value={d.AuthorizerId}>{d.AuthorizerName}</option>))); 
          }
        }
        else { SweetAlert.fire(data.Data); }
      }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    }
    catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
  }

  const handleSearch = async () => {
    glblArr = [];
    if ((typeSelected.length == 0 || typeSelected == [] || typeSelected == null || typeSelected == undefined || typeSelected == "") && (toDate.current.value == "" || toDate.current.value == undefined)) {
      SweetAlert.fire("All fields are mandatory to search");
      return false;
    }
    if (typeSelected.length == 0 || typeSelected == [] || typeSelected == null || typeSelected == undefined || typeSelected == "") {
      SweetAlert.fire("Approver is mandatory to search");
      return false;
    }
    // if (fromDate.current.value == "" || fromDate.current.value == undefined) {
    //   SweetAlert.fire("From date is mandatory to search");
    //   return false;
    // }
    if (toDate.current.value == "" || toDate.current.value == undefined) {
      SweetAlert.fire("To date is mandatory to search");
      return false;
    }
    setIsLoading(true);
    try {
      let url = URL_Start + 'master/getoutstandingamountrecovery/' + typeSelected + '/ /' + toDate.current.value //approverId.current.value
      await axios.get(url).then((response) => {
        const data = response.data;
        setIsLoading(false);
        if (data.Success) {
          if (data.Data == null || data.Data == undefined) { setTable([]); SweetAlert.fire("No record found"); }
          else { setTable(data.Data); }
        }
        else { SweetAlert.fire(data.Data); }
      }).catch(function (error) { setIsLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
    }
    catch (error) { setIsLoading(false); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
  }
  const CreateBillAgainstOrder = async () => {
    if (glblArr.length == 0) {
      SweetAlert.fire("Please select atleast one bill");
      return false;
    }
    const requestOptions = {
      ApproverId: localStorage.LoggedInUser,
      JsonData: JSON.stringify(glblArr)
    };
    setIsSaving(true);
    try {
      let url = URL_Start + 'master/createbillagainstorder'
      await axios.post(url, requestOptions).then((response) => {
        const data = response.data;
        if (data.Success) {
          setIsSaving(false);
          SweetAlert.fire({ title: 'Saved', text: "Bill generated successfully", icon: 'success', confirmButtonText: 'Ok', reverseButtons: true })
            .then((result) => {
              if (result.value) { window.location.reload(false); glblArr = []; }
              else { window.location.reload(false); glblArr = []; }
            })
        }
        else {
          setIsSaving(false);
          if (data.Data == "-2001") { SweetAlert.fire("JSON value is incorrect, Contact support team"); }
          else if (data.Data == "-2000" || data.Data == "-6") { SweetAlert.fire("Runtime error occured, Check db logs"); }
          else { SweetAlert.fire(data.Data); }
        }
      }).catch(function (error) { setIsSaving(false); if (error.response) { ErrorFunction(error.response.status); } });
    } catch (error) { setIsSaving(false); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
  }
  const TypeChangeHandler = (options) => { setTypeSelected(options.value); }
  const ErrorFunction = async (ErrorCode) => {
    if (ErrorCode == "400")
      SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
    else if (ErrorCode == "401")
      SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
    else if (ErrorCode == "403")
      SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
    else if (ErrorCode == "404")
      SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
    else if (ErrorCode == "500")
      SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
    else if (ErrorCode == "503")
      SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
  }
  return (
    <>
      <Breadcrumb title="Recovery From Approver" parent="Accounts" />
      <Container fluid>
        <Row>
          <Col md={12}>
            <Card>
              <Card.Body>
                <Form.Row>
                  <Col md={3}>
                    <Form.Group>
                      <Form.Label>Approvers</Form.Label>
                      {/* <Form.Control as="select" ref={approverId}>
                        <option key="" value="">---Select----</option>
                        {approvers}
                      </Form.Control> */}
                      <Select onChange={TypeChangeHandler}
                        options={approvers}
                        value={approvers.filter(function (x) { return x.value === typeSelected; })} />
                    </Form.Group>
                  </Col>
                  {/* <Col md={4}>
                    <Form.Group>
                      <Form.Label>From Date</Form.Label>
                      <Form.Control type="date" id="date1" name="date1" ref={fromDate} />
                    </Form.Group>
                  </Col> */}
                  <Col md={4}>
                    <Form.Group>
                      <Form.Label>UpTo Date</Form.Label>
                      <Form.Control type="date" id="date2" name="date2" ref={toDate} max={defaultDate} defaultValue={defaultDate} />
                    </Form.Group>
                  </Col>
                  <Col md={12} className='text-center __center__buttonsec'>
                    {
                      isLoading ?
                        <LoadingButton variantName='primary' cssName="" /> :
                        <Button variant='secondary' className="mr-2" onClick={handleSearch} >Search</Button>
                    }
                  </Col>

                </Form.Row>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {
          table.length > 0 ?
            <>
              <Row>
                <Col md={12}>
                  <Card>
                    <Card.Header className='pb-0'>
                      <Card.Title>Approved Outstanding Bill</Card.Title>
                    </Card.Header>
                    <Card.Body>
                      {
                        isLoading ?
                          <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                          <>
                            <DataTables keyField='CentreId' tableData={table} columns={columns} noDataIndication={NoRecordFounds}
                              pagination={paginationFactory(PaginationOptions)} />
                          </>
                      }
                    </Card.Body>
                  </Card>
                </Col>
                <Col md={12} className='text-center'>
                  {isSaving ? <LoadingButton variantName='primary' cssName="mr-2" /> : <Button variant='primary' type='submit' className="mr-2" onClick={CreateBillAgainstOrder}>Save</Button>}
                </Col>
              </Row>
            </> : null
        }
      </Container>
    </>
  )
}

export default RecoveryFromApprover