import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { URL_Start } from '../../../constant';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import InputGroup from 'react-bootstrap/InputGroup';
import { MultiSelect } from 'react-multi-select-component';
import '../../../pages/admin/react-multiselect.css';
import Select from 'react-select';
import moment from 'moment';
const PatientInformation = () => {
    let fromDate = useRef();
    let toDate = useRef();
    let CurrentDateTime = moment()
    .format('YYYY_MM_DD|hh_mm_ss_a');
    let CurrentDateUSe = moment()
      .format('YYYY-MM-DD');
      let CurrentMonthUse = moment()
      .format('MMMM-YYYY');
      let TodayUse = moment()
      .format('YYYY_MMM_DD');
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [BusinessDatalistCSV, setBusinessDatalistCSV] = useState([]);
    const[ReportOption,SetReportOption]=useState("1");
    const[ExpFilenameDownload,SetExpFilenameDownload]=useState("Cumulative_ClientWiseReport.csv");
    const today = new Date();
    const date = today.setDate(today.getDate());
    const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
    const [defaultDate, setDefaultDate] = useState(defaultValue);
    const [SelectCentreDDL, SetSelectCentreDDL] = useState([]);
    const [SelectCentres, SetSelectCentres] = useState([]);
    const [LGlCentreTypeoption, SetLGlCentreTypeoption] = useState([]);
    const [SelectCentreTypeDDL, SetSelectCentreTypeDDL] = useState([]);
    const [SelectCentreType, SetSelectCentreType] = useState([]);
    const [LGlSelectCentreTypeoption, SetLGlSelectCentreTypeoption] = useState([]);
    const [SelectDepartmentDDL, SetSelectDepartmentDDL] = useState([]);
    const [SelectDepartment, SetSelectDepartment] = useState([]);
    const [LGlSelectDepartmentTypeoption, SetLGlSelectDepartmentTypeoption] = useState([]);
    const[VisitVal,SetVisitVal]=useState("0");
    useEffect(() => {
        const CentreTypeL = [];
        GetBindCenterAllLists(CentreTypeL);
        GetDepartmentList();
    }, []);
   
    const GetBindPatientInformationCSV = async () => {
        const  Objbusiness= {
            FromDate:fromDate.current.value,
            ToDate:toDate.current.value,
            objtblCentreIds:LGlCentreTypeoption,
            objtblDepartmentIDs:LGlSelectDepartmentTypeoption,
            VisitTypeID:VisitVal   
        };
        try {
            let url = URL_Start + 'reports/GETBindPatientInformationReports'
            await axios.post(url,Objbusiness).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null  && data.Data.length>0 ) {
                        
                         if(data.Data=="1")
                         {
                            setDownloadVisible(false);
                            SweetAlert.fire({ title: "Warning", text: "Date Wise Trend(Not more than 31 days)", icon: "info" });

                         }
                       else  if(data.Data=="2")
                         {
                            setDownloadVisible(false);
                            SweetAlert.fire({ title: "Warning", text: "Month Wise Trend(Not more than 12 month)", icon: "info" });

                         }
                         else  if(data.Data=="3")
                         {
                            setDownloadVisible(false);
                            SweetAlert.fire({ title: "Warning", text: "Please choose vaild from date and to date", icon: "info" });

                         }
                          else{
                        setDownloadVisible(true);
                        var newarr = [];
                        // for (var key in data.Data) {
                        //     newarr.push({
                        //         CentreName: data.Data[key].CentreName,
                        //         RateTemplateName: data.Data[key].RateTemplateName,
                        //         FromDate: data.Data[key].FromDate,
                        //         ToDate: data.Data[key].ToDate,
                        //         IsActive: data.Data[key].IsActive,
                        //         IsDefaultTemplate: data.Data[key].IsDefaultTemplate,
                        //         ActiveDays: data.Data[key].ActiveDays
                        //     });
                        // }
                        //setCentreRatelistCSV(newarr);
                        setBusinessDatalistCSV(data.Data);
                        document.getElementById("csvdownload").click();
                    }
                    }
                    else { setDownloadVisible(false);
                        SweetAlert.fire({ title: "Warning", text: "no records found", icon: "info" });
                    }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
   
    
    
  
    const handleSearchList = async () => {
        if(SelectCentres == null || SelectCentres.length == 0)
        {
            SweetAlert.fire({ title: "Warning", text: "client selection is required", icon: "info" });
        }
         else{
           
            let filenameCust="";
           
               let  dateFull='From_'+moment(fromDate.current.value).format('DD_MMM_YYYY')+'_'+'TO_'+moment(toDate).format('DD_MMM_YYYY')+'|Print_'+CurrentDateTime;
               filenameCust= dateFull;
            SetExpFilenameDownload("Patient_InformationReport"+filenameCust+".csv");
            GetBindPatientInformationCSV();
         }
    }
 
   
   
      const  GetBindCenterAllLists= async (TypeOptL) => {
        const  ObjFilterCentye=TypeOptL;
        try {
            let url = URL_Start + 'master/getBindAllCentreLists'
            await axios.post(url,ObjFilterCentye).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                         let ds=data.Data;
                         const TypeOptionDs1 = [];
                        ds.map((option) => {
                            const TypeOptions1 = {
                                'CentreId': option.CentreId,
                                'label': option.CentreName,
                                'value': option.CentreId,
                                'CenterTypeId': option.CenterTypeId,
                                'disabled': false
                            }
                            return TypeOptionDs1.push(TypeOptions1);
                        });
                        SetSelectCentreDDL(TypeOptionDs1); 
                       
                    }
                    else {  SetSelectCentreDDL([]); }
                }
            }).catch(function (error) {
                //ErrorFunction(error.response.status);
                SetSelectCentreDDL([]);
             });
        }
        catch (error) {
            SetSelectCentreDDL([]);
            SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
   
    const CentreHandler = (options) => {

        SetSelectCentres(options);
        const TypeOptL = [];
        if (options != null && options.length > 0) {
            options.map((item) => {
                const TypeOptrows = {
                    'CentreId': item.CentreId
                }
                return TypeOptL.push(TypeOptrows);
            });
          SetLGlCentreTypeoption(TypeOptL);

        }
         else{
            SetLGlCentreTypeoption([]);  
         }
    }
        const GetDepartmentList = async () => {
            try {
                let url = URL_Start + 'master/getBindAllDepartmentLists'
                await axios.get(url).then((response) => {
                    const data = response.data;
                    if (data.Success) { 
                        if (data.Data != null) {
                            let ds=data.Data;
                            const TypeOptionDs1 = [];
                           ds.map((option) => {
                               const TypeOptions1 = {
                                   'DeptId': option.DeptId,
                                   'label': option.DepartmentName,
                                   'value': option.DeptId,
                                   'Description':option.Description,
                                   'disabled': false
                               }
                               return TypeOptionDs1.push(TypeOptions1);
                           });
                           SetSelectDepartmentDDL(TypeOptionDs1); 
                          
                       }
                       else {  SetSelectDepartmentDDL([]); } }
                }).catch(function (error) { });
            }
            catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
        }
        const DepartmentHandler = (options) => {
            SetSelectDepartment(options);
            const TypeOptL = [];
            if (options != null && options.length > 0) {
                options.map((item) => {
                    const TypeOptrows = {
                        'SubCategoryId': item.DeptId
                    }
                    return TypeOptL.push(TypeOptrows);
                });
              SetLGlSelectDepartmentTypeoption(TypeOptL);
            }
             else{
                SetLGlSelectDepartmentTypeoption([]);
             }
        }
    return (
        <React.Fragment>
            <Breadcrumb title="Patient Information" parent="Reports" />
            <Container fluid>
                 <Row className='mt25'>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                            
                                <Form.Row>
                                    <Col md={12}>
                                   <div className='mb-2'><b>Date Filter</b></div>
                                    </Col>
                                </Form.Row>
                             <Form.Row>
                          <Col md={3}>
                              <Form.Group>
                                  <Form.Label>From Date</Form.Label>
                                  <InputGroup className="mb-3 d-flex justify-content-start">
                                      <Form.Control type="date" id="fromDate" name="fromDate" ref={fromDate} defaultValue={defaultDate} />
                                  </InputGroup>
                              </Form.Group>
                          </Col>
                          <Col md={3}>
                              <Form.Group>
                                  <Form.Label>To Date</Form.Label>
                                  <InputGroup className="mb-3 d-flex justify-content-start">
                                      <Form.Control type="date" id="toDate" name="toDate" ref={toDate} defaultValue={defaultDate} />
                                  </InputGroup>
                              </Form.Group>
                          </Col>
                      </Form.Row>
                      <Form.Row>
                                    <Col md={12}>
                                   <div className='mb-2'><b>Centres Detail</b></div>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="justify-content-left">
                                <Col md={5} lg={5}>
                             <Form.Group>
                                <Form.Label>Centre<span className='text-required'>* required</span></Form.Label>
                                <MultiSelect id="CentreSelect" name="CentreSelect"
                                    options={SelectCentreDDL}
                                    value={SelectCentres}
                                    onChange={CentreHandler}
                                    labelledBy="Select"
                                />
                            </Form.Group>
                        </Col>
                        <Col md={4} lg={4}>
                             <Form.Group>
                                <Form.Label>Department</Form.Label>
                                <MultiSelect id="DepartmentSelect" name="DepartmentSelect"
                                    options={SelectDepartmentDDL}
                                    value={SelectDepartment}
                                    onChange={DepartmentHandler}
                                    labelledBy="Select"
                                />
                            </Form.Group>
                        </Col>  
                          </Form.Row>
                          <Form.Row className="justify-content-left">
                              
                          </Form.Row>
                                <Form.Row>
                                 
                                    <Col md={12} className='text-center __center__buttonsec mb-4 mt25'>
                                        <Button variant='primary' onClick={handleSearchList} filename={"CentreRateTemplates.csv"} target="_blank" >Download CSV</Button>
                                    </Col>
                                  
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row> 
                {
                    downloadvisible &&
                    <CSVLink data={BusinessDatalistCSV} filename={ExpFilenameDownload} target="_blank" id="csvdownload"></CSVLink>
                }
            </Container>
        </React.Fragment>
    )
}

export default PatientInformation;