
import React, { useState, useEffect, useRef,Fragment } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import EditToolTip from '../UI/ToolTips/EditToolTip';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip';
import ActiveToolTip from '../UI/ToolTips/ActiveToolTip';
import InactiveToolTip from '../UI/ToolTips/InactiveToolTip';
import { URL_Start } from '../../../constant';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
 let globalModuleId="0";
 let globalModuleUTypeId="0";
 let globalPageSelection=false;
 let GlbModuleCallArr=[];
  let GlbSelectedPageUserR=[];  
const AddAccessGroup = () => {
    const history = useHistory();
    const [SelectedPageUserR,SetSelectedPageUserR] = useState([]);
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const[ErrorPopUp, setErrorPopUp]=useState(0);
    const[tblRefDoctor,SettblRefDoctor]=useState([]);
    const[ModuleDetail,SetModuleDetail]=useState(0);
    const[tbAccessPageRMaster,SettbAccessPageRMaster]=useState([]);
    useEffect(() => {
           asyncFunModuleDDLDetails();
    }, []);
   
    
      const asyncFunAccessPageRelationDetails =async(moduleId,usertypeid )=> {
        try {
         
        let url=URL_Start+ 'users/getuserPageRelationmasterdetails';
        let options = {
            method: 'POST',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {ModuleId:`${moduleId}`,USERTYPEID:`${usertypeid}`}
        };
        let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if(data.Success==true)
                { let ds=data.Data;
                    if(ds!=null && ds.length>0)
                    {
                        SettbAccessPageRMaster(ds);
                                     
                    }

                    if ((moduleId=="1")) {
                        const IsDefaultDs=ds.filter((item)=>{

                             return item.IsDefaultSelected=="True";
                        });
                         if(IsDefaultDs!=null && IsDefaultDs.length>0)
                         {
                            GlbModuleCallArr=[];
                              for( var i=0;i<IsDefaultDs.length; i++)
                              {
                                const item1={
                                    "PageId":IsDefaultDs[i].PageId,
                                    "UserTypeId":IsDefaultDs[i].UserTypeId
                                }
                                GlbModuleCallArr.push(item1);

                              }
                          
                            // if(SelectedPageUserR.map(val => val["PageId"]).indexOf(item1["PageId"]) == -1) 
                            // {
                            //     SetSelectedPageUserR([...SelectedPageUserR, item1]);
                            // }

                         }

                    
                     
                    }
                     
                }
                else{
                    let Errtext=<p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                    console.log('no record found');
                }
               
              
            }
            else{
                let Errtext=<p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
            }
         // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
         }
      }


      const asyncFunModuleDDLDetails =async()=> {
        try {
         
        let url=URL_Start+ 'master/getBindModuledetails';
        let options = {
            method: 'POST',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {}
        };
        let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if(data.Success==true)
                { let ds=data.Data;
                    if(ds!=null && ds.length>0)
                    {
                        SetModuleDetail(ds.map((option) =>(<option value={option.Mid}>{option.ModuleName}</option>)));               
                    }
                }
                else{
                    let Errtext=<p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                    console.log('no record found');
                }
               
              
            }
            else{
                let Errtext=<p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
            }
         // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
         }
      }
     
     
  
      /********** Modals  ***********/
      const [modal, setModal] = useState();
      const toggleModal = () => {
          setModal(!modal)
      }



    
      const [errorModal, setErrorModal] = useState();
      const errorHandler = () => {
        if(GlbSelectedPageUserR.length==0 && globalModuleId!="1")
        {
            globalPageSelection=true;
        }
       else if((GlbSelectedPageUserR.length==0 ||GlbSelectedPageUserR.length==1) && globalModuleId=="1")
        {
            globalPageSelection=true;
        }
         else{
             globalPageSelection=false; 
         }
        
         
           setModal(true); setErrorModal(true);
           formik.handleSubmit();
      
      }
  
    
      
  
  
      /********** Validations  ***********/
      const formik = useFormik({
          initialValues: {
            module:'',
            moduletype:'',
            groupname:'',
            PageAccessGroupId:'0'
          },
          enableReinitialize: true,
          validationSchema: Yup.object({
              // email: Yup.string().email('Invalid email address').required('Required'),
              // callType: Yup.string().transform(v => v=== '0' ? '' : v)
              // .required('Call Type is Required'),
              module: Yup.string().transform(v => v=== '0' ? '' : v)
                   .required('Module is Required'),
                   groupname: Yup.string().required('Access Group Name is Required')
                  
                
          }),
          isSubmitting:true,
          onSubmit:  async (values,actions) => {
                  try {
                    if(GlbSelectedPageUserR.length==0 && globalModuleId!="1")
                    {
                     globalPageSelection=true;
                     setModal(true); setErrorModal(true);
                      return true;
                    }
                   else if((GlbSelectedPageUserR.length==0 ||GlbSelectedPageUserR.length==1) && globalModuleId=="1")
                    {
                     globalPageSelection=true;
                     setModal(true); setErrorModal(true);
                      return true;
                    }
                     else{
                         globalPageSelection=false;
                   
                         setModal(false);
                         setErrorModal(false);
                         setErrorPopUp("");
          
                     }
       
                  let url=URL_Start+ 'Users/SETUPDATEPageAccessGroups';
                 
                  let options = {
                      method: 'POST',
                      url: url,
                      headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json;charset=UTF-8'
                      },
                    data:{PageAccessGroupId:values.PageAccessGroupId,AccessGroupName:values.groupname,ModuleId:values.module,
                    AddedBy:`${LoginId}`,objPageIds:GlbSelectedPageUserR }
                  };
                 
                  let response = await axios(options);
                      let responseOK = response && response.status == 200;
                      if (responseOK) {
                          let data = response.data;
                          // let data = await response.data;
                          if(data.Success==true && data.Data=="2000")
                          {  formik.resetForm();
                             
                            
                              SweetAlert.fire({ title: "Success!", text: "Access Group has been Created!", icon: "success" });
                              setErrorModal(false);
                              SettbAccessPageRMaster([]);
                              history.push({ pathname: `${process.env.PUBLIC_URL}/user-master/accessgroups`, search: `` });
  
                          }
                         else if(data.Success==true && data.Data=="2001")
                          {  formik.resetForm();
                             
                            
                              SweetAlert.fire({ title: "Warning", text: "Access Group Already Exist!", icon: "info" });
                              setErrorModal(false);
                             
  
                          }
                          else{
                               if(data.ErrorList!=null && data.ErrorList.length>0)
                               {
                                  let Errtext=<p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                                  SweetAlert.fire({ title: "Error", text:data.ErrorList[0].errorMsg, icon: "error" });
                               }
                              
                          }
                         
                        
                      }
                      else{
                          console.log('no record found');
                          
                      }
                   // return data;
                  } catch (error) {
                      console.log(error.message);
                      let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                      SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
                   }
                   setTimeout(() => {
                    
                      actions.setSubmitting(false);
                      }, 1000);
          },
      });
      
          
      function onKeyDown(keyEvent) {
          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
          }
        }
             
        const ModuleChangeHandler= (e) => {
            const s1 = e.target.value;
            globalModuleId=s1;
            GlbSelectedPageUserR=[];
            formik.setFieldValue('groupname','');
            SettbAccessPageRMaster([]);
            if(e.target.value=='0')
            {
                SettbAccessPageRMaster([]);
                formik.setFieldValue('module','');
            }
            else{
                formik.setFieldValue('module',e.target.value);
               
               // setValue(e.target.value);
                }
               
                if(s1=="0")
                     {
                         formik.values.module="0";
                     }
                     else{
                        asyncFunAccessPageRelationDetails(globalModuleId,0);
                     }
          
        }

  const PrevBckHandler= async()=>{
    history.push({ pathname: `${process.env.PUBLIC_URL}/user-master/accessgroups`, search: `` });
  }



  
  const CheckHandler = (e) => {
    const value = e.target.value;
    const itemUTypeId=globalModuleId=="1"?"3":"0";
  const isChecked =e.target.checked;

//   const GlbModuleCallArrFilter=GlbModuleCallArr.filter((item)=>{

//     return item.UserTypeId==itemUTypeId && itemUTypeId!='-11' && globalModuleId=="1";
// });
// if(GlbModuleCallArrFilter!=null && GlbModuleCallArrFilter.length>0 && globalModuleId=="1")
// {
//     const item1={
//         "PageId":GlbModuleCallArrFilter[0].PageId,
//         "UserTypeId":GlbModuleCallArrFilter[0].UserTypeId
//     }

//     if(SelectedPageUserR.map(val => val["PageId"]).indexOf(item1["PageId"]) == -1 && globalModuleId=="1") 
//     {
//                              SetSelectedPageUserR([...SelectedPageUserR, item1]);
//    }
// }

// if(GlbModuleCallArrFilter!=null && GlbModuleCallArrFilter.length>0 && globalModuleId=="1" && SelectedPageUserR.map(val => val["UserTypeId"]).indexOf(itemUTypeId) ==-1)
// {
//  alert('You cant selected');
// }
//alert(itemUTypeId);
//GlbModuleCallArr
  //   SetSelectedPageUserR((prev) =>
//   SelectedPageUserR.includes(value)
//         ? prev.filter((cur) => cur !== value)
//         : [...prev, e.target.value]
//     );
  const item = {
    "PageId":value,
 "UserTypeId":itemUTypeId
  };
  if (e.target.checked) {
    if(globalModuleId=="1" && GlbSelectedPageUserR.length==0)
    {
                
      const GlbModuleCallArrFilter=GlbModuleCallArr.filter((itemk)=>{

          return itemk.UserTypeId==itemUTypeId && globalModuleId=="1";
      });
      if(GlbModuleCallArrFilter!=null && GlbModuleCallArrFilter.length>0)
      {
          let item11={
              "PageId":GlbModuleCallArrFilter[0].PageId,
              "UserTypeId":GlbModuleCallArrFilter[0].UserTypeId
          }

      if((GlbSelectedPageUserR.map(val => val["PageId"]).indexOf(item11["PageId"]) == -1)) 
       {
        GlbSelectedPageUserR.push(item11);
        //SetSelectedPageUserR(SelectedPageUserR=>[...SelectedPageUserR, item11]);
       // ...SelectedPageUserR, 
       //SetSelectedPageUserR([item11]);   
      }
      }
      
    }
    else{
        if(GlbSelectedPageUserR.length==1 && globalModuleId=="1" )
        {
            GlbSelectedPageUserR=[];
            const GlbModuleCallArrFilter=GlbModuleCallArr.filter((itemk)=>{

                return itemk.UserTypeId==itemUTypeId && globalModuleId=="1";
            });
            if(GlbModuleCallArrFilter!=null && GlbModuleCallArrFilter.length>0)
            {
                let item11={
                    "PageId":GlbModuleCallArrFilter[0].PageId,
                    "UserTypeId":GlbModuleCallArrFilter[0].UserTypeId
                }
      
            if((GlbSelectedPageUserR.map(val => val["PageId"]).indexOf(item11["PageId"]) == -1)) 
             {
              GlbSelectedPageUserR.push(item11);
              //SetSelectedPageUserR(SelectedPageUserR=>[...SelectedPageUserR, item11]);
            }
            }
            
            //SetSelectedPageUserR(SelectedPageUserR=>[...[]]);
        }
    }
    if(GlbSelectedPageUserR.filter(val => val["UserTypeId"]==itemUTypeId)==false && GlbSelectedPageUserR.length>0 && globalModuleId=="1")
    {
        
    
        SweetAlert.fire({ title: "Warning", text:"You  can choose only single module type user or admin.", icon: "warning" });
        return e.target.checked=false;
   
    }
    if(GlbSelectedPageUserR.map(val => val["PageId"]).indexOf(item["PageId"]) == -1) 
    GlbSelectedPageUserR.push(item);
    //SetSelectedPageUserR(SelectedPageUserR=>[...SelectedPageUserR, item]);
    //SetSelectedPageUserR([...SelectedPageUserR, item]);
  } else {
    GlbSelectedPageUserR=GlbSelectedPageUserR.filter(val => val["PageId"] != item["PageId"]);
   // SetSelectedPageUserR(SelectedPageUserR=>[...SelectedPageUserR.filter(val => val["PageId"] != item["PageId"])]);
    //SetSelectedPageUserR(SelectedPageUserR.filter(val => val["PageId"] != item["PageId"]));
  } 

  
  };


  const CheckHandler1 = (e) => {
    const value = e.target.value;
    const itemUTypeId="0";
  const isChecked =e.target.checked;

  const item = {
    "PageId":value,
 "UserTypeId":itemUTypeId
  };
  if (e.target.checked) {
    if(globalModuleId=="1" && GlbSelectedPageUserR.length==0)
    {
                
      
      const GlbModuleCallArrFilter=GlbModuleCallArr.filter((itemk)=>{

          return itemk.UserTypeId==itemUTypeId && globalModuleId=="1";
      });
      if(GlbModuleCallArrFilter!=null && GlbModuleCallArrFilter.length>0)
      {
          let item11={
              "PageId":GlbModuleCallArrFilter[0].PageId,
              "UserTypeId":GlbModuleCallArrFilter[0].UserTypeId
          }

      if((GlbSelectedPageUserR.map(val => val["PageId"]).indexOf(item11["PageId"]) == -1)) 
       {
        GlbSelectedPageUserR.push(item11);
        //SetSelectedPageUserR(SelectedPageUserR=>[...SelectedPageUserR, item11]);
      }
      }
      
    }
    else{
        if(GlbSelectedPageUserR.length==1 && globalModuleId=="1" )
        {
            GlbSelectedPageUserR=[];
            const GlbModuleCallArrFilter=GlbModuleCallArr.filter((itemk)=>{

                return itemk.UserTypeId==itemUTypeId && globalModuleId=="1";
            });
            if(GlbModuleCallArrFilter!=null && GlbModuleCallArrFilter.length>0)
            {
                let item11={
                    "PageId":GlbModuleCallArrFilter[0].PageId,
                    "UserTypeId":GlbModuleCallArrFilter[0].UserTypeId
                }
      
            if((GlbSelectedPageUserR.map(val => val["PageId"]).indexOf(item11["PageId"]) == -1)) 
             {
              GlbSelectedPageUserR.push(item11);
              //SetSelectedPageUserR(SelectedPageUserR=>[...SelectedPageUserR, item11]);
            }
            }
            
            //SetSelectedPageUserR(SelectedPageUserR=>[...[]]);
        }
    }
    if(GlbSelectedPageUserR.filter(val => val["UserTypeId"]==itemUTypeId)==false && GlbSelectedPageUserR.length>0 && globalModuleId=="1")
    {
 
    
        SweetAlert.fire({ title: "Warning", text:"You  can choose only single module type user or admin.", icon: "warning" });
        return e.target.checked=false;
   
    }
    if(GlbSelectedPageUserR.map(val => val["PageId"]).indexOf(item["PageId"]) == -1)
    GlbSelectedPageUserR.push(item);
    //SetSelectedPageUserR(SelectedPageUserR=>[...SelectedPageUserR, item]);
  } else {
    GlbSelectedPageUserR=GlbSelectedPageUserR.filter(val => val["PageId"] != item["PageId"]);
   // SetSelectedPageUserR(SelectedPageUserR=>[...SelectedPageUserR.filter(val => val["PageId"] != item["PageId"])]);
    //SetSelectedPageUserR(SelectedPageUserR.filter(val => val["PageId"] != item["PageId"]));
  } 

  
  };
 
    return (
        <Fragment>
            <Breadcrumb title="Access Groups" parent="Master" />
            <Container fluid>

                <Row>
                    <Col md={12}>
                        <Card>
                        <div class="pb-0 card-header"><div class="card-title h5">Access Group Creation</div></div>
                            <Card.Body>
                             
                                   
                                    <Form.Row className="justify-content-center">
                                       
                                       <Col md={6} lg={6}>
                                               <Form.Group>
                                                               <Form.Label>Module<span className='text-required'>*</span></Form.Label>
                                                               <Form.Control as="select"  name="module"  onChange={ModuleChangeHandler} onBlur={formik.handleBlur} value={formik.values.module}>
                                                               <option value='0'>---select---</option>
                                                             {ModuleDetail}
                                                               </Form.Control>
                                                               {formik.touched.module && formik.errors.module ? (<div className='text-required'>{formik.errors.module}</div>) : null}
                                                           </Form.Group>
                                          </Col>
                                        
                                       </Form.Row>
                                      
                                      
                                          {tbAccessPageRMaster!=null && tbAccessPageRMaster.length>0?<>
                                      
                                            {<div className='text-required'>*Page Selection required</div>}
                                            {globalModuleId=="1"?<>
                                            <br></br>
                                            <div className='text-required'>{'Module Type-->User'}</div>
                                            <Form.Row className="justify-content-left">
                                        {tbAccessPageRMaster.map((item) =>{
 
                                             return<>
                                              {(item.ModuleId=="1"  && ( item.UserTypeId=="3" ||item.UserTypeId=="-11"))? <Col md={4} lg={4}>

                            {item.IsDefaultSelected=="True" ?<>
                                    <Form.Label>
                                                    
                                                    <input name={"chk"+`${item.PageId}`} className="checkbox_animated" type="checkbox"  
                                                    value={`${item.PageId}`}  checked />
                                                    {item.Hierarchy}
                                                </Form.Label></>:   <Form.Label>
                                                    
                                                    <input name={"chk"+`${item.PageId}`} className="checkbox_animated" type="checkbox"  
                                                    onChange={CheckHandler}  value={`${item.PageId}`}  />
                                                    {item.Hierarchy}
                                                </Form.Label>
                                                    
                                                }
                                                
                                </Col>:null}
                                             
                                             </>
                                        })

                                        }
                                        
                            
                                      
                                            </Form.Row><hr></hr> <div className='text-required'>{'Module Type-->Admin'}</div><Form.Row className="justify-content-left">
                                        {tbAccessPageRMaster.map((item) =>{
 
                                             return<>
                                              {(item.ModuleId=="1"  && ( item.UserTypeId=="0" ||item.UserTypeId=="-11"))? <Col md={4} lg={4}>

                            {item.IsDefaultSelected=="True" ?<>
                                    <Form.Label>
                                                    
                                                    <input name={"chk"+`${item.PageId}`} className="checkbox_animated" type="checkbox"  
                                                    value={`${item.PageId}`}   checked />
                                                    {item.Hierarchy}
                                                </Form.Label></>:   <Form.Label>
                                                    
                                                    <input name={"chk"+`${item.PageId}`} className="checkbox_animated" type="checkbox"  
                                                    onChange={CheckHandler1}  value={`${item.PageId}`}   />
                                                    {item.Hierarchy}
                                                </Form.Label>
                                                    
                                                }
                                                
                                </Col>:null}
                                             
                                             </>
                                        })

                                        }
                                        
                            
                                      
                                            </Form.Row></> :<Form.Row className="justify-content-left">
                                        {tbAccessPageRMaster.map((item) =>{
 
                                             return<>
                                              <Col md={4} lg={4}>
                                              {/* <Form.Group className='animate-chk m-checkbox-inline'> */}
                                              { item.ModuleId=="1" && item.IsDefaultSelected=="True"?<>
                                              <Form.Label>
                                                              
                                                              <input name={"chk"+`${item.PageId}`} className="checkbox_animated" type="checkbox"  
                                                               value={`${item.PageId}`} checked />
                                                              {item.Hierarchy}
                                                          </Form.Label></>:   <Form.Label>
                                                              
                                                              <input name={"chk"+`${item.PageId}`} className="checkbox_animated" type="checkbox"  
                                                               onChange={CheckHandler}  value={`${item.PageId}`} />
                                                              {item.Hierarchy}
                                                          </Form.Label>
                                                                
                                                            }
                                                         
                                                            {/* </Form.Group> */}
                                          </Col>
                                             </>
                                        })

                                        }
                                      
                                            </Form.Row> }
                                            
                                          </>:null

                                          }
                                      
                                  <Form.Row className="justify-content-center">
                                       
                                        <Col md={6} lg={6}>
                                            <Form.Group>
                                                <Form.Label htmlFor="groupname">Access Group Name<span className='text-required'>*</span></Form.Label>
                                                <Form.Control type='text' id="" name="groupname"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.groupname} />

                                                {formik.touched.groupname && formik.errors.groupname ? (
                                                    <div className='text-required'>{formik.errors.groupname}</div>
                                                ) : null}

                                               


                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>          
                <Row className='mt15'>
                                    <Col md={12} className='text-center'>
                                        <Button variant='secondary' className="mr-2"  onClick={PrevBckHandler} >Cancel</Button>
                                        {<Button variant='primary' type='submit' onClick={errorHandler}>Save</Button>}
                                    </Col>
                                </Row>
            </Container>

            {errorModal &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                    <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                    <ModalBody>
                   
                        <p>
                        {formik.touched.module && formik.errors.module ? (<div className='text-required'>{formik.errors.module}</div>) : null}
                        </p>
                      
                        {<p>{globalPageSelection==true?<div className='text-required'>Page Selection required</div>:null}</p>
                    
                        }
                      
                        <p>
                        {formik.touched.groupname && formik.errors.groupname ? (
                                                    <div className='text-required'>{formik.errors.groupname}</div>
                                                ) : null}
                        </p>
                       
               
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                        <Button variant="primary" onClick={toggleModal}>Ok</Button>
                    </ModalFooter>
                    
                </Modal>
            }
        </Fragment>
    )
}

export default AddAccessGroup;