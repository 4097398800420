import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { URL_Start } from '../../../constant';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';

const CentreRateTemplate = () => {

    const [statesList, setStatesList] = useState([]);
    const [RegionsList, setRegionsList] = useState([]);
    const [CentreType, setCentreType] = useState([]);
    const [table, setTable] = useState([]);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [CentreRatelistCSV, setCentreRatelistCSV] = useState([]);

    let refstate = useRef();
    let refregion = useRef();
    let refcentertype = useRef();

    useEffect(() => {
        GetStates("1");
        GetCentreType();
        GetTemplateList(refstate.current.value, refregion.current.value, refcentertype.current.value);
    }, []);

    const StateChangeHandler = (e) => {
        if (e.target.value !== "0") { GetRegions(e.target.value); } else { setRegionsList([]); }
        GetTemplateList(e.target.value, refregion.current.value, refcentertype.current.value);
    }
    const RegionChangeHandler = (e) => {
        GetTemplateList(refstate.current.value, e.target.value, refcentertype.current.value);
    }
    const paginationOptions = {
        sizePerPage: 100,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const columns = [{ dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    { dataField: 'CentreName', text: 'Centre Name', editable: false },
    { dataField: 'RateTemplateName', text: 'Rate Template Name', editable: false },
    { dataField: 'FromDate', text: 'From Date', editable: false },
    { dataField: 'ToDate', text: 'To Date', editable: false },
    { dataField: 'ActiveDays', text: 'Active Days', editable: false },
    { dataField: 'IsDefaultTemplate', text: 'Is Default Template', editable: false },
    { dataField: 'IsActive', text: 'Is Active', editable: false }
    ];
    const GetStates = async (id) => {
        try {
            let url = URL_Start + 'master/getstateslist/' + id
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setStatesList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });

        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetRegions = async (id) => {
        try {
            let url = URL_Start + 'master/getregionslist/' + id;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setRegionsList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }

    const GetCentreType = async () => {
        try {
            let url = URL_Start + 'master/getpartnertypelist/2/1'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setCentreType(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }

    const GetTemplateList = async (StateId, regionId, centretype) => {
        try {
            let url = URL_Start + 'master/GetCentreRateTemplate/' + StateId + '/' + regionId + '/' + centretype
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) { setTable(data.Data); }
                    else { setTable([]); }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetTemplateListCSV = async (StateId, regionId, centretype) => {
        try {
            let url = URL_Start + 'master/GetCentreRateTemplate/' + StateId + '/' + regionId + '/' + centretype
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        setDownloadVisible(true);
                        var newarr = [];
                        for (var key in data.Data) {
                            newarr.push({
                                CentreName: data.Data[key].CentreName,
                                RateTemplateName: data.Data[key].RateTemplateName,
                                FromDate: data.Data[key].FromDate,
                                ToDate: data.Data[key].ToDate,
                                IsActive: data.Data[key].IsActive,
                                IsDefaultTemplate: data.Data[key].IsDefaultTemplate,
                                ActiveDays: data.Data[key].ActiveDays
                            });
                        }
                        setCentreRatelistCSV(newarr);
                        document.getElementById("csvdownload").click();
                    }
                    else { setDownloadVisible(false); }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const CallRateTemplate = (e) => {
        GetTemplateList(refstate.current.value, refregion.current.value, e.target.value);
    }

    const handleSearchList = async () => {
        GetTemplateListCSV(refstate.current.value, refregion.current.value, refcentertype.current.value);
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Centre Rate Templates" parent="Reports" />
            <Container fluid>
                <Row className='mt25'>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>State</Form.Label>
                                            <Form.Control as="select" ref={refstate} name="centerstate" onChange={StateChangeHandler}>
                                                <option key="0" value="0">---Select---</option>
                                                {statesList}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>Region</Form.Label>
                                            <Form.Control as="select" ref={refregion} name="centerregion" onChange={RegionChangeHandler}>
                                                <option key="0" value="0">---Select---</option>
                                                {RegionsList}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>Centre Type</Form.Label>
                                            <Form.Control as="select" name="centertype" ref={refcentertype} onChange={(e) => CallRateTemplate(e)}>
                                                <option key="0" value="0">---Select---</option>
                                                {CentreType}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} className='text-center __center__buttonsec mb-4'>
                                        <Button variant='primary' onClick={handleSearchList} filename={"CentreRateTemplates.csv"} target="_blank" >Download CSV</Button>
                                    </Col>
                                    <Col lg={12} md={12}>
                                        {
                                            <DataTables
                                                keyField='Id'
                                                tableData={table}
                                                columns={columns}
                                                noDataIndication={NoRecordFounds}
                                                pagination={paginationFactory(paginationOptions)}
                                            />
                                        }
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    downloadvisible &&
                    <CSVLink data={CentreRatelistCSV} filename="CentreRateTemplates.csv" target="_blank" id="csvdownload"></CSVLink>
                }
            </Container>
        </React.Fragment>
    )
}

export default CentreRateTemplate