import React from 'react'

const NoRecordFounds = () => {
    return (
        <div style={{ textAlign: 'center', color: 'red' }}>
            <h4>No Record Found</h4>
        </div>
    )
}

export default NoRecordFounds
