import React, { useEffect, useState, useRef } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Breadcrumb from '../../../components/common/breadcrumb';
import DynamicDataTables from '../commonComponent/DataTable/DynamicDataTable';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ViewToolTip from '../UI/ToolTips/ViewToolTip';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';

const DoctorUploadBusinessMapping = () => {
    let textSearch = useRef();
    let refstate = useRef();
    let refregion = useRef();
    let refDistrict = useRef();
    let refMapped = useRef();
    const [statesList, setStatesList] = useState([]);
    const [RegionsList, setRegionsList] = useState([]);
    const [DistrictList, setDistrictList] = useState([]);
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [DrBusinessMaplistCSV, setDrBusinessMaplistCSV] = useState([]);
    const [downloadvisible, setDownloadVisible] = useState(false);

    const [PageHelper, SetPageHelper] = useState({
        PageNo: '1',
        PageSize: '10',
        TotalRecord: 0,
        dsDoctorDlists: []

    });
    const paginationOptions = {

        totalSize: PageHelper.TotalRecord,
        sizePerPage: PageHelper.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',

        onSizePerPageChange: (sizePerPage, page, event) => {
            console.log('Size per page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            BindGrid(page, sizePerPage, textSearch.current.value.toLowerCase());
            document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
        },
        onPageChange: (page, sizePerPage) => {
            console.log('Page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            BindGrid(page, sizePerPage, textSearch.current.value.toLowerCase());
        }
    };

    const columns = [{
        dataField: '#',
        text: '#',
        formatter: (cell, row, rowIndex) => {
            let rowNumber = (PageHelper.PageNo - 1) * PageHelper.PageSize + (rowIndex + 1);
            return <span>{rowNumber}</span>;
        },
        headerStyle: (colum, colIndex) => {
            return { width: '5%' };
        }
    },
    {
        dataField: 'DoctorName',
        text: 'DoctorName',
        editable: false

    },
    {
        dataField: 'Mobile',
        text: 'Mobile',
        editable: false
    },
    {
        dataField: 'FMLocation',
        text: 'Location',
        editable: false
    },
    {
        dataField: 'DocSpecialization',
        text: 'Specialization',
        editable: false
    }
        // {
        //     dataField: 'actions',
        //     isDummyField: true,
        //     text: 'Actions',
        //     formatter: ViewFormatter
        // }
    ];

    useEffect(() => {
        GetStates(1);
        BindGrid(1, 10, '');
    }, []);

    const GetStates = async (id) => {
        try {
            let url = URL_Start + 'master/getstateslist/' + id
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setStatesList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetRegions = async (id) => {
        try {
            let url = URL_Start + 'master/getregionslist/' + id;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data !== null) { setRegionsList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
                    else { setRegionsList([]); }
                }
                else { setRegionsList([]); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetDistricts = async (id) => {
        try {
            let url = URL_Start + 'master/getdistrictslist/' + id;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data !== null) { setDistrictList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
                    else { setDistrictList([]); }
                }
                else { setDistrictList([]); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }

    const StateChangeHandler = (e) => {
        if (e.target.value !== "0") { GetRegions(e.target.value); } else { setRegionsList([]); }
        setDistrictList([]);
    }
    const RegionChangeHandler = (e) => {
        if (e.target.value !== "0") { GetDistricts(e.target.value); } else { setDistrictList([]); }
    }

    const handleSearch = async () => {BindGrid(1, 10, textSearch.current.value.toLowerCase());}
  
    const BindGrid = async (PageNo, PageSize, SearchTxtInput) => {

        setIsLoading(true);
        try {

            let url = URL_Start + 'DoctorMaster/getuploaddoctordetails';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { PageNo: `${PageNo}`, PageSize: `${PageSize}`, SearchTxt: `${SearchTxtInput}`,DrExcelStatus: '0',StateId: refstate.current.value,RegionId: refregion.current.value,DistrictId: refDistrict.current.value,
                MapDoctor:refMapped.current.value }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;

                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        setIsLoading(false);
                        await SetPageHelper({
                            ...PageHelper,
                            PageNo: Number(PageNo),
                            PageSize: Number(PageSize),
                            TotalRecord: Number(ds.TotalRecord),
                            dsDoctorDlists: ds.ObjDoctorUploadBusinessList == null ? [] : ds.ObjDoctorUploadBusinessList
                        });

                     
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');
                    setIsLoading(false);
                    await SetPageHelper({
                                ...PageHelper,
                                PageNo: Number(PageNo),
                                PageSize: Number(PageSize),
                                TotalRecord: Number(0),
                                dsDoctorDlists: []
                            });
                }

            }
            else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
            // return data;
        } catch (error) {
            if (error.response) {
                ErrorFunction(error.response.status);
                setIsLoading(false);
                await SetPageHelper({
                    ...PageHelper,
                    PageNo: Number(PageNo),
                    PageSize: Number(PageSize),
                    TotalRecord: Number(0),
                    dsDoctorDlists: []
                });
            }

        }

        // if (SearchTxtInput == "") {
        //     await SetPageHelper({
        //         ...PageHelper,
        //         PageNo: Number(PageNo),
        //         PageSize: Number(PageSize),
        //         TotalRecord: Number(0),
        //         dsDoctorDlists: []
        //     });

        //     setIsLoading(false);
        // }
        // else {
           
        // }

    }
    const handleSearchList = async () => {

        DownloadDrBusinessMapList(1, 10, textSearch.current.value.toLowerCase());

    }
    const DownloadDrBusinessMapList = async (PageNo, PageSize, SearchTxtInput) => {
        try {
          
                // setIsLoading(true);
                let url = URL_Start + 'DoctorMaster/getdownloaddoctordetails';
                let options = {
                    method: 'POST',
                    url: url,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                    data: { PageNo: `${PageNo}`, PageSize: `${PageSize}`, SearchTxt: `${SearchTxtInput}`, DrExcelStatus: '1',StateId: refstate.current.value,RegionId: refregion.current.value,DistrictId: refDistrict.current.value
                    ,MapDoctor:refMapped.current.value }
                };
                let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                    let data = response.data;
                    if (data.Success) {
                        // setIsLoading(false);
                        if (data.Data != null && data.Data.ObjDoctorDownloadBusinessList.length>0) { setDownloadVisible(true); setDrBusinessMaplistCSV(data.Data.ObjDoctorDownloadBusinessList); document.getElementById("csvdownload").click(); } else {
                            setDownloadVisible(false); setDrBusinessMaplistCSV([]);
                            SweetAlert.fire({ title: "warning", text:'no record found', icon: "info" });
                         }
                    }
                    else {
                        setDownloadVisible(false); setDrBusinessMaplistCSV([]);
                        //  setIsLoading(false);
                        //APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); 
                    }
                }
                else { 
                    setDownloadVisible(false); setDrBusinessMaplistCSV([]);
                    SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.');}
        } catch (error) {
            if (error.response) {
                setDownloadVisible(false); setDrBusinessMaplistCSV([]);
                ErrorFunction(error.response.status);
                // setIsLoading(false);
            }

        }
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Data unavailable", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-2") { SweetAlert.fire({ title: "API Server Error", text: ErrorMsg, icon: "error" }); }
        else if (ErrorCode === "-3") { SweetAlert.fire({ title: "Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        else if (ErrorCode === "-4") { SweetAlert.fire({ title: "Wrong Credentials", text: "Please enter correct credentials", icon: "error" }) }
        else if (ErrorCode === "-5") { SweetAlert.fire({ title: "Oops", text: "Data could not be saved, Please try again later", icon: "error" }) }
        else if (ErrorCode === "-6") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
        else if (ErrorCode === "-7") { SweetAlert.fire({ title: "Duplicate", text: "Template already exists", icon: "error" }) }
        else if (ErrorCode === "-8") { SweetAlert.fire({ title: "Data unavailable", text: "No record found to import", icon: "error" }) }
        else if (ErrorCode === "-9") { SweetAlert.fire({ title: "LDAP authentication failed", text: ErrorMsg, icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }

    return (
        <React.Fragment>
            <Breadcrumb title="Download Uploading Doctor Lists" parent="Master" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                            <Form.Row className='justify-content-center'>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>State</Form.Label>
                                            <Form.Control as="select" ref={refstate} name="state" onChange={StateChangeHandler} >
                                                <option key="0" value="0">---Select All---</option>
                                                {statesList}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Region</Form.Label>
                                            <Form.Control as="select" ref={refregion} name="region" onChange={RegionChangeHandler}>
                                                <option key="0" value="0">---Select All---</option>
                                                {RegionsList}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>District</Form.Label>
                                            <Form.Control as="select" name="District" ref={refDistrict} >
                                                <option key="0" value="0">---Select All---</option>
                                                {DistrictList}
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Mapping Status</Form.Label>
                                            <Form.Control as="select" name="MapStatus" ref={refMapped} >
                                                <option key="0" value="0">---Select All---</option>
                                                <option key="1" value="1">Mapped</option>
                                                <option key="2" value="2">UnMapped</option>
                                            </Form.Control>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row className='justify-content-center'>
                                    <Col lg={6} md={6} className="col-7">
                                        <Form.Group>

                                            <InputGroup className="mb-3">
                                                <Form.Control  placeholder="Doctor Name" ref={textSearch} />
                                                <InputGroup.Append>
                                                    <Button variant="secondary" onClick={handleSearch}><i className='fa fa-search'></i></Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2} md={6} className="col-5">
                                        <Form.Group>
                                            <InputGroup className="mb-3">

                                                <InputGroup.Append>
                                                    <Button variant='primary' type='submit' onClick={handleSearchList} filename={"DoctorUploadBusinessMappingList.csv"} target="_blank" >Download CSV</Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={12} className='text-center'>
                                        {/* <Button variant='secondary' className="mr-2" onClick={handleSearch} >Search</Button> */}

                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Doctor List</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading &&
                                    <div className='text-center'> <Spinner animation="border" size='sm' /></div>
                                }

                                <DynamicDataTables keyField="DoctorId" tableData={PageHelper.dsDoctorDlists} columns={columns} pagination={paginationOptions ? paginationFactory(paginationOptions) : null} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    downloadvisible &&
                    <CSVLink data={DrBusinessMaplistCSV} filename="DoctorUploadBusinessMappingList.csv" target="_blank" id="csvdownload"></CSVLink>
                }
            </Container>
        </React.Fragment>
    )
}

export default DoctorUploadBusinessMapping;