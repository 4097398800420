import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { URL_Start } from '../../../constant';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import InputGroup from 'react-bootstrap/InputGroup';
import { useHistory } from "react-router-dom";
import LoadingButton from '../commonComponent/LoadingButton';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { MultiSelect } from 'react-multi-select-component';
import '../../../pages/admin/react-multiselect.css';

const B2BInvoices = () => {
    let fromDate = useRef();
    let toDate = useRef();
    let CentreName = useRef("");

    const [crntPageNO, setCrntPageNO] = useState(0);
    const [defaultDate, setDefaultDate] = useState("");
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [invoices, setInvoices] = useState([]);
    const [users, setUsers] = useState([]);
    const [SelectCentres, SetSelectCentres] = useState([]);
    const [LGlCentreTypeoption, SetLGlCentreTypeoption] = useState([]);

    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        GetCentresList();
        //GetB2BInvoiceNo("0");
    }, []);

    const paginationOptions = {
        sizePerPage: 50,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {/* <a href='javascriptvoid:(0)' id={`Documents${rowData.B2BInv_ID}`} onClick={() => GetReportDataCheckRecordFound(rowData.B2BInv_ID)}>View Invoice</a> */}
                <button className='btn btn-link' style={{ color: '#e31e25', padding: '0px' }} id={`Documents${rowData.B2BInv_ID}`} onClick={() => GetReportDataCheckRecordFound(rowData.B2BInv_ID)}>View Invoice</button>

            </div>
        </React.Fragment>
    }
    const columns =
        [
            { dataField: '#', text: '#', editable: false, formatter: idFormatter },
            { dataField: 'CentreName', text: 'Centre Name', editable: false },
            { dataField: 'InvoiceNo', text: 'Invoice No', editable: false },
            { dataField: 'BillingCycle', text: 'Billing Cycle', editable: false },
            { dataField: 'FromDate', text: 'From Date', editable: false },
            { dataField: 'ToDate', text: 'To Date', editable: false },
            { dataField: 'Action', text: 'Action', editable: false, formatter: actionFormatter }
        ];

    const GetCentresList = async () => {
        try {
            let url = URL_Start + 'master/GetCentresforPartnerBilling'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        let ds = data.Data;
                        const TypeOptionDs1 = [];
                        ds.map((option) => {
                            const TypeOptions1 = {
                                'CentreId': option.CentreId,
                                'label': option.CentreName,
                                'value': option.CentreId,
                                'disabled': false
                            }
                            return TypeOptionDs1.push(TypeOptions1);
                        });
                        setUsers(TypeOptionDs1);
                    }
                    else { setUsers([]); }
                    // setUsers(data.Data.map((d) => (<option key={d.Id} value={d.CentreId}>{d.CentreName}</option>)))
                }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetB2BInvoiceNo = async (Flag) => {
        var fromdte = "0";
        var todte = "0";
        if (Flag == "1") {
            if (fromDate.current.value == "" || fromDate.current.value == null || fromDate.current.value == undefined) {
                SweetAlert.fire({ title: "Warning!", text: "Please select from date", icon: "info" });
                return false;
            }
            if (toDate.current.value == "" || toDate.current.value == null || toDate.current.value == undefined) {
                SweetAlert.fire({ title: "Warning!", text: "Please select to date", icon: "info" });
                return false;
            }
            // if (CentreName.current.value == '0' || CentreName.current.value == '') {
            //     SweetAlert.fire({ title: "Warning!", text: "Please select partner", icon: "info" });
            //     return false;
            // }
            if (LGlCentreTypeoption.length == 0 || LGlCentreTypeoption == null || LGlCentreTypeoption == undefined || LGlCentreTypeoption == []) { 
                SweetAlert.fire("Partner is mandatory to select");
                 return false; 
            }
            fromdte = fromDate.current.value;
            todte = toDate.current.value;
        }
        const requestoptions = {
            FromDate: fromdte,
            ToDate: todte,
            CentreId: JSON.stringify(LGlCentreTypeoption),
            LoginId: localStorage.LoggedInUser
        };
        setIsButtonLoading(true);
        try {
            let url = URL_Start + 'reports/GetB2BInvoiceNo' 
            await axios.post(url,requestoptions).then((response) => {
                const data = response.data;
                setIsButtonLoading(false);
                if (data.Success) {
                    if (data.Data == null) { setInvoices([]); }
                    else { setInvoices(data.Data); }
                }
                else { setInvoices([]); }
            }).catch(function (error) { if (error.response) { setIsButtonLoading(false); ErrorFunction(error.response.status); } });
        }
        catch (error) { setIsButtonLoading(false); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetReportDataCheckRecordFound = async (InvoiceId) => {
        const ObjServiceCollection = {
            InvoiceId: InvoiceId,
            LoginId: localStorage.LoggedInUser
        };
        try {
            let url = URL_Start + 'reports/GetB2bInvoiceGenerateReports'
            await axios.post(url, ObjServiceCollection).then((response) => {
                const data = response.data;
                if (data.Success) {
                    let ds = data.Data;
                    //if (ds != null && ds.objB2BInvoiceTestCountDataList != null && ds.objB2BInvoiceTestCountDataList.length > 0) {
                    if (ds != null) {
                        const stateparams = { fromDate: fromDate.current.value, toDate: toDate.current.value, CentreId: CentreName.current.value };
                        localStorage.removeItem('CustreportData');
                        localStorage.removeItem('IID');
                        localStorage.setItem('CustreportData', JSON.stringify(stateparams));
                        localStorage.setItem('IID', InvoiceId);
                        window.open(`${process.env.PUBLIC_URL}/pdfprints/printb2binvoice`, '_blank');
                    }
                    else {
                        SweetAlert.fire({ title: "Warning!", text: "record no found", icon: "info" });
                    }
                }
            }).catch(function (error) { if (error.response) { setIsButtonLoading(false); ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const CentreHandler = (options) => {
        SetSelectCentres(options);
        const TypeOptL = [];
        if (options != null && options.length > 0) {
            options.map((item) => {
                const TypeOptrows = {
                    'CentreId': item.CentreId
                }
                return TypeOptL.push(TypeOptrows);
            });
            SetLGlCentreTypeoption(TypeOptL);
        }
        else {
            SetLGlCentreTypeoption([]);
        }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="B2B Invoices" parent="Reports" />
            <Container fluid>
                <Row className='mt25'>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row >
                                    <Col md={1}>&nbsp;</Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>From Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                {/* defaultValue={defaultDate} */}
                                                <Form.Control type="date" id="fromDate" name="fromDate" ref={fromDate} defaultValue={defaultDate} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>To Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                {/* defaultValue={defaultDate} */}
                                                <Form.Control type="date" id="toDate" name="toDate" ref={toDate} defaultValue={defaultDate} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Label>Partners<span className='text-red'>*</span></Form.Label>
                                        <div className='txtMultiSelect1'>
                                            <MultiSelect id="Centrename" name="Centrename"
                                                options={users}
                                                value={SelectCentres}
                                                onChange={CentreHandler}
                                                labelledBy="Select"
                                            /></div>
                                        {/* <Form.Control as="select" id="Centrename" name="Centrename" ref={CentreName} >
                                            <option value="0" selected>Select</option>
                                            {users}
                                        </Form.Control> */}
                                    </Col>
                                    <Col md={12} className='text-center __center__buttonsec'>
                                        <Form.Group>
                                            {isButtonLoading ? <LoadingButton variantName='primary' cssName="" /> :
                                                <Button className='ml-2' variant='primary' onClick={() => GetB2BInvoiceNo("1")}>Search</Button>
                                            }
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <div className="mt15">
                                    <BootstrapTable
                                        keyField='B2BInv_ID'
                                        data={invoices}
                                        columns={columns}
                                        bootstrap4
                                        headerWrapperClasses="thead-light"
                                        bordered={false}
                                        noDataIndication={<NoRecordFounds />}
                                        pagination={paginationFactory(paginationOptions)}
                                    >
                                    </BootstrapTable>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}
export default B2BInvoices;