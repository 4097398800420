import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Dropdown } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { BackgroundColor, FrontDesk_URL, URL_Start, Right } from '../../../constant';
import Breadcrumb from '../../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip';
import LoadingButton from '../commonComponent/LoadingButton';
import NumericInput from 'react-numeric-input';
import { useHistory } from "react-router-dom";
import BootstrapTable from 'react-bootstrap-table-next';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';
import ViewInvoice from '../../admin/BulkInvoice/ViewInvoice';

var glblDefaultRec = [];
var glblitemstore = [];
var chkflag = '';
var glblValArr = [];
let glbCreditCentreTypes = [];
let globalCentreid = "0";
const CreateInvoice = () => {
    const history = useHistory();
    const [centres, setCentres] = useState([]);
    const [options, setOptions] = useState([]);
    const [ratetype, setRateType] = useState(0);
    const [itemsno, setItemSno] = useState("");
    const [tableitems, setTableItems] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [changeflag, setChangeFlag] = useState(false);
    const [totaltest, setTotalTest] = useState(0);
    const [totalamt, setTotalAmt] = useState(0);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [paymentModeId, setPaymentModeId] = useState("0");
    const [Percentagecheckbox, setPercentagecheckbox] = useState("0");
    const [storeitem, setStoreItem] = useState([]);
    const [paymentmode, setPaymentMode] = useState([]);
    const [disablePayMode, setDisablePayMode] = useState(false);
    const [channelid, setChannelId] = useState("0");
    const [UserPOSCode, setUserPOSCode] = useState("");
    const [paymentChannels, setPaymentChannels] = useState([]);
    const [discountauthorizerdetails, setDiscountAuthorizerDetails] = useState({});
    const [creditauthorizerdetails, setCreditAuthorizerDetails] = useState({});
    const [discountAuthorizers, setDiscountAuthorizers] = useState([]);
    const [creditAuthorizers, setCreditAuthorizers] = useState([]);
    const [hideAddPayButton, setHideAddPayButton] = useState(false);
    const [hideshowpaymentbutton, setHideShowPaymentButton] = useState(false);
    const [centreDiscountLimit, setCentreDiscountLimit] = useState(0);
    const [dueamt, setDueAmt] = useState(0);
    const [paidamt, setPaidAmt] = useState(0);
    const [discamt, setDiscAmt] = useState(0);
    const [credamt, setCredAmt] = useState(0);
    const [visitingid, setVisitingId] = useState(0);
    const [paymenttable, setPaymentTable] = useState([]);
    const [tableitemsstatus, setTableItemsStatus] = useState(false);
    const [partnerTypeList, setPartnerTypeList] = useState([]);
    const [CenterTypeId, setCenterTypeId] = useState(0);
    const [buyercentreid, setbuyercentreid] = useState(0);
    const [availableamt, setavailableamt] = useState(0);
    const [creditLimit, setcreditLimit] = useState(0);
    const [showAvailableBalance, setShowAvailableBalance] = useState(1);
    const [FrieghtTaxAmt, setFrieghtTaxAmt] = useState(0);
    const [FrieghtTotalAmt, setFrieghtTotalAmt] = useState("0");
    const [gtotalamt, setGTotalAmt] = useState(0);
    const [frieghtTaxPer, setFrieghtTaxPer] = useState(0);

    const [invoiceType, setinvoiceType] = useState("1");
    const [billingStatesList, setBillingStatesList] = useState([]);
    const [billingRegionsList, setBillingRegionsList] = useState([]);
    const [billingDistrictsList, setBillingDistrictsList] = useState([]);

    const [shippingStatesList, setShippingStatesList] = useState([]);
    const [shippingRegionsList, setShippingRegionsList] = useState([]);
    const [shippingDistrictsList, setShippingDistrictsList] = useState([]);

    const [buyerBillToAddress, setBuyerBillToAddress] = useState("");
    const [buyerBillToPincode, setbuyerBillToPincode] = useState();
    const [buyerBillToStateId, setbuyerBillToStateId] = useState();
    const [buyerBillToRegionId, setbuyerBillToRegionId] = useState();
    const [buyerBillToDistrictId, setbuyerBillToDistrictId] = useState();
    const [buyerBillToGSTIN, setbuyerBillToGSTIN] = useState("");
    const [TransporterID, setTransporterID] = useState("");

    const [invoicepage, setInvoicePage] = useState(false);
    const [zeroinvoiceactive, setZeroInvoiceActive] = useState("false");


    const [cid, setCID] = useState("0");
    const multiple = false
    let testreference = useRef();
    let rratetype = useRef();
    let centreid = useRef();
    let partnertypeid = useRef();
    var paymentModeIdSetting = "";
    let paymentmodedetail = useRef();
    let paymentamountdetail = useRef();
    let paymentChannelId = useRef();
    let paymentReferenceNo = useRef();
    let authorizerId = useRef();
    let concessionReason = useRef();
    let posMachineId = useRef();
    var glblDiscountAuthorizer = [];
    var glblCreditAuthorizer = [];
    var glblPaymentsList = [];
    var glblPaymentModesList = [];
    let rfVehicleNo = useRef();
    useEffect(() => {
        setCenterTypeId(2);
        GetPartnerTypes(2);
        GetSAPStates("1");
        setStoreItem([]);
    }, [])
    useEffect(() => {
        var gTotal = 0;
        var totalTest = 0;
        var storedueamt = 0;
        var grandTotalAmt = 0;
        if (tableitems == "") {
            glblitemstore = [];
            setStoreItem([]);
        }
        else {
            glblitemstore = [];
            for (var key in tableitems) {
                gTotal += parseFloat(tableitems[key].TotalAmt);
                totalTest += parseFloat(tableitems[key].Qty);
                storedueamt += parseFloat(tableitems[key].TotalAmt);
                glblitemstore.push(
                    {
                        ItemSno: tableitems[key].ItemSNo,
                        ItemCode: tableitems[key].Code,
                        Item: tableitems[key].Item,
                        MRP: tableitems[key].Rate,
                        Disc: tableitems[key].Disc,
                        Rate: tableitems[key].Rate,
                        DeliveryDate: "",
                        MachineSNo: "0",
                        AppointmentTime: "",
                        Prereqsite: "",
                        Urgent: "0",
                        IsPackage: "0",
                        SchemeId: "0",
                        Qty: tableitems[key].Qty.toString(),
                        SubTotal: tableitems[key].SubTotal
                    }
                );
            }
        }
        setTotalAmt(Math.round(gTotal).toFixed(2));
        grandTotalAmt = parseFloat(gTotal) + parseFloat(FrieghtTotalAmt);
        setGTotalAmt(Math.round(grandTotalAmt).toFixed(2));
        setTotalTest(totalTest);
        if (paidamt == "0" && discamt == "0" && credamt == "0") { setDueAmt(parseFloat(grandTotalAmt).toFixed(2)) }
        else if (paidamt == "0" && discamt == "0" && credamt != "0") { setDueAmt(parseFloat(grandTotalAmt - credamt).toFixed(2)); }
        else if (paidamt == "0" && discamt != "0" && credamt == "0") { setDueAmt(parseFloat(grandTotalAmt - discamt).toFixed(2)); }
        else if (paidamt != "0" && discamt == "0" && credamt == "0") { setDueAmt(parseFloat(grandTotalAmt - paidamt).toFixed(2)); }
        else if (paidamt != "0" && discamt != "0" && credamt == "0") { setDueAmt(parseFloat(grandTotalAmt - (paidamt + discamt)).toFixed(2)); }
        else if (paidamt != "0" && discamt == "0" && credamt != "0") { setDueAmt(parseFloat(grandTotalAmt - (paidamt + credamt)).toFixed(2)); }
        else if (paidamt == "0" && discamt != "0" && credamt != "0") { setDueAmt(parseFloat(grandTotalAmt - (discamt + credamt)).toFixed(2)); }
        else if (paidamt != "0" && discamt != "0" && credamt != "0") { setDueAmt(parseFloat(grandTotalAmt - (paidamt + (discamt + credamt))).toFixed(2)); }
        grandTotalAmt > 0 && grandTotalAmt === paidamt ? setHideAddPayButton(true) : setHideAddPayButton(false);
        storedueamt = grandTotalAmt;
        setStoreItem(glblitemstore);
    }, [tableitems]);
    useEffect(() => {
        setTableItems(glblValArr);
        var gTotal = 0;
        var totalTest = 0;
        var storedueamt = 0;
        var grandTotalAmt = 0;
        glblitemstore = [];
        for (var key in tableitems) {
            gTotal += parseFloat(tableitems[key].TotalAmt);
            totalTest += parseFloat(tableitems[key].Qty);
            storedueamt += parseFloat(tableitems[key].TotalAmt);
            glblitemstore.push(
                {
                    ItemSno: tableitems[key].ItemSNo,
                    ItemCode: tableitems[key].Code,
                    Item: tableitems[key].Item,
                    MRP: tableitems[key].Rate,
                    Disc: tableitems[key].Disc,
                    Rate: tableitems[key].Rate,
                    DeliveryDate: "",
                    MachineSNo: "0",
                    AppointmentTime: "",
                    Prereqsite: "",
                    Urgent: "0",
                    IsPackage: "0",
                    SchemeId: "0",
                    Qty: tableitems[key].Qty.toString(),
                    SubTotal: tableitems[key].SubTotal
                }
            );
        }
        setTotalAmt(Math.round(gTotal).toFixed(2));
        grandTotalAmt = parseFloat(gTotal) + parseFloat(FrieghtTotalAmt);
        setGTotalAmt(Math.round(grandTotalAmt).toFixed(2));
        setTotalTest(totalTest);
        if (paidamt == "0" && discamt == "0" && credamt == "0") { setDueAmt(parseFloat(grandTotalAmt).toFixed(2)) }
        else if (paidamt == "0" && discamt == "0" && credamt != "0") { setDueAmt(parseFloat(grandTotalAmt - credamt).toFixed(2)); }
        else if (paidamt == "0" && discamt != "0" && credamt == "0") { setDueAmt(parseFloat(grandTotalAmt - discamt).toFixed(2)); }
        else if (paidamt != "0" && discamt == "0" && credamt == "0") { setDueAmt(parseFloat(grandTotalAmt - paidamt).toFixed(2)); }
        else if (paidamt != "0" && discamt != "0" && credamt == "0") { setDueAmt(parseFloat(grandTotalAmt - (paidamt + discamt)).toFixed(2)); }
        else if (paidamt != "0" && discamt == "0" && credamt != "0") { setDueAmt(parseFloat(grandTotalAmt - (paidamt + credamt)).toFixed(2)); }
        else if (paidamt == "0" && discamt != "0" && credamt != "0") { setDueAmt(parseFloat(grandTotalAmt - (discamt + credamt)).toFixed(2)); }
        else if (paidamt != "0" && discamt != "0" && credamt != "0") { setDueAmt(parseFloat(grandTotalAmt - (paidamt + (discamt + credamt))).toFixed(2)); }
        grandTotalAmt > 0 && grandTotalAmt === paidamt ? setHideAddPayButton(true) : setHideAddPayButton(false);
        setStoreItem(glblitemstore);
    }, [tableitemsstatus])

    useEffect(() => {
        glblValArr = glblValArr.filter(item => item.ItemSNo !== chkflag);
        setTableItems(glblValArr);
        setChangeFlag(false);
    }, [changeflag]);
    useEffect(() => {
        if (paidamt == "0" && discamt == "0" && credamt == "0") { setDueAmt(parseFloat(gtotalamt).toFixed(2)) }
        else if (paidamt == "0" && discamt == "0" && credamt != "0") { setDueAmt(parseFloat(gtotalamt - credamt).toFixed(2)); }
        else if (paidamt == "0" && discamt != "0" && credamt == "0") { setDueAmt(parseFloat(gtotalamt - discamt).toFixed(2)); }
        else if (paidamt != "0" && discamt == "0" && credamt == "0") { setDueAmt(parseFloat(gtotalamt - paidamt).toFixed(2)); }
        else if (paidamt != "0" && discamt != "0" && credamt == "0") { setDueAmt(parseFloat(gtotalamt - (paidamt + discamt)).toFixed(2)); }
        else if (paidamt != "0" && discamt == "0" && credamt != "0") { setDueAmt(parseFloat(gtotalamt - (paidamt + credamt)).toFixed(2)); }
        else if (paidamt == "0" && discamt != "0" && credamt != "0") { setDueAmt(parseFloat(gtotalamt - (discamt + credamt)).toFixed(2)); }
        else if (paidamt != "0" && discamt != "0" && credamt != "0") { setDueAmt(parseFloat(gtotalamt - (paidamt + (discamt + credamt))).toFixed(2)); }
        gtotalamt > 0 && gtotalamt === paidamt ? setHideAddPayButton(true) : setHideAddPayButton(false);
    }, [paidamt, discamt, credamt]);
    useEffect(() => {
        var gTotal = 0;
        var totalTest = 0;
        var storedueamt = 0;
        var grandTotalAmt = 0;
        if (tableitems == "") {
            glblitemstore = [];
            setStoreItem([]);
        }
        else {
            glblitemstore = [];
            for (var key in tableitems) {
                gTotal += parseFloat(tableitems[key].TotalAmt);
                totalTest += parseFloat(tableitems[key].Qty);
                storedueamt += parseFloat(tableitems[key].TotalAmt);
                glblitemstore.push(
                    {
                        ItemSno: tableitems[key].ItemSNo,
                        ItemCode: tableitems[key].Code,
                        Item: tableitems[key].Item,
                        MRP: tableitems[key].Rate,
                        Disc: tableitems[key].Disc,
                        Rate: tableitems[key].Rate,
                        DeliveryDate: "",
                        MachineSNo: "0",
                        AppointmentTime: "",
                        Prereqsite: "",
                        Urgent: "0",
                        IsPackage: "0",
                        SchemeId: "0",
                        Qty: tableitems[key].Qty.toString(),
                        SubTotal: tableitems[key].SubTotal
                    }
                );
            }
        }
        setTotalAmt(Math.round(gTotal).toFixed(2));
        grandTotalAmt = parseFloat(gTotal) + parseFloat(FrieghtTotalAmt);
        setGTotalAmt(Math.round(grandTotalAmt).toFixed(2));
        setTotalTest(totalTest);
        if (paidamt == "0" && discamt == "0" && credamt == "0") { setDueAmt(parseFloat(grandTotalAmt).toFixed(2)) }
        else if (paidamt == "0" && discamt == "0" && credamt != "0") { setDueAmt(parseFloat(grandTotalAmt - credamt).toFixed(2)); }
        else if (paidamt == "0" && discamt != "0" && credamt == "0") { setDueAmt(parseFloat(grandTotalAmt - discamt).toFixed(2)); }
        else if (paidamt != "0" && discamt == "0" && credamt == "0") { setDueAmt(parseFloat(grandTotalAmt - paidamt).toFixed(2)); }
        else if (paidamt != "0" && discamt != "0" && credamt == "0") { setDueAmt(parseFloat(grandTotalAmt - (paidamt + discamt)).toFixed(2)); }
        else if (paidamt != "0" && discamt == "0" && credamt != "0") { setDueAmt(parseFloat(grandTotalAmt - (paidamt + credamt)).toFixed(2)); }
        else if (paidamt == "0" && discamt != "0" && credamt != "0") { setDueAmt(parseFloat(grandTotalAmt - (discamt + credamt)).toFixed(2)); }
        else if (paidamt != "0" && discamt != "0" && credamt != "0") { setDueAmt(parseFloat(grandTotalAmt - (paidamt + (discamt + credamt))).toFixed(2)); }
        grandTotalAmt > 0 && grandTotalAmt === paidamt ? setHideAddPayButton(true) : setHideAddPayButton(false);
        storedueamt = grandTotalAmt;
        setStoreItem(glblitemstore);
    }, [FrieghtTotalAmt]);
    useEffect(() => {
        if (localStorage.getItem('rvpage') == null || localStorage.getItem('rvpage') == undefined || localStorage.getItem('rvpage') == "") { }
        else { window.location.reload(false); localStorage.removeItem('rvpage'); }
    }, [invoicepage])

    const GetPartnerCentre = async (CentreTypeID, PartnerTypeId) => {
        if (CentreTypeID != "" && CentreTypeID != "0" && PartnerTypeId != "" && PartnerTypeId != "0") {
            setRateType([]);
            try {
                let url = FrontDesk_URL + 'master/getpartenercentre_cyclrtonlist/' + CentreTypeID + '/' + PartnerTypeId
                await axios.get(url).then((response) => {
                    const data = response.data;
                    if (data.Success == true) {
                        let ds = data.Data;
                        if (ds != null && ds.length > 0) {
                            const TypeOptionDs = [];
                            ds.map((option) => {
                                const TypeOptions = { 'id': option.CentreID, 'name': option.CentreName }
                                return TypeOptionDs.push(TypeOptions);
                            });
                            setCentres(TypeOptionDs);
                        }
                        else { setCentres([]); }
                    }
                    else {
                        setCentres([]);
                        let Errtext = <p><div className='text-required'> no record found </div></p>;
                        SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                    }
                }).catch(function (error) { });
            }
            catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
        }
    }
    // const GetPartnerCentre = async (CentreTypeID, PartnerTypeId) => {
    //     if (CentreTypeID != "" && CentreTypeID != "0" && PartnerTypeId != "" && PartnerTypeId != "0") {
    //         setRateType([]);
    //         try {
    //             let url = FrontDesk_URL + 'master/getpartenercentre_cyclrtonlist/' + CentreTypeID + '/' + PartnerTypeId
    //             await axios.get(url).then((response) => {
    //                 const data = response.data;
    //                 if (data.Success) { setCentres(data.Data.map((d) => (<option key={d.SNo} value={d.CentreID}>{d.CentreName}</option>))); }
    //                 else {
    //                     setCentres([]);
    //                 }
    //             }).catch(function (error) { });
    //         }
    //         catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    //     }
    // }
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionQtyFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <NumericInput className="form-control" min={1} max={1000} mobile value={tableitems[rowIndex].Qty} onBlur={(e) => upQtyManage(e.target.value, rowData)} />
            </div>
        </React.Fragment>
    }
    const actionQtyExtraFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {tableitems[rowIndex].Qty}
            </div>
        </React.Fragment>
    }
    const actionRateFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <input type="text" id={`txtRate${rowData.ItemSNo}`} className="form-control" onClick={(e) => upRateManage(e.target.value, rowData)} value={tableitems[rowIndex].Rate} onBlur={(e) => upRateManage(e.target.value, rowData)} />
                {/* <input type="text" id={`txtRate${rowData.ItemSNo}`} className="form-control" value={tableitems[rowIndex].Rate} onBlur={abc} /> */}
            </div>
        </React.Fragment>
    }
    const actionRateExtraFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {tableitems[rowIndex].Rate}
            </div>
        </React.Fragment>
    }
    const handlePercentageCheckbox = (e) => {
        const checkedflg = e.target.checked;
        if (checkedflg) { setPercentagecheckbox("1"); } else { setPercentagecheckbox("0"); }
    }
    const upQtyManage = async (cellData, item) => {
        var isUpdate = 0;
        var storedueamt = 0;
        for (var key in tableitems) {
            if (tableitems[key].ItemSNo == item.ItemSNo) {
                isUpdate = 1;
                if (cellData < 0) {
                    tableitems[key].Qty = 1;
                    SweetAlert.fire('You can not add quantity less than 1');
                    return false;
                }
                else {
                    tableitems[key].Qty = cellData;
                }
                if (tableitems[key].Rate > 0 && tableitems[key].TaxRate > 0) {
                    if (tableitems[key].SameState == "0") {
                        tableitems[key].IGSTAmt = ((parseFloat(tableitems[key].Qty) * parseFloat(tableitems[key].Rate * tableitems[key].TaxRate / 100))).toFixed(2);
                        tableitems[key].SGSTAmt = 0.00.toFixed(2);
                        tableitems[key].CGSTAmt = 0.00.toFixed(2);
                    }
                    else {
                        tableitems[key].IGSTAmt = 0.00.toFixed(2);
                        tableitems[key].SGSTAmt = ((parseFloat(tableitems[key].Qty) * parseFloat(tableitems[key].Rate * tableitems[key].TaxRate / 100)) / 2).toFixed(2);
                        tableitems[key].CGSTAmt = ((parseFloat(tableitems[key].Qty) * parseFloat(tableitems[key].Rate * tableitems[key].TaxRate / 100)) / 2).toFixed(2);
                    }
                }
                else {
                    tableitems[key].IGSTAmt = 0.00.toFixed(2);
                    tableitems[key].SGSTAmt = 0.00.toFixed(2);
                    tableitems[key].CGSTAmt = 0.00.toFixed(2);
                }
                tableitems[key].SubTotal = (parseFloat(tableitems[key].Qty) * parseFloat(tableitems[key].Rate)).toFixed(2);
                tableitems[key].TotalAmt = Math.round((parseFloat(tableitems[key].Qty) * parseFloat(tableitems[key].Rate)) +
                    parseFloat(tableitems[key].IGSTAmt) +
                    parseFloat(tableitems[key].SGSTAmt) +
                    parseFloat(tableitems[key].CGSTAmt)).toFixed(2);
                if (isUpdate == 1)
                    setTableItems([...tableitems]);
            }
            storedueamt += Math.round(parseFloat(tableitems[key].TotalAmt));
        }
        setDueAmt(parseFloat(storedueamt).toFixed(2));
    }
    const upRateManage = async (cellData, item) => {
        var isUpdate = 0;
        var storedueamt = 0;
        for (var key in tableitems) {
            if (tableitems[key].ItemSNo == item.ItemSNo) {
                isUpdate = 1;
                if (cellData < 0) {
                    tableitems[key].Rate = 1;
                    SweetAlert.fire('You can not add rate less than 1');
                    return false;
                }
                else {
                    tableitems[key].Rate = cellData;
                }
                if (tableitems[key].Rate > 0 && tableitems[key].TaxRate > 0) {
                    if (tableitems[key].SameState == "0") {
                        tableitems[key].IGSTAmt = ((parseFloat(tableitems[key].Qty) * parseFloat(tableitems[key].Rate * tableitems[key].TaxRate / 100))).toFixed(2);
                        tableitems[key].SGSTAmt = 0.00.toFixed(2);
                        tableitems[key].CGSTAmt = 0.00.toFixed(2);
                    }
                    else {
                        tableitems[key].IGSTAmt = 0.00.toFixed(2);
                        tableitems[key].SGSTAmt = ((parseFloat(tableitems[key].Qty) * parseFloat(tableitems[key].Rate * tableitems[key].TaxRate / 100)) / 2).toFixed(2);
                        tableitems[key].CGSTAmt = ((parseFloat(tableitems[key].Qty) * parseFloat(tableitems[key].Rate * tableitems[key].TaxRate / 100)) / 2).toFixed(2);
                    }
                }
                else {
                    tableitems[key].IGSTAmt = 0.00.toFixed(2);
                    tableitems[key].SGSTAmt = 0.00.toFixed(2);
                    tableitems[key].CGSTAmt = 0.00.toFixed(2);
                }
                tableitems[key].SubTotal = (parseFloat(tableitems[key].Qty) * parseFloat(tableitems[key].Rate)).toFixed(2);
                tableitems[key].TotalAmt = Math.round((parseFloat(tableitems[key].Qty) * parseFloat(tableitems[key].Rate)) +
                    parseFloat(tableitems[key].IGSTAmt) +
                    parseFloat(tableitems[key].SGSTAmt) +
                    parseFloat(tableitems[key].CGSTAmt)).toFixed(2);
                if (isUpdate == 1)
                    setTableItems([...tableitems]);
            }
            storedueamt += Math.round(parseFloat(tableitems[key].TotalAmt));
        }
        setDueAmt(parseFloat(storedueamt).toFixed(2));
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    <DeleteToolTip title="Delete Item" placement="top" id={`Delete${rowIndex}`} onClick={() => DisplayDeleteTest(rowData)} />
                }
            </div>
        </React.Fragment>
    }
    const columns =
        invoiceType == "2" ?
            [
                { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '3%' }; } },
                { dataField: 'Code', text: 'Code', editable: false, headerStyle: (colum, colIndex) => { return { width: '10%' }; } },
                { dataField: 'Item', text: 'Item', editable: false, headerStyle: (colum, colIndex) => { return { width: '25%' }; } },
                { dataField: 'HSNCode', text: 'HSN', editable: false, headerStyle: (colum, colIndex) => { return { width: '10%' }; } },
                { dataField: 'Rate', text: 'Rate', editable: true, formatter: actionRateFormatter, headerStyle: (colum, colIndex) => { return { width: '10%' }; } },
                { dataField: 'Qty', text: 'Qty', editable: false, formatter: actionQtyFormatter, formatExtraData: actionQtyExtraFormatter, headerStyle: (colum, colIndex) => { return { width: '10%', textAlign: 'center' }; } },//, attrs: function callback(cell, row, rowIndex, colIndex) { upQtyManage(cell, row.ItemSNo) } 
                { dataField: 'SubTotal', text: 'Sub Total', editable: false, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
                { dataField: 'TaxRate', text: 'Tax Rate', editable: false, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
                { dataField: 'IGSTAmt', text: 'IGST', editable: false, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
                { dataField: 'SGSTAmt', text: 'SGST', editable: false, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
                { dataField: 'CGSTAmt', text: 'CGST', editable: false, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
                { dataField: 'TotalAmt', text: 'Total', editable: false, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
                { dataField: 'actions', text: 'Actions', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
            ]
            :
            [
                { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '3%' }; } },
                { dataField: 'Code', text: 'Code', editable: false, headerStyle: (colum, colIndex) => { return { width: '10%' }; } },
                { dataField: 'Item', text: 'Item', editable: false, headerStyle: (colum, colIndex) => { return { width: '25%' }; } },
                { dataField: 'HSNCode', text: 'HSN', editable: false, headerStyle: (colum, colIndex) => { return { width: '10%' }; } },
                { dataField: 'Rate', text: 'Rate', editable: false, headerStyle: (colum, colIndex) => { return { width: '10%' }; } },
                { dataField: 'Qty', text: 'Qty', editable: false, formatter: actionQtyFormatter, formatExtraData: actionQtyExtraFormatter, headerStyle: (colum, colIndex) => { return { width: '10%', textAlign: 'center' }; } },//, attrs: function callback(cell, row, rowIndex, colIndex) { upQtyManage(cell, row.ItemSNo) } 
                { dataField: 'SubTotal', text: 'Sub Total', editable: false, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
                { dataField: 'TaxRate', text: 'Tax Rate', editable: false, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
                { dataField: 'IGSTAmt', text: 'IGST', editable: false, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
                { dataField: 'SGSTAmt', text: 'SGST', editable: false, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
                { dataField: 'CGSTAmt', text: 'CGST', editable: false, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
                { dataField: 'TotalAmt', text: 'Total', editable: false, headerStyle: (colum, colIndex) => { return { width: '7%' }; } },
                { dataField: 'actions', text: 'Actions', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
            ]
    const DisplayDeleteTest = (rowData) => {
        SweetAlert.fire({
            title: 'You are going to delete an item. Are you sure?',
            text: "Once deleted, you will not be able to recover!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        }).then((result) => { if (result.value) { DeleteTest(rowData); } else { SweetAlert.fire('Your data is safe!') } })

    }
    const DeleteTest = async (rawData) => {
        setChangeFlag(true);
        chkflag = rawData.ItemSNo;
        SweetAlert.fire('Deleted!', 'Data has been deleted.', 'success')
    }
    const GetPaymentMode = async (paymntType) => {
        try {
            glblPaymentModesList = [];
            var tmpPaymentModes = [];
            let url = FrontDesk_URL + 'master/GetPaymentModesForCyclorton'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    var allPaymentModes = data.Data;
                    for (var i in allPaymentModes) {
                        setPaymentModeId("2");
                        if (allPaymentModes[i].SNo === "2") {
                            tmpPaymentModes.push({ "PaymentMode": allPaymentModes[i].PaymentMode, "SNo": allPaymentModes[i].SNo });
                        }
                    }
                    glblPaymentModesList = tmpPaymentModes;
                    var string = [];
                    for (var i in glblPaymentModesList) {
                        string.push(<option key={glblPaymentModesList[i].SNo} value={glblPaymentModesList[i].SNo}>{glblPaymentModesList[i].PaymentMode}</option>);
                    }
                    setPaymentMode([]);
                    setPaymentMode(string);
                }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const SelectChannel = async (e) => {
        setChannelId(e.target.value);
    }
    const ChangePaymentMode = (value) => {
        setPaymentModeId(value);
        if (value === "14" || value === "15") { }
        else { setPercentagecheckbox("0"); }
        if (value === "0") { paymentamountdetail.current.value = "" }
        else { paymentamountdetail.current.value = dueamt; }
    }
    const getitemlist = async (e, text) => {
        var Centrecode = 0;
        if (CenterTypeId == 3) {
            Centrecode = localStorage.CentreSNo;
        }
        else {
            Centrecode = cid;//centreid.current.value;
        }
        if (rratetype.current.value == "0" || rratetype.current.value == "") {
            testreference.current.clear();
            SweetAlert.fire('Please select rate plan.')
            return false;
        }
        const requestOptions = {
            CentreSNo: Centrecode,
            RateTypeSNo: rratetype.current.value,
            Item: e.target.value
        };
        let url = FrontDesk_URL + 'master/getCorporateInvoiceItemList'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data.Data;
            if (data === null) { } else { setOptions(data); }
        }).catch(function (error) { });
    }
    const BindGrid = async (itmsno) => {
        if (itmsno == "") { SweetAlert.fire('Please add test'); return false }
        testreference.current.clear();
        setPaymentTable([]);
        CallBindGrid(itmsno);
        GetPaymentMode(partnertypeid.current.value);
        setPaidAmt(0);
        setDiscAmt(0);
        setCredAmt(0);
    }
    const SetAuthorizerDetails = (e) => {
        if (paymentModeId === "14") {
            for (var key in glblDiscountAuthorizer) {
                if (glblDiscountAuthorizer[key].Id === e.target.value) {
                    setDiscountAuthorizerDetails({
                        "DiscountAuthorizerName": glblDiscountAuthorizer[key].Name.split("[")[0],
                        "DiscountAuthorizerEmail": glblDiscountAuthorizer[key].EmailID
                    });
                    return false;
                }
            }
        }
        if (paymentModeId === "15") {
            for (var key in glblCreditAuthorizer) {
                if (glblCreditAuthorizer[key].Id === e.target.value) {
                    setCreditAuthorizerDetails({
                        "CreditAuthorizerName": glblCreditAuthorizer[key].Name.split("[")[0],
                        "CreditAuthorizerEmail": glblCreditAuthorizer[key].EmailID
                    });
                    return false;
                }
            }
        }
    }
    const actionpaymentFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.OrderDetailsId === "0" && rowData.PaymentModeId === "13" ? null :
                        <DeleteToolTip title="Delete" placement="top" id={`DeletePayment${rowData.PaymentModeId}`} onClick={() => RemovePayment(rowData)} />
                }
            </div>
        </React.Fragment>
    }
    const paymentColumns = [
        { dataField: 'PaymentMode', text: 'Payment Mode', editable: false },
        { dataField: 'PaidAmount', text: 'Paid Amount', editable: false },
        { dataField: 'ReferenceNo', text: 'Reference No.', editable: false },
        { dataField: 'Authorizer', text: 'Authorizer', editable: false },
        { dataField: 'ConcessionReason', text: 'Concession Reason', editable: false },
        { dataField: '', text: 'Remove', editable: false, formatter: actionpaymentFormatter }
    ]
    const RemovePayment = async (rowData) => {
        SweetAlert.fire({ title: 'Are you sure?', text: "Once deleted, you will not be able to recover!", icon: 'warning', showCancelButton: true, confirmButtonText: 'Ok', cancelButtonText: 'Cancel', reverseButtons: true })
            .then((result) => {
                if (result.value) {
                    setPaymentTable([]);
                    GetPaymentMode(partnertypeid.current.value);
                    glblPaymentsList = glblPaymentsList.filter(function (x) { return x.Id !== rowData.Id; })
                    var totalPaidSoFar = 0;
                    var totalDiscSoFar = 0;
                    var totalCredSoFar = 0;
                    setPaidAmt(0);
                    setDiscAmt(0);
                    setCredAmt(0);
                    for (var x in glblPaymentsList) {
                        if (glblPaymentsList[x].PaymentModeId === "1" || glblPaymentsList[x].PaymentModeId === "13") {
                            totalPaidSoFar = totalPaidSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
                        }
                        if (glblPaymentsList[x].PaymentModeId === "14") {
                            totalDiscSoFar = totalDiscSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
                        }
                        if (glblPaymentsList[x].PaymentModeId === "15" || glblPaymentsList[x].PaymentModeId === "2") {
                            totalCredSoFar = totalCredSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
                        }
                    }
                    setPaidAmt(totalPaidSoFar);
                    setDiscAmt(totalDiscSoFar);
                    setCredAmt(totalCredSoFar);
                    setPaymentTable(glblPaymentsList);
                    var _visitId = "0";
                    if (visitingid == "" || visitingid == undefined || visitingid == null || visitingid == "0") { _visitId = "0"; } else { _visitId = visitingid; }
                    if (_visitId === "0") {
                        var tmpArray = [];
                        for (var i in glblPaymentModesList) {
                            var payModeExist = false;
                            for (var j in glblPaymentsList) { if (glblPaymentsList[j].PaymentModeId == glblPaymentModesList[i].SNo) { payModeExist = true; break; } }
                            if (payModeExist == false) { tmpArray.push(<option value={glblPaymentModesList[i].SNo}>{glblPaymentModesList[i].PaymentMode}</option>); }
                        }
                        setPaymentMode([]);
                        setPaymentMode(tmpArray);
                    }
                    paymentmodedetail.current.value = "0";
                    ChangePaymentMode("0");
                }
                else { SweetAlert.fire('Your data is safe!') }
            });
    }
    const BindPaymentGrid = async () => {
        if (tableitems == "") { SweetAlert.fire('Please add some test'); return false }
        if (paymentmodedetail.current.value == "0") { SweetAlert.fire('Please select payment mode'); return false }
        if (paymentamountdetail.current.value == "") { SweetAlert.fire('Please add payment amount'); return false }
        if (paymentmodedetail.current.value !== "23" && parseFloat(paymentamountdetail.current.value) <= 0 && dueamt >= 0) { SweetAlert.fire('Please add payment amount more than ZERO'); return false }
        if (paymentmodedetail.current.value == "13" && paymentChannelId.current.value === "0") { SweetAlert.fire('Please select payment channel'); return false }
        if (paymentmodedetail.current.value == "13" && channelid !== "-1" && paymentReferenceNo.current.value === "") { SweetAlert.fire('Please add reference no'); return false }
        if (paymentmodedetail.current.value == "13" && channelid === "-1" && posMachineId.current.value === "0") { SweetAlert.fire('Please select POS machine'); return false }
        if ((paymentmodedetail.current.value !== "1" && paymentmodedetail.current.value !== "13") && localStorage.MinBookingCash !== "") {
            if (parseFloat(localStorage.MinBookingCash) > 0) {
                var tmpValue = parseFloat(gtotalamt) * (parseFloat(localStorage.MinBookingCash) / 100);
                var alreadyAddedAmount = 0;
                var cardpaidexist = false;
                for (var x in glblPaymentsList) {
                    if (glblPaymentsList[x].PaymentModeId == "1" || glblPaymentsList[x].PaymentModeId == "13") {
                        cardpaidexist = true; alreadyAddedAmount += parseFloat(glblPaymentsList[x].PaidAmount)
                    }
                }
            }
        }
        if (paymentmodedetail.current.value == "14" && paymentModeIdSetting === "2") {
            var orderPercent = parseFloat(centreDiscountLimit);
            var maxOrderAmount = parseFloat(gtotalamt) * (parseFloat(orderPercent) / 100);
            var curntAmount = paymentamountdetail.current.value;
            if (parseFloat(curntAmount) > parseFloat(maxOrderAmount)) { SweetAlert.fire('You can give ' + parseFloat(orderPercent).toFixed(2) + '% discount of total order value.'); return false }
        }
        for (var x in glblPaymentsList) {
            if (paymentmodedetail.current.value === glblPaymentsList[x].PaymentModeId && glblPaymentsList[x].OrderDetailsId == "0") { SweetAlert.fire('Payment mode already exist, please remove selected mode or change it.'); return false }
        }
        if (Percentagecheckbox === "0") { if (parseFloat(dueamt) < parseFloat(paymentamountdetail.current.value)) { SweetAlert.fire('Amount cannot be greater than total amount'); return false } }
        var payRefNo = ""; if (paymentReferenceNo.current !== null && paymentReferenceNo.current !== undefined) { payRefNo = paymentReferenceNo.current.value; }
        var _authorizerId = ""; if (authorizerId.current !== null && authorizerId.current !== undefined) { _authorizerId = authorizerId.current.value; }
        var _authorizer = ""; if (authorizerId.current !== null && authorizerId.current !== undefined) { _authorizer = authorizerId.current.options[authorizerId.current.selectedIndex].text; }
        var _concessionReason = ""; if (concessionReason.current !== null && concessionReason.current !== undefined) { _concessionReason = concessionReason.current.value; }
        var pChkbx = "";
        if (Percentagecheckbox === "1") { pChkbx = parseFloat(parseFloat(gtotalamt) * (parseFloat(paymentamountdetail.current.value) / 100)).toFixed(2); }
        else { pChkbx = paymentamountdetail.current.value; }
        let intCountr = 0;
        for (var x in glblPaymentsList) { intCountr = parseInt(glblPaymentsList[x].Id) + 1; }
        var txtPayMode = paymentmodedetail.current.options[paymentmodedetail.current.selectedIndex].text;
        var pymntChnlId = "0";
        if (paymentChannelId.current != undefined) { pymntChnlId = paymentChannelId.current.value; }
        if (paymentmodedetail.current.value === "13" && pymntChnlId !== "0") { txtPayMode = txtPayMode + "(" + paymentChannelId.current.options[paymentChannelId.current.selectedIndex].text + ")" }
        if (paymentmodedetail.current.value === "13" && channelid === "-1" && posMachineId.current.value !== "0") {
            var POSMachineName = posMachineId.current.options[posMachineId.current.selectedIndex].text;
        }
        var posmchnId = "0";
        if (posMachineId.current != undefined) { posmchnId = posMachineId.current.value; }
        else {
            glblPaymentsList.push(
                {
                    Id: intCountr, OrderDetailsId: "0", PaymentMode: txtPayMode, PaymentModeId: paymentmodedetail.current.value,
                    PaymentChannelId: pymntChnlId, PaidAmount: pChkbx.toString(), ReferenceNo: payRefNo, AuthorizerId: _authorizerId,
                    Authorizer: _authorizer, ConcessionReason: _concessionReason, Status: "0", DiscountRejectReason: "", POSMachineName: POSMachineName, POSMachineId: posmchnId
                }
            );
            var totalPaidSoFar = 0;
            var totalDiscSoFar = 0;
            var totalCredSoFar = 0;
            setPaidAmt(0);
            setDiscAmt(0);
            setCredAmt(0);
            for (var x in glblPaymentsList) {
                if (glblPaymentsList[x].PaymentModeId === "1" || glblPaymentsList[x].PaymentModeId === "13") {
                    totalPaidSoFar = totalPaidSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
                }
                if (glblPaymentsList[x].PaymentModeId === "14") {
                    totalDiscSoFar = totalDiscSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
                }
                if (glblPaymentsList[x].PaymentModeId === "15" || glblPaymentsList[x].PaymentModeId === "2") {
                    totalCredSoFar = totalCredSoFar + parseFloat(glblPaymentsList[x].PaidAmount);
                }
            }
            setPaidAmt(totalPaidSoFar);
            setDiscAmt(totalDiscSoFar);
            setCredAmt(totalCredSoFar);
            var _visitId = "0";
            if (visitingid == "" || visitingid == undefined || visitingid == null || visitingid == "0") { _visitId = "0"; } else { _visitId = visitingid; }
            if (_visitId === "0") {
                var tmpArray = [];
                for (var i in glblPaymentModesList) {
                    var payModeExist = false;
                    for (var j in glblPaymentsList) { if (glblPaymentsList[j].PaymentModeId == glblPaymentModesList[i].SNo) { payModeExist = true; break; } }
                    if (payModeExist == false) {
                        tmpArray.push(<option value={glblPaymentModesList[i].SNo}>{glblPaymentModesList[i].PaymentMode}</option>);
                    }
                }
                setPaymentMode([]);
                setPaymentMode(tmpArray);
            }
            setPaymentTable(glblPaymentsList);
            paymentamountdetail.current.value = "";
            if (paymentReferenceNo.current !== undefined && paymentReferenceNo.current !== null) { paymentReferenceNo.current.value = ""; }
            paymentmodedetail.current.value = "0";
            ChangePaymentMode("0");
        }
    }
    const CallBindGrid = async (itmsno) => {
        var Centrecode = 0;
        if (CenterTypeId == 3) {
            Centrecode = cid;//centreid.current.value;
        }
        else {
            Centrecode = cid;//centreid.current.value;
        }
        setIsLoading(true);
        var isExist = 0;
        const requestOptions = { ItemTemplateSNo: rratetype.current.value, ItemSNo: itmsno, CentreSNo: Centrecode };
        let url = FrontDesk_URL + 'master/AddCorporateTestItems'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                if (data.Data.corporateTestDetails != null) {
                    if (data.Data.AddedStatus === "-3000") { SweetAlert.fire('Item is already exists.') }
                    else if (data.Data.AddedStatus == "-3001") { SweetAlert.fire('Item is already exists.') }
                    else if (data.Data.corporateTestDetails[0].HSNCode == "" || data.Data.corporateTestDetails[0].HSNCode == null) { SweetAlert.fire('Item without HSNCode can not be added.') }
                    else {
                        setavailableamt(data.Data.corporateTestDetails[0].AvailableBalance);
                        setcreditLimit(data.Data.corporateTestDetails[0].CreditBookingLimt);
                        setFrieghtTaxPer(parseFloat(data.Data.corporateTestDetails[0].TaxRate));
                        for (var key in tableitems) {
                            if (tableitems[key].ItemSNo == itmsno) {
                                isExist = 1;
                                tableitems[key].Qty = parseInt(tableitems[key].Qty) + 1;
                                if (tableitems[key].Rate > 0 && tableitems[key].TaxRate > 0) {
                                    if (data.Data.corporateTestDetails[0].SameState == "0") {
                                        tableitems[key].IGSTAmt = ((parseFloat(tableitems[key].Qty) * parseFloat(tableitems[key].Rate * tableitems[key].TaxRate / 100))).toFixed(2);
                                        tableitems[key].SGSTAmt = 0.00.toFixed(2);
                                        tableitems[key].CGSTAmt = 0.00.toFixed(2);
                                    }
                                    else {
                                        tableitems[key].IGSTAmt = 0.00.toFixed(2);
                                        tableitems[key].SGSTAmt = ((parseFloat(tableitems[key].Qty) * parseFloat(tableitems[key].Rate * tableitems[key].TaxRate / 100)) / 2).toFixed(2);
                                        tableitems[key].CGSTAmt = ((parseFloat(tableitems[key].Qty) * parseFloat(tableitems[key].Rate * tableitems[key].TaxRate / 100)) / 2).toFixed(2);
                                    }
                                }
                                else {
                                    tableitems[key].IGSTAmt = 0.00.toFixed(2);
                                    tableitems[key].SGSTAmt = 0.00.toFixed(2);
                                    tableitems[key].CGSTAmt = 0.00.toFixed(2);
                                }
                                tableitems[key].SubTotal = (parseFloat(tableitems[key].Qty) * parseFloat(tableitems[key].Rate)).toFixed(2);
                                tableitems[key].TotalAmt = Math.round((parseFloat(tableitems[key].Qty) * parseFloat(tableitems[key].Rate)) +
                                    parseFloat(tableitems[key].IGSTAmt) +
                                    parseFloat(tableitems[key].SGSTAmt) +
                                    parseFloat(tableitems[key].CGSTAmt)).toFixed(2);

                                setTableItems([...tableitems]);
                            }
                        }
                        if (isExist === 0) {
                            glblValArr.push(data.Data.corporateTestDetails[0]);
                            if (tableitemsstatus === false) {
                                setTableItemsStatus(true);
                            }
                            else {
                                setTableItemsStatus(false);
                            }
                        }
                    }
                }
                else { SweetAlert.fire('No item exists.') }
            }
            else { SweetAlert.fire({ title: "API Error!", text: "Some error occured! Please try after sometime.", icon: "error" }); }
        }).catch(function (error) { setIsLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    const confirmBookingHandler = async (values, iszeroallowed) => {
        if (iszeroallowed == "0") {
            if (parseFloat(totalamt) == 0.00) {
                SweetAlert.fire('Please add test');
                return false;
            }
            if (showAvailableBalance == 1) {
                var totolcreditAmt = parseFloat(availableamt) + parseFloat(creditLimit);
                if (parseFloat(gtotalamt) > parseFloat(totolcreditAmt)) {
                    SweetAlert.fire('Please add credit balance before order.');
                    return false;
                }
            }
            if (parseFloat(dueamt) > 1) {
                SweetAlert.fire('Please add remaining due amount.');
                return false;
            }

            if (invoiceType == "1" && (TransporterID == undefined || TransporterID == "")) {
                SweetAlert.fire('Transporter ID is missing');
                return false;
            }
        }
        if (invoiceType == "1" && (rfVehicleNo == undefined || rfVehicleNo.current.value == "" || rfVehicleNo.current.value == "")) {
            SweetAlert.fire('Please enter Vehicle number');
            return false;
        }
        else {
            SweetAlert.fire({
                title: 'Are you sure?',
                text: "You are going to preview invoice",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                reverseButtons: true,
                cancelButtonText: 'Cancel',
                cancelButtonColor: "#000",
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        BookAppointment(values, iszeroallowed);
                    }
                    else { SweetAlert.fire('Payment still pending') }
                })
        }
    }
    const BookAppointment = async (values, iszeroallowed) => {
        if (rratetype.current.value == "" || rratetype.current.value == "0" || rratetype.current.value == false) {
            SweetAlert.fire("This appointment was on hold, so either choose a rate plan for new appointment or visit same centre for hold appointment.");
            return false;
        }
        var opdID = "";
        var hospitalUHID = "";
        var visitid = "0";
        var tranpoterid = invoiceType == "1" ? TransporterID : "";
        var vehicleid = invoiceType == "1" ? rfVehicleNo.current.value : "";
        if (visitingid == "" || visitingid == undefined || visitingid == null || visitingid == "0") { visitid = "0"; } else { visitid = visitingid; }
        setIsButtonLoading(true);
        var discountauthname = "";
        var discountauthemail = "";
        var creditauthname = "";
        var creditauthemail = "";
        for (var key in paymenttable) {
            if (paymenttable[key].PaymentModeId === "14") {
                discountauthname = discountauthorizerdetails.DiscountAuthorizerName === undefined ? "" : discountauthorizerdetails.DiscountAuthorizerName
                discountauthemail = discountauthorizerdetails.DiscountAuthorizerEmail === undefined ? "" : discountauthorizerdetails.DiscountAuthorizerEmail
            }
            if (paymenttable[key].PaymentModeId === "15") {
                creditauthname = creditauthorizerdetails.CreditAuthorizerName === undefined ? "" : creditauthorizerdetails.CreditAuthorizerName
                creditauthemail = creditauthorizerdetails.CreditAuthorizerEmail === undefined ? "" : creditauthorizerdetails.CreditAuthorizerEmail
            }
        }
        var selectPatientTypeSNo = "0";
        var selectedCentreSNo = cid;//centreid.current.value;
        if (partnertypeid.current.value == "8" || partnertypeid.current.value == "9") {
            selectPatientTypeSNo = cid;//centreid.current.value;
            selectedCentreSNo = localStorage.CentreSNo;
        }
        if (iszeroallowed == "1") {
            var glblValArr = [];
            var glblValPayArr = [];
            for (var key in storeitem) {
                glblValArr.push({
                    AppointmentTime: storeitem[key].AppointmentTime,
                    DeliveryDate: storeitem[key].DeliveryDate,
                    Disc: "0",
                    IsPackage: storeitem[key].IsPackage,
                    Item: storeitem[key].Item,
                    ItemCode: storeitem[key].ItemCode,
                    ItemSno: storeitem[key].ItemSno,
                    MRP: "0",
                    MachineSNo: storeitem[key].MachineSNo,
                    Prereqsite: storeitem[key].Prereqsite,
                    Qty: storeitem[key].Qty,
                    Rate: "0",
                    SchemeId: storeitem[key].SchemeId,
                    SubTotal: "0",
                    Urgent: storeitem[key].Urgent
                })
            }
            for (var key in paymenttable) {
                glblValPayArr.push({
                    Authorizer: paymenttable[key].Authorizer,
                    AuthorizerId: paymenttable[key].AuthorizerId,
                    ConcessionReason: paymenttable[key].ConcessionReason,
                    DiscountRejectReason: paymenttable[key].DiscountRejectReason,
                    Id: paymenttable[key].Id,
                    OrderDetailsId: paymenttable[key].OrderDetailsId,
                    POSMachineId: paymenttable[key].POSMachineId,
                    POSMachineName: paymenttable[key].POSMachineName,
                    PaidAmount: "0",
                    PaymentChannelId: paymenttable[key].PaymentChannelId,
                    PaymentMode: paymenttable[key].PaymentMode,
                    PaymentModeId: paymenttable[key].PaymentModeId,
                    ReferenceNo: paymenttable[key].ReferenceNo,
                    Status: paymenttable[key].Status
                })
            }
        }
        const requestOptions = {
            VisitingId: visitid.toString(),
            PatientName: "Nuclear Medicine",
            PatientTypeSNo: partnertypeid.current.value,
            RateType: rratetype.current.value,
            ItemDetailsList: iszeroallowed == "1" ? JSON.stringify(glblValArr) : JSON.stringify(storeitem),
            PaymentDetailsList: iszeroallowed == "1" ? JSON.stringify(glblValPayArr) : JSON.stringify(paymenttable),
            TotalAmount: iszeroallowed == "1" ? "0" : gtotalamt.toString(),
            PaidAmount: iszeroallowed == "1" ? "0" : paidamt.toString(),
            DueAmount: iszeroallowed == "1" ? "0" : dueamt.toString(),
            selectPatientTypeSNo: selectPatientTypeSNo,
            OPDID: opdID,
            HospitalUHID: hospitalUHID,
            CentreSNo: selectedCentreSNo,
            CreatedBy: localStorage.LoggedInUser,
            VisitType: "CentreVisit",
            SAPStateId: "0",
            SAPRegionId: "0",
            SAPDistrictId: "0",
            RegNo: "0",
            IsHold: "0",
            OtherLabRefNo: "0",
            FrieghtTotalAmount: "0",
            InvoiceType: invoiceType,
            CreditNote: values.creditnoteremarks,
            ShippingPincode: values.shippingPincode,
            ShippingStateId: values.shippingStateId,
            ShippingRegionId: values.shippingRegionId,
            ShippingDistrictId: values.shippingDistrictId,
            ShippingAddress: values.shippingAddress,
            ShippingGSTIN: values.shippingGSTIN,
            TransporterID: tranpoterid,
            VehicleNo: vehicleid
        };
        let url = FrontDesk_URL + 'master/CyclotronCreateInvoice'
        await axios.post(url, requestOptions).then((response) => {
            setIsButtonLoading(false);
            const data = response.data;
            if (data.Success) {
                SweetAlert.fire({
                    title: 'Preview Invoice!', text: "Data saved successfully.", icon: 'success', showCancelButton: false, confirmButtonText: 'Ok', reverseButtons: true
                }).then((result) => {
                    const stateparams = { VisitingId: data.Data.VisitingId, BuyerCentreId: cid, CreatedBy: localStorage.LoggedInUser };
                    //const stateparams = { VisitingId: data.Data.VisitingId, BuyerCentreId: centreid.current.value, CreatedBy: localStorage.LoggedInUser };
                    localStorage.removeItem('CyclotronData');
                    localStorage.setItem('CyclotronData', JSON.stringify(stateparams));
                    setInvoicePage(true);
                    // window.location.href=`${process.env.PUBLIC_URL}/BulkInvoice/View-Invoice`;
                })
            }
            else {
                if (data.ErrorList[0].errorCode == -2000 || data.Data == -2000) { SweetAlert.fire({ title: "API Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
            }
        }).catch(function (error) {
            setIsButtonLoading(false);
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const GetPartnerTypes = async (ctId) => {
        try {
            let url = FrontDesk_URL + 'master/getpartnertypesAdmin/' + ctId;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    glbCreditCentreTypes = data.Data;
                    setZeroInvoiceActive(data.Data[0].IsZeroInvoiceActive);
                }
            }).catch(function (error) { setZeroInvoiceActive("false"); setPartnerTypeList([]); glbCreditCentreTypes = []; });
        }
        catch (error) { setPartnerTypeList([]); glbCreditCentreTypes = []; SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
        setPartnerTypeList(glbCreditCentreTypes.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>)));
    }
    const handleRadioChange = async (e) => {
        setPartnerTypeList([])
        centreid.current.clear()
        setCentres([]);
        setRateType([]);
        glblValArr = [];
        setTableItems([]);
        setPaymentTable([]);
        setOptions([]);
        GetPartnerTypes(e.target.value);
        setCenterTypeId(e.target.value);
        if (e.target.value == "2") {
            setShowAvailableBalance(1);
        }
        else {
            setShowAvailableBalance(0);
        }
    }
    const handlePartnerTypeChange = async (e) => {
        centreid.current.clear()
        setCentres([]);
        setbuyercentreid(0);
        setRateType([]);
        glblValArr = [];
        setTableItems([]);
        setPaymentTable([]);
        setOptions([]);
        GetPartnerCentre(CenterTypeId, e.target.value);
        GetPaymentMode(e.target.value);
        setDueAmt(parseFloat(gtotalamt).toFixed(2))
    }
    const GetRateTypeByPatientType = async (option) => {
        setTableItems([]);
        setPaymentTable([]);
        setOptions([]);
        var Centrecode = 0;
        const idval = (option.length > 0) ? option[0].id : '';
        formik.setFieldValue('centreName', idval);
        const TypeOpt = [];
        option.map((item) => {
            const TypeOptrows = { 'CentreID': item.id }
            return TypeOpt.push(TypeOptrows);
        });
        Centrecode = idval;

        setCID(Centrecode);
        glblValArr = [];
        if (Centrecode != null && Centrecode != "" && Centrecode != "0") {
            if (partnertypeid.current.value == undefined || partnertypeid.current.value == "0") {
                setRateType([]);
            }
            else {
                if (CenterTypeId == 3) {
                    Centrecode = localStorage.CentreSNo;
                }
                else {
                    // Centrecode = centreid.current.value;            

                }
                setbuyercentreid(Centrecode);
                GetBuyerLocationDetails(Centrecode);
                GetRateList(Centrecode);
            }
        }
    }

    const GetRateList = async (Centrecode) => {
        if (Centrecode != null && Centrecode != "" && Centrecode != "0") {
            try {
                let url = FrontDesk_URL + 'master/RateTypeListByPatientType/' + partnertypeid.current.value + '/' + Centrecode + '/0/0/0';
                await axios.get(url).then((response) => {
                    const data = response.data;
                    if (data.Success) {
                        setRateType(data.Data.map((d) => (<option key={d.SNo} value={d.SNo}>{d.TemplateName}</option>)));
                        glblDefaultRec = data.Data;
                    }
                    else { setRateType("0") }
                }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
            }
            catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
        }
    }

    // const GetRateTypeByPatientType = async () => {
    //     glblValArr = [];
    //     setTableItems([]);
    //     setPaymentTable([]);
    //     setOptions([]);
    //     var Centrecode = 0;
    //     if (partnertypeid.current.value == undefined || partnertypeid.current.value == "0") {
    //         setRateType([]);
    //     }
    //     else {
    //         if (CenterTypeId == "3") {
    //             Centrecode = localStorage.CentreSNo;
    //         }
    //         else {
    //             Centrecode = centreid.current.value;
    //         }
    //         setbuyercentreid(Centrecode);
    //         GetBuyerLocationDetails();
    //         try {
    //             let url = FrontDesk_URL + 'master/RateTypeListByPatientType/' + partnertypeid.current.value + '/' + Centrecode + '/0/0/0';
    //             await axios.get(url).then((response) => {
    //                 const data = response.data;
    //                 if (data.Success) {
    //                     setRateType(data.Data.map((d) => (<option key={d.SNo} value={d.SNo}>{d.TemplateName}</option>)));
    //                     glblDefaultRec = data.Data;
    //                 }
    //                 else { setRateType("0") }
    //             }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
    //         }
    //         catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    //     }
    // }
    const ChangeRatePlan = async (e) => {
        glblValArr = [];
        setTableItems([]);
        setPaymentTable([]);
        setOptions([]);
        setDueAmt(parseFloat(gtotalamt).toFixed(2))
    }
    const handleInvoiceTypeChange = async (e) => {
        setinvoiceType(e.target.value);
        glblValArr = [];
        setTableItems([]);
        setPaymentTable([]);
        setOptions([]);
    }
    const GetBuyerLocationDetails = async (Centrecode) => {
        if (Centrecode != null && Centrecode != "" && Centrecode != "0") {
            try {
                let url = URL_Start + 'master/getbuyerlocationdetails/' + '/' + Centrecode;
                await axios.get(url).then((response) => {
                    const data = response.data;
                    if (data.Success) {
                        formik.setFieldValue('shippingPincode', data.Data.Pincode);
                        formik.setFieldValue('shippingStateId', data.Data.SAPStateId);
                        GetSAPShippingRegions(data.Data.SAPStateId);
                        formik.setFieldValue('shippingRegionId', data.Data.SAPRegionId);
                        GetSAPShippingDistricts(data.Data.SAPRegionId);
                        formik.setFieldValue('shippingDistrictId', data.Data.SAPDistrictId);
                        formik.setFieldValue('shippingAddress', data.Data.Address);
                        formik.setFieldValue('shippingGSTIN', data.Data.GSTIN);
                        setTransporterID(data.Data.TransporterID);
                        setbuyerBillToPincode(data.Data.Pincode);
                        setbuyerBillToStateId(data.Data.SAPStateId);
                        GetSAPBillingRegions(data.Data.SAPStateId);
                        setbuyerBillToRegionId(data.Data.SAPRegionId);
                        GetSAPBillingDistricts(data.Data.SAPRegionId);
                        setbuyerBillToDistrictId(data.Data.SAPDistrictId);
                        setBuyerBillToAddress(data.Data.Address);
                        setbuyerBillToGSTIN(data.Data.GSTIN);
                    }
                    else { }
                }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
            }
            catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
        }
    }
    const GetSAPStates = async (id) => {
        try {
            let url = URL_Start + 'master/getstateslist/' + id
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setBillingStatesList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>)));
                    setShippingStatesList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>)));
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetSAPBillingRegions = async (id) => {
        try {
            let url = URL_Start + 'master/getregionslist/' + id;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data !== null) {
                        setBillingRegionsList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>)));
                    }
                    else { setBillingRegionsList([]); }
                }
                else { setBillingRegionsList([]); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetSAPShippingRegions = async (id) => {
        try {
            let url = URL_Start + 'master/getregionslist/' + id;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data !== null) {
                        setShippingRegionsList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>)));
                    }
                    else { setShippingRegionsList([]); }
                }
                else { setShippingRegionsList([]); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetSAPBillingDistricts = async (id) => {
        try {
            let url = URL_Start + 'master/getdistrictslist/' + id;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data !== null) { setBillingDistrictsList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
                    else { setBillingDistrictsList([]); }
                }
                else { setBillingDistrictsList([]); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetSAPShippingDistricts = async (id) => {
        try {
            let url = URL_Start + 'master/getdistrictslist/' + id;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data !== null) { setShippingDistrictsList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
                    else { setShippingDistrictsList([]); }
                }
                else { setShippingDistrictsList([]); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetPincodeLocation = async (e) => {
        let text = e.target.value;
        let length = text.length;
        if (length === 6) {
            try {
                let url = URL_Start + 'master/getpincodelocations/' + e.target.value
                await axios.get(url).then((response) => {
                    const data = response.data;
                    if (data.Success) {
                        if (data != null) {
                            const pinCodeData = data.Data;
                            formik.values.shippingStateId = pinCodeData[0].SAPStateId;
                            GetSAPShippingRegions(formik.values.shippingStateId);
                            formik.values.shippingRegionId = pinCodeData[0].SAPRegionId;
                            GetSAPShippingDistricts(formik.values.shippingRegionId);
                            formik.values.shippingDistrictId = pinCodeData[0].SAPDistrictId;
                        }
                        else {
                            formik.values.shippingStateId = "";
                            formik.values.shippingRegionId = "";
                            formik.values.shippingDistrictId = "";
                        }
                    }
                    else {
                        formik.values.shippingStateId = "";
                        formik.values.shippingRegionId = "";
                        formik.values.shippingDistrictId = "";
                    }
                }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
            }
            catch (error) {
                SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
            }
        }
        else {
            formik.values.shippingStateId = "";
            formik.values.shippingRegionId = "";
            formik.values.shippingDistrictId = "";
        }
    }
    const SAPStateChangeHandler = (e) => {
        formik.values.shippingRegionId = "0";
        formik.values.shippingDistrictId = "0";
        if (e.target.value !== "0") { GetSAPShippingRegions(e.target.value); } else { GetSAPShippingRegions([]); }
        GetSAPShippingDistricts([]);
    }
    const SAPRegionChangeHandler = (e) => {
        formik.values.sapDistrictId = "0";
        if (e.target.value !== "0") { GetSAPShippingDistricts(e.target.value); } else { GetSAPShippingDistricts([]); }
    }
    const formik = useFormik({
        initialValues: {
            partnerType: '', centreName: '', ratetype: '', creditnoteremarks: '', InvoiceType: '1', shippingPincode: '',
            shippingStateId: '', shippingRegionId: '', shippingDistrictId: '', shippingAddress: ''
        },
        validationSchema: Yup.object({
            partnerType: Yup.string().required('Type is required'),
            centreName: Yup.string().required('Buyer is required'),
            ratetype: Yup.string().required('Rate Type is required'),
            shippingPincode: Yup.string().required('Shipping Pincode is required').typeError('Shipping Pincode  must be a number'),
            shippingStateId: Yup.string().required('Shipping state is required'),
            shippingRegionId: Yup.string().required('Shipping region is required'),
            shippingDistrictId: Yup.string().required('Shipping district is required'),
            shippingAddress: Yup.string().required('Shipping address is required')
        }),
        onSubmit: values => {
            if (invoiceType == "2" && (zeroinvoiceactive == "true" || zeroinvoiceactive == "1")) {
                SweetAlert.fire({
                    title: 'Zero Invoice?',
                    text: "Do you want to create zero amount invoice",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes',
                    reverseButtons: true,
                    cancelButtonText: 'No',
                    cancelButtonColor: "#000",
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            confirmBookingHandler(values, "1");
                        }
                        else {
                            confirmBookingHandler(values, "0");
                        }
                    })
            }
            else {
                confirmBookingHandler(values, "0");
            }
        },
    });
    const onInputChangePartsSelection = (value) => {
        if (value) {
            const instance = this._typeahead.getInstance()
            instance.clear()
            instance.focus()
        }
    }
    return (
        <>
            {
                invoicepage == false ?
                    <>
                        <Breadcrumb title="Create Invoice" parent="Bulk Invoice" />
                        <Form>
                            <Container fluid>
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <Card.Body>
                                                <Form>
                                                    <Form.Row>
                                                        <Col lg={3} md={4}>
                                                            <Form.Label>Centre Type</Form.Label>
                                                            <Form.Group className='animate-chk m-checkbox-inline mt-15'>
                                                                <Form.Label htmlFor="centreType2">
                                                                    <input className="radio_animated" type="radio" id='centreType2' name="rdo-ani" defaultChecked={true} onChange={handleRadioChange} value='2' />Third Party
                                                                </Form.Label>
                                                                <Form.Label htmlFor="centreType3">
                                                                    <input className="radio_animated" type="radio" id='centreType3' name="rdo-ani" onChange={handleRadioChange} value='3' />Panel
                                                                </Form.Label>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={3} md={4}>
                                                            <Form.Group>
                                                                <Form.Label>Type<span className='text-required'>*</span></Form.Label>
                                                                <Form.Control as="select" name="partnerType" ref={partnertypeid} onChangeCapture={handlePartnerTypeChange} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.partnerType}>
                                                                    <option value="">---Select---</option>
                                                                    {partnerTypeList}
                                                                </Form.Control>
                                                                {formik.touched.partnerType && formik.errors.partnerType ? (<div className='text-required'>{formik.errors.partnerType}</div>) : null}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={3} md={4}>
                                                            <Form.Group>
                                                                <Form.Label>Name<span className='text-red'>*</span></Form.Label>
                                                                <Typeahead id="centreName" name="centreName" defaultSelected={centres.slice(0, 1)} labelKey="name"
                                                                    ref={centreid}
                                                                    options={centres}
                                                                    value={formik.values.centreName}
                                                                    onChange={GetRateTypeByPatientType}
                                                                    placeholder="---Select Centre---" />
                                                                {formik.touched.centreName && formik.errors.centreName ? (<div className='text-required'>{formik.errors.centreName}</div>) : null}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={3} md={4}>
                                                            <Form.Group>
                                                                <Form.Label>Rate Plan <span className='text-red'>*</span></Form.Label>
                                                                <Form.Control as="select" name="ratetype" ref={rratetype} onChangeCapture={ChangeRatePlan} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.ratetype}>
                                                                    <option value="0">---Select---</option>
                                                                    {ratetype}
                                                                </Form.Control>
                                                                {formik.touched.ratetype && formik.errors.ratetype ? (<div className='text-required'>{formik.errors.ratetype}</div>) : null}
                                                            </Form.Group>
                                                        </Col>
                                                    </Form.Row>
                                                    <Form.Row>
                                                        <Col lg={3} md={4}>
                                                            <Form.Label>Invoice Type <span className='text-red'>*</span></Form.Label>
                                                            <Form.Group className='animate-chk m-checkbox-inline mt-15'>
                                                                <Form.Label htmlFor="invoiceType1">
                                                                    <input class="radio_animated" type="radio" id='invoiceType1' name="rdo-inv" defaultChecked={true} onChange={handleInvoiceTypeChange} value='1' />Invoice
                                                                </Form.Label>
                                                                <Form.Label htmlFor="invoiceType2">
                                                                    <input class="radio_animated" type="radio" id='invoiceType2' name="rdo-inv" onChange={handleInvoiceTypeChange} value='2' />Credit Note
                                                                </Form.Label>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={9} md={8}>
                                                            <Form.Label>{invoiceType == "2" ? 'Credit Note Remarks' : 'Invoice Remarks'} </Form.Label>
                                                            <Form.Control type="text" name="creditnoteremarks" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.creditnoteremarks} />
                                                        </Col>

                                                    </Form.Row>

                                                    <Form.Row>
                                                        {
                                                            buyercentreid != "0" ?
                                                                <>
                                                                    <Col lg={6} md={4}>
                                                                        <p><b>Billing Address:</b></p>
                                                                        <Row className='bxShipping dactive m-0'>
                                                                            <Col lg={4} md={4}>
                                                                                <Form.Group>
                                                                                    <Form.Label>GSTIN<span className='text-required'>*</span></Form.Label>
                                                                                    <Form.Control type="text" name="pinCode" value={buyerBillToGSTIN} disabled />
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col lg={4} md={4}>
                                                                                <Form.Group>
                                                                                    <Form.Label>Pin Code<span className='text-required'>*</span></Form.Label>
                                                                                    <Form.Control type="text" name="pinCode" value={buyerBillToPincode} disabled />
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col lg={4} md={4}>
                                                                                <Form.Group>
                                                                                    <Form.Label>State<span className='text-required'>*</span></Form.Label>
                                                                                    <Form.Control as="select" name="sapStateId" value={buyerBillToStateId} disabled>
                                                                                        <option value="">---Select---</option>
                                                                                        {billingStatesList}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col lg={4} md={4}>
                                                                                <Form.Group>
                                                                                    <Form.Label>Region<span className='text-required'>*</span></Form.Label>
                                                                                    <Form.Control as="select" name="sapRegionId" value={buyerBillToRegionId} disabled>
                                                                                        <option value="">---Select---</option>
                                                                                        {billingRegionsList}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col lg={4} md={4}>
                                                                                <Form.Group>
                                                                                    <Form.Label>District<span className='text-required'>*</span></Form.Label>
                                                                                    <Form.Control as="select" name="sapDistrictId" value={buyerBillToDistrictId} disabled>
                                                                                        <option value="">---Select---</option>
                                                                                        {billingDistrictsList}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col lg={4} md={4}>
                                                                                <Form.Group>
                                                                                    <Form.Label>Address<span className='text-required'>*</span></Form.Label>
                                                                                    <Form.Control type="text" name="centreAddress" value={buyerBillToAddress} disabled />
                                                                                </Form.Group>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                    <Col lg={6} md={4}>
                                                                        <p><b>Shipping Address:</b></p>
                                                                        <Row className='bxShipping m-0'>
                                                                            {/* <Col lg={4} md={4}>
                                                                    <Form.Group>
                                                                        <Form.Label>GSTIN<span className='text-required'>*</span></Form.Label>
                                                                        <Form.Control type="text" name="shippingGSTIN" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.shippingGSTIN} />
                                                                        {formik.touched.shippingGSTIN && formik.errors.shippingGSTIN ? (<div className='text-required'>{formik.errors.shippingGSTIN}</div>) : null}
                                                                    </Form.Group>
                                                                </Col> */}
                                                                            <Col lg={4} md={4}>
                                                                                <Form.Group>
                                                                                    <Form.Label>Pin Code<span className='text-required'>*</span></Form.Label>
                                                                                    <Form.Control type="text" name="shippingPincode" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.shippingPincode} onKeyUp={(e) => GetPincodeLocation(e, "1")} />
                                                                                    {formik.touched.shippingPincode && formik.errors.shippingPincode ? (<div className='text-required'>{formik.errors.shippingPincode}</div>) : null}
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col lg={4} md={4}>
                                                                                <Form.Group>
                                                                                    <Form.Label>State<span className='text-required'>*</span></Form.Label>
                                                                                    <Form.Control as="select" name="shippingStateId" onChangeCapture={SAPStateChangeHandler} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.shippingStateId}>
                                                                                        <option value="">---Select---</option>
                                                                                        {shippingStatesList}
                                                                                    </Form.Control>
                                                                                    {formik.touched.shippingStateId && formik.errors.shippingStateId ? (<div className='text-required'>{formik.errors.shippingStateId}</div>) : null}
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col lg={4} md={4}>
                                                                                <Form.Group>
                                                                                    <Form.Label>Region<span className='text-required'>*</span></Form.Label>
                                                                                    <Form.Control as="select" name="shippingRegionId" onChangeCapture={SAPRegionChangeHandler} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.shippingRegionId}>
                                                                                        <option value="">---Select---</option>
                                                                                        {shippingRegionsList}
                                                                                    </Form.Control>
                                                                                    {formik.touched.shippingRegionId && formik.errors.shippingRegionId ? (<div className='text-required'>{formik.errors.shippingRegionId}</div>) : null}
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col lg={4} md={4}>
                                                                                <Form.Group>
                                                                                    <Form.Label>District<span className='text-required'>*</span></Form.Label>
                                                                                    <Form.Control as="select" name="shippingDistrictId" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.shippingDistrictId}>
                                                                                        <option value="">---Select---</option>
                                                                                        {shippingDistrictsList}
                                                                                    </Form.Control>
                                                                                    {formik.touched.shippingDistrictId && formik.errors.shippingDistrictId ? (<div className='text-required'>{formik.errors.shippingDistrictId}</div>) : null}
                                                                                </Form.Group>
                                                                            </Col>
                                                                            <Col lg={8} md={4}>
                                                                                <Form.Group>
                                                                                    <Form.Label>Address<span className='text-required'>*</span></Form.Label>
                                                                                    <Form.Control type="text" name="shippingAddress" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.shippingAddress} />
                                                                                    {formik.touched.shippingAddress && formik.errors.shippingAddress ? (<div className='text-required'>{formik.errors.shippingAddress}</div>) : null}
                                                                                </Form.Group>
                                                                            </Col>
                                                                        </Row>
                                                                    </Col>
                                                                </>
                                                                : ""}
                                                    </Form.Row>

                                                    <Form.Row className='mt-5'>
                                                        <Col lg={12} md={12}>
                                                            <Form.Group>
                                                                <Form.Label>Add Test</Form.Label>
                                                                <Typeahead
                                                                    id="basic-typeahead"
                                                                    labelKey="Item"
                                                                    filterBy={["Item", "ShortName"]}
                                                                    multiple={multiple}
                                                                    options={options}
                                                                    placeholder="Choose a test..."
                                                                    ref={testreference}
                                                                    onInputChange={(text, e) => { getitemlist(e, text); }}
                                                                    onChange={(text) => {
                                                                        JSON.stringify(text) == "[]" ? setItemSno("") : setItemSno(text[0].SNo)
                                                                        BindGrid(text[0].SNo)
                                                                    }}
                                                                />
                                                            </Form.Group>
                                                        </Col>
                                                    </Form.Row>
                                                </Form>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <div className='text-right'>
                                    <b>Total Test:</b> <span className='text-danger mr-2' name="totaltest">{totaltest}</span>
                                    <b>Total Amount:</b> <span className='text-danger mr-2' name="totalamount"><i className='fa fa-inr'></i>{totalamt}</span>
                                    <b>Grand Total Amount:</b> <span className='text-danger mr-2' name="gtotalamount"><i className='fa fa-inr'></i>{gtotalamt}</span>
                                </div>
                                <div className="table-responsive mt15">
                                    {/* <DataTablesEdit
                            keyField='ItemSNo'
                            tableData={tableitems}
                            columns={columns}
                            AfterCellCallFnc={}
                            noDataIndication={NoRecordFounds}
                        /> */}
                                    <BootstrapTable
                                        keyField='ItemSNo'
                                        data={tableitems}
                                        columns={columns}
                                        bootstrap4
                                        headerWrapperClasses="thead-light"
                                        bordered={false}
                                        noDataIndication={<NoRecordFounds />}
                                        cellEdit={cellEditFactory({
                                            mode: 'click',
                                            blurToSave: true,
                                            afterSaveCell: (oldValue, newValue, rowData, column) => { upRateManage(newValue, rowData) },
                                        })
                                        }>

                                    </BootstrapTable>
                                </div>
                                {
                                    tableitems.length == 0 ? null :
                                        <>
                                            {
                                                <Col md={12}>
                                                    <Card>
                                                        <Card.Header className='pb-0'>
                                                            <Card.Title>Payment</Card.Title>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            {/* <Form.Row className='p-2 mb-2 justify-content-start' style={{background: '#f2f2f2'}} >
                                                    <Col lg={2} md={2}>
                                                        <Form.Group>
                                                            <Form.Label> <b>Freight Amount</b></Form.Label>
                                                            <Form.Control type="text" id='frieghtAmount' ref={frieghtAmount} onBlur={(e) => FrieghtTax(e.target.value)} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={2} md={2}>
                                                    </Col>
                                                    <Col lg={2} md={2}>
                                                        <Form.Group>
                                                            <Form.Label> <b>Freight Tax </b></Form.Label><br />
                                                            <Form.Label> <span>{FrieghtTaxAmt}</span></Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={2} md={2}>
                                                        <Form.Group>
                                                            <Form.Label><b> Total Freight Amount</b></Form.Label><br />
                                                            <Form.Label><span>{FrieghtTotalAmt}</span></Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>*/}
                                                            <Form.Row className='align-items-center'>
                                                                <Col md={12}>
                                                                    {gtotalamt > 0 ?
                                                                        <Form.Row>
                                                                            <Col md={2}>
                                                                                <Form.Group>
                                                                                    <Form.Label>Payment Mode</Form.Label>
                                                                                    <Form.Control as="select" ref={paymentmodedetail} onChange={(e) => { ChangePaymentMode(e.target.value) }} disabled={disablePayMode} >
                                                                                        {<option value="0">----Select-----</option>}
                                                                                        {paymentmode}
                                                                                    </Form.Control>
                                                                                </Form.Group>
                                                                            </Col>
                                                                            {
                                                                                paymentModeId === "13" ?
                                                                                    <Col md={2}>
                                                                                        <Form.Group>
                                                                                            <Form.Label>Payment Channel</Form.Label>
                                                                                            <Form.Control as="select" ref={paymentChannelId} disabled={disablePayMode} onChange={(e) => SelectChannel(e)}>
                                                                                                {<option value="0">----Select-----</option>}
                                                                                                {UserPOSCode === "" ? null : <option value="-1">POS</option>}
                                                                                                {paymentChannels}
                                                                                            </Form.Control>
                                                                                        </Form.Group>
                                                                                    </Col>
                                                                                    : null
                                                                            }
                                                                            {
                                                                                (paymentModeId === "14" || paymentModeId === "15") && paymentModeIdSetting !== "2" ?
                                                                                    <Col md={2}>
                                                                                        <Form.Group style={{ marginTop: 35, marginLeft: 20 }}>
                                                                                            <input className="checkbox_animated" id="inpercentage" type="checkbox" onClick={(e) => { handlePercentageCheckbox(e); }} /> In Percentage
                                                                                        </Form.Group>
                                                                                    </Col> :
                                                                                    null
                                                                            }
                                                                            {Percentagecheckbox === "0" ?
                                                                                <Col md={2}>
                                                                                    <Form.Group>
                                                                                        <Form.Label>Amount</Form.Label>
                                                                                        <Form.Control type="text" ref={paymentamountdetail} />
                                                                                    </Form.Group>
                                                                                </Col>
                                                                                :
                                                                                <Col md={2}>
                                                                                    <Form.Group>
                                                                                        <Form.Label>In Percentage Amount</Form.Label>
                                                                                        <Form.Control type="text" ref={paymentamountdetail} />
                                                                                    </Form.Group>
                                                                                </Col>
                                                                            }
                                                                            {paymentModeId === "13" ?
                                                                                <>
                                                                                    {
                                                                                        channelid === "-1" || channelid === "0" ? null :
                                                                                            <>
                                                                                                <Col md={3}>
                                                                                                    <Form.Group>
                                                                                                        <Form.Label>Reference No.</Form.Label>
                                                                                                        <Form.Control type="text" ref={paymentReferenceNo} />
                                                                                                    </Form.Group>
                                                                                                </Col>
                                                                                            </>
                                                                                    }
                                                                                </>
                                                                                : (paymentModeId === "14" || paymentModeId === "15") && paymentModeIdSetting !== "2" ?
                                                                                    <>
                                                                                        <Col md={2}>
                                                                                            <Form.Group>
                                                                                                <Form.Label>{paymentModeId === "14" ? "Discount Authorizer" : "Credit Authorizer"}</Form.Label>
                                                                                                <Form.Control as="select" ref={authorizerId} onChange={(e) => SetAuthorizerDetails(e)}>
                                                                                                    {paymentModeId === "14" ? discountAuthorizers : creditAuthorizers}
                                                                                                </Form.Control>
                                                                                            </Form.Group>
                                                                                        </Col>
                                                                                        <Col md={3}>
                                                                                            <Form.Group>
                                                                                                <Form.Label>{paymentModeId === "14" ? "Discount" : "Credit"} Reason</Form.Label>
                                                                                                <Form.Control type="text" ref={concessionReason} />
                                                                                            </Form.Group>
                                                                                        </Col>
                                                                                    </>
                                                                                    : null}

                                                                            {hideAddPayButton ? null :
                                                                                <>
                                                                                    {
                                                                                        hideshowpaymentbutton === false ?
                                                                                            <Col md={2} xs={11}>
                                                                                                <Form.Group className='m-t-15'>
                                                                                                    <Form.Label className='icRight'>
                                                                                                        <Button variant='secondary' onClick={BindPaymentGrid}>Add</Button>
                                                                                                    </Form.Label>
                                                                                                </Form.Group>
                                                                                            </Col>
                                                                                            : null
                                                                                    }
                                                                                </>
                                                                            }
                                                                        </Form.Row>
                                                                        : null}
                                                                </Col>
                                                            </Form.Row>
                                                            {
                                                                showAvailableBalance == 1 ?
                                                                    <div className='text-right'>
                                                                        <b>Available Credit:</b> <span className='text-danger mr-2' name="totalamount"><i className='fa fa-inr'></i>{availableamt}</span>
                                                                    </div> : ""
                                                            }
                                                            <div className="table-responsive mt15">
                                                                <DataTables keyField='PaymentModeId' tableData={paymenttable} columns={paymentColumns} noDataIndication={NoRecordFounds} />
                                                            </div>
                                                        </Card.Body>
                                                    </Card>
                                                </Col>

                                            }
                                        </>
                                }
                                {
                                    invoiceType == "1" ?
                                        <Form.Row className='p-2 mb-2 justify-content-start' style={{ background: '#f2f2f2' }} >
                                            <Col lg={3} md={3}>
                                                <Form.Group>
                                                    <Form.Label> <b>Transporter ID <span className='text-required'>*</span></b></Form.Label>
                                                    <Form.Control type="text" id='transporterid' value={TransporterID} disabled />
                                                </Form.Group>
                                            </Col>
                                            <Col lg={3} md={3}>
                                                <Form.Group>
                                                    <Form.Label> <b>Vehicle Number <span className='text-required'>*</span></b></Form.Label>
                                                    <Form.Control type="text" id='VehicleNo' ref={rfVehicleNo} maxLength={20} />
                                                </Form.Group>
                                            </Col>

                                        </Form.Row> : ""
                                }
                                <Form.Row>
                                    {tableitems.length == 0 ? null :
                                        <Col md={12} className="mt-4 text-center">
                                            {isButtonLoading ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' className="mr-2" type='submit' onClick={formik.handleSubmit}>Preview Invoice</Button>}
                                            <Button variant='secondary' className="mr-2" onClick={(e) => { e.preventDefault(); window.location.reload(false) }} >Refresh</Button>
                                        </Col>
                                    }
                                </Form.Row>

                            </Container>
                        </Form>
                    </> :
                    <ViewInvoice invoicepage={(invoicepage) => setInvoicePage(invoicepage)} />
            }
        </>
    )
}

export default CreateInvoice