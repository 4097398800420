import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import NewToolTip from '../UI/ToolTips/NewToolTip';
import Select from 'react-select';
import { CSVLink } from "react-csv";

const BankCashFlow = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [table, setTable] = useState([]);
    const [filterType, setFilterType] = useState("0");
    const [defaultDate, setDefaultDate] = useState("");
    const [typeSelected, setTypeSelected] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [isButton, setIsButton] = useState(false);
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [CSVData, setCSVData] = useState([]);

    let fromDate = useRef();
    let toDate = useRef();
    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        GetBankFlowNames("1", "0");
        GetBankCashFlowDetails('0', '0', defaultValue, defaultValue, "1");
    }, [])
    const GetBankFlowNames = async (lType, rId) => {
        var glblArr1 = [];
        setIsButton(true);
        try {
            let url = URL_Start + 'accounts/getledgersname/' + lType + '/' + rId
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsButton(false);
                if (data.Success) {
                    let ds = data.Data;
                    if (ds != null) {
                        var itmData = data.Data;
                        itmData.map((option) => { return glblArr1.push({ 'value': option.Id, 'label': option.Value, 'disabled': false }); });
                        setTableData(glblArr1);
                    }
                    else { setTableData([]); }
                }
                else {
                    setTableData([]);
                }
            }).catch(function (error) { setIsButton(false); if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const PaginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {
                    rowData.ReconsileIcon === "0" || rowData.ReconsileIcon === "False" ?
                        <>
                            <NewToolTip title="Reconcile" placement="top" id={`Reconcile${rowData.CashFlowId}`} icon="fa fa-check-square-o" onClick={(e) => ReconcileCheck(rowData.CashFlowId, "1")} />
                            &nbsp; <NewToolTip title="Reject" placement="top" id={`Reject${rowData.CashFlowId}`} icon="fa fa-close" onClick={(e) => ReconcileCheck(rowData.CashFlowId, "2")} />
                        </> :
                        rowData.ReconsileIcon === "2" ?
                            <div>Rejected</div>
                            :
                            <div>Reconciled</div>
                }
            </div>
        </React.Fragment>
    }
    const actionImageFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                {/* <Button id={`Documents${rowData.CashFlowId}`} onClick={() => ShowAllDocuments(rowData.ImageName)}>Download</Button> */}
                <a href='javascriptvoid:(0)' id={`Documents${rowData.CashFlowId}`} onClick={() => ShowAllDocuments(rowData.ImageName)}>Download</a>
                {/* <NewToolTip title="Download Uploaded Documents" id={`Documents${rowData.CashFlowId}`} placement="top" icon="fa fa-download" onClick={() => ShowAllDocuments(rowData.ImageName)} /> */}
                {/* <img src={rowData.ImageName} width="80"></img> */}
            </div>
        </React.Fragment >
    }
    const ShowAllDocuments = async (imagename) => {
        if (imagename === "") {
            SweetAlert.fire("No documents available");
        }
        else {
            var preurl = imagename;
            var prename = "Bank Slips";
            downloadFile(preurl, prename);
        }
    }
    function downloadFile(url, fileName) {
        const aElement = document.createElement('a');
        aElement.setAttribute('download', fileName);
        aElement.href = url;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(url);
    }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'TransferToBank', text: 'Transfer By', editable: false },
        { dataField: 'Amount', text: 'Amount', editable: false },
        { dataField: 'ImageName', text: 'Bank Receipt', editable: false, formatter: actionImageFormatter },
        { dataField: 'ReferenceNo', text: 'Reference No', editable: false },
        { dataField: 'ReconsileBy', text: 'Reconcile By', editable: false },
        { dataField: 'ReconsileOn', text: 'Reconcile On', editable: false },
        { dataField: 'EntryDateTime', text: 'Entry Date Time', editable: false },
        { dataField: 'actions', isDummyField: true, text: 'Actions', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { width: '7%' }; } }
    ];
    const GetBankCashFlowDetails = async (filterType, userid, fromdate, todate, flag) => {
        setDownloadVisible(false);
        setIsLoading(true);
        try {
            let url = URL_Start + 'master/GetBankCashFlowDetails/' + filterType + '/' + userid + '/' + fromdate + '/' + todate + '/' + flag
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsLoading(false);
                if (data.Success) {
                    if (data.Data === null) { setTable([]); setCSVData([]); }
                    else {
                        if (flag == "2") { setDownloadVisible(true); setCSVData(data.Data); document.getElementById("csvdownload").click(); }
                        else { setTable(data.Data); }
                    }
                }
                else {
                    setTable([]); setCSVData([]);
                }
            }).catch(function (error) { setIsLoading(false); if (error.response) { setTable([]); setCSVData([]); ErrorFunction(error.response.status); } });
        }
        catch (error) { setIsLoading(false); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const ReconcileCheck = async (CashFlowId, Flag) => {
        if (Flag === "1") {
            SweetAlert.fire({
                title: 'You are going to reconcile a record. Are you sure?',
                text: "Once reconciled, you will not be able to change!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => { if (result.value) { ReconsileBankAmount(CashFlowId, Flag); } else { SweetAlert.fire('Not reconciled') } })
        }
        else {
            SweetAlert.fire({
                title: 'You are going to reject a record. Are you sure?',
                text: "Once rejected, you will not be able to change!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => { if (result.value) { ReconsileBankAmount(CashFlowId, Flag); } else { SweetAlert.fire('Not rejected') } })
        }
    }
    const ReconsileBankAmount = async (CashFlowId, Flag) => {
        const requestOptions = {
            CashFlowId: CashFlowId,
            LoginId: localStorage.getItem('LoggedInUser'),
            StatusFlag: Flag
        };
        let url = URL_Start + 'master/ReconsileBankAmount'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (Flag === "1") { SweetAlert.fire({ text: "Reconciled", icon: "success" }); }
                else { SweetAlert.fire({ text: "Rejected", icon: "error" }); }
                const today = new Date();
                const date = today.setDate(today.getDate());
                const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
                setDefaultDate(defaultValue);
                setTypeSelected([]);
                fromDate.current.value = defaultValue;
                toDate.current.value = defaultValue;
                GetBankCashFlowDetails('0', '0', defaultValue, defaultValue, "1");
            }
            else {
                if (data.ErrorList[0].errorCode == -2000 || data.Data == -2000) { SweetAlert.fire({ title: "API Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
                else if (data.ErrorList[0].errorCode == -1 || data.Data == -1) {
                    SweetAlert.fire({
                        title: 'Already reconciled',
                        text: "Already reconciled",
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    }).then((result) => { if (result.value) { window.location.reload(false); } else { window.location.reload(false); } })
                }
                else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
            }
        }).catch(function (error) {
            if (error.response) { ErrorFunction(error.response.status); }
        });
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    const handleRadioChange = async (e) => {
        setFilterType(e.target.value);
    }

    const handleSearch = async () => {
        var fdate = (fromDate.current.value == "" || fromDate.current.value == null || fromDate.current.value == undefined) ? '0' : fromDate.current.value
        var tdate = (toDate.current.value == "" || toDate.current.value == null || toDate.current.value == undefined) ? '0' : toDate.current.value
        if (fdate == '0' && tdate != '0') { SweetAlert.fire("From date is mandatory"); return false; }
        if (fdate != '0' && tdate == '0') { SweetAlert.fire("To date is mandatory"); return false; }
        if (typeSelected.length > 0) {
            GetBankCashFlowDetails(filterType, typeSelected[0].value, fdate, tdate, "1");
        }
        else {
            GetBankCashFlowDetails(filterType, '0', fdate, tdate, "1");
        }
    }
    const TypeChangeHandler = (options) => {
        var typearr = [];
        typearr.push({
            value: options.value,
            label: options.label
        });
        setTypeSelected(typearr);
    }
    const handleDownload = async () => {
        var fdate = (fromDate.current.value == "" || fromDate.current.value == null || fromDate.current.value == undefined) ? '0' : fromDate.current.value
        var tdate = (toDate.current.value == "" || toDate.current.value == null || toDate.current.value == undefined) ? '0' : toDate.current.value
        if (fdate == '0' && tdate != '0') { SweetAlert.fire("From date is mandatory"); return false; }
        if (fdate != '0' && tdate == '0') { SweetAlert.fire("To date is mandatory"); return false; }
        if (typeSelected.length > 0) {
            GetBankCashFlowDetails(filterType, typeSelected[0].value, fdate, tdate, "2");
        }
        else {
            GetBankCashFlowDetails(filterType, '0', fdate, tdate, "2");
        }
    }
    return (
        <>
            <Breadcrumb title="Bank Cash Flow" parent="Accounts" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row>
                                    <Col md={12}>
                                        <Form.Label className='mb-4'><b>Type</b></Form.Label>
                                        <Form.Group className='animate-chk m-checkbox-inline center__type mt-15'>
                                            <Form.Label for="cashType">
                                                <input class="radio_animated" type="radio" id='cashType' onChange={handleRadioChange} name="rdoo-ani" defaultChecked value='0' />Pending
                                            </Form.Label>
                                            <Form.Label for="centreType2">
                                                <input class="radio_animated" type="radio" id='cashType' onChange={handleRadioChange} name="rdoo-ani" value='1' />approved
                                            </Form.Label>
                                            <Form.Label for="centreType2">
                                                <input class="radio_animated" type="radio" id='cashType' onChange={handleRadioChange} name="rdoo-ani" value='2' />Reject
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col md={8}>
                                        <Form.Group className='animate-chk m-checkbox-inline center__type mt-15'>
                                            <Form.Label>Users</Form.Label>
                                            {isButton ? <Spinner animation="border" size='sm' /> : null}
                                            <Select onChange={TypeChangeHandler}
                                                options={tableData}
                                                value={typeSelected} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Group>
                                            <Form.Label>From Date</Form.Label>
                                            <Form.Control type="date" id="date1" name="date1" ref={fromDate} defaultValue={defaultDate} max={defaultDate} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={2}>
                                        <Form.Group>
                                            <Form.Label>To Date</Form.Label>
                                            <Form.Control type="date" id="date2" name="date2" ref={toDate} defaultValue={defaultDate} max={defaultDate} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} className='text-center __center__buttonsec'>
                                        <Button variant='secondary' className="mr-2" onClick={handleSearch}>Search</Button>
                                        <Button variant='primary' className="mr-2" onClick={handleDownload}>Download</Button>
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Bank Cash Flow Details</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        // <div className="table-responsive mt15">
                                        <DataTables
                                            keyField='CashFlowId'
                                            tableData={table}
                                            columns={columns}
                                            noDataIndication={NoRecordFounds}
                                            pagination={paginationFactory(PaginationOptions)}
                                        />
                                    // </div>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    downloadvisible &&
                    <CSVLink data={CSVData} filename="Bank-Cash-Flow.csv" target="_blank" id="csvdownload"></CSVLink>
                }
            </Container>
        </>
    )
}
export default BankCashFlow;