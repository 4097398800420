import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import SweetAlert from 'sweetalert2';
import { Image_Path, URL_Start } from '../../../constant';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import LoadingButton from '../commonComponent/LoadingButton';
import DataTables from '../commonComponent/DataTable/DataTables';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip'
import Select from 'react-select';
import Moment from 'moment';

var glblArr = [];
var glblBioWasteArr = [];
var glbContactPersons = [];
let glbSalesHierarchy = [];
let glbCreditCentreTypes = [];
const CentreInfo = (id) => {
    const history = useHistory();
    const [modal, setModal] = useState();
    const toggleModal = () => { setModal(!modal) }
    const [errorModal, setErrorModal] = useState();
    const errorHandler = () => { setModal(true); setErrorModal(true); }
    const [partnerTypeList, setPartnerTypeList] = useState([]);
    const [creditCentreTypes, setCreditCentreTypes] = useState([]);
    const [paymentModeList, setPaymentModeList] = useState([]);
    const [banksList, setBanksList] = useState(0);
    const [processingLabsList, setProcessingLabsList] = useState(0);
    const [businessLabsList, setBusinessLabsList] = useState(0);
    const [billingCycleList, setBillingCycleList] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [selectTitle, setSelectTitle] = useState('---Select---');
    const [selectBLabTitle, setSelectBLabTitle] = useState('---Self---');
    const [isOwnCentreType, setOwnCentreType] = useState(true);
    const [isTPCentreType, setIsTPCentreType] = useState(false);
    const [isPanel, setIsPanel] = useState(false);
    const [showpanelfields, setShowPanelFields] = useState(false);
    const [viewpaneldocuments, setViewPanelDocuments] = useState([]);
    const [viewbiowastedocuments, setViewBioWasteDocuments] = useState([]);
    const [dctypevalue, setDCTypeValue] = useState("0");
    const [sapTitle, setSAPTitle] = useState("SAP Branch Code");
    const [cpDataTable, setCPDataTable] = useState([]);
    const [salesPersons, setSalesPersons] = useState([]);
    const [salesManagers, setSalesManagers] = useState([]);
    const [salesHeads, setSalesHeads] = useState([]);
    const [CompanyName, setCompanyName] = useState(0);
    const [sapStatesList, setSAPStatesList] = useState([]);
    const [sapRegionsList, setSAPRegionsList] = useState([]);
    const [sapDistrictsList, setSAPDistrictsList] = useState([]);
    const [displayCenterType, setDisplayCenterType] = useState("1");
    const [isLab, setIsLab] = useState(false);
    const [isSubCentre, setIsSubCentre] = useState(false);
    const [labTypes, setLabTypes] = useState([]);
    const [hideAmountDisabled, setHideAmountDisabled] = useState(false);
    const [showBalanceDisabled, setShowBalanceDisabled] = useState(false);
    const [hideReciptRateDisabled, setHideReciptRateDisabled] = useState(false);
    const [partnersList, setPartnersList] = useState([]);
    const [processingLabDisabled, setProcessingLabDisabled] = useState(false);
    const [disableCreditSetting, setDisableCreditSetting] = useState(false);
    const [disablePaymentMode, setDisablePaymentMode] = useState(true);
    const [disableMinCashBooking, setDisableMinCashBooking] = useState(false);
    const [hideCentreAdmin, setHideCentreAdmin] = useState(true);
    const [salesPersonSelected, setSalesPersonSelected] = useState("");
    const [salesManagerSelected, setSalesManagerSelected] = useState("");
    const [salesHeadSelected, setSalesHeadSelected] = useState("");
    const [disableCentreType, setDisableCentreType] = useState(false);
    const [invoiceTypeTitle, setInvoiceTypeTitle] = useState("----Self----");
    const [disableWorkTYpe, setDisableWorkTYpe] = useState(false);
    const [disableShowMrpBills, setDisableShowMrpBills] = useState(false);
    const [disablePrintDeptSlips, setDisablePrintDeptSlips] = useState(false);
    const [disableShowPROinReg, setDisableShowPROinReg] = useState(false);
    const [disableShowDetailsOnReport, setDisableShowDetailsOnReport] = useState(false);
    const [iconPath, setIconPath] = useState('');
    const [bannerPath, setBannerPath] = useState('');
    const [bwcpath, setBWCPath] = useState('');
    const [isambulance, setIsAmbulance] = useState(false);
    const [iscentrevisible, setIsCentreVisible] = useState(false);
    const [invalidfiletype, setInvalidFileType] = useState(false);
    const [bankcode, setBankCode] = useState("");
    const [sapNewTitle, setSAPNewTitle] = useState("New SAP Branch Code");
    const [typeSelected, setTypeSelected] = useState([]);
    const [itemname, setItemName] = useState([]);
    let dcname = useRef();
    let dctype = useRef();
    let dcvalue = useRef();
    let bheader = useRef();
    let bdescription = useRef();
    useEffect(() => {
        var element = document.querySelector("input[type='file']")
        element.classList.remove("removeValue");
        ManageCentreTypeChange("1");
        ManageSettings("1");
        glbContactPersons = [];
        GetSAPStates("1");
        GetPartnerTypes("1", "0");
        GetPaymentModes();
        GetBanks();
        GetLabsList("1");
        GetLabsList("2");
        GetBillingCycles();
        GetCompanyName();
        GetCentreDetails();
        GetSalesHierarchy();
        GetLabTypes();
        setDisableCentreType(false);
        glbCreditCentreTypes = [];
    }, []);
    useEffect(() => { glblArr = viewpaneldocuments }, [viewpaneldocuments]);
    useEffect(() => { glblBioWasteArr = viewbiowastedocuments; }, [viewbiowastedocuments])

    const panelidFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const panelFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <DeleteToolTip title="Delete" placement="top" id={`Delete${rowData.DocumentId}`} onClick={(e) => FilterDocument(rowData.DocumentId)} />
        </React.Fragment>
    }
    const panelColumns = [
        { dataField: '#', text: '#', editable: false, formatter: panelidFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'DocumentName', text: 'Document Name', editable: false },
        { dataField: 'DocumentType', text: 'Document Type', editable: false },
        { dataField: 'DocumentValue', text: 'Document Value', editable: false },
        { dataField: 'actions', text: 'Actions', editable: false, formatter: panelFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    const biowasteidFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const biowasteFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <DeleteToolTip title="Delete" placement="top" id={`Delete${rowData.DocumentId}`} onClick={(e) => FilterBioWasteDocument(rowData.DocumentId)} />
        </React.Fragment>
    }
    const biowastedocumentFormatter = (cellData, rowData, rowIndex, extraData) => {
        const FileURL = Image_Path + "BioWasteCompliance/" + rowData.BioWasteDoc;
        return <React.Fragment>
            <i className='fa fa-download' onClick={() => downloadFile(FileURL, rowData.BioWasteDoc)}></i>
        </React.Fragment>
    }
    const biowasteColumns = [
        { dataField: '#', text: '#', editable: false, formatter: biowasteidFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'BioWasteHeader', text: 'Header', editable: false },
        { dataField: 'BioWasteDescription', text: 'Description', editable: false },
        { dataField: 'BioWasteDoc', text: 'Document', editable: false, formatter: biowastedocumentFormatter },
        { dataField: 'actions', text: 'Actions', editable: false, formatter: biowasteFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    function downloadFile(url, fileName) {
        const aElement = document.createElement('a');
        aElement.setAttribute('download', fileName);
        aElement.href = url;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(url);
    }
    const GetSAPStates = async (id) => {
        try {
            let url = URL_Start + 'master/getstateslist/' + id
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setSAPStatesList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetSAPRegions = async (id) => {
        try {
            let url = URL_Start + 'master/getregionslist/' + id;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data !== null) { setSAPRegionsList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
                    else { setSAPRegionsList([]); }
                }
                else { setSAPRegionsList([]); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetSAPDistricts = async (id) => {
        try {
            let url = URL_Start + 'master/getdistrictslist/' + id;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data !== null) { setSAPDistrictsList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
                    else { setSAPDistrictsList([]); }
                }
                else { setSAPDistrictsList([]); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetPartnerTypes = async (ctId, pType) => {
        try {
            let url = URL_Start + 'master/getpartnertypes/' + ctId;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    // setPartnerTypeList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>)));
                    // setCreditCentreTypes(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>)));
                    glbCreditCentreTypes = data.Data;
                }
            }).catch(function (error) { setPartnerTypeList([]); glbCreditCentreTypes = []; });
        }
        catch (error) { setPartnerTypeList([]); glbCreditCentreTypes = []; SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
        setPartnerTypeList(glbCreditCentreTypes.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>)));
        if (pType !== "0" && pType !== "") {
            var tmpCreditCentreType = glbCreditCentreTypes;
            if (pType === "4") { tmpCreditCentreType = glbCreditCentreTypes.filter(function (x) { return x.Id !== "4"; }) }
            else if (pType === "5") { tmpCreditCentreType = glbCreditCentreTypes.filter(function (x) { return x.Id === "3"; }) }
            setCreditCentreTypes(tmpCreditCentreType.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>)));
        }
    }
    const GetPaymentModes = async () => {
        try {
            let url = URL_Start + 'master/getpaymentmode'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setPaymentModeList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetBanks = async () => {
        try {
            let url = URL_Start + 'master/getbank'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    //setBanksList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>)));
                    if (data.Data != null) {
                        var itmData = data.Data;
                        itmData.map((option) => { return glblArr.push({ 'value': option.Id, 'label': option.Name, 'disabled': false }); });
                        setItemName(glblArr);
                    }
                    else { setItemName([]); }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetLabsList = async (typeId) => {

        try {
            let url = URL_Start + 'master/getlabslist/' + typeId
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (typeId === "1") { setProcessingLabsList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>))); }
                    else if (typeId === "2") { setBusinessLabsList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>))); }
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetBillingCycles = async () => {
        try {
            let url = URL_Start + 'master/getbillingcyclelist'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setBankCode(data.Data[0].BankCode); setBillingCycleList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }

    const GetCompanyName = async () => {
        try {
            let url = URL_Start + 'master/GetCompanyName'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setCompanyName(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Companyname}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }

    const GetCentreDetails = async () => {
        try {
            if (localStorage.getItem('cId') !== null) {
                setIsLoading(true);
                formik.values.centreId = localStorage.getItem('cId');
                let url = URL_Start + 'master/getcentredetails/' + formik.values.centreId;
                await axios.get(url).then((response) => {
                    const data = response.data;
                    if (data.Success) {
                        const centrInfo = data.Data;
                        GetPartnerTypes(centrInfo.CentreType, centrInfo.PartnerType);
                        GetSAPRegions(centrInfo.SAPStateId);
                        GetSAPDistricts(centrInfo.SAPRegionId);
                        formik.values.partnerType = centrInfo.PartnerType;
                        ManageCentreTypeChange(centrInfo.CentreType);
                        setDisableCentreType(true);
                        let _isCentreVisible = false;
                        if (centrInfo.WorkingFromDay === "") { setIsCentreVisible(false); }
                        else { setIsCentreVisible(true); _isCentreVisible = true }
                        formik.values.isCentreVisible = _isCentreVisible;
                        formik.values.centreId = centrInfo.CentreId;
                        formik.values.centreType = centrInfo.CentreType;
                        formik.values.workingType = centrInfo.WorkingType;
                        formik.values.centreName = centrInfo.CentreName;
                        formik.values.uhidAbbreviation = centrInfo.UHIDAbbreviation;
                        formik.values.sapCode = centrInfo.SAPCode;
                        formik.values.sapNewCode = centrInfo.NewSAPCode;
                        formik.values.sapStateId = centrInfo.SAPStateId;
                        formik.values.branchid = centrInfo.BranchId;
                        formik.values.sapRegionId = centrInfo.SAPRegionId;
                        formik.values.sapDistrictId = centrInfo.SAPDistrictId;
                        formik.values.pinCode = centrInfo.PinCode;
                        formik.values.centreAddress = centrInfo.CentreAddress;
                        formik.values.latitude = centrInfo.Latitude;
                        formik.values.longitude = centrInfo.Longitude;
                        formik.values.labType = centrInfo.LabType;
                        formik.values.parentCentreType = centrInfo.ParentCentreType;
                        formik.values.parentCentreId = centrInfo.ParentCentreId;
                        formik.values.salesPerson = centrInfo.SalesPerson;
                        formik.values.salesManager = centrInfo.SalesManager;
                        formik.values.salesHead = centrInfo.SalesHead;
                        formik.values.paymentMode = (centrInfo.PaymentModeId == "0" || centrInfo.PaymentModeId == "False" ? "2" : centrInfo.PaymentModeId);
                        formik.values.invoiceEmail = centrInfo.InvoiceEmail;
                        formik.values.reportEmail = centrInfo.ReportEmail;
                        formik.values.minBusinessCommit = centrInfo.MinBusinessCommit;
                        formik.values.gstinNo = centrInfo.GSTIN;
                        formik.values.bankId = centrInfo.BankId;
                        formik.values.accountNo = centrInfo.AccountNo;
                        formik.values.ifscCode = centrInfo.IFSCCode;
                        formik.values.accountName = centrInfo.AccountName;
                        formik.values.panNo = centrInfo.PAN;
                        formik.values.virtualAccount = centrInfo.VirtualAccount;
                        formik.values.invoiceTo = centrInfo.InvoiceTo;
                        formik.values.minBookingCash = centrInfo.MinBookingCash;
                        formik.values.processingLabId = centrInfo.ProcessingLabId;
                        formik.values.hubCentreId = centrInfo.HubCentreId;
                        formik.values.businessLabId = centrInfo.BusinessLabId;
                        formik.values.creditIntimateLimit = centrInfo.CreditIntimationLimit;
                        formik.values.creditReportLimit = centrInfo.CreditReportingLimit;
                        formik.values.creditBookingLimit = centrInfo.CreditBookingLimit;
                        formik.values.creditDays = centrInfo.CreditDays;
                        formik.values.billingCycleId = centrInfo.BillingCycleId;
                        formik.values.CompanyNameId = centrInfo.CompanyId;
                        if (centrInfo.WorkingFromTime !== "00:00:00") { formik.values.fromtime = centrInfo.WorkingFromTime; }
                        if (centrInfo.WorkingToTime !== "00:00:00") { formik.values.totime = centrInfo.WorkingToTime; }
                        if (centrInfo.SundayFromTime !== "00:00:00") { formik.values.sundayfromtime = centrInfo.SundayFromTime; }
                        if (centrInfo.SundayToTime !== "00:00:00") { formik.values.sundaytotime = centrInfo.SundayToTime; }
                        if (centrInfo.GoogleProLink !== "") { formik.values.googleprolink = centrInfo.GoogleProLink; }
                        if (centrInfo.Summary !== "") { formik.values.summary = centrInfo.Summary; }
                        formik.values.wrkngfrmday = centrInfo.WorkingFromDay;
                        formik.values.wrkngtoday = centrInfo.WorkingToDay;
                        let hideBkngAmount = false; if (centrInfo.HideAmountInBooking === "True") { hideBkngAmount = true; }
                        let showBalnceAmt = false; if (centrInfo.ShowBalanceAmount === "True") { showBalnceAmt = true; }
                        let hideRecptRate = false; if (centrInfo.HideReceiptRate === "True") { hideRecptRate = true; }
                        let btchReqrd = false; if (centrInfo.BatchRequired === "True") { btchReqrd = true; }
                        let mrpBill = false; if (centrInfo.ShowMRPBill === "True") { mrpBill = true; }
                        let prntDeptSlip = false; if (centrInfo.PrintDepartmentSlip === "True") { prntDeptSlip = true; }
                        let showPRO = false; if (centrInfo.ShowPROInRegistration === "True") { showPRO = true; }
                        let centreDtailOnRprt = false; if (centrInfo.ShowCentreDetailOnReport === "True") { centreDtailOnRprt = true; }
                        let hideFootr = false; if (centrInfo.HideFooterOnWithoutLetterheadReport === "True") { hideFootr = true; }
                        let hidebillstat = false; if (centrInfo.HideBillingStationery === "True") { hidebillstat = true; }
                        let centreStatus = false; if (centrInfo.IsActive === "True") { centreStatus = true; }
                        let _isProcessingLab = false; if (centrInfo.IsProcessingLab === "True") { _isProcessingLab = true; }
                        let _isSubCentre = false; if (centrInfo.IsSubCentre === "True") { _isSubCentre = true; }
                        let _otherLabRefNo = false; if (centrInfo.OtherLabRefNo === "True") { _otherLabRefNo = true; }
                        let _isHLMParty = false; if (centrInfo.IsHLMParty === "True") { _isHLMParty = true; }
                        let _isAmbulance = false; if (centrInfo.IsAmbulance.toLowerCase() === "available") { _isAmbulance = true; }
                        formik.values.isAmbulanceAvailable = _isAmbulance;
                        setIsAmbulance(_isAmbulance);
                        formik.values.otherLabRefNo = _otherLabRefNo;
                        formik.values.isProcessingLab = _isProcessingLab;
                        formik.values.isSubCentre = _isSubCentre;
                        formik.values.isHLMParty = _isHLMParty;
                        formik.values.hideBookingAmount = hideBkngAmount;
                        formik.values.showBalanceAmount = showBalnceAmt;
                        formik.values.hideReceiptRate = hideRecptRate;
                        formik.values.batchRequired = btchReqrd;
                        formik.values.showMRPBill = mrpBill;
                        formik.values.printDepartmentSlip = prntDeptSlip;
                        formik.values.showPROInRegistration = showPRO;
                        formik.values.showCentreDetailOnReport = centreDtailOnRprt;
                        formik.values.hideFooterOnWithoutLetterheadReport = hideFootr;
                        formik.values.hidebillingstationery = hidebillstat;
                        formik.values.isActive = centreStatus;
                        formik.values.centreEmail = centrInfo.EmailId;
                        formik.values.centreContactNo = centrInfo.ContactNo;
                        formik.values.warehouseCode = centrInfo.WarehouseCode;
                        formik.values.radiologyWHCode = centrInfo.RadiologyWHCode;
                        formik.values.pathcostcode1 = centrInfo.PathologyCostCode1;
                        formik.values.pathcostcode2 = centrInfo.PathologyCostCode2;
                        formik.values.pathcostcode3 = centrInfo.PathologyCostCode3;
                        formik.values.radcostcode1 = centrInfo.RadiologyCostCode1;
                        formik.values.radcostcode2 = centrInfo.RadiologyCostCode2;
                        formik.values.radcostcode3 = centrInfo.RadiologyCostCode3;
                        formik.values.nmWHCode = centrInfo.NuclearMedicineWarehouseCode;
                        formik.values.nmcostcode1 = centrInfo.NuclearMedicineCostCode1;
                        formik.values.nmcostcode2 = centrInfo.NuclearMedicineCostCode2;
                        formik.values.nmcostcode3 = centrInfo.NuclearMedicineCostCode3;
                        formik.values.websiteappointmentemail = centrInfo.WebsiteAppointmentEmail;
                        formik.values.sapSeries = centrInfo.SAPSeries;
                        setSelectBLabTitle('---Select---');
                        if (centrInfo.CentreType === "1") {
                            setIsLab(_isProcessingLab);
                            if (_isProcessingLab === true) { setSelectTitle('---Self---'); } else { setSelectTitle('---Select---'); }
                            setProcessingLabDisabled(_isProcessingLab);
                            setOwnCentreType(true);
                            setDisableWorkTYpe(false);
                        }
                        if (centrInfo.CentreType === "2") {
                            setIsSubCentre(_isSubCentre);
                            setIsTPCentreType(true);
                            GetPartners(centrInfo.ParentCentreType)
                            if (_isSubCentre) { setDisableCreditSetting(true); }
                            else { setDisableCreditSetting(false); }
                            formik.values.workingType = "1";
                            setDisableWorkTYpe(true);
                        }
                        if (centrInfo.CentreType === "3") {
                            setIsPanel(true);
                            // setProcessingLabsList([]);
                            // setBusinessLabsList([]);
                            setSelectTitle('---Select---');
                            setSelectBLabTitle('---Select---');
                            setShowPanelFields(true);
                            setViewPanelDocuments(centrInfo.paneldocuments)
                        }
                        glbContactPersons = [];
                        for (var i = 0; i < centrInfo.ContactPersons.length; i++) {
                            var cpInfo = centrInfo.ContactPersons[i];
                            var isCentreAdmin = "No";
                            var cpAdmin = "0";
                            if (cpInfo.CentreAdmin === "True") { isCentreAdmin = "Yes"; cpAdmin = "1"; }
                            glbContactPersons.push({
                                Id: cpInfo.Id, Name: cpInfo.Name, Email: cpInfo.Email, Desigantion: cpInfo.Desigantion,
                                ContactNo: cpInfo.ContactNo, AlternateContactNo: cpInfo.AlternateContactNo, CentreAdmin: cpAdmin, IsCentreAdmin: isCentreAdmin
                            });
                        }
                        if (formik.values.partnerType === "3") { setDisplayCenterType("0"); } else { setDisplayCenterType(formik.values.centreType); }

                        setSalesPersonSelected(centrInfo.SalesPerson);
                        setSalesManagerSelected(centrInfo.SalesManager);
                        setSalesHeadSelected(centrInfo.SalesHead);
                        setTypeSelected(centrInfo.BankId);
                        setIsLoading(false);
                        formik.values.stardusName = centrInfo.StardusName;
                        formik.values.stardusCustomerId = centrInfo.StardusCustomerId;
                        formik.values.stardusKeyId = centrInfo.StardusKeyId;
                        formik.values.stardusUrl = centrInfo.StardusUrl;
                        formik.values.stardusSecretKey = centrInfo.StardusSecretKey;
                        formik.values.centreBanner = centrInfo.CentreBanner;
                        formik.values.maxDiscountLimit = centrInfo.MaxDiscountLimit;
                        const finalfromdate = Moment(centrInfo.ContractFromDate).format('YYYY-MM-DD');
                        const finaltodate = Moment(centrInfo.ContractToDate).format('YYYY-MM-DD');
                        formik.values.fromDate = finalfromdate;
                        formik.values.toDate = finaltodate;
                        //formik.values.biotitle = centrInfo.BioWasteTitle;
                        // formik.values.bioheader = centrInfo.BioWasteHeader;
                        // formik.values.biodescription = centrInfo.BioWasteDescription;
                        // if (centrInfo.BioWasteDoc !== "") {
                        //     setBWCPath(Image_Path + "BioWasteCompliance/" + centrInfo.BioWasteDoc);
                        //     formik.values.bwcpath = centrInfo.BioWasteDoc;
                        //     var element = document.querySelector("input[type='file']")
                        //     element.classList.add("removeValue");
                        //     document.getElementById("showpdficon").style.display = "block";
                        // }
                        // else { setBWCPath(Image_Path + "BioWasteCompliance/NoImage.png"); formik.values.bwcpath = ""; }
                        if (centrInfo.biowastedocuments !== null) {
                            const bioarr = centrInfo.biowastedocuments;
                            var biowasteArray = [];
                            for (var key in bioarr) {
                                var id = Math.random().toString(36).slice(2);
                                biowasteArray.push({
                                    DocumentId: id,
                                    BioWasteHeader: bioarr[key].BioWasteHeader,
                                    BioWasteDescription: bioarr[key].BioWasteDescription,
                                    BioWasteDoc: bioarr[key].BioWasteDoc
                                });
                            }
                            setViewBioWasteDocuments(biowasteArray);
                        }
                        else { setViewBioWasteDocuments([]); }

                        if (centrInfo.CentreBanner !== "") {
                            setBannerPath(Image_Path + "CentreImage/" + centrInfo.CentreBanner);
                            formik.values.centreBanner = centrInfo.CentreBanner;
                        }
                        else { setBannerPath(Image_Path + "CentreImage/NoImage.png"); formik.values.centreBanner = ""; }
                    }
                }).catch(function (error) { setIsLoading(false); });
            }
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); setIsLoading(false); }
        setCPDataTable(glbContactPersons);
    }
    const SAPStateChangeHandler = (e) => {
        formik.values.sapRegionId = "0";
        formik.values.sapDistrictId = "0";
        formik.values.isSubCentre = false;
        if (e.target.value !== "0") { GetSAPRegions(e.target.value); } else { setSAPRegionsList([]); }
        setSAPDistrictsList([]);
    }
    const SAPRegionChangeHandler = (e) => {
        formik.values.sapDistrictId = "0";
        if (e.target.value !== "0") { GetSAPDistricts(e.target.value); } else { setSAPDistrictsList([]); }
    }
    const formik = useFormik({
        initialValues: {
            centreId: '0', centreType: '1', partnerType: '', workingType: '1', centreName: '', uhidAbbreviation: '', sapCode: '', sapStateId: '', sapRegionId: '',
            sapDistrictId: '', pinCode: '', centreAddress: '', latitude: '', longitude: '', paymentMode: '', gstinNo: '', bankId: '', accountNo: '', ifscCode: '',
            accountName: '', panNo: '', processingLabId: '', businessLabId: '', creditIntimateLimit: '0.00', creditReportLimit: '0.00', creditBookingLimit: '0.00',
            creditDays: '0', billingCycleId: '', hideBookingAmount: false, showBalanceAmount: false, hideReceiptRate: false, batchRequired: false, showMRPBill: false,
            printDepartmentSlip: false, showPROInRegistration: false, showCentreDetailOnReport: false, hideFooterOnWithoutLetterheadReport: false, isActive: true,
            salesPerson: '', salesManager: '', salesHead: '', otherLabRefNo: '', invoiceEmail: '', reportEmail: '', minBusinessCommit: '0.00', invoiceTo: '', minBookingCash: '0',
            isProcessingLab: '', labType: '', isSubCentre: '', parentCentreType: '', parentCentreId: '', virtualAccount: '', stardusName: '', stardusCustomerId: '', stardusKeyId: '',
            stardusUrl: '', stardusSecretKey: '', centreEmail: '', centreContactNo: '', warehouseCode: '', radiologyWHCode: '', sapSeries: '', centreBanner: '', maxDiscountLimit: "0.00", isHLMParty: '',
            fromtime: '', totime: '', wrkngfrmday: '', wrkngtoday: '', isAmbulanceAvailable: '', bioheader: '', biodescription: '', bwcpath: '', CompanyNameId: '', fromDate: '', toDate: '', websiteappointmentemail: '', sapNewCode: '', branchid: '', pathcostcode1: '',
            pathcostcode2: '', pathcostcode3: '', radcostcode1: '', radcostcode2: '', radcostcode3: '', hidebillingstationery: false, nmWHCode: '', nmcostcode1: '', nmcostcode2: '', nmcostcode3: ''
        },
        validationSchema: Yup.object({
            partnerType: showpanelfields === true ? Yup.string().required('Panel type is required') : Yup.string().required('Partner type is required'),
            centreName: Yup.string().max(100, 'Centre name must be in 100 characters or less').required('Centre name is required'),
            uhidAbbreviation: Yup.string().required('UHID abbreviation is required'),
            sapCode: Yup.string().required('Centre code is required'),
            sapStateId: Yup.string().required('SAP state is required'),
            sapRegionId: Yup.string().required('SAP region is required'),
            sapDistrictId: Yup.string().required('SAP district is required'),
            pinCode: Yup.string().typeError('Pincode  must be a number').required('Pincode is required'),
            centreAddress: Yup.string().required('Address is required'),
            creditIntimateLimit: Yup.number().typeError('Credit intimate limit must be number only'),
            creditReportLimit: Yup.number().typeError('Credit report limit must be number only'),
            creditBookingLimit: Yup.number().typeError('Credit booking limit must be number only'),
            creditDays: Yup.number().min(0, 'Credit days must be zero or more than zero').typeError('Credit days must be number only'),
            minBusinessCommit: Yup.number().typeError('Value must be number only'),
            minBookingCash: Yup.number().max(100, 'Value can not be greater than 100 percent').typeError('Value must be number only'),
            virtualAccount: Yup.string().required('virtual account is required'),
            invoiceEmail: Yup.string().email('Invalid email address'),
            reportEmail: Yup.string().email('Invalid email address'),
            centreEmail: Yup.string().email('Invalid email address').required('Centre email is required'),
            centreContactNo: Yup.string().max(10, 'Contact no must be  in 10 digits').min(10, 'Contact no must be  in 10 digits').required('Centre contact no. is required'),
            sapSeries: Yup.number().typeError('SAP series must be number only'),
            maxDiscountLimit: Yup.number().max(100, 'Value can not be greater than 100 percent').typeError('Value must be number only'),
            CompanyNameId: Yup.string().required('Company name is required'),
            websiteappointmentemail: Yup.string().email('Invalid email address'),
            sapNewCode: Yup.string().required('New centre code is required')
            //branchid: Yup.string().required('Branch id is required')
        }),
        onSubmit: values => {
            setModal(false);
            setErrorModal(false);
            AddCentre(values);
        },
    });
    function validateLatLng(lat, lng) {
        let pattern = new RegExp('^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}');
        return pattern.test(lat) && pattern.test(lng);
    }
    const AddCentre = async (values) => {
        // if ((values.bioheader !== "" || values.biodescription !== "" || values.bwcpath !== "") && values.biotitle === "") {
        //     SweetAlert.fire({ title: "Validation Failed!", text: "Title is mandatory.", icon: "error" });
        //     return false;
        // }
        if (values.uhidAbbreviation == "N/A" || values.uhidAbbreviation == "N / A" || values.uhidAbbreviation == "NA" || values.uhidAbbreviation == "N-A" || values.uhidAbbreviation == "N_A") {
            SweetAlert.fire("UHID Abbreviation cannot be NA");
            return false;
        }
        if (values.sapCode == "N/A" || values.sapCode == "N / A" || values.sapCode == "NA" || values.sapCode == "N-A" || values.sapCode == "N_A") {
            SweetAlert.fire("SAP code cannot be NA");
            return false;
        }
        if (values.centreName == "N/A" || values.centreName == "N / A" || values.centreName == "NA" || values.centreName == "N-A" || values.centreName == "N_A") {
            SweetAlert.fire("Centre name cannot be NA");
            return false;
        }
        if (values.sapNewCode == "N/A" || values.sapNewCode == "N / A" || values.sapNewCode == "NA" || values.sapNewCode == "N-A" || values.sapNewCode == "N_A") {
            SweetAlert.fire("New SAP Code cannot be NA");
            return false;
        }
        if (values.branchid == "N/A" || values.branchid == "N / A" || values.branchid == "NA" || values.branchid == "N-A" || values.branchid == "N_A") {
            SweetAlert.fire("Branch id cannot be NA");
            return false;
        }
        if (values.bwcpath !== "") {
            if (invalidfiletype === true) { SweetAlert.fire({ title: "Validation Failed!", text: "Please upload valid file type.", icon: "error" }); return false; }
        }
        if ((values.centreType == "1" || values.centreType == "2")) {
            if ((values.warehouseCode == null || values.warehouseCode == undefined || values.warehouseCode == "")) {
                if ((values.pathcostcode1 == null || values.pathcostcode1 == undefined || values.pathcostcode1 == "") &&
                    (values.pathcostcode2 == null || values.pathcostcode2 == undefined || values.pathcostcode2 == "") &&
                    (values.pathcostcode3 == null || values.pathcostcode3 == undefined || values.pathcostcode3 == "")) { }
                else { SweetAlert.fire("Pathology ware house code is mandatory"); return false; }
            }
            else {
                if (values.pathcostcode1 == null || values.pathcostcode1 == undefined || values.pathcostcode1 == "") {
                    SweetAlert.fire("Pathology cost code 1 is mandatory"); return false;
                }
                if (values.pathcostcode2 == null || values.pathcostcode2 == undefined || values.pathcostcode2 == "") {
                    SweetAlert.fire("Pathology cost code 2 is mandatory"); return false;
                }
                if (values.pathcostcode3 == null || values.pathcostcode3 == undefined || values.pathcostcode3 == "") {
                    SweetAlert.fire("Pathology cost code 3 is mandatory"); return false;
                }
            }
            if ((values.radiologyWHCode == null || values.radiologyWHCode == undefined || values.radiologyWHCode == "")) {
                if ((values.radcostcode1 == null || values.radcostcode1 == undefined || values.radcostcode1 == "") &&
                    (values.radcostcode2 == null || values.radcostcode2 == undefined || values.radcostcode2 == "") &&
                    (values.radcostcode3 == null || values.radcostcode3 == undefined || values.radcostcode3 == "")) { }
                else { SweetAlert.fire("Radiology ware house code is mandatory"); return false; }
            }
            else {
                if (values.radcostcode1 == null || values.radcostcode1 == undefined || values.radcostcode1 == "") {
                    SweetAlert.fire("Radiology cost code 1 is mandatory"); return false;
                }
                if (values.radcostcode2 == null || values.radcostcode2 == undefined || values.radcostcode2 == "") {
                    SweetAlert.fire("Radiology cost code 2 is mandatory"); return false;
                }
                if (values.radcostcode3 == null || values.radcostcode3 == undefined || values.radcostcode3 == "") {
                    SweetAlert.fire("Radiology cost code 3 is mandatory"); return false;
                }
            }
            if ((values.nmWHCode == null || values.nmWHCode == undefined || values.nmWHCode == "")) {
                if ((values.nmcostcode1 == null || values.nmcostcode1 == undefined || values.nmcostcode1 == "") &&
                    (values.nmcostcode2 == null || values.nmcostcode2 == undefined || values.nmcostcode2 == "") &&
                    (values.nmcostcode3 == null || values.nmcostcode3 == undefined || values.nmcostcode3 == "")) { }
                else { SweetAlert.fire("Nuclear medicine ware house code is mandatory"); return false; }
            }
            else {
                if (values.nmcostcode1 == null || values.nmcostcode1 == undefined || values.nmcostcode1 == "") {
                    SweetAlert.fire("Nuclear medicine cost code 1 is mandatory"); return false;
                }
                if (values.nmcostcode2 == null || values.nmcostcode2 == undefined || values.nmcostcode2 == "") {
                    SweetAlert.fire("Nuclear medicine cost code 2 is mandatory"); return false;
                }
                if (values.nmcostcode3 == null || values.nmcostcode3 == undefined || values.nmcostcode3 == "") {
                    SweetAlert.fire("Nuclear medicine cost code 3 is mandatory"); return false;
                }
            }
        }
        var wft = "";
        var wtt = "";
        var wfd = "";
        var wtd = "";
        if (iscentrevisible === true) {
            if (values.centreBanner === '') { SweetAlert.fire({ title: "Validation Failed!", text: "Please select centre image.", icon: "error" }); return false; }
            else if (invalidfiletype === true) { SweetAlert.fire({ title: "Validation Failed!", text: "Please upload valid file type.", icon: "error" }); return false; }
            else if (values.fromtime === '') { SweetAlert.fire({ title: "Validation Failed!", text: "Please select working from time.", icon: "error" }); return false; }
            else if (values.totime === '') { SweetAlert.fire({ title: "Validation Failed!", text: "Please select working to time.", icon: "error" }); return false; }
            else if (values.wrkngfrmday === '') { SweetAlert.fire({ title: "Validation Failed!", text: "Please select working from day.", icon: "error" }); return false; }
            else if (values.wrkngtoday === '') { SweetAlert.fire({ title: "Validation Failed!", text: "Please select working to day.", icon: "error" }); return false; }
            else if (values.latitude === '' && values.longitude === '') { SweetAlert.fire({ title: "Validation Failed!", text: "Please enter latitude and longitude.", icon: "error" }); return false; }
            else if (values.summary === '') { SweetAlert.fire({ title: "Validation Failed!", text: "Please enter summary", icon: "error" }); return false; }
            else if (values.fromtime === values.totime) { SweetAlert.fire({ title: "Validation Failed!", text: "Working from time and working to time cannot be same", icon: "error" }); return false; }
            else {
                wft = values.fromtime;
                wtt = values.totime;
                wfd = values.wrkngfrmday;
                wtd = values.wrkngtoday;
            }
            if ((values.sundayfromtime === '' || values.sundayfromtime === undefined) && (values.sundaytotime !== '')) {
                SweetAlert.fire({ title: "Validation Failed!", text: "Please select sunday working from time", icon: "error" });
                return false;
            }
            if ((values.sundaytotime === '' || values.sundaytotime === undefined) && (values.sundayfromtime !== '')) {
                SweetAlert.fire({ title: "Validation Failed!", text: "Please select sunday working to time", icon: "error" });
                return false;
            }
        }
        else {
            wfd = "";
            wtd = "";
        }
        if ((values.billingCycleId == "0" || values.billingCycleId == "") && (values.centreType == "2" || values.centreType == "3")) {
            SweetAlert.fire("Billing cycle is mandatory");
            return false;
        }
        if ((values.centreType === "1" && (values.isProcessingLab === false || values.isProcessingLab === "0")) || values.centreType === "2" || values.centreType === "3") {
            if (values.processingLabId === '' || values.processingLabId === "0") { SweetAlert.fire({ title: "Validation Failed!", text: "Please select tag processing lab.", icon: "error" }); return false; }
            // else if (values.businessLabId === '' || values.businessLabId === "0") { SweetAlert.fire({ title: "Validation Failed!", text: "Please select business lab.", icon: "error" }); return false; }
        }
        if (values.latitude !== "" && values.longitude === "") { SweetAlert.fire({ title: "Validation Failed!", text: "Please enter longitude", icon: "error" }); return false; }
        if (values.longitude !== "" && values.latitude === "") { SweetAlert.fire({ title: "Validation Failed!", text: "Please enter latitude", icon: "error" }); return false; }
        if (values.latitude !== '' && values.longitude !== '') {
            if (validateLatLng(values.latitude, values.longitude) === false) { SweetAlert.fire({ title: "Validation Failed!", text: "Please enter valid latitude or longitude", icon: "error" }); return false; }
        }
        if (glbContactPersons.length > 0) {
            if (values.centreType === "2") {
                if (values.processingLabId === "0") { SweetAlert.fire({ title: "Validation Failed!", text: "Please select processing lab.", icon: "error" }); return false; }
                else if (values.businessLabId === "0") { SweetAlert.fire({ title: "Validation Failed!", text: "Please select business lab.", icon: "error" }); return false; }
                var idAdmin = false;
                for (var i = 0; i < glbContactPersons.length; i++) { if (glbContactPersons[i].CentreAdmin) { idAdmin = true; break; } }
                if (idAdmin === false) { SweetAlert.fire({ title: "Validation Failed!", text: "Please select atleast one centre admin.", icon: "error" }); }
            }
            if (values.centreType === "3") {
                if (values.businessLabId === '' || values.businessLabId === "0") { SweetAlert.fire({ title: "Validation Failed!", text: "Please select business lab.", icon: "error" }); return false; }
            }
            let hideBkngAmount = "0"; if (values.hideBookingAmount === true) { hideBkngAmount = "1"; }
            let showBalnceAmt = "0"; if (values.showBalanceAmount === true) { showBalnceAmt = "1"; }
            let hideRecptRate = "0"; if (values.hideReceiptRate === true) { hideRecptRate = "1"; }
            let btchReqrd = "0"; if (values.batchRequired === true) { btchReqrd = "1"; }
            let mrpBill = "0"; if (values.showMRPBill === true) { mrpBill = "1"; }
            let prntDeptSlip = "0"; if (values.printDepartmentSlip === true) { prntDeptSlip = "1"; }
            let showPRO = "0"; if (values.showPROInRegistration === true) { showPRO = "1"; }
            let centreDtailOnRprt = "0"; if (values.showCentreDetailOnReport === true) { centreDtailOnRprt = "1"; }
            let hideFootr = "0"; if (values.hideFooterOnWithoutLetterheadReport === true) { hideFootr = "1"; }
            let hidebillstat = "0"; if (values.hidebillingstationery === true) { hidebillstat = "1"; }
            let centreStatus = "0"; if (values.isActive === true) { centreStatus = "1"; }
            let billingCycleId = "0"; if (values.billingCycleId !== "") { billingCycleId = values.billingCycleId; }
            let invoiceTo = "0"; if (values.invoiceTo !== "") { invoiceTo = values.invoiceTo; }
            let isProcessingLab = "0"; if (values.isProcessingLab === true) { isProcessingLab = "1"; }
            let isSubCentre = "0"; if (values.isSubCentre === true) { isSubCentre = "1"; }
            let otherLabRefNo = "0"; if (values.otherLabRefNo === true) { otherLabRefNo = "1"; }
            // if (values.centreType === "1") {
            //     if (isProcessingLab === "0" && values.processingLabId === "0") { SweetAlert.fire({ title: "Validation Failed!", text: "Please select processing lab.", icon: "error" }); return; }
            // }
            let maxDiscountLimit = "0"; if (values.maxDiscountLimit !== "") { maxDiscountLimit = values.maxDiscountLimit; }
            let isHLMParty = "0"; if (values.isHLMParty === true) { isHLMParty = "1"; }
            setIsSaving(true);
            const requestOptions = {
                CentreId: values.centreId,
                CentreType: values.centreType,
                PartnerType: values.partnerType,
                WorkingType: values.workingType === "" ? "0" : values.workingType,
                CentreName: values.centreName,
                UHIDAbbreviation: values.uhidAbbreviation,
                SAPCode: values.sapCode,
                SAPStateId: values.sapStateId,
                SAPRegionId: values.sapRegionId,
                SAPDistrictId: values.sapDistrictId,
                PinCode: values.pinCode,
                CentreAddress: values.centreAddress,
                Latitude: values.latitude,
                Longitude: values.longitude,
                IsProcessingLab: isProcessingLab,
                LabType: values.labType,
                IsSubCentre: isSubCentre,
                ParentCentreType: values.parentCentreType,
                ParentCentreId: values.parentCentreId,
                SalesPerson: values.salesPerson,
                SalesManager: values.salesManager,
                SalesHead: values.salesHead,
                PaymentModeId: values.paymentMode,
                OtherLabRefNo: otherLabRefNo,
                InvoiceEmail: values.invoiceEmail,
                ReportEmail: values.reportEmail,
                MinBusinessCommit: values.minBusinessCommit,
                GSTIN: values.gstinNo,
                BankId: values.bankId,
                AccountNo: values.accountNo,
                IFSCCode: values.ifscCode,
                AccountName: values.accountName,
                PAN: values.panNo,
                VirtualAccount: values.virtualAccount,
                InvoiceTo: invoiceTo,
                MinBookingCash: values.minBookingCash,
                ProcessingLabId: values.processingLabId,
                BusinessLabId: values.businessLabId,
                CreditIntimationLimit: values.creditIntimateLimit,
                CreditReportingLimit: values.creditReportLimit,
                CreditBookingLimit: values.creditBookingLimit,
                CreditDays: values.creditDays,
                BillingCycleId: billingCycleId,
                HideAmountInBooking: hideBkngAmount,
                ShowBalanceAmount: showBalnceAmt,
                HideReceiptRate: hideRecptRate,
                BatchRequired: btchReqrd,
                ShowMRPBill: mrpBill,
                PrintDepartmentSlip: prntDeptSlip,
                ShowPROInRegistration: showPRO,
                ShowCentreDetailOnReport: centreDtailOnRprt,
                HideFooterOnWithoutLetterheadReport: hideFootr,
                HideBillingStationery: hidebillstat,
                IsActive: centreStatus,
                AddedBy: localStorage.LoggedInUser,
                PanelDocs: JSON.stringify(viewpaneldocuments),
                ContactPerson: JSON.stringify(glbContactPersons),
                StardusName: values.stardusName,
                StardusCustomerId: values.stardusCustomerId,
                StardusKeyId: values.stardusKeyId,
                StardusUrl: values.stardusUrl,
                StardusSecretKey: values.stardusSecretKey,
                ContactNo: values.centreContactNo,
                EmailId: values.centreEmail,
                WarehouseCode: values.warehouseCode,
                RadiologyWHCode: values.radiologyWHCode,
                SAPSeries: values.sapSeries,
                CentreBanner: values.centreBanner,
                MaxDiscountLimit: maxDiscountLimit,
                IsHLMParty: isHLMParty,
                WorkingFromTime: wft,
                WorkingToTime: wtt,
                WorkingFromDay: wfd,
                WorkingToDay: wtd,
                CompanyId: values.CompanyNameId,
                IsAmbulance: isambulance === "" ? "false" : isambulance.toString(),
                BioWasteDocs: JSON.stringify(viewbiowastedocuments),
                BioWasteTitle: values.CompanyNameId,
                // BioWasteHeader: values.bioheader,
                // BioWasteDescription: values.biodescription,
                // BioWasteDoc: values.bwcpath,
                SundayFromTime: values.sundayfromtime === undefined ? "" : values.sundayfromtime,
                SundayToTime: values.sundaytotime === undefined ? "" : values.sundaytotime,
                GoogleProLink: values.googleprolink === undefined ? "" : values.googleprolink,
                Summary: values.summary === undefined ? "" : values.summary,
                ContractFromDate: values.fromDate === undefined ? "" : values.fromDate,
                ContractToDate: values.toDate === undefined ? "" : values.toDate,
                websiteappointmentemail: (values.websiteappointmentemail === undefined || values.websiteappointmentemail === null) ? "" : values.websiteappointmentemail,
                NewSAPCode: values.sapNewCode,
                BranchId: values.branchid,
                PathologyCostCode1: (values.pathcostcode1 == null || values.pathcostcode1 == undefined) ? "" : values.pathcostcode1,
                PathologyCostCode2: (values.pathcostcode2 == null || values.pathcostcode2 == undefined) ? "" : values.pathcostcode2,
                PathologyCostCode3: (values.pathcostcode3 == null || values.pathcostcode3 == undefined) ? "" : values.pathcostcode3,
                RadiologyCostCode1: (values.radcostcode1 == null || values.radcostcode1 == undefined) ? "" : values.radcostcode1,
                RadiologyCostCode2: (values.radcostcode2 == null || values.radcostcode2 == undefined) ? "" : values.radcostcode2,
                RadiologyCostCode3: (values.radcostcode3 == null || values.radcostcode3 == undefined) ? "" : values.radcostcode3,
                NuclearMedicineWarehouseCode: (values.nmWHCode == null || values.nmWHCode == undefined) ? "" : values.nmWHCode,
                NuclearMedicineCostCode1: (values.nmcostcode1 == null || values.nmcostcode1 == undefined) ? "" : values.nmcostcode1,
                NuclearMedicineCostCode2: (values.nmcostcode2 == null || values.nmcostcode2 == undefined) ? "" : values.nmcostcode2,
                NuclearMedicineCostCode3: (values.nmcostcode3 == null || values.nmcostcode3 == undefined) ? "" : values.nmcostcode3
            };
            let url = URL_Start + 'master/addcentre'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                if (data.Success) {
                    SweetAlert.fire({ title: "Success", text: "Data saved successfully", icon: "success" });
                    glblArr = [];
                    glblBioWasteArr = [];
                    formik.resetForm();
                    localStorage.setItem('cId', data.Data);
                    history.push({ pathname: `${process.env.PUBLIC_URL}/master/centres`, search: `` });
                    window.open(`${process.env.PUBLIC_URL}/common/centredetailsprint`, "_blank");
                }
                else {
                    if (data.ErrorList[0].errorCode === "-2") { SweetAlert.fire({ title: "Centre Duplicate", text: "Centre already exist in selected district with same name.", icon: "error" }); }
                    else if (data.ErrorList[0].errorCode === "-4") { SweetAlert.fire({ title: "SAP Code Duplicate", text: "SAP/Customer Code already exist with another centre.", icon: "error" }); }
                    else if (data.ErrorList[0].errorCode === "-6") { SweetAlert.fire({ title: "New SAP Code Duplicate", text: "New SAP/Customer Code already exist with another centre.", icon: "error" }); }
                    else if (data.ErrorList[0].errorCode === "-7") { SweetAlert.fire({ title: "Branch Id Duplicate", text: "Branch Id already exist with another centre.", icon: "error" }); }
                    else if (data.ErrorList[0].errorCode === "-5") { SweetAlert.fire({ title: "UHID Abbreviation Duplicate", text: "UHID Abbreviation already exist with another centre.", icon: "error" }); }
                    else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); }
                    setIsSaving(false);
                }
            }).catch(function (error) { setIsSaving(false); });
        }
        else { SweetAlert.fire({ title: "Validation Failed!", text: "Please add atleast one contact person before continue", icon: "error" }); }
    }
    const cancelHandler = async (CentreId) => {
        localStorage.removeItem('cId');
        history.push({ pathname: `${process.env.PUBLIC_URL}/master/centres`, search: `` });
    }
    const handleRadioChange = async (e) => {
        formik.values.partnerType = "";
        ManageCentreTypeChange(e.target.value);
    }
    const ManageCentreTypeChange = (cType) => {
        GetPartnerTypes(cType, formik.values.partnerType);
        formik.values.centreType = cType;
        setSAPTitle("SAP Customer Code");
        setSAPNewTitle("New SAP Customer Code");
        setShowPanelFields(false);
        setDisplayCenterType(cType);
        formik.values.isProcessingLab = false;
        formik.values.isSubCentre = false;
        formik.values.isHLMParty = false;
        setDisablePaymentMode(true);
        setHideCentreAdmin(true);
        setSelectBLabTitle('---Select---');
        if (cType === "1") {
            formik.values.centreEmail = "healthcare@atulaya.com";
            formik.values.centreContactNo = "9779599499";
            setSAPTitle("SAP Branch Code");
            setSAPNewTitle("New SAP Branch Code");
            { formik.values.partnerType === "" ? ManageSettings("1") : ManageSettings(formik.values.partnerType) }
            setDisableWorkTYpe(false);
            if (formik.values.uhidAbbreviation === "N/A") { formik.values.uhidAbbreviation = ""; }
            // if (formik.values.partnerType == "1") { setSelectBLabTitle('---Self---'); } else { setSelectBLabTitle('---Select---'); }
        }
        else if (cType === "2") {
            formik.values.centreEmail = "";
            formik.values.centreContactNo = "";
            { formik.values.partnerType === "" ? ManageSettings("3") : ManageSettings(formik.values.partnerType) }
            setHideCentreAdmin(false);
            formik.values.workingType = "1";
            setDisableWorkTYpe(true);
            if (formik.values.uhidAbbreviation === "N/A") { formik.values.uhidAbbreviation = ""; }
            setSelectBLabTitle('---Select---');
        }
        else if (cType === "3") {
            formik.values.centreEmail = "healthcare@atulaya.com";
            formik.values.centreContactNo = "9779599499";
            if (formik.values.uhidAbbreviation === "N/A") { formik.values.uhidAbbreviation = ""; }
            //formik.values.uhidAbbreviation = "N/A";
            // formik.values.workingType = "N/A";
            setShowPanelFields(true)
            //setDisablePaymentMode(false);
            ManageSettings("0");
            setSelectBLabTitle('---Select---');
        }
    }
    const SavePanelDocument = async () => {
        var DocName = 0;
        if (dcname.current.value === "") { SweetAlert.fire("Document name required") }
        else if (dctype.current.value === "0") { SweetAlert.fire("Document type required") }
        else if (dctype.current.value === "3" && dcvalue.current.value === "") { SweetAlert.fire("Document value required") }
        else if (dctype.current.value === "4" && dcvalue.current.value === "") { SweetAlert.fire("Document value required") }
        else {
            if (dctype.current.value === "1") { DocName = "Text" } else if (dctype.current.value === "2") { DocName = "FileUpload" }
            else if (dctype.current.value === "3") { DocName = "DropDown" } else if (dctype.current.value === "4") { DocName = "CheckBox" } else { DocName = "CheckBox" }
            var id = Math.random().toString(36).slice(2)
            for (var key in viewpaneldocuments) {
                if (viewpaneldocuments[key].DocumentName === dcname.current.value && viewpaneldocuments[key].DocumentType === DocName) {
                    SweetAlert.fire("Document with same name and type already exist")
                    return false;
                }
            }
            dctype.current.value === "3" || dctype.current.value === "4" ?
                setViewPanelDocuments([...viewpaneldocuments, {
                    DocumentId: id,
                    DocumentName: dcname.current.value,
                    DocumentType: DocName,
                    DocumentValue: dcvalue.current.value
                }])
                :
                setViewPanelDocuments([...viewpaneldocuments, {
                    DocumentId: id,
                    DocumentName: dcname.current.value,
                    DocumentType: DocName,
                    DocumentValue: ""
                }])
            if (dctype.current.value === "3" || dctype.current.value === "4") {
                dcname.current.value = "";
                dctype.current.value = "0";
                dcvalue.current.value = "";
            } else {
                dcname.current.value = "";
                dctype.current.value = "0";
            }
        }
    }
    const SaveBioWasteDocument = async () => {
        var id = Math.random().toString(36).slice(2)
        if (formik.values.bwcpath === "") { SweetAlert.fire({ title: "Validation Failed!", text: "Please upload document upto 5 mb.", icon: "error" }); }
        else {
            setViewBioWasteDocuments([...viewbiowastedocuments, {
                DocumentId: id,
                BioWasteHeader: bheader.current.value,
                BioWasteDescription: bdescription.current.value,
                BioWasteDoc: formik.values.bwcpath//bwcpath
            }]);
        }
        formik.values.bioheader = "";
        formik.values.biodescription = "";
        setBWCPath("");
        var element = document.querySelector("input[type='file']")
        element.classList.add("removeValue");
    }
    const GetPincodeLocation = async (e) => {
        let text = e.target.value;
        let length = text.length;
        if (length === 6) {
            try {
                let url = URL_Start + 'master/getpincodelocations/' + e.target.value
                await axios.get(url).then((response) => {
                    const data = response.data;
                    if (data.Success) {
                        if (data != null) {
                            const pinCodeData = data.Data;
                            formik.values.sapStateId = pinCodeData[0].SAPStateId;
                            GetSAPRegions(formik.values.sapStateId);
                            formik.values.sapRegionId = pinCodeData[0].SAPRegionId;
                            GetSAPDistricts(formik.values.sapRegionId);
                            formik.values.sapDistrictId = pinCodeData[0].SAPDistrictId;
                        }
                        else {
                            formik.values.sapStateId = "";
                            formik.values.sapRegionId = "";
                            formik.values.sapDistrictId = "";
                        }
                    }
                    else {
                        formik.values.sapStateId = "";
                        formik.values.sapRegionId = "";
                        formik.values.sapDistrictId = "";
                    }
                }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
            }
            catch (error) {
                SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
            }
        }
        else {
            formik.values.sapStateId = "";
            formik.values.sapRegionId = "";
            formik.values.sapDistrictId = "";
        }
    }
    const FilterDocument = (DocId) => {
        glblArr = glblArr.filter((d) => d.DocumentId !== DocId);
        setViewPanelDocuments(glblArr);
    }
    const FilterBioWasteDocument = (DocId) => {
        glblBioWasteArr = glblBioWasteArr.filter((d) => d.DocumentId !== DocId);
        setViewBioWasteDocuments(glblBioWasteArr);
    }
    const cpFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <DeleteToolTip title="Delete" placement="top" id={`Delete${rowData.ContactNo}`} onClick={(e) => handleCPDelete(rowData.ContactNo)} />
        </React.Fragment>
    }
    const handleCPDelete = (contactNo) => {
        glbContactPersons = glbContactPersons.filter((d) => d.ContactNo !== contactNo);
        setCPDataTable(glbContactPersons);
    }
    const cpColumns = [
        { dataField: 'Name', text: 'Name', editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'Email', text: 'Email', editable: false },
        { dataField: 'ContactNo', text: 'Contact No', editable: false },
        { dataField: 'Desigantion', text: 'Desigantion', editable: false },
        { dataField: 'AlternateContactNo', text: 'Alternate Contact No', editable: false },
        { dataField: 'IsCentreAdmin', text: 'Centre Admin', editable: false },
        { dataField: 'actions', text: 'Actions', editable: false, formatter: cpFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    const cpFormik = useFormik({
        initialValues: { id: '0', cpName: '', cpEmail: '', cpContactNo: '', cpAlternateContactNo: '', cpDesigantion: '', cpAdmin: false },
        validationSchema: Yup.object({
            cpName: Yup.string().max(50, 'Name must be 100 characters or less').required('Name is required'),
            cpEmail: Yup.string().email('Invalid email address').required('Email is required'),
            cpContactNo: Yup.string().max(10, 'Contact No must be  in 10 digits').min(10, 'Contact No must be  in 10 digits').required('Contact No. is required'),
            cpAlternateContactNo: Yup.string().max(10, 'Contact No must be  in 10 digits').min(10, 'Contact No must be  in 10 digits')
        }),
        onSubmit: values => {
            var isValid = true;
            for (var i = 0; i < glbContactPersons.length; i++) {
                var cpInfo = glbContactPersons[i];
                if (cpInfo.Email === values.cpEmail) { SweetAlert.fire({ title: "Validation Failed!", text: "Email of contact person already exist!", icon: "error" }); isValid = false; break; }
                if (cpInfo.ContactNo === values.cpContactNo) { SweetAlert.fire({ title: "Validation Failed!", text: "Contact No of contact person already exist!", icon: "error" }); isValid = false; break; }
            }
            if (isValid === true) {
                var isCentreAdmin = "No";
                var cpAdmin = "0";
                if (values.cpAdmin === true) { isCentreAdmin = "Yes"; cpAdmin = "1"; }
                glbContactPersons.push({
                    Id: values.id, Name: values.cpName, Email: values.cpEmail, Desigantion: values.cpDesigantion,
                    ContactNo: values.cpContactNo, AlternateContactNo: values.cpAlternateContactNo, CentreAdmin: cpAdmin, IsCentreAdmin: isCentreAdmin
                });
                cpFormik.resetForm();
            }
            setCPDataTable(glbContactPersons);
        }
    });
    const GetSalesHierarchy = async () => {
        try {
            let url = URL_Start + 'master/getsaleshierarchy'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    var itmData = data.Data;
                    glbSalesHierarchy = [];
                    itmData.map((option) => { return glbSalesHierarchy.push({ 'value': option.Id, 'label': option.Value, 'disabled': false }); });
                }
            }).catch(function (error) { });
            setSalesPersons(glbSalesHierarchy);
            setSalesManagers(glbSalesHierarchy);
            setSalesHeads(glbSalesHierarchy);
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const SalesPersonsChangeHandler = (info) => {
        formik.values.salesPerson = info.value;
        setSalesPersonSelected(info.value);
    }
    const SalesManagersChangeHandler = (info) => {
        formik.values.salesManager = info.value;
        setSalesManagerSelected(info.value);
    }
    const SalesHeadsChangeHandler = (info) => {
        formik.values.salesHead = info.value;
        setSalesHeadSelected(info.value);
    }
    const handlePartnerTypeChange = async (e) => {
        formik.values.isSubCentre = false;
        formik.values.isHLMParty = false;
        setIsSubCentre(false);
        ManageSettings(e.target.value);
    }
    const ManageSettings = (partnerType) => {
        setHideAmountDisabled(false);
        setShowBalanceDisabled(false);
        setHideReciptRateDisabled(false);
        setDisablePaymentMode(true);
        setDisableCreditSetting(false);
        setDisableShowMrpBills(false);
        setDisablePrintDeptSlips(false);
        setDisableShowPROinReg(false);
        setDisableCentreType(false);
        setDisableShowDetailsOnReport(true);
        formik.values.hideBookingAmount = false;
        formik.values.showBalanceAmount = false;
        formik.values.hideReceiptRate = false;
        formik.values.batchRequired = false;
        formik.values.showMRPBill = false;
        formik.values.paymentMode = "0";
        formik.values.isActive = true;
        setSelectBLabTitle('---Select---');
        if (partnerType === "1") {
            formik.values.paymentMode = "1";
            setDisableCreditSetting(true);
            setDisableMinCashBooking(false);
            formik.values.showMRPBill = true;
            formik.values.printDepartmentSlip = true;
            formik.values.showPROInRegistration = true;
            formik.values.showCentreDetailOnReport = true;
            setDisableShowMrpBills(true);
            setDisablePrintDeptSlips(true);
            setDisableShowPROinReg(true);
            // setDisableCentreType(true);
            setDisableShowDetailsOnReport(true);
            setSelectBLabTitle('---Self---');
        }
        else if (partnerType === "2") {
            formik.values.paymentMode = "1";
            formik.values.showBalanceAmount = true;
            formik.values.batchRequired = true;
            setShowBalanceDisabled(true);
            setDisableCreditSetting(true);
            setDisableMinCashBooking(false);
        }
        else if (partnerType === "3" || partnerType === "4") {
            formik.values.hideBookingAmount = true;
            formik.values.showBalanceAmount = true;
            formik.values.hideReceiptRate = true;
            formik.values.batchRequired = true;
            formik.values.showMRPBill = true;
            formik.values.paymentMode = "2";
            setHideAmountDisabled(true);
            setShowBalanceDisabled(true);
            setHideReciptRateDisabled(true);
            // setDisableCreditSetting(true);
            setDisableMinCashBooking(true);
        }
        else if (partnerType === "5") {
            //setDisablePaymentMode(false);
            setShowBalanceDisabled(true);
            // setDisableCreditSetting(true);
            formik.values.paymentMode = "2";
            formik.values.showBalanceAmount = true;
            formik.values.batchRequired = true;
        }
        else if (partnerType === "0" || partnerType === "8" || partnerType === "9") {
            if (localStorage.getItem('cId') == null || localStorage.getItem('cId') == undefined || localStorage.getItem('cId') == "") {
                setDisablePaymentMode(false); //change to make available credit cash option in panel
            }
            formik.values.paymentMode = "2";
            setViewPanelDocuments([]);
        }
        if (partnerType === "3" || partnerType == "5") { setDisplayCenterType("0"); } else { setDisplayCenterType(formik.values.centreType); }
    }
    const handleIsLab = async (e) => {
        setIsLab(e.target.value);
        if (e.target.value === true) { setSelectTitle('---Self---'); } else { setSelectTitle('---Select---'); }
        setProcessingLabDisabled(e.target.value);
        formik.values.isProcessingLab = e.target.value;
    }
    const handleIsAmbulance = async (e) => {
        setIsAmbulance(e.target.value);
    }
    const handleIsCentreVisible = async (e) => {
        setIsCentreVisible(e.target.value);
    }
    const handleIsSubCentre = async (e) => {
        if (e.target.value === true) {
            if (formik.values.partnerType === "0" || formik.values.partnerType === "") {
                SweetAlert.fire({ title: "Validation Failed", text: "Please select partner type first", icon: "error" });
                document.getElementById("chktrgt").checked = false;
                return false;
            }
            if (formik.values.sapRegionId === "0" || formik.values.sapRegionId === "") {
                SweetAlert.fire({ title: "Validation Failed", text: "Please select SAP region first", icon: "error" });
                document.getElementById("chktrgt").checked = false;
                return false;
            }
            setInvoiceTypeTitle("----Select----");
            var tmpCreditCentreType = glbCreditCentreTypes;
            if (formik.values.partnerType === "4") {
                // tmpCreditCentreType = glbCreditCentreTypes.filter(function (x) { return x.Id === "3"; })
                tmpCreditCentreType = glbCreditCentreTypes.filter(function (x) { return x.Id !== "4"; })
            }
            else if (formik.values.partnerType === "5") {
                // tmpCreditCentreType = glbCreditCentreTypes.filter(function (x) { return x.Id !== "5"; })
                tmpCreditCentreType = glbCreditCentreTypes.filter(function (x) { return x.Id === "3"; })
            }
            setCreditCentreTypes(tmpCreditCentreType.map((d) => (<option key={d.Id} value={d.Id}>{d.Name}</option>)));
            setDisableCreditSetting(true);
        }
        else { setInvoiceTypeTitle("----Self----"); setCreditCentreTypes([]); setDisableCreditSetting(false); }
        setIsSubCentre(e.target.value);
        formik.values.isSubCentre = e.target.value;
    }
    const GetLabTypes = async () => {
        try {
            let url = URL_Start + 'master/getlabtypes'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setLabTypes(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const PartnerTypeChangeHandler = (e) => {
        formik.values.parentCentreId = "0";
        if (e.target.value !== "0") { GetPartners(e.target.value) } else { setPartnersList([]); }
    }
    const PartnersChangeHandler = (e) => { formik.values.invoiceTo = e.target.value; }
    const SAPCodeChangeHandler = (e) => { }
    const GetPartners = async (typId) => {
        try {
            let url = URL_Start + 'master/getpartners/' + typId + '/' + formik.values.sapRegionId;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setPartnersList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { setPartnersList([]); });
        }
        catch (error) { setPartnersList([]); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const handlePaymentModeChange = (e) => {
        if (e.target.value === "1") { setDisableCreditSetting(true); setDisableMinCashBooking(true); } else { setDisableCreditSetting(false); setDisableMinCashBooking(false); }
    }
    const CheckSAPCodeUHIDAbb = async (e, sType) => {
        let textCode = e.target.value;
        let length = textCode.length;
        let cId = localStorage.getItem('cId');
        if (cId == null) { cId = "0"; }
        if (sType === "1") { formik.values.virtualAccount = "ATUL" + textCode; }
        if ((textCode == "N/A" || textCode == "N / A" || textCode == "NA" || textCode == "N-A" || textCode == "N_A") && sType == "2") {
            formik.values.uhidAbbreviation = "";
            SweetAlert.fire("UHID Abbreviation cannot be NA");
            return false;
        }
        if ((textCode == "N/A" || textCode == "N / A" || textCode == "NA" || textCode == "N-A" || textCode == "N_A") && sType == "1") {
            formik.values.sapCode = "";
            SweetAlert.fire("SAP code cannot be NA");
            return false;
        }
        if (length > 2) {
            try {
                let url = URL_Start + 'master/checksapcodeuhidabb/' + textCode + '/' + cId + '/' + sType;
                await axios.get(url).then((response) => {
                    const data = response.data;
                    if (data.Success) {
                        var returnStatus = data.Data;
                        var retrnCentreName = returnStatus.CentreName;
                        var retrnStatus = parseInt(returnStatus.Status);
                        if (retrnStatus === 1) {
                            if (sType === "1") { SweetAlert.fire({ title: "Duplicate Code!", text: "Code '" + textCode + "' already exist for '" + retrnCentreName + "'", icon: "error" }); formik.values.sapCode = ""; }
                            else { SweetAlert.fire({ title: "Duplicate UHID Abbreviation!", text: "UHID Abbreviation '" + textCode + "' already exist for '" + retrnCentreName + "'", icon: "error" }); formik.values.uhidAbbreviation = ""; }
                        }
                    }
                    else { if (sType === "1") { formik.values.sapCode = ""; } else { formik.values.uhidAbbreviation = ""; } }
                }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
            }
            catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
        }
    }
    const UploadImages = async (e, folderName) => {
        const FileURL = e.target.files[0].name;
        const BreakURL = FileURL.lastIndexOf('.');
        const extension = FileURL.substring(BreakURL + 1);
        //const extension = e.target.files[0].name.split(".");
        if (folderName === "BioWasteCompliance") {
            if (extension.toLowerCase() === "pdf") {
                setInvalidFileType(false);
                var element = document.querySelector("input[type='file']")
                element.classList.remove("removeValue");
                document.getElementById("showpdficon").style.display = "none";
            }
            else {
                SweetAlert.fire("Invalid file type! Please select only pdf type.");
                setInvalidFileType(true);
                var element = document.querySelector("input[type='file']")
                element.classList.add("removeValue");
                document.getElementById("showpdficon").style.display = "none";
                return false;
            }
        }
        else {
            if (extension.toLowerCase() === "jpg" || extension.toLowerCase() === "jpeg" || extension.toLowerCase() === "png" || extension.toLowerCase() === "gif" || extension.toLowerCase() === "svg") { setInvalidFileType(false); }
            else { SweetAlert.fire("Invalid file type! Please select only image type."); setInvalidFileType(true); setBannerPath(''); return false; }
        }
        var base64 = await toBase64(e.target.files[0]);
        var base64img = base64.split("base64,");
        const formData = new FormData();
        formData.append('Image', base64img[1]);
        formData.append('FolderName', folderName);
        const randomImageId = Math.random();
        var imgName = randomImageId + folderName + "." + extension; //extension[extension.length - 1];
        formData.append('ImageName', imgName);
        let url = URL_Start + 'master/UploadImage/'
        await axios.post(url, formData, {
            headers: { "Content-type": "multipart/form-data" },
        }).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (folderName === "BioWasteCompliance") { formik.values.bwcpath = imgName; setBWCPath(Image_Path + folderName + "/" + imgName); }
                else { formik.values.centreBanner = imgName; setBannerPath(Image_Path + folderName + "/" + imgName); }
            } else { SweetAlert.fire({ title: "Upload failed", text: "Unable to upload document due to " + data.Data + ".Please retry again", icon: "error" }) }
        }).catch(function (error) { if (error.response) { } });
    }
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const handleKeypress = (e) => { e.preventDefault(); return false; }

    const TypeChangeHandler = (options) => { formik.values.bankId = options.value; setTypeSelected(options.value); }

    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Centre Master" parent="Master" />
            <Container fluid>
                {
                    isLoading ?
                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                        <>
                            <Form onSubmit={formik.handleSubmit}>
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <Card.Header className='pb-0'>
                                                <Card.Title>Basic Detail</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form.Row>
                                                    <Col lg={3} md={4}>
                                                        <Form.Label>Centre Type</Form.Label>
                                                        <Form.Group className='animate-chk m-checkbox-inline mt-15'>
                                                            <Form.Label for="centreType1">
                                                                <input class="radio_animated" type="radio" id='centreType1' name="rdo-ani" defaultChecked={isOwnCentreType} onChange={handleRadioChange} value='1' disabled={disableCentreType} />Own
                                                            </Form.Label>
                                                            <Form.Label for="centreType2">
                                                                <input class="radio_animated" type="radio" id='centreType2' name="rdo-ani" defaultChecked={isTPCentreType} onChange={handleRadioChange} value='2' disabled={disableCentreType} />Third Party
                                                            </Form.Label>
                                                            <Form.Label for="centreType3">
                                                                <input class="radio_animated" type="radio" id='centreType3' name="rdo-ani" defaultChecked={isPanel} onChange={handleRadioChange} value='3' disabled={disableCentreType} />Panel
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>
                                                                {showpanelfields ? "Panel Type" : "Partner Type"}<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control as="select" name="partnerType" onChangeCapture={handlePartnerTypeChange} disabled={disableCentreType} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.partnerType}>
                                                                <option value="">---Select---</option>
                                                                {partnerTypeList}
                                                            </Form.Control>
                                                            {formik.touched.partnerType && formik.errors.partnerType ? (<div className='text-required'>{formik.errors.partnerType}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    {
                                                        showpanelfields === false ?
                                                            <>
                                                                <Col lg={3} md={3}>
                                                                    <Form.Group>
                                                                        <Form.Label>Working Type<span className='text-required'>*</span></Form.Label>
                                                                        <Form.Control as="select" name="workingType" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.workingType} disabled={disableWorkTYpe}>
                                                                            <option value="1">Physical</option>
                                                                            <option value="2">Virtual</option>
                                                                        </Form.Control>
                                                                    </Form.Group>
                                                                </Col>
                                                            </>
                                                            : null
                                                    }
                                                    <Col lg={3} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>{showpanelfields ? "Panel Name" : "Centre Name"}<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type='text' name="centreName" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.centreName} />
                                                            {formik.touched.centreName && formik.errors.centreName ? (<div className='text-required'>{formik.errors.centreName}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    {/* {
                                                        showpanelfields === false ?
                                                            <> */}
                                                    <Col lg={3} md={3}>
                                                        <Form.Group>
                                                            <Form.Label>UHID Abbreviation<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type='text' maxLength={3} name="uhidAbbreviation" onChange={formik.handleChange} onBlur={formik.handleBlur}
                                                                value={formik.values.uhidAbbreviation} onKeyUp={(e) => CheckSAPCodeUHIDAbb(e, "2")} />
                                                            {formik.touched.uhidAbbreviation && formik.errors.uhidAbbreviation ? (<div className='text-required'>{formik.errors.uhidAbbreviation}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    {/* </>
                                                            : null
                                                    } */}
                                                    <Col lg={3} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>{showpanelfields ? "SAP Code" : sapTitle}<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type='text' name="sapCode" onChangeCapture={SAPCodeChangeHandler} onChange={formik.handleChange} onBlur={formik.handleBlur}
                                                                value={formik.values.sapCode} onKeyUp={(e) => CheckSAPCodeUHIDAbb(e, "1")} />
                                                            {formik.touched.sapCode && formik.errors.sapCode ? (<div className='text-required'>{formik.errors.sapCode}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>{showpanelfields ? "New SAP Code" : sapNewTitle}<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type='text' name="sapNewCode" onChangeCapture={SAPCodeChangeHandler} onChange={formik.handleChange} onBlur={formik.handleBlur}
                                                                value={formik.values.sapNewCode} />
                                                            {formik.touched.sapNewCode && formik.errors.sapNewCode ? (<div className='text-required'>{formik.errors.sapNewCode}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>BranchId</Form.Label>
                                                            <Form.Control type='text' name="branchid" onChange={formik.handleChange} onBlur={formik.handleBlur}
                                                                value={formik.values.branchid} />
                                                            {formik.touched.branchid && formik.errors.branchid ? (<div className='text-required'>{formik.errors.branchid}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>Pin Code<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="text" name="pinCode" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.pinCode} onKeyUp={(e) => GetPincodeLocation(e, "1")} />
                                                            {formik.touched.pinCode && formik.errors.pinCode ? (<div className='text-required'>{formik.errors.pinCode}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>SAP State<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control as="select" name="sapStateId" onChangeCapture={SAPStateChangeHandler} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.sapStateId}>
                                                                <option value="">---Select---</option>
                                                                {sapStatesList}
                                                            </Form.Control>
                                                            {formik.touched.sapStateId && formik.errors.sapStateId ? (<div className='text-required'>{formik.errors.sapStateId}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>SAP Region<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control as="select" name="sapRegionId" onChangeCapture={SAPRegionChangeHandler} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.sapRegionId}>
                                                                <option value="">---Select---</option>
                                                                {sapRegionsList}
                                                            </Form.Control>
                                                            {formik.touched.sapRegionId && formik.errors.sapRegionId ? (<div className='text-required'>{formik.errors.sapRegionId}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>SAP District<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control as="select" name="sapDistrictId" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.sapDistrictId}>
                                                                <option value="">---Select---</option>
                                                                {sapDistrictsList}
                                                            </Form.Control>
                                                            {formik.touched.sapDistrictId && formik.errors.sapDistrictId ? (<div className='text-required'>{formik.errors.sapDistrictId}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>Address<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="text" name="centreAddress" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.centreAddress} />
                                                            {formik.touched.centreAddress && formik.errors.centreAddress ? (<div className='text-required'>{formik.errors.centreAddress}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>Company Name<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control as="select" name="CompanyNameId" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.CompanyNameId}>
                                                                <option value="">---Select---</option>
                                                                {CompanyName}
                                                            </Form.Control>
                                                            {formik.touched.CompanyNameId && formik.errors.CompanyNameId ? (<div className='text-required'>{formik.errors.CompanyNameId}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    {
                                                        showpanelfields === false ?
                                                            <>
                                                                <Col lg={3} md={3}>
                                                                    <Form.Group>
                                                                        <Form.Label>Centre Email<span className='text-required'>*</span></Form.Label>
                                                                        <Form.Control type="email" name="centreEmail" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.centreEmail} />
                                                                        {formik.touched.centreEmail && formik.errors.centreEmail ? (<div className='text-required'>{formik.errors.centreEmail}</div>) : null}
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={3} md={3}>
                                                                    <Form.Group>
                                                                        <Form.Label>Centre Contact No<span className='text-required'>*</span></Form.Label>
                                                                        <Form.Control type="text" name="centreContactNo" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.centreContactNo} />
                                                                        {formik.touched.centreContactNo && formik.errors.centreContactNo ? (<div className='text-required'>{formik.errors.centreContactNo}</div>) : null}
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={3} md={3}>
                                                                    <Form.Group>
                                                                        <Form.Label>SAP Series</Form.Label>
                                                                        <Form.Control type="text" name="sapSeries" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.sapSeries} />
                                                                        {formik.touched.sapSeries && formik.errors.sapSeries ? (<div className='text-required'>{formik.errors.sapSeries}</div>) : null}
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={3} md={3}>
                                                                    <Form.Group>
                                                                        <Form.Label>Pathology Warehouse Code</Form.Label>
                                                                        <Form.Control type="text" name="warehouseCode" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.warehouseCode} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={3} md={3}>
                                                                    <Form.Group>
                                                                        <Form.Label>Pathology Cost Code 1</Form.Label>
                                                                        <Form.Control type="text" name="pathcostcode1" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.pathcostcode1} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={3} md={3}>
                                                                    <Form.Group>
                                                                        <Form.Label>Pathology Cost Code 2</Form.Label>
                                                                        <Form.Control type="text" name="pathcostcode2" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.pathcostcode2} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={3} md={3}>
                                                                    <Form.Group>
                                                                        <Form.Label>Pathology Cost Code 3</Form.Label>
                                                                        <Form.Control type="text" name="pathcostcode3" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.pathcostcode3} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={3} md={3}>
                                                                    <Form.Group>
                                                                        <Form.Label>Radiology Warehouse Code</Form.Label>
                                                                        <Form.Control type="text" name="radiologyWHCode" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.radiologyWHCode} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={3} md={3}>
                                                                    <Form.Group>
                                                                        <Form.Label>Radiology Cost Code 1</Form.Label>
                                                                        <Form.Control type="text" name="radcostcode1" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.radcostcode1} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={3} md={3}>
                                                                    <Form.Group>
                                                                        <Form.Label>Radiology Cost Code 2</Form.Label>
                                                                        <Form.Control type="text" name="radcostcode2" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.radcostcode2} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={3} md={3}>
                                                                    <Form.Group>
                                                                        <Form.Label>Radiology Cost Code 3</Form.Label>
                                                                        <Form.Control type="text" name="radcostcode3" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.radcostcode3} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={3} md={3}>
                                                                    <Form.Group>
                                                                        <Form.Label>Nuclear Medicine Warehouse Code</Form.Label>
                                                                        <Form.Control type="text" name="nmWHCode" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.nmWHCode} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={3} md={3}>
                                                                    <Form.Group>
                                                                        <Form.Label>Nuclear Medicine Cost Code 1</Form.Label>
                                                                        <Form.Control type="text" name="nmcostcode1" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.nmcostcode1} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={3} md={3}>
                                                                    <Form.Group>
                                                                        <Form.Label>Nuclear Medicine Cost Code 2</Form.Label>
                                                                        <Form.Control type="text" name="nmcostcode2" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.nmcostcode2} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={3} md={3}>
                                                                    <Form.Group>
                                                                        <Form.Label>Nuclear Medicine Cost Code 3</Form.Label>
                                                                        <Form.Control type="text" name="nmcostcode3" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.nmcostcode3} />
                                                                    </Form.Group>
                                                                </Col>
                                                                <Col lg={3} md={3}>
                                                                    <Form.Group>
                                                                        <Form.Label>Website Appointment Email</Form.Label>
                                                                        <Form.Control type="text" name="websiteappointmentemail" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.websiteappointmentemail} />
                                                                        {formik.touched.websiteappointmentemail && formik.errors.websiteappointmentemail ? (<div className='text-required'>{formik.errors.websiteappointmentemail}</div>) : null}
                                                                    </Form.Group>
                                                                </Col>
                                                            </>
                                                            : null
                                                    }
                                                    {displayCenterType === "1" ?
                                                        <>
                                                            <Col lg={3} md={3} style={{ marginTop: '35px' }}>
                                                                <Form.Group className='animate-chk m-checkbox-inline'>
                                                                    <Form.Label>
                                                                        <input name="isProcessingLab" className="checkbox_animated" type="checkbox" checked={formik.values.isProcessingLab} onChange={formik.handleChange}
                                                                            value={formik.values.isProcessingLab} onChangeCapture={(e) => { handleIsLab({ target: { value: e.target.checked } }); }} />
                                                                        Is this also a processing lab?
                                                                    </Form.Label>
                                                                </Form.Group>
                                                            </Col>
                                                            {isLab ? <Col lg={3} md={3}>
                                                                <Form.Group className='animate-chk m-checkbox-inline'>
                                                                    <Form.Label>Lab Type</Form.Label>
                                                                    <Form.Control as="select" name="labType" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.labType}>
                                                                        <option value="">----Select----</option>
                                                                        {labTypes}
                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </Col>
                                                                : null}
                                                        </>
                                                        : null
                                                    }
                                                    {formik.values.centreType === "2" ?
                                                        <>
                                                            <Col lg={3} md={3}>
                                                                <Form.Group>
                                                                    <Form.Label>Max Discount Limit(%)</Form.Label>
                                                                    <Form.Control type="text" name="maxDiscountLimit" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.maxDiscountLimit} />
                                                                    {formik.touched.maxDiscountLimit && formik.errors.maxDiscountLimit ? (<div className='text-required'>{formik.errors.maxDiscountLimit}</div>) : null}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={3} md={3}>
                                                                <Form.Group className='animate-chk m-checkbox-inline'>
                                                                    <Form.Label style={{ marginTop: 30 }}>
                                                                        <input name="isHLMParty" className="checkbox_animated" type="checkbox" checked={formik.values.isHLMParty} onChange={formik.handleChange}
                                                                            value={formik.values.isHLMParty} />
                                                                        HLM Party?
                                                                    </Form.Label>
                                                                </Form.Group>
                                                            </Col>
                                                        </>
                                                        : null
                                                    }
                                                    {formik.values.centreType === "1" ?
                                                        <Col lg={3} md={3}>
                                                            <Form.Group className='animate-chk m-checkbox-inline'>
                                                                <Form.Label style={{ marginTop: 30 }}>
                                                                    <input name="isHLMParty" className="checkbox_animated" type="checkbox" checked={formik.values.isHLMParty} onChange={formik.handleChange}
                                                                        value={formik.values.isHLMParty} />
                                                                    HLM Party?
                                                                </Form.Label>
                                                            </Form.Group>
                                                        </Col> : null
                                                    }
                                                    {displayCenterType !== "1" ?
                                                        <>
                                                            <Col lg={3} md={3}>
                                                                <Form.Group>
                                                                    <Form.Label>Contract From Date</Form.Label>
                                                                    <Form.Control type="date" name="fromDate" defaultValue={formik.values.fromDate} onKeyPress={(e) => { handleKeypress(e) }}
                                                                        onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.fromDate}
                                                                    />
                                                                    {formik.touched.fromDate && formik.errors.fromDate ? (<div className='text-required'>{formik.errors.fromDate}</div>) : null}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={3} md={3}>
                                                                <Form.Group>
                                                                    <Form.Label>Contract To Date</Form.Label>
                                                                    <Form.Control type="date" name="toDate" defaultValue={formik.values.toDate} onKeyPress={(e) => { handleKeypress(e) }}
                                                                        onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.toDate} />
                                                                    {formik.touched.toDate && formik.errors.toDate ? (<div className='text-required'>{formik.errors.toDate}</div>) : null}
                                                                </Form.Group>
                                                            </Col>
                                                        </> : null
                                                    }
                                                    {displayCenterType === "2" ?
                                                        <>
                                                            <Col lg={3} md={3}>
                                                                <Form.Group className='animate-chk m-checkbox-inline'>
                                                                    <Form.Label style={{ marginTop: 30 }}>
                                                                        <input name="isSubCentre" id="chktrgt" className="checkbox_animated" type="checkbox" checked={formik.values.isSubCentre} onChange={formik.handleChange}
                                                                            value={formik.values.isSubCentre} onChangeCapture={(e) => { handleIsSubCentre({ target: { value: e.target.checked } }); }} />
                                                                        Use credit limit of any other centre?
                                                                    </Form.Label>
                                                                </Form.Group>
                                                            </Col>
                                                            {isSubCentre ?
                                                                <>
                                                                    <Col lg={3} md={3}>
                                                                        <Form.Label>Partner Type</Form.Label>
                                                                        <Form.Group className='animate-chk m-checkbox-inline'>
                                                                            <Form.Control as="select" name="parentCentreType" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.parentCentreType}
                                                                                onChangeCapture={PartnerTypeChangeHandler}>
                                                                                <option value="0">----Select----</option>
                                                                                {creditCentreTypes}
                                                                            </Form.Control>
                                                                        </Form.Group>
                                                                    </Col>
                                                                    <Col lg={3} md={3}>
                                                                        <Form.Label>Partners</Form.Label>
                                                                        <Form.Group className='animate-chk m-checkbox-inline'>
                                                                            <Form.Control as="select" name="parentCentreId" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.parentCentreId}
                                                                                onChangeCapture={PartnersChangeHandler}>
                                                                                <option value="0">----Select----</option>
                                                                                {partnersList}
                                                                            </Form.Control>
                                                                        </Form.Group>
                                                                    </Col>
                                                                </>
                                                                : null}
                                                        </>
                                                        : null
                                                    }
                                                    <Col lg={12} md={12}>
                                                        <Form.Group className='animate-chk m-checkbox-inline'>
                                                            <Form.Label>
                                                                <input name="isCentreVisible" className="checkbox_animated" type="checkbox" checked={formik.values.isCentreVisible} onChange={formik.handleChange}
                                                                    value={formik.values.isCentreVisible} onChangeCapture={(e) => { handleIsCentreVisible({ target: { value: e.target.checked } }); }} />
                                                                Do you want to show this centre details on website?
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    {
                                                        iscentrevisible &&
                                                        <>
                                                            <Col lg={3} md={3}>
                                                                <Form.Group>
                                                                    <Form.Label>Centre Image<span className='text-required'><small>(Img size: 2290px X 600px)</small> *</span></Form.Label>
                                                                    <div><input type="file" onChange={(e) => UploadImages(e, "CentreImage")} accept="image/*" /></div>
                                                                    <img src={bannerPath} width="80"></img>
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={3} md={3}>
                                                                <Form.Group>
                                                                    <Form.Label>Working From Time<span className='text-required'>*</span></Form.Label>
                                                                    <Form.Control type="time" name="fromtime" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.fromtime} />
                                                                    {formik.touched.fromtime && formik.errors.fromtime ? (<div className='text-required'>{formik.errors.fromtime}</div>) : null}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={3} md={3}>
                                                                <Form.Group>
                                                                    <Form.Label>Working To Time<span className='text-required'>*</span></Form.Label>
                                                                    <Form.Control type="time" name="totime" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.totime} />
                                                                    {formik.touched.totime && formik.errors.totime ? (<div className='text-required'>{formik.errors.totime}</div>) : null}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={3} md={3}>
                                                                <Form.Group>
                                                                    <Form.Label>Working From Day<span className='text-required'>*</span></Form.Label>
                                                                    <Form.Control as="select" name="wrkngfrmday" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.wrkngfrmday}>
                                                                        <option key="" value="">---Select---</option>
                                                                        <option key="1" value="Monday">Monday</option>
                                                                        <option key="2" value="Tuesday">Tuesday</option>
                                                                        <option key="3" value="Wednesday">Wednesday</option>
                                                                        <option key="4" value="Thursday">Thursday</option>
                                                                        <option key="5" value="Friday">Friday</option>
                                                                        <option key="6" value="Saturday">Saturday</option>
                                                                        <option key="7" value="Sunday">Sunday</option>
                                                                    </Form.Control>
                                                                    {formik.touched.wrkngfrmday && formik.errors.wrkngfrmday ? (<div className='text-required'>{formik.errors.wrkngfrmday}</div>) : null}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={3} md={3}>
                                                                <Form.Group>
                                                                    <Form.Label>Working To Day<span className='text-required'>*</span></Form.Label>
                                                                    <Form.Control as="select" name="wrkngtoday" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.wrkngtoday}>
                                                                        <option key="" value="">---Select---</option>
                                                                        <option key="1" value="Monday">Monday</option>
                                                                        <option key="2" value="Tuesday">Tuesday</option>
                                                                        <option key="3" value="Wednesday">Wednesday</option>
                                                                        <option key="4" value="Thursday">Thursday</option>
                                                                        <option key="5" value="Friday">Friday</option>
                                                                        <option key="6" value="Saturday">Saturday</option>
                                                                        <option key="7" value="Sunday">Sunday</option>
                                                                    </Form.Control>
                                                                    {formik.touched.wrkngtoday && formik.errors.wrkngtoday ? (<div className='text-required'>{formik.errors.wrkngtoday}</div>) : null}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={3} md={3}>
                                                                <Form.Group>
                                                                    <Form.Label>Latitude<span className='text-required'>*</span></Form.Label>
                                                                    <Form.Control type="text" name="latitude" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.latitude} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={3} md={3}>
                                                                <Form.Group>
                                                                    <Form.Label>Longitude<span className='text-required'>*</span></Form.Label>
                                                                    <Form.Control type="text" name="longitude" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.longitude} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={3} md={3}>
                                                                <Form.Group>
                                                                    <Form.Label>Sunday Working From Time</Form.Label>
                                                                    <Form.Control type="time" name="sundayfromtime" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.sundayfromtime} />
                                                                    {formik.touched.sundayfromtime && formik.errors.sundayfromtime ? (<div className='text-required'>{formik.errors.sundayfromtime}</div>) : null}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={3} md={3}>
                                                                <Form.Group>
                                                                    <Form.Label>Sunday Working To Time</Form.Label>
                                                                    <Form.Control type="time" name="sundaytotime" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.sundaytotime} />
                                                                    {formik.touched.sundaytotime && formik.errors.sundaytotime ? (<div className='text-required'>{formik.errors.sundaytotime}</div>) : null}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={3} md={3}>
                                                                <Form.Group>
                                                                    <Form.Label>Google Profile Link</Form.Label>
                                                                    <Form.Control as="textarea" rows={3} name="googleprolink" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.googleprolink} />
                                                                    {formik.touched.googleprolink && formik.errors.googleprolink ? (<div className='text-required'>{formik.errors.googleprolink}</div>) : null}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={3} md={3}>
                                                                <Form.Group>
                                                                    <Form.Label>Summary<span className='text-required'>*</span></Form.Label>
                                                                    <Form.Control as="textarea" rows={3} name="summary" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.summary} />
                                                                    {formik.touched.summary && formik.errors.summary ? (<div className='text-required'>{formik.errors.summary}</div>) : null}
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={3} md={3}>
                                                                <Form.Group className='animate-chk m-checkbox-inline' style={{ marginTop: 35 }}>
                                                                    <Form.Label>
                                                                        <input name="isAmbulanceAvailable" className="checkbox_animated" type="checkbox" checked={formik.values.isAmbulanceAvailable} onChange={formik.handleChange}
                                                                            value={formik.values.isAmbulanceAvailable} onChangeCapture={(e) => { handleIsAmbulance({ target: { value: e.target.checked } }); }} />
                                                                        Is ambulance available?
                                                                    </Form.Label>
                                                                </Form.Group>
                                                            </Col>
                                                        </>
                                                    }
                                                </Form.Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                {showpanelfields === true ?
                                    <>
                                        <Row>
                                            <Col lg={12}>
                                                <Card>
                                                    <Card.Header className='pb-0'>
                                                        <Card.Title>Additional Information / Document Required on Registration:</Card.Title>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Form.Row>
                                                            <Col lg={3}>
                                                                <Form.Group>
                                                                    <Form.Label>Document Name</Form.Label>
                                                                    <Form.Control type="text" name="dcname" ref={dcname} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={3}>
                                                                <Form.Group>
                                                                    <Form.Label>Document Type</Form.Label>
                                                                    <Form.Control as="select" name="dctype" ref={dctype} onChange={(e) => setDCTypeValue(e.target.value)}>
                                                                        <option value="0">Select</option>
                                                                        <option value="1">Text</option>
                                                                        <option value="2">File Upload</option>
                                                                        <option value="3">DropDown</option>
                                                                        <option value="4">Checkbox</option>
                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </Col>
                                                            {
                                                                dctypevalue === "3" || dctypevalue === "4" ?
                                                                    <>
                                                                        <Col lg={3}>
                                                                            <Form.Group>
                                                                                <Form.Label>Document Value <span className='text-required'>(eg: Val1,Val2)</span></Form.Label>
                                                                                <Form.Control type="text" name="dcvalue" ref={dcvalue} />
                                                                            </Form.Group>
                                                                        </Col>
                                                                    </> :
                                                                    ""
                                                            }
                                                            <Col lg={3} style={{ padding: 30 }}>
                                                                <Form.Group>
                                                                    <Button variant='primary' onClick={SavePanelDocument}>Save Document</Button>
                                                                </Form.Group>
                                                            </Col>
                                                        </Form.Row>
                                                        <DataTables keyField='DocumentId' tableData={viewpaneldocuments} columns={panelColumns} />
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </>
                                    :
                                    null
                                }
                                <Form>
                                    <Row>
                                        <Col lg={12}>
                                            <Card>
                                                <Card.Header className='pb-0'>
                                                    <Card.Title>Contact Person Detail</Card.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Form.Row>
                                                        <Col lg={3} md={3}>
                                                            <Form.Group>
                                                                <Form.Label>Name<span className='text-required'>*</span></Form.Label>
                                                                <Form.Control type="text" name="cpName" onChange={cpFormik.handleChange} onBlur={cpFormik.handleBlur} value={cpFormik.values.cpName} />
                                                                {cpFormik.touched.cpName && cpFormik.errors.cpName ? (<div className='text-required'>{cpFormik.errors.cpName}</div>) : null}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={3} md={3}>
                                                            <Form.Group>
                                                                <Form.Label>Email<span className='text-required'>*</span></Form.Label>
                                                                <Form.Control type="email" name="cpEmail" onChange={cpFormik.handleChange} onBlur={cpFormik.handleBlur} value={cpFormik.values.cpEmail} />
                                                                {cpFormik.touched.cpEmail && cpFormik.errors.cpEmail ? (<div className='text-required'>{cpFormik.errors.cpEmail}</div>) : null}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={3} md={3}>
                                                            <Form.Group>
                                                                <Form.Label>Contact No<span className='text-required'>*</span></Form.Label>
                                                                <Form.Control type="text" name="cpContactNo" onChange={cpFormik.handleChange} onBlur={cpFormik.handleBlur} value={cpFormik.values.cpContactNo} />
                                                                {cpFormik.touched.cpContactNo && cpFormik.errors.cpContactNo ? (<div className='text-required'>{cpFormik.errors.cpContactNo}</div>) : null}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={3} md={3}>
                                                            <Form.Group>
                                                                <Form.Label>Alternate Contact No</Form.Label>
                                                                <Form.Control type="text" name="cpAlternateContactNo" onChange={cpFormik.handleChange} onBlur={cpFormik.handleBlur} value={cpFormik.values.cpAlternateContactNo} />
                                                                {cpFormik.touched.cpAlternateContactNo && cpFormik.errors.cpAlternateContactNo ? (<div className='text-required'>{cpFormik.errors.cpAlternateContactNo}</div>) : null}
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={3} md={3}>
                                                            <Form.Group>
                                                                <Form.Label>Desigantion</Form.Label>
                                                                <Form.Control type="text" name="cpDesigantion" onChange={cpFormik.handleChange} onBlur={cpFormik.handleBlur} value={cpFormik.values.cpDesigantion} />
                                                            </Form.Group>
                                                        </Col>
                                                        {
                                                            showpanelfields === false ?
                                                                <>
                                                                    {
                                                                        hideCentreAdmin ? null :
                                                                            <Col lg={3}>
                                                                                <Form.Group className='animate-chk m-checkbox-inline'>
                                                                                    <Form.Label>
                                                                                        <input style={{ marginTop: 50 }} name="cpAdmin" className="checkbox_animated" type="checkbox" checked={cpFormik.values.cpAdmin} onChange={cpFormik.handleChange} value={cpFormik.values.cpAdmin} />
                                                                                        Also Centre Admin User?
                                                                                    </Form.Label>
                                                                                </Form.Group>
                                                                            </Col>
                                                                    }
                                                                </> : null}
                                                        <Col lg={12} className='text-center'>
                                                            <Form.Group>
                                                                <Button style={{ marginTop: 30 }} variant='primary' type='submit' onClick={cpFormik.handleSubmit}>Add Contact Person</Button>
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={12} className='text-center'>
                                                            <DataTables keyField='Id' tableData={cpDataTable} columns={cpColumns} />
                                                        </Col>
                                                    </Form.Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Form>
                                {
                                    showpanelfields === false ?
                                        <Row>
                                            <Col lg={12}>
                                                <Card>
                                                    <Card.Header className='pb-0'>
                                                        <Card.Title>Sales Hierarchy</Card.Title>
                                                    </Card.Header>
                                                    <Card.Body>
                                                        <Form.Row>
                                                            <Col lg={4} md={4}>
                                                                <Form.Group>
                                                                    <Form.Label>Sales Person<span className='text-required'>*</span></Form.Label>
                                                                    <Select options={salesPersons} onChange={SalesPersonsChangeHandler}
                                                                        value={salesPersons.filter(function (x) { return x.value === salesPersonSelected; })} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={4} md={4}>
                                                                <Form.Group>
                                                                    <Form.Label>Sales Manager</Form.Label>
                                                                    <Select options={salesManagers} onChange={SalesManagersChangeHandler}
                                                                        value={salesManagers.filter(function (x) { return x.value === salesManagerSelected; })} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={4} md={4}>
                                                                <Form.Group>
                                                                    <Form.Label>Sales Head</Form.Label>
                                                                    <Select options={salesHeads} onChange={SalesHeadsChangeHandler}
                                                                        value={salesHeads.filter(function (i) { return i.value === salesHeadSelected; })} />
                                                                </Form.Group>
                                                            </Col>
                                                        </Form.Row>
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>
                                        : null}

                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <Card.Header className='pb-0'>
                                                <Card.Title>Bio Waste Compliance</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form.Row className="billingandfinance">
                                                    {/* <Col lg={3} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>Title</Form.Label>
                                                            <Form.Control as="select" name="biotitle" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.biotitle}>
                                                                <option value="">---Select---</option>
                                                                {CompanyName}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Col> */}
                                                    <Col lg={3} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>Header</Form.Label>
                                                            <Form.Control type="text" ref={bheader} name="bioheader" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.bioheader} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>Description</Form.Label>
                                                            <Form.Control type="text" ref={bdescription} name="biodescription" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.biodescription} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>Upload Document <span className='text-red'>* (Max 5 mb accepted)</span></Form.Label>
                                                            <div className='d-inline-flex'>
                                                                <input type="file" onChange={(e) => UploadImages(e, "BioWasteCompliance")} accept="application/pdf" />
                                                                <i class="fa fa-file-pdf-o" id="showpdficon" style={{ fontSize: '30px', color: 'red', display: 'none', position: 'absolute', right: '50%' }} aria-hidden="true"></i>
                                                            </div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} md={4} style={{ padding: 30 }}>
                                                        <Form.Group>
                                                            <Button variant='primary' onClick={SaveBioWasteDocument}>Save Document</Button>
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>
                                                <DataTables keyField='DocumentId' tableData={viewbiowastedocuments} columns={biowasteColumns} />
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <Card.Header className='pb-0'>
                                                <Card.Title>Billing &amp; Finance</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form.Row className="billingandfinance">
                                                    <Col lg={3} md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Payment Mode</Form.Label>
                                                            <Form.Control as="select" name="paymentMode" onChangeCapture={handlePaymentModeChange} disabled={disablePaymentMode}
                                                                onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.paymentMode}>
                                                                {paymentModeList}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} md={6}>
                                                        <Form.Group className='animate-chk m-checkbox-inline'>
                                                            <Form.Label>
                                                                <input style={{ marginTop: 50 }} name="otherLabRefNo" className="checkbox_animated" type="checkbox" checked={formik.values.otherLabRefNo} onChange={formik.handleChange} value={formik.values.otherLabRefNo} />
                                                                Other Lab Ref. No.
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    {formik.values.centreType === "2" ?
                                                        <>
                                                            <Col lg={3} md={3}>
                                                                <Form.Group>
                                                                    <Form.Label>Email Id(Invoice)</Form.Label>
                                                                    <Form.Control type="text" name="invoiceEmail" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.invoiceEmail} />
                                                                </Form.Group>
                                                            </Col>
                                                            <Col lg={3} md={3}>
                                                                <Form.Group>
                                                                    <Form.Label>Email Id(Report)</Form.Label>
                                                                    <Form.Control type="text" name="reportEmail" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.reportEmail} />
                                                                </Form.Group>
                                                            </Col>

                                                            <Col lg={3} md={3}>
                                                                <Form.Group>
                                                                    <Form.Label>Min. Business Commit.</Form.Label>
                                                                    <Form.Control type="text" name="minBusinessCommit" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.minBusinessCommit} />
                                                                    {formik.touched.minBusinessCommit && formik.errors.minBusinessCommit ? (<div className='text-required'>{formik.errors.minBusinessCommit}</div>) : null}
                                                                </Form.Group>
                                                            </Col>
                                                        </>
                                                        : null
                                                    }
                                                    <Col lg={3} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>GSTIN</Form.Label>
                                                            <Form.Control type="text" name="gstinNo" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.gstinNo} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>Bank Name </Form.Label>
                                                            {/* <Form.Control as="select" name="bankId" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.bankId}>
                                                                <option value="0">---Select---</option>
                                                                {banksList}
                                                            </Form.Control> */}
                                                            <Select onChange={TypeChangeHandler}
                                                                options={itemname}
                                                                value={itemname.filter(function (x) { return x.value === typeSelected; })} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>Account No.</Form.Label>
                                                            <Form.Control type="text" name="accountNo" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.accountNo} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>IFSC Code</Form.Label>
                                                            <Form.Control type="text" name="ifscCode" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.ifscCode} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>Bank Account Name</Form.Label>
                                                            <Form.Control type="text" name="accountName" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.accountName} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>PAN</Form.Label>
                                                            <Form.Control type="text" name="panNo" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.panNo} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xl={4} lg={8} md={8}>
                                                        <Form.Group>
                                                            <Form.Label>Virtual Account(IFSC Code: {bankcode})<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="text" name="virtualAccount" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.virtualAccount} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xl={4} lg={3} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>Invoice To</Form.Label>
                                                            <Form.Control as="select" name="invoiceTo" onChange={formik.handleChange} onBlur={formik.handleBlur}
                                                                value={formik.values.invoiceTo} disabled={disableCreditSetting}>
                                                                <option value="0">{invoiceTypeTitle}</option>
                                                                {partnersList}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col xl={4} lg={3} md={4}>
                                                        <Form.Group>
                                                            <Form.Label>Min Cash in Booking(%)</Form.Label>
                                                            <Form.Control type="text" name="minBookingCash" disabled={true} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.minBookingCash} />
                                                            {formik.touched.minBookingCash && formik.errors.minBookingCash ? (<div className='text-required'>{formik.errors.minBookingCash}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={12}>
                                        <Card>
                                            <Card.Header className='pb-0'>
                                                <Card.Title>Other Settings</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form.Row>
                                                    <Col lg={3} md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Tag Processing Lab</Form.Label>
                                                            <Form.Control as="select" name="processingLabId" onChange={formik.handleChange} onBlur={formik.handleBlur}
                                                                value={formik.values.processingLabId} disabled={processingLabDisabled}>
                                                                <option value="0">{selectTitle}</option>
                                                                {processingLabsList}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Business Lab</Form.Label>
                                                            <Form.Control as="select" name="businessLabId" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.businessLabId}>
                                                                <option value="0">{selectBLabTitle}</option>
                                                                {businessLabsList}
                                                            </Form.Control>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Credit Intimation Limit</Form.Label>
                                                            <Form.Control type="text" name="creditIntimateLimit" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.creditIntimateLimit} disabled={disableCreditSetting} />
                                                            {formik.touched.creditIntimateLimit && formik.errors.creditIntimateLimit ? (<div className='text-required'>{formik.errors.creditIntimateLimit}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Credit Reporting Limit</Form.Label>
                                                            <Form.Control type="text" name="creditReportLimit" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.creditReportLimit} disabled={disableCreditSetting} />
                                                            {formik.touched.creditReportLimit && formik.errors.creditReportLimit ? (<div className='text-required'>{formik.errors.creditReportLimit}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col lg={3} md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Credit Booking Limit</Form.Label>
                                                            <Form.Control type="text" name="creditBookingLimit" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.creditBookingLimit} disabled={disableCreditSetting} />
                                                            {formik.touched.creditBookingLimit && formik.errors.creditBookingLimit ? (<div className='text-required'>{formik.errors.creditBookingLimit}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    {formik.values.centreType === "3" || formik.values.centreType === "2" ?
                                                        <>
                                                            <Col lg={3}>
                                                                <Form.Group>
                                                                    <Form.Label>Credit Days</Form.Label>
                                                                    <Form.Control type="text" name="creditDays" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.creditDays} disabled={disableCreditSetting} />
                                                                    {formik.touched.creditDays && formik.errors.creditDays ? (<div className='text-required'>{formik.errors.creditDays}</div>) : null}
                                                                </Form.Group>
                                                            </Col>
                                                        </> : null
                                                    }
                                                    {formik.values.centreType === "3" || formik.values.centreType === "2" ?
                                                        <>
                                                            <Col lg={3}>
                                                                <Form.Group>
                                                                    <Form.Label>Billing Cycle <span className='text-red'>*</span></Form.Label>
                                                                    <Form.Control as="select" name="billingCycleId" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.billingCycleId}>
                                                                        <option value="0">---Select---</option>
                                                                        {billingCycleList}
                                                                    </Form.Control>
                                                                </Form.Group>
                                                            </Col>
                                                        </> : null}
                                                </Form.Row>
                                                <Form.Row>
                                                    <Col lg={12}>
                                                        <Form.Group className='animate-chk m-checkbox-inline'>
                                                            <Form.Label>
                                                                <input name="hideBookingAmount" className="checkbox_animated" type="checkbox" checked={formik.values.hideBookingAmount} onChange={formik.handleChange} value={formik.values.hideBookingAmount} disabled={hideAmountDisabled} />
                                                                Hide Amount In Booking
                                                            </Form.Label>
                                                            <Form.Label>
                                                                <input name="showBalanceAmount" className="checkbox_animated" type="checkbox" checked={formik.values.showBalanceAmount} onChange={formik.handleChange} value={formik.values.showBalanceAmount} disabled={showBalanceDisabled} />
                                                                Show Balance Amount
                                                            </Form.Label>
                                                            <Form.Label>
                                                                <input name="hideReceiptRate" className="checkbox_animated" type="checkbox" checked={formik.values.hideReceiptRate} onChange={formik.handleChange} value={formik.values.hideReceiptRate} disabled={hideReciptRateDisabled} />
                                                                Hide Receipt Rate
                                                            </Form.Label>
                                                            <Form.Label>
                                                                <input name="batchRequired" className="checkbox_animated" type="checkbox" checked={formik.values.batchRequired} onChange={formik.handleChange} value={formik.values.batchRequired} />
                                                                Batch Required
                                                            </Form.Label>

                                                            <Form.Label>
                                                                <input name="showMRPBill" className="checkbox_animated" type="checkbox" checked={formik.values.showMRPBill} onChange={formik.handleChange} value={formik.values.showMRPBill} disabled={disableShowMrpBills} />
                                                                Show MRP Bill
                                                            </Form.Label>
                                                            <Form.Label>
                                                                <input name="printDepartmentSlip" className="checkbox_animated" type="checkbox" checked={formik.values.printDepartmentSlip} onChange={formik.handleChange} value={formik.values.printDepartmentSlip} disabled={disablePrintDeptSlips} />
                                                                Print Department Slip
                                                            </Form.Label>
                                                            <Form.Label>
                                                                <input name="showPROInRegistration" className="checkbox_animated" type="checkbox" checked={formik.values.showPROInRegistration} onChange={formik.handleChange} value={formik.values.showPROInRegistration} disabled={disableShowPROinReg} />
                                                                Show PRO in Registration
                                                            </Form.Label>
                                                            <Form.Label>
                                                                <input name="showCentreDetailOnReport" className="checkbox_animated" type="checkbox" checked={formik.values.showCentreDetailOnReport} onChange={formik.handleChange} value={formik.values.showCentreDetailOnReport} disabled={disableShowDetailsOnReport} />
                                                                Show Centre detail on Report
                                                            </Form.Label>
                                                            <Form.Label>
                                                                <input name="hideFooterOnWithoutLetterheadReport" className="checkbox_animated" type="checkbox" checked={formik.values.hideFooterOnWithoutLetterheadReport} onChange={formik.handleChange} value={formik.values.hideFooterOnWithoutLetterheadReport} />
                                                                Hide Footer on Without Letterhead Report
                                                            </Form.Label>
                                                            <Form.Label>
                                                                <input name="hidebillingstationery" className="checkbox_animated" type="checkbox" checked={formik.values.hidebillingstationery} onChange={formik.handleChange} value={formik.values.hidebillingstationery} />
                                                                Hide Billing Stationery
                                                            </Form.Label>
                                                            <Form.Label>
                                                                <input className="checkbox_animated" type="checkbox" name="isActive" checked={formik.values.isActive} onChange={formik.handleChange} value={formik.values.isActive} /> Is Active
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>

                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                {displayCenterType === "1" ?
                                    <Row>
                                        <Col lg={12}>
                                            <Card>
                                                <Card.Header className='pb-0'>
                                                    <Card.Title>Stradus Settings</Card.Title>
                                                </Card.Header>
                                                <Card.Body>
                                                    <Form.Row>
                                                        <Col lg={3} md={3}>
                                                            <Form.Group>
                                                                <Form.Label>Stradus Name</Form.Label>
                                                                <Form.Control type="text" name="stardusName" maxLength={100} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.stardusName} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={2} md={2}>
                                                            <Form.Group>
                                                                <Form.Label>Customer Id</Form.Label>
                                                                <Form.Control type="text" name="stardusCustomerId" maxLength={50} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.stardusCustomerId} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={2} md={2}>
                                                            <Form.Group>
                                                                <Form.Label>Key Id</Form.Label>
                                                                <Form.Control type="text" name="stardusKeyId" maxLength={100} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.stardusKeyId} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={3} md={2}>
                                                            <Form.Group>
                                                                <Form.Label>Url</Form.Label>
                                                                <Form.Control type="text" name="stardusUrl" maxLength={250} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.stardusUrl} />
                                                            </Form.Group>
                                                        </Col>
                                                        <Col lg={2} md={2}>
                                                            <Form.Group>
                                                                <Form.Label>Secret Key</Form.Label>
                                                                <Form.Control type="text" name="stardusSecretKey" maxLength={100} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.stardusSecretKey} />
                                                            </Form.Group>
                                                        </Col>
                                                    </Form.Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                    : null
                                }
                                <Row className='mt15'>
                                    <Col lg={12} className='text-center'>
                                        <Button variant='secondary' className="mr-2" onClick={cancelHandler}>Cancel</Button>
                                        {isSaving ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' type='submit' onClick={formik.handleSubmit}>Save</Button>
                                        }
                                    </Col>
                                </Row>
                            </Form>
                        </>
                }
            </Container>
        </React.Fragment >
    )
}

export default CentreInfo;