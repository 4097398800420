import React, { useState, useEffect, useRef, Fragment } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import EditToolTip from '../UI/ToolTips/EditToolTip';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip';
import ActiveToolTip from '../UI/ToolTips/ActiveToolTip';
import InactiveToolTip from '../UI/ToolTips/InactiveToolTip';
import ViewToolTip from '../UI/ToolTips/ViewToolTip';
import { URL_Start } from '../../../constant';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import InputGroup from 'react-bootstrap/InputGroup';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Spinner } from 'react-bootstrap';
import AddToolTip from '../UI/ToolTips/AddToolTip';
import SubtractToolTip from '../UI/ToolTips/SubtractToolTip';
import { Typeahead } from 'react-bootstrap-typeahead';
import { MultiSelect } from 'react-multi-select-component';
import '../../../pages/admin/react-multiselect.css';
import Select from 'react-select';
import { AlertTriangle } from 'react-feather';
let globalModuleId = "0";
let globalModuleUTypeId = "0";
let globalPageSelection = false;
let globalmoduletype = false;
let globaluserPlatform = "";
let globalUserId = "0";
let globaltblAllGroups = [];
let globaltblExistGroups = [];
let FlagUserCustFilterOnly = "0";
let GlobalPartnerType = "0";
let globallocationStateList = [];
let LGlbLocationRegionList = [];
let LGLocationDistrictList = [];

let GGLocationRegionList = [];
let GGLocationDistrictList = [];
let StateOptionSelFlag = false;
let stringStates = "";
let stringStateIds = "";
let stringRegions = "";
let stringRegionIds = "";
let stringDistricts = "";
let stringDistrictIds = "";
let stringCities = "";
let stringCityIds = "";
let SelectedAllStateFlag = false;
let SelectedAllregionFlag = false;
let SelectedAlldistricFlag = false;
const AddDiscount = () => {
    const [MaxDiscountField, SetMaxDiscountField] = useState("");
    const [SelectDepartmentDDL, SetSelectDepartmentDDL] = useState([]);
    const [SelectDepartment, SetSelectDepartment] = useState(null);
    const [SelectDesginationDDL, SetSelectDesginationDDL] = useState([]);
    const [SelectDesgination, SetSelectDesgination] = useState(null);
    const [SelectUserDDL, SetSelectUserDDL] = useState([]);
    const [SelectUsers, SetSelectUsers] = useState([]);
    const [SelectDiscountShareDDL, SetSelectDiscountShareDDL] = useState([]);
    const [SelectDiscountShare, SetSelectDiscountShare] = useState(null);
    const [SelectCentreDDL, SetSelectCentreDDL] = useState([]);
    const [SelectCentres, SetSelectCentres] = useState([]);
    const [LGlCentreTypeoption, SetLGlCentreTypeoption] = useState([]);
    const [LGlEmployeeTypeoption, SetLGlEmployeeTypeoption] = useState([]);
    const [IsButtonSubmit, SetIsButtonSubmit] = useState(false);


    let typeahead = useRef(null);
    let textSearch = useRef();
    let isFirstRender = useRef(false);
    let isLastRender = useRef(false);
    let IsDefaultLocation = useRef(false);
    const history = useHistory();
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const [ErrorPopUp, setErrorPopUp] = useState(0);
    const [tblRefDoctor, SettblRefDoctor] = useState([]);
    const [ModuleDetail, SetModuleDetail] = useState(0);
    const [IsUserTypeMoudle, SetIsUserTypeMoudle] = useState(false);
    const [tbAccessPageRMaster, SettbAccessPageRMaster] = useState([]);
    const [DepartmentDetail, SetDepartmentDetail] = useState(0);
    const [DesignationDetail, SetDesignationDetail] = useState(0);
    const [UserPlatformDetail, SetUserPlatformDetail] = useState(0);
    const [PartnerTypeDetail, SetPartnerTypeDetail] = useState(0);
    const [IsPartnerTypeShow, SetIsPartnerTypeShow] = useState(false);
    const [IsDepartmentShow, SetIsDepartmentShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isButton, setIsButton] = useState(false);
    const [IsSearchUsers, SetIsSearchUsers] = useState(false);
    const [SearchBox, SetSearchBox] = useState("");
    const [UsersDLists, SetUsersDLists] = useState([]);
    const [tblUserGroupMapping, SettblUserGroupMapping] = useState(null);
    const [userModuleShow, SetuserModuleShow] = useState(false);
    const [TXtSearch, SetTXtSearch] = useState("");
    const [ViewAccessGroups, SetViewAccessGroups] = useState(null);
    const [IsUserView, SetIsUserView] = useState(false);
    const [LocationZoneId, SetLocationZoneId] = useState(1);
    const [LocationStateList, SetLocationStateList] = useState([]);
    const [LocationRegionList, SetLocationRegionList] = useState([]);
    const [selectedGlocationStateList, SetselectedGlocationStateList] = useState([]);
    const [selectedGlocationRegionList, SetselectedGlocationRegionList] = useState([]);
    const [selectedGlocationDistrictList, SetselectedGlocationDistrictList] = useState([]);
    const [LocationDistrictList, SetLocationDistrictList] = useState([]);
    const [SelectionglobaltblAllGroups, SetSelectionglobaltblAllGroups] = useState([]);
    const [VaildFlag, SetVaildFlag] = useState(false);
    const [FinalStage, SetFinalStage] = useState(false);
    const [LGLocationZoneList, SetLGLocationZoneList] = useState([{
        "zoneId": "1"
    }]);
    useEffect(() => {
        asyncBindFunDepartmentDDL();
        asyncFunLocationBindDetails();
        asyncBindFunDiscountShareDDL();
    }, [globallocationStateList]);


    const asyncBindFunDepartmentDDL = async () => {
        try {

            let url = URL_Start + 'master/getBindDepartmentDetailsDDL';
            let options = {
                method: 'GET',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {}
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;

                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        setErrorModal(false);
                        const TypeOptionDs1 = [];

                        ds.map((option) => {
                            const TypeOptions1 = {
                                'DepartmentId': option.DepartmentId,
                                'label': option.CustDeptName,
                                'value': option.DepartmentId,
                                'disabled': false
                            }
                            return TypeOptionDs1.push(TypeOptions1);
                        });
                        SetSelectDepartmentDDL(TypeOptionDs1);
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');

                }

            }
            else {
                setErrorModal(false);
                SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false);
            }
            // return data;
        } catch (error) {
            if (error.response) {
                setErrorModal(false);
                ErrorFunction(error.response.status);

            }

        }
    }

    const asyncBindFunDesginationDDL = async (deptId) => {
        try {

            let url = URL_Start + 'hrms/getdesignationDDL';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { DepartmentId: `${deptId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;

                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        setErrorModal(false);
                        const TypeOptionDs1 = [];

                        ds.map((option) => {
                            const TypeOptions1 = {
                                'DesignationId': option.DesignationId,
                                'label': option.Designation,
                                'value': option.DesignationId,
                                'disabled': false
                            }
                            return TypeOptionDs1.push(TypeOptions1);
                        });
                        SetSelectDesginationDDL(TypeOptionDs1);
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');

                }

            }
            else {
                setErrorModal(false);
                SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false);
            }
            // return data;
        } catch (error) {
            if (error.response) {
                setErrorModal(false);
                ErrorFunction(error.response.status);

            }

        }
    }

    const asyncBindFunUserDDL = async (desginationId) => {

        try {

            let url = URL_Start + 'hrms/getEmployeeDDLByDesignation';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { DesignationId: `${desginationId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;

                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        setErrorModal(false);
                        const TypeOptionDs1 = [];

                        ds.map((option) => {
                            const TypeOptions1 = {
                                'DesignationId': option.EmployeeId,
                                'label': option.CustUserName,
                                'value': option.EmployeeId,
                                'EmployeeCode': option.EmployeeCode,
                                'disabled': false
                            }
                            return TypeOptionDs1.push(TypeOptions1);
                        });
                        SetSelectUserDDL(TypeOptionDs1);
                        //SetSelectUsers(TypeOptionDs1);
                        SetSelectUsers([]);

                    }
                    else {
                        SetSelectUserDDL([]);
                        SetSelectUsers([]);
                    }
                    const TypeOptL = [];
                    if (ds != null && ds.length > 0) {
                        ds.map((item) => {
                            const TypeOptrows = {
                                'EmployeeId': item.EmployeeId
                            }
                            return TypeOptL.push(TypeOptrows);
                        });
                        SetLGlEmployeeTypeoption(TypeOptL);
                        asyncFunCentresbylocationsCust(TypeOptL);
                    }
                    else {
                        SetLGlEmployeeTypeoption([]);
                    }

                }
                else {
                    SetSelectUserDDL([]);
                    SetSelectUsers([]);
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');

                }

            }
            else {
                SetSelectUserDDL([]);
                SetSelectUsers([]);
                setErrorModal(false);
                SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false);
            }
            // return data;
        } catch (error) {
            SetSelectUserDDL([]);
            SetSelectUsers([]);
            if (error.response) {
                setErrorModal(false);
                ErrorFunction(error.response.status);

            }

        }
    }

    const asyncBindFunDiscountShareDDL = async () => {
        try {

            let url = URL_Start + 'master/getdiscountsharedetails';
            let options = {
                method: 'GET',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {}
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;

                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        setErrorModal(false);
                        const TypeOptionDs1 = [];

                        ds.map((option) => {
                            const TypeOptions1 = {
                                'DisShareId': option.DisShareId,
                                'label': option.ShareType,
                                'value': option.DisShareId,
                                'disabled': false
                            }
                            return TypeOptionDs1.push(TypeOptions1);
                        });
                        SetSelectDiscountShareDDL(TypeOptionDs1);
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');

                }

            }
            else {
                setErrorModal(false);
                SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false);
            }
            // return data;
        } catch (error) {
            if (error.response) {
                setErrorModal(false);
                ErrorFunction(error.response.status);

            }

        }
    }
    const asyncFunLocationBindDetails = async () => {
        try {

            let url = URL_Start + 'master/getstateslistlocationMapping/' + `${LocationZoneId}`;
            let options = {
                method: 'GET',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {}
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null && ds.length > 0) {
                        const TypeOptionDs = [];

                        ds.map((option) => {
                            const TypeOptions = {
                                'label': option.Value,
                                'value': option.Id,
                                'StateId': option.Id,
                                'disabled': option.HaveRegionStatus == "1" ? false : true
                            }
                            return TypeOptionDs.push(TypeOptions);
                        });
                        SetLocationStateList(TypeOptionDs);
                    }
                    else {
                        SetLocationStateList([]);
                    }

                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                    console.log('no record found');
                }
            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
            }
            // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }

    const asyncFunRegionLocationBindDetails = async (isChecked) => {
        try {

            let url = URL_Start + 'master/getBindLocationRegionDetails'
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { objTblLocationTypeList: globallocationStateList }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    SetLocationRegionList([]);
                    if (ds != null && ds.length > 0) {
                        const TypeOptionDs = [];

                        ds.map((option) => {
                            const TypeOptions = {
                                'label': option.RegionName,
                                'value': option.RegionId,
                                'StateId': option.StateId,
                                'RegionId': option.RegionId,
                                'IsHaveDistrictStatus': option.IsHaveDistrictStatus,
                                'disabled': false
                            }
                            return TypeOptionDs.push(TypeOptions);
                        });
                        SetLocationRegionList(TypeOptionDs);
                        SetuserModuleShow(false);

                    }
                    else {
                        SetLocationRegionList([]);
                        SetselectedGlocationRegionList([]);
                        if (globallocationStateList.length == 0) {
                            SetuserModuleShow(false);
                        }

                    }

                    if (ds != null && ds.length > 0) {
                        if (SelectedAllStateFlag == true) {
                            const TypeOptionDs = [];

                            ds.map((option) => {
                                const TypeOptions = {
                                    'label': option.RegionName,
                                    'value': option.RegionId,
                                    'StateId': option.StateId,
                                    'RegionId': option.RegionId,
                                    'IsHaveDistrictStatus': option.IsHaveDistrictStatus,
                                    'disabled': false
                                }
                                return TypeOptionDs.push(TypeOptions);
                            });

                            SetselectedGlocationRegionList(selectedGlocationRegionList => [...TypeOptionDs]);
                            LGlbLocationRegionList = [];
                            ds.map((option) => {
                                const item = {
                                    "RegionId": option.RegionId
                                };
                                LGlbLocationRegionList.push(item);
                            });
                            asyncFunDistinctLocationBindDetails(true);
                            SetuserModuleShow(true);
                        }
                        else {

                            SetselectedGlocationRegionList(selectedGlocationRegionList => [...selectedGlocationRegionList.filter(o1 => ds.some(o2 => o1.RegionId == o2.RegionId))]);

                            if (selectedGlocationRegionList.length == 0) {
                                SetuserModuleShow(false);
                            }
                            else {
                                SetuserModuleShow(true);
                            }
                        }
                        //LGlbLocationRegionList=LGlbLocationRegionList.filter(o1 => ds.some(o2 => o1.RegionId== o2.RegionId));
                    }
                    else {
                        SetLocationRegionList([]);
                        SetselectedGlocationRegionList([]);
                        if (globallocationStateList.length > 0) {
                            stringRegions = "Selected All Regions";
                            stringRegionIds = "0";
                            stringDistricts = "Selected All Districts";
                            stringDistrictIds = "0";
                            SetuserModuleShow(true);
                        }
                        else {
                            stringRegions = "";
                            stringRegionIds = "0";
                            stringDistricts = "";
                            stringDistrictIds = "0";

                        }

                        globaltblAllGroups = globaltblAllGroups.map(el => (
                            el.IsAlreadyHaveDB == "False" ? { ...el, LocationRegions: stringRegions, LocationRegionIds: stringRegionIds, LocationDistricts: stringDistricts, LocationDistrictIds: stringDistrictIds } : el
                        ));

                    }
                    console.log(JSON.stringify(LGlbLocationRegionList));
                }
                else {
                    SetselectedGlocationRegionList([]);
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                    console.log('no record found');
                }
            }
            else {
                SetselectedGlocationRegionList([]);
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
            }
        } catch (error) {
            SetselectedGlocationRegionList([]);
            console.log(error.message);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }

    const asyncFunDistinctLocationBindDetails = async (isChecked) => {
        try {

            let url = URL_Start + 'master/getBindLocationDistrictDetails'
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { objTblLocationTypeList: LGlbLocationRegionList }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    SetLocationDistrictList([]);
                    if (ds != null && ds.length > 0) {
                        const TypeOptionDs = [];

                        ds.map((option) => {
                            const TypeOptions = {
                                'label': option.DistrictName,
                                'value': option.DistrictId,
                                'StateId': option.StateId,
                                'RegionId': option.RegionId,
                                'DistrictId': option.DistrictId,
                                'disabled': false
                            }
                            return TypeOptionDs.push(TypeOptions);
                        });

                        SetLocationDistrictList(TypeOptionDs);

                    }
                    else {
                        SetLocationDistrictList([]);
                    }

                    if (ds != null && ds.length > 0) {
                        if (SelectedAllregionFlag == true) {
                            const TypeOptionDs = [];

                            ds.map((option) => {
                                const TypeOptions = {
                                    'label': option.DistrictName,
                                    'value': option.DistrictId,
                                    'StateId': option.StateId,
                                    'RegionId': option.RegionId,
                                    'DistrictId': option.DistrictId,
                                    'disabled': false
                                }
                                return TypeOptionDs.push(TypeOptions);
                            });

                            SetselectedGlocationDistrictList(selectedGlocationDistrictList => [...TypeOptionDs]);
                            LGLocationDistrictList = [];
                            ds.map((option) => {
                                const item = {
                                    "DistrictId": option.DistrictId
                                };
                                LGLocationDistrictList.push(item);
                            });
                            SetuserModuleShow(true);
                        }
                        else {
                            SetselectedGlocationDistrictList(selectedGlocationDistrictList => [...selectedGlocationDistrictList.filter(o1 => ds.some(o2 => o1.DistrictId == o2.DistrictId))]);

                        }



                    }
                    else {
                        SetselectedGlocationDistrictList([]);
                        if (LGlbLocationRegionList.length > 0) {
                            SetuserModuleShow(true);
                        }
                    }

                }
                else {
                    SetselectedGlocationDistrictList([]);
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                    console.log('no record found');
                }
            }
            else {
                SetselectedGlocationDistrictList([]);
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
            }
        } catch (error) {
            SetselectedGlocationDistrictList([]);
            console.log(error.message);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }
    const asyncFunCentresbylocationsCust = async (LGlEmployeeTypeoptionMM) => {
        try {

            let url = URL_Start + 'master/getBindCentrebylocationDetails'
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { objTblLocationTypeList: LGLocationDistrictList, objtblDiscountDesignationEmployeeType: LGlEmployeeTypeoptionMM }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        setErrorModal(false);
                        const TypeOptionDs1 = [];

                        ds.map((option) => {
                            const TypeOptions1 = {
                                'CentreId': option.CentreId,
                                'label': option.CentreName,
                                'value': option.CentreId,
                                'CenterTypeId': option.CenterTypeId,
                                'disabled': false
                            }
                            return TypeOptionDs1.push(TypeOptions1);
                        });
                        SetSelectCentreDDL(TypeOptionDs1);
                    }
                    else {
                        SetSelectCentreDDL([]);
                    }
                }
                else {
                    SetSelectCentreDDL([]);
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');

                }

            }
            else {
                SetSelectCentreDDL([]);
                setErrorModal(false);
                SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false);
            }
            // return data;
        } catch (error) {
            SetSelectCentreDDL([]);
            if (error.response) {
                setErrorModal(false);
                ErrorFunction(error.response.status);

            }

        }
    }
    const asyncFunCentresbylocations = async () => {
        try {

            let url = URL_Start + 'master/getBindCentrebylocationDetails'
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { objTblLocationTypeList: LGLocationDistrictList, objtblDiscountDesignationEmployeeType: LGlEmployeeTypeoption }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        setErrorModal(false);
                        const TypeOptionDs1 = [];

                        ds.map((option) => {
                            const TypeOptions1 = {
                                'CentreId': option.CentreId,
                                'label': option.CentreName,
                                'value': option.CentreId,
                                'CenterTypeId': option.CenterTypeId,
                                'disabled': false
                            }
                            return TypeOptionDs1.push(TypeOptions1);
                        });
                        SetSelectCentreDDL(TypeOptionDs1);
                    }
                    else {
                        SetSelectCentreDDL([]);
                    }
                }
                else {
                    SetSelectCentreDDL([]);
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');

                }

            }
            else {
                SetSelectCentreDDL([]);
                setErrorModal(false);
                SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false);
            }
            // return data;
        } catch (error) {
            SetSelectCentreDDL([]);
            if (error.response) {
                setErrorModal(false);
                ErrorFunction(error.response.status);

            }

        }
    }

    /********** Modals  ***********/
    const [modal, setModal] = useState();
    const toggleModal = () => {
        setModal(!modal)
    }

    const [errorModal, setErrorModal] = useState();
    const errorHandler = () => {
        SetIsButtonSubmit(true);
        SetIsUserView(false);
        setModal(true); setErrorModal(true);
        formik.handleSubmit();

    }





    /********** Validations  ***********/
    const formik = useFormik({
        initialValues: {
            maxdiscount: '',
            maxdiscountprecent: ''

        },
        enableReinitialize: true,
        validationSchema: Yup.object({
            // email: Yup.string().email('Invalid email address').required('Required'),
            // callType: Yup.string().transform(v => v=== '0' ? '' : v)
            // .required('Call Type is Required'),
            maxdiscount: Yup.number().required('Max Discount Per Month (in Rs.) is Required')



        }),
        isSubmitting: true,
        onSubmit: async (values, actions) => {

            if ((SelectDepartment == null || SelectDepartment.length == 0)) {
                setModal(true); setErrorModal(true);
            }
            else if ((SelectDesgination == null || SelectDesgination.length == 0)) {
                setModal(true); setErrorModal(true);
            }
            else if (globallocationStateList.length == 0) {
                setModal(true); setErrorModal(true);
            }
            else if (LocationRegionList.length > 0 && LGlbLocationRegionList.length == 0) {
                setModal(true); setErrorModal(true);
            }
            else if (LocationDistrictList.length > 0 && LGLocationDistrictList.length == 0) {
                setModal(true); setErrorModal(true);
            }

            else {
                setModal(false);
                setErrorModal(false);
                setErrorPopUp("");
                try {
                    let DesignationMId = "";
                    let DisShareMId = "0";
                    if (SelectDesgination.DesignationId != "" || SelectDesgination.DesignationId != "0") {
                        DesignationMId = SelectDesgination.DesignationId;
                    }

                    let url = URL_Start + 'master/SETUpdateEmployeeDiscountDetails';
                    console.log(LGlEmployeeTypeoption)
                    let options = {
                        method: 'POST',
                        url: url,
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json;charset=UTF-8'
                        },
                        data: {
                            DesignationId: `${DesignationMId}`, MaxDisPerMnthRs: `${values.maxdiscount}`, MaxDisPerMnthPercent: `${MaxDiscountField}`, DisShareId: `${DisShareMId}`, AddedBy: `${LoginId}`, objtblDiscountDesignationCentreType: LGlCentreTypeoption,
                            objtblDiscountDesignationEmployeeType: LGlEmployeeTypeoption
                        }
                    };

                    let response = await axios(options);
                    let responseOK = response && response.status == 200;
                    if (responseOK) {
                        let data = response.data;
                        // let data = await response.data;
                        if (data.Success == true && data.Data == "2000") {
                            formik.resetForm();
                            globallocationStateList = [];
                            LGLocationDistrictList = [];
                            LGlbLocationRegionList = [];
                            SetLocationRegionList([]);
                            SetLocationDistrictList([]);
                            SetselectedGlocationRegionList([]);
                            SetLocationDistrictList([]);
                            SetselectedGlocationDistrictList([]);
                            SetLocationRegionList([]);
                            SetLocationDistrictList([]);
                            SetselectedGlocationStateList([]);
                            SetSelectDepartment(null);
                            SetSelectDesginationDDL([]);
                            SetSelectUserDDL([]);
                            SetSelectUsers([]);
                            SetSelectDiscountShare(null);
                            SetSelectCentreDDL([]);
                            SetSelectCentres([]);
                            SetLGlCentreTypeoption([]);
                            SweetAlert.fire({ title: "Success!", text: "Discount has been Saved!", icon: "success" });
                            setErrorModal(false);
                            SetIsButtonSubmit(false);
                            history.push({ pathname: `${process.env.PUBLIC_URL}/master/discounts`, search: `` });

                        }

                        else {
                            if (data.ErrorList != null && data.ErrorList.length > 0) {
                                let Errtext = <p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                                SweetAlert.fire({ title: "Error", text: data.ErrorList[0].errorMsg, icon: "error" });
                            }

                        }


                    }
                    else {
                        console.log('no record found');

                    }
                    // return data;
                } catch (error) {
                    console.log(error.message);
                    let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                    SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
                }
                setTimeout(() => {

                    actions.setSubmitting(false);
                }, 1000);
            }


        },
    });


    function onKeyDown(keyEvent) {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
        }
    }




    const PrevBckHandler = async () => {
        formik.resetForm();
        SetUsersDLists([]);
        globallocationStateList = [];
        LGLocationDistrictList = [];
        LGlbLocationRegionList = [];
        SetselectedGlocationRegionList([]);
        SetLocationDistrictList([]);
        SetselectedGlocationDistrictList([]);
        SetLocationRegionList([]);
        SetLocationDistrictList([]);
        SetselectedGlocationStateList([]);
        history.push({ pathname: `${process.env.PUBLIC_URL}/master/discounts`, search: `` });
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }

    const stateSelectionHandler = (options) => {
        SetselectedGlocationStateList(options);
        if (options != null && options.length > 0) {
            globallocationStateList = [];
            SetLocationDistrictList([]);
            //SetselectedGlocationDistrictList([]);
            options.map((option) => {
                const TypeOptions = {
                    'StateId': option.value
                }
                return globallocationStateList.push(TypeOptions);
            });
            StateOptionSelFlag = false;
            asyncFunRegionLocationBindDetails(true);
            asyncFunDistinctLocationBindDetails(true);
            asyncFunCentresbylocations(true);
        }
        else {
            globallocationStateList = [];
            LGlbLocationRegionList = [];
            LGLocationDistrictList = [];
            SetLocationRegionList([]);
            SetLocationDistrictList([]);
            SetselectedGlocationRegionList([]);
            SetLocationDistrictList([]);
            SetselectedGlocationDistrictList([]);
            asyncFunRegionLocationBindDetails(false);
            asyncFunCentresbylocations(true);
            stringRegions = "";
            stringRegionIds = "0";
            stringDistricts = "";
            stringDistrictIds = "0";

            globaltblAllGroups = globaltblAllGroups.map(el => (
                el.IsAlreadyHaveDB == "False" ? { ...el, LocationRegions: stringRegions, LocationRegionIds: stringRegionIds, LocationDistricts: stringDistricts, LocationDistrictIds: stringDistrictIds } : el
            ));
            StateOptionSelFlag = true;


        }



        console.log(JSON.stringify(globallocationStateList));
        //SetgloballocationStateList

    }
    const RegionSelectionHandler = (options) => {


        if (options != null && options.length > 0) {
            let hasMatchSecond = globaltblExistGroups.some(function (d) {
                return d.ModuleId == globalModuleId &&
                    d.LocationRegionIds.split(',').some(host => options.map(val => val["IsHaveDistrictStatus"] == "0" && val["RegionId"]).indexOf(host) != -1)
            });
            if (hasMatchSecond) {
                SweetAlert.fire({ title: "Warning", text: "you have already choose selected region or District. choose different region or District", icon: "warning" });
            }
            else {
                SetselectedGlocationRegionList(options);
                LGlbLocationRegionList = [];
                const TypeOptDocL = [];
                options.map((option) => {
                    const item = {
                        "RegionId": option.value
                    };
                    LGlbLocationRegionList.push(item);
                });
                asyncFunDistinctLocationBindDetails(true);
                asyncFunCentresbylocations(true);
            }

        }
        else {
            SetselectedGlocationRegionList(options);
            LGlbLocationRegionList = [];
            SetLocationDistrictList([]);
            LGLocationDistrictList = [];
            asyncFunDistinctLocationBindDetails(true);
            asyncFunCentresbylocations(true);
            SetuserModuleShow(false);
        }
        SelectedAllStateFlag = false;
        SelectedAllregionFlag = false;
        isFirstRender.current = true;
    }
    const DistrictSelectionHandler = (options) => {


        if (options != null && options.length > 0) {
            let hasMatchSecond = globaltblExistGroups.some(function (d) {
                return d.ModuleId == globalModuleId &&
                    ((d.LocationRegionIds.split(',').some(host => selectedGlocationRegionList.map(val => val["IsHaveDistrictStatus"] == "0" && val["RegionId"]).indexOf(host) != -1))
                        || (d.LocationDistrictIds.split(',').some(host => options.map(val => val["DistrictId"]).indexOf(host) != -1))
                    )
            });
            if (hasMatchSecond) {
                SweetAlert.fire({ title: "Warning", text: "you have already choose selected region or District. choose different region or District", icon: "warning" });
            }
            else {
                SetselectedGlocationDistrictList(options);
                LGLocationDistrictList = [];
                const TypeOptDocL = [];
                options.map((option) => {
                    const item = {
                        "DistrictId": option.value
                    };
                    LGLocationDistrictList.push(item);
                });
                asyncFunCentresbylocations(true);

            }

        }
        else {
            SetselectedGlocationDistrictList(options);
            LGLocationDistrictList = [];
            asyncFunCentresbylocations(true);
        }
        SelectedAllStateFlag = false;
        SelectedAllregionFlag = false;
        isLastRender.current = true;

    }


    const DepartmentHandler = (options) => {

        SetSelectDepartment(options);
        SetSelectDesginationDDL([]);
        SetSelectDesgination(null);
        SetSelectUserDDL([]);
        SetSelectUsers([]);
        SetSelectDiscountShare(null);
        if (options != null && options.DepartmentId != null && options.DepartmentId != "0") {
            asyncBindFunDesginationDDL(options.DepartmentId);
        }
    }
    const DesignationHandler = (options) => {
        console.log(options);
        SetSelectDesgination(options);
        SetSelectUserDDL([]);
        SetSelectUsers([]);
        SetSelectDiscountShare(null);
        if (options != null && options.DesignationId != null && options.DesignationId != "0") {
            asyncBindFunUserDDL(options.DesignationId);
        }
    }
    const EmployeeHandler = (options) => {
        SetSelectUsers(options);
        const TypeOptL = [];
        if (options != null && options.length > 0) {
            options.map((item) => {
                const TypeOptrows = {
                    'EmployeeId': item.value
                }
                return TypeOptL.push(TypeOptrows);
            });
            SetLGlEmployeeTypeoption(TypeOptL);

        }
        asyncFunCentresbylocations();
    }
    const DiscountShareHandler = (options) => {

        SetSelectDiscountShare(options);
    }
    const CentreHandler = (options) => {

        SetSelectCentres(options);
        const TypeOptL = [];
        if (options != null && options.length > 0) {
            options.map((item) => {
                const TypeOptrows = {
                    'CentreId': item.CentreId
                }
                return TypeOptL.push(TypeOptrows);
            });
            SetLGlCentreTypeoption(TypeOptL);

        }
    }
    const MaxhandleChange = event => {
        if (event.target.value != '' && event.target.value != null) {
            if (Number(event.target.value) > 0 && Number(event.target.value) <= 100) {
                SetMaxDiscountField(event.target.value);
            } else {
                SweetAlert.fire('Disc. % Allow only 1 to 100.');
            }
        }
        else {
            SetMaxDiscountField(event.target.value);
        }
    };
    return (
        <Fragment>
            <Breadcrumb title="Add Discount" parent="Master" />
            <Container fluid>

                <Row>
                    <Col md={12}>
                        <Card>
                            <div class="pb-0 card-header"><div class="card-title h5">Add Discount</div></div>
                            <Card.Body>
                                <Form.Row className="justify-content-center">

                                    <Col md={6} lg={6}>
                                        <Form.Group>
                                            <Form.Label>Departments<span className='text-required'>* </span></Form.Label>
                                            <Select
                                                onChange={DepartmentHandler}
                                                options={SelectDepartmentDDL}
                                                value={SelectDepartment}
                                            />
                                            {((SelectDepartment == null || SelectDepartment.length == 0) && IsButtonSubmit == true) ? (<div className='text-required'>Department is Required</div>) : null}
                                        </Form.Group>
                                    </Col>

                                </Form.Row>
                                <Form.Row className="justify-content-center">

                                    <Col md={6} lg={6}>
                                        <Form.Group>
                                            <Form.Label>Designation<span className='text-required'>* </span></Form.Label>
                                            <Select
                                                onChange={DesignationHandler}
                                                options={SelectDesginationDDL}
                                                value={SelectDesgination}
                                            />
                                            {((SelectDesgination == null || SelectDesgination.length == 0) && IsButtonSubmit == true) ? (<div className='text-required'>Designation is Required</div>) : null}
                                        </Form.Group>
                                    </Col>

                                </Form.Row>
                                <Form.Row className="justify-content-center">

                                    <Col md={6} lg={6}>
                                        <Form.Group>
                                            <Form.Label>Employee<span className='text-required'>* </span></Form.Label>
                                            <MultiSelect id="EmployeeSelect" name="EmployeeSelect"
                                                options={SelectUserDDL}
                                                value={SelectUsers}
                                                onChange={EmployeeHandler}
                                                // valueRenderer={customStateValRenderer}
                                                labelledBy="Select"
                                            />
                                            {((SelectUsers == null || SelectUsers.length == 0) && IsButtonSubmit == true) ? (<div className='text-required'>Employee is Required</div>) : null}
                                        </Form.Group>
                                    </Col>

                                </Form.Row>
                                <Form.Row className="justify-content-center">

                                    <Col md={6} lg={6}>
                                        <Form.Group>
                                            <Form.Label>Max Discount Per Month(in Rs)<span className='text-required'>* </span></Form.Label>
                                            <Form.Control type='number' id="maxdiscount" name="maxdiscount"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.maxdiscount}

                                            />
                                            {formik.touched.maxdiscount && formik.errors.maxdiscount ? (<div className='text-required'>{formik.errors.maxdiscount}</div>) : null}
                                        </Form.Group>
                                    </Col>

                                </Form.Row>
                                <Form.Row className="justify-content-center">

                                    <Col md={6} lg={6}>
                                        <Form.Group>
                                            <Form.Label>Max Disc.(%)<span className='text-required'>* </span></Form.Label>
                                            <Form.Control type='number' id="maxdiscountprecent" name="maxdiscountprecent"
                                                onChange={MaxhandleChange}
                                                //  onBlur={formik.handleBlur}
                                                value={MaxDiscountField}

                                            />
                                        </Form.Group>
                                    </Col>

                                </Form.Row>
                                {/* <Form.Row className="justify-content-center">
                                       
                                       <Col md={6} lg={6}>
                                               <Form.Group>
                                                               <Form.Label>Share Type<span className='text-required'>* </span></Form.Label>
                                                               <Select 
                                                    onChange={DiscountShareHandler}
                                                    options={SelectDiscountShareDDL}
                                                value={SelectDiscountShare}
                                                        />   

                                             {((SelectDiscountShare==null ||SelectDiscountShare.length==0) && IsButtonSubmit==true)  ? (<div className='text-required'>Selection Share is Required</div>) : null} 
                                     
                                                           </Form.Group>
                                          </Col>
                                        
                                       </Form.Row> */}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <div class="pb-0 card-header"><div class="card-title h5">Centre Search</div></div>
                            <Card.Body>

                                <Form.Row className="justify-content-center">

                                    <Col md={4} lg={4}>
                                        <Form.Group>
                                            <Form.Label>State<span className='text-required'>*</span></Form.Label>
                                            <MultiSelect id="stateSelect" name="stateSelect"
                                                options={LocationStateList}
                                                value={selectedGlocationStateList}
                                                onChange={stateSelectionHandler}
                                                labelledBy="Select"
                                            />
                                            {(globallocationStateList.length == 0) && IsButtonSubmit == true ? (<div className='text-required'>{"State Selection is Required"}</div>) : null}
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} lg={4}>
                                        <Form.Group>
                                            <Form.Label>Region<span className='text-required'>*</span></Form.Label>
                                            <MultiSelect id="regionSelect" name="regionSelect"
                                                options={LocationRegionList}
                                                disabled={SelectedAllStateFlag}
                                                value={selectedGlocationRegionList}
                                                onChange={RegionSelectionHandler}
                                                labelledBy="Select"
                                            />

                                            {(LocationRegionList.length > 0 && selectedGlocationRegionList.length == 0) && IsButtonSubmit == true ? (<div className='text-required'>{"Region Selection is Required"}</div>) : null}

                                        </Form.Group>
                                    </Col>
                                    <Col md={4} lg={4}>
                                        <Form.Group>
                                            <Form.Label>District<span className='text-required'>*</span></Form.Label>
                                            <MultiSelect id="DistrictSelect" name="DistrictSelect"
                                                disabled={SelectedAllregionFlag}
                                                options={LocationDistrictList}
                                                value={selectedGlocationDistrictList}
                                                onChange={DistrictSelectionHandler}
                                                labelledBy="Select"
                                            />
                                            {(LocationDistrictList.length > 0 && LGLocationDistrictList.length == 0) && IsButtonSubmit == true ? (<div className='text-required'>{"District Selection is Required"}</div>) : null}
                                        </Form.Group>
                                    </Col>
                                </Form.Row>

                                <Form.Row className="justify-content-left">

                                    <Col md={4} lg={4}>
                                        <Form.Group>
                                            <Form.Label>Centre<span className='text-required'>*</span></Form.Label>
                                            <MultiSelect id="CentreSelect" name="CentreSelect"
                                                //  valueRenderer={CustomDistrictValRenderer}  
                                                options={SelectCentreDDL}
                                                value={SelectCentres}
                                                onChange={CentreHandler}
                                                labelledBy="Select"
                                            />
                                            {(SelectCentres == null || SelectCentres.length == 0) && IsButtonSubmit == true ? (<div className='text-required'>{"Centre Selection is Required"}</div>) : null}
                                        </Form.Group>
                                    </Col>

                                </Form.Row>

                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className='mt15'>
                    <Col md={12} className='text-center'>
                        <Button variant='secondary' className="mr-2" onClick={PrevBckHandler} >Cancel</Button>
                        {<Button variant='primary' type='submit' onClick={errorHandler}>Save</Button>}
                    </Col>
                </Row>
            </Container>

            {errorModal &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                    <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                    <ModalBody>
                        <p>
                            {((SelectDepartment == null || SelectDepartment.length == 0) && IsButtonSubmit == true) ? (<div className='text-required'>Department is Required</div>) : null}
                        </p>
                        <p>
                            {((SelectDesgination == null || SelectDesgination.length == 0) && IsButtonSubmit == true) ? (<div className='text-required'>Designation is Required</div>) : null}
                        </p>
                        <p>
                            {((SelectUsers == null || SelectUsers.length == 0) && IsButtonSubmit == true) ? (<div className='text-required'>Employee is Required</div>) : null}
                        </p>
                        <p> {formik.touched.maxdiscount && formik.errors.maxdiscount ? (<div className='text-required'>{formik.errors.maxdiscount}</div>) : null}</p>
                        <p>  {((SelectDiscountShare == null || SelectDiscountShare.length == 0) && IsButtonSubmit == true) ? (<div className='text-required'>Selection Share is Required</div>) : null} </p>
                        <p>
                            {(globallocationStateList.length == 0) && IsButtonSubmit == true ? (<div className='text-required'>{"State Selection is Required"}</div>) : null}
                        </p>
                        <p>
                            {(GGLocationRegionList.length > 0 && LGlbLocationRegionList.length == 0) && IsButtonSubmit == true ? (<div className='text-required'>{"Region Selection is Required"}</div>) : null}
                        </p>
                        <p>
                            {(GGLocationDistrictList.length > 0 && selectedGlocationDistrictList.length == 0) && IsButtonSubmit == true ? (<div className='text-required'>{"District Selection is Required"}</div>) : null}
                        </p>
                        <p>{(SelectCentres == null || SelectCentres.length == 0) && IsButtonSubmit == true ? (<div className='text-required'>{"Centre Selection is Required"}</div>) : null}</p>

                    </ModalBody>
                    <ModalFooter>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                        <Button variant="primary" onClick={toggleModal}>Ok</Button>
                    </ModalFooter>

                </Modal>
            }
        </Fragment>
    )
}

export default AddDiscount;