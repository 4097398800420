import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import Breadcrumb from '../../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CSVLink } from "react-csv";
import { utils, writeFile } from 'xlsx';
import { MultiSelect } from 'react-multi-select-component';
import '../../../pages/admin/react-multiselect.css';
import Select from 'react-select';
import { AlertTriangle } from 'react-feather';
let glbCurrentList = [];
const SalesReportNew = () => {
    let textSearch = useRef();
    let fromdateSearch = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [reportHeaderData, setReportHeaderData] = useState([]);
    const [reportLineItemData, setReportLineItemData] = useState([]);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [reportType, setReportType] = useState("2");
    const [displayDownloadButton, setDisplayDownloadButton] = useState(false);
    const [hideDownload, setHideDownload] = useState(true);


    const [SelectCentreDDL, SetSelectCentreDDL] = useState([]);
    const [SelectCentres, SetSelectCentres] = useState([]);
    const [LGlCentreTypeoption, SetLGlCentreTypeoption] = useState([]);
    const [filtertype, setFilterType] = useState("2");
    const [CompanySelected, setCompanySelected] = useState([]);
    const [SelectCompanyDDL, SetSelectCompanyDDL] = useState([]);

    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const paginationOptions = { sizePerPage: 5, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last', onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); } };
    const headerColumns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        // { dataField: 'DocNum', text: 'DocNum', editable: false },
        { dataField: 'Invoice', text: 'Invoice', editable: false },
        { dataField: 'CardCode', text: 'CardCode', editable: false },
        { dataField: 'U_WhCode', text: 'U_WhCode', editable: false },
        { dataField: 'ReceiptNumber', text: 'ReceiptNumber', editable: false },
        { dataField: 'UnitPrice', text: 'UnitPrice', editable: false },
        { dataField: 'docdate', text: 'DocDate', editable: false },
        { dataField: 'Quantity', text: 'Quantity', editable: false },
        { dataField: 'Series', text: 'Series', editable: false, headerStyle: (colum, colIndex) => { return { width: '4%' }; } },
    ];
    const lineItemColumns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        // { dataField: 'DocNum', text: 'DocNum', editable: false },
        { dataField: 'CardCode', text: 'CardCode', editable: false },
        { dataField: 'U_WhCode', text: 'U_WhCode', editable: false },
        { dataField: 'ItemCode', text: 'ItemCode', editable: false },
        { dataField: 'Price', text: 'Price', editable: false },
        { dataField: 'Quantity', text: 'Quantity', editable: false },
        { dataField: 'ReceiptNumber', text: 'ReceiptNumber', editable: false },
        { dataField: 'UseDownpayment', text: 'UseDownpayment', editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    ];
    useEffect(() => {
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        var curntDate = year + "-" + month + "-" + date;
        textSearch.current.value = curntDate;
        fromdateSearch.current.value = curntDate;
        GetCompanyName();
        // BindGrid(fromdateSearch.current.value, textSearch.current.value);
    }, []);

    const handleRadioChange = async (e) => { 
        console.log(e.target.value); 
        GetBindCenterAllLists(e.target.value, CompanySelected); 
        setReportType(e.target.value); 
        // SetLGlCentreTypeoption([]);
        // SetSelectCentres([]);
    }

    const handleSearch = async () => {
        if (LGlCentreTypeoption.length === 0) {
            SweetAlert.fire("Please select centre"); return false; 
          }
        if (fromdateSearch.current.value === "") { SweetAlert.fire("Please enter from date"); return false; }
        if (textSearch.current.value === "") { SweetAlert.fire("Please enter to date"); return false; }
        // if ((date1 !== "")) { SweetAlert.fire("Please enter from date"); return false; }
        setCrntPageNO(0);
        var searchDate = textSearch.current.value;
        var searchFromDate = fromdateSearch.current.value;
        let newDate = new Date()
        let date = newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        var curntDate = year + "-" + month + "-" + date;
        if ((searchFromDate !== "") && (searchDate !== "")) {
            if (searchFromDate > searchDate) {
                SweetAlert.fire({ title: "Wrong Date Selection", text: "From date always less than or equal to to date.", icon: 'warning' });
                return false;
            }
            else if (searchDate > curntDate) {
                SweetAlert.fire({ title: "Wrong Date Selection", text: "To date always less than or equal to current date.", icon: 'warning' });
                return false;
            }
        }

        BindGrid(searchFromDate, searchDate);

    }
    const handleDownload = async () => {
        setCrntPageNO(0);
        // document.getElementById("HeaderCsvLink").click();
        //  document.getElementById("LineItemCsvLink").click();
        var searchDate = textSearch.current.value;
        DownloadData(fromdateSearch.current.value, searchDate);

    }
    const BindGrid = async (fromdateSearch, reportDate) => {
        glbCurrentList = [];
        setIsLoading(true);
        const requestOptions = { ReportType: reportType, SaleFromDate: fromdateSearch, SaleDate: reportDate, objtblCentreIds: LGlCentreTypeoption };
        let url = URL_Start + 'master/getsapsalesreportnew/'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                var reportData = data.Data;
                setReportHeaderData(reportData.ARPositive);
                setReportLineItemData(reportData.ARCreditPositive);
                if (reportData.ARPositive.length > 0) { setDisplayDownloadButton(true); } else { setDisplayDownloadButton(false); }
                setIsLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }

    const DownloadData = async (fromdateSearch, reportDate) => {
        if ((fromdateSearch !== "") && (reportDate === "")) { SweetAlert.fire("Please enter to date"); return false; }
        if ((reportDate !== "") && (fromdateSearch === "")) { SweetAlert.fire("Please enter from date"); return false; }
        glbCurrentList = [];
        //setIsLoading(true);
        const requestOptions = { ReportType: reportType, SaleFromDate: fromdateSearch, SaleDate: reportDate, objtblCentreIds: LGlCentreTypeoption  };
        let url = URL_Start + 'master/getsapsalesreportnew/'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                var reportData = data.Data;
                if (reportData.ARPositive.length > 0) {
                    const headings = [[
                        'Invoice',
                        'CardCode',
                        'BPL_IDAssignedToInvoice',
                        'U_WhCode',
                        'Series',
                        'ItemCode',
                        'UnitPrice',
                        'Quantity',
                        'Remarks',
                        'BinAbsEntry',
                        'BatchNumber',
                        'ReceiptNumber',
                        'UseDownpayment',
                        'postingdate',
                        'docdate',
                        'duedate'
                    ]];
                    const wb = utils.book_new();
                    const ws = utils.json_to_sheet([]);
                    utils.sheet_add_aoa(ws, headings);
                    utils.sheet_add_json(ws, reportData.ARPositive, { origin: 'A2', skipHeader: true });
                    utils.book_append_sheet(wb, ws, 'ARPositive');
                    writeFile(wb, 'AR.csv');

                    const headings1 = [[
                        'CardCode',
                        'U_WhCode',
                        'BPL_IDAssignedToInvoice',
                        'JrnlMemo',
                        'OriginalRefNo',
                        'OriginalRefDate',
                        'ItemCode',
                        'Price',
                        'Quantity',
                        'WithoutInventoryMovement',
                        'Series',
                        'postingdate',
                        'docdate'
                    ]];
                    const wbp = utils.book_new();
                    const wsp = utils.json_to_sheet([]);
                    utils.sheet_add_aoa(wsp, headings1);
                    utils.sheet_add_json(wsp, reportData.ARCreditPositive, { origin: 'A2', skipHeader: true });
                    utils.book_append_sheet(wbp, wsp, 'ARCreditPositive');
                    writeFile(wbp, 'ARCredit.csv');
                }
                else {
                    //setIsLoading(false);
                }
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });

    }

    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }

    const GetBindCenterAllLists = async (ftypevalue,CompanyID) => {
        SetLGlCentreTypeoption([]);
        SetSelectCentres([]);
        // alert(ftypevalue);
        // alert(CompanyID);
        const ObjFilterCentye = [];
        const requestOptions = {
            CentreFilterType: ftypevalue,
            CompanyID : CompanyID
        };
        try {
            let url = URL_Start + 'master/getBindCompanyAllCentreListsNew'
            await axios.post(url, requestOptions).then((response) => { //ObjFilterCentye
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        let ds = data.Data;
                        const TypeOptionDs1 = [];
                        ds.map((option) => {
                            const TypeOptions1 = {
                                'CentreId': option.CentreId,
                                'label': option.CentreName,
                                'value': option.CentreId,
                                'CenterTypeId': option.CenterTypeId,
                                'disabled': false
                            }
                            return TypeOptionDs1.push(TypeOptions1);
                        });
                        SetSelectCentreDDL(TypeOptionDs1);

                    }
                    else { SetSelectCentreDDL([]); }
                }
            }).catch(function (error) {
                //ErrorFunction(error.response.status);
                SetSelectCentreDDL([]);
            });
        }
        catch (error) {
            SetSelectCentreDDL([]);
            SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }

    const CentreHandler = (options) => {
        SetSelectCentres(options);
        const TypeOptL = [];
        if (options != null && options.length > 0) {
            options.map((item) => {
                const TypeOptrows = {
                    'CentreId': item.CentreId
                }
                return TypeOptL.push(TypeOptrows);
            });
            SetLGlCentreTypeoption(TypeOptL);
        }
        else {
            SetLGlCentreTypeoption([]);
        }
    }

    const GetCompanyName = async () => {
        try {
            var glblArr1 = [];
            let url = URL_Start + 'master/GetCompanyName'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { 
                    var itmData = data.Data;
                        itmData.map((option) => { return glblArr1.push({ 'value': option.Id, 'label': option.Companyname, 'disabled': false }); });
                        SetSelectCompanyDDL(glblArr1);
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const CompanyChangeHandler = (options) => 
    { 
        setCompanySelected(options.value); 
        GetBindCenterAllLists(reportType, options.value);
    }

    return (
        <React.Fragment>
            <Breadcrumb title="Sales Report New" parent="SAP" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                    <Form.Row className='justify-content-center'>
                                        <Col lg={7} md={7} className="salesreportsec pt-2">
                                            <Form.Label>Company</Form.Label>
                                            <Select 
                                            onChange={CompanyChangeHandler}
                                            options={SelectCompanyDDL}
                                            value={SelectCompanyDDL.filter(function (x) 
                                            { return x.value === CompanySelected; })} 
                                            styles={{
                                                menu: (provided) => ({
                                                    ...provided,
                                                    zIndex: 999
                                                })
                                            }}
                                            />
                                        </Col>
                                    </Form.Row>
                                <Form.Row className="justify-content-center">
                                    <Col lg={7} md={7} className="salesreportsec pt-2">
                                        <Form.Label>Report Type</Form.Label>
                                        <Form.Label for="centreType1" className='ml-3'>
                                            <input class="radio_animated" type="radio" id='centreType' name="rdoo-ani" defaultChecked onChange={handleRadioChange} value='1' />All Centres
                                        </Form.Label>
                                        <Form.Label for="centreType1" className='ml-3'>
                                            <input class="radio_animated" type="radio" id='centreType1' name="rdoo-ani" onChange={handleRadioChange} value='2' />Own Centres
                                        </Form.Label>
                                        <Form.Label for="centreType2" className='ml-3'>
                                            <input class="radio_animated" type="radio" id='centreType2' name="rdoo-ani" onChange={handleRadioChange} value='3' />Third Party
                                        </Form.Label>
                                        <Form.Label for="centreType2" className='ml-3'>
                                            <input class="radio_animated" type="radio" id='centreType3' name="rdoo-ani" onChange={handleRadioChange} value='4' />Panel
                                        </Form.Label>
                                    </Col>
                                    </Form.Row>
                                    <Form.Row className='justify-content-center'>
                                        <Col lg={7} md={7} className="salesreportsec pt-2">
                                            <Form.Label>Centre</Form.Label>
                                                <div className='txtMultiSelect1'>
                                                    <MultiSelect id="CentreSelect" name="CentreSelect"
                                                        options={SelectCentreDDL}
                                                        value={SelectCentres}
                                                        onChange={CentreHandler}
                                                        labelledBy="Select"
                                                    /></div>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row className='justify-content-center'>
                                    <Col lg={3} md={3} className="salesreportsec pt-2">
                                        <Form.Group>
                                            <Form.Label>From Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="fromdate" name="fromdate" ref={fromdateSearch} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={3} md={3} className="salesreportsec pt-2">
                                        <Form.Group>
                                            <Form.Label>To Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="date1" name="date1" ref={textSearch} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={1} md={1} className="salesreportsec pt-2"></Col>
                                    </Form.Row>
                                    <Form.Row className='justify-content-center'>
                                    <Col md={2} className='text-center'>
                                        <Button variant='primary' onClick={handleSearch} >Search</Button>
                                    </Col>
                                    {displayDownloadButton ?
                                        <Col md={2}>
                                            <Form.Group>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Append>
                                                        <Button variant='primary' type='Button' onClick={handleDownload}>Download Report</Button>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        : null}
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>AR</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <DataTables keyField='id' tableData={reportHeaderData} columns={headerColumns} pagination={paginationFactory(paginationOptions)} />
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>ARCredit</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <DataTables keyField='id' tableData={reportLineItemData} columns={lineItemColumns} pagination={paginationFactory(paginationOptions)} />
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default SalesReportNew;