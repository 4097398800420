import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import SweetAlert from 'sweetalert2';
import axios from 'axios';
import LoadingButton from '../commonComponent/LoadingButton';
import { Spinner } from 'react-bootstrap';
import DynamicDataTables from '../commonComponent/DataTable/DynamicDataTable';
import paginationFactory from 'react-bootstrap-table2-paginator';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import ToolTip from '../commonComponent/ToolTip';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { FlatCard, URL_Start } from '../../../constant';

const Doctortitle = () => {
    const [isSaving, setIsSaving] = useState(false);
    const [tabledata, setTableData] = useState([]);
    const [Drtitleid, setDrtitleid] = useState("0");
    const [isLoading, setIsLoading] = useState(false);
    const [modal, setModal] = useState();
    const toggleModal = () => {
        setModal(!modal)
    }

    const [PageHelper, SetPageHelper] = useState({
        PageNo: '1',
        PageSize: '10',
        TotalRecord: 0,
        dsDoctorTitlelists: []

    });
    useEffect(() => {
        BindGrid(1, 10);
    }, [])
    const BindGrid = async (PageNo, PageSize) => {
        setIsLoading(true);
        try {

            let url = URL_Start + 'DoctorMaster/getdoctortitleDetails';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { PageNo: `${PageNo}`, PageSize: `${PageSize}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;

                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {

                        await SetPageHelper({
                            ...PageHelper,
                            PageNo: Number(PageNo),
                            PageSize: Number(PageSize),
                            TotalRecord: Number(ds.TotalRecord),
                            dsDoctorTitlelists: ds.ObjDoctorTitleDetailsList == null ? [] : ds.ObjDoctorTitleDetailsList
                        });

                        setIsLoading(false);
                    }
                     else{
                        setIsLoading(false);
                     }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');
                    setIsLoading(false);
                }

            }
            else { 
                setIsLoading(false);
                SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
            // return data;
        } catch (error) {
            if (error.response) {
                ErrorFunction(error.response.status);
                setIsLoading(false);
            }

        }
    }
    const formik = useFormik({
        initialValues: {
            Drtitle: ''
        },
        validationSchema: Yup.object({
            Drtitle: Yup.string()
                .required('Doctor title is Required').matches(/^[A-Za-z.]+$/, "Only alphabets are allowed and space not allowed for Doctor title"),
            // Drtitle: Yup.string().required('Title is required'),

        }),
        onSubmit: values => {
            Savedrtitle(values);
        },
    });
    const editformik = useFormik({
        initialValues: {
            Drtitle: ''
        },
        validationSchema: Yup.object({
            // Drtitle: Yup.string().required('Title is required'),
             Drtitle: Yup.string()
             .required('Doctor title is Required').matches(/^[A-Za-z.]+$/, "Only alphabets are allowed and space not allowed for Doctor title"),
        }),
        onSubmit: values => {
            Savedrtitle(values);
        },
    });

    const Savedrtitle = async (values) => {
        if (values.Drtitle === "") { SweetAlert.fire({ title: "Validation Failed!", text: "Title is mandatory.", icon: "error" }); return false; }
        setIsSaving(true);
        const requestOptions = {
            DrTitle: values.Drtitle,
            AddedBy: localStorage.LoggedInUser,
            DrTitleId: Drtitleid
        };
        let url = URL_Start + 'DoctorMaster/savedoctortitledetails'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsSaving(false);
            if (data.Success) {
                if (Drtitleid === "0") { SweetAlert.fire({ title: "Success", text: "Data saved successfully", icon: "success" }); }
                else { SweetAlert.fire({ title: "Success", text: "Data updated successfully", icon: "success" }); }
                cancelHandler();
                setDrtitleid("0");
                BindGrid(1, 10);
            }
            else {
                SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" });
                setIsSaving(false);
            }
        }).catch(function (error) { setIsSaving(false); if (error.response) { ErrorFunction(error.response.status); } });
    }
    const cancelHandler = async () => {
        formik.resetForm();
        editformik.resetForm();
        formik.values.Drtitle = "";
        editformik.values.Drtitle="";
    }
    const ActionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div >
            {/* onClick={() => editHandler(rowData)} */}
                <ToolTip title="Edit" placement="top" onClick={() => editHandler(rowData)} linkId="Edit" faName="fa fa-pencil mr-2"></ToolTip>
                <ToolTip title="Delete" placement="top" onClick={() => SelectOption(rowData)} linkId="Delete" faName="fa fa-trash mr-2"></ToolTip>
            </div>
        </React.Fragment>
    }
    const columns = [{
        dataField: '#',
        text: '#',
        formatter: (cell, row, rowIndex) => {
            let rowNumber = (PageHelper.PageNo - 1) * PageHelper.PageSize + (rowIndex + 1);
            return <span>{rowNumber}</span>;
        },
        headerStyle: (colum, colIndex) => { return { width: '5%' }; }

    },
    {
        dataField: 'DoctorTitleName',
        text: 'Doctor Title',
        editable: false

    },
    {
        dataField: 'actions',
        isDummyField: true,
        text: 'Actions',
        formatter: ActionFormatter,
        headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    }
    ];
    const paginationOptions = {

        totalSize: PageHelper.TotalRecord,
        sizePerPage: PageHelper.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',

        onSizePerPageChange: (sizePerPage, page, event) => {
            console.log('Size per page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            BindGrid(page, sizePerPage);
            document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
        },
        onPageChange: (page, sizePerPage) => {
            console.log('Page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            BindGrid(page, sizePerPage);
        }
    };
    const SelectOption = async (rowData) => {
            cancelHandler();
            SweetAlert.fire({
                title: 'You are going to delete an item. Are you sure?',
                text: "Once deleted, you will not be able to recover!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonText: 'Ok',
                cancelButtonText: 'Cancel',
                reverseButtons: true
            }).then((result) => { if (result.value) { DeleteDrTitle(rowData.DoctorTitleId); } else { SweetAlert.fire('Your data is safe!') } }) 
    }
    const editHandler = async (rowData) => {
         formik.resetForm();
         editformik.resetForm();
         formik.values.Drtitle = "";
         editformik.values.Drtitle="";
         setDrtitleid("0");
         toggleModal();
        editformik.values.Drtitle = rowData.DoctorTitleName;
        setDrtitleid(rowData.DoctorTitleId);

    }
    const updateHandler = () => {
     editformik.handleSubmit();
     setModal(false);
    }

    const DeleteDrTitle = async (DoctorTitleId) => {
        setIsSaving(true);
        const requestOptions = {
            DrTitleId: DoctorTitleId,
            AddedBy: localStorage.LoggedInUser
        };
        let url = URL_Start + 'DoctorMaster/DeleteDrTitle'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsSaving(false);
            if (data.Success) {
                SweetAlert.fire({ title: "Success", text: "Data deleted successfully", icon: "success" });
                BindGrid(1, 10);
            }
            else {
                SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" });
                setIsSaving(false);
            }
        }).catch(function (error) { setIsSaving(false); if (error.response) { ErrorFunction(error.response.status); } });
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    return (
        <>
            <Breadcrumb title="Doctor Title" parent="Doctor Master" />
            <Container fluid>
                <Form>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Header className='pb-0'>
                                    <Card.Title>Add Doctor Title</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    <Form.Row className="justify-content-center" >
                                        <Col lg={4} md={4} >
                                            <Form.Group>
                                                <Form.Label>Dr. Title<span className='text-required'>*</span></Form.Label>
                                                <Form.Control type="text" name="Drtitle" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.Drtitle} />
                                                {formik.touched.Drtitle && formik.errors.Drtitle ? (<div className='text-required'>{formik.errors.Drtitle}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col lg={12} className='text-center'>
                                            <Button variant='secondary' className="mr-2" id="cancelbutton" onClick={cancelHandler} >Cancel</Button>
                                            {isSaving ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' type='submit' onClick={formik.handleSubmit} >Save</Button>
                                            }
                                        </Col>
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Card>
                                <Card.Header className='pb-0'>
                                    <Card.Title>Doctor Title Detail</Card.Title>
                                </Card.Header>
                                <Card.Body>
                                    {
                                        isLoading &&
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div>
                                    }

                                    <DynamicDataTables keyField="DoctorTitleId" tableData={PageHelper.dsDoctorTitlelists} columns={columns} pagination={paginationOptions ? paginationFactory(paginationOptions) : null} />
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Form>
            </Container>
            {modal &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true} >
                    <ModalHeader toggle={toggleModal}>Update Doctor Title </ModalHeader>
                    <ModalBody>
                            <Row>
                                <Col md={12}>
                                    <Card>
                                        <Card.Body>
                                            <Form.Row className="justify-content-center">
                                                <Col md={12}>
                                                    <Form.Group>
                                                      <Form.Label>Dr. Title Name<span className='text-required'>*</span></Form.Label>
                                                      <Form.Control type="text" name="Drtitle" onChange={editformik.handleChange} onBlur={editformik.handleBlur} value={editformik.values.Drtitle} />
                                                      {editformik.touched.Drtitle && editformik.errors.Drtitle ? (<div className='text-required'>{editformik.errors.Drtitle}</div>) : null}
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>

                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row> 
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="primary" onClick={updateHandler}>Update</Button>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            }
        </>
    )
}

export default Doctortitle