import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import Breadcrumb from '../../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { useFormik } from 'formik';
import * as Yup from 'yup';
import LoadingButton from '../commonComponent/LoadingButton';
import { CSVLink } from "react-csv";
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Typeahead } from 'react-bootstrap-typeahead';

const Ledgers = () => {
    let stateId = useRef();
    let regionId = useRef();
    let ledgerId = useRef();
    let fromDate = useRef();
    let toDate = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [sapStatesList, setSAPStatesList] = useState([]);
    const [sapRegionsList, setSAPRegionsList] = useState([]);
    const [ledgerNamesList, setLedgerNamesList] = useState([]);
    const [noteTypesList, setNoteTypesList] = useState([]);
    const [ledgerType, setLedgerType] = useState("1");
    const [noteTypeLabel, setNoteTypeLabel] = useState("Debit Note");
    const [tableData, setTableData] = useState([]);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [isLedgerExist, setIsLedgerExist] = useState(false);
    const [testsModal, setTestsModal] = useState();
    const openModal = () => { setTestsModal(!testsModal); };
    const [invalidfiletype, setInvalidFileType] = useState(false)
    const [modal, setModal] = useState();
    const [errorModal, setErrorModal] = useState();
    const errorHandler = () => { setModal(true); setErrorModal(true); }
    const [isSaving, setIsSaving] = useState(false);
    const [csvLedger, setCSVLedger] = useState([]);
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [isButton, setIsButton] = useState(false);
    const [lid, setLID] = useState("0");

    const paginationOptions = { sizePerPage: 50, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last', onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); } };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'Date', text: 'Date', editable: false },
        { dataField: 'TransactionType', text: 'Transaction Type', editable: false },
        { dataField: 'ReferenceId', text: 'Reference Id', editable: false },
        { dataField: 'PatientName', text: 'Patient Name', editable: false },
        { dataField: 'Amount', text: 'Amount', editable: false },
        { dataField: 'ActiveBalance', text: 'Active Balance', editable: false },
        { dataField: 'Bank', text: 'Bank', editable: false },
        { dataField: 'EntryBy', text: 'Entry By', editable: false },
        { dataField: 'ApprovedBy', text: 'Approved By', editable: false },
        { dataField: 'Remarks', text: 'Remarks', editable: false },
        { dataField: 'CreditDebitNoteType', text: 'Credit/Debit Note Type', editable: false },
        // { dataField: '', text: 'Action', formatter: actionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } }
    ];
    useEffect(() => { GetSAPStates("1"); GetLedgerNames("1", "0"); }, []);
    const handleRadioChange = async (e) => {
        ledgerId.current.clear();
        setTableData([]);
        setLedgerType(e.target.value);
        fromDate.current.value = "";
        toDate.current.value = "";
        //ledgerId.current.value = "";
        setLID("0");
        if (e.target.value === "1") { GetLedgerNames("1", "0"); } else { setLedgerNamesList([]); }
    }
    const handleSearch = async () => {
        // if (ledgerId.current.value != "0" && fromDate.current.value != "" && toDate.current.value != "") { BindGrid("0"); }
        if (lid != "0" && fromDate.current.value != "" && toDate.current.value != "") { BindGrid("0"); }
        else { setTableData([]); }
    }
    const GetLedgerNames = async (lType, rId) => {
        setIsButton(true);
        try {
            let url = URL_Start + 'accounts/getledgersname/' + lType + '/' + rId
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsButton(false);
                if (data.Success) {
                    //setLedgerNamesList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>)));
                    setLedgerNamesList(data.Data);
                }
            }).catch(function (error) { setIsButton(false); });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetSAPStates = async (id) => {
        try {
            let url = URL_Start + 'master/getstateslist/' + id
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setSAPStatesList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const StateChangeHandler = (e) => {
        if (e.target.value !== "0") { GetSAPRegions(e.target.value); } else { setSAPRegionsList([]); }
        setLedgerNamesList([]);
    }
    const GetSAPRegions = async (id) => {
        try {
            let url = URL_Start + 'master/getregionslist/' + id;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setSAPRegionsList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const handleRegionChange = async (e) => {
        if (e.target.value === "0") { setLedgerNamesList([]); } else { GetLedgerNames("2", e.target.value); }
    }
    const BindGrid = async (isCSV) => {
        // if (ledgerId.current.value != "0") { setIsLedgerExist(true); } else { setIsLedgerExist(false); }
        if (lid != "0") { setIsLedgerExist(true); } else { setIsLedgerExist(false); }
        setIsLoading(true);
        // let url = URL_Start + 'accounts/getledgersinfo/' + ledgerId.current.value + "/" + ledgerType + "/" + fromDate.current.value + "/" + toDate.current.value
        let url = URL_Start + 'accounts/getledgersinfo/' + lid + "/" + ledgerType + "/" + fromDate.current.value + "/" + toDate.current.value
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data !== null) {
                    if (isCSV === "0") { setTableData(data.Data); } else { setDownloadVisible(true); setCSVLedger(data.Data); document.getElementById("csvDownload").click(); }
                } else { setTableData([]); setDownloadVisible(false); }
                setIsLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const handleDebitCreditNote = async () => { GetNoteTypes(); openModal(); }
    const handleNoteTypeChange = async (e) => {
        formik.values.noteType = e.target.value;
        if (e.target.value === "1") { setNoteTypeLabel("Debit Note"); } else { setNoteTypeLabel("Credit Note"); }
    }
    const GetNoteTypes = async () => {
        try {
            let url = URL_Start + 'accounts/getdebitcreditnotetypes'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setNoteTypesList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const UploadImages = async (e, folderName) => {
        const extension = e.target.files[0].name.split(".");
        if (extension[1].toLowerCase() === "pdf" || extension[1].toLowerCase() === "jpg" || extension[1].toLowerCase() === "jpeg" ||
            extension[1].toLowerCase() === "png" || extension[1].toLowerCase() === "gif" || extension[1].toLowerCase() === "svg") {
            setInvalidFileType(false);
            var base64 = await toBase64(e.target.files[0]);
            var base64img = base64.split("base64,");
            const formData = new FormData();
            formData.append('Image', base64img[1]);
            formData.append('FolderName', folderName);
            const randomImageId = Math.random();
            var imgName = randomImageId + folderName + "." + extension[extension.length - 1];
            formData.append('ImageName', imgName);
            let url = URL_Start + 'master/UploadImage/'
            await axios.post(url, formData, {
                headers: { "Content-type": "multipart/form-data" },
            }).then((response) => {
                const data = response.data;
                if (data.Success) { formik.values.noteReceipt = imgName; }
                else { SweetAlert.fire({ title: "Upload failed", text: "Unable to upload document due to " + data.Data + ".Please retry again", icon: "error" }) }
            }).catch(function (error) { if (error.response) { } });
        }
        else { SweetAlert.fire("Invalid file type! You can upload only image/pdf only, please reupload valid file only."); setInvalidFileType(true); return false; }

    }
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
    const formik = useFormik({
        initialValues: { noteType: '1', noteReason: '', noteAmount: '', noteNarration: '', noteReceipt: '' },
        validationSchema: Yup.object({
            noteAmount: Yup.number().min(1, 'Amount must be than zero').typeError('Amount must be numeric only').required('Amount is required'),
            noteReason: Yup.string().required('Reason is required'),
        }),
        onSubmit: values => {
            setModal(false);
            setErrorModal(false);
            AddDebitCreditNote(values);
        }
    });
    const AddDebitCreditNote = async (values) => {
        setIsSaving(true);
        const requestOptions = {
            NoteType: values.noteType,
            NoteReasonId: values.noteReason,
            Amount: values.noteAmount,
            Narration: values.noteNarration,
            ReceiptName: values.noteReceipt,
            LedgerTypeId: ledgerType,
            // LedgerHolderId: ledgerId.current.value,
            LedgerHolderId: lid,
            AddedBy: localStorage.LoggedInUser
        };
        let url = URL_Start + 'accounts/createdebitcreditnote'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                SweetAlert.fire({ title: "Success", text: "Data saved successfully", icon: "success" });
                formik.resetForm();
                BindGrid("0");
                setIsSaving(false);
                openModal();
            }
            else {
                SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" });
                setIsSaving(false);
            }
        }).catch(function (error) { setIsSaving(false); });
    }
    const userGroupChangeHandler = async () => {
        alert();
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Data unavailable", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-2") { SweetAlert.fire({ title: "API Server Error", text: ErrorMsg, icon: "error" }); }
        else if (ErrorCode === "-3") { SweetAlert.fire({ title: "Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        else if (ErrorCode === "-4") { SweetAlert.fire({ title: "Wrong Credentials", text: "Please enter correct credentials", icon: "error" }) }
        else if (ErrorCode === "-5") { SweetAlert.fire({ title: "Oops", text: "Data could not be saved, Please try again later", icon: "error" }) }
        else if (ErrorCode === "-6") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
        else if (ErrorCode === "-7") { SweetAlert.fire({ title: "Duplicate", text: "Template already exists", icon: "error" }) }
        else if (ErrorCode === "-8") { SweetAlert.fire({ title: "Data unavailable", text: "No record found to import", icon: "error" }) }
        else if (ErrorCode === "-9") { SweetAlert.fire({ title: "LDAP authentication failed", text: ErrorMsg, icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Ledgers" parent="Accounts" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row>
                                    <Col md={3}>
                                        <Form.Label className='mb-4'><b>Ledger Type</b></Form.Label>
                                        <Form.Group className='animate-chk m-checkbox-inline center__type mt-15'>
                                            <Form.Label for="centreType1">
                                                <input class="radio_animated" type="radio" id='centreType1' name="rdoo-ani" defaultChecked onChange={handleRadioChange} value='1' />Own
                                            </Form.Label>
                                            <Form.Label for="centreType2">
                                                <input class="radio_animated" type="radio" id='centreType2' name="rdoo-ani" onChange={handleRadioChange} value='2' />Third Party
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                    {ledgerType === "2" ?
                                        <>
                                            <Col md={3}>
                                                <Form.Group>
                                                    <Form.Label>State</Form.Label>
                                                    <Form.Control as="select" name="stateId" ref={stateId} onChange={StateChangeHandler}>
                                                        <option value="0">---Select---</option>
                                                        {sapStatesList}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                            <Col md={3}>
                                                <Form.Group>
                                                    <Form.Label>Region</Form.Label>
                                                    <Form.Control as="select" name="regionId" ref={regionId} onChange={handleRegionChange}>
                                                        <option value="0">---Select---</option>
                                                        {sapRegionsList}
                                                    </Form.Control>
                                                </Form.Group>
                                            </Col>
                                        </>
                                        : null}
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>Ledger Names</Form.Label>
                                            {isButton ? <Spinner animation="border" size='sm' /> : null}
                                            <Typeahead
                                                id="ledger-names"
                                                labelKey="Value"
                                                options={ledgerNamesList}
                                                placeholder="---Select---"
                                                ref={ledgerId}
                                                //onChange={userGroupChangeHandler}
                                                // onInputChange={(text, e) => { getitemlist(e, text); }}
                                                onChange={(text) => {
                                                    JSON.stringify(text) == "[]" ? setLID("0") : setLID(text[0].Id)
                                                }}
                                            />

                                            {/* <Form.Control as="select" name="centreType" ref={ledgerId}>
                                                <option value="0">---Select---</option>
                                                {ledgerNamesList}
                                            </Form.Control> */}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>From Date</Form.Label>
                                            <Form.Control type="date" id="date1" name="date1" ref={fromDate} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={3}>
                                        <Form.Group>
                                            <Form.Label>To Date</Form.Label>
                                            <Form.Control type="date" id="date2" name="date2" ref={toDate} />
                                        </Form.Group>
                                    </Col>
                                    <Col md={12} className='text-center __center__buttonsec'>
                                        <Button variant='secondary' className="mr-2" onClick={handleSearch} >Search</Button>
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Transactions</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {isLedgerExist ?
                                    <Form.Row>
                                        <Col md={4}></Col>
                                        <Col className='text-right' md={8}>
                                            <Button variant='primary' className="mr-2" onClick={() => BindGrid('1')} filename={"LedgerStatement.csv"} target="_blank">Download Statement</Button>
                                            <Button variant='secondary' className="mr-2" onClick={handleDebitCreditNote} >Add Debit/Credit Note</Button>
                                        </Col>
                                    </Form.Row>
                                    : null}
                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <DataTables keyField='CentreId' tableData={tableData} columns={columns} noDataIndication={NoRecordFounds}
                                                pagination={paginationFactory(paginationOptions)} />
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Form onSubmit={formik.handleSubmit}>
                    {testsModal &&
                        <Modal isOpen={testsModal} toggle={openModal} centered={true} size="lg">
                            <ModalHeader toggle={openModal}>Tests List</ModalHeader>
                            <ModalBody>
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <Card.Body>
                                                <Form.Row>
                                                    <Col md={6}>
                                                        <Form.Label className='mb-4'><b>Note Type</b></Form.Label>
                                                        <Form.Group className='animate-chk m-checkbox-inline center__type mt-15'>
                                                            <Form.Label for="noteType1">
                                                                <input class="radio_animated" type="radio" id='noteType1' name="rdoo-noteType" defaultChecked onChange={handleNoteTypeChange} value='1' />Debit
                                                            </Form.Label>
                                                            <Form.Label for="noteType2">
                                                                <input class="radio_animated" type="radio" id='noteType2' name="rdoo-noteType" onChange={handleNoteTypeChange} value='2' />Credit
                                                            </Form.Label>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label>{noteTypeLabel} Reason<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control as="select" name="noteReason" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.noteReason}>
                                                                <option value="">---Select---</option>
                                                                {noteTypesList}
                                                            </Form.Control>
                                                            {formik.touched.noteReason && formik.errors.noteReason ? (<div className='text-required'>{formik.errors.noteReason}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Amount<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="text" name="noteAmount" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.noteAmount} />
                                                            {formik.touched.noteAmount && formik.errors.noteAmount ? (<div className='text-required'>{formik.errors.noteAmount}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Narration</Form.Label>
                                                            <Form.Control type="text" name="noteNarration" maxLength={500} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.noteNarration} />
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label>{noteTypeLabel} Receipt(image/pdf only)</Form.Label>
                                                            <div><input type="file" onChange={(e) => UploadImages(e, "DebitCreditNote")} accept="image/*" /></div>
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={12} className='text-center'>
                                                        {isSaving ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' type='submit' onClick={formik.handleSubmit}>Save</Button>
                                                        }
                                                    </Col>
                                                </Form.Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                            </ModalBody>
                        </Modal>
                    }
                </Form>
                {
                    downloadvisible &&
                    <CSVLink data={csvLedger} filename="LedgerStatement.csv" target="_blank" id="csvDownload"></CSVLink>
                }
            </Container>
        </React.Fragment>
    )
}
export default Ledgers;