import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import Breadcrumb from '../../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import LoadingButton from '../commonComponent/LoadingButton';

var glbRecords = [];
const SAPStates = () => {
    let textSearch = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        var boolValue = true;
        if (rowData.IsUnionTerritory === "False") { boolValue = false; }
        return <React.Fragment>
            <div>
                <input className="checkbox_animated" type="checkbox" defaultChecked={boolValue} onClick={(e) => { handleEditCheckbox(e, rowData); }} />
            </div>
        </React.Fragment>
    }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '1%' }; } },
        { dataField: 'ZoneName', text: 'Zone', editable: false, headerStyle: (colum, colIndex) => { return { width: '2%' }; } },
        { dataField: 'StateName', text: 'Name', editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'IsUnionTerritory', text: 'Is UnionT erritory', editable: false, formatter: actionFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    ];
    const handleEditCheckbox = (e, rawData) => {
        const checked = e.target.checked;
        for (var i in glbRecords) {
            if (glbRecords[i].Id === rawData.Id) {
                glbRecords[i].IsUnionTerritory = "False";
                if (checked) { glbRecords[i].IsUnionTerritory = "True"; };
            }
        }
    };
    useEffect(() => { BindGrid(); }, []);
    const handleSearch = async () => {
        setTableData(glbRecords.filter((item) => item.StateName.toLowerCase().indexOf(textSearch.current.value.toLowerCase()) !== -1));
    }
    const BindGrid = async () => {
        setIsLoading(true);
        let url = URL_Start + 'master/getsapstates'
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                glbRecords = data.Data;
                setTableData(glbRecords);
                setIsLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const UpdateTeritoryStatus = async () => {
        setIsSaving(true);
        var arrayUTStatus = [];
        for (var i in glbRecords) {
            var isUT = "0"; if (glbRecords[i].IsUnionTerritory === "True") { isUT = "1"; }
            arrayUTStatus.push({ Id: glbRecords[i].Id, IsUT: isUT });
        }
        const requestOptions = {
            ModifiedBy: localStorage.LoggedInUser,
            UTInfo: arrayUTStatus,
        };
        let url = URL_Start + 'master/updateutstatus'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                SweetAlert.fire({ title: "Success", text: "UT Status updated successfully", icon: "success" });
                setIsSaving(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsSaving(false); }
        }).catch(function (error) { setIsSaving(false); });
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="States" parent="SAP Locations" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row className="justify-content-center">
                                    <Col lg={4} md={6}>
                                        <Form.Group>
                                            <InputGroup className="mb-3">
                                                <Form.Control onChange={handleSearch} placeholder="Search by state name..." ref={textSearch} />
                                                <InputGroup.Append>
                                                    <Button variant="secondary" onClick={handleSearch}><i className='fa fa-search'></i></Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>States List</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <DataTables keyField='id' tableData={tableData} columns={columns} />
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className='text-center'>
                        {isSaving ? <LoadingButton variantName='primary' cssName="mr-2" /> : <Button variant='primary' type='submit' className="mr-2" onClick={UpdateTeritoryStatus}>Update</Button>}
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default SAPStates;