
import React, { useState, useEffect, useRef,Fragment } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import EditToolTip from '../UI/ToolTips/EditToolTip';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip';
import ActiveToolTip from '../UI/ToolTips/ActiveToolTip';
import InactiveToolTip from '../UI/ToolTips/InactiveToolTip';
import { URL_Start } from '../../../constant';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import DatePicker from "react-datepicker";
import { useHistory } from "react-router-dom";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
 let globalModuleId="0";
 let globalModuleUTypeId="0";
let globalPageSelection=false;
let GlbModuleCallArr=[];
let GlbSelectedPageUserR=[]; 
const EditAccessGroup = () => {
    const history = useHistory();
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const [PageAccessGroupId, SetPageAccessGroupId] = useState(localStorage.PageAccessGroupId == undefined ? "" : localStorage.PageAccessGroupId);
    const[ErrorPopUp, setErrorPopUp]=useState(0);
    const[tblRefDoctor,SettblRefDoctor]=useState([]);
    const[ModuleDetail,SetModuleDetail]=useState(0);
    const[IsUserTypeMoudle,SetIsUserTypeMoudle]=useState(false);
    const[tbAccessPageRMaster,SettbAccessPageRMaster]=useState([]);
    const[EditAccessGroup,SetEditAccessGroup]=useState(null);
   
    useEffect(() => {
           asyncFunModuleDDLDetails();
    }, []);
   
    
      const asyncFunAccessPageRelationDetails =async(moduleId,usertypeid )=> {
        try {
         
        let url=URL_Start+ 'users/getuserPageRelationmasterdetails';
        let options = {
            method: 'POST',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {ModuleId:`${moduleId}`,USERTYPEID:`${usertypeid}`}
        };
        let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if(data.Success==true)
                { let ds=data.Data;
                    if(ds!=null && ds.length>0)
                    {
                        SettbAccessPageRMaster(ds); 
                          
                        
                    }
                    if ((moduleId=="1")) {
                        const IsDefaultDs=ds.filter((item)=>{

                             return item.IsDefaultSelected=="True";
                        });
                         if(IsDefaultDs!=null && IsDefaultDs.length>0)
                         {
                            GlbModuleCallArr=[];
                              for( var i=0;i<IsDefaultDs.length; i++)
                              {
                                const item1={
                                    "PageId":IsDefaultDs[i].PageId,
                                    "UserTypeId":IsDefaultDs[i].UserTypeId
                                }
                                GlbModuleCallArr.push(item1);

                              }
                          
                            // if(SelectedPageUserR.map(val => val["PageId"]).indexOf(item1["PageId"]) == -1) 
                            // {
                            //     SetSelectedPageUserR([...SelectedPageUserR, item1]);
                            // }

                         }

                    
                     
                    }
                }
                else{
                    let Errtext=<p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                    console.log('no record found');
                }
               
              
            }
            else{
                let Errtext=<p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
            }
         // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
         }
      }


      const asyncFunModuleDDLDetails =async()=> {
        try {
         
        let url=URL_Start+ 'master/getBindModuledetails';
        let options = {
            method: 'POST',
            url: url,
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json;charset=UTF-8'
            },
            data: {}
        };
        let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if(data.Success==true)
                { let ds=data.Data;
                    if(ds!=null && ds.length>0)
                    {
                        SetModuleDetail(ds.map((option) =>(<option value={option.Mid}>{option.ModuleName}</option>)));               
                    }
                }
                else{
                    let Errtext=<p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                    console.log('no record found');
                }
               
              
            }
            else{
                let Errtext=<p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
            }
         // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
         }
      }

     
      
         const ErrorFunction = async (ErrorCode) => {
            if (ErrorCode == "400")
                SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
            else if (ErrorCode == "401")
                SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
            else if (ErrorCode == "403")
                SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
            else if (ErrorCode == "404")
                SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
            else if (ErrorCode == "500")
                SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
            else if (ErrorCode == "503")
                SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
        }
     
  
      /********** Modals  ***********/
      const [modal, setModal] = useState();
      const toggleModal = () => {
          setModal(!modal)
      }
  
      const [errorModal, setErrorModal] = useState(false);
      const errorHandler = () => {
        if(GlbSelectedPageUserR.length==0 && globalModuleId!="1")
        {
            globalPageSelection=true;
        }
       else if((GlbSelectedPageUserR.length==0 ||GlbSelectedPageUserR.length==1) && globalModuleId=="1")
        {
            globalPageSelection=true;
        }
         else{
             globalPageSelection=false; 
         }
           setModal(true); setErrorModal(true);

           formik.handleSubmit();
      
           
      }
  
  
      
  
  
      /********** Validations  ***********/
      const formik = useFormik({
          initialValues: {
            module:'',
            moduletype:'',
            groupname:'',
            PageAccessGroupId:'0',
          

          },
          validationSchema: Yup.object({
                   groupname: Yup.string().required('Access Group Name is Required') 
          }),
          isSubmitting:true,
          onSubmit:  async (values,actions) => {
                  try {
                    if(GlbSelectedPageUserR.length==0 && globalModuleId!="1")
                    {
                     globalPageSelection=true;
                     setModal(true); setErrorModal(true);
                      return true;
                    }
                   else if((GlbSelectedPageUserR.length==0 ||GlbSelectedPageUserR.length==1) && globalModuleId=="1")
                    {
                     globalPageSelection=true;
                     setModal(true); setErrorModal(true);
                      return true;
                    }
                     else{
                         globalPageSelection=false;
                   
                         setModal(false);
                         setErrorModal(false);
                         setErrorPopUp("");
          
                     }
                     
                  let url=URL_Start+ 'Users/SETUPDATEPageAccessGroups';
                 
                  let options = {
                      method: 'POST',
                      url: url,
                      headers: {
                          'Accept': 'application/json',
                          'Content-Type': 'application/json;charset=UTF-8'
                      },
                    data:{PageAccessGroupId:values.PageAccessGroupId,AccessGroupName:values.groupname,ModuleId:values.module,
                    AddedBy:`${LoginId}`,objPageIds:GlbSelectedPageUserR }
                  };
                 
                  let response = await axios(options);
                      let responseOK = response && response.status == 200;
                      if (responseOK) {
                          let data = response.data;
                          // let data = await response.data;
                          if(data.Success==true && data.Data=="2000")
                          {  formik.resetForm();
                             
                            
                              SweetAlert.fire({ title: "Success!", text: "Access Group has been Updated!", icon: "success" });
                              setErrorModal(false);
                              SettbAccessPageRMaster([]);
                              history.push({ pathname: `${process.env.PUBLIC_URL}/user-master/accessgroups`, search: `` });
  
                          }
                         else if(data.Success==true && data.Data=="2001")
                          {  formik.resetForm();
                             
                            
                              SweetAlert.fire({ title: "Warning", text: "Access Group Already Exist!", icon: "info" });
                              setErrorModal(false);
                             
  
                          }
                          else{
                               if(data.ErrorList!=null && data.ErrorList.length>0)
                               {
                                  let Errtext=<p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                                  SweetAlert.fire({ title: "Error", text:data.ErrorList[0].errorMsg, icon: "error" });
                               }
                              
                          }
                         
                        
                      }
                      else{
                          console.log('no record found');
                          
                      }
                   // return data;
                  } catch (error) {
                      console.log(error.message);
                      let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                      SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
                   }
                   setTimeout(() => {
                    
                      actions.setSubmitting(false);
                      }, 1000);
          },
      });
      
          
      function onKeyDown(keyEvent) {
          if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            keyEvent.preventDefault();
          }
        }
             
        const ModuleChangeHandler= (e) => {
            const s1 = e.target.value;
            globalModuleId=s1;
            GlbSelectedPageUserR=[];
            formik.setFieldValue('groupname','');
            SettbAccessPageRMaster([]);
            if(e.target.value=='0')
            {
                SettbAccessPageRMaster([]);
                SetIsUserTypeMoudle(false);
                formik.setFieldValue('module','');
            }
            else{
                formik.setFieldValue('module',e.target.value);
               
               // setValue(e.target.value);
                }
               
                if(s1=="0")
                {
                    formik.values.module="0";
                }
                else{
                   asyncFunAccessPageRelationDetails(globalModuleId,0);
                }
          
        }

  const PrevBckHandler= async()=>{
    history.push({ pathname: `${process.env.PUBLIC_URL}/user-master/accessgroups`, search: `` });
  }

  const [SelectedPageUserR,SetSelectedPageUserR] = useState([]);

  
  
  const CheckHandler = (e) => {
    const value = e.target.value;
    const itemUTypeId=globalModuleId=="1"?"3":"0";
  const isChecked =e.target.checked;
  const item = {
    "PageId":value,
 "UserTypeId":itemUTypeId
  };
  if (e.target.checked) {
    if(globalModuleId=="1" && GlbSelectedPageUserR.length==0)
    {
                
      const GlbModuleCallArrFilter=GlbModuleCallArr.filter((itemk)=>{

          return itemk.UserTypeId==itemUTypeId && globalModuleId=="1";
      });
      if(GlbModuleCallArrFilter!=null && GlbModuleCallArrFilter.length>0)
      {
          let item11={
              "PageId":GlbModuleCallArrFilter[0].PageId,
              "UserTypeId":GlbModuleCallArrFilter[0].UserTypeId
          }

      if((GlbSelectedPageUserR.map(val => val["PageId"]).indexOf(item11["PageId"]) == -1)) 
       {
        GlbSelectedPageUserR.push(item11);
      }
      }
      
    }
    else{
        if(GlbSelectedPageUserR.length==1 && globalModuleId=="1" )
        {
            GlbSelectedPageUserR=[];
            const GlbModuleCallArrFilter=GlbModuleCallArr.filter((itemk)=>{

                return itemk.UserTypeId==itemUTypeId && globalModuleId=="1";
            });
            if(GlbModuleCallArrFilter!=null && GlbModuleCallArrFilter.length>0)
            {
                let item11={
                    "PageId":GlbModuleCallArrFilter[0].PageId,
                    "UserTypeId":GlbModuleCallArrFilter[0].UserTypeId
                }
      
            if((GlbSelectedPageUserR.map(val => val["PageId"]).indexOf(item11["PageId"]) == -1)) 
             {
              GlbSelectedPageUserR.push(item11);
            }
            }
            
        }
    }
    if(GlbSelectedPageUserR.filter(val => val["UserTypeId"]==itemUTypeId)==false && GlbSelectedPageUserR.length>0 && globalModuleId=="1")
    {
        
    
        SweetAlert.fire({ title: "Warning", text:"You  can choose only single module type user or admin.", icon: "warning" });
        return e.target.checked=false;
   
    }
    if(GlbSelectedPageUserR.map(val => val["PageId"]).indexOf(item["PageId"]) == -1) 
    GlbSelectedPageUserR.push(item);
  } else {
    GlbSelectedPageUserR=GlbSelectedPageUserR.filter(val => val["PageId"] != item["PageId"]);
  } 

  
  };


  const CheckHandler1 = (e) => {
    const value = e.target.value;
    const itemUTypeId="0";
  const isChecked =e.target.checked;

  const item = {
    "PageId":value,
 "UserTypeId":itemUTypeId
  };
  if (e.target.checked) {
    if(globalModuleId=="1" && GlbSelectedPageUserR.length==0)
    {
                
      
      const GlbModuleCallArrFilter=GlbModuleCallArr.filter((itemk)=>{

          return itemk.UserTypeId==itemUTypeId && globalModuleId=="1";
      });
      if(GlbModuleCallArrFilter!=null && GlbModuleCallArrFilter.length>0)
      {
          let item11={
              "PageId":GlbModuleCallArrFilter[0].PageId,
              "UserTypeId":GlbModuleCallArrFilter[0].UserTypeId
          }

      if((GlbSelectedPageUserR.map(val => val["PageId"]).indexOf(item11["PageId"]) == -1)) 
       {
        GlbSelectedPageUserR.push(item11);
      }
      }
      
    }
    else{
        if(GlbSelectedPageUserR.length==1 && globalModuleId=="1" )
        {
            GlbSelectedPageUserR=[];
            const GlbModuleCallArrFilter=GlbModuleCallArr.filter((itemk)=>{

                return itemk.UserTypeId==itemUTypeId && globalModuleId=="1";
            });
            if(GlbModuleCallArrFilter!=null && GlbModuleCallArrFilter.length>0)
            {
                let item11={
                    "PageId":GlbModuleCallArrFilter[0].PageId,
                    "UserTypeId":GlbModuleCallArrFilter[0].UserTypeId
                }
      
            if((GlbSelectedPageUserR.map(val => val["PageId"]).indexOf(item11["PageId"]) == -1)) 
             {
              GlbSelectedPageUserR.push(item11);
            }
            }
            
        }
    }
    if(GlbSelectedPageUserR.filter(val => val["UserTypeId"]==itemUTypeId)==false && GlbSelectedPageUserR.length>0 && globalModuleId=="1")
    {
 
    
        SweetAlert.fire({ title: "Warning", text:"You  can choose only single module type user or admin.", icon: "warning" });
        return e.target.checked=false;
   
    }
    if(GlbSelectedPageUserR.map(val => val["PageId"]).indexOf(item["PageId"]) == -1)
    GlbSelectedPageUserR.push(item);
  } else {
    GlbSelectedPageUserR=GlbSelectedPageUserR.filter(val => val["PageId"] != item["PageId"]);
  
  } 

  
  };

  useEffect(() => {
    BindPageAccessDetailById();
}, []);

  async function  BindPageAccessDetailById() 
  {
   formik.resetForm();
     try {
      
         let url=URL_Start + 'Users/getpageaccessgroupById';
             let options = {
                 method: 'POST',
                 url: url,
                 headers: {
                     'Accept': 'application/json',
                     'Content-Type': 'application/json;charset=UTF-8'
                 },
                 data: {PageAccessGroupId: `${PageAccessGroupId}`}
             };
             let response = await axios(options);
             let responseOK = response && response.status == 200;
             if (responseOK) {
                 let data = response.data;
 
                 // let data = await response.data;
                 if (data.Success == true) {
                     let ds = data.Data;
                     if (ds != null) {
                          if(ds.objPageAccess!=null)
                          {
                           globalModuleId=ds.objPageAccess.ModuleId;
                             await SetEditAccessGroup(ds);
                             asyncFunAccessPageRelationDetails(ds.objPageAccess.ModuleId,"0");
                            

                            formik.values.PageAccessGroupId=PageAccessGroupId;
                            formik.values.groupname=ds.objPageAccess.AccessGroupName;
                            formik.values.module=ds.objPageAccess.ModuleId;
                            //formik.setFieldValue('module',ds.objPageAccess.ModuleId);
                            
                                  
                                        formik.setFieldValue('moduletype',ds.objPageAccess.UserTypeId);

                     
                          }
                         if(ds.objPageAccessGroupMappingList!=null && ds.objPageAccessGroupMappingList.length>0)
                         {
                            const TypeOptEdit=[];
                            GlbSelectedPageUserR=[];
                            ds.objPageAccessGroupMappingList.map((item1) =>{
                                const item = {
                                    "PageId":item1.PageId,
                                    "UserTypeId":item1.UserTypeId
                                  };
                          return GlbSelectedPageUserR.push(item);
                         });
                         //GlbSelectedPageUserR.push(TypeOptEdit);
                         }

                       
                       
                     }
                 }
                 else {
                     let Errtext = <p><div className='text-required'> no record found </div></p>;
                 console.log('no record found');
                 //setIsLoading(false);
                 }
 
             }
             else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); 
             //setIsLoading(false);
             }
             // return data;
         } catch (error) {
            
             if (error.response) { ErrorFunction(error.response.status);
                // setIsLoading(false);
         }
 
     }
     }
  
    return (
        <Fragment>
            <Breadcrumb title="Access Groups" parent="Master" />
            <Container fluid>
            {EditAccessGroup!=null?<> <Row>
                    <Col md={12}>
                        <Card>
                        <div class="pb-0 card-header"><div class="card-title h5">Access Group Creation</div></div>
                            <Card.Body>
                             
                                   
                                    <Form.Row className="justify-content-center">
                                       
                                       <Col md={6} lg={6}>
                                       <Form.Group>
                           
                              <label className="col-form-label"><b>Module Name</b></label>
                               <span className="form-control-plaintext">{EditAccessGroup.objPageAccess.ModuleName==''?'N/A':EditAccessGroup.objPageAccess.ModuleName}</span>
                           </Form.Group>
                                          </Col>
                                        
                                       </Form.Row>
                                       {IsUserTypeMoudle&&<>
                                       {EditAccessGroup.objPageAccess.ModuleType!=''? <Form.Row className="justify-content-center">
                                            
                                            <Col md={6} lg={6}>
                                               <Form.Group>
                                               <label className="col-form-label"><b>Module Type</b></label>
                                       <span className="form-control-plaintext">{EditAccessGroup.objPageAccess.ModuleType==''?null:EditAccessGroup.objPageAccess.ModuleType}</span>             </Form.Group>
                                          </Col></Form.Row>:null }
                                       
                                          </>
                                          
                                          }
                                              {tbAccessPageRMaster!=null && tbAccessPageRMaster.length>0?<>
                                      
                                      {<div className='text-required'>*Page Selection required</div>}
                                      {globalModuleId=="1"?<>
                                      <br></br>
                                      <div className='text-required'>{'Module Type-->User'}</div>
                                      <Form.Row className="justify-content-left">
                                  {tbAccessPageRMaster.map((item) =>{

                                       return<>
                                        { (item.ModuleId=="1"  && ( item.UserTypeId=="3" ||item.UserTypeId=="-11"))&&  GlbSelectedPageUserR.some(function (d) { return d.PageId == item.PageId })==false? <Col md={4} lg={4}>
                                              {/* <Form.Group className='animate-chk m-checkbox-inline'> */}
                                              <Form.Label>
                                                              
                                                              <input name={"chk"+`${item.PageId}`} className="checkbox_animated" type="checkbox"  
                                                               value={`${item.PageId}`} onChange={CheckHandler}  />
                                                              {item.Hierarchy}  
                                                          </Form.Label>
                                                            {/* </Form.Group> */}
                                          </Col>:
                                        
                                       <> {(item.ModuleId=="1"  && ( item.UserTypeId=="3" ||item.UserTypeId=="-11"))? <Col md={4} lg={4}>

                                       {item.IsDefaultSelected=="True" ?<>
                                               <Form.Label>
                                                               
                                                               <input name={"chk"+`${item.PageId}`} className="checkbox_animated" type="checkbox"  
                                                               value={`${item.PageId}`}  checked />
                                                               {item.Hierarchy}
                                                           </Form.Label></>:<>{GlbSelectedPageUserR.some(function (d) { return d.UserTypeId=="3"})==true?<Form.Label>
                                                               <input name={"chk"+`${item.PageId}`} className="checkbox_animated" type="checkbox"  
                                                               onChange={CheckHandler}  value={`${item.PageId}`} defaultChecked={true}    />
                                                               {item.Hierarchy}
                                                           </Form.Label>:<Form.Label>
                                                           <input name={"chk"+`${item.PageId}`} className="checkbox_animated" type="checkbox"  
                                                           onChange={CheckHandler}  value={`${item.PageId}`}     />
                                                           {item.Hierarchy}
                                                       </Form.Label>}</>   
                                                               
                                                               
                                                               
                                                           }
                                                           
                                           </Col>:null}</>}
                                       
                                       </>
                                  })

                                  }
                                  
                      
                                
                                      </Form.Row><hr></hr> <div className='text-required'>{'Module Type-->Admin'}</div><Form.Row className="justify-content-left">
                                  {tbAccessPageRMaster.map((item) =>{

                                       return<>

{(item.ModuleId=="1"  && ( item.UserTypeId=="0" ||item.UserTypeId=="-11")) &&  GlbSelectedPageUserR.some(function (d) { return d.PageId == item.PageId })==false? <Col md={4} lg={4}>
                                              {/* <Form.Group className='animate-chk m-checkbox-inline'> */}
                                              <Form.Label>
                                                              
                                                              <input name={"chk"+`${item.PageId}`} className="checkbox_animated" type="checkbox"  
                                                               value={`${item.PageId}`} onChange={CheckHandler1}   />
                                                              {item.Hierarchy} 
                                                              
                                                          </Form.Label>
                                                            {/* </Form.Group> */}
                                          </Col>:
                                        
                                       <>  {(item.ModuleId=="1"  && ( item.UserTypeId=="0" ||item.UserTypeId=="-11"))? <Col md={4} lg={4}>

                                       {item.IsDefaultSelected=="True" ?<>
                                               <Form.Label>
                                                               
                                                               <input name={"chk"+`${item.PageId}`} className="checkbox_animated" type="checkbox"  
                                                               value={`${item.PageId}`}   checked />
                                                               {item.Hierarchy}
                                                           </Form.Label></>:   <>{GlbSelectedPageUserR.some(function (d) { return d.UserTypeId=="0"})==true?<Form.Label>
                                                               <input name={"chk"+`${item.PageId}`} className="checkbox_animated" type="checkbox"  
                                                               onChange={CheckHandler1}  value={`${item.PageId}`} defaultChecked={true}    />
                                                               {item.Hierarchy}
                                                           </Form.Label>:<Form.Label>
                                                           <input name={"chk"+`${item.PageId}`} className="checkbox_animated" type="checkbox"  
                                                           onChange={CheckHandler1}  value={`${item.PageId}`}     />
                                                           {item.Hierarchy}
                                                       </Form.Label>}</>  
                                                               
                                                           }
                                                           
                                           </Col>:null} </>}
                                      
                                       
                                       </>
                                  })

                                  }
                                  
                      
                                
                                      </Form.Row></> :<Form.Row className="justify-content-left">
                                  {tbAccessPageRMaster.map((item) =>{
                                       return<>
                                       { GlbSelectedPageUserR.some(function (d) { return d.PageId == item.PageId })==false? <Col md={4} lg={4}>
                                              {/* <Form.Group className='animate-chk m-checkbox-inline'> */}
                                              <Form.Label>
                                                              
                                                              <input name={"chk"+`${item.PageId}`} className="checkbox_animated" type="checkbox"  
                                                               value={`${item.PageId}`} onChange={CheckHandler}   />
                                                              {item.Hierarchy}    
                                                          </Form.Label>
                                                            {/* </Form.Group> */}
                                          </Col>:
                                        <Col md={4} lg={4}>
                                        {/* <Form.Group className='animate-chk m-checkbox-inline'> */}
                                        { item.ModuleId=="1" && item.IsDefaultSelected=="True"?<>
                                        <Form.Label>
                                                        
                                                        <input name={"chk"+`${item.PageId}`} className="checkbox_animated" type="checkbox"  
                                                         value={`${item.PageId}`} checked />
                                                        {item.Hierarchy}
                                                       
                                                    </Form.Label></>:  <Form.Label>
                                                    
                                                        <input name={"chk"+`${item.PageId}`} className="checkbox_animated" type="checkbox"  
                                                         onChange={CheckHandler}  value={`${item.PageId}`}  defaultChecked={true} />
                                                        {item.Hierarchy}
                                                       
                                                    </Form.Label>
                                                          
                                                      }
                                                   
                                                      {/* </Form.Group> */}
                                    </Col>}
                                       </>
                                  })

                                  }
                                
                                      </Form.Row> }
                                      
                                    </>:null

                                    }
                                      
                                  <Form.Row className="justify-content-center">
                                       
                                        <Col md={6} lg={6}>
                                            <Form.Group>
                                                <Form.Label htmlFor="groupname">Access Group Name<span className='text-required'>*</span></Form.Label>
                                                <Form.Control type='text' id="groupname" name="groupname"
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    value={formik.values.groupname} />

                                                {formik.touched.groupname && formik.errors.groupname ? (
                                                    <div className='text-required'>{formik.errors.groupname}</div>
                                                ) : null}

                                               


                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>          
                <Row className='mt15'>
                                    <Col md={12} className='text-center'>
                                        <Button variant='secondary' className="mr-2"  onClick={PrevBckHandler} >Cancel</Button>
                                        {<Button variant='primary' type='submit' onClick={errorHandler}>Save</Button>}
                                    </Col>
                                </Row></>:null}
               
            </Container>




          
          

            {errorModal==true?
                <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                    <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                    <ModalBody>
                        <p>
                        {formik.touched.module && formik.errors.module ? (<div className='text-required'>{formik.errors.module}</div>) : null}
                        </p>
                        <p>
                        {formik.touched.moduletype && formik.errors.moduletype ? (<div className='text-required'>{formik.errors.moduletype}</div>) : null}
                        </p>
                        <p>{globalPageSelection==true?<div className='text-required'>Page Selection required</div>:null}</p>
                        <p>
                        {formik.touched.groupname && formik.errors.groupname ? (
                                                    <div className='text-required'>{formik.errors.groupname}</div>
                                                ) : null}
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                        <Button variant="primary" onClick={toggleModal}>Ok</Button>
                    </ModalFooter>
                    
                </Modal>:null
            }
        </Fragment>
    )
}
export default EditAccessGroup;