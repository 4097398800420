import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import Breadcrumb from '../../../components/common/breadcrumb';
import paginationFactory from 'react-bootstrap-table2-paginator';
import DynamicDataTables from '../commonComponent/DataTable/DynamicDataTable';
import SweetAlert from 'sweetalert2';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import moment from 'moment';
import Select from 'react-select';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';

let globalPaymentModeText = "";
let GlobalOptionAdvc = "";
let selectedCentreId = "0";
const AdvancePayment = () => {
    const today = new Date();
    const date = today.setDate(today.getDate());
    const defaultValue = new Date(date).toISOString().split('T')[0];
    const [modal, setModal] = useState();
    const [errorModal, setErrorModal] = useState();
    let fromDate = useRef("");
    let paymentDate = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [PaymentModeDLL, SetPaymentModeDLL] = useState([]);
    const [PaymentModeVal, SetPaymentModeVal] = useState("");
    const [BankDLL, SetBankDLL] = useState([]);
    const [BankVal, SetBankVal] = useState([]);
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const [IsButtonSubmitNew, SetIsButtonSubmitNew] = useState(false);
    const [ValidDateF, SetValidDateF] = useState("");
    const [IsShowDateCand, SetIsShowDateCand] = useState(false);
    const [ShowValidDateF, SetShowValidDateF] = useState("");
    const [CustrizeNo, SetCustrizeNo] = useState("");
    const [PageHelper, SetPageHelper] = useState({
        PageNo: '1', PageSize: '10', TotalRecord: 0, TotalApprovedAmt: 0, TotalPendingAmt: 0, AvailableBalance: 0, dsAdvancePaymentDlists: []
    });
    const [sapStatesList, setSAPStatesList] = useState([]);
    const [centresList, setCentresList] = useState([]);
    const [centreTitle, SetCentreTitle] = useState("Centre");
    const [centreTypeId, SetCentreTypeId] = useState("2");
    const [centreId, SetCentreId] = useState("0");
    const [companylist, setCompanyList] = useState([]);
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [advancepaymentdetails, setAdvancePaymentDetails] = useState([]);
    const [typeSelected, setTypeSelected] = useState([]);

    let stateId = useRef();
    let companyId = useRef();
    const PaymentFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <p><strong>Payment Mode:</strong> {rowData.Payment}</p>
                <p><strong>Amount:</strong> {rowData.DepositAmount}</p>
                <p><strong>Deposit By:</strong> {rowData.UserName}</p>
                <p><strong>Deposit Date:</strong> {rowData.CustDepositDate}</p>
                <p><strong>Entry Date:</strong> {rowData.CustEntryDate}</p>
                <p><strong>Payment Status:</strong> {rowData.PaymentStatus}</p>
            </div>
        </React.Fragment>
    }
    const AdvPaymentFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <p><strong>Bank Name:</strong> {rowData.Bank == '' ? 'N/A' : rowData.Bank}</p>
                <p><strong>Payment Mode No:</strong> {rowData.PaymentModeNo == '' ? 'N/A' : rowData.PaymentModeNo}</p>
                <p><strong>Payment Mode Date:</strong> {rowData.CustPaymentDate == '' ? 'N/A' : rowData.CustPaymentDate}</p>
            </div>
        </React.Fragment>
    }
    const columns = [
        {
            dataField: '#', text: '#',
            formatter: (cell, row, rowIndex) => { let rowNumber = (PageHelper.PageNo - 1) * PageHelper.PageSize + (rowIndex + 1); return <span>{rowNumber}</span>; },
            headerStyle: (colum, colIndex) => { return { width: '5%' }; }
        },
        { text: 'Payment Detail', editable: false, formatter: PaymentFormatter, headerStyle: (colum, colIndex) => { return { width: '22%' }; } },
        { text: 'Payment Advc. Detail', editable: false, formatter: AdvPaymentFormatter, headerStyle: (colum, colIndex) => { return { width: '22%' }; } },
        { dataField: 'Remarks', text: 'Remarks', editable: false },
        { dataField: 'RejectReason', text: 'Reject Reason', editable: false }
    ];
    const paginationOptions = {
        totalSize: PageHelper.TotalRecord,
        sizePerPage: PageHelper.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onSizePerPageChange: (sizePerPage, page, event) => {
            BindGrid(1, 10, GlobalOptionAdvc);
            document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
        },
        onPageChange: (page, sizePerPage) => { BindGrid(1, 10, GlobalOptionAdvc); }
    };
    useEffect(() => {
        selectedCentreId = "0";
        GetPaymentmode();
        GetBankLists();
        GetCompanyName();
        //GetSAPStates("1");
        BindGrid(1, 10, "1");
    }, []);
    const BindGrid = async (PageNo, PageSize, PaymentStatusId) => {
        setIsLoading(true);
        try {

            let url = URL_Start + 'accounts/GETAdvancePaymentDetails'
            let options = {
                method: 'POST', url: url,
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
                data: { PageNo: `${PageNo}`, PageSize: `${PageSize}`, PaymentStatusId: `${PaymentStatusId}`, CentreId: selectedCentreId }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        await SetPageHelper({
                            ...PageHelper,
                            PageNo: Number(PageNo),
                            PageSize: Number(PageSize),
                            TotalRecord: Number(ds.TotalRecord),
                            TotalApprovedAmt: Number(ds.TotalApprovedAmt),
                            TotalPendingAmt: Number(ds.TotalPendingAmt),
                            AvailableBalance: Number(ds.AvailableBalance),
                            dsAdvancePaymentDlists: ds.ObjBindAdvancePaymentList == null ? [] : ds.ObjBindAdvancePaymentList
                        });
                        setIsLoading(false);
                    }
                }
                else { setIsLoading(false); }
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        } catch (error) { if (error.response) { ErrorFunction(error.response.status); setIsLoading(false); } }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }
    const GetBankLists = async () => {
        var glblArr1 = [];
        try {
            let url = URL_Start + 'master/BankLists'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { 
                    let ds = data.Data;
                    if (ds != null) {
                        ds.map((option) => { return glblArr1.push({ 'value': option.SNo, 'label': option.Bank, 'disabled': false }); });
                        SetBankDLL(glblArr1);
                    }
                    else {
                        SetBankDLL([]);
                    }
                    //SetBankDLL(data.Data.map((d) => (<option key={d.SNo} value={d.SNo}>{d.Bank}</option>))); 
                }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetPaymentmode = async () => {
        try {
            let url = URL_Start + 'master/GetPaymentModesAdvance'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { SetPaymentModeDLL(data.Data.map((d) => (<option key={d.SNo} value={d.SNo}>{d.PaymentMode}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const toggleModal = () => { setModal(!modal) }
    const errorHandler = async () => {
        setErrorModal(true);
        setModal(true);
        if (fromDate.current != undefined && fromDate.current != "") { SetValidDateF(fromDate.current.value); }
        else { SetValidDateF(""); }
        if (paymentDate.current != undefined && paymentDate.current != "") { SetShowValidDateF(paymentDate.current.value); } else { SetShowValidDateF(""); }
        SetIsButtonSubmitNew(true);
        formik.handleSubmit();
    }
    const formik = useFormik({
        initialValues: { aymentmode: '0', advanceamt: '', txtremarks: '' },
        enableReinitialize: true,
        validationSchema: Yup.object({
            advanceamt: Yup.string().required('Advance Amount is Required'),
            txtremarks: Yup.string().required("Remarks is Required")
        }),
        isSubmitting: true,
        onSubmit: async (values, actions) => {
            try {
                if (IsShowDateCand == true && BankVal == "" && IsButtonSubmitNew == true) { setModal(true); setErrorModal(true); }
                else if (IsShowDateCand == true && CustrizeNo == "" && IsButtonSubmitNew == true) { setModal(true); setErrorModal(true); }
                else if (selectedCentreId == "" || selectedCentreId == "0") { setModal(true); setErrorModal(true); }
                else if (IsShowDateCand == true && (ShowValidDateF == "" || moment(ShowValidDateF, 'YYYY-MM-DD').isValid() == false)) { setModal(true); setErrorModal(true); }
                else {
                    SetIsButtonSubmitNew(false);
                    setModal(false);
                    let url = URL_Start + 'accounts/SETAdvancePaymentDetails';
                    let options = {
                        method: 'POST',
                        url: url,
                        headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
                        data: {
                            CentreId: `${selectedCentreId}`, PaymentId: `${PaymentModeVal}`, DepositDate: `${ValidDateF}`, DepositAmount: `${values.advanceamt}`,
                            Remarks: `${values.txtremarks}`, BankId: `${BankVal}`, PaymentModeNo: `${CustrizeNo}`, PaymentDate: `${ShowValidDateF}`, AddedBy: `${LoginId}`
                        }
                    };
                    let response = await axios(options);
                    let responseOK = response && response.status == 200;
                    if (responseOK) {
                        let data = response.data;
                        if (data.Success == true && data.Data > "0") {
                            formik.resetForm();
                            SweetAlert.fire({ title: "Success!", text: "Advance Payment has been Saved!", icon: "success" });
                            setErrorModal(false);
                            BindGrid(1, 10, "1")
                        }
                        else { if (data.ErrorList != null && data.ErrorList.length > 0) { SweetAlert.fire({ title: "Error", text: data.ErrorList[0].errorMsg, icon: "error" }); } }
                    }
                }
            }
            catch (error) { SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
            setTimeout(() => { actions.setSubmitting(false); }, 1000);
        },
    });
    const AdvanceChkHandler = async (e) => {
        const value = e.target.value;
        GlobalOptionAdvc = value;
        const isChecked = e.target.checked;
        if (isChecked == true) { BindGrid(1, 10, GlobalOptionAdvc) }
    }
    const bankHandler = async (e) => {
        const value = e.target.value;
        SetBankVal(value);
    }
    const PaymentModeHandler = async (e) => {
        formik.resetForm();
        SetBankVal([]);
        SetCustrizeNo("");
        const value = e.target.value;
        globalPaymentModeText = e.target.options[e.target.selectedIndex].text;
        SetPaymentModeVal(value);
        if (value == "3" || value == "7" || value == "8" || value == "9" || value == "10" || value == "11") { SetIsShowDateCand(true); setTypeSelected([]); } else { SetIsShowDateCand(false); setTypeSelected([]); }
    }
    const handleCenterTypeRadioChange = async (e) => {
        formik.resetForm();
        var radiobtn = document.getElementById("chkAdvance");
        radiobtn.checked = true;
        companyId.current.value = "0";
        SetPaymentModeVal("");
        SetBankVal([]);
        SetCustrizeNo("");
        selectedCentreId = "0";
        SetCentreTypeId(e.target.value);
        SetCentreId("0");
        if (e.target.value === "2") { SetCentreTitle("Centre") } else { SetCentreTitle("Panel") }
        GetCentres(companyId.current.value, e.target.value);
        BindGrid(1, 10, GlobalOptionAdvc);
    }
    const GetSAPStates = async (id) => {
        try {
            let url = URL_Start + 'master/getstateslist/' + id
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setSAPStatesList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetCompanyName = async () => {
        try {
            let url = URL_Start + 'master/GetCompanyName/'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setCompanyList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Companyname}</option>))); }
                else { setCompanyList([]); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetCentres = async (sId, cTypeId) => {
        try {
            let url = URL_Start + 'accounts/getcentreslistbytypestateid/' + sId + '/' + cTypeId
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    // setCentresList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); 
                    var itmData = data.Data;
                    if (itmData.length > 0) {
                        let cntresList = [];
                        itmData.map((option) => { return cntresList.push({ 'value': option.Id, 'label': option.Value, 'disabled': false }); });
                        setCentresList(cntresList);
                    }
                    else { setCentresList([]); }
                }
                else { setCentresList([]); }
            }).catch(function (error) { setCentresList([]); });
        }
        catch (error) { setCentresList([]); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const handleStateChange = async (e) => {
        selectedCentreId = "0";
        SetCentreId("0");
        GetCentres(e.target.value, centreTypeId);
    }
    const handleCompanyChange = async (e) => {
        var radiobtn = document.getElementById("chkAdvance");
        radiobtn.checked = true;
        selectedCentreId = "0";
        SetCentreId("0");
        GetCentres(e.target.value, centreTypeId);
        BindGrid(1, 10, GlobalOptionAdvc);
    }
    const CentreSelectHandler = (options) => {
        SetCentreId(options);
        selectedCentreId = options.value;
        BindGrid(1, 10, "1");
    }
    const CustrizeHandler = async (e) => { const val = e.target.value; SetCustrizeNo(val); }

    const DownloadAdvanceStatement = async () => {
        setIsLoading(true);
        try {
            let url = URL_Start + 'accounts/getadvancepaymentstatement/' + (GlobalOptionAdvc == "" ? "1" : GlobalOptionAdvc) + '/' + selectedCentreId
            await axios.get(url).then((response) => {
                const data = response.data;
                setIsLoading(false);
                if (data.Success) {
                    if (data.Data == null) { setDownloadVisible(false); setIsLoading(false); setAdvancePaymentDetails([]); }
                    else { setDownloadVisible(true); setAdvancePaymentDetails(data.Data); document.getElementById("csvdownload").click(); }
                }
                else { setDownloadVisible(false); setIsLoading(false); setAdvancePaymentDetails([]); }
            }).catch(function (error) { setIsLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
        } catch (error) { setIsLoading(false); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const TypeChangeHandler = (options) => { setTypeSelected(options.value); SetBankVal(options.value);  }
    return (
        <>
            <Breadcrumb title="Advance Payment" parent="Account" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form>
                                    <Form.Row>
                                        <Col md={4}>
                                            <Form.Label className='mb-4'>Centre Type</Form.Label>
                                            <Form.Group className='animate-chk m-checkbox-inline center__type mt-15'>
                                                <Form.Label for="centreType2">
                                                    <input class="radio_animated" type="radio" id='centreType2' name="rdoo-ani" defaultChecked onChange={handleCenterTypeRadioChange} value='2' />Third Party Centres
                                                </Form.Label>
                                                <Form.Label for="centreType5">
                                                    <input class="radio_animated" type="radio" id='centreType5' name="rdoo-ani" onChange={handleCenterTypeRadioChange} value='3' />Panel
                                                </Form.Label>
                                            </Form.Group>
                                        </Col>
                                        {/* <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>State</Form.Label>
                                                <Form.Control as="select" name="stateId" ref={stateId} onChange={handleStateChange}>
                                                    <option value="0">---Select---</option>
                                                    {sapStatesList}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col> */}
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>Company Name</Form.Label>
                                                <Form.Control as="select" name="companyId" ref={companyId} onChange={handleCompanyChange}>
                                                    <option key="0" value="0">---Select---</option>
                                                    {companylist}
                                                </Form.Control>
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>{centreTitle}</Form.Label>
                                                <Select options={centresList} value={centreId} onChange={CentreSelectHandler} />
                                            </Form.Group>
                                        </Col>
                                        {selectedCentreId !== "0" ?
                                            <>
                                                <Col md={2}>
                                                    <Form.Group>
                                                        <Form.Label>Payment Mode<span className='text-red'>*</span></Form.Label>
                                                        <Form.Control as="select" id="Paymentmode" name="Paymentmode" value={PaymentModeVal} onChange={PaymentModeHandler}>
                                                            <option key="0" value="0" selected>Select</option>
                                                            {PaymentModeDLL}
                                                        </Form.Control>
                                                        {PaymentModeVal == "" && IsButtonSubmitNew == true ? (<div className='text-required'>{"Payment Mode is Required"}</div>) : null}
                                                    </Form.Group>
                                                </Col>
                                                <Col md={2}>
                                                    <Form.Group>
                                                        <Form.Label>Deposit Date</Form.Label>
                                                        <Form.Control type="date" id="date1" name="date1" defaultValue={defaultValue} min={new Date().toISOString().split('T')[0]} ref={fromDate} />
                                                        {(ValidDateF == "" || moment(ValidDateF, 'YYYY-MM-DD').isValid() == false) && IsButtonSubmitNew == true ? <div className='text-required'>Valid Deposit Date Required</div> : null}
                                                    </Form.Group>
                                                </Col>
                                                <Col md={2}>
                                                    <Form.Group>
                                                        <Form.Label>Advance Amount<span className='text-red'>*</span></Form.Label>
                                                        <Form.Control type="number" id="advanceamt" name="advanceamt" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.advanceamt} />
                                                        {formik.touched.advanceamt && formik.errors.advanceamt ? (<div className='text-required'>{formik.errors.advanceamt}</div>) : null}
                                                    </Form.Group>
                                                </Col>
                                                {PaymentModeVal != "" && (PaymentModeVal == "3" || PaymentModeVal == "7" || PaymentModeVal == "8" || PaymentModeVal == "9" ||
                                                    PaymentModeVal == "10" || PaymentModeVal == "11") ?
                                                    <>
                                                        <Col md={2}>
                                                            <Form.Group>
                                                                <Form.Label>Bank Name</Form.Label>
                                                                {/* <Form.Control as="select" id="bankname" name="bankname" onChange={bankHandler} value={BankVal} >
                                                                    <option value="0" selected>Select</option>
                                                                    {BankDLL}
                                                                </Form.Control>
                                                                {IsShowDateCand == true && BankVal == "" && IsButtonSubmitNew == true ? <div className='text-required'>Bank Name is Required</div> : null} */}
                                                                <Select onChange={TypeChangeHandler}
                                                                    options={BankDLL}
                                                                    value={BankDLL.filter(function (x) { return x.value === typeSelected; })} />
                                                            </Form.Group>
                                                        </Col><Col md={2}>
                                                            <Form.Group>
                                                                <Form.Label>{globalPaymentModeText} No.</Form.Label>
                                                                <Form.Control type="text" id="txtcustrizeNo" name="txtcustrizeNo" onChange={CustrizeHandler} value={CustrizeNo} />
                                                                {IsShowDateCand == true && CustrizeNo == "" && IsButtonSubmitNew == true ? <div className='text-required'>{globalPaymentModeText} No. is Required</div> : null}
                                                            </Form.Group>
                                                        </Col><Col md={2}>
                                                            <Form.Group>
                                                                <Form.Label>{globalPaymentModeText} Date</Form.Label>
                                                                <Form.Control type="date" id="datePayment" name="datepayment" ref={paymentDate} defaultValue={defaultValue} />
                                                                {IsShowDateCand == true && (ShowValidDateF == "" || moment(ShowValidDateF, 'YYYY-MM-DD').isValid() == false) && IsButtonSubmitNew == true ? <div className='text-required'>Valid {globalPaymentModeText} Date is Required</div> : null}
                                                            </Form.Group>
                                                        </Col></> : null}
                                                <Col md={3}>
                                                    <Form.Group>
                                                        <Form.Label>Remarks<span className='text-red'>*</span></Form.Label>
                                                        <Form.Control type="text" id="txtremarks" name="txtremarks" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.txtremarks} />
                                                        {formik.touched.txtremarks && formik.errors.txtremarks ? (<div className='text-required'>{formik.errors.txtremarks}</div>) : null}
                                                    </Form.Group>
                                                </Col>
                                                <Col md={2} className='text-center mt-4'>
                                                    <Button variant='primary' onClick={errorHandler} className='mt-1'>Add Payment</Button>
                                                </Col>
                                            </>
                                            : null}
                                    </Form.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Advance Payment Detail</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <Form>
                                    <Form.Row >
                                        <Col md={6}>
                                            <div>
                                                <Form.Group className='advancepaymentradio'>
                                                    <Form.Label><strong>Filter Data:</strong></Form.Label>
                                                    <Form.Label>
                                                        <input name="chkAdvance" defaultChecked={true} type="radio" id="chkAdvance" onChange={AdvanceChkHandler} value={1} />Pending
                                                    </Form.Label> &nbsp;  &nbsp;
                                                    <Form.Label>
                                                        <input name="chkAdvance" type="radio" id="chkAdvance" onChange={AdvanceChkHandler} value={2} />Approved
                                                    </Form.Label> &nbsp;   &nbsp;
                                                    <Form.Label>
                                                        <input name="chkAdvance" type="radio" id="chkAdvance" onChange={AdvanceChkHandler} value={3} />Rejected
                                                    </Form.Label> &nbsp;   &nbsp;
                                                    <Form.Label>
                                                        <input name="chkAdvance" type="radio" id="chkAdvance" onChange={AdvanceChkHandler} value={4} />All
                                                    </Form.Label>
                                                </Form.Group>
                                            </div>
                                        </Col>
                                        <Col md={6} className='text-right'>
                                            <Form.Label><strong>Available Balance</strong> :{PageHelper.AvailableBalance == '' ? '0.00' : parseFloat(PageHelper.AvailableBalance).toFixed(2)}</Form.Label>
                                            <Form.Label className='ml-4'><strong>Pending Total</strong> :{PageHelper.TotalPendingAmt == '' ? '0.00' : parseFloat(PageHelper.TotalPendingAmt).toFixed(2)}</Form.Label>
                                        </Col>
                                    </Form.Row>
                                    {
                                        selectedCentreId == "" || selectedCentreId == "0" ? null :
                                            <Form.Row>
                                                <Col md={4}></Col>
                                                <Col className='text-right' md={8}>
                                                    <Button variant='primary' className="mr-2" onClick={() => DownloadAdvanceStatement()} filename={"AdvancePayment.csv"} target="_blank">Download Statement</Button>
                                                </Col>
                                            </Form.Row>
                                    }
                                </Form>
                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <DynamicDataTables keyField="AdvancePaymentId" tableData={PageHelper.dsAdvancePaymentDlists} columns={columns} pagination={paginationOptions ? paginationFactory(paginationOptions) : null} />
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    downloadvisible &&
                    <CSVLink data={advancepaymentdetails} filename="AdvancePayment.csv" target="_blank" id="csvdownload"></CSVLink>
                }
            </Container>
            {
                errorModal &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                    <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                    <ModalBody>
                        <p>{(selectedCentreId == "" || selectedCentreId == "0") && IsButtonSubmitNew == true ? (<div className='text-required'>{"Centre is Required"}</div>) : null} </p>
                        <p>{PaymentModeVal == "" && IsButtonSubmitNew == true ? (<div className='text-required'>{"Payment Mode is Required"}</div>) : null}</p>
                        <p> {formik.touched.advanceamt && formik.errors.advanceamt ? (<div className='text-required'>{formik.errors.advanceamt}</div>) : null} </p>
                        <p> {formik.touched.txtremarks && formik.errors.txtremarks ? (<div className='text-required'>{formik.errors.txtremarks}</div>) : null} </p>
                        <p> {(ValidDateF == "" || moment(ValidDateF, 'YYYY-MM-DD').isValid() == false) && IsButtonSubmitNew == true ? <div className='text-required'>Valid Deposit Date Required</div> : null} </p>
                        <p>{IsShowDateCand == true && BankVal == "" && IsButtonSubmitNew == true ? <div className='text-required'>Bank Name is Required</div> : null}</p>
                        <p> {IsShowDateCand == true && CustrizeNo == "" && IsButtonSubmitNew == true ? <div className='text-required'>{globalPaymentModeText} No. is Required</div> : null}</p>
                        <p> {IsShowDateCand == true && (ShowValidDateF == "" || moment(ShowValidDateF, 'YYYY-MM-DD').isValid() == false) && IsButtonSubmitNew == true ? <div className='text-required'>Valid {globalPaymentModeText} Date Required</div> : null}</p>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                        <Button variant="primary" onClick={toggleModal}>Ok</Button>
                    </ModalFooter>
                </Modal>
            }
        </>
    )
}
export default AdvancePayment;