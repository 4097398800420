import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import Breadcrumb from '../../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import { CSVLink } from "react-csv";
import paginationFactory from 'react-bootstrap-table2-paginator';

var glblData = [];
const CentreMasterData = () => {
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [tableData, setTableData] = useState([]);
    const [CentrelistCSV, setCentrelistCSV] = useState([]);
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [centreTypeId, setCentreTypeId] = useState(0);

    const paginationOptions = { sizePerPage: 100, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last', onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); } };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'ClientType', text: 'Client Type', editable: false },
        { dataField: 'CentreName', text: 'Name', editable: false },
        { dataField: 'CentreType', text: 'Type', editable: false },
        { dataField: 'SAPCode', text: 'SAP Code', editable: false },
        { dataField: 'CentreAddress', text: 'Address', editable: false },
        { dataField: 'WarehouseCode', text: 'Warehouse Code', editable: false },
        { dataField: 'SAPSeries', text: 'SAP Series', editable: false },
        { dataField: 'CompanyName', text: 'Company', editable: false },
        { dataField: 'IsActive', text: 'Is Active', editable: false },
        { dataField: 'ContractFromDate', text: 'Contract From', editable: false },
        { dataField: 'ContractToDate', text: 'Contract To', editable: false },
    ];
    useEffect(() => { BindGrid("0"); }, []);

    const handleSearchList = async () => {
        GetCentreDetailList(centreTypeId);
    }
    const BindGrid = async (ctId) => {
        setIsLoading(true);
        let url = URL_Start + 'reports/getcentremasterdatareport/' + localStorage.getItem('LoggedInUser') + '/' + ctId
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                if (data.Data !== null) { setTableData(data.Data); glblData = data.Data } else { setTableData([]); }
                setIsLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }
    const handleCenterTypeRadioChange = async (e) => { setCentreTypeId(e.target.value); BindGrid(e.target.value); }
    const GetCentreDetailList = async (ctId) => {
        setIsLoading(true);
        try {
            let url = URL_Start + 'reports/getcentremasterdatareport/' + localStorage.getItem('LoggedInUser') + '/' + ctId
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsLoading(false);
                    if (data.Data != null) { setDownloadVisible(true); setCentrelistCSV(data.Data); document.getElementById("csvdownload").click(); } else { APIError(data.Data); }
                }
                else { setDownloadVisible(false); setIsLoading(false); }
            }).catch(function (error) { setIsLoading(false); if (error.response) { ErrorFunction(error.response.status); } });
        } catch (error) { setIsLoading(false); APIError("-3"); }
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Data unavailable", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-2") { SweetAlert.fire({ title: "API Server Error", text: ErrorMsg, icon: "error" }); }
        else if (ErrorCode === "-3") { SweetAlert.fire({ title: "Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        else if (ErrorCode === "-4") { SweetAlert.fire({ title: "Wrong Credentials", text: "Please enter correct credentials", icon: "error" }) }
        else if (ErrorCode === "-5") { SweetAlert.fire({ title: "Oops", text: "Data could not be saved, Please try again later", icon: "error" }) }
        else if (ErrorCode === "-6") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
        else if (ErrorCode === "-7") { SweetAlert.fire({ title: "Duplicate", text: "Template already exists", icon: "error" }) }
        else if (ErrorCode === "-8") { SweetAlert.fire({ title: "Data unavailable", text: "No record found to import", icon: "error" }) }
        else if (ErrorCode === "-9") { SweetAlert.fire({ title: "LDAP authentication failed", text: ErrorMsg, icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Centre Master Data" parent="Reports" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row>
                                    <Col md={6}>
                                        <Form.Label className='mb-4'><b>Centre Type</b></Form.Label>
                                        <Form.Group className='animate-chk m-checkbox-inline center__type mt-15'>
                                            <Form.Label for="centreType0">
                                                <input class="radio_animated" type="radio" id='centreType0' name="rdoo-ani" defaultChecked onChange={handleCenterTypeRadioChange} value='0' />All
                                            </Form.Label>
                                            <Form.Label for="centreType1">
                                                <input class="radio_animated" type="radio" id='centreType1' name="rdoo-ani" onChange={handleCenterTypeRadioChange} value='1' />Own Centres
                                            </Form.Label>
                                            <Form.Label for="centreType2">
                                                <input class="radio_animated" type="radio" id='centreType2' name="rdoo-ani" onChange={handleCenterTypeRadioChange} value='2' />Third Party Centres
                                            </Form.Label>
                                            <Form.Label for="centreType3">
                                                <input class="radio_animated" type="radio" id='centreType3' name="rdoo-ani" onChange={handleCenterTypeRadioChange} value='3' />Panel
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} className='text-center __center__buttonsec'>
                                        <Button variant='secondary' onClick={handleSearchList}>Download CSV</Button>
                                    </Col>
                                </Form.Row>
                                <Form.Row>

                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Centre List</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <DataTables
                                                keyField='CentreId'
                                                tableData={tableData}
                                                columns={columns}
                                                noDataIndication={NoRecordFounds}
                                                pagination={paginationFactory(paginationOptions)}
                                            />
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    downloadvisible &&
                    <CSVLink data={CentrelistCSV} filename="CentreMasterData.csv" target="_blank" id="csvdownload"></CSVLink>
                }
            </Container>
        </React.Fragment>
    )
}
export default CentreMasterData;