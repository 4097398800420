import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import Breadcrumb from '../../../components/common/breadcrumb';
import SweetAlert from 'sweetalert2';
import axios from 'axios';
import { URL_Start } from '../../../constant';
import { useLocation, useHistory } from "react-router-dom";


const SettleDiscount = () => {

  const [Name, SetName] = useState("");
  const [PatientMobile, SetPatientMobile] = useState("");
  const [PatientUHID, SetPatientUHID] = useState("");
  const [PatientDOB, SetPatientDOB] = useState("");
  const [PatientGender, SetPatientGender] = useState("");
  const [Address, SetAddress] = useState("");
  const [CentreName, SetCentreName] = useState("");
  const [Visitno, SetVisitno] = useState("");
  const [VisitDate, SetVisitDate] = useState("");
  const [ReferedBy, SetReferedBy] = useState("");
  const [Registrationdate, SetRegistrationdate] = useState("");
  const [PatientEmailid, SetPatientEmailid] = useState("");
  const [ReferDoctor, SetReferDoctor] = useState("");
  const [TotalAmt, SetTotalAmt] = useState("");
  const [PaidAmt, SetPaidAmt] = useState("");
  const [DiscAmt, SetDiscAmt] = useState("");
  const [Orderstatus, SetOrderStatus] = useState("0");
  const [ObjdiscountAprvdetails, SetObjdiscountAprvdetails] = useState([]);
  const [orderdetailID, SetOrderDetailID] = useState("0");
  const [Type, SetType] = useState("0");
  const [cardhide, setCardHide] = useState(false);
  const [availLimitFlag, setAvailLimitFlag] = useState("");
  const location = useLocation();
  let Aprvbutton = useRef();
  let reasontext = useRef();
  const [initiatedBy, setInitiatedBy] = useState("");
  const [approverName, setApproverName] = useState("");
  const [availableLimit, setAvailableLimit] = useState("");
  const [totalLimit, setTotalLimit] = useState("");
  const [IsApprove, setIsApprove] = useState("-1");
  const [remarks, setRemarks] = useState("");
  const [hide, setHide] = useState(false);

  // useEffect(() => {
  //   const params = location.search;//new URLSearchParams(location.search);
  //   let newparams = params;
  //   if(params.charAt(0) === "?"){newparams = params.split("?")[1]}
  //   const decryptString = window.atob(newparams);
  //   // getdiscountapproved(params.get('id'), params.get('type'),params.get('Isaprrove'));
  //   getdiscountapproved(decryptString.split('&')[0].split('=')[1], decryptString.split('&')[1].split('=')[1], decryptString.split('Isaprrove=')[1]);
  // }, [])

  useEffect(() => {
    let url = location.search;
    if (url.charAt(0) === "?") { url = url.substring(1) }
    const decryptString = window.atob(url);
    CheckCorrectUserLogin(decryptString.split('&')[0].split('=')[1], decryptString.split('&')[1].split('=')[1], decryptString.split('Isaprrove=')[1])
    //getdiscountapproved(decryptString.split('&')[0].split('=')[1], decryptString.split('&')[1].split('=')[1], decryptString.split('Isaprrove=')[1]);
  }, [])

  const getdiscountapproved = async (OrderDetailId, type, Isaprrove) => {
    SetOrderDetailID(OrderDetailId);
    SetType(type);
    setIsApprove(Isaprrove);
    try {
      let url = URL_Start + 'master/Getdiscountapproved/' + OrderDetailId + '/' + type + '/' + Isaprrove + '/0/0'
      await axios.get(url).then((response) => {
        const data = response.data;
        if (data.Success) {
          if (data.Data.Name === null && data.Data.PatientUHID === null) {
            SweetAlert.fire("Url details is not valid");
            setCardHide(true);
          }
          else {
            setCardHide(false);
            SetName(data.Data.Name);
            SetPatientMobile(data.Data.PatientMobile);
            SetPatientUHID(data.Data.PatientUHID);
            SetPatientDOB(data.Data.PatientDOB);
            SetPatientGender(data.Data.PatientGender);
            SetAddress(data.Data.Address);
            SetCentreName(data.Data.CentreName);
            SetVisitno(data.Data.Visitno);
            SetVisitDate(data.Data.VisitDate);
            SetReferedBy(data.Data.ReferedBy);
            SetRegistrationdate(data.Data.Registrationdate);
            SetPatientEmailid(data.Data.PatientEmailid);
            SetReferDoctor(data.Data.ReferDoctor);
            SetTotalAmt(data.Data.TotalAmount);
            SetPaidAmt(data.Data.PaidAmount);
            SetDiscAmt(data.Data.DiscountAmount);
            SetObjdiscountAprvdetails(data.Data.objdiscountAprvdetails);
            SetOrderStatus(data.Data.OrderStatus);
            setAvailLimitFlag(data.Data.AvailLimitFlag);
            setInitiatedBy(data.Data.InitiatedBy);
            setApproverName(data.Data.ApproverName);
            setAvailableLimit(data.Data.AvailableLimit);
            setTotalLimit(data.Data.TotalLimit);
            setRemarks(data.Data.Remarks);
          }
        }
        else {
          SetName("");
          SetPatientMobile("");
          SetPatientUHID("");
          SetPatientDOB("");
          SetPatientGender("");
          SetAddress("");
          SetCentreName("");
          SetVisitno("");
          SetVisitDate("");
          SetReferedBy("");
          SetRegistrationdate("");
          SetPatientEmailid("");
          SetReferDoctor("");
          SetTotalAmt("");
          SetPaidAmt("");
          SetDiscAmt("");
          SetObjdiscountAprvdetails("");
          SetOrderStatus("0");
          setAvailLimitFlag("");
          setInitiatedBy("");
          setApproverName("");
          setAvailableLimit("");
          setTotalLimit("");
          setRemarks("");
        }
      }).catch(function (error) {
        if (error.response) {
          AxiosError(error.response.status);
        }
      });
    }
    catch (error) {
      SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
    }
  }
  const CheckCorrectUserLogin = async (OrderDetailId, type, Isaprrove) => {
    try {
      let url = URL_Start + 'master/CheckCorrectUserLogin/' + OrderDetailId + '/' + localStorage.LoggedInUser
      await axios.get(url).then((response) => {
        const data = response.data;
        if (data.Success === true) {
          setHide(false);
          if (data.Data.Output === "1" || data.Data.Output === 1 || data.Data.Output === "True") {
            setHide(false);
            getdiscountapproved(OrderDetailId, type, Isaprrove);
          }
          else if (data.Data.Output === "2") {
            setHide(true);
            SweetAlert.fire({
              title: 'Error', text: "This amount has already been settled.", icon: 'error', showCancelButton: false, confirmButtonText: 'Ok', reverseButtons: true
            }).then((result) => {
              if (Type === "1") {
                var win = window.open(`${process.env.PUBLIC_URL}/accounts/discounts`, "discount");
                win.location.reload();
              }
              if (Type === "2") {
                var win2 = window.open(`${process.env.PUBLIC_URL}/accounts/credits`, "credit");
                win2.location.reload();
              }
              window.top.close(false);
            })
            return false;
          }
          else {
            setHide(true);
            SweetAlert.fire({
              title: 'Error', text: "Please login with correct authorizer", icon: 'error', showCancelButton: false, confirmButtonText: 'Ok', reverseButtons: true
            }).then((result) => {
              if (Type === "1") {
                var win = window.open(`${process.env.PUBLIC_URL}/accounts/discounts`, "discount");
                win.location.reload();
              }
              if (Type === "2") {
                var win2 = window.open(`${process.env.PUBLIC_URL}/accounts/credits`, "credit");
                win2.location.reload();
              }
              window.top.close(false);
            })
            return false;
          }
        }
        else {
          SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" });
        }
      }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } });
    } catch (error) { APIError("-3"); }
  }
  const CheckCorrectUserLoginOnClickAgain = async (flag) => {
    try {
      let url = URL_Start + 'master/CheckCorrectUserLogin/' + orderdetailID + '/' + localStorage.LoggedInUser
      await axios.get(url).then((response) => {
        const data = response.data;
        if (data.Success === true) {
          setHide(false);
          if (data.Data.Output === "1" || data.Data.Output === 1 || data.Data.Output === "True") {
            setHide(false);
            Approvediscount(flag);
          }
          else if (data.Data.Output === "2") {
            setHide(true);
            SweetAlert.fire({
              title: 'Error', text: "This amount has already been settled.", icon: 'error', showCancelButton: false, confirmButtonText: 'Ok', reverseButtons: true
            }).then((result) => {
              if (Type === "1") {
                var win = window.open(`${process.env.PUBLIC_URL}/accounts/discounts`, "discount");
                win.location.reload();
              }
              if (Type === "2") {
                var win2 = window.open(`${process.env.PUBLIC_URL}/accounts/credits`, "credit");
                win2.location.reload();
              }
              window.top.close(false);
            })
            return false;
          }
          else {
            setHide(true);
            SweetAlert.fire({
              title: 'Error', text: "Please login with correct authorizer", icon: 'error', showCancelButton: false, confirmButtonText: 'Ok', reverseButtons: true
            }).then((result) => {
              if (Type === "1") {
                var win = window.open(`${process.env.PUBLIC_URL}/accounts/discounts`, "discount");
                win.location.reload();
              }
              if (Type === "2") {
                var win2 = window.open(`${process.env.PUBLIC_URL}/accounts/credits`, "credit");
                win2.location.reload();
              }
              window.top.close(false);
            })
            return false;
          }
        }
        else {
          SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" });
        }
      }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } });
    } catch (error) { APIError("-3"); }
  }
  const Approvediscount = async (flag) => {
    if (flag === "2") {
      if (reasontext.current.value === "") {
        SweetAlert.fire("Reject reason is mandatory");
        return false;
      }
    }
    try {
      let url;
      url = URL_Start + 'master/Getdiscountapproved/' + orderdetailID + '/' + Type + '/' + IsApprove + '/' + flag + '/' + (reasontext.current.value === "" ? "0" : reasontext.current.value)
      await axios.get(url).then((response) => {
        const data = response.data;
        if (Type === "1") {
          if (data.Data.ApproveReject === "1" || data.Data.ApproveReject === "6") {
            if (data.Data.FinalOrderStatus === "0" || data.Data.FinalOrderStatus === "6") {
              SweetAlert.fire({
                text: "Approved",
                showCancelButton: false,
                confirmButtonText: 'Ok',
                reverseButtons: true
              })
                .then((result) => {
                  if (result.value) {
                    var win = window.open(`${process.env.PUBLIC_URL}/accounts/discounts`, "discount");
                    win.location.reload();
                    window.top.close(false);
                  }
                  else {
                    var win = window.open(`${process.env.PUBLIC_URL}/accounts/discounts`, "discount");
                    win.location.reload();
                    window.top.close(false);
                  }
                })
            }
            else {
              SweetAlert.fire({
                text: "Already Approved",
                showCancelButton: false,
                confirmButtonText: 'Ok',
                reverseButtons: true
              })
                .then((result) => {
                  if (result.value) {
                    var win = window.open(`${process.env.PUBLIC_URL}/accounts/discounts`, "discount");
                    win.location.reload();
                    window.top.close(false);
                  }
                  else {
                    var win = window.open(`${process.env.PUBLIC_URL}/accounts/discounts`, "discount");
                    win.location.reload();
                    window.top.close(false);
                  }
                })
            }
          }
          else if (data.Data.ApproveReject === "2" || data.Data.ApproveReject === "6") {
            reasontext.current.value = data.Data.RejectReasonText;
            if (data.Data.FinalOrderStatus === "0" || data.Data.FinalOrderStatus === "6") {
              SweetAlert.fire({
                text: "Rejected",
                showCancelButton: false,
                confirmButtonText: 'Ok',
                reverseButtons: true
              })
                .then((result) => {
                  if (result.value) {
                    var win = window.open(`${process.env.PUBLIC_URL}/accounts/discounts`, "discount");
                    win.location.reload();
                    window.top.close(false);
                  }
                  else {
                    var win = window.open(`${process.env.PUBLIC_URL}/accounts/discounts`, "discount");
                    win.location.reload();
                    window.top.close(false);
                  }
                })
            }
            else {
              SweetAlert.fire({
                text: "Already Rejected",
                showCancelButton: false,
                confirmButtonText: 'Ok',
                reverseButtons: true
              })
                .then((result) => {
                  if (result.value) {
                    var win = window.open(`${process.env.PUBLIC_URL}/accounts/discounts`, "discount");
                    win.location.reload();
                    window.top.close(false);
                  }
                  else {
                    var win = window.open(`${process.env.PUBLIC_URL}/accounts/discounts`, "discount");
                    win.location.reload();
                    window.top.close(false);
                  }
                })
            }
          }
        }
        else {
          if (data.Data.ApproveReject === "1" || data.Data.ApproveReject === "6" || data.Data.ApproveReject === "5") {
            if (data.Data.FinalOrderStatus === "0" || data.Data.FinalOrderStatus === "6") {
              SweetAlert.fire({
                text: "Approved",
                showCancelButton: false,
                confirmButtonText: 'Ok',
                reverseButtons: true
              })
                .then((result) => {
                  if (result.value) {
                    var win = window.open(`${process.env.PUBLIC_URL}/accounts/credits`, "credit");
                    win.location.reload();
                    window.top.close(false);
                  }
                  else {
                    var win = window.open(`${process.env.PUBLIC_URL}/accounts/credits`, "credit");
                    win.location.reload();
                    window.top.close(false);
                  }
                })
            }
            else {
              SweetAlert.fire({
                text: "Already Approved",
                showCancelButton: false,
                confirmButtonText: 'Ok',
                reverseButtons: true
              })
                .then((result) => {
                  if (result.value) {
                    var win = window.open(`${process.env.PUBLIC_URL}/accounts/credits`, "credit");
                    win.location.reload();
                    window.top.close(false);
                  }
                  else {
                    var win = window.open(`${process.env.PUBLIC_URL}/accounts/credits`, "credit");
                    win.location.reload();
                    window.top.close(false);
                  }
                })
            }
          }
          else if (data.Data.ApproveReject === "2" || data.Data.ApproveReject === "6" || data.Data.ApproveReject === "5") {
            reasontext.current.value = data.Data.RejectReasonText;
            if (data.Data.FinalOrderStatus === "0" || data.Data.FinalOrderStatus === "6") {
              SweetAlert.fire({
                text: "Rejected",
                showCancelButton: false,
                confirmButtonText: 'Ok',
                reverseButtons: true
              })
                .then((result) => {
                  if (result.value) {
                    var win = window.open(`${process.env.PUBLIC_URL}/accounts/credits`, "credit");
                    win.location.reload();
                    window.top.close(false);
                  }
                  else {
                    var win = window.open(`${process.env.PUBLIC_URL}/accounts/credits`, "credit");
                    win.location.reload();
                    window.top.close(false);
                  }
                })
            }
            else {
              SweetAlert.fire({
                text: "Already Rejected",
                showCancelButton: false,
                confirmButtonText: 'Ok',
                reverseButtons: true
              })
                .then((result) => {
                  if (result.value) {
                    var win = window.open(`${process.env.PUBLIC_URL}/accounts/credits`, "credit");
                    win.location.reload();
                    window.top.close(false);
                  }
                  else {
                    var win = window.open(`${process.env.PUBLIC_URL}/accounts/credits`, "credit");
                    win.location.reload();
                    window.top.close(false);
                  }
                })
            }
          }
        }

      }).catch(function (error) { if (error.response) { AxiosError(error.response.status); } });
    } catch (error) { APIError("-3"); }
  }
  const AxiosError = async (ErrorCode) => {
    if (ErrorCode === 400) { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
    else if (ErrorCode === 401) { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
    else if (ErrorCode === 403) { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
    else if (ErrorCode === 404) { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
    else if (ErrorCode === 500) { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
    else if (ErrorCode === 503) { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
  }
  const APIError = async (ErrorCode, ErrorMsg) => {
    if (ErrorCode === "-1") { SweetAlert.fire({ title: "Data unavailable", text: "No record found", icon: "error" }) }
    else if (ErrorCode === "-2") { SweetAlert.fire({ title: "API Server Error", text: ErrorMsg, icon: "error" }); }
    else if (ErrorCode === "-3") { SweetAlert.fire({ title: "Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
    else if (ErrorCode === "-4") { SweetAlert.fire({ title: "Wrong Credentials", text: "Please enter correct credentials", icon: "error" }) }
    else if (ErrorCode === "-5") { SweetAlert.fire({ title: "Oops", text: "Data could not be saved, Please try again later", icon: "error" }) }
    else if (ErrorCode === "-6") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
    else if (ErrorCode === "-7") { SweetAlert.fire({ title: "Duplicate", text: "Template already exists", icon: "error" }) }
    else if (ErrorCode === "-8") { SweetAlert.fire({ title: "Data unavailable", text: "No record found to import", icon: "error" }) }
    else if (ErrorCode === "-9") { SweetAlert.fire({ title: "LDAP authentication failed", text: ErrorMsg, icon: "error" }) }
    else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
  }

  return (
    <React.Fragment>
      {
        hide === false ?
          <>
            {Type === '1' ?
              <Breadcrumb title="Discount Approval" parent="Common" />
              :
              <Breadcrumb title="Credit Approval" parent="Common" />
            }
            <Container fluid>
              <Row>
                <Col md={12}>
                  <Card>
                    <Card.Body>
                      <div className="formWrapper">
                        <div className="main">
                          <div className="row mb-2">
                            <div className="col-sm-6"> Patient Name: <span><b>{Name}</b></span></div>
                            <div className="col-sm-6">  Age/Gender: <span><b>{PatientDOB + "/" + PatientGender}</b></span></div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-sm-6"> Visit No:  <span><b>{Visitno}</b></span></div>
                            <div className="col-sm-6"> Visit Date.:  <span><b>{VisitDate}</b></span></div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-sm-6">  UHID No.:  <span><b>{PatientUHID}</b></span></div>
                            <div className="col-sm-6"> Registration Date:  <span><b>{Registrationdate}</b></span></div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-sm-6"> Centre:  <span><b>{CentreName}</b></span></div>
                            <div className="col-sm-6"> Refer Doctor:  <span><b>{ReferDoctor}</b></span></div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-sm-6"> Patient Mobile:   <span><b>{PatientMobile}</b></span></div>
                            <div className="col-sm-6"> Patient Email:  <span><b>{PatientEmailid}</b></span></div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-sm-6"> Initiated By:  <span><b>{initiatedBy}</b></span></div>
                            <div className="col-sm-6"> Approver Name:   <span><b>{approverName}</b></span></div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-sm-6"> Available Limit:  <span><b>{availableLimit}</b></span></div>
                            <div className="col-sm-6"> Total Limit:   <span><b>{totalLimit}</b></span></div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-sm-6"> Address:   <span><b>{Address}</b></span></div>
                            <div className="col-sm-6"> Remarks:  <span><b>{remarks}</b></span></div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-sm-12"> Investigations:  <span><b>{ }</b></span></div>
                          </div>
                          <table class="table table-bordered">
                            <thead>
                              <tr>
                                <th scope="col"> #</th>
                                <th scope="col"> Investigation Name </th>
                                <th scope="col"> Rate </th>
                                <th scope="col"> Line Item Discount </th>
                                <th scope="col"> Amount </th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                ObjdiscountAprvdetails != [] ?
                                  <>
                                    {
                                      ObjdiscountAprvdetails.map((d, index) =>
                                        <tr>
                                          <td>{index + 1}</td>
                                          <td>{d.ServiceName}</td>
                                          <td>{d.MRP}</td>
                                          <td>{d.ItemDiscount}</td>
                                          <td>{d.Total}</td>
                                        </tr>
                                      )
                                    }
                                  </>
                                  : null
                              }
                            </tbody>
                          </table>
                          <div className="row mb-2">
                            <div className="col-sm-6"> Net Amount:   <span><b>{TotalAmt}</b></span></div>
                            <div className="col-sm-6"> Paid Amount:  <span><b>{PaidAmt}</b></span></div>
                          </div>
                          <div className="row mb-2">
                            <div className="col-sm-6">{Type === '1' ? "Discount Amount:" : "Credit Amount:"}<span><b>{DiscAmt}</b></span></div>
                            <div className="col-sm-6"> Status:
                              {
                                cardhide === false ?
                                  <>
                                    {
                                      Orderstatus === "0" || Orderstatus === "6" ?
                                        <>
                                          {
                                            availLimitFlag === "0" || availLimitFlag === "False" ?
                                              <Button variant='primary' type='submit' id="verify" className="mr-2" onClick={() => CheckCorrectUserLoginOnClickAgain("1")} >Approve</Button> :
                                              <div className='text-red'>Insufficient available limit</div>
                                          }
                                          <Button variant='secondary' type='submit' id="verify" className="mr-2" onClick={() => CheckCorrectUserLoginOnClickAgain("2")} >Reject</Button>
                                        </>
                                        :
                                        Orderstatus === "1" || Orderstatus === "5" ?
                                          <span><b>Approved</b></span> :
                                          <span><b>Rejected</b></span>
                                    }
                                  </>
                                  : null
                              }

                            </div>
                          </div>
                          {
                            Orderstatus === "0" || Orderstatus === "6" ?
                              <>
                                <div className="row mb-2">
                                  <div className="col-sm-12"> Reject Reason:  <input type='text' ref={reasontext}></input></div>
                                </div>
                              </> :
                              null
                          }
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Container>
          </> :
          null
      }
    </React.Fragment>
  )
}

export default SettleDiscount;
