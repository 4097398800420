import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ViewToolTip  from '../UI/ToolTips/ViewToolTip';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { useHistory } from 'react-router';
const ViewEmployeeDetails = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [isButton, setIsButton] = useState(false);
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const[EmployeeId, setEmployeeId] = useState(localStorage.EmployeeId == undefined ? "" : localStorage.EmployeeId);
    const[EditEmployeeDetails,SetEditEmployeeDetails]=useState(null);
    const navigate = useHistory();
    const [modal, setModal] = useState();
    const toggleModal = () => {
        setModal(!modal)
        
    }
    useEffect(() => {
        BindEmployeeDetailByEmpId();
       }, []);

    const[IsEmployeeView, SetIsEmployeeView] =useState(false);
    
   
   

     async function  BindEmployeeDetailByEmpId() 
     {
        try {
         
            let url=URL_Start + 'hrms/getemployeedetailByEmpId';
                let options = {
                    method: 'POST',
                    url: url,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                    data: { EmployeeId: `${EmployeeId}`}
                };
                let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                    let data = response.data;
    
                    // let data = await response.data;
                    if (data.Success == true) {
                        let ds = data.Data;
                        if (ds != null) {
    
                            await SetEditEmployeeDetails(ds);
                            setModal(!modal);
                        SetIsEmployeeView(true);
                            //BindGrid(1,10,textSearch.current.value.toLowerCase());
                        }
                    }
                    else {
                        let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');
                    setIsLoading(false);
                    }
    
                }
                else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
                // return data;
            } catch (error) {
                if (error.response) { ErrorFunction(error.response.status);
                    setIsLoading(false);
            }
    
        }
        }
     
   
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }

    
    const backHandler = () => {
        navigate.push(`/hrms-master/employeemaster`);
     }

  
  return (
      <><Breadcrumb title="View Employee Detail" parent="Master" />
      <Container fluid>
           {EditEmployeeDetails!=null? <Row>
              <Col md={12}>
                  <Card>
                      <Card.Body>
                      <Form.Row className="justify-content-center">
                         
                         <Col lg={4} md={6}>
                             <Form.Group>
                            
                             <label className="col-form-label"><b>Employee Code</b></label>
                                 <span className="form-control-plaintext" >{EditEmployeeDetails.EmployeeCode==null?'N/A':EditEmployeeDetails.EmployeeCode}</span>
                             </Form.Group>
                         </Col>
                         <Col lg={4} md={6}>
                             <Form.Group>
                             <label className="col-form-label"><b>Employee Name</b></label>
                             <span className="form-control-plaintext" >{EditEmployeeDetails.EmployeeName==null?'N/A':EditEmployeeDetails.EmployeeName}</span>
                             </Form.Group>
                         </Col>
                         <Col lg={4} md={6}>
                         <Form.Group>
                             <label className="col-form-label"><b>Company Code</b></label>
                             <span className="form-control-plaintext" >{EditEmployeeDetails.CompanyCode==null?'N/A':EditEmployeeDetails.CompanyCode}</span>
                             </Form.Group>
                         </Col>
                         
                     </Form.Row>
                     <Form.Row className="justify-content-center">
                    
                    <Col lg={4} md={6}>
                        <Form.Group>
                       
                        <label className="col-form-label"><b>Company Name</b></label>
                            <span className="form-control-plaintext" >{EditEmployeeDetails.CompanyName==null?'N/A':EditEmployeeDetails.CompanyName}</span>
                        </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>Branch Code</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.BranchCode==null?'N/A':EditEmployeeDetails.BranchCode}</span>
                        </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                    <Form.Group>
                        <label className="col-form-label"><b>Branch Name</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.BranchName==null?'N/A':EditEmployeeDetails.BranchName}</span>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row className="justify-content-center">
                <Col lg={4} md={6}>
                    <Form.Group>
                        <label className="col-form-label"><b>Branch Head</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.BranchHead==''?'N/A':<>{EditEmployeeDetails.BranchHead=='True'?'Yes':'NO'}</>}</span>
                        </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                       
                        <label className="col-form-label"><b>Department Code</b></label>
                            <span className="form-control-plaintext" >{EditEmployeeDetails.DepartmentCode==null?'N/A':EditEmployeeDetails.DepartmentCode}</span>
                        </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>Department Name</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.DepartmentName==null?'N/A':EditEmployeeDetails.DepartmentName}</span>
                        </Form.Group>
                    </Col>
                    
                </Form.Row>
                <Form.Row className="justify-content-center">
                <Col lg={4} md={6}>
                    <Form.Group>
                        <label className="col-form-label"><b>Department Head</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.DepartmentHead==null?'N/A':<>{EditEmployeeDetails.DepartmentHead=='True'?'Yes':'NO'}</>}</span>
                        </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                       
                        <label className="col-form-label"><b>Designation</b></label>
                            <span className="form-control-plaintext" >{EditEmployeeDetails.Designation==null?'N/A':EditEmployeeDetails.Designation}</span>
                        </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>Division</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.Division==''?'N/A':EditEmployeeDetails.Division}</span>
                        </Form.Group>
                    </Col>
                   
                </Form.Row>

                <Form.Row className="justify-content-center">
                <Col lg={4} md={6}>
                    <Form.Group>
                        <label className="col-form-label"><b>Zone Code</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.ZoneCode==''?'N/A':EditEmployeeDetails.ZoneCode}</span>
                        </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>Zone Name</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.ZoneName==''?'N/A':EditEmployeeDetails.ZoneName}</span>
                          </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>EmpGroupCode</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.EmpGroupCode==''?'N/A':EditEmployeeDetails.EmpGroupCode}</span>
                        </Form.Group>
                    </Col>
                   
                </Form.Row>
                <Form.Row className="justify-content-center">
                <Col lg={4} md={6}>
                    <Form.Group>
                        <label className="col-form-label"><b>AadhaarNo</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.AadhaarNo==''?'N/A':EditEmployeeDetails.AadhaarNo}</span>
                        </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>Basic Education</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.BasicEducation==''?'N/A':EditEmployeeDetails.BasicEducation}</span>
                          </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>EmpGroupCode</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.EmpGroupCode==''?'N/A':EditEmployeeDetails.EmpGroupCode}</span>
                        </Form.Group>
                    </Col>
                   
                </Form.Row>

                <Form.Row className="justify-content-center">
                <Col lg={4} md={6}>
                    <Form.Group>
                        <label className="col-form-label"><b>Blood Group</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.BloodGroup==''?'N/A':EditEmployeeDetails.BloodGroup}</span>
                        </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>Contact No</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.ContactNo==''?'N/A':EditEmployeeDetails.ContactNo}</span>
                          </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>Creation Date</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.CreationDate==''?'N/A':EditEmployeeDetails.CreationDate}</span>
                        </Form.Group>
                    </Col>
                   
                </Form.Row>

                <Form.Row className="justify-content-center">
                <Col lg={4} md={6}>
                    <Form.Group>
                        <label className="col-form-label"><b>DateOfJoining</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.DateOfJoining==''?'N/A':EditEmployeeDetails.DateOfJoining}</span>
                        </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>DateofLeaving</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.DateofLeaving==''?'N/A':EditEmployeeDetails.DateofLeaving}</span>
                          </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>DateOfRetirement</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.DateOfRetirement==''?'N/A':EditEmployeeDetails.DateOfRetirement}</span>
                        </Form.Group>
                    </Col>
                   
                </Form.Row>
                <Form.Row className="justify-content-center">
                <Col lg={4} md={6}>
                    <Form.Group>
                        <label className="col-form-label"><b>DOB</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.DOB==''?'N/A':EditEmployeeDetails.DOB}</span>
                        </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>DrivingLiNo</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.DrivingLiNo==''?'N/A':EditEmployeeDetails.DrivingLiNo}</span>
                          </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>EmailID</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.EmailID==''?'N/A':EditEmployeeDetails.EmailID}</span>
                        </Form.Group>
                    </Col>
                   
                </Form.Row>
                <Form.Row className="justify-content-center">
                <Col lg={4} md={6}>
                    <Form.Group>
                        <label className="col-form-label"><b>EmailID2</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.EmailID2==''?'N/A':EditEmployeeDetails.EmailID2}</span>
                        </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>EmerContactNo1</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.EmerContactNo1==''?'N/A':EditEmployeeDetails.EmerContactNo1}</span>
                          </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>EmerContactNo2</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.EmerContactNo2==''?'N/A':EditEmployeeDetails.EmerContactNo2}</span>
                        </Form.Group>
                    </Col>
                   
                </Form.Row>
                <Form.Row className="justify-content-center">
                <Col lg={4} md={6}>
                    <Form.Group>
                        <label className="col-form-label"><b>EmerName1</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.EmerName1==''?'N/A':EditEmployeeDetails.EmerName1}</span>
                        </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>EmerName2</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.EmerName2==''?'N/A':EditEmployeeDetails.EmerName2}</span>
                          </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>EmpGroupName</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.EmpGroupName==''?'N/A':EditEmployeeDetails.EmpGroupName}</span>
                        </Form.Group>
                    </Col>
                   
                </Form.Row>
                <Form.Row className="justify-content-center">
                <Col lg={4} md={6}>
                    <Form.Group>
                        <label className="col-form-label"><b>EmploymentType</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.EmploymentType==''?'N/A':EditEmployeeDetails.EmploymentType}</span>
                        </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>JobTypeName</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.JobTypeName==''?'N/A':EditEmployeeDetails.JobTypeName}</span>
                          </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>FatherName</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.FatherName==''?'N/A':EditEmployeeDetails.FatherName}</span>
                        </Form.Group>
                    </Col>
                   
                </Form.Row>
                <Form.Row className="justify-content-center">
                <Col lg={4} md={6}>
                    <Form.Group>
                        <label className="col-form-label"><b>PAddress</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.PAddress==''?'N/A':EditEmployeeDetails.PAddress}</span>
                        </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>PPinCode</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.PPinCode==''?'N/A':EditEmployeeDetails.PPinCode}</span>
                          </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>PCity</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.PCity==''?'N/A':EditEmployeeDetails.PCity}</span>
                        </Form.Group>
                    </Col>
                   
                </Form.Row>
                <Form.Row className="justify-content-center">
                <Col lg={4} md={6}>
                    <Form.Group>
                        <label className="col-form-label"><b>PStateName</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.PStateName==''?'N/A':EditEmployeeDetails.PStateName}</span>
                        </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>PCountryName</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.PCountryName==''?'N/A':EditEmployeeDetails.PCountryName}</span>
                          </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>PANNo</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.PANNo==''?'N/A':EditEmployeeDetails.PANNo}</span>
                        </Form.Group>
                    </Col>
                   
                </Form.Row>
                <Form.Row className="justify-content-center">
                <Col lg={4} md={6}>
                    <Form.Group>
                        <label className="col-form-label"><b>ReportingHead1</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.ReportingHead1==''?'N/A':EditEmployeeDetails.ReportingHead1}</span>
                        </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>ReportingHead2</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.ReportingHead2==''?'N/A':EditEmployeeDetails.ReportingHead2}</span>
                          </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>DateOfJoining</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.DateOfJoining==''?'N/A':EditEmployeeDetails.DateOfJoining}</span>
                        </Form.Group>
                    </Col>
                   
                </Form.Row>

                <Form.Row className="justify-content-center">
                <Col lg={4} md={6}>
                    <Form.Group>
                        <label className="col-form-label"><b>SalaryDate</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.SalaryDate==''?'N/A':EditEmployeeDetails.SalaryDate}</span>
                        </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>ShiftName</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.ShiftName==''?'N/A':EditEmployeeDetails.ShiftName}</span>
                          </Form.Group>
                    </Col>
                    <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>AnniversaryDate</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.AnniversaryDate==''?'N/A':EditEmployeeDetails.AnniversaryDate}</span>
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Form.Row className="justify-content-left">
                <Col lg={4} md={6}>
                        <Form.Group>
                        <label className="col-form-label"><b>EmpStatus</b></label>
                        <span className="form-control-plaintext" >{EditEmployeeDetails.EmpStatus==''?'N/A':EditEmployeeDetails.EmpStatus}</span>
                        </Form.Group>
                    </Col>
                </Form.Row>
                      </Card.Body>
                      <div className="card-footer text-center">
                                <button className="btn btn-primary mr-1"onClick={backHandler} >Previous</button>
                                {/* <button className="btn btn-secondary" onClick={modifyHandler}>Modify Personal Detail</button> */}
                            </div>
                  </Card>
              </Col>
              
          </Row>:null}
         
      </Container></>
  )
}

export default ViewEmployeeDetails;