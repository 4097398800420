import React from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from 'reactstrap';

const SampleToolTip = (props) => {
    return (
        <>
            <Link to="#" onClick={props.onClick} className="text-dark"><i class="fa fa-list-ul mr-2" id={`SampleType${props.id}`} aria-hidden="true"></i></Link>
            <UncontrolledTooltip placement={props.placement} target={`SampleType${props.id}`}>
                {props.title}
            </UncontrolledTooltip>
        </>
    )
}

export default SampleToolTip