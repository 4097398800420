import React, { useState, useEffect, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import AddToolTip from '../UI/ToolTips/AddToolTip';
import SubtractToolTip from '../UI/ToolTips/SubtractToolTip';
import EditToolTip from '../UI/ToolTips/EditToolTip';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Spinner } from 'react-bootstrap';
import LoadingButton from '../commonComponent/LoadingButton';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useHistory } from 'react-router';

var glblIdAdd = "";
var glblNameAdd = "";
var glblIdSubtract = "";
var glblNameSubtract = "";
var glblChecked = "False";
var centreidglobal = "0";
const RatePlanMapping = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [showrateview, setShowRateShowView] = useState(false);
    const [showcenterview, setShowCenterView] = useState(false);
    const [showcentertemplates, setShowCenterTemplates] = useState(false);
    const [showratetemplates, setShowRateTemplates] = useState(false);
    const [newtemplate, setNewTemplate] = useState([]);
    const [existingtemplate, setExistingTemplate] = useState([]);
    const [newcentertemplate, setNewCenterTemplate] = useState([]);
    const [existingcentertemplate, setExistingCenterTemplate] = useState([]);
    const [panelcentertemplate, setPanelCenterTemplate] = useState([]);
    const [panelcentertemplate1, setPanelCenterTemplate1] = useState([]);
    const [region, setRegion] = useState("0");
    const [partnertype, setPartnerType] = useState("0");
    const [newpartnertype, setNewPartnerType] = useState("0");
    const [template, setTemplate] = useState("0");
    const [center, setCenter] = useState("0");
    const [dummyrateadd, setDummyRateAdd] = useState();
    const [dummyratesubtract, setDummyRateSubtract] = useState();
    const [dummyrateaddcenter, setDummyRateAddCenter] = useState();
    const [dummyratesubtractcenter, setDummyRateSubtractCenter] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const [istemplateselected, setIsTemplateSelected] = useState(false);
    const [regionsList, setRegionsList] = useState([]);
    const [displayFilter, setDisplayFilter] = useState(false);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [crntPageNO1, setCrntPageNO1] = useState(0);


    let refregion = useRef();
    let refpartnertype = useRef();
    let refcenterratetype = useRef();
    let refrateplanmapping = useRef();

    useEffect(() => {
        localStorage.removeItem("EditTemplateData");
    }, [])

    useEffect(() => {
        if (existingtemplate.length > 0) {
            for (var key in existingtemplate) {
                if (existingtemplate[key].IsDefaultTemplate == "True") {
                    if (glblChecked === "True") {
                        SweetAlert.fire("Default template already added.Disable previous default template to make a new template as default");
                        return false;
                    }
                }
            }
        }
        setNewTemplate(newtemplate.filter((d) => d.Id !== glblIdAdd));
        setExistingTemplate([...existingtemplate, { "Id": glblIdAdd, "Name": glblNameAdd, "IsDefaultTemplate": glblChecked }]);
    }, [dummyrateadd])
    useEffect(() => {
        setExistingTemplate(existingtemplate.filter((d) => d.Id !== glblIdSubtract));
        setNewTemplate([...newtemplate, { "Id": glblIdSubtract, "Name": glblNameSubtract, "IsDefaultTemplate": glblChecked }]);
    }, [dummyratesubtract])
    useEffect(() => {
        setNewCenterTemplate(newcentertemplate.filter((d) => d.Id !== glblIdAdd));
        setExistingCenterTemplate([...existingcentertemplate, { "Id": glblIdAdd, "Name": glblNameAdd, }]);
    }, [dummyrateaddcenter])
    useEffect(() => {
        setExistingCenterTemplate(existingcentertemplate.filter((d) => d.Id !== glblIdSubtract));
        setNewCenterTemplate([...newcentertemplate, { "Id": glblIdSubtract, "Name": glblNameSubtract }]);
    }, [dummyratesubtractcenter])

    const formik = useFormik({
        initialValues: {
            // centerregion: '',
            // centerpartnertype: '',
            // rate: ''
        },
        validationSchema: Yup.object({
            // centerregion: Yup.string()
            //     .required('Region is required'),
            // centerpartnertype: Yup.string()
            //     .required('Centre Type is required'),
            // rate: Yup.string()
            //     .required('Rate Plan Template is required'),
        }),
        onSubmit: values => {
            //SaveTemplateCenterMapping(values);
        }
    });
    const editformik = useFormik({
        initialValues: {
            // rateregion: '',
            // ratepartnertype: '',
            // center: ''
        },
        validationSchema: Yup.object({
            // rateregion: Yup.string()
            //     .required('Region is required'),
            // ratepartnertype: Yup.string()
            //     .required('Centre Type is required'),
            // center: Yup.string()
            //     .required('Center is required'),
        }),
        onSubmit: values => {
            //SaveRate(values);
        }
    });
    const callSubtract = (Id, Name) => {
        glblIdSubtract = Id;
        glblNameSubtract = Name;
        var chksub = document.getElementById("defaultadd" + Id).innerHTML;
        if (chksub === "False") { glblChecked = "False"; }
        else { glblChecked = "False" }
        const today = new Date();
        const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        setDummyRateSubtract(time);
    }
    const callAdd = (Id, Name) => {
        glblIdAdd = Id;
        glblNameAdd = Name;
        var chk = document.getElementById("default" + Id).checked;
        if (chk === true) { glblChecked = "True"; }
        else { glblChecked = "False" }
        const today = new Date();
        const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        setDummyRateAdd(time);
    }
    const callCenterSubtract = (Id, Name) => {
        glblIdSubtract = Id;
        glblNameSubtract = Name;
        const today = new Date();
        const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        setDummyRateSubtractCenter(time);
    }
    const callCenterAdd = (Id, Name) => {
        glblIdAdd = Id;
        glblNameAdd = Name;
        const today = new Date();
        const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        setDummyRateAddCenter(time);
    }
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actioncenternewFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <EditToolTip title="Edit" placement="top" onClick={(e) => EditRate(rowData.Id)} />
                <AddToolTip title="Add" placement="top" id={rowData.Id} onClick={(e) => callAdd(rowData.Id, rowData.Name)} />
            </div>
        </React.Fragment>
    }
    const actioncenterexistingFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <SubtractToolTip title="Remove" placement="top" id={rowData.Id} onClick={(e) => callSubtract(rowData.Id, rowData.Name)} />
            </div>
        </React.Fragment>
    }
    const actionratenewFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <AddToolTip title="Add" placement="top" id={rowData.Id} onClick={(e) => callCenterAdd(rowData.Id, rowData.Name)} />
            </div>
        </React.Fragment>
    }
    const actionrateexistingFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <SubtractToolTip title="Remove" placement="top" id={rowData.Id} onClick={(e) => callCenterSubtract(rowData.Id, rowData.Name)} />
            </div>
        </React.Fragment>
    }
    const actiondefaultFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <input type="radio" name="isdefault" id={`default${rowData.Id}`} value={rowData.Id} />
            </div>
        </React.Fragment>
    }
    const actiondefault2Formatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            {
                existingtemplate.length > 0 ?
                    <div id={`defaultadd${rowData.Id}`}>
                        {existingtemplate.map((d) => d.Id === rowData.Id ? d.IsDefaultTemplate === "True" ? "Yes" : "No" : null)}
                    </div>
                    :
                    null
            }
        </React.Fragment>
    }
    const centernewcolumns = [{
        dataField: '#',
        text: '#',
        editable: false,
        formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    },
    {
        dataField: 'Name',
        text: 'New Template',
        editable: false
    },
    {
        dataField: 'actions',
        text: 'IsDefault',
        editable: false,
        formatter: actiondefaultFormatter
    },
    {
        dataField: 'actions',
        isDummyField: true,
        text: 'Actions',
        editable: false,
        formatter: actioncenternewFormatter,
        headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    }
    ];
    const centerexistingcolumns = [{
        dataField: '#',
        text: '#',
        editable: false,
        formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    },
    {
        dataField: 'Name',
        text: 'Added Template',
        editable: false
    },
    {
        dataField: 'actions',
        text: 'IsDefault',
        editable: false,
        formatter: actiondefault2Formatter
    },
    {
        dataField: 'actions',
        isDummyField: true,
        text: 'Actions',
        editable: false,
        formatter: actioncenterexistingFormatter,
        headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    }
    ];
    const rateplannewcolumns = [{
        dataField: '#',
        text: '#',
        editable: false,
        formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    },
    {
        dataField: 'Name',
        text: 'New Center',
        editable: false
    },
    {
        dataField: 'actions',
        isDummyField: true,
        text: 'Actions',
        editable: false,
        formatter: actionratenewFormatter,
        headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    }
    ];
    const rateplanexistingcolumns = [{
        dataField: '#',
        text: '#',
        editable: false,
        formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    },
    {
        dataField: 'Name',
        text: 'Added Center',
        editable: false
    },
    {
        dataField: 'actions',
        isDummyField: true,
        text: 'Actions',
        editable: false,
        formatter: actionrateexistingFormatter,
        headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    }
    ];
    const paginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const paginationOptions1 = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO1(((page - 1) * sizePerPage)); }
    };
    const actioncentrepanelFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <EditToolTip title="Edit" placement="top" id={`EditId${rowData.CentrePanelTemplateId}`} onClick={() => editHandler(rowData)} />
            </div>
        </React.Fragment>
    }
    const idcentrepanelFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const centrepanelcolumns = [{
        dataField: '#',
        text: '#',
        editable: false,
        formatter: idcentrepanelFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    },
    {
        dataField: 'CentreName',
        text: 'Mapped Center',
        editable: false
    },
    {
        dataField: 'PanelName',
        text: 'With Panel',
        editable: false
    },
    // {
    //     dataField: 'PaymentTypeName',
    //     text: 'Payment Type',
    //     editable: false
    // },
    {
        dataField: 'actions',
        isDummyField: true,
        text: 'Actions',
        editable: false,
        formatter: actioncentrepanelFormatter,
        headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    }
    ];
    const editHandler = async (rowData) => {
        localStorage.setItem("EditTemplateData", JSON.stringify(rowData));
        history.push({ pathname: `${process.env.PUBLIC_URL}/master/addeditcentrepaneltemplatemapping`, search: `` });
    }
    const actioncentrepanelFormatter1 = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <EditToolTip title="Edit" placement="top" id={`EditId${rowData.CentrePanelTemplateId}`} onClick={() => editHandler1(rowData)} />
            </div>
        </React.Fragment>
    }
    const idcentrepanelFormatter1 = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO1 + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const centrepanelcolumns1 = [{
        dataField: '#',
        text: '#',
        editable: false,
        formatter: idcentrepanelFormatter1, headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    },
    {
        dataField: 'PanelName',
        text: 'Mapped Panel',
        editable: false
    },
    {
        dataField: 'TemplateName',
        text: 'With Template',
        editable: false
    },
    // {
    //     dataField: 'PaymentTypeName',
    //     text: 'Payment Type',
    //     editable: false
    // },
    {
        dataField: 'actions',
        isDummyField: true,
        text: 'Actions',
        editable: false,
        formatter: actioncentrepanelFormatter1,
        headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    }
    ];
    const editHandler1 = async (rowData) => {
        localStorage.setItem("EditTemplateData", JSON.stringify(rowData));
        history.push({ pathname: `${process.env.PUBLIC_URL}/master/addeditcentrepaneltemplatemapping`, search: `` });
    }
    const CallMappingView = (e) => {
        if (e.target.value === "0") {
            setShowRateShowView(false);
            setShowCenterView(false);
        }
        else if (e.target.value === "1") {
            setTemplate("0"); setCenter("0"); setPartnerType("0"); setShowCenterTemplates(false); setShowRateTemplates(false); setIsTemplateSelected(false);
            setShowCenterView(false);
            setShowRateShowView(true);
            GetStatesList("1");
        }
        else {
            setTemplate("0"); setCenter("0"); setPartnerType("0"); setShowCenterTemplates(false); setShowRateTemplates(false); setIsTemplateSelected(false);
            setShowRateShowView(false);
            setShowCenterView(true);
            GetStatesList("1");
        }
        setDisplayFilter(false);
    }
    const showcentertemplatesfunc = (e) => {
        centreidglobal = e.target.value;
        setNewPartnerType("0");
        GetNewPartnerType();
        if (e.target.value === "0") { setShowRateTemplates(false); }
        else {
            setShowRateTemplates(true);
            GetMappedCenterTemplate(refpartnertype.current.value, refregion.current.value, "2", e.target.value, "0");
        }
    }
    const showratetemplatesfunc = (e) => {
        setNewPartnerType("0");
        GetNewPartnerType();
        if (e.target.value === "0") {
            setShowCenterTemplates(false);
            setIsTemplateSelected(false);
            setDisplayFilter(false);
        }
        else {
            setShowCenterTemplates(true);
            setIsTemplateSelected(true);
            GetMappedCenterTemplate(refpartnertype.current.value, refregion.current.value, "1", e.target.value, "0");
            setDisplayFilter(true);
        }
    }
    const RegionFilterHandler = (e) => {
        GetMappedCenterTemplate(refpartnertype.current.value, refregion.current.value, "1", refcenterratetype.current.value, e.target.value);
    }
    const CallRateChange = (e) => {
        GetMappedCenterTemplate(refpartnertype.current.value, refregion.current.value, "2", refcenterratetype.current.value, e.target.value);
    }
    const EditRate = (Id) => {
        window.open(`${process.env.PUBLIC_URL}/master/displayrateplan?id=${Id}`, "_blank");
    }
    const EditTemplateRate = (e) => {
        window.open(`${process.env.PUBLIC_URL}/master/displayrateplan?id=${refcenterratetype.current.value}`, "_blank");
    }
    const GetStatesList = async (Id) => {
        setIsLoading(true);
        try {
            let url = URL_Start + 'master/getstateslist/' + Id;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsLoading(false);
                    if (data.Data != null) {
                        setRegion(data.Data.map((d) => <option key={d.Id} value={d.Id}>{d.Value}</option>));
                    }
                    else { APIError(data.Data); }
                }
                else { setIsLoading(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
        } catch (error) { setIsLoading(false); APIError("-3"); }
    }
    const GetPartnerType = async () => {
        setIsLoading(true);
        try {
            let url = URL_Start + 'master/getpartnertypelist/2/1'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsLoading(false);
                    if (data.Data != null) {
                        let glbCreditCentreTypes = data.Data;
                        if (refrateplanmapping.current.value !== "2") {
                            glbCreditCentreTypes = glbCreditCentreTypes.filter(function (x) { return x.Id !== "6"; });
                            glbCreditCentreTypes = glbCreditCentreTypes.filter(function (x) { return x.Id !== "7"; });
                        }
                        // if (refrateplanmapping.current.value !== "1") {
                        //     glbCreditCentreTypes = glbCreditCentreTypes.filter(function (x) { return x.Id !== "6"; });
                        // }
                        setPartnerType(glbCreditCentreTypes.map((d) => <option key={d.Id} value={d.Id}>{d.Name}</option>))
                    }
                    else { APIError(data.Data); }
                }
                else { setIsLoading(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
        } catch (error) { setIsLoading(false); APIError("-3"); }
    }
    const GetNewPartnerType = async () => {
        setIsLoading(true);
        try {
            // let url = URL_Start + 'master/getpartnertypelist/1/1'
            let url = URL_Start + 'master/getpartnertypelist/2/1' // Done this because this is use only for filteration that's why used flag is 2
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsLoading(false);
                    if (data.Data != null) {
                        setNewPartnerType(data.Data.map((d) => <option key={d.Id} value={d.Id}>{d.Name}</option>))
                    }
                    else { APIError(data.Data); }
                }
                else { setIsLoading(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
        } catch (error) { setIsLoading(false); APIError("-3"); }
    }
    const GetMappedCenterTemplate = async (partnertypeid, stateId, flag, centertemplateid, regionId) => {
        setIsLoading(true);
        try {
            let url = URL_Start + 'master/getcentermappedtemplate/' + partnertypeid + '/' + stateId + '/' + flag + '/' + centertemplateid + '/' + regionId
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsLoading(false);
                    if (data.Data != null) {
                        if (flag === "1") {
                            if (data.Data.existingratecentermapped !== null) { setExistingCenterTemplate(data.Data.existingratecentermapped); }
                            else { setExistingCenterTemplate([]); }

                            if (data.Data.newratecentermapped !== null) { setNewCenterTemplate(data.Data.newratecentermapped); }
                            else { setNewCenterTemplate([]); }

                            if (data.Data.panelcentertemplatedata !== null) { setPanelCenterTemplate(data.Data.panelcentertemplatedata); }
                            else { setPanelCenterTemplate([]); }

                        }
                        else {
                            if (data.Data.existingratecentermapped !== null) { setExistingTemplate(data.Data.existingratecentermapped); }
                            else { setExistingTemplate([]); }

                            if (data.Data.newratecentermapped !== null) { setNewTemplate(data.Data.newratecentermapped); }
                            else { setNewTemplate([]); }
                            if (data.Data.panelcentertemplatedata !== null) {
                                var dArr = data.Data.panelcentertemplatedata.filter((f) => f.CentreId == centreidglobal);
                                setPanelCenterTemplate1(dArr);
                            }
                            else { setPanelCenterTemplate1([]); }
                        }
                    }
                    else { APIError(data.Data); }
                }
                else { setIsLoading(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
        } catch (error) { setIsLoading(false); APIError("-3"); }
    }
    const CallPartnerType = async (e) => {
        if (e.target.value === "0") {
            setPartnerType("0");
            setTemplate("0");
            setCenter("0");
            setShowCenterTemplates(false);
            setShowRateTemplates(false);
            setIsTemplateSelected(false);
            setNewPartnerType("0");
            setRegionsList([]);
        }
        else {
            setPartnerType("0");
            setTemplate("0");
            setCenter("0");
            setShowCenterTemplates(false);
            setShowRateTemplates(false);
            setIsTemplateSelected(false);
            setNewPartnerType("0");
            GetPartnerType();
            GetNewPartnerType();
            GetRegions(e.target.value);
        }
    }
    const CallRateCenter = async (e, flag) => {
        setTemplate("0"); setCenter("0"); setShowCenterTemplates(false); setShowRateTemplates(false); setIsTemplateSelected(false);
        GetRateCenterTemplate(e.target.value, flag);
    }
    const GetRateCenterTemplate = async (partnertypeid, flag) => {
        setIsLoading(true);
        try {
            let url = URL_Start + 'master/getratecentertemplatelist/' + partnertypeid + '/' + refregion.current.value + '/' + flag
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsLoading(false);
                    if (flag === "1") {
                        setTemplate(data.Data.map((d) => <option key={d.Id} value={d.Id}>{d.Name}</option>))
                    }
                    else {
                        setCenter(data.Data.map((d) => <option key={d.Id} value={d.Id}>{d.Name}</option>))
                    }
                }
                else { setIsLoading(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsLoading(false); if (error.response) { AxiosError(error.response.status); } });
        } catch (error) { setIsLoading(false); APIError("-3"); }
    }
    const SaveTemplateCenterMapping = async (e, flag) => {
        if (flag === "1") { if (existingcentertemplate.length === 0) { SweetAlert.fire("Not allowed to save"); return false; } }
        else { if (existingtemplate.length === 0) { SweetAlert.fire("Not allowed to save"); return false; } }
        const requestOptions = {
            RegionId: refregion.current.value,
            PartnerTypeId: refpartnertype.current.value,
            TemplateCenterId: refcenterratetype.current.value,
            Flag: flag,
            TemplateCenterList: flag === "1" ? JSON.stringify(existingcentertemplate) : JSON.stringify(existingtemplate),
            CreatedBy: localStorage.LoggedInUser
        };
        setIsSaving(true);
        try {
            let url = URL_Start + 'master/savetemplatecentermapping'
            await axios.post(url, requestOptions).then((response) => {
                const data = response.data;
                if (data.Success) {
                    setIsSaving(false);
                    SweetAlert.fire({
                        title: 'Mapped',
                        text: "Saved successfully",
                        icon: 'success',
                        confirmButtonText: 'Ok',
                        reverseButtons: true
                    })
                        .then((result) => {
                            if (result.value) {
                                refrateplanmapping.current.value = "0";
                                setShowRateTemplates(false);
                                setShowCenterTemplates(false);
                                setIsTemplateSelected(false);
                                setShowRateShowView(false);
                                setShowCenterView(false);
                            }
                        });
                }
                else { setIsSaving(false); APIError(data.Data, data.ErrorList.length === 0 ? "" : data.ErrorList[0].errorMsg); }
            }).catch(function (error) { setIsSaving(false); if (error.response) { AxiosError(error.response.status); } });
        } catch (error) { setIsSaving(false); APIError("-3"); }
    }
    const GetRegions = async (id) => {
        try {
            let url = URL_Start + 'master/getregionslist/' + id;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setRegionsList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const AxiosError = async (ErrorCode) => {
        if (ErrorCode === 400) { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode === 401) { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode === 403) { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode === 404) { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode === 500) { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode === 503) { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Data unavailable", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-2") { SweetAlert.fire({ title: "API Server Error", text: ErrorMsg, icon: "error" }); }
        else if (ErrorCode === "-3") { SweetAlert.fire({ title: "Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        else if (ErrorCode === "-4") { SweetAlert.fire({ title: "Wrong Credentials", text: "Please enter correct credentials", icon: "error" }) }
        else if (ErrorCode === "-5") { SweetAlert.fire({ title: "Oops", text: "Data could not be saved, Please try again later", icon: "error" }) }
        else if (ErrorCode === "-6") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
        else if (ErrorCode === "-7") { SweetAlert.fire({ title: "Duplicate", text: "Template already exists", icon: "error" }) }
        else if (ErrorCode === "-8") { SweetAlert.fire({ title: "Data unavailable", text: "No record found to import", icon: "error" }) }
        else if (ErrorCode === "-9") { SweetAlert.fire({ title: "LDAP authentication failed", text: ErrorMsg, icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Rate Templates Mapping" parent="Master" />
            <Container fluid>
                <Row className='mt25'>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row className="justify-content-center">
                                    <Col lg={4} md={6}>
                                        <Form.Group>
                                            <Form.Label>Rate Templates Mapping <span className='text-red'>*</span></Form.Label>
                                            <Form.Control as="select" ref={refrateplanmapping} name="rateplanmapping" onChange={(e) => CallMappingView(e)}>
                                                <option value="0">---Select---</option>
                                                <option value="2">By Rate Type</option>
                                                <option value="1">By Centre</option>
                                            </Form.Control>
                                            {formik.touched.rateplanmapping && formik.errors.rateplanmapping ? (<div className='text-required'>{formik.errors.rateplanmapping}</div>) : null}
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    showcenterview &&
                    <Row className='mt25'>
                        <Col md={12}>
                            <Card>
                                <Card.Body>
                                    <Form.Row>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>State <span className='text-red'>*</span></Form.Label>
                                                <Form.Control as="select" ref={refregion} name="centerregion" onBlur={formik.handleBlur} onChange={(e) => CallPartnerType(e)}>
                                                    <option key="0" value="0">---Select---</option>
                                                    {region}
                                                </Form.Control>
                                                {formik.touched.centerregion && formik.errors.centerregion ? (<div className='text-required'>{formik.errors.centerregion}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>Rate Type <span className='text-red'>*</span></Form.Label>
                                                <Form.Control as="select" ref={refpartnertype} name="centerpartnertype" onBlur={formik.handleBlur} onChange={(e) => CallRateCenter(e, "1")}>
                                                    <option key="0" value="0">---Select---</option>
                                                    {partnertype}
                                                </Form.Control>
                                                {formik.touched.centerpartnertype && formik.errors.centerpartnertype ? (<div className='text-required'>{formik.errors.centerpartnertype}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                {
                                                    istemplateselected === true ?
                                                        <Form.Label>Rate Plan Template <span className='text-red'>*</span><a href="javascriptvoid:(0)" onClick={(e) => EditTemplateRate(e)}><small> (View & Edit)</small></a></Form.Label>
                                                        :
                                                        <Form.Label>Rate Plan Template <span className='text-red'>*</span></Form.Label>
                                                }
                                                <Form.Control as="select" ref={refcenterratetype} name="rate" onBlur={formik.handleBlur} onChange={(e) => showratetemplatesfunc(e)}>
                                                    <option key="0" value="0">---Select---</option>
                                                    {template}
                                                </Form.Control>
                                                {formik.touched.rate && formik.errors.rate ? (<div className='text-required'>{formik.errors.rate}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        {
                                            displayFilter ?
                                                <Col md={4}>
                                                    <Form.Group>
                                                        <Form.Label>Region</Form.Label>
                                                        <Form.Control as="select" onChange={(e) => RegionFilterHandler(e)}>
                                                            <option key="0" value="0">---Select---</option>
                                                            {regionsList}
                                                        </Form.Control>
                                                    </Form.Group>
                                                </Col> : null
                                        }
                                        <Col md={4}></Col>
                                        <Col md={4}></Col>
                                        {
                                            showcentertemplates &&
                                            <>
                                                <Col md={5}>
                                                    <DataTables
                                                        keyField='Id'
                                                        tableData={newcentertemplate}
                                                        columns={rateplannewcolumns}
                                                        noDataIndication={NoRecordFounds}
                                                    />
                                                </Col>
                                                <Col md={2}></Col>
                                                <Col md={5}>
                                                    <DataTables
                                                        keyField='Id'
                                                        tableData={existingcentertemplate}
                                                        columns={rateplanexistingcolumns}
                                                        noDataIndication={NoRecordFounds}
                                                    />
                                                    <DataTables
                                                        keyField='CentrePanelTemplateId'
                                                        tableData={panelcentertemplate}
                                                        columns={centrepanelcolumns}
                                                        noDataIndication={NoRecordFounds}
                                                        pagination={paginationFactory(paginationOptions)}
                                                    />
                                                </Col>
                                                <Col md={12} className='text-center'>
                                                    {isSaving ? <LoadingButton variantName='primary' cssName="mr-2" /> : <Button variant='primary' type='submit' className="mr-2" onClick={(e) => SaveTemplateCenterMapping(e, "1")}>Save</Button>}
                                                </Col>
                                            </>
                                        }
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                }
                {
                    showrateview &&
                    <Row className='mt25'>
                        <Col md={12}>
                            <Card>
                                <Card.Body>
                                    <Form.Row>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>State <span className='text-red'>*</span></Form.Label>
                                                <Form.Control as="select" ref={refregion} name="rateregion" onBlur={editformik.handleBlur} onChange={(e) => CallPartnerType(e)}>
                                                    <option key="0" value="0">---Select---</option>
                                                    {region}
                                                </Form.Control>
                                                {editformik.touched.rateregion && editformik.errors.rateregion ? (<div className='text-required'>{editformik.errors.rateregion}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>Partner Type <span className='text-red'>*</span></Form.Label>
                                                <Form.Control as="select" ref={refpartnertype} name="ratepartnertype" onBlur={editformik.handleBlur} onChange={(e) => CallRateCenter(e, "2")}>
                                                    <option key="0" value="0">---Select---</option>
                                                    {partnertype}
                                                </Form.Control>
                                                {editformik.touched.ratepartnertype && editformik.errors.ratepartnertype ? (<div className='text-required'>{editformik.errors.ratepartnertype}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        <Col md={4}>
                                            <Form.Group>
                                                <Form.Label>Centre <span className='text-red'>*</span></Form.Label>
                                                <Form.Control as="select" ref={refcenterratetype} name="center" onBlur={editformik.handleBlur} onChange={(e) => showcentertemplatesfunc(e)}>
                                                    <option key="0" value="0">---Select---</option>
                                                    {center}
                                                </Form.Control>
                                                {editformik.touched.center && editformik.errors.center ? (<div className='text-required'>{editformik.errors.center}</div>) : null}
                                            </Form.Group>
                                        </Col>
                                        {
                                            showratetemplates &&
                                            <>

                                                <Col md={5}>
                                                    <DataTables
                                                        keyField='Id'
                                                        tableData={newtemplate}
                                                        columns={centernewcolumns}
                                                        noDataIndication={NoRecordFounds}
                                                    />
                                                </Col>
                                                <Col md={2}></Col>
                                                <Col md={5}>
                                                    <DataTables
                                                        keyField='Id'
                                                        tableData={existingtemplate}
                                                        columns={centerexistingcolumns}
                                                        noDataIndication={NoRecordFounds}
                                                    />
                                                    <DataTables
                                                        keyField='CentrePanelTemplateId'
                                                        tableData={panelcentertemplate1}
                                                        columns={centrepanelcolumns1}
                                                        noDataIndication={NoRecordFounds}
                                                        pagination={paginationFactory(paginationOptions1)}
                                                    />
                                                </Col>
                                                <Col md={12} className='text-center'>
                                                    {isSaving ? <LoadingButton variantName='primary' cssName="mr-2" /> : <Button variant='primary' type='submit' className="mr-2" onClick={(e) => SaveTemplateCenterMapping(e, "2")}>Save</Button>}
                                                </Col>
                                            </>
                                        }
                                    </Form.Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                }
                {
                    isLoading ?
                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                        null
                }
            </Container>
        </React.Fragment>
    )
}

export default RatePlanMapping;