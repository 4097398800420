import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Spinner } from 'react-bootstrap';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import axios from 'axios';
import { URL_Start } from '../../../constant';
import SweetAlert from 'sweetalert2';
import EditToolTip from '../UI/ToolTips/EditToolTip';
import paginationFactory from 'react-bootstrap-table2-paginator';
import LoadingButton from '../commonComponent/LoadingButton';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import Select from 'react-select';

var glblrecords = [];
var glblArr = [];
export const DiscountApproval = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isrejectLoading, setIsRejectLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [crntPageNO, setCrntPageNO] = useState(0);
  const [showbutton, setShowButton] = useState(false);
  const [isRejectReport, setIsRejectReport] = useState(false);
  const [typeSelected, setTypeSelected] = useState([]);
  const [CentreListDDL, SetCentreListDDL] = useState([]);

  let rejectreason = useRef();
  useEffect(() => {
    GetCentresList();
    BindGrid();
  }, [])

  const TypeChangeHandler = (options) => {
    setTypeSelected(options.value);
    var arr = [];
    arr = tableData.filter((d) => d.CentreId == options.value)
    setTableData(arr);
  }
  const GetCentresList = async () => {
    var glblArr1 = [];
    try {

      let url = URL_Start + 'master/GETBindCentreListDDL'
      let options = {
        method: 'GET',
        url: url,
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {}
      };
      let response = await axios(options);
      let responseOK = response && response.status == 200;
      if (responseOK) {
        let data = response.data;
        if (data.Success == true) {
          let ds = data.Data;
          if (ds != null) {
            var itmData = data.Data;
            itmData = itmData.filter((d) => d.CentreTypeId == "1")
            // glblArr1.push({ 'value': "0", 'label': "Select All", 'disabled': false });
            itmData.map((option) => { return glblArr1.push({ 'value': option.CentreId, 'label': option.CustCentreName, 'disabled': false }); });
            SetCentreListDDL(glblArr1);
          }
          else { SetCentreListDDL([]); }
        }
        else {
          SetCentreListDDL([]);
          let Errtext = <p><div className='text-required'> No record found </div></p>;
        }
      }
      else { SetCentreListDDL([]); SweetAlert.fire('There is some issue at server. Please try again'); setIsLoading(false); }
    } catch (error) {
      SetCentreListDDL([]);
      if (error.response) {
        ErrorFunction(error.response.status);
      }
    }
  }
  const idFormatter = (cellData, rowData, rowIndex, extraData) => {
    //console.log(JSON.stringify(rowData));
    return <React.Fragment>
      <div>
        <span>{crntPageNO + rowIndex + 1}</span>
      </div>
    </React.Fragment>
  }
  const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
    return <React.Fragment>
      <div>
        {
          rowData.OrderStatus.toLowerCase() === "pending" ?
            <>
              <EditToolTip title="Edit" placement="top" onClick={() => editHandler(rowData.OrderDetailId, "1", "-1")} />
              <input className="checkbox_animated" type="checkbox" onClick={(e) => { handleEditCheckbox(e, rowData); }} />
            </>
            :
            null
        }
      </div>
    </React.Fragment>
  }
  const columns = [
    { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    { dataField: '', text: 'Action', formatter: actionFormatter, editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    { dataField: 'OrderStatus', text: 'Status', editable: false },
    { dataField: 'DiscountType', text: 'Discount Type', editable: false },
    { dataField: 'VisitingCode', text: 'Visit No', editable: false },
    { dataField: 'PatientName', text: 'Patient Name', editable: false },
    { dataField: 'CentreName', text: 'Centre Name', editable: false },
    { dataField: 'TotalAmount', text: 'Total Amount', editable: false },
    { dataField: 'PaidAmount', text: 'Paid Amount', editable: false },
    { dataField: 'DiscountAmount', text: 'Discount Amount', editable: false },
    { dataField: 'ApproverName', text: 'Discount Authorizer Name', editable: false }
  ];

  const BindGrid = async () => {
    setIsSaving(true);
    let url = URL_Start + 'master/GetdiscountapprovedList/ ' + localStorage.getItem('LoggedInUser') + '/1/' + localStorage.getItem('cId')
    await axios.get(url).then((response) => {
      const data = response.data;
      if (data.Success) {
        if (data.Data !== null) {
          glblrecords = data.Data;
          setTableData(data.Data.filter((d) => d.OrderStatus.toLowerCase() === "pending"));
        }
        else { glblrecords = []; setTableData([]); }
        setIsSaving(false);
      }
      else { setIsSaving(false); }
    }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsSaving(false); });
  }

  const paginationOptions = { sizePerPage: 50, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last', onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); } };

  const editHandler = async (OrderDetailId, SetType, Isapprove) => {
    const encryptString = window.btoa(`id=${OrderDetailId}&type=${SetType}&Isaprrove=${Isapprove}`);
    window.open(`${process.env.PUBLIC_URL}/accounts/approve-amount?${encryptString}`, "_blank");
    window.top.close(false);
    // window.open(`${process.env.PUBLIC_URL}/accounts/approve-amount?id=${VisitingId}&type=${SetType}&Isaprrove=${Isapprove}`, "_blank");
  }
  const handleTypeRadioChange = async (e) => {
    setTypeSelected([]);
    if (e.target.value == "1") {
      setTableData(glblrecords.filter((d) => d.OrderStatus.toLowerCase() === "pending"))
    }
    else if (e.target.value == "2") {
      setTableData(glblrecords.filter((d) => d.OrderStatus.toLowerCase() === "approved"))
    }
    else if (e.target.value == "3") {
      setTableData(glblrecords.filter((d) => d.OrderStatus.toLowerCase() === "rejected"))
    }
    else {
      setTableData(glblrecords);
    }
  }
  const handleEditCheckbox = (e, rawData) => {
    console.log(rawData);
    const checked = e.target.checked;
    if (checked) {
      glblArr.push({
        OrderDetailId: rawData.OrderDetailId,
        CentreId: rawData.CentreId
      })
    }
    else {
      glblArr = glblArr.filter((d) => d.OrderDetailId != rawData.OrderDetailId)
    }
    if (glblArr.length == 0) { setShowButton(false); }
    else { setShowButton(true); }
  };
  const handleSave = async (AppRejFlag) => {
    if (glblArr.length == 0) {
      SweetAlert.fire("Please select atleast one order to approve");
      return false;
    }
    const requestOptions = {
      ApproverId: localStorage.LoggedInUser,
      JsonData: JSON.stringify(glblArr),
      DiscountCreditFlag: "1",
      ApproveRejectFlag: AppRejFlag,
      RejectReason: rejectreason.current == undefined || rejectreason.current == null || rejectreason.current == "" ? "" : rejectreason.current.value
    };
    if (AppRejFlag == "1") { setIsLoading(true); }
    if (AppRejFlag == "2") { setIsRejectLoading(true); }
    try {
      let url = URL_Start + 'master/approveselectedcreditdiscount'
      await axios.post(url, requestOptions).then((response) => {
        const data = response.data;
        if (data.Success) {
          if (AppRejFlag == "1") {
            setIsLoading(false);
            SweetAlert.fire({ title: 'Approve', text: "Approved successfully", icon: 'success', confirmButtonText: 'Ok', reverseButtons: true })
              .then((result) => {
                if (result.value) { glblArr = []; setShowButton(false); window.location.reload(false); }
                else { glblArr = []; setShowButton(false); window.location.reload(false); }
              })
          }
          if (AppRejFlag == "2") {
            setIsRejectLoading(false);
            SweetAlert.fire({ title: 'Reject', text: "Rejected successfully", icon: 'success', confirmButtonText: 'Ok', reverseButtons: true })
              .then((result) => {
                if (result.value) { glblArr = []; setShowButton(false); window.location.reload(false); }
                else { glblArr = []; setShowButton(false); window.location.reload(false); }
              })
          }
        }
        else {
          if (AppRejFlag == "1") { setIsLoading(false); }
          if (AppRejFlag == "2") { setIsRejectLoading(false); }
          if (data.Data == "-2001") { SweetAlert.fire("JSON value is incorrect, Contact support team"); }
          else if (data.Data == "-2000" || data.Data == "-6") { SweetAlert.fire("Runtime error occured, Check db logs"); }
          else if (data.Data == "-2010") {
            SweetAlert.fire({ title: 'Only approved those which have available limits', text: `Insufficient available limit on ${data.ErrorList[0].errorMsg} `, icon: 'warning', confirmButtonText: 'Ok', reverseButtons: true })
              .then((result) => {
                if (result.value) { window.location.reload(false); }
                else { window.location.reload(false); }
              });
          }
          else { SweetAlert.fire(data.Data); }
        }
      }).catch(function (error) {
        if (AppRejFlag == "1") { setIsLoading(false); }
        if (AppRejFlag == "2") { setIsRejectLoading(false); }
        if (error.response) { ErrorFunction(error.response.status); }
      });
    } catch (error) {
      if (AppRejFlag == "1") { setIsLoading(false); }
      if (AppRejFlag == "2") { setIsRejectLoading(false); }
      SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
    }
  }
  const openRejectModal = () => { setIsRejectReport(!isRejectReport); }
  const CallFunction = (AppRejFlag) => {
    if (AppRejFlag == "1") { handleSave("1"); }
    else { openRejectModal(); }
  }
  const ErrorFunction = async (ErrorCode) => {
    if (ErrorCode == "400")
      SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
    else if (ErrorCode == "401")
      SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
    else if (ErrorCode == "403")
      SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
    else if (ErrorCode == "404")
      SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
    else if (ErrorCode == "500")
      SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
    else if (ErrorCode == "503")
      SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
  }
  return (
    <React.Fragment>
      <Breadcrumb title="Discount Approval" parent="Accounts" />
      <Container fluid>
        <Row>
          <Col md={12}>
            <Card>
              <Card.Header className='pb-0'>
                <Card.Title>Discount Approval List</Card.Title>
              </Card.Header>
              <Card.Body>
                <Form.Group className='animate-chk m-checkbox-inline center__type mt-15'>
                  <Form.Label for="pending">
                    <input class="radio_animated" type="radio" id='pending' name="rdoo-ani" defaultChecked onChange={handleTypeRadioChange} value='1' />Pending
                  </Form.Label>
                  <Form.Label for="approved">
                    <input class="radio_animated" type="radio" id='approved' name="rdoo-ani" onChange={handleTypeRadioChange} value='2' />Approved
                  </Form.Label>
                  <Form.Label for="rejected">
                    <input class="radio_animated" type="radio" id='rejected' name="rdoo-ani" onChange={handleTypeRadioChange} value='3' />Rejected
                  </Form.Label>
                  <Form.Label for="all">
                    <input class="radio_animated" type="radio" id='all' name="rdoo-ani" onChange={handleTypeRadioChange} value='4' />All
                  </Form.Label>
                </Form.Group>
                <Row>
                  <Col md={6}>
                    <Form.Group className='animate-chk m-checkbox-inline center__type mt-15'>
                      <Form.Label>Center Name</Form.Label>
                      <Select onChange={TypeChangeHandler}
                        options={CentreListDDL}
                        // placeholder='Select All'
                        value={CentreListDDL.filter(function (x) { return x.value === typeSelected; })} />
                    </Form.Group>
                  </Col>
                </Row>
                {
                  isSaving ?
                    <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                    <>
                      <DataTables
                        keyField='OrderDetailId'
                        tableData={tableData}
                        columns={columns}
                        noDataIndication={NoRecordFounds}
                        pagination={paginationFactory(paginationOptions)}
                      />
                    </>
                }
                {
                  showbutton &&
                  <Col md={12} className='text-center __center__buttonsec'>
                    {
                      isLoading ?
                        <LoadingButton variantName='primary' cssName="" /> :
                        <>
                          <Button variant='primary' className="mr-2" onClick={() => CallFunction("1")} >Approve Selected</Button>
                        </>
                    }
                    {
                      isrejectLoading ?
                        <LoadingButton variantName='primary' cssName="" /> :
                        <>
                          <Button variant='secondary' className="mr-2" onClick={() => CallFunction("2")} >Reject Selected</Button>
                        </>
                    }
                  </Col>
                }
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Modal isOpen={isRejectReport} toggle={openRejectModal} centered={true}>
        <ModalHeader toggle={openRejectModal}>Reject reason</ModalHeader>
        <ModalBody>
          <Form.Row className='justify-content-left mt-4'>
            <Col md={12} lg={12}>
              <Form.Group>
                <Form.Label>Reject reason <span className='text-red'>* (250 characters allowed)</span></Form.Label>
                <Form.Control as="textarea" rows={3} name="refundreason" maxLength={250} ref={rejectreason} />
              </Form.Group>
            </Col>
            <Col md={12} className='text-center'>
              {isSaving ?
                <LoadingButton variantName='primary' cssName="mr-2" /> :
                <>
                  <Button variant='primary' type='submit' className="mr-2" onClick={() => handleSave("2")}>Reject</Button>
                </>
              }
            </Col>
          </Form.Row>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}

export default DiscountApproval;