import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import Breadcrumb from '../../../components/common/breadcrumb';
import EditToolTip from '../UI/ToolTips/EditToolTip';
import DataTables from '../commonComponent/DataTable/DataTables';
import SweetAlert from 'sweetalert2';
import { Image_Path, URL_Start } from '../../../constant';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import Alert from "react-bootstrap/Alert";
import { CSVLink } from "react-csv";
import Select from 'react-select';

const PartnerBilling = () => {
    let fromDate = useRef("");
    let TODate = useRef("");
    let CentreName = useRef("");
    const [isLoading, setIsLoading] = useState(false);
    const [table, setTable] = useState([]);
    const [defaultDate, setDefaultDate] = useState("");
    const [apiData, setAPIData] = useState();
    const [Itemcounttable, setItemcountTable] = useState([]);
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [displayPrintReport, setDisplayPrintReport] = useState(false);
    const [csvtable, setCSVTable] = useState([]);
    const [users, setUsers] = useState([]);
    const [displayPDFReport, setDisplayPDFReport] = useState(false);
    const [typeSelected, setTypeSelected] = useState([]);

    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }

    useEffect(() => {
        GetCentresList();
    }, []);

    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0]
        setDefaultDate(defaultValue);
    }, []);
    useEffect(() => {
        if (downloadvisible === true) {
            document.getElementById("csvdownload").click();
        }
    }, [downloadvisible])
    const columns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'VisitNo', text: 'VisitNo', editable: false },
        { dataField: 'PatientName', text: 'PatientName', editable: false },
        { dataField: 'BillDate', text: 'BillDate', editable: false },
        { dataField: 'ItemName', text: 'ItemName', editable: false },
        { dataField: 'NetAmt', text: 'NetAmt', editable: false }
    ];

    const ItemCountcolumns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'ItemDescription', text: 'ItemDescription', editable: false },
        { dataField: 'TestCount', text: 'TestCount', editable: false },
        { dataField: 'Rate', text: 'Rate', editable: false },
        { dataField: 'TotalAmount', text: 'TotalAmount', editable: false },
    ];

    const OpenCSV = () => {
        setDownloadVisible(true);
    }

    const GetCentresList = async () => {
        var glblArr1 = [];
        try {
            let url = URL_Start + 'master/GetCentresforPartnerBilling/'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    let ds = data.Data;
                    if (ds != null) {
                        ds.map((option) => { return glblArr1.push({ 'value': option.CentreId, 'label': option.CentreName, 'disabled': false }); });
                        setUsers(glblArr1);
                    }
                    else {
                        setUsers([]);
                    }
                    //setUsers(data.Data.map((d) => (<option key={d.Id} value={d.CentreId}>{d.CentreName}</option>)))
                }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }

    const handleSearch = async (flag) => {
        if (fromDate.current.value == '' || fromDate.current.value == null || fromDate.current.value == undefined) {
            SweetAlert.fire('Please select from date');
        }
        else if (TODate.current.value === '' || TODate.current.value == null || TODate.current.value == undefined) {
            SweetAlert.fire('Please select to date');
        }
        // else if (CentreName.current.value === '0') {
        else if (typeSelected.length == 0 || typeSelected == [] || typeSelected == null || typeSelected == undefined || typeSelected == "") {
            SweetAlert.fire('Please select centre name');
        }
        else {
            BindGrid(fromDate.current.value, TODate.current.value, flag);
        }
    }

    const BindGrid = async (Fdte, Tdte, flag) => {
        setIsLoading(true);
        setDisplayPrintReport(false);
        if (flag !== "1") { flag = 0 }
        let url = URL_Start + 'master/GetPartnerBillingDetails/' + Fdte + "/" + Tdte + "/" + typeSelected + "/" + flag //CentreName.current.value
        await axios.get(url).then((response) => {
            const data = response.data;
            setIsLoading(false);
            if (data.Success) {
                var apiData = data.Data;
                if (apiData !== null) {
                    setAPIData(apiData);
                    if (flag === "1") {
                        const url = Image_Path + "PDF/" + apiData;
                        downloadFile(url, apiData);
                    }
                    else {
                        setDisplayPrintReport(true);
                        if (apiData.objInvoiceItemDetail !== null) { setTable(apiData.objInvoiceItemDetail); setCSVTable(apiData.objInvoiceItemDetail); }
                        else { setTable([]); setCSVTable([]); }
                        if (apiData.objItemcountDetails !== null) { setItemcountTable(apiData.objItemcountDetails) }
                        else { setItemcountTable([]); }
                    }
                }
                else {
                    setTable([]);
                }
            }
            else {
                SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false);
            }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }


    function downloadFile(url, fileName) {
        const aElement = document.createElement('a');
        aElement.setAttribute('download', fileName);
        aElement.href = url;
        aElement.setAttribute('target', '_blank');
        aElement.click();
        URL.revokeObjectURL(url);
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }

    const TypeChangeHandler = (options) => { setTypeSelected(options.value); }
    return (
        <>
            <Breadcrumb title="Partner Billing" parent="Accounts" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form>
                                    <Form.Row className='justify-content-center'>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>From Date<span className='text-red'>*</span></Form.Label>
                                                <Form.Control type="date" id="fromDate" name="fromDate" ref={fromDate} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Group>
                                                <Form.Label>To Date<span className='text-red'>*</span></Form.Label>
                                                <Form.Control type="date" id="TODate" name="TODate" ref={TODate} />
                                            </Form.Group>
                                        </Col>
                                        <Col md={3}>
                                            <Form.Label>Centre Name<span className='text-red'>*</span></Form.Label>
                                            {/* <Form.Control as="select" id="Centrename" name="Centrename" onChange={GetCentresList} ref={CentreName} >
                                                <option value="0" selected>Select</option>
                                                {users}
                                            </Form.Control> */}
                                            <Select onChange={TypeChangeHandler}
                                                options={users}
                                                value={users.filter(function (x) { return x.value === typeSelected; })} />
                                        </Col>
                                        <Col md={12} className='text-center'>
                                            <Button variant='primary' onClick={handleSearch} >Search</Button>
                                            {/* {displayPrintReport ?
                                                <>
                                            <Button variant='secondary' className="ml-2" filename={"PartnerBilling.csv"} target="_blank" onClick={(e) => OpenCSV()}>Download CSV</Button>
                                            </>
                                                : null} */}
                                            <Button variant='secondary' className="ml-2" onClick={(e) => handleSearch("1")}>Generate Pdf</Button>
                                        </Col>
                                        <Col md={8}>
                                            <Form.Row className='mt15 TableClass'>
                                                <Col md={12}>
                                                    {
                                                        isLoading ?
                                                            <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                            <>
                                                                <DataTables keyField='id' tableData={Itemcounttable} columns={ItemCountcolumns} />
                                                            </>
                                                    }
                                                </Col>
                                            </Form.Row>
                                        </Col>
                                        <Form.Row className='mt15 summaryTableClass'>
                                            <Col md={12}>
                                                {
                                                    isLoading ?
                                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                                        <>
                                                            <DataTables keyField='id' tableData={table} columns={columns} />
                                                        </>
                                                }
                                            </Col>
                                        </Form.Row>
                                    </Form.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    downloadvisible &&
                    <CSVLink data={csvtable} filename="PartnerBilling.csv" target="_blank" id="csvdownload"></CSVLink>
                }
            </Container>
        </>
    )
}

export default PartnerBilling