
import React, { useState, useEffect, useRef,Fragment } from 'react';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { URL_Start } from '../../../../constant';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { useHistory } from "react-router-dom";
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { Spinner } from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import SubtractToolTip from '../../UI/ToolTips/SubtractToolTip';
import DataTables from '../../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../../commonComponent/DataTable/NoRecordFounds';
 let globaldeptid="";
  let globalempid="";
const CACHE = {};
let globaltblAllGroups=[];
//let globaltblExistGroups=[];
let Clickpage=1;
const DoctorSalesMWise = () => {
  
    let typeahead = useRef(null);  
    const typeaheadAsycDoctor = useRef(null);
    let textSearch = useRef(null);
    const history = useHistory();
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const[ErrorPopUp, setErrorPopUp]=useState(0);
    const[tblRefDoctor,SettblRefDoctor]=useState([]);
    const[ModuleDetail,SetModuleDetail]=useState(0);
    const[IsUserTypeMoudle,SetIsUserTypeMoudle]=useState(false);
    const[tbAccessPageRMaster,SettbAccessPageRMaster]=useState([]);
    const[DepartmentDetail,SetDepartmentDetail]=useState([]);
    const[DesignationDetail,SetDesignationDetail]=useState(0);
    const[DoctorBusinessDetails,SetDoctorBusinessDetails]=useState(0);
    const[PartnerTypeDetail,SetPartnerTypeDetail]=useState(0);
    const[IsPartnerTypeShow,SetIsPartnerTypeShow]=useState(false);
    const[IsDepartmentShow,SetIsDepartmentShow]=useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isButton, setIsButton] = useState(false);
    const[IsSearchUsers,SetIsSearchUsers]=useState(false);
    const[SearchBox,SetSearchBox]=useState("");
    const[EmployeeDLists,SetEmployeeDLists]=useState([]);
    const[EmployeeTyOption,SetEmployeeTyOption]=useState([]);
const [userModuleShow, SetuserModuleShow] = useState(false);
const[TXtSearch,SetTXtSearch]=useState("");
const[ViewAccessGroups,SetViewAccessGroups]=useState(null);
const[IsUserView,SetIsUserView]=useState(false);
const[SelectedDeptId,SetSelectedDeptId]=useState(0);
const[DoctorFList,SetDoctorFList]=useState([]);
const[DoctorTyOption,SetDoctorTyOption]=useState([]);
const[EditDoctorFList,SetEditDoctorFList]=useState([]);
 const[SelectedDoctorLists,SetSelectedDoctorLists]=useState([]);
const[globaltblExistGroups, SetglobaltblExistGroups]=useState([]);
 //const[saleslist,SetSalesList]=useState([]);
//    useEffect(() => {
//     alert();
//       console.log('test');
//          }, [globaltblExistGroups]);

const [PageHelper, SetPageHelper] = useState({
    PageNo: '1',
    PageSize: '50',
    TotalRecord: 0,
    dsDoctorDlists: []

});

const [PageHelperBind, SetPageHelperBind] = useState({
    PageNo: '1',
    PageSize: '10',
    TotalRecord: 0,
    dsDoctorDlists: []

});
const paginationOptions = {

    totalSize:globaltblExistGroups.length,
    sizePerPage:PageHelperBind.PageSize,
    showTotal: true,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',

    onSizePerPageChange: (sizePerPage, page, event) => {
        console.log('Size per page change!!!');
        console.log('Newest size per page:' + sizePerPage);
        console.log('Newest page:' + page);
        SetPageHelperBind({...PageHelperBind,PageNo:page, pagesize:sizePerPage});
     
        document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
    },
    onPageChange: (page, sizePerPage) => {
        Clickpage=page;
        let startIndex = page * sizePerPage + 1;
        SetPageHelperBind({...PageHelperBind,PageNo:page});
    
        console.log('Page change!!!');
        console.log('Newest size per page:' + sizePerPage);
        console.log('Newest page:' + page);
    
    }
};

    useEffect(() => {
if(window.Activeflag==false)
  {
    SetglobaltblExistGroups([]);
  }
        asyncFunDepartmentDetails();
 }, []);

 const asyncFunDepartmentDetails =async()=> {
     try {
     let url=URL_Start+ 'master/getBindDepartmentDetailsDDL';
     let options = {
         method: 'GET',
         url: url,
         headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json;charset=UTF-8'
         },
         data: {}
     };
     let response = await axios(options);
         let responseOK = response && response.status == 200;
         if (responseOK) {
             let data = response.data;
             // let data = await response.data;
             if(data.Success==true)
             { let ds=data.Data;
                 if(ds!=null && ds.length>0)
                 {   
                     SetDepartmentDetail(ds.map((option) =>(<option value={option.DepartmentId}>{option.CustDeptName}</option>)));   
                 }
                  else{
                     SetDepartmentDetail([]);
                  }
             }
             else{
                 SetDepartmentDetail([]);
                 let Errtext=<p><div className='text-required'> no record found </div></p>;
                 SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                 console.log('no record found');
             }
            
           
         }
         else{
             let Errtext=<p><div className='text-required'> no record found </div></p>;
             SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
         }
      // return data;
     } catch (error) {
         console.log(error.message);
         let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
         SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
      }
   }
   const asyncFunEmpDetails =async(deptId)=> {
     try {
         setIsButton(true);
     let url=URL_Start+ 'master/getUsersfilterbyDeptiddetails';
     let options = {
         method: 'POST',
         url: url,
         headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json;charset=UTF-8'
         },
         data: {DepartmentId:`${deptId}`
     }
     };
     let response = await axios(options);
         let responseOK = response && response.status == 200;
         if (responseOK) {
             let data = response.data;
             // let data = await response.data;
             if(data.Success==true)
             { let ds=data.Data;
                 if(ds!=null && ds.length>0)
                 {   
                     const timer = setTimeout(() => {
                         setIsButton(false);
                         const TypeOptionDs=[];
                   
                         ds.map((option) =>{
                             const TypeOptions={
                                 'id':option.EmployeeId,
                                 'name':option.CustUserName
                             }
                          return TypeOptionDs.push(TypeOptions);
                             });
                             SetEmployeeDLists(TypeOptionDs);
                       }, 700);
                       return () => clearTimeout(timer);
                  
                 }
                  else{
                     SetEmployeeDLists([]);
                     setIsButton(false);
                  }
             }
             else{
                 SetEmployeeDLists([]);
                 setIsButton(false);
                 let Errtext=<p><div className='text-required'> no record found </div></p>;
                 SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                 console.log('no record found');
             }
         }
         else{
             setIsButton(false);
             let Errtext=<p><div className='text-required'> no record found </div></p>;
             SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
         }
      // return data;
     } catch (error) {
         setIsButton(false);
         console.log(error.message);
         let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
         SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
      }
   }

   const asyncFunDoctorsDetailsBind =async(employeeid)=> {
    try {
    let url=URL_Start+ 'doctormaster/getDoctorBusinessMappingBind';
    let options = {
        method: 'POST',
        url: url,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'
        },
        data: {DrBusinessId:`${1}`, DepartmentId:`${globaldeptid}`,CommonId:`${employeeid}`
    }
    };
    let response = await axios(options);
        let responseOK = response && response.status == 200;
        if (responseOK) {
            let data = response.data;
            // let data = await response.data;
            if(data.Success==true)
            { let ds=data.Data;
                if(ds!=null && ds.length>0)
                {  
                   
                        const TypeOptDocL=[];
                   
                        SetglobaltblExistGroups([]);
                         ds.map((item1) =>{
                            if(globaltblExistGroups.length==0 && globaltblAllGroups.map(val => val["DoctorId"]).indexOf(item1.DoctorId) == -1)
                           {
                               const TypeOptions={
                                   'DoctorId':item1.DoctorId,
                                   'DoctorName':item1.CustrizeName
                               };
                      return TypeOptDocL.push(TypeOptions);
                    
                           }
                     });
                     window.Activeflag=true;
                     SetglobaltblExistGroups([...globaltblExistGroups,...TypeOptDocL]);
                     
                  
                        // ds.map((item) =>{
                        //               const TypeOptrows={
                        //                   'DoctorId':item.DoctorId
                        //               }
                        //            return TypeOptDocL.push(TypeOptrows);
                        //               });
                                      
                        //               SetDoctorTyOption(TypeOptDocL);
                        formik.setFieldValue('LDoctor',ds[0].DoctorId);
                        formik.setFieldTouched('LDoctor',false);
                 
                }
                 else{
                  
                 }
            }
            else{
                let Errtext=<p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                console.log('no record found');
            }
        }
        else{
            let Errtext=<p><div className='text-required'> no record found </div></p>;
            SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
        }
     // return data;
    } catch (error) {
        console.log(error.message);
        let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
        SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
     }
  }

 
   /********** Modals  ***********/
   const [modal, setModal] = useState();
   const toggleModal = () => {
       setModal(!modal)
   }

   const [errorModal, setErrorModal] = useState();
   const errorHandler = () => {
    
      SetIsUserView(false);
        setModal(true); setErrorModal(true);
        formik.handleSubmit();
   
   }


   


   /********** Validations  ***********/
   const formik = useFormik({
       initialValues: {
        department:'0',
         Employee:'0'
       },
       enableReinitialize: true,
       validationSchema: Yup.object({
        department: Yup.string().transform(v => v=== '0' ? '' : v)
                .required('Department is Required'),
                Employee:Yup.string().transform(v => v=== '0' ? '' : v)
                .required('Employee is Required')
               
             
       }),
       isSubmitting:true,
       onSubmit:  async (values,actions) => {
         if(globaltblExistGroups.length==0)
         {
            setModal(true); setErrorModal(true);
         }
          else{
            setModal(false);
            setErrorModal(false);
            setErrorPopUp("");
            try {
               
             let url=URL_Start+ 'doctormaster/SETInsertUpdateDoctorBusinessMapping';
            
             let options = {
                 method: 'POST',
                 url: url,
                 headers: {
                     'Accept': 'application/json',
                     'Content-Type': 'application/json;charset=UTF-8'
                 },
               data:{DrBusinessId:`${1}`,DepartmentId:`${formik.values.department}`, AddedBy:`${LoginId}`,objDoctorIdsList:globaltblExistGroups, objEmployeeIdsList:EmployeeTyOption}
             };
            
             let response = await axios(options);
                 let responseOK = response && response.status == 200;
                 if (responseOK) {
                     let data = response.data;
                     // let data = await response.data;
                     if(data.Success==true && data.Data=="2000")
                     {  
                         formik.resetForm();
                        SetuserModuleShow(false);
                        SetEmployeeDLists([]);
                        SetDoctorFList([]);
                        setTimeout(() => typeahead.current.clear(), 100);
                        window.GlobalBusiness="0";
                        history.push({ pathname: `${process.env.PUBLIC_URL}/doctor-master/doctortoBusinessMapping`, search: `` }); 
                        //setTimeout(() =>typeaheadAsycDoctor.current='', 100);
                         SweetAlert.fire({ title: "Success!", text: "Salesman  been Mapped to Doctors!", icon: "success" });
                         setErrorModal(false);
                     }
                   
                     else{
                          if(data.ErrorList!=null && data.ErrorList.length>0)
                          {
                             let Errtext=<p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                             SweetAlert.fire({ title: "Error", text:data.ErrorList[0].errorMsg, icon: "error" });
                          }
                         
                     }
                    
                   
                 }
                 else{
                     console.log('no record found');
                     
                 }
              // return data;
             } catch (error) {
                 console.log(error.message);
                 let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                 SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
              }
              setTimeout(() => {
               
                 actions.setSubmitting(false);
                 }, 1000);

          }  
       },
   });
   
       
   function onKeyDown(keyEvent) {
       if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
         keyEvent.preventDefault();
       }
     }


 
  const empGroupBlurHandler= (e) => {
       
         if(e.target.value!='' &&e.target.value!=null)
         formik.setFieldTouched('Employee',true);
          else
          formik.errors.Employee="";
      
      }
     const empGroupChangeHandler= (option) => {
         const value = (option.length > 0) ?option[0].name : '';
         const idval = (option.length > 0) ?option[0].id : '';
         globalempid=idval;
          if(idval!='')
          {
            SetglobaltblExistGroups([]);
            asyncFunDoctorsDetailsBind(idval);
          }
           else
           {
            SetglobaltblExistGroups([]);   
           }
        
         formik.setFieldValue('Employee', idval);
         const TypeOpt=[];
                         
             option.map((item) =>{
                           const TypeOptrows={
                               'EmployeeId':item.id
                           }
                        return TypeOpt.push(TypeOptrows);
                           });
                           SetEmployeeTyOption(TypeOpt);
                           console.log(TypeOpt);

         const s1 =idval;
                 if(idval=="0")
                 {
                     formik.setFieldValue('Employee',0);
                 }
                  else{
                     formik.setFieldValue('Employee',idval);
                  } 
            
     }
   
 
    
const PrevBckHandler= async()=>{
   formik.resetForm();
   SetEmployeeDLists([]);
   SetDoctorFList([]);
 window.GlobalBusiness="0";
  history.push({ pathname: `${process.env.PUBLIC_URL}/doctor-master/doctortoBusinessMapping`, search: `` }); 
}


const departmentChangeHandler= (e) => {
 const s1 = e.target.value;
 globaldeptid=s1;
 SetglobaltblExistGroups([]);
 asyncFunEmpDetails(s1);
 setTimeout(() => typeahead.current.clear(), 100);
         if(s1=="0")
         {
             formik.setFieldValue('department',0);
         }
          else{
             formik.setFieldValue('department',s1);   
             
          }  
}

const asyncFundoctorFilterDetails =async(query)=> {
 BindGrid(1,50,query);
}

const BindGrid = async(PageNo, PageSize,query)=>
{
 try {
   setIsLoading(true);
     let url=URL_Start+ 'DoctorMaster/getdoctorfilterBusinessMappedDetails';
     let options = {
         method: 'POST',
         url: url,
         headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json;charset=UTF-8'
         },
         data: { PageNo: `${PageNo}`, PageSize: `${PageSize}`,SearchTxt:query,DrBusinessId:`${1}`, DepartmentId:`${globaldeptid}`,CommonId:`${globalempid}`}
     };
     let response = await axios(options);
         let responseOK = response && response.status == 200;
         if (responseOK) {
             let data = response.data;
             // let data = await response.data;
             if(data.Success==true)
             { let ds=data.Data;
                 setIsLoading(false);
                 if(ds!=null && ds.ObjBindDoctorRecordList!=null && ds.ObjBindDoctorRecordList.length>0)
                 {
                     const TypeOptionDs=[];
                     globaltblAllGroups=[];

                     ds.ObjBindDoctorRecordList.map((item1) =>{
                         if(globaltblExistGroups.map(val => val["DoctorId"]).indexOf(item1.DoctorId) == -1)
                        {
                            const TypeOptions={
                                'id':item1.DoctorId,
                                'name':item1.CustDoctorName
                               
                            };
                   return globaltblAllGroups.push(TypeOptions);
                        }
                         else{
                            const TypeOptions={
                                'id':item1.DoctorId,
                                'name':item1.CustDoctorName,
                                'disabled':true
                            };
                         return globaltblAllGroups.push(TypeOptions);
                         }     
                  });
                
               CACHE[query]={...globaltblAllGroups,globaltblAllGroups, total_count:Number(ds.TotalRecord),page:Number(PageNo)};
                   
                     await SetPageHelper({
                         ...PageHelper,
                          PageNo:Number(PageNo),
                         PageSize: Number(PageSize),
                         TotalRecord: Number(ds.TotalRecord),
                         dsDoctorDlists: ds.ObjBindDoctorRecordList==null ? [] : ds.ObjBindDoctorRecordList
                     }); 
                   
                                      
                 }
             }
             else{
                 setIsLoading(false);
                 let Errtext=<p><div className='text-required'> no record found </div></p>;
                 SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                 console.log('no record found');
                
             }
            
           
         }
         else{
             setIsLoading(false);
             let Errtext=<p><div className='text-required'> no record found </div></p>;
             SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
         }
      // return data;
     } catch (error) {
         setIsLoading(false);
         console.log(error.message);
         let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
         SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
      }

}
const [query, setQuery] = useState('');

const handleInputChange = (q) => {
 setQuery(q);
};

const handlePagination = (e, shownResults) => {
const cachedQuery = CACHE[query];

 if (
    cachedQuery.globaltblAllGroups.length > shownResults ||
    cachedQuery.globaltblAllGroups.length === cachedQuery.total_count
  ) {
    return;
  }

 const page = cachedQuery.page + 1;
 const pagesize=PageHelper.PageSize;
 BindGridAFTER(page,pagesize,query,cachedQuery);
};

const BindGridAFTER = async(PageNo, PageSize,query,cachedQuery)=>
{
 try {
     setIsLoading(true);
     let url=URL_Start+ 'DoctorMaster/getdoctorfilterBusinessMappedDetails';
     let options = {
         method: 'POST',
         url: url,
         headers: {
             'Accept': 'application/json',
             'Content-Type': 'application/json;charset=UTF-8'
         },
         data: { PageNo: `${PageNo}`, PageSize: `${PageSize}`,SearchTxt:query ,DrBusinessId:`${1}`, DepartmentId:`${globaldeptid}`,CommonId:`${globalempid}`}
     };
     let response = await axios(options);
         let responseOK = response && response.status == 200;
         if (responseOK) {
             let data = response.data;
             // let data = await response.data;
             if(data.Success==true)
             { let ds=data.Data;
                 setIsLoading(false);
                 if(ds!=null && ds.ObjBindDoctorRecordList!=null &&  ds.ObjBindDoctorRecordList.length>0)
                 {
                     //let TypeOptionDs=[];

                     globaltblAllGroups=[];

                     ds.ObjBindDoctorRecordList.map((item1) =>{
                         if(globaltblExistGroups.map(val => val["DoctorId"]).indexOf(item1.DoctorId) == -1)
                        {
                            const TypeOptions={
                                'id':item1.DoctorId,
                                'name':item1.CustDoctorName
                               
                            };
                   return globaltblAllGroups.push(TypeOptions);
                        }
                         else{
                            const TypeOptions={
                                'id':item1.DoctorId,
                                'name':item1.CustDoctorName,
                                'disabled':true
                            };
                         return globaltblAllGroups.push(TypeOptions);
                         }     
                  });

                       globaltblAllGroups=[].concat(cachedQuery.globaltblAllGroups,globaltblAllGroups);
                     const page=PageNo;
                     CACHE[query] = { ...cachedQuery, globaltblAllGroups, page };
            
                     await SetPageHelper({
                         ...PageHelper,
                          PageNo:Number(PageNo),
                         PageSize: Number(PageSize),
                         TotalRecord: Number(ds.TotalRecord),
                         dsDoctorDlists: ds.ObjBindDoctorRecordList==null ? [] : ds.ObjBindDoctorRecordList
                     }); 
                   
                                      
                 }
             }
             else{
                 setIsLoading(false);
                 let Errtext=<p><div className='text-required'> no record found </div></p>;
                 SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
                 console.log('no record found');
             }
            
           
         }
         else{
             setIsLoading(false);
             let Errtext=<p><div className='text-required'> no record found </div></p>;
             SweetAlert.fire({ title: "Error", text:"no record found", icon: "error" });
         }
      // return data;
     } catch (error) {
         setIsLoading(false);
         console.log(error.message);
         let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
         SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
      }

}
const ErrorFunction = async (ErrorCode) => {
    if (ErrorCode == "400")
        SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
    else if (ErrorCode == "401")
        SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
    else if (ErrorCode == "403")
        SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
    else if (ErrorCode == "404")
        SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
    else if (ErrorCode == "500")
        SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
    else if (ErrorCode == "503")
        SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
   }
    const RemoveGroup= async(DoctorId,DoctorName)=>{
        let jsonRow={
            "DoctorId":DoctorId, "DoctorName": DoctorName 
        }
        SetglobaltblExistGroups(globaltblExistGroups => [...globaltblExistGroups.filter((d) => d.DoctorId !=DoctorId)]);
    } 
    const idFormatter = (cellData, rowData, rowIndex, extraData,page) => {
        //let startIndex = page * sizePerPage + 1;
       ///Clickpage=
       let rowNumber = (PageHelperBind.PageNo - 1) * PageHelperBind.PageSize + (rowIndex + 1);
       return    <div>
       <span>{rowNumber}</span>
   </div>;
    }
   
    const actionExistingFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <SubtractToolTip title="Remove" placement="top" id={rowData.DoctorId} onClick={(e) => RemoveGroup(rowData.DoctorId, rowData.DoctorName)} />
            </div>
        </React.Fragment>
    }
    
   
    const Existcolumns = [{
        dataField: '#',
        text: '#',
        editable: false,
        formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    },
    {
        dataField: 'DoctorName',
        text: 'Added Doctors',
        editable: false
    },
    {
        dataField: 'actions',
        isDummyField: true,
        text: 'Actions',
        editable: false,
        formatter: actionExistingFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; }
    }
    ];
    
    const handleChange = (option) => {
        const value = (option.length > 0) ?option[0].name : '';
        const idval = (option.length > 0) ?option[0].id : '';
        const hasMatch =globaltblExistGroups.some(function (d) {
            return d.DoctorId ==idval
         });
          if(hasMatch)
          {
            SweetAlert.fire({ title: "Warning", text:"you have already added doctor.", icon: "warning" });
          }
          else{
           
                const TypeOptrows1={
                    "DoctorId":idval,
                    "DoctorName":value
                }
                SetglobaltblExistGroups([...globaltblExistGroups,TypeOptrows1]);
          }
                     
                        window.Activeflag=true;
        typeaheadAsycDoctor.current._instance.clear();
      };
    
 return (
     <Fragment>


             <Row>
                 <Col md={12}>
                     <Card>
                         <Card.Body>
                    
                                     <Form.Row className="justify-content-center">
                                    
                                    <Col md={6} lg={6}>
                                        
                                    <Form.Group>
                                                            <Form.Label>Department<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control as="select"  name="department"  onChange={departmentChangeHandler} onBlur={formik.handleBlur} value={formik.values.department}>
                                                            <option value='0'>---select---</option>
                                                          {DepartmentDetail}
                                                            </Form.Control>
                                                            {formik.touched.department && formik.errors.department ? (<div className='text-required'>{formik.errors.department}</div>) : null}
                                                        </Form.Group>
                                          
                                       </Col>
                                     
                                    </Form.Row>
                                  
                            
                                    <Form.Row className="justify-content-center">
                                    
                                    <Col md={6} lg={6}>
                                            <Form.Group>
                                                            <Form.Label>Employee<span className='text-required'>*</span></Form.Label>
                                                            {isButton?<Spinner animation="border" size='sm' />:null} 
                                                            <Typeahead
                                                 id="Employee" name="Employee"
                                                 //clearButton
                                                 defaultSelected={EmployeeDLists.slice(0, 5)}
                                                 labelKey="name"
                                                 ref={typeahead}
                                                 options={EmployeeDLists}
                                                 value={formik.values.Employee}
                                                  // onChange is on valid option selection (a menu click)
onChange={empGroupChangeHandler}

// onBlur is on leaving the control. Can be used for error checks
onBlur={empGroupBlurHandler}
                                                 placeholder="---select---"
                                             />
                                                            {formik.touched.Employee && formik.errors.Employee ? (<div className='text-required'>{formik.errors.Employee}</div>) : null}
                                                        </Form.Group>
                                                        
                                       </Col>
                                     
                                    </Form.Row>
                                    <Form.Row className="justify-content-center">
                                    
                                    <Col md={6} lg={6}>
                                            <Form.Group>
                                                            <Form.Label>Doctors</Form.Label>
                                                            {isLoading?<Spinner animation="border" size='sm' />:null} 
                                                            <AsyncTypeahead
                                                 id="LDoctor" name="LDoctor"
                                                 //clearButton
                                                 labelKey="name"
                                                 //multiple
                                                 minLength={3}
                                                 maxResults={Number(PageHelper.PageSize)-1}
                                                 paginate
                                                
                                                 onInputChange={handleInputChange}
                                                 onPaginate={handlePagination}
                                                 onSearch={asyncFundoctorFilterDetails}
                                                 options={globaltblAllGroups}
                                                 value={formik.values.LDoctor}
                                                onChange={handleChange}
                     ref={typeaheadAsycDoctor}
                                                 placeholder="Location OR Doctor Name OR Doctor Mobile OR Specialization ....."
                                             />
                                                        </Form.Group>
                                                        
                                       </Col>
                                     
                                    </Form.Row>
                                
                        
                                    <Form.Row className="justify-content-center">
                           
                           
                           <Col md={10} lg={10}>
                           <span className='text-required'>* Doctor Required</span>
                         <DataTables
                                           keyField='DoctorId'
                                           tableData={globaltblExistGroups}
                                           columns={Existcolumns}
                                           noDataIndication={NoRecordFounds}
                                           pagination={paginationOptions ? paginationFactory(paginationOptions) : null}
                                       /> 
                           </Col>
                       </Form.Row>
                        
                
                         </Card.Body>
                     </Card>
                 </Col>
             </Row>          
             <Row className='mt15'>
                                 <Col md={12} className='text-center'>
                                     <Button variant='secondary' className="mr-2"  onClick={PrevBckHandler} >Cancel</Button>
                                     {<Button variant='primary' type='submit' onClick={errorHandler}>Save</Button>}
                                 </Col>
                             </Row>
      

       

         {errorModal &&
             <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                 <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                 <ModalBody>
                    <p>
                    {formik.touched.department && formik.errors.department ? (<div className='text-required'>{formik.errors.department}</div>) : null}
                     </p>
                     <p>
                     {formik.touched.Employee && formik.errors.Employee ? (<div className='text-required'>{formik.errors.Employee}</div>) : null}
                     </p>
                     <p>
                     {globaltblExistGroups==0? (<div className='text-required'>Doctor is Required</div>) : null}
                     </p>
                 </ModalBody>
                 <ModalFooter>
                     <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                     <Button variant="primary" onClick={toggleModal}>Ok</Button>
                 </ModalFooter>
                 
             </Modal>
         }
     </Fragment>
 )
}

export default DoctorSalesMWise;