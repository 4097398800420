import React, { useState, useEffect, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import SweetAlert from 'sweetalert2';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import Select from 'react-select';
import { MultiSelect } from 'react-multi-select-component';
import LoadingButton from '../commonComponent/LoadingButton';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip';
import { useHistory } from 'react-router';


var glblArr1 = [];
const AddEditUserCentreTemplatesMapping = () => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [typeSelected, setTypeSelected] = useState([]);
    const [centretypeSelected, setCentreTypeSelected] = useState([]);
    const [users, setUsers] = useState([]);
    const [centres, setCentres] = useState([]);
    const [showcentres, setShowCentres] = useState(false);
    const [selectedoptions, setSelectedOptions] = useState([]);
    const [temporarydata, setTemporaryData] = useState([]);
    const [ratetemplate, setRateTemplate] = useState([]);
    const [ratetemplatetypeSelected, setRateTemplateTypeSelected] = useState([]);

    useEffect(() => {
        GetAllUsers();
        return () => {
            localStorage.removeItem("EditUserCentreTemplateData");
        }
    }, [])
    const GetAllUsers = async () => {
        var glblArr = [];
        try {
            let url = URL_Start + 'master/getallusers'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        const usersdata = data.Data;
                        usersdata.map((option) => { return glblArr.push({ 'value': option.UserId, 'label': option.UserName }); });
                        setUsers(glblArr);
                        //For Edit Purpose Only////
                        if (JSON.parse(localStorage.getItem('EditUserCentreTemplateData')) == null || JSON.parse(localStorage.getItem('EditUserCentreTemplateData')) == undefined) { }
                        else {
                            var ExtractArray = JSON.parse(localStorage.getItem('EditUserCentreTemplateData'));
                            var typearr = [];
                            typearr.push({
                                value: ExtractArray.UserId,
                                label: ExtractArray.UserName
                            });
                            setTypeSelected(typearr);
                            GetCentreAgainstUser(ExtractArray.UserId);
                            setShowCentres(true);
                            const TypeOptL = [];
                            TypeOptL.push({
                                centreid: ExtractArray.CentreId,
                                centrename: ExtractArray.CentreName
                            });
                            setSelectedOptions(TypeOptL);
                            const TypeOptLArr = [];
                            TypeOptLArr.push({
                                value: ExtractArray.CentreId,
                                label: ExtractArray.CentreName
                            });
                            setCentreTypeSelected(TypeOptLArr);
                            var Arr = [];
                            Arr.push({
                                Id: "1",
                                UserId: ExtractArray.UserId,
                                UserName: ExtractArray.UserName,
                                CentreId: ExtractArray.CentreId,
                                CentreName: ExtractArray.CentreName
                            });
                            setTemporaryData(Arr);
                            GetRateTemplates();
                            var arrayn = [];
                            var tarr = [];
                            arrayn = ExtractArray.TemplateId.split(",");
                            for (var keyn in arrayn) {
                                tarr.push({
                                    Id: "1",
                                    value: arrayn[keyn],
                                    label: ExtractArray.TemplateName,
                                    centreid: ExtractArray.CentreId,

                                });
                            }
                            setRateTemplateTypeSelected(tarr);

                            // var tarr = [];
                            // tarr.push({
                            //     Id: "1",
                            //     value: ExtractArray.TemplateId,
                            //     label: ExtractArray.TemplateName,
                            //     centreid: ExtractArray.CentreId,

                            // });
                            // setRateTemplateTypeSelected(tarr);

                            // var TypeOptLL = [];
                            // e.map((item) => {
                            //     const TypeOptrowss = {
                            //         Id: "1",
                            //         value: item.value,
                            //         label: item.label,
                            //         centreid: ExtractArray.CentreId
                            //     }
                            //     return TypeOptLL.push(TypeOptrowss);
                            // });
                            // setRateTemplateTypeSelected(TypeOptLL);
                        }
                        //For Edit Purpose Only////
                    }
                    else { setUsers([]); }
                }
                else { setUsers([]); }
            }).catch(function (error) { if (error.response) { setUsers([]); AxiosError(error.response.status); } });
        }
        catch (error) { setUsers([]); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const TypeChangeHandler = (options) => {
        var typearr = [];
        typearr.push({
            value: options.value,
            label: options.label
        });
        setTypeSelected(typearr);
        GetCentreAgainstUser(options.value);
        setShowCentres(true);
    }
    const GetCentreAgainstUser = async (UserId) => {
        var glblArr = [];
        try {
            let url = URL_Start + 'master/getcentresagainstuser/' + UserId
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        const centresdata = data.Data;
                        centresdata.map((option) => { return glblArr.push({ 'value': option.CentreId, 'label': option.CentreName }); });
                        setCentres(glblArr);
                    }
                    else { setCentres([]); }
                }
                else { setCentres([]); }
            }).catch(function (error) { if (error.response) { setCentres([]); AxiosError(error.response.status); } });
        }
        catch (error) { setCentres([]); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const CentreChangeHandler = (options) => {
        setIsLoading(false);
        setCentreTypeSelected(options);
        const TypeOptL = [];
        if (options != null && options.length > 0) {
            options.map((item) => {
                const TypeOptrows = {
                    centreid: item.value,
                    centrename: item.label
                }
                return TypeOptL.push(TypeOptrows);
            });
            setSelectedOptions(TypeOptL);
            GetRateTemplates();
        }
        else {
            setSelectedOptions([]);
        }
    }
    const SaveTemporaryData = async () => {
        setIsLoading(true);
        setTimeout(() => {
            var Arr = [];
            for (var key in selectedoptions) {
                Arr.push({
                    Id: parseInt(key + 1),
                    UserId: typeSelected[0].value,
                    UserName: typeSelected[0].label,
                    CentreId: selectedoptions[key].centreid,
                    CentreName: selectedoptions[key].centrename
                });
            }
            setTemporaryData(Arr);
            if (glblArr1.length > 0) {
                var Arr1 = [];
                for (var keys in Arr) {
                    for (var keyz in glblArr1) {
                        if (Arr[keys].CentreId == glblArr1[keyz].CentreId) {
                            Arr1.push({
                                Id: Arr[keys].Id,
                                value: glblArr1[keyz].TemplateId,
                                label: glblArr1[keyz].TemplateName,
                                centreid: glblArr1[keyz].CentreId
                            });
                        }
                    }
                }
                setRateTemplateTypeSelected(Arr1);
            }
            setIsLoading(false);
        }, 500);
    }
    const Displayalert = (Id) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        })
            .then((result) => { if (result.value) { UpdateStatus(Id); } else { SweetAlert.fire('Your data is safe!'); } })
    }
    const UpdateStatus = async (Id) => {
        setTemporaryData(temporarydata.filter((d) => d.Id != Id));
        setRateTemplateTypeSelected(ratetemplatetypeSelected.filter((e) => e.Id != Id));
    }
    const GetRateTemplates = async () => {
        glblArr1 = [];
        var glblArr = [];
        try {
            let url = URL_Start + 'master/getratetemplates'
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) {
                        glblArr1 = data.Data;
                        data.Data.map((option) => { return glblArr.push({ 'value': option.TemplateId, 'label': option.TemplateName, 'centreid': option.CentreId }); });
                        setRateTemplate(glblArr);
                    }
                    else { glblArr1 = []; setRateTemplate([]); }
                }
                else { glblArr1 = []; setRateTemplate([]); }
            }).catch(function (error) { if (error.response) { glblArr1 = []; setRateTemplate([]); AxiosError(error.response.status); } });
        }
        catch (error) { glblArr1 = []; setRateTemplate([]); SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const RateTemplateTypeChangeHandler = (e, Id) => {
        setIsLoading(false);
        if (e == null || e == undefined || e == "") {
            setRateTemplateTypeSelected([]);
        }
        else {
            var temparr = [];
            temparr = ratetemplatetypeSelected;
            temparr = temparr.filter((l) => l.Id != Id)
            var TypeOptL = [];
            e.map((item) => {
                const TypeOptrows = {
                    Id: Id,
                    value: item.value,
                    label: item.label,
                    centreid: e.centreid
                }
                return TypeOptL.push(TypeOptrows);
            });
            const arr3 = temparr.concat(TypeOptL);
            setRateTemplateTypeSelected(arr3);
        }
    }
    const SavePermanentData = async () => {
        var idexist = [];
        for (var keyf in ratetemplatetypeSelected) {
            idexist.push(ratetemplatetypeSelected[keyf].Id)
        }
        const unique = Array.from(new Set(idexist));
        if (temporarydata.length != unique.length) {
            SweetAlert.fire('Rate template is mandatory in all centres');
            return false;
        }
        var finalArr = [];
        for (var key in temporarydata) {
            for (var keys in ratetemplatetypeSelected) {
                if (temporarydata[key].Id == ratetemplatetypeSelected[keys].Id) {
                    finalArr.push({
                        AutoScriptId: temporarydata[key].Id.toString(),
                        UserId: temporarydata[key].UserId,
                        CentreId: temporarydata[key].CentreId,
                        TemplateId: ratetemplatetypeSelected[keys].value,
                        TemplateName: ratetemplatetypeSelected[keys].label
                    })
                }
            }
        }
        var EditId = "0";
        if (JSON.parse(localStorage.getItem('EditUserCentreTemplateData')) == null || JSON.parse(localStorage.getItem('EditUserCentreTemplateData')) == undefined) { EditId = "0"; }
        else {
            var ExtractArray = JSON.parse(localStorage.getItem('EditUserCentreTemplateData'));
            EditId = ExtractArray.UserCentreTemplateId;
        }
        const requestOptions = {
            LoginId: localStorage.LoggedInUser, UserCentreData: JSON.stringify(finalArr), EditTemplateData: EditId
        };
        setIsButtonLoading(true);
        let url = URL_Start + 'master/SaveUserCentreTemplatesData'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            setIsButtonLoading(false);
            if (data.Success) {
                SweetAlert.fire({
                    title: 'Success!',
                    text: EditId == "0" ? "Data saved successfully!" : "Data updated successfully!",
                    icon: 'success',
                    showCancelButton: false,
                    confirmButtonText: 'Ok',
                    reverseButtons: true
                })
                    .then((result) => {
                        if (result.value) { history.push({ pathname: `${process.env.PUBLIC_URL}/master/usercentretemplatesmapping`, search: `` }); window.location.reload(false); }
                        else { history.push({ pathname: `${process.env.PUBLIC_URL}/master/usercentretemplatesmapping`, search: `` }); window.location.reload(false); }
                    })
            }
            else {
                setIsButtonLoading(false);
                if (data.Data == "-1999") { SweetAlert.fire({ title: "Error", text: "User centre templates already mapped!", icon: "error" }); return false; }
                else if (data.Data == "-2000") { SweetAlert.fire({ title: "Error", text: "Check db logs!", icon: "error" }); return false; }
                else { SweetAlert.fire({ title: "Error", text: data.Data, icon: "error" }); return false; }
            }
        }).catch(function (error) { setIsButtonLoading(false); if (error.response) { AxiosError(error.response.status); } });
    }
    const PrevBckHandler = async () => {
        history.push({ pathname: `${process.env.PUBLIC_URL}/master/usercentretemplatesmapping`, search: `` });
    }
    const AxiosError = async (ErrorCode) => {
        if (ErrorCode === 400) { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode === 401) { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode === 403) { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode === 404) { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode === 500) { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode === 503) { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }
    const APIError = async (ErrorCode, ErrorMsg) => {
        if (ErrorCode === "-1") { SweetAlert.fire({ title: "Data unavailable", text: "No record found", icon: "error" }) }
        else if (ErrorCode === "-2") { SweetAlert.fire({ title: "API Server Error", text: ErrorMsg, icon: "error" }); }
        else if (ErrorCode === "-3") { SweetAlert.fire({ title: "Runtime Exception", text: "Some error occured! Please try after sometime.", icon: "error" }) }
        else if (ErrorCode === "-4") { SweetAlert.fire({ title: "Wrong Credentials", text: "Please enter correct credentials", icon: "error" }) }
        else if (ErrorCode === "-5") { SweetAlert.fire({ title: "Oops", text: "Data could not be saved, Please try again later", icon: "error" }) }
        else if (ErrorCode === "-6") { SweetAlert.fire({ title: "Failed", text: "Check DB Logs", icon: "error" }) }
        else if (ErrorCode === "-7") { SweetAlert.fire({ title: "Duplicate", text: "Template already exists", icon: "error" }) }
        else if (ErrorCode === "-8") { SweetAlert.fire({ title: "Data unavailable", text: "No record found to import", icon: "error" }) }
        else if (ErrorCode === "-9") { SweetAlert.fire({ title: "LDAP authentication failed", text: ErrorMsg, icon: "error" }) }
        else if (ErrorCode === "-10") { SweetAlert.fire({ title: "Validations", text: "Please insert atleast one valid rate", icon: "error" }) }
        else if (ErrorCode === "-25") { SweetAlert.fire({ title: "Duplicate", text: "Item already added to this template.Please refresh", icon: "error" }) }
        else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }) }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Add Edit User Centre Templates Mapping" parent="Master" />
            <Container fluid>
                <Row className='mt25'>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row>
                                    <Col lg={6} md={6}>
                                        <Form.Group>
                                            <Form.Label>Users <span className='text-red'>*</span></Form.Label>
                                            <Select onChange={TypeChangeHandler}
                                                options={users}
                                                value={typeSelected} />
                                        </Form.Group>
                                    </Col>
                                    {
                                        showcentres == true ?
                                            <Col lg={6} md={6}>
                                                <Form.Group>
                                                    <Form.Label>Centres <span className='text-red'>*</span></Form.Label>
                                                    <div className='txtMultiSelect1'>
                                                        <MultiSelect id="SalesSelect" name="SalesSelect"
                                                            options={centres}
                                                            value={centretypeSelected}
                                                            onChange={CentreChangeHandler}
                                                            labelledBy="Select"
                                                        /></div>
                                                </Form.Group>
                                            </Col> : null
                                    }
                                    <Col lg={12} md={12} className='text-center'>
                                        <Button variant='secondary' className="mr-2" onClick={PrevBckHandler} >Cancel</Button>
                                        {
                                            selectedoptions == [] || selectedoptions == null || selectedoptions == undefined || selectedoptions == "" ? null :
                                                <>
                                                    {
                                                        (JSON.parse(localStorage.getItem('EditUserCentreTemplateData')) == null || JSON.parse(localStorage.getItem('EditUserCentreTemplateData')) == undefined) ?
                                                            <>
                                                                {isLoading ? <LoadingButton variantName='primary' cssName="mr-2" /> :
                                                                    <Button variant='primary' type='submit' className="mr-2" onClick={(e) => SaveTemporaryData()}>Bind Grid</Button>
                                                                }
                                                            </> :
                                                            <>
                                                                <Button variant='primary' type='submit' className="mr-2" onClick={(e) => SaveTemporaryData()} disabled>Bind Grid</Button>
                                                            </>
                                                    }
                                                </>
                                        }
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    temporarydata == [] || temporarydata == null || temporarydata == undefined || temporarydata == "" ? null :
                        <Row className='mt25'>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <Form.Row>
                                            <Col lg={12} md={12}>
                                                <Table>
                                                    <thead className="thead-light">
                                                        <tr>
                                                            <th scope="col">#</th>
                                                            <th scope="col">User Name</th>
                                                            <th scope="col">Centre Name</th>
                                                            <th scope="col">Rate Templates</th>
                                                            <th scope="col">Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {
                                                            temporarydata.length > 0 ?
                                                                <>
                                                                    {
                                                                        temporarydata.map((d, index) =>
                                                                            <>
                                                                                <tr>
                                                                                    <td>{index + 1}</td>
                                                                                    <td>{d.UserName}</td>
                                                                                    <td>{d.CentreName}</td>
                                                                                    <td>
                                                                                        <MultiSelect id={`SalesSelect${index}`} name={`SalesSelect${index}`}
                                                                                            options={ratetemplate.filter((q) => q.centreid == d.CentreId)}
                                                                                            value={ratetemplatetypeSelected.filter((z) => z.Id == d.Id)}
                                                                                            onChange={(e) => RateTemplateTypeChangeHandler(e, d.Id)}
                                                                                            labelledBy="Select"
                                                                                            className="fixed-width-select"
                                                                                        />
                                                                                        {/* <Select
                                                                                            onChange={(e) => RateTemplateTypeChangeHandler(e, d.Id)}
                                                                                            options={ratetemplate.filter((q) => q.centreid == d.CentreId)}
                                                                                            value={ratetemplatetypeSelected.filter((z) => z.Id == d.Id)}
                                                                                        /> */}
                                                                                    </td>
                                                                                    <td className="text-center">
                                                                                        <DeleteToolTip
                                                                                            title="Delete"
                                                                                            placement="top"
                                                                                            id={`Delete${d.Id}`}
                                                                                            onClick={() => Displayalert(d.Id)}
                                                                                        />
                                                                                    </td>
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    }
                                                                </>
                                                                :
                                                                <tr><td className="text-center" colSpan={6} style={{ color: 'red' }}><b>No record found</b></td></tr>
                                                        }
                                                    </tbody>
                                                </Table>
                                            </Col>
                                            <Col lg={12} md={12} className='text-center'>
                                                {isButtonLoading ? <LoadingButton variantName='primary' cssName="mr-2" /> : <Button variant='primary' type='submit' className="mr-2" onClick={(e) => SavePermanentData()}>Save</Button>}
                                            </Col>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                }
            </Container>
        </React.Fragment>
    )
}

export default AddEditUserCentreTemplatesMapping