import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import DynamicDataTables from '../commonComponent/DataTable/DynamicDataTable';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container, Row, Col, Card } from 'react-bootstrap';



const ValidateBankPayment = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [PageHelper, SetPageHelper] = useState({
        PageNo: '1',
        PageSize: '10',
        TotalRecord: 0,
        dsBankPaymentDlists: []
    });
    const paginationOptions = {
        totalSize: PageHelper.TotalRecord,
        sizePerPage: PageHelper.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onSizePerPageChange: (sizePerPage, page, event) => {
            GetBankPaymentDetails(page, sizePerPage);
            document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
        },
        onPageChange: (page, sizePerPage) => {
            GetBankPaymentDetails(page, sizePerPage);
        }
    };

    const columns = [
        {
            dataField: '#',
            text: '#',
            formatter: (cell, row, rowIndex) => {
                let rowNumber = (PageHelper.PageNo - 1) * PageHelper.PageSize + (rowIndex + 1);
                return <span>{rowNumber}</span>
            },
            headerStyle: (colum, colIndex) => {
                return { width: '5%' };
            }
        },
        { dataField: 'TransactionId', text: 'Transaction Id', editable: false },
        { dataField: 'AccountNo', text: 'Account No', editable: false },
        { dataField: 'AccountIFSC', text: 'Account IFSC', editable: false },
        { dataField: 'Amount', text: 'Amount', editable: false },
        { dataField: 'Status', text: 'Status', editable: false },
        { dataField: 'PaymentGateway', text: 'Payment Mode', editable: false },
        {
            dataField: 'LastSync', text: 'Last Sync', editable: false,
            headerStyle: (colum, colIndex) => {
                return { width: '5%' };
            }
        }
    ];
    useEffect(() => {
        GetBankPaymentDetails(1, 10)
    }, []);
    const GetBankPaymentDetails = async (PageNo, PageSize) => {
        setIsLoading(true);
        try {
            let url = URL_Start + 'master/GetBankPaymentDetails'
            let options = {
                method: 'POST',
                url: url,
                headers: { 'Accept': 'application/json', 'Content-Type': 'application/json;charset=UTF-8' },
                data: { PageNo: `${PageNo}`, PageSize: `${PageSize}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        await SetPageHelper({
                            ...PageHelper,
                            PageNo: Number(PageNo),
                            PageSize: Number(PageSize),
                            TotalRecord: Number(ds.TotalRecords),
                            dsBankPaymentDlists: ds.ObjBankPaymentDetails == null ? [] : ds.ObjBankPaymentDetails
                        });
                        setIsLoading(false);
                    }
                }
                else { setIsLoading(false); }
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        } catch (error) {
            if (error.response) {
                ErrorFunction(error.response.status);
                setIsLoading(false);
            }
        }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }
    return (
        <>
            <Breadcrumb title="Validate Bank Payment" parent="Accounts" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Bank Payment Details</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading &&
                                    <div className='text-center'> <Spinner animation="border" size='sm' /></div>
                                }
                                <DynamicDataTables keyField="YesECollectId" tableData={PageHelper.dsBankPaymentDlists} columns={columns} pagination={paginationOptions ? paginationFactory(paginationOptions) : null} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    )
}
export default ValidateBankPayment;