import React, { useEffect, useState, useRef } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Breadcrumb from '../../../components/common/breadcrumb';
import DynamicDataTables from '../commonComponent/DataTable/DynamicDataTable';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ViewToolTip  from '../UI/ToolTips/ViewToolTip';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { useHistory } from 'react-router';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import EditToolTip from '../UI/ToolTips/EditToolTip';
const Employeemaster = () => {
    let textSearch = useRef();
    const navigate = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isButton, setIsButton] = useState(false);
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const [IsEmployeeEdit, SetIsEmployeeEdit] = useState(false);
    const [PageHelper, SetPageHelper] = useState({
        PageNo: '1',
        PageSize: '10',
        TotalRecord: 0,
        dsEmployeeDlists: []

    });
    const[IsEmployeeView, SetIsEmployeeView] =useState(false);
    const [modal, setModal] = useState();
    const toggleModal = () => {
        setModal(!modal)
        
    }
     const[EditEmployeeDetails,SetEditEmployeeDetails]=useState(null);

    const paginationOptions = {

        totalSize: PageHelper.TotalRecord,
        sizePerPage: PageHelper.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',

        onSizePerPageChange: (sizePerPage, page, event) => {
            console.log('Size per page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
           BindGrid(page, sizePerPage,textSearch.current.value.toLowerCase());
            document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
        },
        onPageChange: (page, sizePerPage) => {
            console.log('Page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
        BindGrid(page, sizePerPage,textSearch.current.value.toLowerCase());
        }
    };
    const ViewFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
        <div >
            <ViewToolTip title="View" onClick={()=>ViewEmployeeHandler(rowData.EmployeeId)} />
        </div>
    </React.Fragment>
    }
 
    const columns = [{
        dataField: '#',
        text: '#',
        formatter: (cell, row, rowIndex) => {
          let rowNumber = (PageHelper.PageNo - 1) * PageHelper.PageSize + (rowIndex + 1);
          return <span>{rowNumber}</span>;
        },
        headerStyle: (colum, colIndex) => {
            return { width: '5%'};
            }
  
    },
    {
        dataField: 'EmployeeCode',
        text: 'EmployeeCode',
        editable: false 

    },
    {
        dataField: 'EmployeeName',
        text: 'EmployeeName',
        editable: false 
    },
    {
        dataField: 'BranchCode',
        text: 'Branchcode',
        editable: false 
    },
   {
        dataField: 'DepartmentName',
        text: 'Department',
        editable: false 
    },
    {
         dataField: 'Designation',
         text: 'Designation',
         editable: false 
     }
     ,
     {
          dataField: 'EmpStatus',
          text: 'EmpStatus',
          editable: false 
      },
    {
         dataField: 'LastSyncDate',
         text: 'LastSyncDate',
         editable: false 
     },
    {
        dataField: 'actions',
        isDummyField: true,
        text: 'Actions',
        formatter: ViewFormatter,
        
        headerStyle: (colum, colIndex) => {
            return { width: '5%'};
            }

    }
    ];
   
    const [tableData, setTableData] = useState([]);

    useEffect(() => { BindGrid(1,10,''); 
}, []);
    const handleSearch = async () => {

        BindGrid(1,10,textSearch.current.value.toLowerCase());
    
    }
    const BindGrid = async (PageNo, PageSize,SearchTxtInput) => {
        setIsLoading(true);
        try {
         
            let url=URL_Start + 'hrms/getemployeedetails';
                let options = {
                    method: 'POST',
                    url: url,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                    data: {  PageNo: `${PageNo}`, PageSize: `${PageSize}` ,SearchTxt:`${SearchTxtInput}` }
                };
                let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                    let data = response.data;
    
                    // let data = await response.data;
                    if (data.Success == true) {
                        let ds = data.Data;
                        if (ds != null) {
    
                            await SetPageHelper({
                                ...PageHelper,
                                 PageNo:Number(PageNo),
                                PageSize: Number(PageSize),
                                TotalRecord: Number(ds.TotalRecord),
                                dsEmployeeDlists: ds.ObjEmployeeList == null ? [] : ds.ObjEmployeeList
                            });
    
                            setIsLoading(false);
                        }
                    }
                    else {
                        let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');
                    setIsLoading(false);
                    }
    
                }
                else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
                // return data;
            } catch (error) {
                if (error.response) { ErrorFunction(error.response.status);
                    setIsLoading(false);
            }

        }
        }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }

    const CustomlistHandler=()=>{
        asyncFunhrmsSyncEmployeeList();
     }
    async function  asyncFunhrmsSyncEmployeeList() {
        try {
            setIsButton(true);
            let url=URL_Start +'hrms/updateEmployeemasterList';
    
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
              data:{AddedBy:`${LoginId}` }
            };
    
            let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                    let data = response.data;
                    // let data = await response.data;
                    if(data.Success==true)
                    {
                       
                        setTimeout(() => {
                            setIsButton(false);
                            BindGrid(1,10,textSearch.current.value.toLowerCase());
                        SweetAlert.fire({ title: "Success!", text: "Latest Sync data has been successfully!", icon: "success" });
                      }, 2000);

                    }
                    else{
                        setIsButton(false);
                         if(data.ErrorList!=null && data.ErrorList.length>0)
                         {
                          
                            SweetAlert.fire({ title: "Error", text:  data.ErrorList[0].errorMsg, icon: "error" });
                         }
    
                    }
    
    
                }
                else{
                    setIsButton(false);
                    console.log('no record found');
    
                }
             // return data;
            } catch (error) {
                setIsButton(false);
                console.log(error.message);
                let Errtext="";
                    Errtext =<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                    SweetAlert.fire({ title: "Error", text:  Errtext, icon: "error" });
                  
             }
    }

 const ViewEmployeeHandler = (EmployeeId) => {
    localStorage.removeItem('EmployeeId');
        localStorage.setItem('EmployeeId',EmployeeId);
        navigate.push(`/hrms-master/ViewEmployeeDetails`);
   // BindEmployeeDetailByEmpId(EmpoyeeId)    
  }
 async function  BindEmployeeDetailByEmpId(EmployeeId) 
 {
    try {
     
        let url=URL_Start + 'hrms/getemployeedetailByEmpId';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { EmployeeId: `${EmployeeId}`}
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;

                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                       formikEmp.values.employeeid=EmployeeId;
                         if(ds.SAPCode!=null && ds.SAPCode!='')
                         { 
                            formikEmp.values.sapcode=ds.SAPCode;
                            
                         }
                         if(ds.CashCode!=null && ds.CashCode!='')
                         {
                            formikEmp.values.cashcode=ds.CashCode;
                         }
                       
                        await SetEditEmployeeDetails(ds);
                        setModal(!modal);
                        SetIsEmployeeEdit(true);
                    //SetIsEmployeeView(true);
                        //BindGrid(1,10,textSearch.current.value.toLowerCase());
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                console.log('no record found');
                setIsLoading(false);
                }

            }
            else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
            // return data;
        } catch (error) {
            if (error.response) { ErrorFunction(error.response.status);
                setIsLoading(false);
        }

    }
    }
    const [errorModal, setErrorModal] = useState();
const updateEmpHandler=async()=>{

      setModal(true);
     setErrorModal(true);
     formikEmp.handleSubmit();
}
      /********** Validations  ***********/
      const formikEmp = useFormik({
        initialValues: {
          sapcode:'',
          cashcode:'',
          employeeid:''
        },
        //enableReinitialize: true,
        validationSchema: Yup.object({
            sapcode: Yup.string().required('SAP Code Required'),
            cashcode: Yup.string().required('Cash Code Required')
          
              
        }),
        isSubmitting:true,
        onSubmit:  async (values,actions) => {
             try {
                 
                
                       setModal(false);
                       setErrorModal(false);
                       SetIsEmployeeView(false);
                let url=URL_Start+ 'hrms/updateEmployeeSAPCodeDetails';
               
                let options = {
                    method: 'POST',
                    url: url,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                  data:{SAPCode:`${values.sapcode}`, CashCode:`${values.cashcode}`,
                  AddedBy:`${LoginId}`, EmployeeId:`${values.employeeid}`}
                };
               
                let response = await axios(options);
                    let responseOK = response && response.status == 200;
                    if (responseOK) {
                        let data = response.data;
                        // let data = await response.data;
                        if(data.Success==true && data.Data=="2000")
                        {  formikEmp.resetForm();
                           
                          
                            SweetAlert.fire({ title: "Success!", text: "SAP Code and Cash Code been Updated!", icon: "success" });
                            setErrorModal(false);
                           

                        }
                        else{
                             if(data.ErrorList!=null && data.ErrorList.length>0)
                             {
                                let Errtext=<p><div className='text-required'>{data.ErrorList[0].errorMsg} </div></p>;
                                SweetAlert.fire({ title: "Error", text:data.ErrorList[0].errorMsg, icon: "error" });
                             }
                            
                        }
                       
                      
                    }
                    else{
                        console.log('no record found');
                        
                    }
                } catch (error) {
                    console.log(error.message);
                    let Errtext=<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                    SweetAlert.fire({ title: "Error", text:"You may not be connected to a network or Unable to connect to a server", icon: "error" });
                 }
                 setTimeout(() => {
                  
                    actions.setSubmitting(false);
                    }, 1000);
        },
    });
  
    return (
        <React.Fragment>
            <Breadcrumb title="Employee" parent="Master" />
            <Container fluid>
                
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row className="justify-content-center">
                               
                                    <Col lg={4} md={6} className="col-8">
                                        <Form.Group>
                                       
                                            <InputGroup className="mb-3">
                                                <Form.Control onChange={handleSearch} placeholder="Employee Code OR Employee Name..." ref={textSearch} />
                                                <InputGroup.Append>
                                                    <Button variant="secondary" onClick={handleSearch}><i className='fa fa-search'></i></Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2} md={6} className="col-4">
                                        <Form.Group>
                                        <InputGroup className="mb-3">
                                               
                                               <InputGroup.Append>
                                               <Button variant='primary' type='submit'  onClick={CustomlistHandler} >{isButton?<Spinner animation="border" size='sm' />:null} Sync Data </Button>
                                                  
                                               </InputGroup.Append>
                                           </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Employee List</Card.Title>
                            </Card.Header>
                            <Card.Body>

                            {
                                    isLoading &&
                                    <div className='text-center'> <Spinner animation="border" size='sm' /></div>
                                }
                                <DynamicDataTables keyField="EmployeeId" tableData={PageHelper.dsEmployeeDlists} columns={columns} pagination={paginationOptions ? paginationFactory(paginationOptions) : null} />
                              
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            {IsEmployeeView &&
    <Modal isOpen={modal} toggle={toggleModal} centered={true} size="lg">
        <ModalHeader toggle={toggleModal}>View Employee Detail</ModalHeader>
        <ModalBody>
        <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row className="justify-content-center">
                               
                                    <Col lg={4} md={6}>
                                        <Form.Group>
                                       
                                        <label className="col-form-label"><b>Employee Code</b></label>
                                            <span className="form-control-plaintext" >{EditEmployeeDetails.EmployeeCode==null?'N/A':EditEmployeeDetails.EmployeeCode}</span>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} md={6}>
                                        <Form.Group>
                                        <label className="col-form-label"><b>Employee Name</b></label>
                                        <span className="form-control-plaintext" >{EditEmployeeDetails.EmployeeName==null?'N/A':EditEmployeeDetails.EmployeeName}</span>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={4} md={6}>
                                    <Form.Group>
                                        <label className="col-form-label"><b>Company Code</b></label>
                                        <span className="form-control-plaintext" >{EditEmployeeDetails.CompanyCode==null?'N/A':EditEmployeeDetails.CompanyCode}</span>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row className="justify-content-center">
                               
                               <Col lg={4} md={6}>
                                   <Form.Group>
                                  
                                   <label className="col-form-label"><b>Company Name</b></label>
                                       <span className="form-control-plaintext" >{EditEmployeeDetails.CompanyName==null?'N/A':EditEmployeeDetails.CompanyName}</span>
                                   </Form.Group>
                               </Col>
                               <Col lg={4} md={6}>
                                   <Form.Group>
                                   <label className="col-form-label"><b>Branch Code</b></label>
                                   <span className="form-control-plaintext" >{EditEmployeeDetails.BranchCode==null?'N/A':EditEmployeeDetails.BranchCode}</span>
                                   </Form.Group>
                               </Col>
                               <Col lg={4} md={6}>
                               <Form.Group>
                                   <label className="col-form-label"><b>Branch Name</b></label>
                                   <span className="form-control-plaintext" >{EditEmployeeDetails.BranchName==null?'N/A':EditEmployeeDetails.BranchName}</span>
                                   </Form.Group>
                               </Col>
                           </Form.Row>
                           <Form.Row className="justify-content-center">
                               
                               <Col lg={4} md={6}>
                                   <Form.Group>
                                  
                                   <label className="col-form-label"><b>Department Code</b></label>
                                       <span className="form-control-plaintext" >{EditEmployeeDetails.DepartmentCode==null?'N/A':EditEmployeeDetails.DepartmentCode}</span>
                                   </Form.Group>
                               </Col>
                               <Col lg={4} md={6}>
                                   <Form.Group>
                                   <label className="col-form-label"><b>Branch Code</b></label>
                                   <span className="form-control-plaintext" >{EditEmployeeDetails.BranchCode==null?'N/A':EditEmployeeDetails.BranchCode}</span>
                                   </Form.Group>
                               </Col>
                               <Col lg={4} md={6}>
                               <Form.Group>
                                   <label className="col-form-label"><b>Branch Name</b></label>
                                   <span className="form-control-plaintext" >{EditEmployeeDetails.BranchName==null?'N/A':EditEmployeeDetails.BranchName}</span>
                                   </Form.Group>
                               </Col>
                           </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
        </ModalBody>
        <ModalFooter>
            <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
        </ModalFooter>
    </Modal>
}
{IsEmployeeEdit &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true} >
                    <ModalHeader toggle={toggleModal}>Update SAP Code  and Cash Code Detail</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                           <Form.Row className="justify-content-center">
                               <Col md={12}>
                                   <Form.Group>
                                   <label className="col-form-label"><b>Employee Name</b></label>
                                <span className="form-control-plaintext" >{EditEmployeeDetails.EmployeeName==null?'N/A':EditEmployeeDetails.EmployeeName} ( {EditEmployeeDetails.EmployeeCode==null?'N/A':EditEmployeeDetails.EmployeeCode} )</span>
                                   </Form.Group>
                               </Col>
                           </Form.Row>
                                    <Form.Row className="justify-content-center">
                               <Col md={12}>
                                   <Form.Group>
                                   <label className="col-form-label"><b>Branch Code</b></label>
                                <span className="form-control-plaintext" >{EditEmployeeDetails.BranchCode==null?'N/A':EditEmployeeDetails.BranchCode}</span>
                                   </Form.Group>
                               </Col>
                           </Form.Row>
                                        <Form.Row className="justify-content-center">
                         
                                            <Col md={12}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>SAP Code</b></label>
                                                    <Form.Control type='text' id="sapcode" name="sapcode"
                                                    onChange={formikEmp.handleChange}
                                                    onBlur={formikEmp.handleBlur}
                                                    value={formikEmp.values.sapcode} />

                                                {formikEmp.touched.sapcode && formikEmp.errors.sapcode ? (
                                                    <div className='text-required'>{formikEmp.errors.sapcode}</div>
                                                ) : null}
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                        <Form.Row className="justify-content-center">

                                        <Col md={12}>
                                                <Form.Group>
                                                    <label className="col-form-label"><b>Cash Code</b></label>
                                                    <Form.Control type='text' id="cashcode" name="cashcode"
                                                    onChange={formikEmp.handleChange}
                                                    onBlur={formikEmp.handleBlur}
                                                    value={formikEmp.values.cashcode} />

                                                {formikEmp.touched.cashcode && formikEmp.errors.cashcode ? (
                                                    <div className='text-required'>{formikEmp.errors.cashcode}</div>
                                                ) : null}
                                                </Form.Group>
                                            </Col>
                                        </Form.Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="primary" onClick={updateEmpHandler}  >Update</Button>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                    </ModalFooter>
                </Modal>
            }
             {errorModal &&
                <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                    <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                    <ModalBody>
                       
                       
                    <p>
                    {formikEmp.touched.sapcode && formikEmp.errors.sapcode ? (
                                                    <div className='text-required'>{formikEmp.errors.sapcode}</div>
                                                ) : null}
                        </p>
                        <p>
                        {formikEmp.touched.cashcode && formikEmp.errors.cashcode ? (
                                                    <div className='text-required'>{formikEmp.errors.cashcode}</div>
                                                ) : null}
                        </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                        <Button variant="primary" onClick={toggleModal}>Ok</Button>
                    </ModalFooter>
                    
                </Modal>
            } 
        </React.Fragment>

    )
}


export default Employeemaster;