import React, { useEffect, useState, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import Breadcrumb from '../../../components/common/breadcrumb';
import DataTables from '../commonComponent/DataTable/DataTables';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CSVLink } from "react-csv";
import { utils, writeFile } from 'xlsx';

let glbCurrentList = [];
const SalesReport = () => {
    let textSearch = useRef();
    let fromdateSearch = useRef();
    const [isLoading, setIsLoading] = useState(false);
    const [reportHeaderData, setReportHeaderData] = useState([]);
    const [reportLineItemData, setReportLineItemData] = useState([]);
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [reportType, setReportType] = useState("1");
    const [displayDownloadButton, setDisplayDownloadButton] = useState(false);
    const [hideDownload, setHideDownload] = useState(true);
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const paginationOptions = { sizePerPage: 5, showTotal: false, firstPageText: 'First', prePageText: 'Back', nextPageText: 'Next', lastPageText: 'Last', onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); } };
    const headerColumns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'DocNum', text: 'DocNum', editable: false },
        { dataField: 'ParentCompany', text: 'ParentCompany', editable: false },
        { dataField: 'DocDate', text: 'DocDate', editable: false },
        { dataField: 'CardCode', text: 'CardCode', editable: false },
        { dataField: 'NumAtCard', text: 'NumAtCard', editable: false },
        { dataField: 'U_UNE_DUNM', text: 'U_UNE_DUNM', editable: false },
        { dataField: 'U_UNE_LISSI', text: 'U_UNE_LISSI', editable: false },
        { dataField: 'U_UNE_F_Pid', text: 'U_UNE_F_Pid', editable: false },
        { dataField: 'Series', text: 'Series', editable: false, headerStyle: (colum, colIndex) => { return { width: '4%' }; } },
    ];
    const lineItemColumns = [
        { dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
        { dataField: 'DocNum', text: 'DocNum', editable: false },
        { dataField: 'ParentCompany', text: 'ParentCompany', editable: false },
        { dataField: 'LineNum', text: 'LineNum', editable: false },
        { dataField: 'ItemCode', text: 'ItemCode', editable: false },
        { dataField: 'Price', text: 'Price', editable: false },
        { dataField: 'TaxCode', text: 'TaxCode', editable: false },
        { dataField: 'WhsCode', text: 'WarehouseCode', editable: false },
        { dataField: 'UNE_LISSI', text: 'UNE_LISSI', editable: false, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    ];
    useEffect(() => {
        let newDate = new Date()
        let date = newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        var curntDate = year + "-" + month + "-" + date;
        textSearch.current.value = curntDate;
        fromdateSearch.current.value = curntDate;
        BindGrid(fromdateSearch.current.value, textSearch.current.value);
    }, []);

    const handleRadioChange = async (e) => { console.log(e.target.value); setReportType(e.target.value); }
    const handleSearch = async () => {
        setCrntPageNO(0);
        var searchDate = textSearch.current.value;
        var searchFromDate = fromdateSearch.current.value;
        let newDate = new Date()
        let date = newDate.getDate() < 10 ? "0" + newDate.getDate() : newDate.getDate();
        let month = newDate.getMonth() + 1;
        let year = newDate.getFullYear();
        var curntDate = year + "-" + month + "-" + date;
        if ((searchFromDate !== "") && (searchDate !== "")) {
            if (searchFromDate > searchDate) {
                SweetAlert.fire({ title: "Wrong Date Selection", text: "From date always less than or equal to to date.", icon: 'warning' });
                return false;
            }
            else if (searchDate > curntDate) {
                SweetAlert.fire({ title: "Wrong Date Selection", text: "To date always less than or equal to current date.", icon: 'warning' });
                return false;
            }
        }

        BindGrid(searchFromDate, searchDate);

    }
    const handleDownload = async () => {
        setCrntPageNO(0);
        // document.getElementById("HeaderCsvLink").click();
        //  document.getElementById("LineItemCsvLink").click();
        var searchDate = textSearch.current.value;
        DownloadData(fromdateSearch.current.value, searchDate);

    }
    const BindGrid = async (fromdateSearch, reportDate) => {
        if ((fromdateSearch !== "") && (reportDate === "")) { SweetAlert.fire("Please enter to date"); return false; }
        if ((reportDate !== "") && (fromdateSearch === "")) { SweetAlert.fire("Please enter from date"); return false; }
        glbCurrentList = [];
        setIsLoading(true);
        const requestOptions = { ReportType: reportType, SaleFromDate: fromdateSearch, SaleDate: reportDate };
        let url = URL_Start + 'master/getsapsalesreport/'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                var reportData = data.Data;
                setReportHeaderData(reportData.ReportHeaders);
                setReportLineItemData(reportData.LineItems);
                if (reportData.ReportHeaders.length > 0) { setDisplayDownloadButton(true); } else { setDisplayDownloadButton(false); }
                setIsLoading(false);
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });
    }

    const DownloadData = async (fromdateSearch, reportDate) => {
        if ((fromdateSearch !== "") && (reportDate === "")) { SweetAlert.fire("Please enter to date"); return false; }
        if ((reportDate !== "") && (fromdateSearch === "")) { SweetAlert.fire("Please enter from date"); return false; }
        glbCurrentList = [];
        //setIsLoading(true);
        const requestOptions = { ReportType: reportType, SaleFromDate: fromdateSearch, SaleDate: reportDate };
        let url = URL_Start + 'master/getsapsalesreport/'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                var reportData = data.Data;
                if (reportData.ReportHeaders.length > 0) {
                    const headings = [[
                        'DocNum',
                        'ParentCompany',
                        'DocType',
                        'DocDate',
                        'DocDueDate',
                        'CardCode',
                        'NumAtCard',
                        'Comments',
                        'BPL_IDAssignedToInvoice',
                        'U_UNE_DUNM',
                        'U_UNE_LISSI',
                        'U_UNE_TYPE',
                        'U_UNE_F_Pid',
                        'U_UNE_F_Bid',
                        'GSTTransactionType',
                        'Series',
                        'CentreSAPCode',
                        'CentreSAPCodeNew'
                    ]];
                    const wb = utils.book_new();
                    const ws = utils.json_to_sheet([]);
                    utils.sheet_add_aoa(ws, headings);
                    utils.sheet_add_json(ws, reportData.ReportHeaders, { origin: 'A2', skipHeader: false });
                    utils.book_append_sheet(wb, ws, 'SAPSalesHeader');
                    writeFile(wb, 'SAPSalesHeader.csv');

                    const headings2 = [[
                        'ParentKey',
                        'ParentCompany',
                        'LineNum',
                        'ItemCode',
                        'Quantity',
                        'Price',
                        'TaxCode',
                        'LineTotal',
                        'U_DISCAMT',
                        'WtLiable',
                        'AccountCode',
                        'WarehouseCode',
                        'UNE_LISSI',
                        'Une_Input',
                        'CentreSAPCode',
                        'CentreSAPCodeNew'
                    ]];
                    const wbs = utils.book_new();
                    const wss = utils.json_to_sheet([]);
                    utils.sheet_add_aoa(wss, headings2);
                    utils.sheet_add_json(wss, reportData.LineItems, { origin: 'A2', skipHeader: false });
                    utils.book_append_sheet(wbs, wss, 'SAPSalesLineItems');
                    writeFile(wbs, 'SAPSalesLineItems.csv');

                    //setIsLoading(false);
                }
                else {
                    //setIsLoading(false);
                }
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); });

    }

    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }) }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }) }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }) }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }) }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }) }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }) }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Sales Report" parent="SAP" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row className="justify-content-center">
                                    <Col lg={7} md={7} className="salesreportsec pt-2">
                                        <Form.Label>Report Type</Form.Label>
                                        <Form.Label for="centreType1" className='ml-3'>
                                            <input class="radio_animated" type="radio" id='centreType1' name="rdoo-ani" defaultChecked onChange={handleRadioChange} value='1' />Own Centres
                                        </Form.Label>
                                        <Form.Label for="centreType2" className='ml-3'>
                                            <input class="radio_animated" type="radio" id='centreType2' name="rdoo-ani" onChange={handleRadioChange} value='2' />Other Centres
                                        </Form.Label>
                                    </Col>
                                    <Col lg={2} md={3}>
                                        <Form.Group>
                                            <Form.Label>From Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="fromdate" name="fromdate" ref={fromdateSearch} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2} md={3}>
                                        <Form.Group>
                                            <Form.Label>To Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="date1" name="date1" ref={textSearch} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={2} className='text-center'>
                                        <Button variant='primary' onClick={handleSearch} >Search</Button>
                                    </Col>
                                    {displayDownloadButton ?
                                        <Col md={2}>
                                            <Form.Group>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Append>
                                                        <Button variant='primary' type='Button' onClick={handleDownload}>Download Report</Button>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        : null}
                                    {/* {displayDownloadButton ?
                                        <Col md={2}>
                                            <Form.Group>
                                                <InputGroup className="mb-3">
                                                    <InputGroup.Append>
                                                        <Button variant='primary' type='Button' onClick={handleDownload}>Download Report</Button>
                                                    </InputGroup.Append>
                                                </InputGroup>
                                            </Form.Group>
                                            {
                                                hideDownload ?
                                                    <>
                                                        <CSVLink data={reportHeaderData} filename={"SAPSalesHeader.csv"} target="_blank" id="HeaderCsvLink"></CSVLink>
                                                        <CSVLink data={reportLineItemData} filename={"SAPSalesLineItems.csv"} target="_blank" id="LineItemCsvLink"></CSVLink>
                                                    </>
                                                    : null
                                            }
                                        </Col>
                                        : null} */}

                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Sales Header</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <DataTables keyField='id' tableData={reportHeaderData} columns={headerColumns} pagination={paginationFactory(paginationOptions)} />
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Sales Line Items</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading ?
                                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                                        <>
                                            <DataTables keyField='id' tableData={reportLineItemData} columns={lineItemColumns} pagination={paginationFactory(paginationOptions)} />
                                        </>
                                }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default SalesReport;