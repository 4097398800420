import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from "../../../components/common/breadcrumb";
import { URL_Start } from '../../../constant';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { CSVLink } from "react-csv";
import InputGroup from 'react-bootstrap/InputGroup';
import { MultiSelect } from 'react-multi-select-component';

const SaleTrendvsVisitTrend = () => {
    const [reporttype, setReportType] = useState("1");
    const [defaultDate, setDefaultDate] = useState("");
    const [jointsalesexecutive, setJointSalesExecutive] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [csvdata, setCSVData] = useState([]);
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [saleexecutivedropdown, setSalesExecutiveDropDown] = useState([]);
    const [selectsales, setSelectSales] = useState([]);
    const [selectedoptions, setSelectedOptions] = useState([]);

    let fromDate = useRef();
    let toDate = useRef();
    let dcrsalesexe = useRef();

    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        GetJointSalesExecutive();
    }, [])

    const handleReportTypeRadioChange = async (e) => { setReportType(e.target.value); }

    const GetJointSalesExecutive = async () => {
        setIsLoading(true);
        let url = URL_Start + 'reports/getjointsalesexecutive/0/' + localStorage.LoggedInUser
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                let ds = data.Data;
                const TypeOptionDs1 = [];
                ds.map((option) => {
                    const TypeOptions1 = {
                        'value': option.Id,
                        'label': option.Name,
                        'disabled': false
                    }
                    return TypeOptionDs1.push(TypeOptions1);
                });
                setSalesExecutiveDropDown(TypeOptionDs1);
                setIsLoading(false);
            }
            else { setSalesExecutiveDropDown([]); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); setSalesExecutiveDropDown([]); });
    }
    const handleSearchList = async () => {
        var frmdte = "";
        var todte = "";
        if (fromDate.current.value === "" || fromDate.current.value === null || fromDate.current.value === undefined) { frmdte = "0"; } else { frmdte = fromDate.current.value }
        if (toDate.current.value === "" || toDate.current.value === null || toDate.current.value === undefined) { todte = "0"; } else { todte = toDate.current.value }
        if (frmdte == "0" && todte == "0") { SweetAlert.fire("From date and to date is mandatory"); return false; }
        if (frmdte == "0" && todte != "0") { SweetAlert.fire("From date is mandatory"); return false; }
        if (frmdte != "0" && todte == "0") { SweetAlert.fire("To date is mandatory"); return false; }
        if (selectedoptions.length == 0 || selectedoptions == null || selectedoptions == undefined || selectedoptions == []) { SweetAlert.fire("Sales executive is mandatory to select"); return false; }
        if (reporttype == "1") {
            const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
            const firstDate = new Date(toDate.current.value);
            const secondDate = new Date(fromDate.current.value);
            const diffDays = Math.round(Math.abs((firstDate - secondDate) / oneDay));
            if (diffDays > 31) { SweetAlert.fire("You can select max 31 days difference in date wise trend"); return false; }
            else { GetSaleVisitReportData(); }
        }
        else {
            var date1 = new Date(frmdte);
            var date2 = new Date(todte);
            var diff = date2.getTime() - date1.getTime();
            diff = diff / (1000 * 60 * 60 * 24 * 30);
            if (diff > 12) { SweetAlert.fire("You can select max 12 months difference in month wise trend"); return false; }
            else { GetSaleVisitReportData(); }
        }
    }
    const GetSaleVisitReportData = async () => {
        setDownloadVisible(false);
        const requestoptions = {
            CentreId: "0",
            LoginId: localStorage.getItem('LoggedInUser'),
            FromDate: fromDate.current.value,
            ToDate: toDate.current.value,
            SalesPersonList: JSON.stringify(selectedoptions),
            ReporType: reporttype,
            DoctorCategory: "0"
        };
        try {
            let url = URL_Start + 'reports/getsalevisitreportdatadownload'
            await axios.post(url, requestoptions).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) { setDownloadVisible(true); setCSVData(data.Data); document.getElementById("csvdownload").click(); }
                    else {
                        setCSVData([]);
                        SweetAlert.fire("No record available");
                    }
                }
                else {
                    setCSVData([]);
                    if (data.Data == "-1") { SweetAlert.fire("Some db error occured.Please report to support team."); }
                    else { SweetAlert.fire("No record available"); }
                }
            }).catch(function (error) { if (error.response) { setCSVData([]); ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const SalesHandler = (options) => {
        setSelectSales(options);
        const TypeOptL = [];
        if (options != null && options.length > 0) {
            options.map((item) => {
                const TypeOptrows = {
                    'SalesPersonId': item.value
                }
                return TypeOptL.push(TypeOptrows);
            });
            setSelectedOptions(TypeOptL);
        }
        else {
            setSelectedOptions([]);
        }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Sale Trend vs Visit Trend" parent="Reports" />
            <Container fluid>
                <Row className='mt25'>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row>
                                    <Col md={6}>
                                        <Form.Label className='mb-4'><b>Report Type</b></Form.Label>
                                        <Form.Group className='animate-chk m-checkbox-inline center__type mt-15'>
                                            <Form.Label for="reportType0">
                                                <input class="radio_animated" type="radio" id='reportType0' name="rdooreporttype-ani" defaultChecked onChange={handleReportTypeRadioChange} value='1' />Date Wise Trend
                                            </Form.Label>
                                            <Form.Label for="reportType1">
                                                <input class="radio_animated" type="radio" id='reportType1' name="rdooreporttype-ani" onChange={handleReportTypeRadioChange} value='2' />Month Wise Trend
                                            </Form.Label>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>From Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="fromDate" name="fromDate" ref={fromDate} max={defaultDate} defaultValue={defaultDate} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>To Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="toDate" name="toDate" ref={toDate} max={defaultDate} defaultValue={defaultDate} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>Sales Executive: <span className='text-red'>*</span></Form.Label>
                                            <div className='txtMultiSelect1'>
                                                <MultiSelect id="SalesSelect" name="SalesSelect"
                                                    options={saleexecutivedropdown}
                                                    value={selectsales}
                                                    onChange={SalesHandler}
                                                    labelledBy="Select"
                                                /></div>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={12} className='text-center __center__buttonsec mb-4 mt25'>
                                        <Button variant='primary' onClick={handleSearchList} target="_blank" >Download CSV</Button>
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    downloadvisible &&
                    <CSVLink data={csvdata} filename="SaleTrendvsVisitTrend.csv" target="_blank" id="csvdownload"></CSVLink>
                }
            </Container>
        </React.Fragment>
    )
}

export default SaleTrendvsVisitTrend