import React, { useEffect, useState, useRef } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Breadcrumb from '../../../components/common/breadcrumb';
import DynamicDataTables from '../commonComponent/DataTable/DynamicDataTable';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ViewToolTip  from '../UI/ToolTips/ViewToolTip';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { useHistory } from 'react-router';
const LDAPSync = () => {
    let textSearch = useRef();
    const navigate = useHistory();
    const [isLoading, setIsLoading] = useState(false);
    const [isButton, setIsButton] = useState(false);
    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
   
    const [PageHelper, SetPageHelper] = useState({
        PageNo: '1',
        PageSize: '10',
        TotalRecord: 0,
        dsUserDlists: []

    });
    const [modal, setModal] = useState();
    const toggleModal = () => {
        setModal(!modal)
        
    }

    const paginationOptions = {

        totalSize: PageHelper.TotalRecord,
        sizePerPage: PageHelper.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',

        onSizePerPageChange: (sizePerPage, page, event) => {
            console.log('Size per page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
           BindGrid(page, sizePerPage,textSearch.current.value.toLowerCase());
            document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
        },
        onPageChange: (page, sizePerPage) => {
            console.log('Page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
        BindGrid(page, sizePerPage,textSearch.current.value.toLowerCase());
        }
    };
    const ViewFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
        <div >
            {/* <ViewToolTip title="View" onClick={()=>ViewHandler(rowData.UserId)} /> */}
        </div>
    </React.Fragment>
    }

    const columns = [{
        dataField: '#',
        text: '#',
        formatter: (cell, row, rowIndex) => {
          let rowNumber = (PageHelper.PageNo - 1) * PageHelper.PageSize + (rowIndex + 1);
          return <span>{rowNumber}</span>;
        },
        headerStyle: (colum, colIndex) => {
            return { width: '5%'};
            }
  
    },
    {
        dataField: 'LDAPUserName',
        text: 'username'
    },
    {
       dataField: 'LDAPDisplayName',
       text: 'DisplayName'
   },
   {
       dataField: 'LDAPEmail',
       text: 'Email'
   },
   {
       dataField: 'LDAPguid',
       text: 'Guid'
   },
   {
       dataField: 'LDAPLastSyncDate',
       text: 'LastSyncDate',
       headerStyle: (colum, colIndex) => {
        return { width: '5%'};
        }
   }

    ];
   
    const [tableData, setTableData] = useState([]);

    useEffect(() => { BindGrid(1,10,''); 
}, []);
    const handleSearch = async () => {

        BindGrid(1,10,textSearch.current.value.toLowerCase());
    
    }
    const BindGrid = async (PageNo, PageSize,SearchTxtInput) => {
        setIsLoading(true);
        try {
         
            let url=URL_Start + 'users/GetldapUsersmaster';
                let options = {
                    method: 'POST',
                    url: url,
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/json;charset=UTF-8'
                    },
                    data: {  PageNo: `${PageNo}`, PageSize: `${PageSize}` ,SearchTxt:`${SearchTxtInput}` }
                };
                let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                    let data = response.data;
    
                    // let data = await response.data;
                    if (data.Success == true) {
                        let ds = data.Data;
                        if (ds != null) {
    
                            await SetPageHelper({
                                ...PageHelper,
                                 PageNo:Number(PageNo),
                                PageSize: Number(PageSize),
                                TotalRecord: Number(ds.TotalRecord),
                                dsUserDlists: ds.objtblLDAPSyncUserLists==null?[]:ds.objtblLDAPSyncUserLists
                            });
    
                            setIsLoading(false);
                        }
                    }
                    else {
                        let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');
                    setIsLoading(false);
                    }
    
                }
                else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
                // return data;
            } catch (error) {
                if (error.response) { ErrorFunction(error.response.status);
                    setIsLoading(false);
            }

        }
        }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }

    const CustomlistHandler=()=>{
        asyncFunLDALSyncUsersList();
     }
    async function asyncFunLDALSyncUsersList() {
        try {
            setIsButton(true);
            let url=URL_Start +'users/PushldapuserPushLists';
    
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
              data:{AddedBy:`${LoginId}` }
            };
    
            let response = await axios(options);
                let responseOK = response && response.status == 200;
                if (responseOK) {
                    let data = response.data;
                    // let data = await response.data;
                    if(data.Success==true)
                    {
                       
                        setTimeout(() => {
                            setIsButton(false);
                            BindGrid(1,10,textSearch.current.value.toLowerCase());
                        SweetAlert.fire({ title: "Success!", text: "Latest Sync data has been successfully!", icon: "success" });
                      }, 2000);

                    }
                    else{
                        setIsButton(false);
                         if(data.ErrorList!=null && data.ErrorList.length>0)
                         {
                          
                            SweetAlert.fire({ title: "Error", text:  data.ErrorList[0].errorMsg, icon: "error" });
                         }
    
                    }
    
    
                }
                else{
                    setIsButton(false);
                    console.log('no record found');
    
                }
             // return data;
            } catch (error) {
                setIsButton(false);
                console.log(error.message);
                let Errtext="";
                    Errtext =<p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
                    SweetAlert.fire({ title: "Error", text:  Errtext, icon: "error" });
                  
             }
    }


    return (
        <React.Fragment>
            <Breadcrumb title="LDAP Users" parent="Master" />
            <Container fluid>
                
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row className="justify-content-center">
                               
                                    <Col lg={4} md={6} className="col-8">
                                        <Form.Group>
                                       
                                            <InputGroup className="mb-3">
                                                <Form.Control onChange={handleSearch} placeholder="Filter : Username OR Email..." ref={textSearch} />
                                                <InputGroup.Append>
                                                    <Button variant="secondary" onClick={handleSearch}><i className='fa fa-search'></i></Button>
                                                </InputGroup.Append>
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col lg={2} md={6} className="col-4">
                                        <Form.Group>
                                        <InputGroup className="mb-3">
                                               
                                               <InputGroup.Append>
                                               <Button variant='primary' type='submit'  onClick={CustomlistHandler} >{isButton?<Spinner animation="border" size='sm' />:null} Sync Data </Button>
                                                  
                                               </InputGroup.Append>
                                           </InputGroup>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>User List</Card.Title>
                            </Card.Header>
                            <Card.Body>

                            {
                                    isLoading &&
                                    <div className='text-center'> <Spinner animation="border" size='sm' /></div>
                                }
                                <DynamicDataTables keyField="UserId" tableData={PageHelper.dsUserDlists} columns={columns} pagination={paginationOptions ? paginationFactory(paginationOptions) : null} />
                              
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            
        </React.Fragment>

    )
}

export default LDAPSync;