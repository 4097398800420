import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useHistory } from "react-router-dom";
import SweetAlert from 'sweetalert2';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
import LoadingButton from '../commonComponent/LoadingButton';

var glblArr = [];
const PRODetails = (id) => {
    const history = useHistory();
    const [modal, setModal] = useState();
    const toggleModal = () => { setModal(!modal) }
    const [errorModal, setErrorModal] = useState();
    const errorHandler = () => { setModal(true); setErrorModal(true); }
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [statesList, setStatesList] = useState([]);
    const [regionsList, setRegionsList] = useState([]);

    useEffect(() => { GetStates("1"); GetDetails();}, []);

    const GetStates = async (id) => {
        try {
            let url = URL_Start + 'master/getstateslist/' + id
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setStatesList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetRegions = async (id) => {
        try {
            let url = URL_Start + 'master/getregionslist/' + id;
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) { setRegionsList(data.Data.map((d) => (<option key={d.Id} value={d.Id}>{d.Value}</option>))); }
            }).catch(function (error) { });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const GetDetails = async () => {
        try {
            if (localStorage.getItem('fbId') !== null) {
                setIsLoading(true);
                debugger;
                formik.values.id = localStorage.getItem('fbId');
                let url = URL_Start + 'master/getProinfo/' + formik.values.id;
                await axios.get(url).then((response) => {
                    const data = response.data;
                    if (data.Success) {
                        const fldBoyInfo = data.Data;
                        GetRegions(fldBoyInfo.SAPStateId);
                        formik.values.fieldBoyName = fldBoyInfo.PROName;
                        formik.values.age = fldBoyInfo.Age;
                        formik.values.mobileNo = fldBoyInfo.MobileNo;
                        formik.values.address = fldBoyInfo.Address;
                        formik.values.stateId = fldBoyInfo.SAPStateId;
                        formik.values.regionId = fldBoyInfo.SAPRegionId;
                        setIsLoading(false);
                    }
                }).catch(function (error) { setIsLoading(false); });
            }
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); setIsLoading(false); }
    }
    const StateChangeHandler = (e) => {
        formik.values.regionId = "";
        if (e.target.value !== "0") { GetRegions(e.target.value); } else { setRegionsList([]); }
    }
    const formik = useFormik({
        initialValues: { id: '0', fieldBoyName: '', age: '', mobileNo: '', address: '', stateId: '', regionId: '' },
        validationSchema: Yup.object({
            fieldBoyName: Yup.string().required('PRO name is required'),
            stateId: Yup.string().required('State is required'),
            regionId: Yup.string().required('Region is required'),
            mobileNo: Yup.string().max(10, 'Mobile No must be  in 10 digits').min(10, 'Mobile No must be  in 10 digits').required('Mobile No is required'),
            age: Yup.number().typeError('Age must be a number').min(0, 'Min age must be 0.').max(99, 'Max age must be 99.'),
        }),
        onSubmit: values => { setModal(false); setErrorModal(false); AddFieldBoys(values); }
    });
    const AddFieldBoys = async (values) => {
        setIsSaving(true);
        debugger;
        const requestOptions = {
            Id: values.id,
            PROName: values.fieldBoyName,
            Age: values.age,
            MobileNo: values.mobileNo,
            Address: values.address,
            SAPStateId: values.stateId,
            SAPRegionId: values.regionId,
            AddedBy: localStorage.LoggedInUser
        };
        let url = URL_Start + 'master/SetPRO'
        await axios.post(url, requestOptions).then((response) => {
            const data = response.data;
            if (data.Success) {
                SweetAlert.fire({ title: "Success", text: "Data saved successfully", icon: "success" });
                formik.resetForm();
                localStorage.removeItem('fbId');
                history.push({ pathname: `${process.env.PUBLIC_URL}/master/pro-list`, search: `` });
            }
            else { SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsSaving(false); }
        }).catch(function (error) { setIsSaving(false); });
    }
    const cancelHandler = async (CentreId) => {
        localStorage.removeItem('fbId');
        history.push({ pathname: `${process.env.PUBLIC_URL}/master/pro-list`, search: `` });
    }
    return (
        <React.Fragment>
            <Breadcrumb title="PRO Details" parent="Master" />
            <Container fluid>
                {
                    isLoading ?
                        <div className='text-center'> <Spinner animation="border" size='sm' /></div> :
                        <>
                            <Form onSubmit={formik.handleSubmit}>
                                <Row>
                                    <Col md={12}>
                                        <Card>
                                            <Card.Header className='pb-0'>
                                                <Card.Title>Details</Card.Title>
                                            </Card.Header>
                                            <Card.Body>
                                                <Form.Row>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Name<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type='text' name="fieldBoyName" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.fieldBoyName} />
                                                            {formik.touched.fieldBoyName && formik.errors.fieldBoyName ? (<div className='text-required'>{formik.errors.fieldBoyName}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Mobile<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control type="text" name="mobileNo" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.mobileNo} />
                                                            {formik.touched.mobileNo && formik.errors.mobileNo ? (<div className='text-required'>{formik.errors.mobileNo}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Age</Form.Label>
                                                            <Form.Control type='text' name="age" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.age} />
                                                            {formik.touched.age && formik.errors.age ? (<div className='text-required'>{formik.errors.age}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>State<span className='text-required'>*</span></Form.Label>
                                                            <Form.Control as="select" name="stateId" onChangeCapture={StateChangeHandler} onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.stateId}>
                                                                <option value="">---Select---</option>
                                                                {statesList}
                                                            </Form.Control>
                                                            {formik.touched.stateId && formik.errors.stateId ? (<div className='text-required'>{formik.errors.stateId}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={3}>
                                                        <Form.Group>
                                                            <Form.Label>Region</Form.Label>
                                                            <Form.Control as="select" name="regionId" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.regionId}>
                                                                <option value="">---Select---</option>
                                                                {regionsList}
                                                            </Form.Control>
                                                            {formik.touched.regionId && formik.errors.regionId ? (<div className='text-required'>{formik.errors.regionId}</div>) : null}
                                                        </Form.Group>
                                                    </Col>
                                                    <Col md={6}>
                                                        <Form.Group>
                                                            <Form.Label>Address</Form.Label>
                                                            <Form.Control type="text" name="address" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.address} />
                                                        </Form.Group>
                                                    </Col>
                                                </Form.Row>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                </Row>
                                <Row className='mt15'>
                                    <Col md={12} className='text-center'>
                                        <Button variant='secondary' className="mr-2" onClick={cancelHandler}>Cancel</Button>
                                        {isSaving ? <LoadingButton variantName='primary' cssName="" /> : <Button variant='primary' type='submit' onClick={errorHandler}>Save</Button>}
                                    </Col>
                                </Row>
                            </Form>
                        </>
                }
                {errorModal &&
                    <Modal isOpen={modal} toggle={toggleModal} centered={true}>
                        <ModalHeader toggle={toggleModal}>Errors</ModalHeader>
                        <ModalBody>
                            <p>{formik.touched.fieldBoyName && formik.errors.fieldBoyName ? (<div className='text-required'>{formik.errors.fieldBoyName}</div>) : null}</p>
                            <p>{formik.touched.stateId && formik.errors.stateId ? (<div className='text-required'>{formik.errors.stateId}</div>) : null}</p>
                            <p>{formik.touched.regionId && formik.errors.regionId ? (<div className='text-required'>{formik.errors.regionId}</div>) : null}</p>
                            <p>{formik.touched.mobileNo && formik.errors.mobileNo ? (<div className='text-required'>{formik.errors.mobileNo}</div>) : null}</p>
                            <p>{formik.touched.age && formik.errors.age ? (<div className='text-required'>{formik.errors.age}</div>) : null}</p>
                        </ModalBody>
                        <ModalFooter>
                            <Button variant="secondary" onClick={toggleModal}>Cancel</Button>
                            <Button variant="primary" onClick={toggleModal}>Ok</Button>
                        </ModalFooter>
                    </Modal>
                }
            </Container>
        </React.Fragment >
    )
}

export default PRODetails;