import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from '../../../components/common/breadcrumb';
import { FrontDesk_URL } from '../../../constant';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import InputGroup from 'react-bootstrap/InputGroup';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import { CSVLink } from "react-csv";
import LoadingButton from '../commonComponent/LoadingButton';


export const ReportInvoice = () => {
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [defaultDate, setDefaultDate] = useState("");
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [reportinvoicedata, setReportInvoiceData] = useState([]);


    let fromDate = useRef();
    let toDate = useRef();

    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
    }, []);

    const DownloadSaleRegisterStatement = async () => {
        setDownloadVisible(false);
        if (fromDate.current.value == undefined || fromDate.current.value == null || fromDate.current.value == "" || fromDate.current.value == "0") {
            SweetAlert.fire({ title: "Required", text: "Please enter from date", icon: "error" });
        }
        else if (toDate.current.value == undefined || toDate.current.value == null || toDate.current.value == "" || toDate.current.value == "0") {
            SweetAlert.fire({ title: "Required", text: "Please enter to date", icon: "error" });
        }
        else {
            setIsButtonLoading(true);
            const ObjBulkInvoiceReq = {
                FromDate: fromDate.current.value,
                ToDate: toDate.current.value
            };
            let url = FrontDesk_URL + 'master/GetReportInvoiceItemWiseList/';
            await axios.post(url, ObjBulkInvoiceReq).then((response) => {
                const data = response.data;
                setIsButtonLoading(false);
                if (data.Success) {
                    if (data.Data !== null) { setReportInvoiceData(data.Data); setDownloadVisible(true); document.getElementById("csvdownload").click(); }
                    else { setReportInvoiceData([]); setDownloadVisible(false); SweetAlert.fire("No record found"); }
                }
                else { setReportInvoiceData([]); setDownloadVisible(false); SweetAlert.fire({ title: "Application Exception", text: "Some error occured! Please try after sometime", icon: "error" }); setIsButtonLoading(false); }
            }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsButtonLoading(false); setReportInvoiceData([]); setDownloadVisible(false); });
        }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="Report Invoice" parent="Bulk Invoice" />
            <Container fluid>
                <Row className='mt25'>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form>
                                    <Form.Row>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>From Date<span className='text-red'>*</span></Form.Label>
                                                <InputGroup className="mb-3 d-flex justify-content-start">
                                                    <Form.Control type="date" id="fromDate" name="fromDate" ref={fromDate} defaultValue={defaultDate} />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                        <Col md={6}>
                                            <Form.Group>
                                                <Form.Label>To Date<span className='text-red'>*</span></Form.Label>
                                                <InputGroup className="mb-3 d-flex justify-content-start">
                                                    <Form.Control type="date" id="toDate" name="toDate" ref={toDate} defaultValue={defaultDate} />
                                                </InputGroup>
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                    <Form.Row>
                                        <Col md={12} className='text-center __center__buttonsec'>
                                            <Form.Group>
                                                {isButtonLoading ? <LoadingButton variantName='secondary' cssName="" /> :
                                                    <Button variant='primary' className='ml-2' onClick={() => DownloadSaleRegisterStatement()}><i className='fa fa-file-excel-o'></i> Download Statement</Button>
                                                }
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                </Form>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {
                    downloadvisible &&
                    <CSVLink data={reportinvoicedata} filename="Report_Invoice.csv" target="_blank" id="csvdownload"></CSVLink>
                }
            </Container>
        </React.Fragment>
    )
}
export default ReportInvoice