import React, { useEffect, useState, useRef } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Breadcrumb from '../../../components/common/breadcrumb';
import DynamicDataTables from '../commonComponent/DataTable/DynamicDataTable';
import { URL_Start } from '../../../constant';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import { Spinner } from 'react-bootstrap';
import paginationFactory from 'react-bootstrap-table2-paginator';
import cellEditFactory, { Type } from 'react-bootstrap-table2-editor';
import filterFactory, { textFilter } from 'react-bootstrap-table2-filter';
import ViewToolTip from '../UI/ToolTips/ViewToolTip';
import { Modal, ModalHeader, ModalBody, ModalFooter, Alert } from 'reactstrap';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import { useHistory } from 'react-router';

import EditToolTip from '../UI/ToolTips/EditToolTip';
import DeleteToolTip from '../UI/ToolTips/DeleteToolTip';
import SelfActiveToolTip from '../UI/ToolTips/SelfActiveToolTip';
import SelfInactiveToolTip from '../UI/ToolTips/SelfInactiveToolTip';
import { MultiSelect } from 'react-multi-select-component';
import '../../../pages/admin/react-multiselect.css';
import Select from 'react-select';
const Credits = () => {
    let textSearch = useRef();
    const [errorModal, setErrorModal] = useState();
    const [SelectDepartmentDDL, SetSelectDepartmentDDL] = useState([]);
    const [SelectDepartment, SetSelectDepartment] = useState(null);
    const [SelectDesginationDDL, SetSelectDesginationDDL] = useState([]);
    const [SelectDesgination, SetSelectDesgination] = useState(null);
    const [SelectEmployeeDDL, SetSelectEmployeeDDL] = useState([]);
    const [SelectEmployee, SetSelectEmployee] = useState(null);

    const [LoginId, setLoginId] = useState(localStorage.LoggedInUser == undefined ? "" : localStorage.LoggedInUser);
    const [isLoading, setIsLoading] = useState(false);
    const history = useHistory();
    const [tableData, setTableData] = useState([]);
    const [PageHelper, SetPageHelper] = useState({
        PageNo: '1',
        PageSize: '10',
        TotalRecord: 0,
        dsDIsDesignationDlists: []

    });
    const paginationOptions = {

        totalSize: PageHelper.TotalRecord,
        sizePerPage: PageHelper.PageSize,
        showTotal: true,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',

        onSizePerPageChange: (sizePerPage, page, event) => {
            console.log('Size per page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            let DesignationMId = "";
            let EmployeeMId = "";
            if (SelectDesgination != null) {
                if (SelectDesgination.DesignationId != "" || SelectDesgination.DesignationId != "0") {
                    DesignationMId = SelectDesgination.DesignationId;
                }
                else {
                    DesignationMId = "0";
                }
            }
            else {
                DesignationMId = "0";
            }
            if (SelectEmployee != null) {
                if (SelectEmployee.EmployeeId != "" || SelectEmployee.EmployeeId != "0") {
                    EmployeeMId = SelectEmployee.EmployeeId;
                }
                else {
                    EmployeeMId = "0";
                }

            }
            else {
                EmployeeMId = "0";
            }


            BindGrid(page, sizePerPage, DesignationMId, EmployeeMId);
            document.getElementsByClassName('dropdown-menu show')[0].classList.remove('show')
        },
        onPageChange: (page, sizePerPage) => {
            console.log('Page change!!!');
            console.log('Newest size per page:' + sizePerPage);
            console.log('Newest page:' + page);
            let DesignationMId = "";
            let EmployeeMId = "";
            if (SelectDesgination != null) {
                if (SelectDesgination.DesignationId != "" || SelectDesgination.DesignationId != "0") {
                    DesignationMId = SelectDesgination.DesignationId;
                }
                else {
                    DesignationMId = "0";
                }
            }
            else {
                DesignationMId = "0";
            }
            if (SelectEmployee != null) {
                if (SelectEmployee.EmployeeId != "" || SelectEmployee.EmployeeId != "0") {
                    EmployeeMId = SelectEmployee.EmployeeId;
                }
                else {
                    EmployeeMId = "0";
                }

            }
            else {
                EmployeeMId = "0";
            }


            BindGrid(page, sizePerPage, DesignationMId, EmployeeMId);
        }
    };
    const ViewFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div >
                {/* <ViewToolTip title="View" onClick={()=>ViewDoctorHandler(rowData.DoctorId)} /> */}
                <EditToolTip title="Edit" onClick={() => editHandler(rowData.DesignationCreditId)} />
                <DeleteToolTip title="Delete" onClick={() => Displayalert(rowData.DesignationCreditId)} />

            </div>
        </React.Fragment>
    }

    const columns = [{
        dataField: '#',
        text: '#',
        formatter: (cell, row, rowIndex) => {
            let rowNumber = (PageHelper.PageNo - 1) * PageHelper.PageSize + (rowIndex + 1);
            return <span>{rowNumber}</span>;
        },
        headerStyle: (colum, colIndex) => {
            return { width: '5%' };
        }

    },
    {
        dataField: 'DisCustCentres',
        text: 'Centre',
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '20%' };
        }
    },
    {
        dataField: 'DisCustEmployee',
        text: 'Employee',
        editable: false,
        headerStyle: (colum, colIndex) => {
            return { width: '20%' };
        }

    },
    {
        dataField: 'MaxCreditPerMnthRs',
        text: 'Max CREDIT.(Amt.)',
        editable: false
    },
        ,
    {
        dataField: 'MaxCreditPerMnthPercent',
        text: 'Max CREDIT.(%)',
        editable: false
    },

    {
        dataField: 'actions',
        isDummyField: true,
        text: 'Actions',
        formatter: ViewFormatter


    }
    ];


    useEffect(() => {
        BindGrid(1, 10, '0', '0');
        asyncBindFunDepartmentDDL();
        asyncBindFunUserDDL("0");
    }, []);

    const handleSearch = async () => {
        let DesignationMId = "";
        let EmployeeMId = "";
        if (SelectDesgination != null) {
            if (SelectDesgination.DesignationId != "" || SelectDesgination.DesignationId != "0") {
                DesignationMId = SelectDesgination.DesignationId;
            }
            else {
                DesignationMId = "0";
            }
        }
        else {
            DesignationMId = "0";
        }
        if (SelectEmployee != null) {
            if (SelectEmployee.EmployeeId != "" || SelectEmployee.EmployeeId != "0") {
                EmployeeMId = SelectEmployee.EmployeeId;
            }
            else {
                EmployeeMId = "0";
            }

        }
        else {
            EmployeeMId = "0";
        }


        BindGrid(1, 10, DesignationMId, EmployeeMId);
    }


    const BindGrid = async (PageNo, PageSize, DesginationId, EmployeeMId) => {
        setIsLoading(true);
        try {

            let url = URL_Start + 'Master/getCreditDesignationmaster';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { PageNo: `${PageNo}`, PageSize: `${PageSize}`, DesginationId: `${DesginationId}`, EmployeeId: `${EmployeeMId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;

                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {

                        await SetPageHelper({
                            ...PageHelper,
                            PageNo: Number(PageNo),
                            PageSize: Number(PageSize),
                            TotalRecord: Number(ds.TotalRecord),
                            dsDIsDesignationDlists: ds.bindDesignationCreditDetails == null ? [] : ds.bindDesignationCreditDetails
                        });

                        setIsLoading(false);
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');
                    setIsLoading(false);
                }

            }
            else { SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false); }
            // return data;
        } catch (error) {
            if (error.response) {
                ErrorFunction(error.response.status);
                setIsLoading(false);
            }

        }
    }
    const asyncBindFunDepartmentDDL = async () => {
        try {

            let url = URL_Start + 'master/getBindDepartmentDetailsDDL';
            let options = {
                method: 'GET',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: {}
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;

                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        setErrorModal(false);
                        const TypeOptionDs1 = [];

                        ds.map((option) => {
                            const TypeOptions1 = {
                                'DepartmentId': option.DepartmentId,
                                'label': option.CustDeptName,
                                'value': option.DepartmentId,
                                'disabled': false
                            }
                            return TypeOptionDs1.push(TypeOptions1);
                        });
                        SetSelectDepartmentDDL(TypeOptionDs1);
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');

                }

            }
            else {
                setErrorModal(false);
                SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false);
            }
            // return data;
        } catch (error) {
            if (error.response) {
                setErrorModal(false);
                ErrorFunction(error.response.status);

            }

        }
    }

    const asyncBindFunDesginationDDL = async (deptId) => {
        try {

            let url = URL_Start + 'hrms/getdesignationDDL';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { DepartmentId: `${deptId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;

                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        setErrorModal(false);
                        const TypeOptionDs1 = [];

                        ds.map((option) => {
                            const TypeOptions1 = {
                                'DesignationId': option.DesignationId,
                                'label': option.Designation,
                                'value': option.DesignationId,
                                'disabled': false
                            }
                            return TypeOptionDs1.push(TypeOptions1);
                        });
                        SetSelectDesginationDDL(TypeOptionDs1);
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');

                }

            }
            else {
                setErrorModal(false);
                SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false);
            }
            // return data;
        } catch (error) {
            if (error.response) {
                setErrorModal(false);
                ErrorFunction(error.response.status);

            }

        }
    }
    const asyncBindFunUserDDL = async (desginationId) => {

        try {

            let url = URL_Start + 'hrms/getEmployeeDDLByDesignation';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { DesignationId: `${desginationId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;

                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (ds != null) {
                        setErrorModal(false);
                        const TypeOptionDs1 = [];

                        ds.map((option) => {
                            const TypeOptions1 = {
                                'EmployeeId': option.EmployeeId,
                                'label': option.CustUserName,
                                'value': option.EmployeeId,
                                'EmployeeCode': option.EmployeeCode,
                                'disabled': false
                            }
                            return TypeOptionDs1.push(TypeOptions1);
                        });
                        SetSelectEmployeeDDL(TypeOptionDs1);

                    }
                    else {
                        SetSelectEmployeeDDL([]);
                    }

                }
                else {
                    SetSelectEmployeeDDL([]);
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    console.log('no record found');

                }

            }
            else {
                SetSelectEmployeeDDL([]);
                setErrorModal(false);
                SweetAlert.fire('There is some issue at server. We already know about it and working hard to get it fixed as soon as possible.'); setIsLoading(false);
            }
            // return data;
        } catch (error) {
            SetSelectEmployeeDDL([]);
            if (error.response) {
                setErrorModal(false);
                ErrorFunction(error.response.status);

            }

        }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400")
            SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
        else if (ErrorCode == "401")
            SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
        else if (ErrorCode == "403")
            SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
        else if (ErrorCode == "404")
            SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
        else if (ErrorCode == "500")
            SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
        else if (ErrorCode == "503")
            SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
    }

    const editHandler = async (DesignationCreditId) => {
        localStorage.removeItem('DesignationCreditId');
        localStorage.setItem('DesignationCreditId', DesignationCreditId);
        history.push({ pathname: `${process.env.PUBLIC_URL}/master/editCredit`, search: `` });
    }
    const newHandler = async () => {
        history.push({ pathname: `${process.env.PUBLIC_URL}/master/addCredit`, search: `` });
    }
    const Displayalert = (DesignationCreditId) => {
        SweetAlert.fire({
            title: 'Are you sure?',
            text: "Once deleted, you will not be able to recover!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Ok',
            cancelButtonText: 'Cancel',
            reverseButtons: true
        })
            .then((result) => {
                if (result.value) {
                    asyncFunDeletedCreditDesignationDetails(DesignationCreditId);
                } else { SweetAlert.fire('Your data is safe!'); }
            })
    }
    const asyncFunDeletedCreditDesignationDetails = async (DesignationCreditId) => {
        try {

            let url = URL_Start + 'master/SETDeletedEmployeeCreditDetails';
            let options = {
                method: 'POST',
                url: url,
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json;charset=UTF-8'
                },
                data: { AddedBy: `${LoginId}`, DesignationCreditId: `${DesignationCreditId}` }
            };
            let response = await axios(options);
            let responseOK = response && response.status == 200;
            if (responseOK) {
                let data = response.data;
                // let data = await response.data;
                if (data.Success == true) {
                    let ds = data.Data;
                    if (data.Success == true && data.Data == "2000") {
                        SweetAlert.fire({ title: "Success!", text: "Discount has been deleted!", icon: "success" });
                        BindGrid(1, 10, '0', '0');
                    }
                }
                else {
                    let Errtext = <p><div className='text-required'> no record found </div></p>;
                    SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
                    console.log('no record found');
                }


            }
            else {
                let Errtext = <p><div className='text-required'> no record found </div></p>;
                SweetAlert.fire({ title: "Error", text: "no record found", icon: "error" });
            }
            // return data;
        } catch (error) {
            console.log(error.message);
            let Errtext = <p><div className='text-required'>You may not be connected to a network or Unable to connect to a server</div></p>;
            SweetAlert.fire({ title: "Error", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
        }
    }



    const DepartmentHandler = (options) => {

        SetSelectDepartment(options);
        SetSelectDesginationDDL([]);
        SetSelectDesgination(null);
        SetSelectEmployeeDDL([]);
        SetSelectEmployee(null);
        if (options != null && options.DepartmentId != null && options.DepartmentId != "0") {
            asyncBindFunDesginationDDL(options.DepartmentId);
        }

    }
    const DesignationHandler = (options) => {
        SetSelectDesgination(options);
        SetSelectEmployee(null);
        if (options != null && options.DesignationId != null && options.DesignationId != "0") {
            asyncBindFunUserDDL(options.DesignationId);
        }
    }
    const EmployeeHandler = (options) => {

        SetSelectEmployee(options);

    }
    return (
        <React.Fragment>
            <Breadcrumb title="Credits" parent="Master" />
            <Container fluid>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row className='align-items-center'>
                                    {/* <Col md={4} lg={4}>
                                        <Form.Group>
                                            <Form.Label>Departments</Form.Label>
                                            <Select
                                                onChange={DepartmentHandler}
                                                options={SelectDepartmentDDL}
                                                value={SelectDepartment}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} lg={4}>
                                        <Form.Group>
                                            <Form.Label>Designation</Form.Label>
                                            <Select
                                                onChange={DesignationHandler}
                                                options={SelectDesginationDDL}
                                                value={SelectDesgination}
                                            />
                                        </Form.Group>
                                    </Col> */}
                                    <Col md={6} lg={6}>
                                        <Form.Group>
                                            <Form.Label>Employee</Form.Label>
                                            <Select
                                                onChange={EmployeeHandler}
                                                options={SelectEmployeeDDL}
                                                value={SelectEmployee}
                                            />
                                        </Form.Group>
                                    </Col>
                                    <Col md={6} lg={6}>
                                        <Form.Group className='mb-0'>
                                        <Button variant='secondary' className="mr-2" onClick={handleSearch} >Search</Button>
                                        <Button variant='primary' type='submit' onClick={newHandler}>Add Credit</Button>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                {/* <Form.Row>
                                    <Col md={12} className='text-center'>
                                        <Button variant='secondary' className="mr-2" onClick={handleSearch} >Search</Button>
                                        <Button variant='primary' type='submit' onClick={newHandler}>Add Credit</Button>
                                    </Col>
                                </Form.Row> */}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Card>
                            <Card.Header className='pb-0'>
                                <Card.Title>Credit List</Card.Title>
                            </Card.Header>
                            <Card.Body>
                                {
                                    isLoading &&
                                    <div className='text-center'> <Spinner animation="border" size='sm' /></div>
                                }

                                <DynamicDataTables keyField="DoctorId" tableData={PageHelper.dsDIsDesignationDlists} columns={columns} pagination={paginationOptions ? paginationFactory(paginationOptions) : null} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </React.Fragment>
    )
}

export default Credits;