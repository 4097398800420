import React, { useEffect, useState, useRef } from 'react';
import Breadcrumb from "../../../components/common/breadcrumb";
import { URL_Start } from '../../../constant';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import DataTables from '../commonComponent/DataTable/DataTables';
import NoRecordFounds from '../commonComponent/DataTable/NoRecordFounds';
import axios from 'axios';
import SweetAlert from 'sweetalert2';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import InputGroup from 'react-bootstrap/InputGroup';
import { Modal, ModalHeader, ModalBody, ModalFooter, Progress, } from "reactstrap";
import { MultiSelect } from 'react-multi-select-component';

const DCRReports = () => {
    const [defaultDate, setDefaultDate] = useState("");
    const [jointsalesexecutive, setJointSalesExecutive] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [tableData, setTableData] = useState();
    const [crntPageNO, setCrntPageNO] = useState(0);
    const [downloadvisible, setDownloadVisible] = useState(false);
    const [csvdata, setCSVData] = useState([]);
    const [detailtabledata, setDetailTableData] = useState([]);
    const [clickeddate, setClickedDate] = useState("");
    const [rowdownloadvisible, setRowDownloadVisible] = useState(false);
    const [saleexecutivedropdown, setSalesExecutiveDropDown] = useState([]);
    const [selectsales, setSelectSales] = useState([]);
    const [selectedoptions, setSelectedOptions] = useState([]);

    let fromDate = useRef();
    let toDate = useRef();

    useEffect(() => {
        const today = new Date();
        const date = today.setDate(today.getDate());
        const defaultValue = new Date(date).toISOString().split('T')[0] // yyyy-mm-dd
        setDefaultDate(defaultValue);
        GetJointSalesExecutive();
        //GetReportData();
    }, [])

    const paginationOptions = {
        sizePerPage: 10,
        showTotal: false,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        onPageChange: (page, sizePerPage) => { setCrntPageNO(((page - 1) * sizePerPage)); }
    };
    const idFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <span>{crntPageNO + rowIndex + 1}</span>
            </div>
        </React.Fragment>
    }
    const actionFormatter = (cellData, rowData, rowIndex, extraData) => {
        return <React.Fragment>
            <div>
                <Button variant='secondary' size='sm' onClick={() => openDetailsModal(rowData.Id, rowData.CallDate)}>Download CSV</Button>
            </div>
        </React.Fragment>
    }
    const columns = [{ dataField: '#', text: '#', editable: false, formatter: idFormatter, headerStyle: (colum, colIndex) => { return { width: '5%' }; } },
    //{ dataField: 'LocationName', text: 'Location Name', editable: false },
    { dataField: 'SalesExecutive', text: 'Sales Executive', editable: false },
    { dataField: 'CallDate', text: 'Date (yyyy-mm-dd)', editable: false },
    { dataField: 'WorkingType', text: 'Work Type', editable: false },
    { dataField: 'Remarks', text: 'Remarks', editable: false },
    { dataField: 'Details', isDummyField: true, text: 'Details', editable: false, formatter: actionFormatter }
    ];
    const GetJointSalesExecutive = async () => {
        setIsLoading(true);
        let url = URL_Start + 'reports/getjointsalesexecutive/0/' + localStorage.LoggedInUser
        await axios.get(url).then((response) => {
            const data = response.data;
            if (data.Success) {
                let ds = data.Data;
                const TypeOptionDs1 = [];
                ds.map((option) => {
                    const TypeOptions1 = {
                        'value': option.Id,
                        'label': option.Name,
                        'disabled': false
                    }
                    return TypeOptionDs1.push(TypeOptions1);
                });
                setSalesExecutiveDropDown(TypeOptionDs1);
                setIsLoading(false);
            }
            else { setSalesExecutiveDropDown([]); setIsLoading(false); }
        }).catch(function (error) { if (error.response) { ErrorFunction(error.response.status); } setIsLoading(false); setSalesExecutiveDropDown([]); });
    }
    const GetReportData = async () => {
        var frmdte = "";
        var todte = "";
        if (fromDate.current.value === "" || fromDate.current.value === null || fromDate.current.value === undefined) { frmdte = "0"; } else { frmdte = fromDate.current.value }
        if (toDate.current.value === "" || toDate.current.value === null || toDate.current.value === undefined) { todte = "0"; } else { todte = toDate.current.value }
        if (frmdte == "0" && todte != "0") { SweetAlert.fire("From date is mandatory if you select to date"); return false; }
        if (frmdte != "0" && todte == "0") { SweetAlert.fire("To date is mandatory if you select from date"); return false; }
        if (selectedoptions.length == 0 || selectedoptions == null || selectedoptions == undefined || selectedoptions == []) {SweetAlert.fire("Sales executive is mandatory to select"); return false; }
        const requestoptions = {
            CentreId: "0",
            LoginId: localStorage.getItem('LoggedInUser'),
            FromDate: frmdte,
            ToDate: todte,
            SalesPersonList: JSON.stringify(selectedoptions)
        };
        try {
            let url = URL_Start + 'reports/getdcrreportdata' 
            await axios.post(url,requestoptions).then((response) => {
                const data = response.data;
                if (data.Success) { if (data.Data != null) { setTableData(data.Data); } else { setTableData([]); } }
                else { setTableData([]); SweetAlert.fire("Some db error occured.Please report to support team."); }
            }).catch(function (error) { if (error.response) { setTableData([]); ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const handleDownload = async () => {
        setDownloadVisible(false);
        var frmdte = "";
        var todte = "";
        if (fromDate.current.value === "" || fromDate.current.value === null || fromDate.current.value === undefined) { frmdte = "0"; } else { frmdte = fromDate.current.value }
        if (toDate.current.value === "" || toDate.current.value === null || toDate.current.value === undefined) { todte = "0"; } else { todte = toDate.current.value }
        if (frmdte == "0" && todte != "0") { SweetAlert.fire("From date is mandatory if you select to date"); return false; }
        if (frmdte != "0" && todte == "0") { SweetAlert.fire("To date is mandatory if you select from date"); return false; }
        if (selectedoptions.length == 0 || selectedoptions == null || selectedoptions == undefined || selectedoptions == []) {SweetAlert.fire("Sales executive is mandatory to select"); return false; }
        const requestoptions = {
            CentreId: "0",
            LoginId: localStorage.getItem('LoggedInUser'),
            FromDate: frmdte,
            ToDate: todte,
            SalesPersonList: JSON.stringify(selectedoptions)
        };
        try {
            let url = URL_Start + 'reports/getdcrreportdatadownload'
            await axios.post(url,requestoptions).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) { setDownloadVisible(true); setCSVData(data.Data); document.getElementById("csvdownload").click(); }
                    else {
                        setCSVData([]);
                        SweetAlert.fire("No record available");
                    }
                }
                else {
                    setCSVData([]);
                    if (data.Data == "-1") { SweetAlert.fire("Some db error occured.Please report to support team."); }
                    else { SweetAlert.fire("No record available"); }
                }
            }).catch(function (error) { if (error.response) { setCSVData([]); ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const openDetailsModal = async (Id, Date) => {
        setRowDownloadVisible(false);
        setClickedDate(Date);
        try {
            let url = URL_Start + 'reports/getdcrreportdoctordata/' + localStorage.getItem('LoggedInUser') + '/0/' + Id
            await axios.get(url).then((response) => {
                const data = response.data;
                if (data.Success) {
                    if (data.Data != null) { setRowDownloadVisible(true); setDetailTableData(data.Data); document.getElementById("csvdownload1").click(); }
                    else { setDetailTableData([]); SweetAlert.fire("No record available"); }
                }
                else {
                    setDetailTableData([]);
                    if (data.Data == "-1") { SweetAlert.fire("Some db error occured.Please report to support team."); }
                    else { SweetAlert.fire("No record available"); }
                }
            }).catch(function (error) { if (error.response) { setDetailTableData([]); ErrorFunction(error.response.status); } });
        }
        catch (error) { SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" }); }
    }
    const SalesHandler = (options) => {
        setSelectSales(options);
        const TypeOptL = [];
        if (options != null && options.length > 0) {
            options.map((item) => {
                const TypeOptrows = {
                    'SalesPersonId': item.value
                }
                return TypeOptL.push(TypeOptrows);
            });
            setSelectedOptions(TypeOptL);
        }
        else {
            setSelectedOptions([]);
        }
    }
    const ErrorFunction = async (ErrorCode) => {
        if (ErrorCode == "400") { SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" }); }
        else if (ErrorCode == "401") { SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" }); }
        else if (ErrorCode == "403") { SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" }); }
        else if (ErrorCode == "404") { SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" }); }
        else if (ErrorCode == "500") { SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" }); }
        else if (ErrorCode == "503") { SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" }); }
    }
    return (
        <React.Fragment>
            <Breadcrumb title="DCR Reports" parent="Reports" />
            <Container fluid>
                <Row className='mt25'>
                    <Col md={12}>
                        <Card>
                            <Card.Body>
                                <Form.Row>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>From Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="fromDate" name="fromDate" ref={fromDate} max={defaultDate} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>To Date</Form.Label>
                                            <InputGroup className="mb-3 d-flex justify-content-start">
                                                <Form.Control type="date" id="toDate" name="toDate" ref={toDate} max={defaultDate} />
                                            </InputGroup>
                                        </Form.Group>
                                    </Col>
                                    <Col md={4}>
                                        <Form.Group>
                                            <Form.Label>Sales Executive: <span className='text-red'>*</span></Form.Label>
                                            <div className='txtMultiSelect1'>
                                                <MultiSelect id="SalesSelect" name="SalesSelect"
                                                    options={saleexecutivedropdown}
                                                    value={selectsales}
                                                    onChange={SalesHandler}
                                                    labelledBy="Select"
                                                /></div>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col md={12} className='text-center __center__buttonsec mb-4 mt25'>
                                        <Button variant='primary' onClick={GetReportData}>Search</Button>
                                    </Col>
                                </Form.Row>
                                <div className="mt15 d-none d-lg-block">
                                    {
                                        tableData == [] || tableData == null ? null :
                                            <>

                                                <Form.Row className='justify-content-center mt-4'>
                                                    <Col lg={12} md={12}>
                                                        {
                                                            <DataTables
                                                                keyField='Id'
                                                                tableData={tableData}
                                                                columns={columns}
                                                                noDataIndication={NoRecordFounds}
                                                                pagination={paginationOptions ? paginationFactory(paginationOptions) : null}
                                                            />
                                                        }
                                                    </Col>
                                                </Form.Row>
                                            </>
                                    }
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <div className="d-lg-none">
                    <Row>
                        {
                            tableData == [] || tableData == null ? null :
                                <>
                                    {
                                        tableData.map((g, index) =>
                                            <>
                                                <Col md={12}>
                                                    <Card className='routeCard'>
                                                        <Card.Header>
                                                            <Row className='align-items-center'>
                                                                <Col xs={6}>
                                                                    <h3 className='routeSN'>{index + 1}</h3>
                                                                </Col>
                                                            </Row>
                                                        </Card.Header>
                                                        <Card.Body>
                                                            <Row>
                                                                <Col xs={12}>
                                                                    <Form.Group>
                                                                        <Form.Label>Sales Executive: <b>{g.SalesExecutive}</b></Form.Label>
                                                                    </Form.Group>
                                                                    <Form.Group>
                                                                        <Form.Label>Date (yyyy-mm-dd): <b>{g.CallDate}</b></Form.Label>
                                                                    </Form.Group>
                                                                    <Form.Group>
                                                                        <Form.Label>Work Type: <b>{g.WorkingType}</b></Form.Label>
                                                                    </Form.Group>
                                                                    <Form.Group>
                                                                        <Form.Label>Remarks: <b>{g.Remarks}</b></Form.Label>
                                                                    </Form.Group>
                                                                </Col>
                                                            </Row>
                                                        </Card.Body>
                                                        <Card.Footer>
                                                            <Button variant='secondary' id={`Download${g.Id}`} onClick={() => openDetailsModal(g.Id, g.CallDate)}>Download CSV</Button>
                                                        </Card.Footer>
                                                    </Card>
                                                </Col>
                                            </>
                                        )
                                    }
                                </>
                        }
                    </Row>
                </div>
                {
                    tableData == [] || tableData == null || tableData.length == 0 ? null :
                        <Form.Row>
                            <Col md={12} className='text-center __center__buttonsec mb-4 mt25'>
                                <Button className='ml-2' variant='primary' onClick={handleDownload}>Download CSV</Button>
                            </Col>
                        </Form.Row>
                }
                {
                    downloadvisible &&
                    <CSVLink data={csvdata} filename="DailyCallReport.csv" target="_blank" id="csvdownload"></CSVLink>
                }
                {
                    rowdownloadvisible &&
                    <CSVLink data={detailtabledata} filename={`DailyCallReport_${clickeddate}.csv`} target="_blank" id="csvdownload1"></CSVLink>
                }
            </Container>
        </React.Fragment>
    )
}

export default DCRReports