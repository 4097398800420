import React, { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Card, Form, Button, Table } from 'react-bootstrap';
import SweetAlert from 'sweetalert2';
import axios from 'axios';
import { URL_Start } from '../../../constant';

export const CentreDetailsPrint = () => {

  const [CentreType, setCentreType] = useState("");
  const [PartnerType, setPartnerType] = useState("");
  const [WorkingType, setWorkingType] = useState("");
  const [CentreName, setCentreName] = useState("");
  const [UHIDAbbreviation, setUHIDAbbreviation] = useState("");
  const [SAPCode, setSAPCode] = useState("");
  const [SAPStateId, setSAPStateId] = useState("");
  const [SAPRegionId, setSAPRegionId] = useState("");
  const [SAPDistrictId, setSAPDistrictId] = useState("");
  const [PinCode, setPinCode] = useState("");
  const [CentreAddress, setCentreAddress] = useState("");
  const [Latitude, setLatitude] = useState("");
  const [Longitude, setLongitude] = useState("");
  const [IsProcessingLab, setIsProcessingLab] = useState("");
  const [ProcessingLab, setProcessingLab] = useState("");
  const [ProcessingLabSetting, setProcessingLabSetting] = useState("");
  const [PaymentMode, setPaymentMode] = useState("");
  const [SalesPerson, setSalesPerson] = useState("");
  const [SalesManager, setSalesManager] = useState("");
  const [SalesHead, setSalesHead] = useState("");
  const [InvoiceEmail, setInvoiceEmail] = useState("");
  const [ReportEmail, setReportEmail] = useState("");
  const [MinBusinessCommit, setMinBusinessCommit] = useState("");
  const [GSTIN, setGSTIN] = useState("");
  const [BankName, setBankName] = useState("");
  const [AccountNo, setAccountNo] = useState("");
  const [BankAccountName, setBankAccountName] = useState("");
  const [IFSCCode, setIFSCCode] = useState("");
  const [PAN, setPAN] = useState("");
  const [virtualAccount, setVirtualAccount] = useState("");
  const [InvoiceTo, setInvoiceTo] = useState("");
  const [MinBookingCash, setMinBookingCash] = useState("");
  const [BusinessLab, setBusinessLab] = useState("");
  const [CreditBookingLimit, setCreditBookingLimit] = useState("");
  const [CreditIntimationLimit, setCreditIntimationLimit] = useState("");
  const [CreditReportingLimit, setCreditReportingLimit] = useState("");
  const [CreditDays, setCreditDays] = useState("");
  const [BillingCycleId, setBillingCycleId] = useState("");
  const [IsActive, setIsActive] = useState("");
  const [CPName, setCPName] = useState("");
  const [CPEmail, setCPEmail] = useState("");
  const [CPContactNo, setCPContactNo] = useState("");
  const [CPAlternateNo, setCPAlternateNo] = useState("");
  const [CPDesignation, setCPDesignation] = useState("");
  const [CPCentreAdmin, setCPCentreAdmin] = useState("");
  const [CentreEmailid, setCentreEmailid] = useState("");
  const [CentreContactno, setCentreContactno] = useState("");
  const [WarehouseCode, setWarehouseCode] = useState("");
  const [StardusName, setStardusName] = useState("");
  const [StardusCustomerId, setStardusCustomerId] = useState("");
  const [StardusKeyId, setStardusKeyId] = useState("");
  const [StardusUrl, setStardusUrl] = useState("");
  const [StardusSecretKey, setStardusSecretKey] = useState("");
  const [DocumentName, setDocumentName] = useState("");
  const [DocumentType, setDocumentType] = useState("");
  const [DocumentValue, setDocumentValue] = useState("");
  const [CompanyName, setCompanyName] = useState("");
  const [issubcentre, setIsSubCentre] = useState("");
  const [parentcentretype, setParentCentreType] = useState("");
  const [parentcentrename, setParentCentreName] = useState("");
  const [OtherLabRefNo, setOtherLabRefNo] = useState("");
  const [bankcode, setBankCode] = useState("");
  const [SAPSeries, setSAPSeries] = useState("");
  const [HideAmountInBooking, setHideAmountInBooking] = useState("");
  const [ShowBalanceAmount, setShowBalanceAmount] = useState("");
  const [HideReceiptRate, setHideReceiptRate] = useState("");
  const [BatchRequired, setBatchRequired] = useState("");
  const [ShowMRPBill, setShowMRPBill] = useState("");
  const [PrintDepartmentSlip, setPrintDepartmentSlip] = useState("");
  const [ShowPROInRegistration, setShowPROInRegistration] = useState("");
  const [ShowCentreDetailOnReport, setShowCentreDetailOnReport] = useState("");
  const [HideFooterOnWithoutLetterheadReport, setHideFooterOnWithoutLetterheadReport] = useState("");
  const [HideBillingStationery, setHideBillingStationery] = useState("");
  const [radiowhcode, setRadioWarehouseCode] = useState("");
  const [defaultratetemplate, setDefaultRateTemplate] = useState("");
  const [websiteappointmentemail, setWebsiteAppointmentEmail] = useState("");
  const [NewSAPCode, setNewSAPCode] = useState("");
  const [branchid, setBranchId] = useState("");
  const [pathologycostcode1, setPathologyCostCode1] = useState("");
  const [pathologycostcode2, setPathologyCostCode2] = useState("");
  const [pathologycostcode3, setPathologyCostCode3] = useState("");
  const [radiologycostcode1, setRadiologyCostCode1] = useState("");
  const [radiologycostcode2, setRadiologyCostCode2] = useState("");
  const [radiologycostcode3, setRadiologyCostCode3] = useState("");
  const [nmwarehousecode, setNMWarehouseCode] = useState("");
  const [nuclearmedicinecostcode1, setNuclearMedicineCostCode1] = useState("");
  const [nuclearmedicinecostcode2, setNuclearMedicineCostCode2] = useState("");
  const [nuclearmedicinecostcode3, setNuclearMedicineCostCode3] = useState("");

  useEffect(() => {
    GetCentreMasterdetails();
  }, [])

  const GetCentreMasterdetails = async () => {
    try {
      let url = URL_Start + 'master/GetCentreMasterReport/' + localStorage.getItem('cId')
      await axios.get(url).then((response) => {
        const data = response.data;
        if (data.Success) {
          setCentreType(data.Data.CentreType);
          setPartnerType(data.Data.PartnerType);
          setWorkingType(data.Data.WorkingType);
          setCentreName(data.Data.CentreName);
          setUHIDAbbreviation(data.Data.UHIDAbbreviation);
          setSAPCode(data.Data.SAPCode);
          setNewSAPCode(data.Data.NewSAPCode);
          setBranchId(data.Data.BranchId);
          setSAPStateId(data.Data.SAPStateId);
          setSAPRegionId(data.Data.SAPRegionId);
          setSAPDistrictId(data.Data.SAPDistrictId);
          setPinCode(data.Data.PinCode);
          setCentreAddress(data.Data.CentreAddress);
          setLatitude(data.Data.Latitude);
          setLongitude(data.Data.Longitude);
          setIsProcessingLab(data.Data.IsProcessingLab);
          setProcessingLab(data.Data.ProcessingLab);
          setPaymentMode(data.Data.PaymentMode);
          setSalesPerson(data.Data.SalesPerson);
          setSalesManager(data.Data.SalesManager);
          setSalesHead(data.Data.SalesHead);
          setInvoiceEmail(data.Data.InvoiceEmail);
          setReportEmail(data.Data.ReportEmail);
          setMinBusinessCommit(data.Data.MinBusinessCommit);
          setGSTIN(data.Data.GSTIN);
          setBankName(data.Data.BankName);
          setAccountNo(data.Data.AccountNo);
          setBankAccountName(data.Data.BankAccountName);
          setIFSCCode(data.Data.IFSCCode);
          setPAN(data.Data.PAN);
          setVirtualAccount(data.Data.VirtualAccount);
          setInvoiceTo(data.Data.InvoiceTo);
          setMinBookingCash(data.Data.MinBookingCash);
          setBusinessLab(data.Data.BusinessLab);
          setCreditBookingLimit(data.Data.CreditBookingLimit);
          setCreditIntimationLimit(data.Data.CreditIntimationLimit);
          setCreditReportingLimit(data.Data.CreditReportingLimit);
          setCreditDays(data.Data.CreditDays);
          setBillingCycleId(data.Data.BillingCycleId);
          setHideAmountInBooking(data.Data.HideAmountInBooking);
          setShowBalanceAmount(data.Data.ShowBalanceAmount);
          setHideReceiptRate(data.Data.HideReceiptRate);
          setBatchRequired(data.Data.BatchRequired);
          setShowMRPBill(data.Data.ShowMRPBill);
          setPrintDepartmentSlip(data.Data.PrintDepartmentSlip);
          setShowPROInRegistration(data.Data.ShowPROInRegistration);
          setShowCentreDetailOnReport(data.Data.ShowCentreDetailOnReport);
          setHideFooterOnWithoutLetterheadReport(data.Data.HideFooterOnWithoutLetterheadReport);
          setHideBillingStationery(data.Data.HideBillingStationery);
          setIsActive(data.Data.IsActive);
          setCPName(data.Data.CPName);
          setCPEmail(data.Data.CPEmail);
          setCPContactNo(data.Data.CPContactNo);
          setCPAlternateNo(data.Data.CPAlternateNo);
          setCPDesignation(data.Data.CPDesignation);
          setCPCentreAdmin(data.Data.CPCentreAdmin);
          setCentreContactno(data.Data.CentreContactno);
          setCentreEmailid(data.Data.CentreEmailid);
          setWarehouseCode(data.Data.WarehouseCode);
          setRadioWarehouseCode(data.Data.RadiologyWHCode);
          setPathologyCostCode1(data.Data.PathologyCostCode1);
          setPathologyCostCode2(data.Data.PathologyCostCode2);
          setPathologyCostCode3(data.Data.PathologyCostCode3);
          setRadiologyCostCode1(data.Data.RadiologyCostCode1);
          setRadiologyCostCode2(data.Data.RadiologyCostCode2);
          setRadiologyCostCode3(data.Data.RadiologyCostCode3);
          setNMWarehouseCode(data.Data.NuclearMedicineWarehouseCode);
          setNuclearMedicineCostCode1(data.Data.NuclearMedicineCostCode1);
          setNuclearMedicineCostCode2(data.Data.NuclearMedicineCostCode2);
          setNuclearMedicineCostCode3(data.Data.NuclearMedicineCostCode3);
          setWebsiteAppointmentEmail(data.Data.WebsiteAppointmentEmail);
          setSAPSeries(data.Data.SAPSeries);
          setStardusName(data.Data.StardusName);
          setStardusCustomerId(data.Data.StardusCustomerId);
          setStardusKeyId(data.Data.StardusKeyId);
          setStardusUrl(data.Data.StardusUrl);
          setStardusSecretKey(data.Data.StardusSecretKey);
          setDocumentName(data.Data.DocumentName);
          setDocumentType(data.Data.DocumentType);
          setDocumentValue(data.Data.DocumentValue);
          setCompanyName(data.Data.CompanyName);
          setIsSubCentre(data.Data.IsSubCentre);
          setParentCentreType(data.Data.ParentCentreType);
          setParentCentreName(data.Data.ParentCentreName);
          setOtherLabRefNo(data.Data.OtherLabRefNo);
          setBankCode(data.Data.BankCode);
          setProcessingLabSetting(data.Data.ProcessingLabSetting);
          setDefaultRateTemplate(data.Data.DefaultTemplateName);
          localStorage.removeItem('cId');
        }
        else {
          setDefaultRateTemplate("");
          setProcessingLabSetting("");
          setRadioWarehouseCode("");
          setPathologyCostCode1("");
          setPathologyCostCode2("");
          setPathologyCostCode3("");
          setRadiologyCostCode1("");
          setRadiologyCostCode2("");
          setRadiologyCostCode3("");
          setNMWarehouseCode("");
          setNuclearMedicineCostCode1("");
          setNuclearMedicineCostCode2("");
          setNuclearMedicineCostCode3("");
          setWebsiteAppointmentEmail("");
          setBankCode("");
          setOtherLabRefNo("");
          setIsSubCentre("");
          setParentCentreType("");
          setParentCentreName("");
          setCentreType("");
          setPartnerType("");
          setWorkingType("");
          setCentreName("");
          setUHIDAbbreviation("");
          setSAPCode("");
          setNewSAPCode("");
          setBranchId("");
          setSAPStateId("");
          setSAPRegionId("");
          setSAPDistrictId("");
          setPinCode("");
          setCentreAddress("");
          setLatitude("");
          setLongitude("");
          setIsProcessingLab("");
          setProcessingLab("");
          setPaymentMode("");
          setSalesPerson("");
          setSalesManager("");
          setSalesHead("");
          setInvoiceEmail("");
          setReportEmail("");
          setMinBusinessCommit("");
          setGSTIN("");
          setBankName("");
          setAccountNo("");
          setBankAccountName("");
          setIFSCCode("");
          setPAN("");
          setVirtualAccount("");
          setInvoiceTo("");
          setMinBookingCash("");
          setBusinessLab("");
          setCreditBookingLimit("");
          setCreditIntimationLimit("");
          setCreditReportingLimit("");
          setCreditDays("");
          setBillingCycleId("");
          setHideAmountInBooking("");
          setShowBalanceAmount("");
          setHideReceiptRate("");
          setBatchRequired("");
          setShowMRPBill("");
          setPrintDepartmentSlip("");
          setShowPROInRegistration("");
          setShowCentreDetailOnReport("");
          setHideFooterOnWithoutLetterheadReport("");
          setHideBillingStationery("");
          setIsActive("");
          setCPName("");
          setCPEmail("");
          setCPContactNo("");
          setCPAlternateNo("");
          setCPDesignation("");
          setCPCentreAdmin("");
          setCentreContactno("");
          setCentreEmailid("");
          setWarehouseCode("");
          setSAPSeries("");
          setStardusName("");
          setStardusCustomerId("");
          setStardusKeyId("");
          setStardusUrl("");
          setStardusSecretKey("");
          setDocumentName("");
          setDocumentType("");
          setDocumentValue("");
        }
      }).catch(function (error) {
        if (error.response) {
          ErrorFunction(error.response.status);
        }
      });
    }
    catch (error) {
      SweetAlert.fire({ title: "Server Inaccessible!", text: "You may not be connected to a network or Unable to connect to a server", icon: "error" });
    }
  }

  const ErrorFunction = async (ErrorCode) => {
    if (ErrorCode == "400")
      SweetAlert.fire({ title: "Error 400", text: "Bad request", icon: "error" })
    else if (ErrorCode == "401")
      SweetAlert.fire({ title: "Error 401", text: "Unauthorized", icon: "error" })
    else if (ErrorCode == "403")
      SweetAlert.fire({ title: "Error 403", text: "Forbidden", icon: "error" })
    else if (ErrorCode == "404")
      SweetAlert.fire({ title: "Error 404", text: "Page not found", icon: "error" })
    else if (ErrorCode == "500")
      SweetAlert.fire({ title: "Error 500", text: "Internal Server Error", icon: "error" })
    else if (ErrorCode == "503")
      SweetAlert.fire({ title: "Error 503", text: "Service Unavailable", icon: "error" })
  }

  return (
    <>
      <Container fluid>
        <Row>
          <Col md={12}>
            <Card>
              <Card.Body style={{ paddingTop: '0px' }}>
                <div className="headinginnersec" >
                  <center>  <h4 > Centre Master </h4></center>
                </div>
                <hr></hr>
                <div className="main">
                  <div className="row mb-2">
                    <div className="col-sm-4"> Centre Type: <span><b>{CentreType}</b></span></div>
                    <div className="col-sm-4"> Partner Type: <span><b>{PartnerType}</b></span></div>
                    <div className="col-sm-4"> Working Type: <span><b>{parseInt(WorkingType) === 1 ? "Physical" : "Virtual"}</b></span></div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-sm-4"> Centre Name: <span><b>{CentreName}</b></span></div>
                    {CentreType === "Own" ?
                      <div className="col-sm-4"> SAP Branch Code: <span><b>{SAPCode}</b></span></div>
                      : CentreType === "Panel" ?
                        <div className="col-sm-4"> SAP Code: <span><b>{SAPCode}</b></span></div>
                        : <div className="col-sm-4"> SAP Customer Code: <span><b>{SAPCode}</b></span></div>
                    }
                    {CentreType === "Own" ?
                      <div className="col-sm-4"> New SAP Branch Code: <span><b>{NewSAPCode}</b></span></div>
                      : CentreType === "Panel" ?
                        <div className="col-sm-4"> New SAP Code: <span><b>{NewSAPCode}</b></span></div>
                        : <div className="col-sm-4"> New SAP Customer Code: <span><b>{NewSAPCode}</b></span></div>
                    }
                    <div className="col-sm-4"> Branch Id: <span><b>{branchid}</b></span></div>
                    <div className="col-sm-4"> UHID Abb. Code: <span><b>{UHIDAbbreviation}</b></span></div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-sm-4"> IsActive: <span><b>{IsActive}</b></span></div>
                    <div className="col-sm-4"> Company Name: <span><b>{CompanyName}</b></span></div>
                    <div className="col-sm-4"> Website Appointment Email: <span><b>{websiteappointmentemail}</b></span></div>
                    {CentreType != "Panel" ?
                      <div className="col-sm-4"> SAP Series: <span><b>{SAPSeries}</b></span></div>
                      : null}
                  </div>
                  <hr></hr>
                  <div className="headinginnersec">
                    <h4> Address Detail </h4>
                  </div>
                  <hr></hr>
                  <div className="row mb-2">
                    <div className="col-sm-4"> SAP State: <span><b>{SAPStateId}</b></span></div>
                    <div className="col-sm-4"> SAP Region: <span><b>{SAPRegionId}</b></span></div>
                    <div className="col-sm-4"> SAP District: <span><b>{SAPDistrictId}</b></span></div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-sm-4"> SAP Branch Code: <span><b>{SAPCode}</b></span></div>
                    <div className="col-sm-4"> New SAP Branch Code: <span><b>{NewSAPCode}</b></span></div>
                    <div className="col-sm-4"> Branch Id: <span><b>{branchid}</b></span></div>
                    <div className="col-sm-4"> Pin Code: <span><b>{PinCode}</b></span></div>
                    <div className="col-sm-4"> Centre Address: <span><b>{CentreAddress}</b></span></div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-sm-4"> Centre Email: <span><b>{CentreEmailid}</b></span></div>
                    <div className="col-sm-4"> Centre Contact No: <span><b>{CentreContactno}</b></span></div>
                    {
                      CentreType === "Own" ?
                        <div className="col-sm-4"> IsProcessingLab: <span><b>{IsProcessingLab}</b></span></div>
                        : null
                    }
                  </div>
                  {CentreType === "Own" ?
                    <div className="row mb-2">
                      <div className="col-sm-4"> Longitude: <span><b>{Longitude}</b></span></div>
                      <div className="col-sm-4"> Latitude: <span><b>{Latitude}</b></span></div>
                      <div className="col-sm-4"> Pathology Warehouse Code: <span><b>{WarehouseCode}</b></span></div>
                    </div>
                    : null}
                  {CentreType === "Own" ?
                    <div className="row mb-2">
                      <div className="col-sm-4"> Pathology Cost Code 1: <span><b>{pathologycostcode1}</b></span></div>
                      <div className="col-sm-4"> Pathology Cost Code 2: <span><b>{pathologycostcode2}</b></span></div>
                      <div className="col-sm-4"> Pathology Cost Code 3: <span><b>{pathologycostcode3}</b></span></div>
                    </div>
                    : null}
                  <div className="row mb-2">
                    {CentreType === "Own" ?
                      <>
                        <div className="col-sm-4"> Radiology Warehouse Code: <span><b>{radiowhcode}</b></span></div>
                        <div className="col-sm-4"> Radiology Cost Code 1: <span><b>{radiologycostcode1}</b></span></div>
                        <div className="col-sm-4"> Radiology Cost Code 2: <span><b>{radiologycostcode2}</b></span></div>
                      </>
                      : null}
                  </div>
                  {CentreType === "Own" ?
                    <div className="row mb-2">
                      <div className="col-sm-4"> Radiology Cost Code 3: <span><b>{radiologycostcode3}</b></span></div>
                      <div className="col-sm-4"> Nuclear Medicine Warehouse Code: <span><b>{nmwarehousecode}</b></span></div>
                      <div className="col-sm-4"> Nuclear Medicine Cost Code 1: <span><b>{nuclearmedicinecostcode1}</b></span></div>
                    </div>
                    : null}
                  {CentreType === "Own" ? <> <div className="row mb-2">
                    <div className="col-sm-4"> Nuclear Medicine Cost Code 2: <span><b>{nuclearmedicinecostcode2}</b></span></div>
                    <div className="col-sm-4"> Nuclear Medicine Cost Code 3: <span><b>{nuclearmedicinecostcode3}</b></span></div>
                    <div className="col-sm-4"> Stardus Name: <span><b>{StardusName}</b></span></div>
                  </div></>
                    : null}
                  {CentreType === "Own" ? <> <div className="row mb-2">
                    <div className="col-sm-4"> Stardus CustomerId: <span><b>{StardusCustomerId}</b></span></div>
                    <div className="col-sm-4"> Stardus KeyId: <span><b>{StardusKeyId}</b></span></div>
                    <div className="col-sm-4"> Stardus Url: <span><b>{StardusUrl}</b></span></div>
                  </div></>
                    : null}
                     {CentreType === "Own" ? <> <div className="row mb-2">
                      <div className="col-sm-4"> Stardus SecretKey: <span><b>{StardusSecretKey}</b></span></div>
                      <div className="col-sm-4"> Lab Type: <span><b>{ProcessingLab}</b></span></div>
                  </div></>
                    : null}
                  <div className="row mb-2">
                    {CentreType === "Panel" ?
                      <><div className="col-sm-4"> Document Name: <span><b>{DocumentName}</b></span></div>
                        <div className="col-sm-4"> Document Type: <span><b>{DocumentType}</b></span></div>
                        <div className="col-sm-4"> Document Value: <span><b>{DocumentValue}</b></span></div></>
                      : null}
                  </div>
                  <hr></hr>
                  <div className="headinginnersec">
                    <h4> Contact Person Detail </h4>
                  </div>
                  <hr></hr>
                </div>
                <div className="row mb-2">
                  <div className="col-sm-4"> Name: <span><b>{CPName}</b></span></div>
                  <div className="col-sm-4"> Mobile No.: <span><b>{CPContactNo}</b></span></div>
                  <div className="col-sm-4"> Email: <span><b>{CPEmail}</b></span></div>
                </div>
                <div className="row mb-2">
                  <div className="col-sm-4"> designation: <span><b>{CPDesignation}</b></span></div>
                  <div className="col-sm-4"> Alternate ContactNo: <span><b>{CPAlternateNo}</b></span></div>
                  <div className="col-sm-4"> Centre Admin: <span><b>{CPCentreAdmin}</b></span></div>
                </div>
                {CentreType === "Panel" ? null
                  :
                  <div className="row mb-2">
                    <div className="col-sm-4"> Sales Person: <span><b>{SalesPerson}</b></span></div>
                    <div className="col-sm-4"> Sales Manager: <span><b>{SalesManager}</b></span></div>
                    <div className="col-sm-4"> Sales Head: <span><b>{SalesHead}</b></span></div>
                  </div>
                }
                <hr></hr>
                <div className="headinginnersec">
                  <h4> Type </h4>
                </div>
                <hr></hr>
                <div className="row mb-2">
                  <div className="col-sm-4"> Payment Mode: <span><b>{PaymentMode}</b></span></div>
                  <div className="col-sm-4"> Other Lab RefNo: <span><b>{OtherLabRefNo}</b></span></div>
                  {CentreType === "Panel" ? null : <div className="col-sm-4"> Default Rate Template: <span><b>{defaultratetemplate}</b></span></div>}
                </div>
                <div className="row mb-2">
                  <div className="col-sm-4"> Min. Business Commit : <span><b>{MinBusinessCommit}</b></span></div>
                  <div className="col-sm-4"> GST TIN: <span><b>{GSTIN}</b></span></div>
                  <div className="col-sm-4"> IFSC Code: <span><b>{IFSCCode}</b></span></div>
                </div>
                <div className="row mb-2">
                  <div className="col-sm-4"> Bank Name : <span><b>{BankName}</b></span></div>
                  <div className="col-sm-4"> Account No.: <span><b>{AccountNo}</b></span></div>
                  <div className="col-sm-4"> Bank Account Name: <span><b>{BankAccountName}</b></span></div>
                </div>
                <div className="row mb-2">
                  <div className="col-sm-4"> PAN: <span><b>{PAN}</b></span></div>
                  <div className="col-sm-4"> Virtual Account(IFSC Code: <b>{bankcode}</b>): <span><b>{virtualAccount}</b></span></div>
                  <div className="col-sm-4"> InvoiceTo: <span><b>{InvoiceTo}</b></span></div>
                </div>
                {CentreType === "Third Party" ?
                  <div className="row mb-2">
                    <div className="col-sm-4"> Is Sub Centre: <span><b>{issubcentre}</b></span></div>
                    <div className="col-sm-4"> Parent Centre Type: <span><b>{parentcentretype}</b></span></div>
                    <div className="col-sm-4"> Parent Centre Name: <span><b>{parentcentrename}</b></span></div>
                  </div>
                  : null}
                <div className="row mb-2">
                  {CentreType === "Third Party" ?
                    <>
                      <div className="col-sm-4"> Email Id(Invoice): <span><b>{InvoiceEmail}</b></span></div>
                      <div className="col-sm-4"> Email Id(Report): <span><b>{ReportEmail}</b></span></div>
                    </>
                    : null}
                  <div className="col-sm-4"> Min Cash in Booking: <span><b>{MinBookingCash}</b></span></div>
                </div>
                <hr></hr>
                <div className="headinginnersec">
                  <h4> Other Settings </h4>
                </div>
                <hr></hr>
                {
                  CentreType == "Own" ? null :
                    <div className="row mb-2">
                      <div className="col-sm-4"> Billing Cycle: <span><b>{BillingCycleId}</b></span></div>
                    </div>
                }
                <div className="row mb-2">
                  <div className="col-sm-4"> Tag Processing Lab: <span><b>{ProcessingLabSetting}</b></span></div>
                  <div className="col-sm-4"> Business Lab: <span><b>{BusinessLab}</b></span></div>
                  {
                    CentreType == "Own" ? null :
                      <div className="col-sm-4"> Credit Intimation Limit: <span><b>{CreditIntimationLimit}</b></span></div>
                  }
                </div>
                {
                  CentreType == "Own" ? null :
                    <div className="row mb-2">
                      <div className="col-sm-4"> Credit Reporting Limit: <span><b>{CreditReportingLimit}</b></span></div>
                      <div className="col-sm-4"> Credit Booking Limit: <span><b>{CreditBookingLimit}</b></span></div>
                      <div className="col-sm-4"> CreditDays: <span><b>{CreditDays}</b></span></div>
                    </div>
                }
                <div className="row mb-2">
                  <div className="col-sm-4"> Hide Amount in Booking: <span><b>{HideAmountInBooking}</b></span></div>
                  <div className="col-sm-4"> Show Balance Amount: <span><b>{ShowBalanceAmount}</b></span></div>
                  <div className="col-sm-4"> Hide Receipt Rate: <span><b>{HideReceiptRate}</b></span></div>
                </div>
                <div className="row mb-2">
                  <div className="col-sm-4"> Batch Required: <span><b>{BatchRequired}</b></span></div>
                  <div className="col-sm-4"> Show MRP Bill: <span><b>{ShowMRPBill}</b></span></div>
                  <div className="col-sm-4"> Print Department Slip: <span><b>{PrintDepartmentSlip}</b></span></div>
                </div>
                <div className="row mb-2">
                  <div className="col-sm-4"> Show PRO in Registration: <span><b>{ShowPROInRegistration}</b></span></div>
                  <div className="col-sm-4"> Show Centre Detail on Report: <span><b>{ShowCentreDetailOnReport}</b></span></div>
                  <div className="col-sm-4"> Hide Footer on Without Letterhead Report: <span><b>{HideFooterOnWithoutLetterheadReport}</b></span></div>
                </div>
                <div className="row mb-2">
                  <div className="col-sm-4"> Hide Billing Stationery: <span><b>{HideBillingStationery}</b></span></div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  )
}
export default CentreDetailsPrint;