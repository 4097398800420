import React from "react";
import { Link } from "react-router-dom";
import { UncontrolledTooltip } from 'reactstrap';

const ToolTip = ({ linkId, faName, ...props }) => {
    return (
        <>
            <Link to="#" onClick={props.onClick} className="text-dark"><i class={faName} id={linkId} aria-hidden="true"></i></Link>
            <UncontrolledTooltip placement={props.placement} target={linkId}>
                {props.title}
            </UncontrolledTooltip>
        </>
    )
}

export default ToolTip;